.qode-type1-gradient-left-to-right {
  background: linear-gradient(to right, #ffffff, #b26b53)
}

.qode-type1-gradient-bottom-to-top,
.qode-type1-gradient-bottom-to-top-after:after {
  background: linear-gradient(to top, #ffffff, #b26b53)
}

.qode-type1-gradient-left-bottom-to-right-top {
  background: linear-gradient(to right top, #ffffff, #b26b53)
}

.qode-type1-gradient-left-to-right-2x {
  background: linear-gradient(to right, #ffffff 0%, #b26b53 50%, #ffffff 100%);
  background-size: 200% 200%
}

.qode-type1-gradient-left-to-right-text i,
.qode-type1-gradient-left-to-right-text i:before,
.qode-type1-gradient-left-to-right-text span {
  background: -webkit-linear-gradient(right top, #b26b53, #ffffff);
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent
}

.qode-type1-gradient-bottom-to-top-text i,
.qode-type1-gradient-bottom-to-top-text i:before,
.qode-type1-gradient-bottom-to-top-text span,
.qode-type1-gradient-bottom-to-top-text span span {
  background: -webkit-linear-gradient(bottom, #b26b53, #ffffff);
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent
}

.qode-type1-gradient-bottom-to-top-text-hover:hover i,
.qode-type1-gradient-bottom-to-top-text-hover:hover i:before,
.qode-type1-gradient-bottom-to-top-text-hover:hover span,
.qode-type1-gradient-bottom-to-top-text-hover:hover span span {
  background: -webkit-linear-gradient(bottom, #b26b53, #ffffff);
  color: #fff;
  background-clip: text;
  -webkit-text-fill-color: transparent
}

::selection {
  background: #b26b53
}

::-moz-selection {
  background: #b26b53
}

h1 a:hover,
.box_image_holder .box_icon .fa-stack i.fa-stack-base,
.q_percentage_with_icon,
.filter_holder ul li.active span,
.filter_holder ul li:hover span,
.q_tabs .tabs-nav li.active a:hover,
.q_tabs .tabs-nav li a:hover,
.q_accordion_holder.accordion .ui-accordion-header:hover,
.q_accordion_holder.accordion.with_icon .ui-accordion-header i,
.testimonials .testimonial_text_inner p.testimonial_author span.author_company,
.testimonial_content_inner .testimonial_author .company_position,
.q_icon_with_title.center .icon_holder .font_awsome_icon i:hover,
.q_box_holder.with_icon .box_holder_icon_inner .fa-stack i.fa-stack-base,
.q_icon_with_title.boxed .icon_holder .fa-stack,
.q_progress_bars_icons_inner .bar.active i.fa-circle,
.q_list.number ul>li:before,
.q_social_icon_holder:hover .simple_social,
.social_share_dropdown ul li :hover i,
.social_share_list_holder ul li i:hover,
.blog_holder.blog_masonry_date_in_image .social_share_list_holder ul li i:hover,
.latest_post_inner .post_infos a:hover,
.q_masonry_blog article .q_masonry_blog_post_info a:hover,
.blog_holder article:not(.format-quote):not(.format-link) .post_info a:hover,
.latest_post_inner .post_comments:hover i,
.blog_holder article .post_description a:hover,
.blog_holder article .post_description .post_comments:hover,
.blog_like a:hover i,
.blog_like a.liked i,
.latest_post .blog_like a:hover span,
article:not(.format-quote):not(.format-link) .blog_like a:hover span,
.comment_holder .comment .text .replay,
.comment_holder .comment .text .comment-reply-link,
.header-widget.widget_nav_menu ul.menu li a:hover,
.widget a:hover,
.widget.posts_holder li:hover,
.wpb_widgetised_column .widget a:hover,
.wpb_widgetised_column .widget.posts_holder li:hover,
.elementor-widget.elementor-widget-sidebar .widget a:hover,
.elementor-widget.elementor-widget-sidebar .widget.posts_holder li:hover,
.q_steps_holder .circle_small:hover span,
.q_steps_holder .circle_small:hover .step_title,
.header_top #lang_sel>ul>li>a:hover,
.header_top #lang_sel_click>ul>li>a:hover,
.header_top #lang_sel_list ul li a.lang_sel_sel,
.header_top #lang_sel_list ul li a:hover,
.widget #lang_sel a.lang_sel_sel:hover,
.widget #lang_sel_click a.lang_sel_sel:hover,
.widget #lang_sel ul ul a:hover,
.widget #lang_sel_click ul ul a:hover,
.widget #lang_sel_list li a.lang_sel_sel,
.widget #lang_sel_list li a:hover,
.wpb_widgetised_column .widget #lang_sel a.lang_sel_sel:hover,
.wpb_widgetised_column .widget #lang_sel_click a.lang_sel_sel:hover,
.wpb_widgetised_column .widget #lang_sel ul ul a:hover,
.wpb_widgetised_column .widget #lang_sel_click ul ul a:hover,
.wpb_widgetised_column .widget #lang_sel_list li a.lang_sel_sel,
.wpb_widgetised_column .widget #lang_sel_list li a:hover,
.elementor-widget.elementor-widget-sidebar .widget #lang_sel a.lang_sel_sel:hover,
.elementor-widget.elementor-widget-sidebar .widget #lang_sel_click a.lang_sel_sel:hover,
.elementor-widget.elementor-widget-sidebar .widget #lang_sel ul ul a:hover,
.elementor-widget.elementor-widget-sidebar .widget #lang_sel_click ul ul a:hover,
.elementor-widget.elementor-widget-sidebar .widget #lang_sel_list li a.lang_sel_sel,
.elementor-widget.elementor-widget-sidebar .widget #lang_sel_list li a:hover,
.service_table_inner li.service_table_title_holder i,
.latest_post_two_holder .latest_post_two_text a:hover,
.myaccount_user a,
.woocommerce .select2-results li.select2-highlighted,
.woocommerce-page .select2-results li.select2-highlighted,
.woocommerce-checkout .chosen-container .chosen-results li.active-result.highlighted,
.woocommerce-account .chosen-container .chosen-results li.active-result.highlighted,
.woocommerce ins,
.woocommerce-page ins,
.woocommerce ul.products li.product:hover h6,
.woocommerce div.product div.product_meta>span a:hover,
.woocommerce div.cart-collaterals div.cart_totals table tr.order-total strong span.amount,
.woocommerce-page div.cart-collaterals div.cart_totals table tr.order-total strong span.amount,
.woocommerce div.cart-collaterals div.cart_totals table tr.order-total strong,
.woocommerce .checkout-opener-text a,
.woocommerce form.checkout table.shop_table tfoot tr.order-total th,
.woocommerce form.checkout table.shop_table tfoot tr.order-total td span.amount,
.woocommerce aside ul.product_list_widget li>a:hover,
.woocommerce aside ul.product-categories li>a:hover,
.woocommerce aside ul.product_list_widget li span.amount,
aside ul.product_list_widget li span.amount,
.woocommerce .widget ul.product-categories a:hover,
.woocommerce-page .widget ul.product-categories a:hover,
.wpb_widgetised_column ul.product_list_widget li>a:hover,
.wpb_widgetised_column ul.product-categories li>a:hover,
.wpb_widgetised_column ul.product_list_widget li span.amount,
.wpb_widgetised_column .widget ul.product-categories a:hover,
.elementor-widget.elementor-widget-sidebar ul.product_list_widget li>a:hover,
.elementor-widget.elementor-widget-sidebar ul.product-categories li>a:hover,
.elementor-widget.elementor-widget-sidebar ul.product_list_widget li span.amount,
.elementor-widget.elementor-widget-sidebar .widget ul.product-categories a:hover,
.shopping_cart_header .header_cart:hover i,
.woocommerce-product-rating a:hover,
.select2-container--default.select2-container--open .select2-selection--single,
.select2-container--default .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option--highlighted[aria-selected],
.q_team .q_team_social_holder .q_social_icon_holder:hover .simple_social,
.portfolio_template_8 .portfolio_detail .info .category,
.portfolio_navigation.navigation_title .post_info span.categories,
.qode_portfolio_related .projects_holder article .portfolio_description .project_category,
.blog_compound article .post_content .blog_like a:hover,
.blog_compound article .post_content .blog_like a:hover span,
.blog_compound article .post_content .blog_share a:hover,
.blog_compound article .post_content .blog_share a:hover span,
.blog_compound article .post_content .post_comments:hover,
.blog_compound article .post_content .post_comments:hover span,
.blog_holder.blog_pinterest article.format-link .post_info a:hover,
.blog_holder.blog_pinterest article.format-quote .post_info a:hover,
.blog_compound .post_title .category a,
.blog_compound .post_title .category span.date,
.q_price_table.qode_pricing_table_advanced .qode_pt_subtitle,
.q_price_table.qode_pricing_table_advanced .qode_pt_additional_info .qode_pt_icon,
.q_price_table.qode_pricing_table_advanced .price_table_inner .value,
table.tt_timetable .event .event_header,
table.tt_timetable .event a,
.tt_tabs .tt_tabs_navigation .ui-tabs-active a,
.tt_tabs .tt_tabs_navigation li a:hover,
.qode-owl-slider .owl-nav .owl-next:hover .qode-next-icon,
.qode-owl-slider .owl-nav .owl-next:hover .qode-prev-icon,
.qode-owl-slider .owl-nav .owl-prev:hover .qode-next-icon,
.qode-owl-slider .owl-nav .owl-prev:hover .qode-prev-icon,
.qode-owl-slider-style .owl-nav .owl-next:hover .qode-next-icon,
.qode-owl-slider-style .owl-nav .owl-next:hover .qode-prev-icon,
.qode-owl-slider-style .owl-nav .owl-prev:hover .qode-next-icon,
.qode-owl-slider-style .owl-nav .owl-prev:hover .qode-prev-icon {
  color: #2b2927 !important
}

h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
a:hover,
p a:hover,
.portfolio_share .social_share_holder a:hover,
.breadcrumb .current,
.breadcrumb a:hover,
.q_icon_with_title .icon_with_title_link,
.q_counter_holder span.counter,
.q_font_awsome_icon i,
.q_font_awsome_icon span,
.q_dropcap,
.q_counter_holder span.counter,
nav.mobile_menu ul li a:hover,
nav.mobile_menu ul li.active>a,
.q_progress_bars_icons_inner.square .bar.active i,
.q_progress_bars_icons_inner.circle .bar.active i,
.q_progress_bars_icons_inner.normal .bar.active i,
.q_font_awsome_icon_stack .fa-circle,
.footer_top .q_social_icon_holder:hover .simple_social,
.more_facts_button:hover,
.box_holder_icon .fa-stack i,
.blog_large_image_simple .minimalist_date,
nav.content_menu ul li.active:hover i,
nav.content_menu ul li:hover i,
nav.content_menu ul li.active:hover a,
nav.content_menu ul li:hover a,
.vc_grid-container .vc_grid-filter.vc_grid-filter-color-grey>.vc_grid-filter-item:hover span,
.vc_grid-container .vc_grid-filter.vc_grid-filter-color-grey>.vc_grid-filter-item.vc_active span,
.q_font_awsome_icon i:hover,
.q_font_awsome_icon span:hover,
.fullscreen_search_holder .search_submit:hover,
.title .text_above_title,
.qode-comparative-features-table .qode-cft-link:hover,
.qode-comparative-features-table .qode-cft-mark.qode-cft-active,
.qode-blog-carousel-titled .qode-bct-post .qode-bct-post-date,
.qode-showcase-icon .qode-icon-holder.qode-icon-circle .qode-icon-element,
.qode-horizontal-timeline .qode-timeline-navigation a.qode-prev,
.qode-horizontal-timeline .qode-timeline-navigation a.qode-next {
  color: #2b2927
}

.box_image_with_border:hover,
.qbutton:hover,
.vc_grid-container .vc_row.vc_grid .vc_grid-item .vc_btn:hover,
.vc_grid-container .vc_row.vc_grid .vc_pageable-load-more-btn .vc_btn:hover,
.load_more a:hover,
.blog_load_more_button a:hover,
#submit_comment:hover,
.drop_down .wide .second ul li .qbutton:hover,
.drop_down .wide .second ul li ul li .qbutton:hover,
.qbutton.white:hover,
.qbutton.green,
.portfolio_slides .hover_feature_holder_inner .qbutton:hover,
.testimonials_holder.light .flex-direction-nav a:hover,
.q_progress_bars_icons_inner.square .bar.active .bar_noactive,
.q_progress_bars_icons_inner.square .bar.active .bar_active,
.q_progress_bars_icons_inner.circle .bar.active .bar_noactive,
.q_progress_bars_icons_inner.circle .bar.active .bar_active,
.widget.widget_search form.form_focus,
.q_steps_holder .circle_small_wrapper,
.animated_icon_inner span.animated_icon_back i,
body:not(.search-results) .blog_holder article.format-link .post_text:hover .post_text_inner,
body:not(.search-results) .blog_holder article.format-quote .post_text:hover .post_text_inner,
.woocommerce .button:hover,
.woocommerce-page .button:hover,
.woocommerce #submit:hover,
.woocommerce ul.products li.product a.qbutton:hover,
.woocommerce-page ul.products li.product a.qbutton:hover,
.woocommerce ul.products li.product .added_to_cart:hover,
input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover,
.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .icons_holder a:hover,
.tabs_box_navigation.sf-timetable-menu .tabs_box_navigation_selected {
  border-color: #2b2927
}

.tt_tabs .tt_tabs_navigation .ui-tabs-active a,
.tt_tabs .tt_tabs_navigation li a:hover,
.tt_tabs .tt_tabs_navigation li a {
  border-color: #2b2927 !important
}

.q_icon_list i,
.q_progress_bar .progress_content,
.q_progress_bars_vertical .progress_content_outer .progress_content,
.qbutton:hover,
.vc_grid-container .vc_row.vc_grid .vc_grid-item .vc_btn:hover,
.vc_grid-container .vc_row.vc_grid .vc_pageable-load-more-btn .vc_btn:hover,
.post-password-form input[type=submit]:hover,
.load_more a:hover,
.blog_load_more_button a:hover,
#submit_comment:hover,
.drop_down .wide .second ul li .qbutton:hover,
.drop_down .wide .second ul li ul li .qbutton:hover,
.qbutton.white:hover,
.qbutton.green,
.call_to_action,
.highlight,
.testimonials_holder.light .flex-direction-nav a:hover,
.q_dropcap.circle,
.q_dropcap.square,
.q_message,
.q_price_table.active .active_text,
.q_icon_with_title.boxed .icon_holder .fa-stack,
.q_font_awsome_icon_square,
.q_icon_with_title.square .icon_holder .fa-stack:hover,
.box_holder_icon_inner.square .fa-stack:hover,
.box_holder_icon_inner.circle .fa-stack:hover,
.circle .icon_holder .fa-stack:hover,
.q_list.number.circle_number ul>li:before,
.q_social_icon_holder.circle_social .fa-stack:hover,
.social_share_dropdown ul li.share_title,
.latest_post_holder .latest_post_date .post_publish_day,
.q_masonry_blog article.format-link:hover,
.q_masonry_blog article.format-quote:hover,
#wp-calendar td#today,
.vc_text_separator.full div,
.mejs-controls .mejs-time-rail .mejs-time-current,
.mejs-controls .mejs-time-rail .mejs-time-handle,
.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,
.wp-audio-shortcode .mejs-controls .mejs-time-rail .mejs-time-current,
.wp-audio-shortcode .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,
.q_pie_graf_legend ul li .color_holder,
.q_line_graf_legend ul li .color_holder,
.q_team .q_team_text_inner .separator,
.circle_item .circle:hover,
.qode_call_to_action.container,
.qode_carousels .flex-control-paging li a.flex-active,
.animated_icon_inner span.animated_icon_back i,
.woocommerce .button:hover,
.woocommerce-page .button:hover,
.woocommerce #submit:hover,
.woocommerce ul.products li.product a.qbutton:hover,
.woocommerce-page ul.products li.product a.qbutton:hover,
.woocommerce ul.products li.product .added_to_cart:hover,
.woocommerce .quantity .minus:hover,
.woocommerce #content .quantity .minus:hover,
.woocommerce-page .quantity .minus:hover,
.woocommerce-page #content .quantity .minus:hover,
.woocommerce .quantity .plus:hover,
.woocommerce #content .quantity .plus:hover,
.woocommerce-page .quantity .plus:hover,
.woocommerce-page #content .quantity .plus:hover,
.woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range,
.woocommerce-page .widget_price_filter .ui-slider-horizontal .ui-slider-range,
.q_circles_holder .q_circle_inner2:hover,
input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover,
.portfolio_main_holder .item_holder.subtle_vertical_hover .icons_holder a,
.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .icons_holder a:hover,
.portfolio_main_holder .item_holder.image_text_zoom_hover .icons_holder a,
.portfolio_main_holder .item_holder.slow_zoom .icons_holder a,
.qode_video_box .qode_video_image:hover .qode_video_box_button,
.blog_holder.masonry_gallery article.format-link:hover,
.blog_holder.masonry_gallery article.format-quote:hover,
.blog_holder.blog_chequered article.format-link:hover,
.blog_holder.blog_chequered article.format-quote:hover,
.qode-pricing-calculator .qode-pricing-calculator-switch input:checked+.qode-pricing-calculator-slider,
.qode-icon-holder.qode-icon-circle,
.qode-icon-holder.qode-icon-square,
.qode-qbutton-main-color,
.qode-advanced-tabs .qode-advanced-tabs-nav li,
.qode-accordion-holder .qode-title-holder.ui-state-active,
.qode-accordion-holder .qode-title-holder.ui-state-hover,
header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form>div input[type=text],
.qode-advanced-call-to-action.qode-advanced-cta-gradient-animation .qode-advanced-cta-background-3,
.tt_tabs .tt_tabs_navigation li a,
.tabs_box_navigation.sf-timetable-menu .tabs_box_navigation_selected,
.tabs_box_navigation.sf-timetable-menu li ul li a:hover,
.tabs_box_navigation.sf-timetable-menu li ul li.selected a:hover,
#qode-multi-device-showcase.qode-mds-appear-effect #qode-mds-spinner .qode-mds-pulse,
.qode-horizontal-timeline .qode-events-wrapper .qode-events .qode-filling-line,
.qode-horizontal-timeline .qode-events-wrapper .qode-events a .circle-outer,
.no-touch .qode-horizontal-timeline .qode-events-wrapper .qode-events a:hover .circle-outer,
.qode-horizontal-timeline .qode-events-wrapper .qode-events a.selected .circle-outer {
  background-color: #2b2927
}

.qode-showcase-item-holder.qode-showcase-active .qode-icon-holder.qode-icon-circle {
  background-color: #2b2927 !important
}

.q_circles_holder .q_circle_inner2:hover,
body:not(.search-results) .blog_holder article.format-link .post_text:hover .post_text_inner,
body:not(.search-results) .blog_holder article.format-quote .post_text:hover .post_text_inner {
  background-color: #2b2927 !important;
  border-color: #2b2927 !important
}

.qode-lazy-preloader svg circle,
#qode-multi-device-showcase.qode-mds-appear-effect #qode-mds-spinner svg circle {
  stroke: #2b2927
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a a,
p a,
nav.main_menu>ul>li.active>a,
.drop_down .wide .second ul li ul li.menu-item-has-children>a,
.drop_down .wide .second ul li ul li.menu-item-has-children>a:hover,
.title h1,
.q_icon_list p,
.q_progress_bars_vertical .progress_number,
.qbutton,
.vc_grid-container .vc_row.vc_grid .vc_grid-item .vc_btn,
.vc_grid-container .vc_row.vc_grid .vc_pageable-load-more-btn .vc_btn:hover,
.post-password-form input[type=submit],
.load_more a,
.blog_load_more_button a,
#submit_comment,
.drop_down .wide .second ul li .qbutton,
.drop_down .wide .second ul li ul li .qbutton,
.q_percentage,
.portfolio_navigation .portfolio_prev a:hover,
.portfolio_navigation .portfolio_next a:hover,
.q_tabs .tabs-nav li.active a,
.q_accordion_holder.accordion .ui-accordion-header,
.q_accordion_holder.accordion.with_icon .ui-accordion-header,
.testimonials .testimonial_text_inner p.testimonial_author,
.testimonial_content_inner .testimonial_author .website,
.q_icon_with_title .icon_with_title_link:hover,
.ordered ol li,
.q_list.circle ul>li,
.q_list.number ul>li,
.latest_post_holder .latest_post_date .post_publish_month,
.latest_post_inner .post_infos a,
.latest_post_holder.dividers .latest_post_date .latest_post_day,
.q_masonry_blog article.format-quote .q_masonry_blog_post_text p,
.q_masonry_blog article.format-link .q_masonry_blog_post_text p,
.q_masonry_blog article .q_masonry_blog_post_info,
.blog_holder article.format-quote .post_text .post_title p,
.blog_holder article.format-link .post_text .post_title p,
.single_links_pages span,
.single_links_pages a:hover span,
.comment_holder .comment .text .name,
.blog_holder.masonry article .post_info,
.pagination ul li span,
.pagination ul li a:hover,
.q_team .q_team_description_inner p,
.carousel-inner .item.dark .slider_content .text .qbutton,
.carousel-control,
.more_facts_button,
.woocommerce div.message,
.woocommerce .woocommerce-message,
.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.myaccount_user,
.woocommerce .button,
.woocommerce-page .button,
.woocommerce-page input[type=submit],
.woocommerce input[type=submit],
.woocommerce ul.products li.product .added_to_cart,
.woocommerce .select2-container .select2-choice .select2-arrow .select2-arrow:after,
.woocommerce-page .select2-container .select2-choice .select2-arrow:after,
.woocommerce-pagination ul.page-numbers li span.current,
.woocommerce-pagination ul.page-numbers li a:hover,
.woocommerce .quantity input.qty,
.woocommerce #content .quantity input.qty,
.woocommerce-page .quantity input.qty,
.woocommerce-page #content .quantity input.qty,
.woocommerce .summary p.stock.out-of-stock,
.woocommerce aside ul.product_list_widget li a,
.wpb_widgetised_column ul.product_list_widget li a,
.elementor-widget.elementor-widget-sidebar ul.product_list_widget li a,
.woocommerce .widget_price_filter .price_label,
.woocommerce-page .widget_price_filter .price_label,
.carousel-control:hover,
input.wpcf7-form-control.wpcf7-submit:not([disabled]),
.qode-pricing-calculator .qode-pricing-calculator-total-price-holder,
.qode-advanced-tabs.qode-advanced-horizontal-tab .qode-advanced-tabs-nav li.ui-state-active a,
.qode-advanced-tabs.qode-advanced-horizontal-tab .qode-advanced-tabs-nav li.ui-state-hover a {
  color: #b26b53;
}

.qbutton,
.vc_grid-container .vc_row.vc_grid .vc_grid-item .vc_btn,
.vc_grid-container .vc_row.vc_grid .vc_pageable-load-more-btn .vc_btn:hover,
.post-password-form input[type=submit],
.load_more a,
.blog_load_more_button a,
#submit_comment,
.drop_down .wide .second ul li .qbutton,
.drop_down .wide .second ul li ul li .qbutton,
.testimonials_holder .flex-direction-nav a,
.header_top #lang_sel ul li ul li a,
.header_top #lang_sel ul li ul li a:visited,
.header_top #lang_sel_click ul li ul li a,
.header_top #lang_sel_click ul li ul li a:visited,
.woocommerce .button,
.woocommerce-page .button,
.woocommerce-page input[type=submit],
.woocommerce input[type=submit],
.woocommerce ul.products li.product .added_to_cart,
.carousel-inner .item.dark .slider_content .text .qbutton,
input.wpcf7-form-control.wpcf7-submit:not([disabled]) {
  border-color: #b26b53;
}

.ajax_loader .pulse,
.ajax_loader .double_pulse .double-bounce1,
.ajax_loader .double_pulse .double-bounce2,
.ajax_loader .cube,
.ajax_loader .rotating_cubes .cube1,
.ajax_loader .rotating_cubes .cube2,
.ajax_loader .stripes>div,
.ajax_loader .wave>div,
.ajax_loader .two_rotating_circles .dot1,
.ajax_loader .two_rotating_circles .dot2,
.ajax_loader .five_rotating_circles .container1>div,
.ajax_loader .five_rotating_circles .container2>div,
.ajax_loader .five_rotating_circles .container3>div,
.separator.small,
.testimonials_holder .flex-direction-nav a:hover,
.q_price_table .price_table_inner,
.carousel-inner .item.dark .slider_content .text .qbutton:hover,
.vertical_menu_hidden_button_line,
.vertical_menu_hidden_button_line:after,
.vertical_menu_hidden_button_line:before,
.blog_vertical_loop_button .button_icon a,
.blog_vertical_loop_back_button .button_icon a,
.woocommerce .product .onsale,
.woocommerce .product .single-onsale {
  background-color: #b26b53;
}

body {
  font-family: open sans, sans-serif;
  color: #797979;
  font-size: 15px;
  font-weight: 400;
}

.content .content_inner>.container,
.content .content_inner>.full_width>.full_width_inner {
  background-color: #fff;
}

header.fixed_hiding .q_logo a,
header.fixed_hiding .q_logo {
  max-height: 50px;
}

#respond textarea,
#respond input[type=text],
#respond input[type=email],
.contact_form input[type=text],
.contact_form textarea,
.comment_holder #respond textarea,
.comment_holder #respond input[type=text],
.comment_holder #respond input[type=email],
input.wpcf7-form-control.wpcf7-text,
input.wpcf7-form-control.wpcf7-number,
input.wpcf7-form-control.wpcf7-date,
textarea.wpcf7-form-control.wpcf7-textarea,
select.wpcf7-form-control.wpcf7-select,
input.wpcf7-form-control.wpcf7-quiz,
.post-password-form input[type=password] {
  border: 1px solid #e5e5e5;
}

.woocommerce input[type=text]:not(.qode_search_field):not(.qty),
.woocommerce input[type=password],
.woocommerce input[type=email],
.woocommerce-page input[type=text]:not(.qode_search_field):not(.qty),
.woocommerce-page input[type=password],
.woocommerce-page input[type=email],
.woocommerce-page input[type=tel],
.woocommerce textarea,
.woocommerce-page textarea,
.woocommerce .select2-container .select2-choice,
.woocommerce-page .select2-container .select2-choice,
.woocommerce-page .select2-dropdown-open.select2-drop-above .select2-choice,
.woocommerce div.cart-collaterals .select2-container .select2-choice,
.woocommerce-page div.cart-collaterals .select2-container .select2-choice {
  border: 1px solid #e5e5e5;
}

h1,
.h1,
.title h1,
body.qode-overridden-elementors-fonts .elementor-widget-heading h1.elementor-heading-title {
  color: #212121;
  font-family: josefin sans, sans-serif;
  font-size: 48px;
  line-height: 58px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.title.title_size_large h1 {
  font-size: 48px;
  line-height: 58px;
  font-weight: 400;
  text-transform: uppercase;
}

h2,
.h2,
h2 a,
body.qode-overridden-elementors-fonts .elementor-widget-heading h2.elementor-heading-title {
  color: #2b2927;
  font-family: josefin sans, sans-serif;
  font-size: 27px;
  line-height: 37px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase
}

h3,
.h3,
h3 a,
body.qode-overridden-elementors-fonts .elementor-widget-heading h3.elementor-heading-title {
  color: #868686;
  font-family: eb garamond, sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-style: italic;
  font-weight: 400;
  text-transform: none;
}

h4,
.h4,
h4 a,
body.qode-overridden-elementors-fonts .elementor-widget-heading h4.elementor-heading-title {
  color: #2b2927;
  font-family: josefin sans, sans-serif;
  font-size: 17px;
  line-height: 27px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase
}

h5,
.h5,
h5 a,
body.qode-overridden-elementors-fonts .elementor-widget-heading h5.elementor-heading-title,
.q_icon_with_title .icon_text_holder h5.icon_title {
  color: #2b2927;
  font-family: josefin sans, sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase
}

h6,
.h6,
h6 a,
body.qode-overridden-elementors-fonts .elementor-widget-heading h6.elementor-heading-title {
  color: #212121;
  font-family: josefin sans, sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase
}

p,
body.qode-overridden-elementors-fonts .elementor-widget-heading p.elementor-heading-title {
  color: #797979;
  font-family: open sans, sans-serif;
  font-size: 15px;
  line-height: 27px;
  font-style: normal;
  font-weight: 400;
  text-align: justify;
}

.filter_holder ul li span,
blockquote h5,
.q_social_icon_holder .simple_social,
.header-widget.widget_nav_menu ul.menu li a,
.side_menu a,
.side_menu li,
.side_menu span,
.side_menu p,
.side_menu .widget.widget_rss li a.rsswidget,
.side_menu #wp-calendar caption,
.side_menu #wp-calendar th,
.side_menu #wp-calendar td,
.widget #lang_sel_list li a,
.widget #lang_sel li a,
.widget #lang_sel_click li a,
.wpb_widgetised_column .widget #lang_sel_list li a,
.wpb_widgetised_column .widget #lang_sel li a,
.wpb_widgetised_column .widget #lang_sel_click li a,
.elementor-widget.elementor-widget-sidebar .widget #lang_sel_list li a,
.elementor-widget.elementor-widget-sidebar .widget #lang_sel li a,
.elementor-widget.elementor-widget-sidebar .widget #lang_sel_click li a,
section.side_menu #lang_sel_list li a,
section.side_menu #lang_sel li a,
section.side_menu #lang_sel_click li a,
footer #lang_sel_list li a,
footer #lang_sel li a,
footer #lang_sel_click li a,
footer #lang_sel_list.lang_sel_list_horizontal a,
footer #lang_sel_list.lang_sel_list_vertical a,
.side_menu #lang_sel_list.lang_sel_list_horizontal a,
.side_menu #lang_sel_list.lang_sel_list_vertical a,
#lang_sel_footer a {
  color: #797979;
}

.header_top #lang_sel>ul>li>a,
.header_top #lang_sel_click>ul>li>a,
footer #lang_sel ul li a,
footer #lang_sel ul ul a,
footer #lang_sel_click ul li a,
footer #lang_sel_click ul ul a,
footer #lang_sel_click ul ul a span,
section.side_menu #lang_sel ul li a,
section.side_menu #lang_sel ul ul a,
section.side_menu #lang_sel ul ul a:visited,
section.side_menu #lang_sel_click>ul>li>a,
section.side_menu #lang_sel_click ul ul a,
section.side_menu #lang_sel_click ul ul a:visited {
  color: #797979 !important
}

.woocommerce del,
.woocommerce-page del,
.woocommerce input[type=text]:not(.qode_search_field),
.woocommerce input[type=password],
.woocommerce input[type=email],
.woocommerce-page input[type=text]:not(.qode_search_field),
.woocommerce-page input[type=password],
.woocommerce-page input[type=email],
.woocommerce-page input[type=tel],
.woocommerce textarea,
.woocommerce-page textarea,
.woocommerce .select2-container .select2-choice,
.woocommerce-page .select2-container .select2-choice,
.woocommerce .select2-dropdown-open.select2-drop-above .select2-choice,
.woocommerce .select2-dropdown-open.select2-drop-above .select2-choices,
.woocommerce-page .select2-dropdown-open.select2-drop-above .select2-choice,
.woocommerce-page .select2-dropdown-open.select2-drop-above .select2-choices,
.woocommerce .chosen-container.chosen-container-single .chosen-single,
.woocommerce-page .chosen-container.chosen-container-single .chosen-single,
.woocommerce-checkout .form-row .chosen-container-single .chosen-single,
.woocommerce ul.products li.product h4,
.woocommerce div.product p[itemprop=price] del,
.woocommerce div.product p[itemprop=price] del span.amount,
.woocommerce div.product div.product_meta>span span,
.woocommerce div.product div.product_meta>span a,
.woocommerce aside ul.product_list_widget li>a,
.woocommerce aside ul.product-categories li>a,
.woocommerce aside ul.product_list_widget li del span.amount,
.wpb_widgetised_column ul.product_list_widget li>a,
.wpb_widgetised_column ul.product-categories li>a,
.wpb_widgetised_column ul.product_list_widget li del span.amount,
.elementor-widget.elementor-widget-sidebar ul.product_list_widget li>a,
.elementor-widget.elementor-widget-sidebar ul.product-categories li>a,
.elementor-widget.elementor-widget-sidebar ul.product_list_widget li del span.amount,
.shopping_cart_dropdown ul li a,
.select2-drop {
  color: #797979;
}

a:hover,
p a:hover,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.q_tabs .tabs-nav li a:hover,
.q_icon_with_title .icon_with_title_link:hover,
.blog_holder article .post_description a:hover,
.blog_holder.masonry article .post_info a:hover,
.portfolio_social_holder a:hover,
.latest_post_inner .post_infos a:hover {
  color: #b26b53
}

.q_accordion_holder.accordion.boxed .ui-accordion-header {
  background-color: #f1f1f1;
  color: #000
}

.q_accordion_holder.accordion.boxed .ui-accordion-header:hover {
  background-color: #f1f1f1;
  color: #000 !important;
}

.google_map {
  height: 750px;
}

.vertical_menu_float .menu-item .second {
  left: calc(100% + 30px);
}

.vertical_menu_hidden aside.vertical_menu_area .vertical_menu_float .menu-item .second {
  left: calc(100% + 40px);
}

aside.vertical_menu_area {
  background-color: #0a3639;
}

aside .vertical_menu_area_widget_holder,
aside .vertical_menu_area_widget_holder p,
aside .vertical_menu_area_widget_holder span {
  /* color: #7b7b7b; */
  color: #fff;
}

.vertical_menu_area {
  text-align: center;
}

nav.vertical_menu>ul>li>a {
  color: #d8b80c;
  font-family: josefin sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  text-transform: uppercase;
}

nav.vertical_menu>ul>li>a.current>span,
nav.mobile_menu>ul>li>a.current>span {
  text-decoration: underline;
}

nav.vertical_menu>ul>li>a:hover {
  zoom: 1.3;
  opacity: 0.7;
}

nav.vertical_menu_toggle ul>li.menu-item-has-children>a>.plus,
nav.vertical_menu_on_click ul>li.menu-item-has-children>a>.plus,
nav.vertical_menu_float ul>li.menu-item-has-children>a>.plus {
  margin-top: 18px;
}

.vertical_menu .second .inner>ul>li>a,
.vertical_menu .wide .second .inner>ul>li>a {
  color: #7b7b7b;
  font-family: open sans, sans-serif !important;
  font-size: 15px;
  line-height: 26px;
  font-style: normal;
  font-weight: 300;
  text-transform: none;
}

nav.vertical_menu_toggle ul li ul>li.menu-item-has-children>a>.plus,
nav.vertical_menu_on_click ul li ul>li.menu-item-has-children>a>.plus,
nav.vertical_menu_float ul li ul>li.menu-item-has-children>a>.plus {
  margin-top: 9px;
}

.filter_holder ul li span {
  color: #212121;
  font-family: Josefin Sans;
}

.filter_holder ul li.active span,
.filter_holder ul li:hover span {
  color: #2b2927 !important;
}

.projects_holder article span.text_holder,
.projects_masonry_holder .text_holder {
  background-color: #fff;
}

.projects_holder article .portfolio_description .portfolio_title,
.projects_holder article .portfolio_description .portfolio_title a {
  font-family: Josefin Sans;
  font-size: 17px;
  line-height: 27px;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}

.projects_holder article .portfolio_description .portfolio_title a {
  color: #2b2927;
}

.projects_holder article .portfolio_description .project_category {
  color: #868686;
  font-family: EB Garamond;
  font-size: 18px;
  line-height: 28px;
  font-style: italic;
  text-transform: none;
}

.cf7_custom_style_1 input.wpcf7-form-control.wpcf7-submit,
.cf7_custom_style_1 input.wpcf7-form-control.wpcf7-submit:not([disabled]) {
  background-color: #b26b53;
  border-color: #b26b53;
  border-width: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  color: #fff;
  font-family: EB Garamond;
  font-size: 17px;
  font-style: italic;
  font-weight: 500;
  text-transform: none;
  height: 54px;
  line-height: 54px;
  padding: 0 47px;
}

.cf7_custom_style_1 input.wpcf7-form-control.wpcf7-submit:hover,
.cf7_custom_style_1 input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover {
  color: #b26b53;
  background-color: #fff;
  border-color: #b26b53;
}

.cf7_custom_style_1 input.wpcf7-form-control.wpcf7-text,
.cf7_custom_style_1 input.wpcf7-form-control.wpcf7-number,
.cf7_custom_style_1 input.wpcf7-form-control.wpcf7-date,
.cf7_custom_style_1 textarea.wpcf7-form-control.wpcf7-textarea,
.cf7_custom_style_1 select.wpcf7-form-control.wpcf7-select,
.cf7_custom_style_1 input.wpcf7-form-control.wpcf7-quiz {
  border-color: #e1e1e1;
  border-width: 1px;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  color: #868686;
  font-family: EB Garamond;
  font-size: 17px;
  line-height: 22px;
  font-style: italic;
  padding-bottom: 5px;
  margin-top: 0;
  margin-bottom: 16px;
}

.cf7_custom_style_1 ::-webkit-input-placeholder {
  color: #868686;
  opacity: 1;
}

.cf7_custom_style_1 :-moz-placeholder {
  color: #868686;
  opacity: 1;
}

.cf7_custom_style_1 ::-moz-placeholder {
  color: #868686;
  opacity: 1;
}

.cf7_custom_style_1 :-ms-input-placeholde {
  color: #868686;
  opacity: 1;
}

.cf7_custom_style_1 textarea.wpcf7-form-control.wpcf7-textarea {
  height: 120px;
}

.woocommerce ul.products li.product h6,
.qode_product_list_holder .product_title {
  font-family: Josefin Sans, sans-serif;
  font-size: 12px;
  line-height: 22px;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  color: #212121;
  text-align: left;
  padding: 0;
}

.woocommerce ul.products li.product:hover h6 {
  color: #212121 !important;
}

.woocommerce-page ul.products li.product .product-categories,
.woocommerce ul.products li.product .product-categories {
  text-align: left;
}

.product-category .after-title-spearator {
  margin-left: 0;
}

.woocommerce ul.products li.product .price,
.woocommerce ul.products li.product .price ins,
.qode_product_list_holder .product_price {
  color: #212121 !important;
  font-size: 18px;
  line-height: 28px;
  text-transform: none;
  font-family: EB Garamond, sans-serif;
  font-style: italic;
  font-weight: 400;
  text-align: left;
}

.woocommerce aside ul.product_list_widget li span.amount,
aside ul.product_list_widget li span.amount,
.wpb_widgetised_column ul.product_list_widget li span.amount,
.elementor-widget.elementor-widget-sidebar ul.product_list_widget li span.amount {
  color: #212121 !important;
}

.woocommerce li.product del,
.woocommerce li.product del .amount {
  font-size: 18px;
  color: #797979;
}

.woocommerce .product .onsale:not(.out-of-stock-button),
.woocommerce .product .single-onsale {
  color: #b26b53;
  font-size: 16px;
  text-transform: none;
  font-family: EB Garamond, sans-serif;
  font-style: italic;
  font-weight: 500;
  background-color: transparent;
  border-radius: 46px;
  -webkit-border-radius: 46px;
  -moz-border-radius: 46px;
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.woocommerce .product .onsale-inner:after {
  display: none;
}

.woocommerce .product .onsale.out-of-stock-button {
  font-family: EB Garamond, sans-serif;
}

.woocommerce .qbutton.add-to-cart-button,
.woocommerce .single_add_to_cart_button,
.woocommerce .woocommerce-message a.button,
.woocommerce ul.products li.product .added_to_cart {
  color: #fff;
  font-size: 18px;
  text-transform: none;
  font-family: EB Garamond;
  font-style: italic;
  font-weight: 400;
  background-color: #b26b53;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-color: #b26b53;
  border-width: 1px;
}

.woocommerce ul.products li.product a.qbutton:hover,
.woocommerce .single_add_to_cart_button:hover,
.woocommerce .woocommerce-message a.button:hover,
.woocommerce ul.products li.product .added_to_cart:hover {
  background-color: RGBA(178, 107, 83, 0);
  color: #b26b53;
  border-color: #b26b53;
}

.woocommerce .product h1.product_title {
  font-family: Josefin Sans, sans-serif;
  font-size: 27px;
  line-height: 37px;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  color: #212121;
  margin-bottom: 0;
}

.woocommerce div.product .summary p.price,
.woocommerce div.product .summary p.price span.amount {
  color: #212121;
  font-size: 18px;
  line-height: 28px;
  text-transform: none;
  font-family: EB Garamond, sans-serif;
  font-style: italic;
  font-weight: 400;
}

.woocommerce div.product .summary p.price del,
.woocommerce div.product .summary p.price del span.amount {
  font-size: 18px;
  color: #797979;
}

.woocommerce .quantity .minus,
.woocommerce #content .quantity .minus,
.woocommerce-page .quantity .minus,
.woocommerce-page #content .quantity .minus,
.woocommerce .quantity .plus,
.woocommerce #content .quantity .plus,
.woocommerce-page .quantity .plus,
.woocommerce-page #content .quantity .plus {
  background-color: #fff;
  color: #bcbcbc;
}

.woocommerce .quantity .minus:hover,
.woocommerce #content .quantity .minus:hover,
.woocommerce-page .quantity .minus:hover,
.woocommerce-page #content .quantity .minus:hover,
.woocommerce .quantity .plus:hover,
.woocommerce #content .quantity .plus:hover,
.woocommerce-page .quantity .plus:hover,
.woocommerce-page #content .quantity .plus:hover {
  background-color: #fff;
  color: #bcbcbc;
}

.qode_product_list_holder .product_category a {
  font-family: Josefin Sans, sans-serif;
}

.qode_product_list_holder .product_category a:hover {
  color: #797979;
}

#back_to_top span i,
#back_to_top span span {
  color: #b26b53;
}

#back_to_top:hover span i,
#back_to_top:hover span span {
  color: #fff;
}

#back_to_top>span {
  border-color: #b26b53;
  border-width: 1px;
  border-style: solid;
}

#back_to_top:hover>span {
  background-color: #b26b53;
  border-color: #b26b53;
}

.qode-pl-holder .qode-prl-loading .qode-prl-loading-msg,
.qode-pl-holder .qode-pl-categories ul li a:hover,
.qode-pl-holder .qode-pl-categories ul li a.active,
.qode-pl-holder .qode-pli .qode-pli-rating,
.qode-pl-holder.qode-info-on-image:not(.qode-product-info-light) .qode-pli-category,
.qode-pl-holder.qode-info-on-image:not(.qode-product-info-light) .qode-pli-excerpt,
.qode-pl-holder.qode-info-on-image:not(.qode-product-info-light) .qode-pli-price,
.qode-pl-holder.qode-info-on-image:not(.qode-product-info-light) .qode-pli-rating,
.qode-pl-holder.qode-info-below-image .qode-pli .qode-pli-text-wrapper .qode-pli-add-to-cart a:hover {
  color: #2b2927;
}

.qbutton,
.qbutton.medium,
#submit_comment,
.load_more a,
.blog_load_more_button a,
.post-password-form input[type=submit],
input.wpcf7-form-control.wpcf7-submit,
input.wpcf7-form-control.wpcf7-submit:not([disabled]),
.woocommerce table.cart td.actions input[type=submit],
.woocommerce input#place_order,
.woocommerce-page input[type=submit],
.woocommerce .button {
  color: #fff;
  font-family: eb garamond, sans-serif;
  border-color: #b26b53;
  font-size: 18px;
  line-height: 50px;
  height: 50px;
  font-style: italic;
  font-weight: 400;
  background-color: #b26b53;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  text-transform: none;
  border-width: 1px;
  padding-left: 50px;
  padding-right: 50px
}

.qode-qbutton-main-color {
  font-family: eb garamond, sans-serif;
  font-size: 18px;
  line-height: 50px;
  height: 50px;
  font-style: italic;
  font-weight: 400;
  text-transform: none;
}

.qbutton:hover,
.qbutton.medium:hover,
#submit_comment:hover,
.load_more a:hover,
.blog_load_more_button a:hover,
.post-password-form input[type=submit]:hover,
input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover,
.woocommerce table.cart td.actions input[type=submit]:hover,
.woocommerce input#place_order:hover,
.woocommerce-page input[type=submit]:hover,
.woocommerce .button:hover {
  color: #b26b53;
  border-color: #b26b53;
}

/* .qbutton:hover,
#submit_comment:hover,
.load_more a:hover,
.blog_load_more_button a:hover,
.post-password-form input[type=submit]:hover,
input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover,
.woocommerce table.cart td.actions input[type=submit]:hover,
.woocommerce input#place_order:hover,
.woocommerce-page input[type=submit]:hover,
.woocommerce .button:hover {
  background-color: #fff;
} */

.masonry_gallery_item.square_big .masonry_gallery_item_inner .masonry_gallery_item_content {
  text-align: center;
}

.masonry_gallery_item.square_small .masonry_gallery_item_inner .masonry_gallery_item_content {
  text-align: center;
}

.masonry_gallery_item.rectangle_portrait .masonry_gallery_item_inner .masonry_gallery_item_content {
  text-align: center;
}

.masonry_gallery_item.rectangle_landscape .masonry_gallery_item_inner .masonry_gallery_item_content {
  text-align: center;
}

.qode-accordion-holder .qode-title-holder {
  text-transform: none;
  font-style: normal;
  font-weight: 600;
}

.qode-btn {
  text-transform: none;
  font-style: normal;
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title,
.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title-aux {
  color: #2b2927;
  -webkit-text-stroke-color: #2b2927;
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-category-holder a {
  color: #2b2927;
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-item-number-holder span {
  color: #2b2927;
  border-color: rgba(43, 41, 39, .2);
}

.latest_post_holder.image_on_the_left_boxed .date_hour_holder,
.latest_post_holder.image_on_the_left_boxed .featured .read_more:before {
  background-color: #2b2927;
}

.qode-portfolio-carousel .qode-pc-custom-cursor {
  color: #2b2927;
  border-color: #2b2927;
}

#qode-portfolio-list-stacked .qode-pls-end-of-scroll .qode-eos-title * {
  color: #2b2927;
  -webkit-text-stroke-color: #2b2927;
}

#qode-pls-info .qode-pls-title-holder .qode-pls-title a {
  color: #2b2927;
}

#qode-portfolio-list-stacked .qode-pls-scroll-note .qode-pls-down svg path {
  fill: #2b2927;
}
