@charset "UTF-8";

@font-face {
  font-family: star;
  /* src: url(../../../plugins/woocommerce/assets/fonts/star.eot);
  src: url(../../../plugins/woocommerce/assets/fonts/star.eot?#iefix) format("embedded-opentype"),url(../../../plugins/woocommerce/assets/fonts/star.woff) format("woff"),url(../../../plugins/woocommerce/assets/fonts/star.ttf) format("truetype"),url(../../../plugins/woocommerce/assets/fonts/star.svg#star) format("svg"); */
  font-weight: 400;
  font-style: normal
}

.woocommerce .content .container .container_inner,
.woocommerce-page .content .container .container_inner {
  padding: 50px 0 30px
}

.woocommerce .content .title .container .container_inner,
.woocommerce-page .content .title .container .container_inner {
  padding: 0
}

.woocommerce-pagination {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 0 0 40px;
  clear: both
}

.woocommerce table {
  text-align: left
}

.woocommerce table tr td {
  background: 0 0;
  color: #888
}

.woocommerce table th {
  background: 0 0;
  text-align: left
}

.woocommerce table td {
  text-align: left
}

ul.woocommerce-error {
  list-style: none;
  margin-bottom: 10px
}

.myaccount_user,
.woocommerce .woocommerce-error,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-message,
.woocommerce div.message {
  margin-bottom: 25px;
  position: relative;
  display: block;
  padding: 28px 21px;
  background-color: #fff;
  line-height: 38px;
  font-weight: 700;
  font-family: Raleway, sans-serif;
  color: #303030;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 17px
}

.myaccount_user a {
  color: #1abc9c
}

.woocommerce .woocommerce-error h3,
.woocommerce .woocommerce-info h3,
.woocommerce .woocommerce-message h3,
.woocommerce div.message h3 {
  font-size: 20px
}

.woocommerce-checkout .woocommerce-error,
.woocommerce-checkout .woocommerce-info,
.woocommerce-checkout .woocommerce-message {
  font-size: 13px
}

.woocommerce .woocommerce-message a.button {
  float: right
}

.woocommerce .woocommerce-message a.button i {
  margin-left: 10px
}

.woocommerce .woocommerce-message h3 {
  display: inline-block;
  float: left;
  padding: 5px 0 0
}

ul.woocommerce-error li {
  margin-bottom: 10px
}

ul.woocommerce-error li:last-child {
  margin-bottom: 0
}

.woocommerce del,
.woocommerce-page del {
  color: #777
}

.woocommerce li.product del,
.woocommerce-page li.product del {
  line-height: 20px
}

.woocommerce li.product del .amount,
.woocommerce-page li.product del .amount {
  font-size: 14px;
  margin-right: 5px
}

.woocommerce del .amount,
.woocommerce-page del .amount {
  margin-right: 10px;
  font-size: 17px
}

.woocommerce ul.woocommerce-error {
  list-style: none
}

.woocommerce p.form-row {
  margin-bottom: 20px
}

.woocommerce p.form-row label {
  display: block;
  margin-bottom: 3px
}

.woo-my-account-rememberme {
  margin: 20px 0 !important
}

.woocommerce input[type=text]:not(.qode_search_field),
.woocommerce input[type=password],
.woocommerce input[type=email],
.woocommerce textarea,
.woocommerce-page input[type=tel],
.woocommerce-page input[type=text]:not(.qode_search_field),
.woocommerce-page input[type=password],
.woocommerce-page input[type=email],
.woocommerce-page textarea {
  padding: 15px 13px;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 13px;
  line-height: 17px;
  background-color: #fff;
  color: #818181;
  font-family: Raleway, sans-serif;
  font-weight: 400
}

.woocommerce-account input[type=text],
.woocommerce-account input[type=password],
.woocommerce-account input[type=email] {
  width: 97%
}

.woocommerce-account .chosen-container {
  width: 100% !important
}

.single_add_to_cart_button.button {
  height: 38px
}

.woocommerce .button,
.woocommerce input[type=submit],
.woocommerce ul.products li.product .added_to_cart,
.woocommerce-page .button,
.woocommerce-page input[type=submit] {
  position: relative;
  display: inline-block;
  width: auto;
  height: 38px;
  line-height: 38px;
  margin: 0;
  padding: 0 23px;
  border: 2px solid #303030;
  font-size: 13px;
  text-align: left;
  color: #303030;
  font-family: Raleway, sans-serif;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  background-color: transparent;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -ms-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear
}

.woocommerce button.button,
.woocommerce-page button.button,
.woocommerce-page input[type=submit] {
  line-height: 35px
}

.woocommerce-message.call_to_action .qbutton {
  float: right
}

.woocommerce #submit:hover,
.woocommerce .button:hover,
.woocommerce ul.products li.product .added_to_cart:hover,
.woocommerce ul.products li.product a.qbutton:hover,
.woocommerce-page .button:hover,
.woocommerce-page ul.products li.product a.qbutton:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff;
  text-decoration: none
}

.woocommerce ul.products li.product .add-to-cart-button-outer {
  display: inline-block;
  margin-left: 50%;
  position: absolute;
  top: 50%;
  width: 100%
}

.woocommerce ul.products li.product .add-to-cart-button-inner {
  display: inline-block;
  position: relative
}

.woocommerce ul.products li.product .add-to-cart-button,
.woocommerce ul.products li.product .added_to_cart,
.woocommerce ul.products li.product a.qbutton.out-of-stock-button {
  display: inline-block;
  margin-left: -50%;
  margin-top: -22px;
  opacity: 0;
  padding: 0 17px;
  position: relative;
  vertical-align: middle;
  visibility: hidden;
  z-index: 200
}

.woocommerce ul.products li.product .top-product-section {
  position: relative;
  text-align: left
}

.woocommerce ul.products li.product .image-wrapper {
  position: relative;
  display: block;
  overflow: hidden
}

.woocommerce ul.products li.product .image-wrapper img {
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .2s ease-out;
  -o-transition: opacity .3s ease-out;
  -ms-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  display: block;
  margin: 0 auto
}

.woocommerce ul.products li.product:hover .image-wrapper img {
  opacity: .2
}

.woocommerce ul.products li.product:hover .add-to-cart-button,
.woocommerce ul.products li.product:hover .added_to_cart,
.woocommerce ul.products li.product:hover a.qbutton.out-of-stock-button {
  opacity: 1;
  visibility: visible
}

div.pp_woocommerce .pp_content_container {
  border: 1px solid #ddd;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important
}

.woocommerce .select2-container.select2-dropdown-open,
.woocommerce-page .select2-container.select2-dropdown-open {
  border-radius: 2px 2px 0 0
}

.woocommerce .select2-dropdown-open,
.woocommerce-page .select2-dropdown-open {
  background: 0 0
}

.woocommerce .select2-container .select2-choice,
.woocommerce .select2-dropdown-open.select2-drop-above .select2-choice,
.woocommerce .select2-dropdown-open.select2-drop-above .select2-choices,
.woocommerce-page .select2-container .select2-choice,
.woocommerce-page .select2-dropdown-open.select2-drop-above .select2-choice,
.woocommerce-page .select2-dropdown-open.select2-drop-above .select2-choices {
  background-color: #fff;
  color: #818181;
  height: 47px;
  line-height: 47px;
  padding-left: 1.568265682656827%;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  font-size: 13px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none
}

.woocommerce .select2-results .select2-result-label,
.woocommerce-page .select2-results .select2-result-label {
  padding-left: 12px
}

.woocommerce .select2-container .select2-choice .select2-arrow,
.woocommerce-page .select2-container .select2-choice .select2-arrow {
  width: 47px;
  height: 47px;
  background: 0 0 !important;
  border-left: none
}

.woocommerce .select2-container .select2-choice .select2-arrow b,
.woocommerce-page .select2-container .select2-choice .select2-arrow b {
  position: relative
}

.woocommerce .select2-container .select2-choice .select2-arrow .select2-arrow:after,
.woocommerce-page .select2-container .select2-choice .select2-arrow:after {
  color: #e5e5e5;
  content: "";
  font-family: FontAwesome, serif;
  font-size: 13px;
  height: 100%;
  line-height: 47px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%
}

.woocommerce .select2-results li.select2-highlighted,
.woocommerce-page .select2-results li.select2-highlighted {
  background: 0 0;
  color: #1abc9c
}

.woocommerce .select2-drop .select2-search input[type=text],
.woocommerce .select2-drop.select2-drop-above .select2-search input[type=text],
.woocommerce-page .select2-drop input[type=text],
.woocommerce-page .select2-drop.select2-drop-above .select2-search input[type=text] {
  padding: 5px;
  background-color: #fff;
  border: 1px solid #eaeaea
}

.woocommerce .chosen-container.chosen-container-single .chosen-single,
.woocommerce-checkout .chosen-container.chosen-container-single .chosen-single,
.woocommerce-page .chosen-container.chosen-container-single .chosen-single {
  background: #fff;
  border: none;
  padding-left: 2.5%;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  font-family: inherit;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 47px !important;
  line-height: 47px !important;
  font-size: 13px;
  font-weight: 400;
  color: #818181
}

.woocommerce-account .form-row .chosen-container-single .chosen-single div b,
.woocommerce-checkout .form-row #billing_country_chzn.chzn-container-single .chzn-single div b,
.woocommerce-checkout .form-row #shipping_country_chzn.chzn-container-single .chzn-single div b,
.woocommerce-checkout .form-row .chosen-container-single .chosen-single div b {
  background: 0 0 !important;
  width: 47px;
  height: 47px;
  position: relative;
  text-align: center
}

.woocommerce-account .form-row .chosen-container-single .chosen-single div b:before,
.woocommerce-checkout .form-row #billing_country_chzn.chzn-container-single .chzn-single div b:before,
.woocommerce-checkout .form-row #shipping_country_chzn.chzn-container-single .chzn-single div b:before,
.woocommerce-checkout .form-row .chosen-container-single .chosen-single div b:before {
  content: "\f107";
  font-family: fontawesome;
  color: #e5e5e5;
  font-size: 13px
}

.woocommerce-account .form-row .chosen-container-active .chosen-single-with-drop div,
.woocommerce-account .form-row .chosen-container-single .chosen-single div,
.woocommerce-checkout .form-row .chosen-container-active .chosen-single-with-drop div,
.woocommerce-checkout .form-row .chosen-container-single .chosen-single div {
  width: 47px;
  margin-right: 0
}

.woocommerce-account .form-row .chosen-container .chosen-drop,
.woocommerce-checkout .form-row .chosen-container .chosen-drop {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  background-color: #fff
}

.woocommerce-account .chosen-container .chosen-results li,
.woocommerce-checkout .chosen-container .chosen-results li {
  padding: 12px 0 10px 10px
}

.woocommerce-account .chosen-container .chosen-results li.active-result.highlighted,
.woocommerce-checkout .chosen-container .chosen-results li.active-result.highlighted {
  background: 0 0;
  color: #1abc9c
}

.woocommerce-account .form-row .chosen-container-single .chosen-search input,
.woocommerce-checkout .form-row .chosen-container-single .chosen-search input {
  padding-left: 7px !important;
  padding-top: 7px;
  padding-bottom: 7px;
  background: 0 0 !important;
  border: 1px solid #eaeaea;
  border-radius: 4px
}

.woocommerce ins,
.woocommerce-page ins {
  text-decoration: none;
  color: #1abc9c
}

.woocommerce div.pp_woocommerce .pp_arrow_next,
.woocommerce div.pp_woocommerce .pp_arrow_previous,
.woocommerce-page div.pp_woocommerce .pp_arrow_next,
.woocommerce-page div.pp_woocommerce .pp_arrow_previous {
  margin-top: 2px !important
}

.woocommerce .woocommerce-ordering,
.woocommerce .woocommerce-result-count {
  width: 50%;
  height: 80px
}

.woocommerce .woocommerce-result-count {
  float: right;
  text-align: right;
  line-height: 55px;
  height: 60px;
  margin-bottom: 0;
  font-family: inherit
}

.woocommerce .woocommerce-ordering .orderby {
  width: auto;
  min-width: 200px
}

.woocommerce .select2-container.orderby .select2-choice,
.woocommerce .select2-dropdown-open.select2-drop-above.orderby .select2-choice,
.woocommerce .select2-dropdown-open.select2-drop-above.orderby .select2-choices,
.woocommerce-page .select2-container.orderby .select2-choice,
.woocommerce-page .select2-dropdown-open.select2-drop-above.orderby .select2-choice,
.woocommerce-page .select2-dropdown-open.select2-drop-above.orderby .select2-choices {
  padding-left: 16px;
  line-height: 47px;
  height: 47px;
  background-image: none
}

.woocommerce .select2-container .select2-choice>.select2-chosen {
  font-weight: 400
}

.woocommerce ul.products {
  list-style: none;
  margin: 0 0 18px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.woocommerce ul.products li.product {
  margin-bottom: 24px;
}

.woocommerce .content .container .container_inner,.woocommerce-page .content .container .container_inner {
  padding: 50px 0 30px;
}

@media only screen and (min-width: 769px) {

  .container_inner>ul.products li.product:nth-child(3n+1),
  .cross-sells>ul.products li.product:nth-child(3n+1),
  .products>ul.products li.product:nth-child(3n+1),
  .woocommerce_with_sidebar ul.products li.product:nth-child(2n+1),
  .container_inner>ul.products li.product:nth-child(4n+1),
  .cross-sells>ul.products li.product:nth-child(4n+1),
  .products>ul.products li.product:nth-child(4n+1),
  .woocommerce_with_sidebar ul.products li.product:nth-child(3n+1),
  ul.products li.product:nth-child(3n+1),
  ul.products li.product:nth-child(4n+1) {
    clear: both;
  }

  ul.products.column-4 li.product {
    width: 31.81818181818182%;
    margin-right: 2.272727272727273%;
  }

  .container_inner>ul.products li.product:nth-child(3n),
  .cross-sells>ul.products li.product:nth-child(3n),
  .products>ul.products li.product:nth-child(4n),
  .woocommerce_with_sidebar ul.products li.product:nth-child(2n),
  .container_inner>ul.products li.product:nth-child(4n),
  .cross-sells>ul.products li.product:nth-child(4n),
  .products>ul.products li.product:nth-child(4n),
  .woocommerce_with_sidebar ul.products li.product:nth-child(3n),
  ul.products li.product:nth-child(3n),
  ul.products li.product:nth-child(4n) {
    margin-right: 0;
  }

  ul.products li.product {
    width: 31.81818181818182%;
    margin-right: 2.272727272727273%;
  }

  ul.products.column-4 li.product {
    width: 23.29545454545455%;
    margin-right: 2.272727272727273%;
  }

  .woocommerce_with_sidebar ul.products li.product {
    width: 48.89%;
    margin-right: 2.22%;
  }
}

@media only screen and (min-width: 600px) {

  .column-2 .container_inner>ul.products li.product:nth-child(even),
  .column-2 .cross-sells>ul.products li.product:nth-child(even),
  .column-2 .products>ul.products li.product:nth-child(even),
  .column-2 .woocommerce_with_sidebar ul.products li.product:nth-child(even),
  .column-2 ul.products li.product:nth-child(even) {
    margin-right: 0;
  }

  .column-2 .container_inner>ul.products li.product:nth-child(2n+1),
  .column-2 .cross-sells>ul.products li.product:nth-child(2n+1),
  .column-2 .products>ul.products li.product:nth-child(2n+1),
  .column-2 .woocommerce_with_sidebar ul.products li.product:nth-child(2n+1),
  .column-2 ul.products li.product:nth-child(2n+1) {
    clear: both;
  }
}

.woocommerce ul.products li.product,
.woocommerce ul.products li.product a.product-category,
.woocommerce-page ul.products li.product a.product-category {
  display: block;
  height: 100%;
  background-color: #fff
}

.woocommerce ul.products li.product .product-categories,
.woocommerce-page ul.products li.product .product-categories {
  margin-top: 22px;
  margin-bottom: -4px;
  text-align: center
}

.woocommerce ul.products li.product .product-categories a,
.woocommerce-page ul.products li.product .product-categories a {
  font-size: 12px
}

.woocommerce ul.products li.product .product-categories a:not(:hover),
.woocommerce-page ul.products li.product .product-categories a:not(:hover) {
  color: #777
}

.woocommerce ul.products li.product a.product-category.product-info,
.woocommerce-page ul.products li.product a.product-category.product-info {
  padding-top: 8px
}

.woocommerce .product .onsale,
.woocommerce .product .single-onsale {
  position: absolute;
  top: 15px;
  width: 4.533333333333333em;
  height: 4.533333333333333em;
  line-height: 4.533333333333333em;
  text-align: center;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Raleway, sans-serif;
  font-weight: 700;
  background-color: #303030;
  color: #fff;
  -webkit-border-radius: 500em;
  -moz-border-radius: 500em;
  -ms-border-radius: 500em;
  -o-border-radius: 500em;
  border-radius: 500em;
  z-index: 100;
}

.woocommerce .product .onsale-inner {
  position: relative;
}

.woocommerce .product .onsale-inner:after {
  position: absolute;
  content: '';
  width: 13px;
  height: 2px;
  border-bottom: 2px solid;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
}

.woocommerce .product .onsale.out-of-stock-button {
  background-color: #f1c40f;
  font-size: 13px;
  width: 70px;
  height: 54px;
  padding-top: 16px;
  line-height: 1.5em;
}

.woocommerce ul.products li.product h4,
.woocommerce ul.products li.product h6 {
  padding: 0 15px;
  margin: 7px 0 0;
  text-align: center;
}

.woocommerce .product .onsale {
  left: 10px;
  top: 10px;
}

.woocommerce .product .single-onsale {
  left: 20px;
}

.woocommerce ul.products li.product h6 {
  font-weight: 700;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out;
}

.woocommerce ul.products li.product .price {
  display: block;
  margin: 0 0 16px;
  text-align: center;
  font-size: 14px;
  color: #777;
  font-family: Raleway, sans-serif;
  font-weight: 600;
}

.woocommerce ul.products li.product .add_to_cart_button.added {
  display: none;
}

.woocommerce ul.products li.product:hover h6 {
  color: #1abc9c;
}

.woocommerce-pagination ul.page-numbers {
  list-style: none;
  text-align: center;
  position: relative;
}

.woocommerce-pagination ul.page-numbers li {
  display: inline-block;
}

.woocommerce-pagination ul.page-numbers li a,
.woocommerce-pagination ul.page-numbers li span {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  margin: 0 11px 0 0;
  text-align: center;
  color: #b4b4b4;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border: 2px solid #e5e5e5;
  outline: 0;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.woocommerce-pagination ul.page-numbers li a:hover,
.woocommerce-pagination ul.page-numbers li span.current {
  color: #303030;
  background-color: #eee;
  border-color: #eee;
}

.woocommerce div.product {
  position: relative;
}

.woocommerce .product .images {
  float: left;
  width: 49.09090909090909%;
  margin-right: 1.818181818181818%;
  margin-bottom: 90px;
}

.woocommerce .product .images a {
  position: relative;
  display: block;
}

.woocommerce .product .images img {
  display: block;
}

.woocommerce .product .images figure {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 -10px;
}

.woocommerce .product .images .woocommerce-product-gallery__image {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.woocommerce .product .images .woocommerce-product-gallery__image:first-child {
  width: 100%;
}

.woocommerce .product .images.woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:not(:first-child) {
  float: left;
  padding-top: 20px;
}

.woocommerce .product .images.woocommerce-product-gallery--columns-2 .woocommerce-product-gallery__image:not(:first-child) {
  width: 50%;
}

.woocommerce .product .images.woocommerce-product-gallery--columns-3 .woocommerce-product-gallery__image:not(:first-child) {
  width: 33.33333%;
}

.woocommerce .product .images.woocommerce-product-gallery--columns-4 .woocommerce-product-gallery__image:not(:first-child) {
  width: 20%;
}

.woocommerce div.product .images img:first-child {
  max-width: 100%;
  width: 100%;
}

.woocommerce div.product .images .thumbnails {
  margin-top: 20px;
}

.woocommerce div.product .images .thumbnails a {
  display: inline-block;
  width: 31.06060606060606%;
  margin-right: 3.409090909090909%;
  margin-bottom: 15px;
}

.woocommerce div.product .images .thumbnails a:nth-child(3n) {
  margin-right: 0;
}

.woocommerce div.product .images .thumbnails a img {
  max-width: 100%;
}

.woocommerce .product h1.product_title {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
}

.woocommerce div.product .summary p.price,
.woocommerce div.product .summary p.price span.amount {
  padding-bottom: 30px;
}

.woocommerce div.product .summary p.price span.amount {
  font-size: 21px;
  color: #bcbcbc;
  font-family: Raleway, sans-serif;
  font-weight: 600;
}

.woocommerce div.product p[itemprop=price] del,
.woocommerce div.product p[itemprop=price] del span.amount {
  color: #777;
  font-size: 21px
}

.woocommerce div.product div[itemprop=offers] {
  margin-bottom: 8px
}

.woocommerce div.product div[itemprop=description] {
  margin: 8px 0
}

.woocommerce div.product .cart,
.woocommerce div.product .product_meta {
  margin: 25px 0
}

.woocommerce div.product .cart .quantity {
  float: left;
  margin-right: 19px;
  padding-top: 4px;
  height: 37px;
  line-height: 37px
}

.woocommerce #content .quantity .minus,
.woocommerce #content .quantity .plus,
.woocommerce .quantity .minus,
.woocommerce .quantity .plus,
.woocommerce-page #content .quantity .minus,
.woocommerce-page #content .quantity .plus,
.woocommerce-page .quantity .minus,
.woocommerce-page .quantity .plus {
  padding: 0;
  width: 17px;
  height: 17px;
  line-height: 17px;
  margin: 0;
  display: inline;
  font-size: 14px;
  border: 0;
  cursor: pointer;
  outline: 0;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  background-color: #bcbcbc;
  color: #fff;
  border-radius: 500px;
  font-family: "Times New Roman"
}

.woocommerce #content .quantity .minus:hover,
.woocommerce #content .quantity .plus:hover,
.woocommerce .quantity .minus:hover,
.woocommerce .quantity .plus:hover,
.woocommerce-page #content .quantity .minus:hover,
.woocommerce-page #content .quantity .plus:hover,
.woocommerce-page .quantity .minus:hover,
.woocommerce-page .quantity .plus:hover {
  background-color: #1abc9c
}

.woocommerce #content .quantity input.qty,
.woocommerce .quantity input.qty,
.woocommerce-page #content .quantity input.qty,
.woocommerce-page .quantity input.qty {
  border: none;
  padding: 0 5px;
  width: 22px;
  display: inline;
  text-align: center;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  color: #303030;
  font-size: 17px;
  background: 0 0;
}

.single_add_to_cart_button.qbutton.small {
  line-height: 29px;
}

.woocommerce div.product div.product_meta>.social_share_list_holder>span,
.woocommerce div.product div.product_meta>span {
  display: block;
  position: relative;
  color: #333;
  font-weight: 700;
  font-size: 14px;

  font-weight: 600;
  color: #212121;
  font-size: 12px;
  line-height: 24px;
  font-family: 'Josefin Sans', sans-serif;
}

.woocommerce div.product div.product_meta>.social_share_list_holder>span {
  display: inline-block;
  margin-right: 8px;
}

.woocommerce div.product div.product_meta>span a,
.woocommerce div.product div.product_meta>span span {
  font-weight: 300;
  color: #777;
  text-transform: none;
  margin-left: 3px;
  -webkit-transition: color .1s linear;
  -moz-transition: color .1s linear;
  -ms-transition: color .1s linear;
  -o-transition: color .1s linear;
  transition: color .1s linear;
  backface-visibility: hidden;
}

.woocommerce div.product div.product_meta>span a {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0;
}

.woocommerce div.product div.product_meta>span a:hover {
  color: #1abc9c
}

.woocommerce .summary {
  float: right;
  width: 49.09090909090909%;
  margin-bottom: 45px
}

.woocommerce .summary p.stock.out-of-stock {
  display: inline-block;
  font-weight: 700;
  color: #303030;
  padding-bottom: 1px;
  border-bottom: 2px solid;
  margin: 8px 0
}

.woocommerce div.product .summary table.variations {
  margin-bottom: 25px
}

.woocommerce .woocommerce-tabs.q_tabs .tabs-nav {
  display: block
}

.woocommerce div[itemprop=aggregateRating] .star-rating,
.woocommerce li.product div.star-rating {
  display: none
}

.woocommerce .woocommerce-tabs.q_tabs.horizontal .tabs-container {
  padding-top: 21px
}

.woocommerce .woocommerce-tabs #tab-additional_information .shop_attributes th {
  font-weight: 400
}

.woocommerce div.woocommerce-tabs h2 {
  font-size: 1.733333333333333em;
  font-weight: 200
}

.woocommerce table.shop_attributes th {
  text-transform: uppercase;
  font-weight: 700;
  color: #333
}

.woocommerce div.product div.related,
.woocommerce div.product div.upsells {
  clear: both
}

.woocommerce div.product div.related .qode-related-upsells-title,
.woocommerce div.product div.related h4,
.woocommerce div.product div.upsells .qode-related-upsells-title,
.woocommerce div.product div.upsells h4 {
  margin-bottom: 34px
}

.woocommerce p.stars span,
.woocommerce-page p.stars span {
  width: 80px;
  height: 16px;
  position: relative;
  float: left;
  /* background: url(img/star.png) left 0 repeat-x; */
}

.woocommerce p.stars span a.star-1,
.woocommerce-page p.stars span a.star-1 {
  width: 16px;
  z-index: 10
}

.woocommerce p.stars span a.star-2,
.woocommerce-page p.stars span a.star-2 {
  width: 32px;
  z-index: 9
}

.woocommerce p.stars span a.star-3,
.woocommerce-page p.stars span a.star-3 {
  width: 48px;
  z-index: 8
}

.woocommerce p.stars span a.star-4,
.woocommerce-page p.stars span a.star-4 {
  width: 64px;
  z-index: 7
}

.woocommerce p.stars span a.star-5,
.woocommerce-page p.stars span a.star-5 {
  width: 80px;
  z-index: 6
}

.woocommerce p.stars span a,
.woocommerce-page p.stars span a {
  float: left;
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 0;
  padding-top: 16px;
  overflow: hidden
}

.woocommerce p.stars span a:focus,
.woocommerce p.stars span a:hover,
.woocommerce-page p.stars span a:focus,
.woocommerce-page p.stars span a:hover {
  /* background: url(img/star.png) left -16px repeat-x */
}

.woocommerce p.stars span a.active,
.woocommerce-page p.stars span a.active {
  /* background: url(img/star.png) left -32px repeat-x */
}

.woocommerce p.stars {
  height: 16px
}

.woocommerce ol.commentlist {
  list-style: none
}

.woocommerce .star-rating,
.woocommerce-page .star-rating {
  float: right;
  overflow: hidden;
  position: relative;
  height: 1em;
  line-height: 1em;
  font-size: 1.1em;
  width: 5.4em;
  font-family: star;
  color: #f4d83a
}

.woocommerce .star-rating span,
.woocommerce-page .star-rating span {
  overflow: hidden;
  float: left;
  top: 0;
  left: 0;
  position: absolute;
  padding-top: 1.5em
}

.woocommerce .star-rating:before,
.woocommerce-page .star-rating:before {
  content: "\73\73\73\73\73";
  color: #dfdbdf;
  float: left;
  top: 0;
  left: 0;
  position: absolute
}

.woocommerce .star-rating span:before,
.woocommerce-page .star-rating span:before {
  content: "\53\53\53\53\53";
  top: 0;
  position: absolute;
  left: 0
}

.woocommerce .addresses:after,
.woocommerce .addresses:before,
.woocommerce form.checkout .col2-set:after,
.woocommerce form.checkout .col2-set:before {
  content: " ";
  display: table
}

.woocommerce ol.commentlist li.comment img.avatar {
  float: left;
  margin-right: 10px
}

.woocommerce #comments {
  margin-bottom: 13px
}

.woocommerce #respond #reply-title,
.woocommerce #reviews h2 {
  font-size: 13px;
  margin-bottom: .7em
}

.woocommerce #reviews .add_review {
  margin-top: 15px
}

.elementor-widget.elementor-widget-sidebar ul.product_list_widget li:after,
.elementor-widget.elementor-widget-sidebar ul.product_list_widget li:before,
.woocommerce .woocommerce-message:after,
.woocommerce .woocommerce-message:before,
.woocommerce aside ul.product_list_widget li:after,
.woocommerce aside ul.product_list_widget li:before,
.woocommerce ol.commentlist li:after,
.woocommerce ol.commentlist li:before,
.wpb_widgetised_column ul.product_list_widget li:after,
.wpb_widgetised_column ul.product_list_widget li:before,
aside ul.product_list_widget li:after,
aside ul.product_list_widget li:before {
  content: " ";
  display: table
}

.elementor-widget.elementor-widget-sidebar ul.product_list_widget li:after,
.woocommerce .woocommerce-message:after,
.woocommerce aside ul.product_list_widget li:after,
.woocommerce ol.commentlist li:after,
.wpb_widgetised_column ul.product_list_widget li:after,
aside ul.product_list_widget li:after {
  clear: both
}

.woocommerce ol.commentlist li {
  padding: 15px 15px 15px 0;
  border-bottom: 1px solid #e0dede
}

.woocommerce #comments .noreviews {
  padding: 0 15px
}

.woocommerce ol.commentlist li:first-child {
  padding-top: 0
}

.woocommerce div.summary div.comment-text div[itemprop=description] {
  margin: 0
}

.woocommerce #commentform input[type=text],
.woocommerce #commentform input[type=email],
.woocommerce #commentform textarea {
  display: block;
  padding-left: 2.6%;
  padding-right: 2.6%;
  width: 94.8%
}

.woocommerce .comment-form-comment label {
  margin-bottom: 5px;
  display: inline-block
}

.woocommerce .comment-form-rating {
  margin-bottom: 13px
}

.woocommerce .comment-form-rating label {
  margin-bottom: 5px;
  display: inline-block
}

.woocommerce .myaccount_user {
  margin-bottom: 20px;
  font-size: 13px;
  font-family: Raleway, sans-serif;
  font-weight: 400;
  line-height: 2em
}

.woocommerce .myaccount_address {
  margin: 20px 0
}

.woocommerce .addresses header {
  background: 0 0;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  height: auto;
  position: relative;
  text-align: left;
  padding-bottom: 6px;
  border-bottom: none;
  margin-bottom: 10px
}

.woocommerce .addresses address {
  font-style: normal
}

.woocommerce .addresses header a.edit {
  height: 27px;
  line-height: 27px;
  padding: 0 15px
}

.woocommerce .addresses .col-1.address,
.woocommerce .addresses .col-2.address {
  width: 45%
}

.woocommerce .addresses .col-1,
.woocommerce .addresses .col-2 {
  width: 23.61623616236162%;
  margin-right: 1.845018450184502%
}

.woocommerce .addresses header.title {
  position: static
}

.woocommerce .addresses .col-1.address {
  margin-right: 2.909090909090909%
}

.woocommerce .addresses .col-2.address {
  float: right
}

.woocommerce .addresses:after {
  clear: both
}

.woocommerce .addresses {
  margin-bottom: 70px
}

form#set-address-form input[type=text],
form#set-address-form select {
  width: 93%;
  padding-left: 3.288201160541586%;
  padding-right: 3.288201160541586%
}

form#set-address-form p.form-row-wide input[type=text] {
  width: 97.54716981132076%;
  padding-left: 1.226415094339623%;
  padding-right: 1.226415094339623%
}

form#set-address-form h3 {
  margin-bottom: 20px
}

.woocommerce form#set-address-form .form-row-first,
.woocommerce form#set-address-form .form-row-last,
.woocommerce-page form#set-address-form .form-row-first,
.woocommerce-page form#set-address-form .form-row-last {
  float: left;
  width: 47%;
  overflow: visible
}

.woocommerce form#set-address-form .form-row-last,
.woocommerce-page form#set-address-form .form-row-last {
  float: right
}

.woocommerce form#set-address-form {
  margin-bottom: 30px
}

.woocommerce-account table.my_account_orders {
  margin: 0 0 25px;
  width: 100% !important
}

.woocommerce div.cart-collaterals,
.woocommerce form.lost_reset_password p.form-row,
.woocommerce-page div.cart-collaterals {
  margin-top: 20px
}

.woocommerce-account table.my_account_orders tbody tr td.order-actions {
  text-align: right
}

.woocommerce-account table.my_account_orders tbody tr td.order-actions a {
  padding: 0 22px
}

.woocommerce form.lost_reset_password input[type=text] {
  width: 185px
}

.woocommerce form.lost_reset_password label {
  display: none
}

.woocommerce .change-pass-field {
  width: 25%;
  margin-right: 20px
}

.woocommerce .col-1,
.woocommerce .col-2 {
  float: left;
  width: 49.07749077490775%
}

.woocommerce .col-1 {
  margin-right: 1.845018450184502%
}

.woocommerce #customer_login .col-1 h2,
.woocommerce #customer_login .col-2 h2 {
  margin-bottom: 17px
}

.woocommerce #customer_login .span-3 {
  width: 47.40740740740741%;
  float: left;
  margin-right: 3.703703703703704%
}

.woocommerce #customer_login .form-row-last {
  margin-right: 0
}

.woocommerce #customer_login input[type=text],
.woocommerce #customer_login input[type=email],
.woocommerce #customer_login input[type=password] {
  width: 100%;
  box-sizing: border-box
}

.woo-lost_password2,
.woocommerce #customer_login .lost_password {
  margin-left: 23px
}

.woocommerce table.cart,
.woocommerce-page table.cart {
  width: 100%
}

.woocommerce-page table tr:nth-child(odd) td {
  background: 0 0 !important
}

.woocommerce .checkout table.shop_table thead tr,
.woocommerce table.cart thead tr,
.woocommerce-account table.my_account_orders thead tr,
.woocommerce-page .checkout table.shop_table thead tr,
.woocommerce-page table.cart thead tr {
  border: 1px solid #e6e6e6
}

.woocommerce .checkout table.shop_table thead tr th,
.woocommerce table.cart thead tr th,
.woocommerce-account table.my_account_orders thead tr th,
.woocommerce-page .checkout table.shop_table thead tr th,
.woocommerce-page table.cart thead tr th,
.woocommerce-page table.my_account_orders thead tr th {
  padding: 13px 10px;
  font-size: 14px;
  line-height: 1.571428571428571em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: Raleway, sans-serif;
  font-weight: 600
}

.woocommerce-account table.my_account_orders thead tr th,
.woocommerce-cart table.cart thead th,
.woocommerce-checkout .checkout table thead th,
.woocommerce-page table.my_account_orders thead tr th {
  font-weight: 500;
  font-family: inherit;
  font-size: 14px;
  color: #000
}

.woocommerce-account table.my_account_orders tbody tr,
.woocommerce-cart table.cart tbody tr {
  border: 1px solid #e5e5e5
}

.woocommerce-cart table.cart tbody tr td,
.woocommerce-checkout .checkout table tbody tr td {
  vertical-align: middle
}

.woocommerce-cart table.cart tbody tr td a:not(.checkout-button),
.woocommerce-checkout .checkout table tbody tr td a {
  color: #888
}

.woocommerce table.cart tbody tr td img {
  display: block;
  width: 66px
}

.woocommerce table.cart tbody tr span.amount,
.woocommerce-page table.cart tbody span.amount {
  font-family: inherit;
  color: #888
}

.woocommerce table.cart tbody tr:last-child,
.woocommerce-page table.cart tbody tr:last-child {
  border: none
}

.woocommerce table.cart tbody td.product-remove,
.woocommerce-page table.cart tbody td.product-remove {
  text-align: center;
  border-right: 1px solid #e0dede
}

.woocommerce table.cart tbody td.product-remove a.remove,
.woocommerce-page table.cart tbody td.product-remove a.remove {
  width: 13px;
  height: 13px;
  display: inline-block;
  /* background: url(img/wc_delete_icon.png) center no-repeat; */
  text-indent: -9999px;
  vertical-align: middle
}

.woocommerce form.checkout .col2-set p.form-row label,
.woocommerce table.cart div.coupon label,
.woocommerce-page table.cart div.coupon label {
  display: none
}

.woocommerce table.cart div.coupon,
.woocommerce-page table.cart div.coupon {
  float: left
}

.woocommerce table.cart div.coupon .input-text,
.woocommerce-page table.cart div.coupon .input-text,
.woocommerce-page.woocommerce-checkout div.coupon .input-text,
.woocommerce.woocommerce-checkout div.coupon .input-text {
  width: 178px;
  margin-right: 15px;
  padding: 5px 13px;
  height: 26px
}

.woocommerce table.cart div.coupon .input-text,
.woocommerce-page table.cart div.coupon .input-text {
  background-color: #fff;
  outline: 0;
  resize: none;
  font-family: Raleway, sans-serif;
  font-weight: 400;
  color: #818181;
  border: none
}

.woocommerce table.cart div.coupon .button,
.woocommerce-page table.cart div.coupon .button {
  padding-left: 22px !important;
  padding-right: 22px !important;
  top: 1px !important
}

.woocommerce table.cart td.actions {
  text-align: right;
  padding: 24px 0
}

.woocommerce table.cart td.actions .checkout-button {
  box-sizing: border-box
}

.woocommerce table.cart td.actions .checkout-button,
.woocommerce table.cart td.actions input[type=submit] {
  position: relative;
  top: 3px
}

.woocommerce table.cart td.actions>.checkout-button,
.woocommerce-page table.cart td.actions>.checkout-button {
  float: right;
  padding-left: .9225092250922509% !important;
  padding-right: .9225092250922509% !important;
  margin-left: 1.845018450184502%
}

.woocommerce div.cart-collaterals div.cart_totals th,
.woocommerce-checkout .shop_table.order_details th,
.woocommerce-checkout .shop_table.order_details tr td,
.woocommerce-page .div.cart-collaterals div.cart_totals th {
  padding-left: 0
}

.woocommerce-page .cart-collaterals .cross-sells {
  clear: both
}

.woocommerce div.cart-collaterals div.cart_totals,
.woocommerce-page .div.cart-collaterals div.cart_totals {
  float: right;
  width: 26.3%;
  margin-bottom: 31px
}

.woocommerce div.cart-collaterals div.cart_totals table strong,
.woocommerce div.cart-collaterals div.cart_totals table th,
.woocommerce-page .div.cart-collaterals div.cart_totals table strong,
.woocommerce-page .div.cart-collaterals div.cart_totals table th {
  font-weight: 300
}

.woocommerce div.cart-collaterals div.cart_totals table tr.order-total strong span.amount,
.woocommerce-page div.cart-collaterals div.cart_totals table tr.order-total strong span.amount {
  color: #1abc9c;
  font-size: 1.384615384615385em
}

.woocommerce div.cart-collaterals div.cart_totals table tr.order-total strong {
  font-weight: 400;
  color: #1abc9c
}

.woocommerce div.cart-collaterals .woocommerce-shipping-calculator,
.woocommerce-page .div.cart-collaterals .woocommerce-shipping-calculator {
  float: left;
  width: 49.07749077490775%;
  margin-bottom: 40px
}

.woocommerce div.cart-collaterals .woocommerce-shipping-calculator input[type=text],
.woocommerce-page .div.cart-collaterals .woocommerce-shipping-calculator input[type=text] {
  width: 93.5%;
  padding-left: 3.142329020332717%;
  padding-right: 3.142329020332717%
}

.woocommerce div.cart-collaterals .select2-container .select2-choice,
.woocommerce div.cart-collaterals .select2-dropdown-open.select2-drop-above .select2-choice,
.woocommerce div.cart-collaterals .select2-dropdown-open.select2-drop-above .select2-choices,
.woocommerce-page div.cart-collaterals .select2-container .select2-choice,
.woocommerce-page div.cart-collaterals .select2-dropdown-open.select2-drop-above .select2-choice,
.woocommerce-page div.cart-collaterals .select2-dropdown-open.select2-drop-above .select2-choices {
  padding-left: 3.142329020332717%;
  font-family: inherit;
  font-weight: 300;
  background-color: #fff;
  border: none;
  height: 47px;
  line-height: 47px
}

.woocommerce div.cart-collaterals h2,
.woocommerce-page .div.cart-collaterals h2 {
  font-size: 18px;
  margin-bottom: 15px
}

.woocommerce div.cart-collaterals h2 a,
.woocommerce-page .div.cart-collaterals h2 a {
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.woocommerce div.cart-collaterals h2 a span {
  position: relative;
  top: -2px;
  font-size: .7692307692307692em;
  left: 2px
}

.woocommerce .empty-cart-wrapper,
.woocommerce-page .empty-cart-wrapper {
  text-align: center
}

.woocommerce .empty-cart-wrapper p,
.woocommerce-page .empty-cart-wrapper p {
  margin-bottom: 15px
}

.woocommerce form.checkout .col2-set:after {
  clear: both
}

.woocommerce form.checkout h3,
.woocommerce-page form.checkout h3 {
  margin-bottom: 20px
}

.woocommerce .checkout-opener-text {
  font-family: inherit;
  margin-bottom: 15px
}

.woocommerce .checkout-opener-text a {
  color: #1abc9c;
  font-weight: 300
}

.woocommerce form.checkout_coupon {
  margin-bottom: 20px
}

.woocommerce form.checkout_coupon input.input-text {
  width: 20.63636363636364%
}

.woocommerce form.checkout_coupon .form-row-last {
  margin-bottom: 0
}

.woocommerce form.checkout .col2-set .form-row.notes {
  width: 100%
}

.woocommerce form.checkout .col2-set .form-row.notes textarea {
  width: 94.61538461538462% !important;
  height: 72px
}

.woocommerce #shiptobilling,
.woocommerce-page #shiptobilling {
  margin-bottom: 0;
  float: right;
  position: relative;
  top: 25px
}

.woocommerce #shiptobilling label,
.woocommerce-page #shiptobilling label {
  display: inline-block
}

.woocommerce form.check-login .form-row {
  width: 23.70370370370371%;
  float: left;
  margin-right: 1.845018450184502%
}

.woocommerce form.check-login .form-row input[type=text],
.woocommerce form.check-login .form-row input[type=password] {
  width: 85.5%
}

.woocommerce form.check-login div.login-entrance-text {
  margin-bottom: 20px
}

.woocommerce form.check-login .lost_password {
  margin-left: 15px;
  display: inline-block;
  font-weight: 300
}

.woocommerce form.checkout .col2-set .form-row {
  width: 48.14814814814815%;
  margin-right: 3.703703703703704%;
  float: left
}

.woocommerce form.checkout .col2-set .form-row-wide {
  width: 100%;
  margin-right: 0;
  float: none
}

.woocommerce-checkout #billing_country_field,
.woocommerce-checkout #shipping_country_field {
  height: 47px
}

.woocommerce form.checkout .col2-set .form-row.form-row-wide input[type=text],
.woocommerce form.checkout .col2-set .form-row.notes textarea {
  padding-left: 2.5%;
  padding-right: 2.5%;
  width: 95%
}

.woocommerce form.checkout .col2-set .form-row-last {
  margin-right: 0
}

.woocommerce form.checkout .col2-set .form-row input[type=text],
.woocommerce form.checkout .col2-set .form-row input[type=email],
.woocommerce form.checkout .col2-set .form-row input[type=password],
.woocommerce form.checkout .col2-set .form-row input[type=tel] {
  padding-left: 5.2%;
  padding-right: 5.2%;
  width: 89.6%
}

.woocommerce form.checkout .col2-set p.form-row label.checkbox {
  display: inline-block
}

.woocommerce form.checkout p.register-row {
  margin-bottom: 8px;
  margin-top: 25px
}

.woocommerce form.checkout #order_review_heading {
  margin-top: 23px
}

.woocommerce form.checkout .col2-set .create-account>p {
  margin-bottom: 15px
}

.woocommerce form.checkout table.shop_table td,
.woocommerce form.checkout table.shop_table th {
  text-align: center;
  padding: 10px
}

.woocommerce form.checkout table.shop_table td strong,
.woocommerce form.checkout table.shop_table th {
  font-weight: 300
}

.woocommerce form.checkout table.shop_table tfoot tr.order-total td span.amount,
.woocommerce form.checkout table.shop_table tfoot tr.order-total th {
  color: #1abc9c
}

.woocommerce form.checkout table.shop_table span.amount {
  color: #888;
  font-family: inherit
}

.woocommerce form.checkout table.shop_table {
  width: 100%
}

.woocommerce #payment {
  margin-top: 50px;
  margin-bottom: 70px
}

.woocommerce #payment ul.payment_methods {
  list-style: none;
  border-bottom: 1px solid #ddd;
  margin-bottom: 25px
}

.woocommerce #payment ul.payment_methods li {
  padding: 10px 20px;
  border: 1px solid #e0dede;
  border-bottom: 0
}

.woocommerce #payment ul.payment_methods li label {
  color: #000;
  margin-left: 10px
}

.woocommerce #payment ul.payment_methods li label img {
  margin: 0 10px;
  position: relative;
  top: 10px
}

.woocommerce #payment ul.payment_methods li .payment_box {
  padding: 10px 0 0 35px
}

.woocommerce input#place_order {
  float: right
}

.woocommerce .order_details.clearfix li,
.woocommerce-page .order_details.clearfix li {
  float: left;
  margin-right: 45px
}

.woocommerce .order_details.clearfix li p,
.woocommerce-page .order_details.clearfix li p {
  font-weight: 400;
  color: #000;
  margin-top: 7px
}

.woocommerce ul.order_details,
.woocommerce-page ul.order_details {
  list-style: none;
  margin-bottom: 20px
}

.woocommerce .checkout-success-message,
.woocommerce-page .checkout-success-message {
  margin-bottom: 50px
}

.woocommerce-account h2,
.woocommerce-account h3,
.woocommerce-checkout h2,
.woocommerce-checkout h3 {
  margin-top: 20px;
  margin-bottom: 28px;
  font-size: 17px
}

.woocommerce-account header h3,
.woocommerce-checkout header h3 {
  margin-bottom: 20px
}

.woocommerce-checkout table.shop_table {
  width: 42.07749077490775%;
  margin-bottom: 33px
}

.woocommerce-account table.shop_table {
  margin-bottom: 45px;
  width: 43.077491%
}

.woocommerce-account table.my_account_orders tfoot th,
.woocommerce-account table.my_account_orders thead th,
.woocommerce-account table.shop_table tfoot th,
.woocommerce-account table.shop_table thead th,
.woocommerce-checkout table.shop_table tfoot th,
.woocommerce-checkout table.shop_table thead th {
  color: #000;
  font-weight: 400
}

.woocommerce-account table.shop_table td span.amount,
.woocommerce-checkout table.shop_table td span.amount {
  color: #888
}

.woocommerce-checkout .addresses .col-1,
.woocommerce-checkout .addresses .col-2 {
  width: 23.61623616236162%;
  margin-right: 1.845018450184502%
}

.woocommerce-account .addresses .col-2,
.woocommerce-checkout .addresses .col-2 {
  float: left;
  margin-right: 0
}

.elementor-widget.elementor-widget-sidebar ul.product_list_widget li img,
.woocommerce aside ul.product_list_widget li img,
.wpb_widgetised_column ul.product_list_widget li img,
aside ul.product_list_widget li img {
  float: left;
  margin-right: 7.843137254901961%;
  width: 71px
}

.woocommerce-account div.woocommerce header,
.woocommerce-checkout .order-details-wrapper header {
  position: static;
  background: 0 0;
  border: 0
}

.woocommerce-account .customer_details,
.woocommerce-checkout .customer_details {
  margin-bottom: 18px
}

.woocommerce-checkout .shop_table.order_details tfoot tr:last-child td span.amount,
.woocommerce-checkout .shop_table.order_details tr td a {
  color: #000
}

.woocommerce-checkout .shop_table.order_details tr td span.amount {
  font-family: inherit
}

.woocommerce-checkout .shop_table.order_details tr td .product-quantity {
  font-weight: 400;
  color: #000
}

.woocommerce-checkout .addresses header {
  padding-bottom: 0;
  margin-bottom: 0
}

.elementor-widget.elementor-widget-sidebar ul.product_list_widget li,
.woocommerce aside ul.product_list_widget li,
.wpb_widgetised_column ul.product_list_widget li,
aside ul.product_list_widget li {
  padding: 7px 0 12px;
  background-color: #fff
}

.woocommerce aside ul.product_list_widget li a,
aside ul.product_list_widget li a {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  color: #303030;
  line-height: 20px;
  font-size: 13px
}

.woocommerce aside ul.cart_list li a {
  display: inline-block
}

.woocommerce aside ul.cart_list li .quantity {
  display: block
}

.woocommerce aside ul.product_list_widget li>a:hover,
aside ul.product-categories li>a:hover {
  color: #1abc9c
}

.woocommerce aside ul.product_list_widget li ins,
aside ul.product_list_widget li ins {
  text-decoration: none
}

.woocommerce aside ul.product_list_widget li del span.amount,
aside ul.product_list_widget li del span.amount {
  color: #777
}

.woocommerce aside ul.product_list_widget li span.amount,
aside ul.product_list_widget li span.amount {
  color: #1abc9c;
  font-size: 13px;
  font-weight: 600
}

.elementor-widget.elementor-widget-sidebar ul.product_list_widget li a,
.wpb_widgetised_column ul.product_list_widget li a {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  color: #303030;
  line-height: 20px;
  font-size: 13px
}

.elementor-widget.elementor-widget-sidebar ul.cart_list li a,
.wpb_widgetised_column ul.cart_list li a {
  display: inline-block
}

.elementor-widget.elementor-widget-sidebar ul.cart_list li .quantity,
.wpb_widgetised_column ul.cart_list li .quantity {
  display: block
}

.widget  .search-form .screen-reader-text,
.woocommerce .cart_list li a>img {
  display: none
}

.elementor-widget.elementor-widget-sidebar ul.product_list_widget li>a:hover,
.wpb_widgetised_column ul.product_list_widget li>a:hover {
  color: #1abc9c
}

.elementor-widget.elementor-widget-sidebar ul.product_list_widget li ins,
.wpb_widgetised_column ul.product_list_widget li ins {
  text-decoration: none
}

.elementor-widget.elementor-widget-sidebar ul.product_list_widget li del span.amount,
.wpb_widgetised_column ul.product_list_widget li del span.amount {
  color: #777
}

.elementor-widget.elementor-widget-sidebar ul.product_list_widget li span.amount,
.wpb_widgetised_column ul.product_list_widget li span.amount {
  color: #1abc9c;
  font-size: 13px;
  font-weight: 600
}

.elementor-widget.elementor-widget-sidebar .widget .star-rating,
.wpb_widgetised_column .widget .star-rating {
  float: none;
  font-size: .8em;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 5.6em
}

.elementor-widget.elementor-widget-sidebar .widget.widget_rating_filter .star-rating,
.wpb_widgetised_column .widget.widget_rating_filter .star-rating {
  float: left;
  font-size: 1em;
  margin-right: 5px
}

.elementor-widget.elementor-widget-sidebar .widget ul.product-categories,
.wpb_widgetised_column .widget ul.product-categories {
  list-style: none
}

.elementor-widget.elementor-widget-sidebar .widget ul.product-categories a:hover,
.wpb_widgetised_column .widget ul.product-categories a:hover {
  color: #1abc9c
}

.elementor-widget.elementor-widget-sidebar .select2-container .select2-choice,
.elementor-widget.elementor-widget-sidebar .select2-dropdown-open.select2-drop-above .select2-choice,
.elementor-widget.elementor-widget-sidebar .select2-dropdown-open.select2-drop-above .select2-choices,
.wpb_widgetised_column .select2-container .select2-choice,
.wpb_widgetised_column .select2-dropdown-open.select2-drop-above .select2-choice,
.wpb_widgetised_column .select2-dropdown-open.select2-drop-above .select2-choices {
  padding-left: 12px;
  background-image: none
}

.elementor-widget.elementor-widget-sidebar ul.product-categories ul.children,
.wpb_widgetised_column ul.product-categories ul.children {
  padding-left: 30px
}

.elementor-widget.elementor-widget-sidebar ul.cart_list li,
.wpb_widgetised_column ul.cart_list li {
  padding: 7px 12px
}

.elementor-widget.elementor-widget-sidebar ul.cart_list,
.wpb_widgetised_column ul.cart_list {
  margin-bottom: 10px
}

.woocommerce .widget_shopping_cart_content li {
  height: auto !important
}

.woocommerce .widget_shopping_cart_content .total strong {
  font-weight: 400
}

.woocommerce .widget_shopping_cart_content p.buttons {
  margin-top: 15px
}

.woocommerce .widget_shopping_cart_content p.buttons a.button {
  width: 47%;
  padding-left: 0;
  padding-right: 0;
  text-align: center
}

.woocommerce .widget_shopping_cart_content a.checkout {
  float: right
}

.widget .search-form input[type=text] {
  display: inline-block;
  text-decoration: none;
  border: 0;
  outline: 0;
  color: #adadad;
  background-color: transparent;
  font-family: inherit;
  margin: 0;
  padding: 2px 4%;
  width: 100%;
  height: 33px;
}

.widget .search-form .search-icon {
  width: 37px;
  height: 37px;
  line-height: 37px;
  display: inline-block;
  margin: 0;
  padding: 0 4%;
  outline: 0;
  border: none;
  text-decoration: none;
  color: #a6a6a6;
  font-family: FontAwesome, sans-serif;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  background: 0 0;
}

.woocommerce .widget .star-rating,
.woocommerce-page .widget .star-rating,
.widget .star-rating {
  float: none;
  font-size: .8em;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 5.6em
}

.woocommerce .widget.widget_rating_filter .star-rating {
  float: left;
  font-size: 1em;
  margin-right: 5px
}

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content,
.woocommerce-page .widget_price_filter .price_slider_wrapper .ui-widget-content {
  background-color: #ebebeb
}

.woocommerce .widget_price_filter .ui-slider-horizontal,
.woocommerce-page .widget_price_filter .ui-slider-horizontal {
  height: .31em
}

.woocommerce .widget_price_filter .ui-slider,
.woocommerce-page .widget_price_filter .ui-slider {
  position: relative;
  text-align: left
}

.woocommerce .widget_price_filter .price_slider,
.woocommerce-page .widget_price_filter .price_slider {
  margin-bottom: 1em
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle,
.woocommerce-page .widget_price_filter .ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 15px;
  height: 15px;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  border-radius: 1em;
  cursor: pointer;
  outline: 0;
  top: -6px;
  color: #fff;
  background-color: silver
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle:last-child,
.woocommerce-page .widget_price_filter .ui-slider .ui-slider-handle:last-child {
  margin-left: -.9em
}

.woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range,
.woocommerce-page .widget_price_filter .ui-slider-horizontal .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-color: #1abc9c;
  border-radius: 1em;
  -webkit-border-radius: 1em;
  -moz-border-radius: 1em;
  -ms-border-radius: 1em;
  -o-border-radius: 1em;
  top: 0;
  height: 100%
}

.woocommerce .widget_price_filter .button,
.woocommerce-page .widget_price_filter .button {
  float: right;
  padding: 0 16px
}

.woocommerce .price_slider_amount {
  margin-top: 28px
}

.woocommerce .widget_price_filter .price_label,
.woocommerce-page .widget_price_filter .price_label {
  float: left;
  font-family: Raleway, sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 38px;
  text-transform: uppercase;
  color: #303030;
  letter-spacing: 1px
}

.woocommerce .widget ul.product-categories,
.woocommerce-page .widget ul.product-categories,
.widget ul.product-categories {
  list-style: none
}

.woocommerce .widget ul.product-categories a:hover,
.woocommerce-page .widget ul.product-categories a:hover,
.widget ul.product-categories a:hover {
  color: #1abc9c
}

.woocommerce aside .select2-container .select2-choice,
.woocommerce aside .select2-dropdown-open.select2-drop-above .select2-choice,
.woocommerce aside .select2-dropdown-open.select2-drop-above .select2-choices,
.woocommerce-page aside .select2-container .select2-choice,
.woocommerce-page aside .select2-dropdown-open.select2-drop-above .select2-choice,
.woocommerce-page aside .select2-dropdown-open.select2-drop-above .select2-choices,
aside .select2-container .select2-choice,
aside .select2-dropdown-open.select2-drop-above .select2-choice,
aside .select2-dropdown-open.select2-drop-above .select2-choices {
  padding-left: 12px;
  background-image: none
}

.woocommerce .select2-container .select2-choice .select2-arrow b:after {
  border: none !important
}

.woocommerce aside ul.product-categories ul.children,
.woocommerce-page aside ul.product-categories ul.children,
aside ul.product-categories ul.children {
  padding-left: 30px
}

.woocommerce aside ul.cart_list li,
.woocommerce-page aside ul.cart_list li,
aside ul.cart_list li {
  padding: 7px 12px
}

.woocommerce aside ul.cart_list,
.woocommerce-page aside ul.cart_list,
aside ul.cart_list {
  margin-bottom: 10px
}

.woocommerce .widget_shopping_cart_content .total .amount,
.woocommerce-page .widget_shopping_cart_content .total .amount {
  font-size: 15px
}

.widget.widget_search form input[type=submit] {
  background: 0 0
}

.shopping_cart_outer {
  display: table;
  float: left;
  position: relative;
  width: 43px
}

.shopping_cart_inner {
  display: table-cell;
  height: 100px;
  vertical-align: middle
}

header.sticky .shopping_cart_inner,
header:not(.sticky) .header_menu_bottom .shopping_cart_inner {
  height: 60px
}

.shopping_cart_header {
  display: inline-block;
  width: 43px
}

.shopping_cart_header .header_cart {
  padding: 0 9px 0 0;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px 2px 0 0;
  -webkit-border-radius: 2px 2px 0 0;
  -moz-border-radius: 2px 2px 0 0;
  -o-border-radius: 2px 2px 0 0;
  -ms-border-radius: 2px 2px 0 0;
  position: relative;
  /* background-image: url(img/header_shopping_bag.png); */
  background-repeat: no-repeat;
  background-position: 0 0;
  width: 34px;
  height: 38px
}

.shopping_cart_header .header_cart.cart_icon {
  background-image: none !important;
  width: auto;
  height: auto;
  padding: 0
}

.shopping_cart_header .header_cart.cart_icon:before {
  content: "\e013";
  font-family: ElegantIcons;
  font-size: 23px;
  color: #9d9d9d
}

.shopping_cart_header .header_cart.cart_icon .header_cart_span {
  top: -5px;
  right: -7px;
  left: auto;
  width: 19px;
  height: 19px;
  line-height: 19px;
  background-color: #9d9d9d;
  color: #fff;
  font-size: 10px;
  -webkit-border-radius: 50px;
  border-radius: 50px
}

.shopping_cart_header .header_cart.dripicons-cart {
  background-image: none !important;
  width: auto;
  height: auto;
  padding: 0;
  font-size: 22px
}

.shopping_cart_header .header_cart.dripicons-cart:before {
  color: #9d9d9d
}

.shopping_cart_header .header_cart.dripicons-cart .header_cart_span {
  top: -5px;
  right: -7px;
  left: auto;
  width: 14px;
  height: 14px;
  line-height: 14px;
  background-color: #9d9d9d;
  color: #fff;
  font-size: 10px;
  -webkit-border-radius: 50px;
  border-radius: 50px
}

.shopping_cart_header .header_cart.fa-shopping-cart {
  background-image: none !important;
  width: auto;
  height: auto;
  padding: 0;
  font-size: 22px
}

.shopping_cart_header .header_cart.fa-shopping-cart:before {
  color: #9d9d9d
}

.shopping_cart_header .header_cart.fa-shopping-cart .header_cart_span {
  top: -5px;
  right: -7px;
  left: auto;
  width: 14px;
  height: 14px;
  line-height: 14px;
  background-color: #9d9d9d;
  color: #fff;
  font-size: 10px;
  -webkit-border-radius: 50px;
  border-radius: 50px
}

@media only screen and (min-width: 1000px) {
  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart {
    /* background-image:url(img/header_shopping_bag_black.png) */
  }

  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.cart_icon:before,
  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.dripicons-cart:before {
    color: #000
  }

  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.cart_icon .header_cart_span,
  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.dripicons-cart .header_cart_span {
    background-color: #000
  }

  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart {
    /* background-image: url(img/header_shopping_bag_white.png) */
  }

  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.cart_icon:before,
  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.dripicons-cart:before {
    color: #fff
  }

  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.cart_icon .header_cart_span,
  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.dripicons-cart .header_cart_span {
    background-color: #fff
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio:1.5),
only screen and (-o-min-device-pixel-ratio:150 / 100),
only screen and (min-device-pixel-ratio:1.5),
only screen and (min-resolution:160dpi) {
  .shopping_cart_header .header_cart {
    /* background-image:url(img/header_shopping_bag@1_5x.png); */
    -o-background-size: 34px 38px;
    -webkit-background-size: 34px 38px;
    -moz-background-size: 34px 38px;
    background-size: 34px 38px
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5) and (min-width:1000px),
only screen and (min--moz-device-pixel-ratio:1.5) and (min-width:1000px),
only screen and (-o-min-device-pixel-ratio:150 / 100) and (min-width:1000px),
only screen and (min-device-pixel-ratio:1.5) and (min-width:1000px),
only screen and (min-resolution:160dpi) and (min-width:1000px) {
  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart {
    /* background-image:url(img/header_shopping_bag_black@1_5x.png); */
    -o-background-size: 34px 38px;
    -webkit-background-size: 34px 38px;
    -moz-background-size: 34px 38px;
    background-size: 34px 38px
  }

  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart {
    /* background-image: url(img/header_shopping_bag_white@1_5x.png); */
    -o-background-size: 34px 38px;
    -webkit-background-size: 34px 38px;
    -moz-background-size: 34px 38px;
    background-size: 34px 38px
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:200 / 100),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:210dpi) {
  .shopping_cart_header .header_cart {
    /* background-image:url(img/header_shopping_bag@2x.png); */
    -o-background-size: 34px 38px;
    -webkit-background-size: 34px 38px;
    -moz-background-size: 34px 38px;
    background-size: 34px 38px
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width:1000px),
only screen and (min--moz-device-pixel-ratio:2) and (min-width:1000px),
only screen and (-o-min-device-pixel-ratio:200 / 100) and (min-width:1000px),
only screen and (min-device-pixel-ratio:2) and (min-width:1000px),
only screen and (min-resolution:210dpi) and (min-width:1000px) {
  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart {
    /* background-image:url(img/header_shopping_bag_black@2x.png); */
    -o-background-size: 34px 38px;
    -webkit-background-size: 34px 38px;
    -moz-background-size: 34px 38px;
    background-size: 34px 38px
  }

  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart {
    /* background-image: url(img/header_shopping_bag_white@2x.png); */
    -o-background-size: 34px 38px;
    -webkit-background-size: 34px 38px;
    -moz-background-size: 34px 38px;
    background-size: 34px 38px
  }
}

.shopping_cart_header .header_cart i {
  margin: 0;
  color: #8e8e8e;
  font-size: 14px;
  line-height: 28px;
  transition: color .3s ease;
}

@media only screen and (min-width: 1000px) {
  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart>i {
    color: #000
  }

  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart>i {
    color: #fff
  }
}

.select2-container--default.select2-container--open .select2-selection--single,
.shopping_cart_header .header_cart:hover i {
  color: #1abc9c
}

.shopping_cart_header .header_cart span {
  position: absolute;
  width: 34px;
  height: 38px;
  top: 0;
  left: 0;
  text-align: center;
  line-height: 45px;
  font-family: Raleway, sans-serif;
  font-weight: 600;
  color: #9d9d9d
}

@media only screen and (min-width: 1000px) {

  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.cart_icon .header_cart_span,
  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.dripicons-cart .header_cart_span,
  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart span {
    color: #fff
  }

  header.dark:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart span,
  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.cart_icon .header_cart_span,
  header.light:not(.sticky):not(.scrolled) .shopping_cart_header .header_cart.dripicons-cart .header_cart_span {
    color: #000
  }
}

.shopping_cart_dropdown {
  background-color: #262626;
  display: block;
  left: auto;
  position: absolute;
  right: -1px;
  top: 100%;
  z-index: 1000;
  padding: 15px;
  width: 250px;
  visibility: hidden;
  -webkit-transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out;
  -ms-transition: opacity .2s ease-in-out;
  color: #9d9d9d
}

.header_bottom .shopping_cart_dropdown {
  right: -7px;
  margin-left: -142px
}

header.transparent:not(.sticky) .shopping_cart_dropdown {
  top: 75%
}

.header_bottom .shopping_cart_header {
  padding: 0 4px 0 7px
}

.shopping_cart_dropdown.drop_down_start {
  visibility: visible;
  overflow: visible;
  opacity: 1
}

.header_top .left .shopping_cart_dropdown {
  left: 0;
  right: auto
}

.shopping_cart_outer:hover .shopping_cart_dropdown {
  display: block;
  visibility: visible;
  text-align: left
}

.shopping_cart_dropdown ul li {
  display: inline-block;
  width: 100%;
  float: none;
  padding: 7px 0;
  border-bottom: 1px solid #3d3d3d;
  line-height: 20px;
  color: #9d9d9d;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase
}

.shopping_cart_dropdown .shopping_cart_dropdown_inner {
  margin-bottom: 15px
}

.shopping_cart_dropdown ul li a {
  color: #9d9d9d
}

.shopping_cart_dropdown ul li a:hover {
  color: #fff
}

.shopping_cart_dropdown span.total {
  padding: 0 0 0 8px;
  float: right;
  color: #9d9d9d;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase
}

.shopping_cart_dropdown span.total span {
  font-family: inherit;
  font-size: 13px;
  color: #fff;
  padding: 0 0 0 2px
}

.shopping_cart_dropdown .qbutton {
  height: 28px;
  line-height: 30px;
  font-size: 11px;
  padding: 0 13px
}

.shopping_cart_dropdown .qbutton i {
  font-size: 14px;
  padding: 0 0 0 4px;
  margin: 0
}

.shopping_cart_dropdown .cart_list a>img {
  max-width: 60px;
  float: left;
  padding: 0 10px 0 0;
  display: block !important
}

.shopping_cart_dropdown .cart_list span.quantity {
  display: block;
  color: #fff;
  margin-top: 3px
}

.woocommerce-account .woocommerce-MyAccount-navigation {
  float: left;
  width: 25%
}

.woocommerce-account .woocommerce-MyAccount-navigation li {
  display: block
}

.woocommerce-account .woocommerce-MyAccount-navigation li a {
  display: block;
  margin: 0;
  padding: 13px 0;
  font-size: 13px;
  color: #b4b4b4;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.woocommerce-account .woocommerce-MyAccount-navigation li:first-child a {
  padding-top: 0
}

.woocommerce-account .woocommerce-MyAccount-navigation li.is-active a {
  color: #303030
}

.woocommerce-account .woocommerce-MyAccount-content {
  float: right;
  width: 73%
}

.woocommerce-account .woocommerce-MyAccount-content .woocommerce-Button {
  float: right
}

.woocommerce #shipping_method li {
  list-style: none
}

.woocommerce-cart .woocommerce .blockOverlay.blockUI {
  background: #f6f6f6 !important
}

.select2-container {
  margin: 0;
  position: relative;
  display: inline-block;
  zoom: 1;
  vertical-align: middle
}

.select2-container,
.select2-drop,
.select2-search,
.select2-search input {
  box-sizing: border-box
}

.select2-container .select2-choice {
  display: block;
  height: 26px;
  padding: 0 0 0 8px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  line-height: 26px;
  text-decoration: none;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.select2-container.select2-allowclear .select2-choice .select2-chosen {
  margin-right: 42px
}

.select2-container .select2-choice>.select2-chosen {
  margin-right: 26px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 300
}

.select2-container .select2-choice abbr {
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 24px;
  top: 8px;
  font-size: 1px;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  outline: 0
}

.select2-container.select2-allowclear .select2-choice abbr {
  display: inline-block
}

.select2-container .select2-choice abbr:hover {
  background-position: right -11px;
  cursor: pointer
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  background-color: #fff;
  filter: alpha(opacity=0)
}

.select2-drop {
  width: 100%;
  margin-top: -2px;
  position: absolute;
  z-index: 9999;
  top: 100%;
  background-color: #fff;
  color: #818181;
  border: none
}

.select2-drop.select2-drop-active {
  border-radius: 0 0 2px 2px
}

.select2-drop-auto-width {
  width: auto
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px
}

.select2-container .select2-choice .select2-arrow {
  display: inline-block;
  width: 18px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 2px 2px 0;
  background-clip: padding-box
}

.select2-container .select2-choice .select2-arrow b {
  display: block;
  width: 100%;
  height: 100%
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  z-index: 10000;
  white-space: nowrap
}

.select2-search input {
  width: 100%;
  height: auto !important;
  min-height: 26px;
  padding: 4px 20px 4px 5px;
  margin: 0;
  outline: 0;
  font-family: sans-serif;
  font-size: 1em
}

.select2-drop.select2-drop-above .select2-search input {
  margin-top: 4px
}

.select2-search input.select2-active {
  /* background: url(img/select2-spinner.gif) 100% no-repeat;
  background: url(img/select2-spinner.gif) 100% no-repeat,-webkit-gradient(linear,left bottom,left top,color-stop(.85,#fff),color-stop(.99,#eee));
  background: url(img/select2-spinner.gif) 100% no-repeat,-webkit-linear-gradient(center bottom,#fff 85%,#eee 99%);
  background: url(img/select2-spinner.gif) 100% no-repeat,-moz-linear-gradient(center bottom,#fff 85%,#eee 99%);
  background: url(img/select2-spinner.gif) 100% no-repeat,linear-gradient(top,#fff 85%,#eee 99%) */
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  outline: 0
}

.select2-dropdown-open .select2-choice .select2-arrow {
  background: 0 0;
  border-left: none;
  filter: none
}

.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px
}

.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  font-size: 13px
}

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0
}

.select2-results ul.select2-result-sub>li .select2-result-label {
  padding-left: 20px
}

.select2-results ul.select2-result-sub ul.select2-result-sub>li .select2-result-label {
  padding-left: 40px
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub>li .select2-result-label {
  padding-left: 60px
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub>li .select2-result-label {
  padding-left: 80px
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub>li .select2-result-label {
  padding-left: 100px
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub>li .select2-result-label {
  padding-left: 110px
}

.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub>li .select2-result-label {
  padding-left: 120px
}

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none
}

.select2-results li.select2-result-with-children>.select2-result-label {
  font-weight: 700
}

.select2-results .select2-result-label {
  padding: 4px 7px 6px;
  margin: 0;
  cursor: pointer;
  min-height: 1em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.select2-results .select2-highlighted {
  background: #3875d7;
  color: #fff
}

.select2-results li em {
  background: #feffde;
  font-style: normal
}

.select2-results .select2-highlighted em {
  background: 0 0
}

.select2-results .select2-highlighted ul {
  background-color: #fff;
  color: #000
}

.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item
}

.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default
}

.select2-results .select2-disabled {
  background: #f4f4f4;
  display: list-item;
  cursor: default
}

.select2-results .select2-selected {
  display: none
}

.select2-more-results.select2-active {
  /* background: url(img/select2-spinner.gif) 100% no-repeat #f4f4f4 */
}

.select2-more-results {
  background: #f4f4f4;
  display: list-item
}

.select2-container.select2-container-disabled .select2-choice {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: #f4f4f4;
  background-image: none;
  border-left: 0
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none
}

.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #aaa;
  cursor: text;
  overflow: hidden;
  background-color: #fff;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #eee), color-stop(15%, #fff));
  background-image: -webkit-linear-gradient(top, #eee 1%, #fff 15%);
  background-image: -moz-linear-gradient(top, #eee 1%, #fff 15%);
  background-image: linear-gradient(to top, #eee 1%, #fff 15%);
  min-height: 26px
}

.select2-locked {
  padding: 3px 5px !important
}

.select2-container-multi.select2-container-active .select2-choices {
  outline: 0
}

.select2-container-multi .select2-choices li {
  float: left;
  list-style: none
}

.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;
  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  background: 0 0 !important
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background-color: #fff !important;
  /* background-image: url(img/select2-spinner.gif) no-repeat 100%!important */
}

.select2-default {
  color: #999 !important
}

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 3px 5px 3px 18px;
  margin: 3px 0 3px 5px;
  position: relative;
  line-height: 13px;
  color: #888;
  cursor: default;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default
}

.select2-container-multi .select2-choices .select2-search-choice-focus {
  background: #d4d4d4
}

.select2-search-choice-close {
  display: block;
  width: 12px;
  height: 13px;
  position: absolute;
  right: 3px;
  top: 4px;
  font-size: 1px;
  outline: 0
}

.select2-container-multi .select2-search-choice-close {
  left: 3px
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover,
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px
}

.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px;
  border: 1px solid #ddd;
  background-image: none;
  background-color: #f4f4f4
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {
  display: none;
  background: 0 0
}

.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline
}

.select2-offscreen,
.select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0 !important;
  top: 0 !important
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__rendered,
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding: 0 20px 0 30px
}

.select2-display-none {
  display: none
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-resolution:144dpi) {

  .select2-container .select2-choice .select2-arrow b,
  .select2-container .select2-choice abbr,
  .select2-search input,
  .select2-search-choice-close {
    /* background-image:url(img/select2x2.png)!important; */
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important
  }

  .select2-search input {
    background-position: 100% -21px !important
  }
}

.select2-container--default {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border: 0;
  border-radius: 0;
  text-align: initial;
  box-sizing: border-box
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear,
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 20px;
  right: auto
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder {
  float: right
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin: 7px auto 0 5px
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin: 0 auto 0 3px
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default
}

.select2-container--default.select2-container--open .select2-dropdown {
  left: 0
}

.select2-container--default.select2-container--open .select2-dropdown.select2-dropdown--above {
  border-bottom-color: transparent
}

.select2-container--default.select2-container--open .select2-dropdown.select2-dropdown--below {
  border-top-color: transparent
}

.woocommerce div.cart-collaterals .woocommerce-shipping-calculator .select2-container--default,
.woocommerce-page .div.cart-collaterals .woocommerce-shipping-calculator .select2-container--default {
  width: 100% !important
}

.woocommerce div.cart-collaterals .woocommerce-shipping-calculator .select2-container--default .select2-selection--multiple,
.woocommerce div.cart-collaterals .woocommerce-shipping-calculator .select2-container--default .select2-selection--single,
.woocommerce-page .div.cart-collaterals .woocommerce-shipping-calculator .select2-container--default .select2-selection--multiple,
.woocommerce-page .div.cart-collaterals .woocommerce-shipping-calculator .select2-container--default .select2-selection--single {
  border: 0
}

.select2-container--default .select2-selection--single {
  display: block;
  height: 44px;
  line-height: 42px;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  display: block;
  padding: 0 30px 0 20px;
  color: inherit;
  line-height: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  position: relative;
  cursor: pointer;
  float: right;
  margin: 0;
  font-weight: inherit
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: inherit
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  right: 20px;
  color: inherit;
  line-height: inherit;
  text-align: center
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none
}

.select2-container--default .select2-selection--single .select2-selection__arrow:before {
  font-family: FontAwesome;
  content: '\f107'
}

.select2-container--default .select2-selection--multiple {
  display: block;
  height: 44px;
  line-height: 42px;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  outline: 0;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  display: block;
  list-style: none;
  padding: 0 30px 0 20px;
  margin: 0;
  color: inherit;
  line-height: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__placeholder {
  float: left;
  margin: 0;
  color: inherit
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__clear {
  position: relative;
  cursor: pointer;
  float: right;
  margin: 0;
  font-weight: inherit
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  float: left;
  margin: 7px 5px 0 0;
  padding: 0 10px;
  line-height: 30px;
  color: #fff;
  background-color: #303030;
  border: 0;
  border-radius: 0;
  cursor: default
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove {
  display: inline-block;
  margin: 0 3px 0 0;
  color: inherit;
  font-weight: 700;
  cursor: pointer;
  -webkit-transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice__remove:hover {
  color: inherit;
  opacity: .8
}

.select2-container--default .select2-search--inline {
  float: left
}

.select2-container--default .select2-search--inline .select2-search__field {
  margin: 0;
  padding: 0;
  font-size: 100%;
  background: 0 0;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
  box-sizing: border-box
}

.select2-container--default .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none
}

.select2-container--default .select2-dropdown {
  position: absolute;
  top: 0;
  left: -100000px;
  width: 100%;
  display: block;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 0;
  z-index: 1051;
  box-sizing: border-box
}

.select2-container--default .select2-search--dropdown {
  display: block;
  padding: 14px 15px 7px
}

.select2-container--default .select2-search--dropdown.select2-search--hide {
  display: none
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  padding: 4px 8px;
  width: 100%;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
  border: 1px solid #e6e6e6;
  outline: 0;
  box-sizing: border-box;
  -webkit-appearance: none
}

.select2-container--default .select2-search--dropdown .select2-search__field:focus {
  border-color: #303030
}

.select2-container--default .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none
}

.select2-container--default .select2-results {
  display: block;
  padding: 0;
  margin: 0;
  overflow: hidden
}

.select2-container--default .select2-results>.select2-results__options {
  max-height: 200px;
  overflow-y: auto
}

.select2-container--default .select2-results__options {
  position: relative;
  padding: 2px 15px;
  margin: 5px 0;
  list-style: none
}

.select2-container--default .select2-results__option {
  padding: 7px 0;
  user-select: none;
  -webkit-user-select: none;
  -webkit-transition: color .2s ease-out;
  -moz-transition: color .2s ease-out;
  transition: color .2s ease-out
}

.select2-container--default .select2-results__option[aria-selected] {
  cursor: pointer
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #303030
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected=true] {
  color: #1abc9c;
  background: 0 0
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em
}

.select2-container--default .select2-results__group {
  display: block;
  padding: 7px 0;
  cursor: default
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0)
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important
}

.qode_product_list_holder,
.qode_product_list_holder ul {
  width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle
}

.qode_product_list_holder ul {
  margin: 0;
  padding: 0;
  clear: both
}

.qode_product_list_holder ul li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  margin: 0;
  padding: 0;
  float: left;
  text-align: center;
  background-color: #fff
}

.qode_product_list_holder.three_columns ul li {
  width: 33.33%
}

.qode_product_list_holder.three_columns ul li:nth-child(3n+1),
.qode_product_list_holder.two_columns ul li:nth-child(2n+1) {
  clear: both
}

.qode_product_list_holder.three_columns ul li:nth-child(2n),
.qode_product_list_holder.two_columns ul li:nth-child(4n+2),
.qode_product_list_holder.two_columns ul li:nth-child(4n+3) {
  background-color: #f4f4f4
}

.qode_product_list_holder .product_list_link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: middle
}

.qode_product_list_holder .product_list_inner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 14% 4% 10%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.qode_product_list_holder .product_category {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: 20px 0 0
}

.qode_product_list_holder .product_category a {
  position: relative;
  z-index: 2
}

.qode_product_list_holder .product_title {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: 5px 0 10px
}

.qode_product_list_holder .product_image {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%
}

.qode_product_list_holder .product_image img {
  display: block;
  margin: 0 auto
}

.qode_product_list_holder .product_price {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: 30px 0 0;
  color: #303030;
  font-size: 37px;
  line-height: 1em
}

.qode_product_list_holder .product_price .amount {
  color: inherit;
  font-size: inherit;
  line-height: inherit
}

.qode_product_list_holder .product_button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin: 45px 0 20px
}

.qode_product_list_holder .product_button a {
  position: relative;
  z-index: 2
}

.qode_product_list_holder .product_button a.added {
  display: none
}

.qode_product_list_masonry_holder {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle
}

.qode_product_list_masonry_holder .qode_product_list_masonry_holder_inner {
  opacity: 0
}

.qode_product_list_masonry_holder .qode_product_list_gutter,
.qode_product_list_masonry_holder .qode_product_list_sizer {
  width: 100%
}

.qode_product_list_masonry_holder .qode_product_list_item {
  position: relative
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_image img {
  display: block
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner {
  opacity: 0;
  background-color: #fafafa;
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  transition: opacity .3s ease;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_list_item_table {
  position: relative;
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_list_item_table_cell {
  position: relative;
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  text-align: center
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_category {
  margin-bottom: 5px;
  color: #777
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_category a {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  z-index: 2
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_category a:not(:hover) {
  color: inherit
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_title h1,
.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_title h2,
.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_title h3,
.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_title h4,
.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_title h5 {
  color: #303030;
  font-weight: 500
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_separator {
  width: 70px;
  background-color: #bfbfbf;
  margin: 3px auto 20px
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .qode_product_price {
  color: #303030;
  font-size: 15px;
  font-weight: 500
}

.qode_product_list_masonry_holder .qode_product_list_item .qode_product_list_item_inner .product_list_link {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.qode_product_list_masonry_holder .qode_product_list_item:hover .qode_product_list_item_inner {
  opacity: 1
}

.full_width .grid_section .section_inner .qode_product_list_masonry_holder .qode_product_list_gutter,
.qode_product_list_masonry_holder .qode_product_list_gutter {
  width: 2.72717%
}

.full_width .grid_section .section_inner .qode_product_list_masonry_holder .qode_product_list_item,
.qode_product_list_masonry_holder .qode_product_list_item {
  margin-bottom: 2.72717%
}

.full_width .grid_section .section_inner .qode_product_list_masonry_holder.two_columns .qode_product_list_item,
.full_width .grid_section .section_inner .qode_product_list_masonry_holder.two_columns .qode_product_list_sizer,
.qode_product_list_masonry_holder.two_columns .qode_product_list_item,
.qode_product_list_masonry_holder.two_columns .qode_product_list_sizer {
  width: 48.63641%
}

.full_width .grid_section .section_inner .qode_product_list_masonry_holder.two_columns .qode_product_list_item.large-width-height,
.qode_product_list_masonry_holder.two_columns .qode_product_list_item.large-width-height {
  width: 100%
}

.full_width .grid_section .section_inner .qode_product_list_masonry_holder.three_columns .qode_product_list_item,
.full_width .grid_section .section_inner .qode_product_list_masonry_holder.three_columns .qode_product_list_sizer,
.qode_product_list_masonry_holder.three_columns .qode_product_list_item,
.qode_product_list_masonry_holder.three_columns .qode_product_list_sizer {
  width: 31.51522%
}

.full_width .grid_section .section_inner .qode_product_list_masonry_holder.three_columns .qode_product_list_item.large-width-height,
.qode_product_list_masonry_holder.three_columns .qode_product_list_item.large-width-height {
  width: 65.75761%
}

.full_width .grid_section .section_inner .qode_product_list_masonry_holder.four_columns .qode_product_list_item,
.full_width .grid_section .section_inner .qode_product_list_masonry_holder.four_columns .qode_product_list_sizer,
.qode_product_list_masonry_holder.four_columns .qode_product_list_item,
.qode_product_list_masonry_holder.four_columns .qode_product_list_sizer {
  width: 22.95462%
}

.full_width .grid_section .section_inner .qode_product_list_masonry_holder.four_columns .qode_product_list_item.large-width-height,
.qode_product_list_masonry_holder.four_columns .qode_product_list_item.large-width-height {
  width: 48.63641%
}

.full_width .qode_product_list_masonry_holder .qode_product_list_gutter {
  width: 1.5624%
}

.full_width .qode_product_list_masonry_holder .qode_product_list_item {
  margin-bottom: 1.5624%
}

.full_width .qode_product_list_masonry_holder.two_columns .qode_product_list_item,
.full_width .qode_product_list_masonry_holder.two_columns .qode_product_list_sizer {
  width: 49.2188%
}

.full_width .qode_product_list_masonry_holder.two_columns .qode_product_list_item.large-width-height {
  width: 100%
}

.full_width .qode_product_list_masonry_holder.three_columns .qode_product_list_item,
.full_width .qode_product_list_masonry_holder.three_columns .qode_product_list_sizer {
  width: 32.29173%
}

.full_width .qode_product_list_masonry_holder.three_columns .qode_product_list_item.large-width-height {
  width: 66.14587%
}

.full_width .qode_product_list_masonry_holder.four_columns .qode_product_list_item,
.full_width .qode_product_list_masonry_holder.four_columns .qode_product_list_sizer {
  width: 23.8282%
}

.full_width .qode_product_list_masonry_holder.four_columns .qode_product_list_item.large-width-height {
  width: 49.2188%
}

.qode_product_list_pinterest_holder {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle
}

.qode_product_list_pinterest_holder .qode_product_list_pinterest_holder_inner {
  opacity: 0
}

.qode_product_list_pinterest_holder .qode_product_list_gutter,
.qode_product_list_pinterest_holder .qode_product_list_sizer {
  width: 100%
}

.qode_product_list_pinterest_holder .qode_product_list_item {
  position: relative;
  margin-bottom: 53px
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_image img {
  display: block
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_list_item_hover_holder {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  padding: 30px;
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_list_item_hover_holder .qode_product_list_item_hover {
  display: table;
  width: 100%;
  height: 100%
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_list_item_hover_holder .qode_product_list_item_hover_inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_category {
  font-size: 12px;
  margin-bottom: 2px
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_category.qode_product_category_inherit_color a {
  color: inherit
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_separator {
  width: 46px;
  margin-top: 13px;
  margin-bottom: 12px
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_button {
  margin-top: 38px
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_button .qbutton.added {
  display: none
}

.qode_product_list_pinterest_holder .qode_product_list_item .qode_product_list_item_text {
  text-align: center;
  padding: 33px 5px 20px
}

.qode_product_list_pinterest_holder .qode_product_list_item:hover .qode_product_list_item_hover_holder {
  opacity: 1
}

.full_width .grid_section .section_inner .qode_product_list_pinterest_holder .qode_product_list_gutter,
.qode_product_list_pinterest_holder .qode_product_list_gutter {
  width: 2.72717%
}

.full_width .grid_section .section_inner .qode_product_list_pinterest_holder.two_columns .qode_product_list_item,
.full_width .grid_section .section_inner .qode_product_list_pinterest_holder.two_columns .qode_product_list_sizer,
.qode_product_list_pinterest_holder.two_columns .qode_product_list_item,
.qode_product_list_pinterest_holder.two_columns .qode_product_list_sizer {
  width: 48.63641%
}

.full_width .grid_section .section_inner .qode_product_list_pinterest_holder.two_columns .qode_product_list_item.large-width-height,
.qode_product_list_pinterest_holder.two_columns .qode_product_list_item.large-width-height {
  width: 100%
}

.full_width .grid_section .section_inner .qode_product_list_pinterest_holder.three_columns .qode_product_list_item,
.full_width .grid_section .section_inner .qode_product_list_pinterest_holder.three_columns .qode_product_list_sizer,
.qode_product_list_pinterest_holder.three_columns .qode_product_list_item,
.qode_product_list_pinterest_holder.three_columns .qode_product_list_sizer {
  width: 31.51522%
}

.full_width .grid_section .section_inner .qode_product_list_pinterest_holder.three_columns .qode_product_list_item.large-width-height,
.qode_product_list_pinterest_holder.three_columns .qode_product_list_item.large-width-height {
  width: 65.75761%
}

.full_width .grid_section .section_inner .qode_product_list_pinterest_holder.four_columns .qode_product_list_item,
.full_width .grid_section .section_inner .qode_product_list_pinterest_holder.four_columns .qode_product_list_sizer,
.qode_product_list_pinterest_holder.four_columns .qode_product_list_item,
.qode_product_list_pinterest_holder.four_columns .qode_product_list_sizer {
  width: 22.95462%
}

.full_width .grid_section .section_inner .qode_product_list_pinterest_holder.four_columns .qode_product_list_item.large-width-height,
.qode_product_list_pinterest_holder.four_columns .qode_product_list_item.large-width-height {
  width: 48.63641%
}

.full_width .qode_product_list_pinterest_holder .qode_product_list_gutter {
  width: 1.5624%
}

.full_width .qode_product_list_pinterest_holder.two_columns .qode_product_list_item,
.full_width .qode_product_list_pinterest_holder.two_columns .qode_product_list_sizer {
  width: 49.2188%
}

.full_width .qode_product_list_pinterest_holder.two_columns .qode_product_list_item.large-width-height {
  width: 100%
}

.full_width .qode_product_list_pinterest_holder.three_columns .qode_product_list_item,
.full_width .qode_product_list_pinterest_holder.three_columns .qode_product_list_sizer {
  width: 32.29173%
}

.full_width .qode_product_list_pinterest_holder.three_columns .qode_product_list_item.large-width-height {
  width: 66.14587%
}

.full_width .qode_product_list_pinterest_holder.four_columns .qode_product_list_item,
.full_width .qode_product_list_pinterest_holder.four_columns .qode_product_list_sizer {
  width: 23.8282%
}

.full_width .qode_product_list_pinterest_holder.four_columns .qode_product_list_item.large-width-height {
  width: 49.2188%
}

.woocommerce .product .qode-add-gallery-and-zoom-support {
  position: relative
}

.woocommerce .product .qode-add-gallery-and-zoom-support .flex-control-nav {
  padding: 0;
  margin: 0
}

.woocommerce .product .qode-add-gallery-and-zoom-support .flex-control-nav li {
  float: left;
  width: 33.334%
}

.woocommerce .product .qode-add-gallery-and-zoom-support.woocommerce-product-gallery--columns-4 .flex-control-nav li {
  width: 25%
}

.woocommerce .product .qode-add-gallery-and-zoom-support.images .woocommerce-product-gallery__image,
.woocommerce .product .qode-add-gallery-and-zoom-support.images .woocommerce-product-gallery__image:not(:first-child),
.woocommerce .product .qode-add-gallery-and-zoom-support.images.woocommerce-product-gallery--with-images {
  padding: 0
}

.woocommerce .product .qode-add-gallery-and-zoom-support.images figure {
  margin: 0
}

.woocommerce .product .qode-add-gallery-and-zoom-support .woocommerce-product-gallery__trigger {
  position: absolute;
  right: 20px;
  top: 17px;
  z-index: 100
}

.woocommerce .product .qode-add-gallery-and-zoom-support .woocommerce-product-gallery__trigger img {
  display: none !important
}

.woocommerce .product .qode-add-gallery-and-zoom-support .woocommerce-product-gallery__trigger:after {
  content: "\55";
  font-family: ElegantIcons
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .images {
  width: 63.55%;
  margin-right: 2.9%
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .images .woocommerce-main-image {
  display: inline-block;
  vertical-align: middle;
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 0 5px
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .images .woocommerce-main-image img {
  vertical-align: middle
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .images .thumbnails {
  margin: 0
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .images .thumbnails a,
.woocommerce.single-product.qode-product-single-wide-gallery .product .images .woocommerce-product-gallery__image {
  float: left;
  width: 50%;
  margin: 0 0 10px;
  padding: 0 5px;
  box-sizing: border-box
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .images .thumbnails a img {
  vertical-align: middle
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .summary {
  width: 33.55%;
  padding-right: 65px;
  box-sizing: border-box
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .summary .qode-sp-separator.separator {
  margin-top: 25px
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .summary .product_meta>span {
  font-weight: inherit;
  font-size: inherit;
  text-transform: inherit;
  padding: 2px 0
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .summary .product_meta>.social_share_list_holder>span {
  color: #333;
  font-size: inherit;
  text-transform: inherit;
  font-weight: inherit;
  padding: 2px 0
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .qode-product-gallery-wide-related {
  clear: both;
  padding-top: 80px
}

.woocommerce.single-product.qode-product-single-wide-gallery .product .related ul.products {
  margin-bottom: 105px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs {
  clear: both;
  padding: 50px 16.5% 60px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs #tab-description {
  text-align: center
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs .shop_attributes {
  width: 100%
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs .shop_attributes td,
.woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs .shop_attributes th {
  text-align: center
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs .shop_attributes td,
.woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs .shop_attributes tr {
  border: 1px solid #e5e5e5
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .single-onsale {
  left: 133px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support) {
  width: 63.85%;
  margin-right: 2.3%
}

@media only screen and (min-width: 601px) {
  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support) figure {
    width: 100%;
    margin: 0
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support) .woocommerce-product-gallery__image:first-child {
    padding: 0 0 0 114px
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
    width: 111px;
    height: 111px;
    padding: 0
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:not(:first-child) a,
  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:not(:first-child) a img {
    height: 100%
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:nth-child(2n) {
    top: 0
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:nth-child(3n) {
    top: 114px
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:nth-child(4n) {
    top: 228px
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:nth-child(5n) {
    top: 342px
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:nth-child(6n) {
    top: 456px
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:nth-child(7n) {
    top: 570px
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:nth-child(8n) {
    top: 684px
  }

  .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support).woocommerce-product-gallery--with-images .woocommerce-product-gallery__image:nth-child(9n) {
    top: 798px
  }
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images.qode-add-gallery-and-zoom-support {
  width: 63.85%;
  margin-right: 2.3%
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images.qode-add-gallery-and-zoom-support .flex-viewport {
  margin-left: 114px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images.qode-add-gallery-and-zoom-support .flex-control-nav {
  position: absolute;
  left: 0;
  top: 0;
  width: 111px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images.qode-add-gallery-and-zoom-support .flex-control-nav li {
  float: none;
  width: 100%;
  margin-bottom: 3px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product:not(.qode-product-with-gallery) .images.qode-add-gallery-and-zoom-support {
  padding-left: 114px;
  box-sizing: border-box
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product.qode-product-with-gallery .thumbnails {
  width: 111px;
  margin-right: 3px;
  float: left;
  margin-top: 0
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product.qode-product-with-gallery .thumbnails a {
  width: 100%;
  margin-right: 0;
  margin-bottom: 3px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product.qode-product-with-gallery .thumbnails a img {
  vertical-align: middle
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product.qode-product-with-gallery .woocommerce-main-image {
  float: right;
  width: calc(100% - 114px)
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .summary {
  width: 33.85%
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .summary .qode-sp-separator.separator {
  margin-top: 25px;
  margin-bottom: 25px;
  width: 46px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .summary .product-categories {
  margin-bottom: 11px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .product_meta {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 0;
  text-align: center
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .product_meta>span {
  display: inline-block;
  font-weight: inherit;
  font-size: inherit;
  text-transform: inherit;
  color: inherit;
  border-right: 1px solid #e5e5e5;
  padding-right: 15px;
  padding-left: 12px
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .product_meta>span:last-child {
  border-right: none
}

.woocommerce.single-product.qode-product-single-tabs-on-bottom .product .related {
  padding-top: 55px
}

#yith-quick-view-modal .yith-quick-view-overlay,
.yith-quick-view.yith-modal .yith-quick-view-overlay {
  background-color: rgba(0, 0, 0, .7)
}

#yith-quick-view-modal .yith-wcqv-wrapper,
.yith-quick-view.yith-modal .yith-wcqv-wrapper {
  padding: 0;
  max-width: 1080px;
  height: auto !important
}

#yith-quick-view-modal .yith-wcqv-main,
.yith-quick-view.yith-modal .yith-wcqv-main {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  padding: 0;
  overflow: hidden !important;
  box-shadow: none
}

#yith-quick-view-modal #yith-quick-view-content .product,
.yith-quick-view.yith-modal #yith-quick-view-content .product {
  margin: 0
}

#yith-quick-view-modal #yith-quick-view-content .product-type-grouped .summary p.price,
.yith-quick-view.yith-modal #yith-quick-view-content .product-type-grouped .summary p.price {
  margin: 0 0 30px
}

#yith-quick-view-modal #yith-quick-view-content .product-type-external .summary .qode-single-product-share-wish,
.yith-quick-view.yith-modal #yith-quick-view-content .product-type-external .summary .qode-single-product-share-wish {
  margin-top: 47px
}

#yith-quick-view-modal #yith-quick-view-content .product-type-variable .summary p.price,
.yith-quick-view.yith-modal #yith-quick-view-content .product-type-variable .summary p.price {
  margin: 0 0 30px
}

#yith-quick-view-modal #yith-quick-view-content .product-type-variable form .select2-container--default,
.yith-quick-view.yith-modal #yith-quick-view-content .product-type-variable form .select2-container--default {
  float: none
}

#yith-quick-view-modal #yith-quick-view-content .product-type-variable form .single_variation_wrap,
.yith-quick-view.yith-modal #yith-quick-view-content .product-type-variable form .single_variation_wrap {
  margin: 5px 0 0
}

#yith-quick-view-modal #yith-quick-view-content .images,
#yith-quick-view-modal #yith-quick-view-content .summary,
.yith-quick-view.yith-modal #yith-quick-view-content .images,
.yith-quick-view.yith-modal #yith-quick-view-content .summary {
  position: relative;
  float: left;
  width: 50%;
  box-sizing: border-box;
  margin: 0
}

#yith-quick-view-modal #yith-quick-view-content .images a,
#yith-quick-view-modal #yith-quick-view-content .images img,
.yith-quick-view.yith-modal #yith-quick-view-content .images a,
.yith-quick-view.yith-modal #yith-quick-view-content .images img {
  display: block
}

#yith-quick-view-modal #yith-quick-view-content .summary,
.yith-quick-view.yith-modal #yith-quick-view-content .summary {
  padding: 40px
}

#yith-quick-view-modal #yith-quick-view-content .summary .single_add_to_cart_button,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .single_add_to_cart_button {
  padding: 8px 30px;
  line-height: 25px;
  height: 25px
}

#yith-quick-view-modal #yith-quick-view-content .summary .variations,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .variations {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 20px;
  border: none;
  padding: 0
}

#yith-quick-view-modal #yith-quick-view-content .summary .variations select,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .variations select {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  line-height: 50px;
  height: 50px;
  padding: 0 16px;
  margin: 0 -4px 0 0;
  color: #000;
  font-weight: 300;
  font-size: 13px
}

#yith-quick-view-modal #yith-quick-view-content .summary .variations tr,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .variations tr {
  border: 0;
  display: block;
  float: left
}

#yith-quick-view-modal #yith-quick-view-content .summary .variations tr:first-child,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .variations tr:first-child {
  padding-right: 6px
}

#yith-quick-view-modal #yith-quick-view-content .summary .variations td,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .variations td {
  padding: 0;
  text-align: left;
  display: block
}

#yith-quick-view-modal #yith-quick-view-content .summary .variations td.label,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .variations td.label {
  width: 12%
}

#yith-quick-view-modal #yith-quick-view-content .summary .variations .select2-container,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .variations .select2-container {
  min-width: 180px
}

#yith-quick-view-modal #yith-quick-view-content .summary .variations .reset_variations,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .variations .reset_variations {
  color: #000;
  position: relative;
  top: -5px;
  right: -10px
}

#yith-quick-view-modal #yith-quick-view-content .summary .single_variation_wrap,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .single_variation_wrap {
  margin: 35px 0 0
}

#yith-quick-view-modal #yith-quick-view-content .summary .single_variation_wrap .single_variation,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .single_variation_wrap .single_variation {
  margin: 0 0 35px
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  border: none;
  padding: 0
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table tbody,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table tbody {
  display: block
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table tr,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table tr {
  border: 1px solid #000;
  display: table;
  margin-bottom: 13px;
  width: 100%;
  background-color: #fff
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table td,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table td {
  box-sizing: border-box;
  padding: 0 10px;
  text-align: left;
  display: inline-block
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table td:first-child,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table td:first-child {
  padding: 0
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table td.label,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table td.label {
  text-align: left;
  vertical-align: middle
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table td.price,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table td.price {
  display: table-cell;
  font-size: 16px;
  margin: 0;
  text-align: center;
  vertical-align: middle
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table .qode-quantity-buttons,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table .qode-quantity-buttons {
  margin: 0;
  border: 0;
  background: 0 0;
  padding: 0 10px
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table .qode-quantity-buttons .qode-quantity-label,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table .qode-quantity-buttons .qode-quantity-label {
  display: none
}

#yith-quick-view-modal #yith-quick-view-content .summary table.group_table .qode-quantity-buttons .qode-quantity-input,
.yith-quick-view.yith-modal #yith-quick-view-content .summary table.group_table .qode-quantity-buttons .qode-quantity-input {
  width: 20px;
  margin: 0
}

#yith-quick-view-modal #yith-quick-view-content .summary .qode-yith-product-title,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-yith-product-title {
  display: block;
  margin: 0 0 7px
}

#yith-quick-view-modal #yith-quick-view-content .summary div[itemprop=offers],
.yith-quick-view.yith-modal #yith-quick-view-content .summary div[itemprop=offers] {
  position: relative;
  display: block
}

#yith-quick-view-modal #yith-quick-view-content .summary .price,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .price {
  position: relative;
  display: block;
  margin: 0 0 40px;
  line-height: 1;
  color: #000;
  font-size: 24px
}

#yith-quick-view-modal #yith-quick-view-content .summary .price *,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .price * {
  font-size: inherit;
  line-height: inherit
}

#yith-quick-view-modal #yith-quick-view-content .summary .price del,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .price del {
  margin: 0 8px 0 0;
  font-size: 16px;
  color: #000
}

#yith-quick-view-modal #yith-quick-view-content .summary .price ins,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .price ins {
  text-decoration: none
}

#yith-quick-view-modal #yith-quick-view-content .summary .woocommerce-product-rating,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .woocommerce-product-rating {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  line-height: 1;
  margin: 0 0 16px
}

#yith-quick-view-modal #yith-quick-view-content .summary .woocommerce-product-rating .star-rating,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .woocommerce-product-rating .star-rating {
  height: 20px;
  float: left;
  margin-top: 5px
}

#yith-quick-view-modal #yith-quick-view-content .summary .woocommerce-product-details__short-description,
#yith-quick-view-modal #yith-quick-view-content .summary div[itemprop=description],
.yith-quick-view.yith-modal #yith-quick-view-content .summary .woocommerce-product-details__short-description,
.yith-quick-view.yith-modal #yith-quick-view-content .summary div[itemprop=description] {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 0 0 40px
}

#yith-quick-view-modal #yith-quick-view-content .summary .woocommerce-product-details__short-description p,
#yith-quick-view-modal #yith-quick-view-content .summary div[itemprop=description] p,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .woocommerce-product-details__short-description p,
.yith-quick-view.yith-modal #yith-quick-view-content .summary div[itemprop=description] p {
  margin: 0
}

#yith-quick-view-modal #yith-quick-view-content .summary form.cart,
.yith-quick-view.yith-modal #yith-quick-view-content .summary form.cart {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 0 0 40px
}

#yith-quick-view-modal #yith-quick-view-content .summary .qode-single-product-share-wish,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-single-product-share-wish {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 0
}

#yith-quick-view-modal #yith-quick-view-content .summary .qode-single-product-share-wish .clear,
#yith-quick-view-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div:first-child:after,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-single-product-share-wish .clear,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div:first-child:after {
  display: none
}

#yith-quick-view-modal #yith-quick-view-content .summary .qode-single-product-share-wish .yith-wcwl-wishlistaddedbrowse a:after,
#yith-quick-view-modal #yith-quick-view-content .summary .qode-single-product-share-wish .yith-wcwl-wishlistexistsbrowse a:after,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-single-product-share-wish .yith-wcwl-wishlistaddedbrowse a:after,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-single-product-share-wish .yith-wcwl-wishlistexistsbrowse a:after {
  color: #1abc9c
}

#yith-quick-view-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div {
  padding: 0 20px
}

#yith-quick-view-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div:after,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div:after {
  content: "|";
  color: #d3d3d3;
  position: absolute;
  left: 0;
  top: -2px
}

#yith-quick-view-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div:first-child,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div:first-child {
  padding-left: 0
}

#yith-quick-view-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div:last-child,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .qode-single-product-share-wish>div:last-child {
  padding-right: 0
}

#yith-quick-view-modal #yith-quick-view-content .summary .social_share_list_holder,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .social_share_list_holder {
  float: left;
  position: relative
}

#yith-quick-view-modal #yith-quick-view-content .summary .social_share_list_holder>*,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .social_share_list_holder>* {
  display: table-cell;
  vertical-align: top;
  line-height: 22px
}

#yith-quick-view-modal #yith-quick-view-content .summary .social_share_list_holder>span,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .social_share_list_holder>span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #1abc9c;
  padding: 0 9px 0 0
}

#yith-quick-view-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist {
  width: auto;
  background-color: transparent;
  float: left
}

#yith-quick-view-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a,
#yith-quick-view-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a,
#yith-quick-view-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a {
  font-size: 12px;
  color: #1abc9c;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 22px;
  font-weight: 500
}

#yith-quick-view-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:after,
#yith-quick-view-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a:after,
#yith-quick-view-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a:after,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:after,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a:after,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a:after {
  color: #1abc9c;
  content: "\e089";
  font-size: 13px;
  float: left;
  padding-right: 6px;
  line-height: 19px
}

#yith-quick-view-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:after,
.yith-quick-view.yith-modal #yith-quick-view-content .summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:after {
  content: "\e030"
}

#yith-quick-view-modal #yith-quick-view-content .summary p.stock.in-stock,
#yith-quick-view-modal #yith-quick-view-content .summary p.stock.out-of-stock,
.yith-quick-view.yith-modal #yith-quick-view-content .summary p.stock.in-stock,
.yith-quick-view.yith-modal #yith-quick-view-content .summary p.stock.out-of-stock {
  color: #000;
  font-weight: 700;
  margin: 0 0 18px
}

#yith-quick-view-modal #yith-quick-view-close,
.yith-quick-view.yith-modal #yith-quick-view-close {
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  font-size: 0;
  line-height: 30px;
  font-weight: 400;
  text-align: center;
  opacity: 1;
  border: 0;
  color: #000
}

#yith-quick-view-modal #yith-quick-view-close:hover,
.qode-pl-holder .qode-pli-inner .qode-pli-text-inner .qode-yith-wcqv-holder .yith-wcqv-button:hover:before,
.qode-plc-holder .qode-plc-item .qode-plc-text-inner .qode-yith-wcqv-holder .yith-wcqv-button:hover:before,
.yith-quick-view.yith-modal #yith-quick-view-close:hover,
ul.products>.product .qode-pl-inner .qode-pl-text-inner .qode-yith-wcqv-holder .yith-wcqv-button:hover:before {
  opacity: .8
}

#yith-quick-view-modal #yith-quick-view-close:before,
.yith-quick-view.yith-modal #yith-quick-view-close:before {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-family: ElegantIcons;
  content: "\4d";
  font-size: 30px;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

#yith-quick-view-modal .qode-owl-slider .owl-nav .qode-next-icon,
#yith-quick-view-modal .qode-owl-slider .owl-nav .qode-prev-icon,
.yith-quick-view.yith-modal .qode-owl-slider .owl-nav .qode-next-icon,
.yith-quick-view.yith-modal .qode-owl-slider .owl-nav .qode-prev-icon {
  font-size: 30px
}

ul.products>.product>.yith-wcqv-button {
  display: none !important
}

.qode-pl-holder .qode-pli-inner .qode-pli-text-inner .qode-yith-wcqv-holder,
.qode-plc-holder .qode-plc-item .qode-plc-text-inner .qode-yith-wcqv-holder,
ul.products>.product .qode-pl-inner .qode-pl-text-inner .qode-yith-wcqv-holder {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  background-color: #000;
  width: 26px;
  text-align: center
}

.qode-pl-holder .qode-pli-inner .qode-pli-text-inner .qode-yith-wcqv-holder .yith-wcqv-button,
.qode-plc-holder .qode-plc-item .qode-plc-text-inner .qode-yith-wcqv-holder .yith-wcqv-button,
ul.products>.product .qode-pl-inner .qode-pl-text-inner .qode-yith-wcqv-holder .yith-wcqv-button {
  color: #fff
}

.qode-pl-holder .qode-pli-inner .qode-pli-text-inner .qode-yith-wcqv-holder .yith-wcqv-button:before,
.qode-plc-holder .qode-plc-item .qode-plc-text-inner .qode-yith-wcqv-holder .yith-wcqv-button:before,
ul.products>.product .qode-pl-inner .qode-pl-text-inner .qode-yith-wcqv-holder .yith-wcqv-button:before {
  content: "\e037";
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-family: dripicons-v2;
  font-size: 14px;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  transition: opacity .2s
}

.qode-pl-holder .qode-pli-inner .qode-pli-text-inner .qode-yith-wcqv-holder .yith-wcqv-button div,
.qode-plc-holder .qode-plc-item .qode-plc-text-inner .qode-yith-wcqv-holder .yith-wcqv-button div,
ul.products>.product .qode-pl-inner .qode-pl-text-inner .qode-yith-wcqv-holder .yith-wcqv-button div {
  background-size: 15px 15px !important
}

#yith-wcwl-popup-message {
  position: absolute;
  margin: 0 !important;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 1px solid #ebebeb;
  border-radius: 0;
  padding: 0 21px;
  line-height: 35px
}

#yith-wcwl-popup-message #yith-wcwl-message {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -.14px
}

#yith-wcwl-popup-message #yith-wcwl-message:before {
  position: relative;
  font-family: ElegantIcons;
  content: "\4e";
  top: 2px;
  right: 7px
}

#yith-wcwl-popup-message.qode-wishlist-vanish-out {
  -webkit-animation: qodeWishlistvanishOut 1s both;
  -moz-animation: qodeWishlistvanishOut 1s both;
  animation: qodeWishlistvanishOut 1s both
}

@keyframes qodeWishlistvanishOut {
  0% {
    opacity: 1;
    -webkit-transform: scale(1, 1) translate(-50%, -50%);
    -moz-transform: scale(1, 1) translate(-50%, -50%);
    transform: scale(1, 1) translate(-50%, -50%);
    -webkit-filter: blur(0);
    filter: blur(0)
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(2, 2) translate(-50%, -50%);
    -moz-transform: scale(2, 2) translate(-50%, -50%);
    transform: scale(2, 2) translate(-50%, -50%);
    -webkit-filter: blur(10px);
    filter: blur(10px)
  }
}

.yith-wcwl-add-button a:not(.qode-adding-to-wishlist):hover:after,
.yith-wcwl-wishlistaddedbrowse a:not(.qode-adding-to-wishlist):hover:after,
.yith-wcwl-wishlistexistsbrowse a:not(.qode-adding-to-wishlist):hover:after,
header.dark .qode-wishlist-widget-holder a:hover,
header.light .qode-wishlist-widget-holder a:hover {
  opacity: .8
}

.qode-yith-wcqv-holder {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.yith-wcwl-add-to-wishlist {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  background-color: #555;
  width: 26px;
  text-align: center;
  backface-visibility: hidden
}

.yith-wcwl-add-button a,
.yith-wcwl-wishlistaddedbrowse a,
.yith-wcwl-wishlistexistsbrowse a {
  position: relative;
  display: block;
  vertical-align: top;
  font-size: 0;
  line-height: inherit;
  color: #fff
}

.yith-wcwl-add-button a:after,
.yith-wcwl-wishlistaddedbrowse a:after,
.yith-wcwl-wishlistexistsbrowse a:after {
  content: "\e089";
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-family: ElegantIcons;
  font-size: 11px;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  transition: opacity .2s
}

@-webkit-keyframes qodeWishlistLoading {

  0%,
  100% {
    opacity: 1
  }

  50% {
    opacity: .2
  }
}

@keyframes qodeWishlistLoading {

  0%,
  100% {
    opacity: 1
  }

  50% {
    opacity: .2
  }
}

.yith-wcwl-add-button a.qode-adding-to-wishlist:after,
.yith-wcwl-wishlistaddedbrowse a.qode-adding-to-wishlist:after,
.yith-wcwl-wishlistexistsbrowse a.qode-adding-to-wishlist:after {
  -webkit-animation: qodeWishlistLoading 1s ease infinite;
  -moz-animation: qodeWishlistLoading 1s ease infinite;
  animation: qodeWishlistLoading 1s ease infinite
}

.yith-wcwl-add-button a:hover,
.yith-wcwl-wishlistaddedbrowse a:hover,
.yith-wcwl-wishlistexistsbrowse a:hover {
  color: #fff
}

.yith-wcwl-wishlistaddedbrowse a:after,
.yith-wcwl-wishlistexistsbrowse a:after {
  content: "\4e";
  color: #fff
}

.yith-wcwl-add-button .ajax-loading {
  display: none !important
}

.woocommerce-wishlist .wishlist-title,
.yith-wcwl-wishlistaddedbrowse span,
.yith-wcwl-wishlistexistsbrowse span {
  display: none
}

.woocommerce-wishlist .woocommerce-error,
.woocommerce-wishlist .woocommerce-info,
.woocommerce-wishlist .woocommerce-message {
  position: relative;
  display: block;
  padding: 15px 30px;
  margin: 50px 0 30px;
  line-height: 48px;
  list-style: none;
  background-color: transparent;
  border: 1px solid #000
}

.woocommerce-wishlist .woocommerce-error a,
.woocommerce-wishlist .woocommerce-info a,
.woocommerce-wishlist .woocommerce-message a {
  float: right
}

.woocommerce-wishlist table.wishlist_table {
  font-size: inherit;
  margin: 50px 0 100px;
  border-color: #e0dede
}

.woocommerce-wishlist table.wishlist_table thead th {
  color: #000;
  border-color: #e0dede
}

.woocommerce-wishlist table.wishlist_table tbody tr {
  border: 0;
  border-bottom: 1px solid #e0dede;
  border-right: 1px solid #e0dede
}

.woocommerce-wishlist table.wishlist_table tbody tr:last-child {
  border-bottom: 1px solid #e0dede;
  border-right: 1px solid #e0dede
}

.woocommerce-wishlist table.wishlist_table tbody tr td {
  padding: 5px 10px;
  vertical-align: middle
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-remove {
  border-left: 1px solid #e0dede;
  padding: 5px 10px
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-remove a {
  font-size: 0
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-remove a:after {
  content: "\f404";
  display: inline-block;
  vertical-align: middle;
  speak: none;
  color: inherit;
  font-family: Ionicons;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-thumbnail a,
.woocommerce-wishlist table.wishlist_table tbody tr td.product-thumbnail img {
  display: block
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-name a {
  color: #888
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-name .yith-wcqv-button {
  height: 38px;
  line-height: 38px;
  padding: 0 28px;
  margin: 0
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-price {
  font-size: 14px
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-price ins {
  text-decoration: none
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-stock-status span {
  color: #000 !important
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-add-to-cart {
  text-align: center
}

.woocommerce-wishlist table.wishlist_table tbody tr td.product-add-to-cart a {
  display: inline-block !important;
  padding: 0 30px;
  margin: 0;
  line-height: 38px;
  height: 38px
}

.woocommerce-wishlist table.wishlist_table .yith-wcwl-share {
  margin: 0
}

.qode-single-product-summary .yith-wcwl-add-to-wishlist {
  width: auto;
  background-color: transparent;
  display: block;
  margin: 25px 0;
  text-align: left
}

.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button,
.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse,
.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse {
  vertical-align: middle
}

.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button.show,
.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse.show,
.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse.show {
  display: inline-block !important
}

.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a,
.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a,
.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a {
  font-size: 13px;
  color: #1abc9c;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 22px;
  font-weight: 500
}

.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:after,
.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistaddedbrowse a:after,
.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-wishlistexistsbrowse a:after {
  content: "\e089";
  font-size: 12px;
  float: left;
  padding-right: 6px;
  line-height: 19px;
  color: #1abc9c
}

.qode-single-product-summary .yith-wcwl-add-to-wishlist .yith-wcwl-add-button a:after {
  content: "\e030"
}

.qode-wishlist-widget-holder {
  display: inline-block;
  vertical-align: middle
}

.qode-wishlist-widget-holder a {
  color: #1abc9c;
  font-size: 13px;
  margin: 0 10px;
}

.qode-wishlist-widget-holder a .qode-wishlist-items-number {
  color: #000;
  font-size: 12px;
}

.qode-wishlist-widget-holder a:hover {
  color: #000;
}

header.light .qode-wishlist-widget-holder a,
header.light .qode-wishlist-widget-holder a .qode-wishlist-items-number {
  color: #fff;
}

header.dark .qode-wishlist-widget-holder a,
header.dark .qode-wishlist-widget-holder a .qode-wishlist-items-number {
  color: #000;
}

.woocommerce .quantity .minus,
.woocommerce #content .quantity .minus,
.woocommerce-page .quantity .minus,
.woocommerce-page #content .quantity .minus,
.woocommerce .quantity .plus,
.woocommerce #content .quantity .plus,
.woocommerce-page .quantity .plus,
.woocommerce-page #content .quantity .plus {
  border: 1px solid #bcbcbc;
  border-radius: 0;
  height: 53px;
  width: 53px;
}

.woocommerce input[type='text']:not(.qode_search_field),
.woocommerce-page input[type='text']:not(.qode_search_field) {
  color: #212121;
  font-size: 18px;
  line-height: 28px;
  font-style: italic;
  font-family: "EB Garamond", sans-serif;
}

.q_accordion_holder.accordion.boxed .ui-accordion-header {
  text-align: left;
  padding-left: 20px;
  letter-spacing: 2px;
}