.container {
  padding: 50px 0;
}

.container_inner {
  margin: 0 auto;
}

.product_image {
  max-width: 100%;
  height: auto;
  min-width: 210px;
  min-height: 250px;
}

.information {
  margin-left: 10px !important;
}
