.information-store {
    padding: 150px 260px;
}

.information-store p {
    font-family: NgocBaoCacVietTay1;
    font-size: 1.2em;
    line-height: 1.5;
    color: #000;
    word-spacing: 10px;
    text-align: left;
    margin-bottom: 10px;
}

section.parallax_section_holder {
    height: auto !important;
    min-height: 400px
}

@media only screen and (max-width: 480px) {
    .information-store {
        padding: 30px 20px;
    }
}

.typing {
    width: 100%;
    /* overflow: hidden; */
    top: 0px;
    position: relative;
    animation: typeIt 100s steps(40) infinite;
}

.typing p {
    font-size: 1.2em;
    text-indent: 2em;
}

.typing .text-cover {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 27px;
    background: #fff;
    width: 100%;
    animation: revealLine 10s steps(40) infinite;
}

@keyframes revealLine {
    0% {
        left: 0%;
    }

    100% {
        left: 100%;
    }
}

@keyframes typeIt {
    0% {
        top: 0;
        height: 27px;
    }

    100% {
        top: -30em;
        height: 82em;
    }
}
