@media only print {
  header,footer,.title_outer,.portfolio_print,header.page_header {
      display: none!important
  }

  .container_inner {
      max-width: 80%
  }

  div[class*=columns]>div[class^=column] {
      float: none;
      width: 100%
  }

  html,body,.wrapper,.wrapper-inner .container,.container-inner {
      padding-top: 0!important;
      margin-top: 0!important;
      top: 0!important
  }
}
