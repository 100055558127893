.qode-grid-col-10:after,
.qode-grid-col-10:before,
.qode-grid-col-11:after,
.qode-grid-col-11:before,
.qode-grid-col-12:after,
.qode-grid-col-12:before,
.qode-grid-col-1:after,
.qode-grid-col-1:before,
.qode-grid-col-2:after,
.qode-grid-col-2:before,
.qode-grid-col-3:after,
.qode-grid-col-3:before,
.qode-grid-col-4:after,
.qode-grid-col-4:before,
.qode-grid-col-5:after,
.qode-grid-col-5:before,
.qode-grid-col-6:after,
.qode-grid-col-6:before,
.qode-grid-col-7:after,
.qode-grid-col-7:before,
.qode-grid-col-8:after,
.qode-grid-col-8:before,
.qode-grid-col-9:after,
.qode-grid-col-9:before,
.qode-grid-row:after,
.qode-grid-row:before {
  content: " ";
  display: table;
}

.drop_down .second .inner ul.right li a,
.drop_down .second .inner ul.right li h5,
.text-align-right {
  text-align: right;
}

.bypostauthor,
.gallery-caption,
.sticky,
.wp-caption,
.wp-caption-text {
  opacity: 1;
}

.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}

.aligncenter,
.clear:after,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

.clearfix:after {
  clear: both;
}

.clear:after {
  clear: both;
  content: "";
}

::selection {
  background: #1abc9c;
  color: #fff;
}

::-moz-selection {
  background: #1abc9c;
  color: #fff;
}

.alignleft {
  float: left;
  margin: 0 20px 20px 0;
}

.alignright {
  float: right;
  margin: 0 0 20px 20px;
}

.aligncenter {
  margin: 10px auto;
}

.header_top .aligncenter {
  margin: 8px auto;
}

.text-align-left {
  text-align: left;
}

.ajax_loader .stripes,
.ajax_loader .two_rotating_circles,
.ajax_loader .wave,
.text-align-center,
header.centered_logo,
table td,
table th {
  text-align: center;
}

.content ol ol,
.content ul ul {
  padding: 0 0 0 15px;
}

a,
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var {
  background: 0 0;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  outline: 0;
}

a img,
table {
  border: none;
}

blockquote:after,
blockquote:before {
  content: '';
  content: none;
}

sup {
  font-size: .5em;
  vertical-align: super;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  vertical-align: middle;
}

table th {
  border: 0;
  padding: 5px 7px;
}

table td {
  padding: 5px 10px;
}

/* img {
  max-width: 100%;
  height: auto;
  image-rendering: optimizeQuality
} */

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #303030;
}

body:not([class*=bridge-core]) .h1,
body:not([class*=bridge-core]) .h2,
body:not([class*=bridge-core]) .h3,
body:not([class*=bridge-core]) .h4,
body:not([class*=bridge-core]) .h5,
body:not([class*=bridge-core]) .h6,
body:not([class*=bridge-core]) h1,
body:not([class*=bridge-core]) h2,
body:not([class*=bridge-core]) h3,
body:not([class*=bridge-core]) h4,
body:not([class*=bridge-core]) h5,
body:not([class*=bridge-core]) h6 {
  margin: 20px 0;
}

.h1,
body.qode-overridden-elementors-fonts .elementor-widget-heading h1.elementor-heading-title,
h1 {
  font-size: 30px;
  line-height: 1.25em;
}

.h2,
body.qode-overridden-elementors-fonts .elementor-widget-heading h2.elementor-heading-title,
h2 {
  font-size: 24px;
  line-height: 1.384615384615385em;
  text-transform: uppercase;
  font-weight: 600;
}

.h3,
body.qode-overridden-elementors-fonts .elementor-widget-heading h3.elementor-heading-title,
h3 {
  font-size: 17px;
  line-height: 1.304347826086957em;
  text-transform: uppercase;
  font-weight: 600;
}

.h4,
body.qode-overridden-elementors-fonts .elementor-widget-heading h4.elementor-heading-title,
h4 {
  font-size: 19px;
  line-height: 1.375em;
  font-weight: 400;
}

.h5,
body.qode-overridden-elementors-fonts .elementor-widget-heading h5.elementor-heading-title,
h5 {
  font-size: 15px;
  line-height: 1.571428571428571em;
  text-transform: uppercase;
  font-weight: 600;
}

.h6,
body.qode-overridden-elementors-fonts .elementor-widget-heading h6.elementor-heading-title,
h6 {
  font-size: 13px;
  line-height: 1.538461538461538em;
  text-transform: uppercase;
  font-weight: 600;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #303030;
  -webkit-transition: color .1s linear;
  -moz-transition: color .1s linear;
  transition: color .1s linear;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: #1abc9c;
}

a,
p a {
  color: #303030;
  text-decoration: none;
  cursor: pointer;
}

a:hover,
p a:hover {
  color: #1abc9c;
  text-decoration: none;
}

.woocommerce input[type=button],
.woocommerce-page input[type=button],
input[type=submit] {
  appearance: none;
}

ul {
  list-style-position: inside
}

@-webkit-keyframes outer {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes outer {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes inner {
  0% {
    -webkit-transform: rotate(-100.8deg);
    -moz-transform: rotate(-100.8deg);
    transform: rotate(-100.8deg);
  }

  100% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }
}

@keyframes inner {
  0% {
    -webkit-transform: rotate(-100.8deg);
    -moz-transform: rotate(-100.8deg);
    transform: rotate(-100.8deg);
  }

  100% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }
}

@-webkit-keyframes arc {
  0% {
    stroke-dasharray: 1 210.49px;
    stroke-dashoffset: 0;
  }

  40% {
    stroke-dasharray: 151.55px, 210.49px;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 1 210.49px;
    stroke-dashoffset: -151.55px;
  }
}

@keyframes arc {
  0% {
    stroke-dasharray: 1 210.49px;
    stroke-dashoffset: 0;
  }

  40% {
    stroke-dasharray: 151.55px, 210.49px;
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dasharray: 1 210.49px;
    stroke-dashoffset: -151.55px;
  }
}

.qode-lazy-preloader {
  font-size: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -37.5px 0 0 -37.5px;
  display: inline-block;
  line-height: normal;
  z-index: 10;
  -webkit-animation: outer 6.6s linear infinite;
  animation: outer 6.6s linear infinite;
}

.qode-lazy-preloader svg {
  -webkit-animation: inner 1.32s linear infinite;
  animation: inner 1.32s linear infinite;
}

.qode-lazy-preloader svg circle {
  fill: none;
  stroke: #1abc9c;
  stroke-linecap: round;
  -webkit-animation: arc 1.32s cubic-bezier(.8, 0, .4, .8) infinite;
  animation: arc 1.32s cubic-bezier(.8, 0, .4, .8) infinite;
}

img[data-lazy=true] {
  opacity: 0;
}

.qode-type1-gradient-left-to-right,
.qode-type1-gradient-left-to-right-after:after {
  background: -webkit-linear-gradient(left, #31c8a2, #ae66fd);
  background: -o-linear-gradient(right, #31c8a2, #ae66fd);
  background: -moz-linear-gradient(right, #31c8a2, #ae66fd);
  background: linear-gradient(to right, #31c8a2, #ae66fd);
}

.qode-type1-gradient-bottom-to-top,
.qode-type1-gradient-bottom-to-top-after:after {
  background: -webkit-linear-gradient(bottom, #31c8a2, #ae66fd);
  background: -o-linear-gradient(top, #31c8a2, #ae66fd);
  background: -moz-linear-gradient(top, #31c8a2, #ae66fd);
  background: linear-gradient(to top, #31c8a2, #ae66fd);
}

.qode-type1-gradient-left-bottom-to-right-top {
  background: -webkit-linear-gradient(right top, #ae66fd, #31c8a2);
  background: -o-linear-gradient(right top, #31c8a2, #ae66fd);
  background: -moz-linear-gradient(right top, #31c8a2, #ae66fd);
  background: linear-gradient(to right top, #31c8a2, #ae66fd);
}

.qode-type1-gradient-left-to-right-2x {
  background: -webkit-linear-gradient(left, #31c8a2 0, #ae66fd 50%, #31c8a2 100%);
  background: -o-linear-gradient(right, #31c8a2 0, #ae66fd 50%, #31c8a2 100%);
  background: -moz-linear-gradient(right, #31c8a2 0, #ae66fd 50%, #31c8a2 100%);
  background: linear-gradient(to right, #31c8a2 0, #ae66fd 50%, #31c8a2 100%);
  background-position: 0;
  background-size: 200% 200%;
}

.qode-type1-gradient-left-to-right-text i,
.qode-type1-gradient-left-to-right-text i:before,
.qode-type1-gradient-left-to-right-text span {
  background: -webkit-linear-gradient(right top, #ae66fd, #31c8a2);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #31c8a2;
}

.qode-type1-gradient-bottom-to-top-text i,
.qode-type1-gradient-bottom-to-top-text i:before,
.qode-type1-gradient-bottom-to-top-text span,
.qode-type1-gradient-bottom-to-top-text span span,
.qode-type1-gradient-bottom-to-top-text-hover:hover i,
.qode-type1-gradient-bottom-to-top-text-hover:hover i:before,
.qode-type1-gradient-bottom-to-top-text-hover:hover span,
.qode-type1-gradient-bottom-to-top-text-hover:hover span span {
  background: -webkit-linear-gradient(bottom, #31c8a2, #ae66fd);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #31c8a2;
}

.wrapper,
body {
  background-color: #fff;
}

html {
  height: 100%;
  margin: 0 !important;
  -webkit-transition: all 1.3s ease-out;
  -moz-transition: all 1.3s ease-out;
  -o-transition: all 1.3s ease-out;
  -ms-transition: all 1.3s ease-out;
  transition: all 1.3s ease-out;
}

body {
  font-family: Raleway, sans-serif;
  font-size: 14px;
  line-height: 26px;
  color: #818181;
  font-weight: 400;
  overflow-y: scroll;
  overflow-x: hidden !important;
  -webkit-font-smoothing: antialiased;
}

.wrapper {
  position: relative;
  z-index: 1000;
  transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  left: 0;
  height: 100%;
  overflow: auto;
}

.right_side_menu_opened .wrapper,
.right_side_menu_opened .wrapper header.fixed,
.right_side_menu_opened .wrapper header.fixed_hiding,
.right_side_menu_opened .wrapper header.fixed_top_header .top_header,
.right_side_menu_opened .wrapper header.sticky {
  left: -270px
}

@media only screen and (min-width: 1100px) {
  .right_side_menu_opened:not(.boxed) .carousel-inner:not(.relative_position) {
    left: -270px !important
  }
}

.wrapper_inner {
  width: 100%;
  overflow: hidden;
}

body.boxed .wrapper_inner {
  overflow: visible;
}

body.boxed .content {
  overflow: hidden;
}

.meta {
  display: none
}

.ajax_loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1100;
  display: none
}

.q_logo a,
.q_logo img,
.qode-page-loading-effect-holder .ajax_loader {
  display: block
}

.qode-page-loading-effect-holder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: #fff
}

.qode-page-loading-effect-holder.qode-hide-spinner .ajax_loader {
  display: none
}

@media only screen and (min-width: 1000px) {
  .ajax_loader {
    margin-left: 130px
  }

  .ajax_loader {
    margin-left: 20px
  }
}

.ajax_loader_1 {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%
}

.ajax_loader_2 {
  margin: -50% 0 0 -50%
}

.ajax_loader .pulse {
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  background-color: #303030;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;
  border-radius: 16px;
  -webkit-animation: scaleout 1s infinite ease-in-out;
  animation: scaleout 1s infinite ease-in-out
}

@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(0)
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0
  }
}

@-moz-keyframes scaleout {
  0% {
    -moz-transform: scale(0)
  }

  100% {
    -moz-transform: scale(1);
    opacity: 0
  }
}

@-ms-keyframes scaleout {
  0% {
    -ms-transform: scale(0)
  }

  100% {
    -ms-transform: scale(1);
    opacity: 0
  }
}

@-o-keyframes scaleout {
  0% {
    -o-transform: scale(0)
  }

  100% {
    -o-transform: scale(1);
    opacity: 0
  }
}

@keyframes scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0)
  }

  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0
  }
}

.ajax_loader .double_pulse {
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  position: relative
}

.ajax_loader .double_pulse .double-bounce1,
.ajax_loader .double_pulse .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #303030;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out
}

.ajax_loader .double_pulse .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s
}

@-webkit-keyframes bounce {

  0%,
  100% {
    -webkit-transform: scale(0)
  }

  50% {
    -webkit-transform: scale(1)
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0)
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1)
  }
}

.ajax_loader .cube {
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  background-color: #303030;
  -webkit-animation: rotateplane 1.2s infinite ease-in-out;
  animation: rotateplane 1.2s infinite ease-in-out
}

@-webkit-keyframes rotateplane {
  0% {
    -webkit-transform: perspective(120px)
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg)
  }

  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
  }
}

@keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0) rotateY(0);
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0)
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0)
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg)
  }
}

.ajax_loader .rotating_cubes {
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  position: relative;
}

.ajax_loader .rotating_cubes .cube1,
.ajax_loader .rotating_cubes .cube2 {
  background-color: #303030;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: cubemove 1.8s infinite ease-in-out;
  animation: cubemove 1.8s infinite ease-in-out;
}

.ajax_loader .rotating_cubes .cube2 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

@-webkit-keyframes cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(.5)
  }

  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg)
  }

  75% {
    -webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5)
  }

  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(.5);
  }

  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }

  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }

  75% {
    transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5);
    -webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5);
  }

  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

.ajax_loader .stripes {
  width: 50px;
  height: 60px;
  margin: -30px 0 0 -25px;
  font-size: 10px;
}

.ajax_loader .stripes>div {
  background-color: #303030;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 0 3px 0 0;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.ajax_loader .stripes .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.ajax_loader .stripes .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.ajax_loader .stripes .rect4 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

.ajax_loader .stripes .rect5 {
  -webkit-animation-delay: -.8s;
  animation-delay: -.8s;
}

@-webkit-keyframes stretchdelay {
  0%,
  100%,
  40% {
    -webkit-transform: scaleY(.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes stretchdelay {
  0%,
  100%,
  40% {
    transform: scaleY(.4);
    -webkit-transform: scaleY(.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.ajax_loader .wave {
  width: 72px;
  margin: -9px 0 0 -36px;
}

.ajax_loader .wave>div {
  width: 18px;
  height: 18px;
  background-color: #303030;
  margin: 0 3px 0 0;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ajax_loader .wave .bounce1 {
  -webkit-animation-delay: -.32s;
  animation-delay: -.32s;
}

.ajax_loader .wave .bounce2 {
  -webkit-animation-delay: -.16s;
  animation-delay: -.16s;
}

@-webkit-keyframes bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  100%,
  80% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.ajax_loader .two_rotating_circles {
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  position: relative;
  -webkit-animation: rotatecircles 2s infinite linear;
  animation: rotatecircles 2s infinite linear;
}

.ajax_loader .two_rotating_circles .dot1,
.ajax_loader .two_rotating_circles .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #303030;
  border-radius: 100%;
  -webkit-animation: bounce 2s infinite ease-in-out;
  animation: bounce 2s infinite ease-in-out;
}

.ajax_loader .two_rotating_circles .dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes rotatecircles {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotatecircles {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

.ajax_loader .five_rotating_circles {
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  position: relative;
}

.ajax_loader .five_rotating_circles .container1>div,
.ajax_loader .five_rotating_circles .container2>div,
.ajax_loader .five_rotating_circles .container3>div {
  width: 12px;
  height: 12px;
  background-color: #303030;
  border-radius: 100%;
  position: absolute;
  -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
  animation: bouncedelay 1.2s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.ajax_loader .five_rotating_circles .spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.ajax_loader .five_rotating_circles .container2 {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.ajax_loader .five_rotating_circles .container3 {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.ajax_loader .five_rotating_circles .circle1 {
  top: 0;
  left: 0;
}

.ajax_loader .five_rotating_circles .circle2 {
  top: 0;
  right: 0;
}

.ajax_loader .five_rotating_circles .circle3 {
  right: 0;
  bottom: 0;
}

.ajax_loader .five_rotating_circles .circle4 {
  left: 0;
  bottom: 0;
}

.ajax_loader .five_rotating_circles .container2 .circle1 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.ajax_loader .five_rotating_circles .container3 .circle1 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.ajax_loader .five_rotating_circles .container1 .circle2 {
  -webkit-animation-delay: -.9s;
  animation-delay: -.9s;
}

.ajax_loader .five_rotating_circles .container2 .circle2 {
  -webkit-animation-delay: -.8s;
  animation-delay: -.8s;
}

.ajax_loader .five_rotating_circles .container3 .circle2 {
  -webkit-animation-delay: -.7s;
  animation-delay: -.7s;
}

.ajax_loader .five_rotating_circles .container1 .circle3 {
  -webkit-animation-delay: -.6s;
  animation-delay: -.6s;
}

.ajax_loader .five_rotating_circles .container2 .circle3 {
  -webkit-animation-delay: -.5s;
  animation-delay: -.5s;
}

.ajax_loader .five_rotating_circles .container3 .circle3 {
  -webkit-animation-delay: -.4s;
  animation-delay: -.4s;
}

.ajax_loader .five_rotating_circles .container1 .circle4 {
  -webkit-animation-delay: -.3s;
  animation-delay: -.3s;
}

.ajax_loader .five_rotating_circles .container2 .circle4 {
  -webkit-animation-delay: -.2s;
  animation-delay: -.2s;
}

.ajax_loader .five_rotating_circles .container3 .circle4 {
  -webkit-animation-delay: -.1s;
  animation-delay: -.1s;
}

body.smooth_scroll {
  overflow-y: hidden;
}

body.boxed .footer_inner,
body.boxed .wrapper_inner {
  width: 1150px;
  margin: 0 auto;
}

header {
  width: 100%;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  position: relative;
  z-index: 110;
  backface-visibility: hidden
}

.header_bottom,
.header_inner_right,
.header_top,
.q_logo,
.q_logo a {
  position: relative
}

header.menu_position_left .header_inner_left {
  z-index: 101
}

.boxed header {
  background-color: transparent !important;
  border: 0 !important
}

.boxed .header_inner {
  width: 1150px;
  margin: 0 auto
}

.header_inner_right {
  float: right;
  z-index: 110
}

.header_top {
  z-index: 111;
  line-height: 33px;
  height: 33px;
  padding: 0;
  font-size: 13px;
  background-color: #fff;
  -webkit-transition: all .2s ease 0s;
  -moz-transition: all .2s ease 0s;
  -o-transition: all .2s ease 0s;
  transition: all .2s ease 0s
}

header.scrolled .header_top {
  background-color: #fff !important
}

.header_top p {
  line-height: 32px;
  padding: 0 15px
}

.header_top .left {
  float: left;
  height: 100%
}

.header_top .right {
  float: right;
  height: 100%
}

.header_top .right .inner #lang_sel {
  float: left;
  padding: 0
}

.header_top .inner #lang_sel>ul {
  list-style: none
}

.header_top .left .inner>div,
.header_top .left .inner>div:last-child {
  float: left;
  border-bottom: 0;
  border-top: 0
}

.header_top .right .inner>div {
  border-left: 0;
  float: left
}

header.scrolled:not(.scroll_header_top_area) .header_top {
  border-bottom: 0
}

header.light.header_style_on_scroll .header-widget,
header.light.header_style_on_scroll .header-widget a,
header.light.header_style_on_scroll .header-widget p,
header.light.header_style_on_scroll .header-widget span,
header.light.header_style_on_scroll .header-widget.widget_nav_menu ul.menu>li>a,
header.light.header_style_on_scroll .q_social_icon_holder i.simple_social,
header.light.header_style_on_scroll .q_social_icon_holder span.simple_social,
header.light:not(.sticky) .header-widget,
header.light:not(.sticky) .header-widget a,
header.light:not(.sticky) .header-widget p,
header.light:not(.sticky) .header-widget span,
header.light:not(.sticky) .header-widget.widget_nav_menu ul.menu>li>a,
header.light:not(.sticky) .q_social_icon_holder i.simple_social,
header.light:not(.sticky) .q_social_icon_holder span.qode_icon_font_elegant,
header.light:not(.sticky) .q_social_icon_holder span.simple_social {
  color: #fff;
}

header.light.header_style_on_scroll #lang_sel>ul>li>a,
header.light.header_style_on_scroll #lang_sel_click>ul>li>a,
header.light.header_style_on_scroll .q_social_icon_holder i.simple_social,
header.light.header_style_on_scroll .q_social_icon_holder span.simple_social,
header.light.header_style_on_scroll .textwidget span,
header.light.header_style_on_scroll .textwidget span:hover,
header.light:not(.sticky) #lang_sel>ul>li>a,
header.light:not(.sticky) #lang_sel_click>ul>li>a,
header.light:not(.sticky) .q_social_icon_holder i.simple_social,
header.light:not(.sticky) .q_social_icon_holder span.qode_icon_font_elegant,
header.light:not(.sticky) .q_social_icon_holder span.simple_social,
header.light:not(.sticky) .textwidget span:not(.qode-btn-text),
header.light:not(.sticky) .textwidget span:not(.qode-btn-text):hover {
  color: #fff !important;
}

header.dark.header_style_on_scroll .header-widget,
header.dark.header_style_on_scroll .header-widget a,
header.dark.header_style_on_scroll .header-widget p,
header.dark.header_style_on_scroll .header-widget span,
header.dark.header_style_on_scroll .header-widget.widget_nav_menu ul.menu>li>a,
header.dark.header_style_on_scroll .q_social_icon_holder i.simple_social,
header.dark.header_style_on_scroll .q_social_icon_holder span.simple_social,
header.dark:not(.sticky) .header-widget,
header.dark:not(.sticky) .header-widget a,
header.dark:not(.sticky) .header-widget p,
header.dark:not(.sticky) .header-widget span,
header.dark:not(.sticky) .header-widget.widget_nav_menu ul.menu>li>a,
header.dark:not(.sticky) .q_social_icon_holder i.simple_social,
header.dark:not(.sticky) .q_social_icon_holder span.qode_icon_font_elegant,
header.dark:not(.sticky) .q_social_icon_holder span.simple_social {
  color: #000;
}

header.dark.header_style_on_scroll #lang_sel>ul>li>a,
header.dark.header_style_on_scroll #lang_sel_click>ul>li>a,
header.dark.header_style_on_scroll .q_social_icon_holder i.simple_social,
header.dark.header_style_on_scroll .q_social_icon_holder span.simple_social,
header.dark.header_style_on_scroll .textwidget span,
header.dark.header_style_on_scroll .textwidget span:hover,
header.dark:not(.sticky) #lang_sel>ul>li>a,
header.dark:not(.sticky) #lang_sel_click>ul>li>a,
header.dark:not(.sticky) .q_social_icon_holder i.simple_social,
header.dark:not(.sticky) .q_social_icon_holder span.qode_icon_font_elegant,
header.dark:not(.sticky) .q_social_icon_holder span.simple_social,
header.dark:not(.sticky) .textwidget span:not(.qode-btn-text),
header.dark:not(.sticky) .textwidget span:not(.qode-btn-text):hover {
  color: #000 !important;
}

.header_bottom {
  padding: 0 45px;
  background-color: #fff;
  -webkit-transition: all .2s ease 0s;
  -moz-transition: all .2s ease 0s;
  -o-transition: all .2s ease 0s;
  transition: all .2s ease 0s;
}

.boxed .header_bottom {
  padding: 0 25px;
}

.logo_wrapper {
  height: 100px;
  float: left;
}

.q_logo {
  top: 50%;
  left: 0;
}

.q_logo a {
  visibility: hidden;
}

.q_logo img {
  opacity: 1;
  position: fixed;
  top: -50%;
  width: auto !important;
  max-width: none;
  -webkit-transition: opacity .6s ease-in-out;
  transition: opacity .6s ease-in-out;
}

header.sticky .header_fixed_right_area,
header.sticky .header_top {
  display: none;
}

.menu_position_left .q_logo img {
  -webkit-transition: none;
  transition: none;
}

.q_logo img.dark,
.q_logo img.light,
.q_logo img.mobile,
.q_logo img.popup,
.q_logo img.sticky {
  opacity: 0;
}

header.scrolled.dark:not(.header_style_on_scroll) .q_logo img.normal,
header.scrolled.light:not(.header_style_on_scroll) .q_logo img.normal,
header.scrolled:not(.header_style_on_scroll) .q_logo img.normal {
  opacity: 1;
}

header.light .q_logo img.normal,
header.scrolled.dark:not(.header_style_on_scroll) .q_logo img.dark,
header.scrolled.dark:not(.header_style_on_scroll) .q_logo img.light,
header.scrolled.light:not(.header_style_on_scroll) .q_logo img.dark,
header.scrolled.light:not(.header_style_on_scroll) .q_logo img.light,
header.scrolled:not(.header_style_on_scroll) .q_logo img.dark,
header.scrolled:not(.header_style_on_scroll) .q_logo img.light {
  opacity: 0
}

header.light .q_logo img.light {
  opacity: 1
}

header.dark .q_logo img.light,
header.dark .q_logo img.normal,
header.light .q_logo img.dark {
  opacity: 0;
}

header.dark .q_logo img.dark,
header.sticky:not(.header_style_on_scroll) .q_logo img.sticky {
  opacity: 1;
}

.hide_inital_sticky header.stick {
  -ms-transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.hide_inital_sticky header.stick.sticky {
  -ms-transform: none;
  -webkit-transform: none;
  transform: none;
}

header.sticky {
  -webkit-transition: all .33s cubic-bezier(.694, .0482, .335, 1);
  -moz-transition: all .33s cubic-bezier(.694, .0482, .335, 1);
  -o-transition: all .33s cubic-bezier(.694, .0482, .335, 1);
  -ms-transition: all .33s cubic-bezier(.694, .0482, .335, 1);
  transition: all .33s cubic-bezier(.694, .0482, .335, 1);
  top: -120px;
  left: 0;
  position: fixed;
  -moz-background-clip: content;
  -webkit-background-clip: content;
  background-clip: content-box;
}

header.sticky .header_bottom {
  background-color: #fff !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .11);
}

header.sticky.no_shadow .header_bottom {
  box-shadow: none;
}

header.sticky.centered_logo {
  top: -300px;
}

header.sticky.sticky_animate {
  top: 0
}

header.sticky:not(.header_style_on_scroll) .q_logo img.dark,
header.sticky:not(.header_style_on_scroll) .q_logo img.light,
header.sticky:not(.header_style_on_scroll) .q_logo img.mobile,
header.sticky:not(.header_style_on_scroll) .q_logo img.normal,
header.sticky:not(.header_style_on_scroll) .q_logo img.popup {
  opacity: 0 !important
}

header.sticky .logo_wrapper,
header.sticky.centered_logo .logo_wrapper {
  height: 60px !important;
  float: left
}

header.sticky .side_menu_button,
header:not(.sticky) .header_menu_bottom .side_menu_button {
  height: 60px
}

header.sticky .drop_down .second {
  margin-top: 0
}

.sticky .header_menu_bottom {
  position: static
}

header.fixed,
header.fixed_hiding,
header.fixed_top_header .top_header {
  -webkit-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  -moz-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  -o-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  -ms-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  width: 100%;
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0
}

header.fixed.scrolled .header_bottom,
header.fixed_hiding.scrolled .header_bottom {
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .11);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, .11);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .11);
  background-color: #fff
}

header.menu_bottom .header_inner_left {
  left: 0;
  position: relative
}

header:not(.sticky) .header_menu_bottom nav.main_menu>ul>li>a {
  line-height: 60px
}

header:not(.sticky) .header_menu_bottom nav.main_menu>ul>li:first-child>a {
  padding-left: 0
}

header.menu_bottom:not(.sticky) .drop_down .second {
  top: 100%
}

header.menu_bottom.has_header_fixed_right .mobile_menu_button {
  height: 130px
}

.menu_bottom.sticky .container_inner .header_inner_left {
  position: absolute
}

.menu_bottom .logo_wrapper {
  float: none
}

.menu_bottom .q_logo a {
  display: inline-block
}

.menu_bottom.sticky .logo_wrapper {
  float: left
}

.header_menu_bottom {
  position: relative;
  display: block
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search {
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form {
  border: none;
  display: inline-block;
  height: 100%;
  position: relative
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form>div {
  display: inline-block;
  height: 100%
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form>div label {
  display: none
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form>div input[type=text] {
  display: inline-block;
  height: 100%;
  line-height: 60px;
  padding: 0 55px 0 25px;
  margin: 0;
  border: none;
  background: #1abc9c;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  font-family: inherit;
  outline: 0;
  border-radius: 0
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form>div ::-webkit-input-placeholder {
  color: #fff
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form>div :-moz-placeholder {
  color: #fff;
  opacity: 1
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form>div ::-moz-placeholder {
  color: #fff;
  opacity: 1
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form>div :-ms-input-placeholder {
  color: #fff
}

header.menu_bottom .header_bottom_right_widget_holder .header_bottom_widget.widget_search form>div input[type=submit] {
  position: absolute;
  top: 50%;
  right: 15px;
  font-family: FontAwesome;
  background: 0 0;
  border: none;
  color: #fff;
  font-size: 18px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 1px 6px;
  font-weight: 400;
}

header.centered_logo.sticky .header_inner_left {
  margin: 0
}

header.centered_logo .logo_wrapper {
  float: none;
  height: auto !important
}

header.centered_logo .q_logo {
  top: 0
}

header.centered_logo.sticky .q_logo {
  top: 50%
}

header.centered_logo .q_logo a {
  display: inline-block;
  vertical-align: middle
}

header.centered_logo .q_logo img {
  top: 0;
  margin: 0
}

header.centered_logo.centered_logo_animate .q_logo img,
header:not(.centered_logo) .q_logo img {
  height: 100%
}

header.centered_logo.sticky .q_logo img {
  top: -50%;
  height: 100% !important
}

header.centered_logo .header_inner_right {
  float: none;
  display: inline-block;
  position: relative;
  vertical-align: middle
}

header.centered_logo .header_right_widget {
  float: left
}

header.centered_logo nav.main_menu,
header.centered_logo nav.main_menu.left,
header.centered_logo nav.main_menu.right {
  position: relative;
  display: inline-block;
  left: auto;
  float: none;
  vertical-align: middle
}

header.centered_logo nav.main_menu>ul {
  left: 0;
}

@media only screen and (min-width: 1000px) {
  header.centered_logo:not(.sticky) .q_logo img {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }

  header.fixed_hiding .holeder_for_hidden_menu {
    overflow: hidden;
    max-height: 150px;
    vertical-align: middle;
    -webkit-transition: max-height .2s cubic-bezier(.23, 1, .32, 1) 0s;
    -moz-transition: max-height .2s cubic-bezier(.23, 1, .32, 1) 0s;
    -o-transition: max-height .2s cubic-bezier(.23, 1, .32, 1) 0s;
    -ms-transition: max-height .2s cubic-bezier(.23, 1, .32, 1) 0s;
    transition: max-height .2s cubic-bezier(.23, 1, .32, 1) 0s;
  }

  header.fixed_hiding.scrolled .holeder_for_hidden_menu {
    max-height: 0;
  }

  header.fixed_hiding .holeder_for_hidden_menu:hover {
    overflow: visible;
  }

  header.fixed_hiding.scrolled:hover .holeder_for_hidden_menu {
    max-height: 150px;
    transition-duration: .5s;
    -webkit-transition-duration: .8s;
    -moz-transition-duration: .8s;
    -ms-transition-duration: .8s;
    -o-transition-duration: .8s;
  }

  header.fixed_hiding.centered_logo .header_inner_left {
    margin: 20px 0;
    display: table;
    width: 100%
  }

  header.fixed_hiding.centered_logo.fixed_hiding .header_inner_left {
    height: 50px
  }

  header.fixed_hiding .header-left-from-logo-widget,
  header.fixed_hiding .header-right-from-logo-widget,
  header.fixed_hiding .logo_wrapper {
    display: table-cell;
    width: 33.33%;
    vertical-align: middle;
    position: relative
  }

  header.fixed_hiding .header-left-from-logo-widget-inner,
  header.fixed_hiding .header-right-from-logo-widget-inner {
    width: 100%
  }

  header.fixed_hiding .header-left-from-logo-widget {
    text-align: left
  }

  header.fixed_hiding .header-right-from-logo-widget {
    text-align: right
  }

  header.fixed_hiding .q_logo,
  header.fixed_hiding .q_logo a {
    max-height: 124px;
    opacity: 1;
    -webkit-transition: max-height .2s ease 0s, opacity .2s ease 0s;
    -moz-transition: max-height .2s ease 0s, opacity .2s ease 0s;
    -o-transition: max-height .2s ease 0s, opacity .2s ease 0s;
    -ms-transition: max-height .2s ease 0s, opacity .2s ease 0s;
    transition: max-height .2s ease-out 0s, opacity .2s ease 0s
  }

  header.fixed_hiding.scrolled .q_logo,
  header.fixed_hiding.scrolled .q_logo a {
    -webkit-transition: max-height .2s ease 0s, opacity .4s ease 0s;
    -moz-transition: max-height .2s ease 0s, opacity .4s ease 0s;
    -o-transition: max-height .2s ease 0s, opacity .4s ease 0s;
    -ms-transition: max-height .2s ease 0s, opacity .4s ease 0s;
    transition: max-height .2s ease-out 0s, opacity .4s ease 0s;
    max-height: 0 !important;
    opacity: 0
  }

  header.fixed_hiding .q_logo_hidden a {
    height: 50px;
    position: relative;
    display: block;
    opacity: 0;
    max-height: 0;
    -webkit-transition: opacity 0s ease 0s, max-height 0s ease 0s;
    -moz-transition: opacity 0s ease 0s, max-height 0s ease 0s;
    -o-transition: opacity 0s ease 0s, max-height 0s ease 0s;
    -ms-transition: opacity 0s ease 0s, max-height 0s ease 0s;
    transition: opacity 0s ease 0s, max-height 0s ease 0s
  }

  header.fixed_hiding.scrolled .q_logo_hidden a {
    max-height: 500px;
    opacity: 1;
    -webkit-transition: opacity .15s ease .3s, max-height .15s ease .2s;
    -moz-transition: opacity .15s ease .3s, max-height .15s ease .2s;
    -o-transition: opacity .15s ease .3s, max-height .15s ease .2s;
    -ms-transition: opacity .15s ease .3s, max-height .15s ease .2s;
    transition: opacity .15s ease .3s, max-height .15s ease .2s
  }
}

.mobile_menu_button span:hover,
.side_menu_button>a:hover,
header:not(.with_hover_bg_color) nav.main_menu>ul>li:hover>a {
  opacity: .8
}

nav.main_menu {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 100;
  text-align: left
}

nav.main_menu.right {
  position: relative;
  left: auto;
  float: right
}

nav.main_menu.left {
  position: relative;
  left: auto;
  float: left;
  z-index: 101
}

nav.main_menu ul {
  list-style: none;
  margin: 0;
  padding: 0
}

nav.main_menu>ul {
  left: -50%;
  position: relative
}

nav.main_menu.left>ul,
nav.main_menu.right>ul {
  left: auto
}

nav.main_menu ul li {
  display: inline-block;
  float: left;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  background-position: right
}

nav.main_menu ul li a {
  color: #777;
  font-weight: 400;
  text-decoration: none;
  display: inline-block;
  position: relative;
  line-height: 100px;
  padding: 0;
  margin: 0;
  cursor: pointer
}

nav.main_menu>ul>li>a>i.menu_icon {
  margin-right: 7px
}

.dark.sticky nav.main_menu>ul>li.active>a,
.dark.sticky nav.main_menu>ul>li>a,
.dark.sticky nav.main_menu>ul>li>a:hover,
.light.sticky nav.main_menu>ul>li.active>a,
.light.sticky nav.main_menu>ul>li>a,
.light.sticky nav.main_menu>ul>li>a:hover,
header.sticky nav.main_menu>ul>li>a {
  line-height: 60px
}

nav.main_menu>ul>li>a {
  display: inline-block;
  height: 100%;
  background-color: transparent;
  -webkit-transition: opacity .3s ease-in-out, color .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out, color .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out, color .3s ease-in-out;
  -ms-transition: opacity .3s ease-in-out, color .3s ease-in-out;
  transition: opacity .3s ease-in-out, color .3s ease-in-out
}

.with_hover_bg_color nav.main_menu>ul>li>a {
  -webkit-transition: background-color 0 ease-in-out .15s, color 0 ease-in-out .15s;
  -moz-transition: background-color 0 ease-in-out .15s, color 0 ease-in-out .15s;
  -ms-transition: background-color 0 ease-in-out .15s, color 0 ease-in-out .15s;
  -o-transition: background-color 0 ease-in-out .15s, color 0 ease-in-out .15s;
  transition: background-color 0 ease-in-out .15s, color 0 ease-in-out .15s
}

nav.main_menu>ul>li.active>a {
  color: #303030
}

nav.main_menu>ul>li>a>i.blank {
  display: none
}

nav.main_menu>ul>li.has_sub>a>i.q_menu_arrow {
  display: inline-block;
  margin-left: 6px
}

.light.header_style_on_scroll nav.main_menu>ul>li.active>a,
.light.header_style_on_scroll nav.main_menu>ul>li:before,
.light.header_style_on_scroll nav.main_menu>ul>li>a:hover,
.light:not(.sticky):not(.scrolled) nav.main_menu>ul>li.active>a,
.light:not(.sticky):not(.scrolled) nav.main_menu>ul>li:before,
.light:not(.sticky):not(.scrolled) nav.main_menu>ul>li>a,
.light:not(.sticky):not(.scrolled) nav.main_menu>ul>li>a:hover {
  color: #fff
}

.dark.header_style_on_scroll nav.main_menu>ul>li.active>a,
.dark.header_style_on_scroll nav.main_menu>ul>li:not(:first-child):before,
.dark.header_style_on_scroll nav.main_menu>ul>li>a,
.dark:not(.sticky):not(.scrolled) nav.main_menu>ul>li.active>a,
.dark:not(.sticky):not(.scrolled) nav.main_menu>ul>li:not(:first-child):before,
.dark:not(.sticky):not(.scrolled) nav.main_menu>ul>li>a {
  color: #000
}

nav.main_menu>ul>li>a {
  position: relative;
  padding: 0 17px;
  color: #9d9d9d;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
}

header:not(.with_hover_bg_color) nav.main_menu>ul>li>a>span:not(.plus),
nav.vertical_menu>ul>li>a>span:not(.plus) {
  position: relative;
  display: inline-block;
  line-height: initial
}

nav.main_menu ul li a span.underline_dash,
nav.vertical_menu ul li a span.underline_dash {
  background-color: #9d9d9d;
  bottom: -50%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 100%;
  height: 1px;
  opacity: 0;
  transition: opacity .3s ease;
  -webkit-transition: opacity .3s ease
}

nav.main_menu ul li.active a span.underline_dash,
nav.main_menu ul li:hover a span.underline_dash,
nav.vertical_menu ul li.active a span.underline_dash,
nav.vertical_menu ul li:hover a span.underline_dash {
  opacity: 1
}

.light.header_style_on_scroll nav.main_menu>ul>li.active>a span.underline_dash,
.light.header_style_on_scroll nav.main_menu>ul>li>a:hover span.underline_dash,
.light:not(.sticky):not(.scrolled) nav.main_menu ul li a span.underline_dash,
.vertical_menu_transparency_on .light nav.vertical_menu ul li a span.underline_dash {
  background-color: #fff !important
}

.dark.header_style_on_scroll nav.main_menu>ul>li.active>a span.underline_dash,
.dark.header_style_on_scroll nav.main_menu>ul>li>a:hover span.underline_dash,
.dark:not(.sticky):not(.scrolled) nav.main_menu ul li a span.underline_dash,
.vertical_menu_transparency_on .dark nav.vertical_menu ul li a span.underline_dash {
  background-color: #000 !important
}

.drop_down ul {
  list-style: none
}

.drop_down ul li {
  position: relative
}

header.transparent.fixed.scrolled .drop_down .second,
header.transparent.fixed_hiding.scrolled .drop_down .second {
  top: 100%
}

.drop_down .second {
  left: 0;
  margin: 0;
  top: 100%;
  position: absolute;
  display: block;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
  z-index: 10;
  -webkit-transition: top .3s ease-in-out;
  -moz-transition: top .3s ease-in-out;
  -ms-transition: top .3s ease-in-out;
  -o-transition: top .3s ease-in-out;
  transition: top .3s ease-in-out
}

header.transparent:not(.sticky) .drop_down .second {
  top: 75%
}

header.transparent.with_border:not(.sticky) .drop_down .second,
header.transparent.with_hover_bg_color:not(.sticky) .drop_down .second {
  top: 100%
}

.drop_down li.left_position .second {
  left: auto;
  right: 0
}

header.transparent .drop_down .second:not(.right) {
  left: 16px
}

header.transparent.with_hover_bg_color .drop_down .second:not(.right) {
  left: 0
}

.drop_down li.right_position .second {
  left: -100%
}

.drop_down .second.drop_down_start {
  visibility: visible;
  overflow: visible;
  opacity: 1
}

nav.main_menu>ul>li:hover>.second {
  z-index: 20
}

.drop_down .second .inner {
  position: relative;
  padding: 0;
  display: block;
  z-index: 997
}

.drop_down .second .inner>ul,
li.narrow .second .inner ul {
  display: inline-block;
  position: relative;
  background-color: #262626;
  border-color: #3d3d3d
}

li.narrow .second .inner ul {
  padding: 7px 0
}

.drop_down .second .inner ul li {
  display: block;
  padding: 0 15px;
  position: relative;
  float: none;
  height: auto;
  background: 0;
  width: 190px
}

li.narrow .second .inner ul li:last-child {
  border-bottom: none
}

.drop_down .wide.left_position .second ul li,
.drop_down .wide.right_position .second ul li {
  width: 180px
}

.drop_down .second .inner ul li a,
.drop_down .second .inner ul li h5 {
  display: block;
  font-weight: 600;
  color: #9d9d9d;
  height: auto;
  line-height: 16px;
  margin: 0;
  padding: 9px 0;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out;
  font-size: 11px;
  text-transform: uppercase;
}

.drop_down .second .inner>ul>li:last-child>a,
.drop_down .second .inner>ul>li>ul>li:last-child>a,
.drop_down .second .inner>ul>li>ul>li>ul>li:last-child>a {
  border-bottom: 0
}

.drop_down .second .inner ul.right li a {
  padding: 9px 20px 9px 0
}

.drop_down .second .inner ul li.sub ul li a:hover,
.drop_down .second .inner>ul>li>a:hover {
  color: #fff
}

.drop_down .narrow .second .inner ul li {
  padding: 0;
  width: 225px
}

.drop_down .narrow .second .inner ul li a {
  padding-left: 20px;
  padding-right: 20px
}

.drop_down .wide .second .inner ul li h5,
.drop_down .wide .second .inner ul li.sub a,
.drop_down .wide .second ul li a {
  background: 0
}

.drop_down .second i {
  display: none
}

.drop_down .second .inner ul li ul {
  position: absolute;
  left: 100%;
  top: -7px;
  height: auto;
  display: none;
  z-index: 10;
  overflow: visible
}

.drop_down .second .inner ul li:hover ul {
  z-index: 20;
  display: block
}

.drop_down .second.right {
  right: 0;
  left: auto
}

.drop_down .second .inner ul.right li ul {
  left: -100%
}

.drop_down .second .inner ul li.sub ul li a,
.drop_down .second .inner ul.right li.sub ul li a {
  background: 0 0
}

.drop_down .second .inner .widget {
  padding: 0 20px
}

.drop_down .second .inner .widget p {
  font-size: 13px;
  line-height: 16px;
  padding: 12px 0
}

.drop_down .second ul li {
  border-bottom-width: 1px;
  border-bottom-color: #3d3d3d
}

.drop_down .wide .second ul {
  padding: 15px 10px
}

.drop_down .wide .second ul li {
  float: left;
  width: 249px;
  padding: 0 10px;
  border-left: 1px solid #3d3d3d;
  border-bottom: 0
}

.drop_down .wide.full_width_wide_menu .second ul {
  width: 100%;
  box-sizing: border-box
}

.drop_down .wide.full_width_wide_menu .second ul li {
  box-sizing: border-box
}

.drop_down .wide .second ul li a,
.drop_down .wide .second>.inner>ul>li.sub>ul>li>a {
  padding: 11px 9px
}

.drop_down ul li .qode-featured-icon {
  font-size: 6px;
  position: relative;
  padding-left: 4px;
  vertical-align: text-top;
  top: -3px
}

.drop_down .wide.right_position .second ul li:last-child,
.drop_down .wide:not(.right_position) .second ul li:first-child {
  border-left-color: transparent
}

.drop_down .wide .second ul li:last-child {
  margin-right: 0
}

.drop_down .wide.right_position .second ul li {
  float: right
}

.drop_down .wide .second ul li:hover {
  background-color: transparent
}

.drop_down .wide .second ul li:nth-child(4n+1) {
  clear: both
}

.drop_down .wide.full_width_wide_menu .second ul li:nth-child(4n+1) {
  clear: none
}

.drop_down .second .inner ul li.sub a i.q_menu_arrow {
  display: inline-block;
  float: right;
  position: relative;
  top: 2px;
  font-size: 12px;
  color: #888
}

.drop_down .second .inner ul li.sub ul li a i.q_menu_arrow,
.drop_down .wide .second .inner ul li.sub a i.q_menu_arrow {
  display: none
}

.drop_down .second .inner ul.right li.sub a i.q_menu_arrow {
  float: left;
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg)
}

.drop_down .wide .second .inner>ul>li>a {
  color: #fff;
  border-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 7px;
  font-size: 13px
}

.drop_down .wide .second .inner ul li h5 a,
.drop_down .wide .second .inner ul li.sub h5 a {
  padding: 0 !important
}

.drop_down .wide .second ul li ul {
  display: block;
  left: 0;
  padding: 0;
  position: relative;
  top: 0;
  visibility: visible;
  background-color: transparent !important
}

.drop_down .wide .second ul li ul .widget {
  padding: 0 10px
}

.drop_down .wide.wide_background .second {
  background-color: #262626
}

.drop_down .wide.wide_background .second .inner ul {
  text-align: left
}

.drop_down .wide.wide_background .second .inner,
.fixed_top_header .header_inner_center {
  text-align: center
}

.drop_down .wide .second ul li.show_widget_area_in_popup:hover .popup_wrapper>a {
  color: #fff
}

.drop_down .wide .second ul li.show_widget_area_in_popup .popup_wrapper {
  position: relative;
  display: inline-block;
  vertical-align: middle
}

.drop_down .wide .second ul li.show_widget_area_in_popup a {
  display: inline-block;
  vertical-align: middle
}

.drop_down .wide .second ul li.show_widget_area_in_popup .widget {
  height: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  left: 100%;
  top: 0;
  padding: 5px;
  margin: 5px 0 0 5px;
  background-color: #262626
}

.drop_down .wide .second ul li ul li {
  z-index: 1;
  padding: 0;
  margin: 0;
  border: 0
}

.drop_down .wide .second ul li.show_widget_area_in_popup:hover,
.q_slider {
  z-index: 10
}

.drop_down .wide .second ul li.show_widget_area_in_popup:hover .widget {
  height: auto;
  width: auto;
  opacity: 1
}

.drop_down .wide .second ul li.show_widget_area_in_popup .widget img {
  max-width: inherit;
  display: block;
  position: relative
}

.drop_down .wide .second ul li.show_widget_area_in_popup .widget a {
  padding: 0
}

.drop_down .wide .second ul li ul li.menu-item-has-children>a,
.drop_down .wide .second ul li ul li.menu-item-has-children>a:hover {
  border-bottom: 0 none;
  color: #303030;
  font-size: 15px
}

.drop_down .wide.icons .second ul li a span {
  position: relative;
  display: block
}

.drop_down .wide.icons .second i {
  width: 24px;
  height: 16px;
  float: left;
  display: inline-block;
  color: #888;
  line-height: 16px;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.drop_down .wide.icons .second a:hover i,
.fixed_top_header.light .header_bottom_center_widget {
  color: #fff
}

.drop_down .wide.icons .second i.blank {
  background: 0 0;
  border: 0;
  display: none
}

.fixed_top_header .bottom_header,
.fixed_top_header .top_header,
.fixed_top_header nav.mobile_menu,
nav.mobile_menu {
  background-color: #fff
}

@media only screen and (min-width: 1000px) {
  header.stick_with_left_right_menu:not(.sticky) {
    text-align: center
  }

  .hide_inital_sticky header.stick_with_left_right_menu {
    -ms-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }

  .hide_inital_sticky header.stick_with_left_right_menu.sticky {
    -ms-transform: none;
    -webkit-transform: none;
    transform: none
  }

  header.stick_with_left_right_menu .header_inner_left {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: 0;
    left: auto;
    z-index: 100
  }

  header.stick_with_left_right_menu.sticky .header_inner_left {
    display: none
  }

  header.stick_with_left_right_menu nav.main_menu.left_side {
    text-align: right;
    position: absolute;
    width: 49%;
    top: 0;
    left: 0;
    z-index: 50
  }

  header.stick_with_left_right_menu nav.main_menu.left_side>ul {
    text-align: left;
    display: inline-block;
    vertical-align: middle
  }

  header.stick_with_left_right_menu nav.main_menu.right_side {
    position: absolute;
    width: 49%;
    top: 0;
    right: 0;
    left: auto;
    z-index: 50
  }

  header.stick_with_left_right_menu nav.main_menu.left_side:hover,
  header.stick_with_left_right_menu nav.main_menu.right_side:hover {
    z-index: 200
  }

  header.stick_with_left_right_menu.sticky nav.main_menu.left_side,
  header.stick_with_left_right_menu.sticky nav.main_menu.right_side {
    position: relative;
    display: inline-block;
    vertical-align: middle
  }

  header.stick_with_left_right_menu.sticky nav.main_menu.left_side>ul>li:last-child,
  header.stick_with_left_right_menu.sticky nav.main_menu.right_side>ul>li:first-child {
    margin: 0 !important
  }

  nav.main_menu.left_side>ul,
  nav.main_menu.right_side>ul {
    left: auto
  }

  .fixed_minimal .q_logo a img {
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
  }
}

.fixed_minimal .container_inner .header_inner_left,
.fixed_minimal .header_inner_left {
  position: relative;
  left: auto;
  top: auto;
  float: left
}

.fixed_minimal .container_inner .logo_wrapper,
.fixed_minimal .logo_wrapper {
  float: none;
  position: absolute;
  left: 50%;
  top: 0
}

.fixed_minimal .popup_menu .line:after,
.fixed_minimal .popup_menu .line:before {
  z-index: 1
}

.fixed_minimal .side_menu_button>a {
  margin: 0
}

.fixed_top_header .top_header {
  position: fixed;
  z-index: 110;
  width: 100%;
  left: 0;
  top: 0;
  backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.fixed_top_header .top_header .left {
  float: left
}

.fixed_top_header .top_header .right {
  float: right
}

.fixed_top_header .top_header nav.main_menu {
  position: relative;
  left: 0
}

.fixed_top_header .top_header nav.main_menu>ul {
  left: 0
}

.fixed_top_header .logo_wrapper {
  float: none;
  padding: 25px 0
}

.fixed_top_header .q_logo {
  top: 0
}

.fixed_top_header .q_logo a {
  display: inline-block;
  vertical-align: middle
}

.fixed_top_header .q_logo a img {
  top: 0;
  left: 0;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0)
}

.fixed_top_header .header_bottom_right_widget_holder,
.fixed_top_header .shopping_cart_inner,
.fixed_top_header .side_menu_button {
  height: 45px
}

.fixed_top_header nav.main_menu>ul>li>a {
  line-height: 45px
}

.fixed_top_header.has_top .bottom_header {
  padding-top: 45px
}

.fixed_top_header .header_bottom_center_widget {
  padding-bottom: 20px
}

.fixed_top_header .top_header .header-widget {
  float: left
}

.fixed_top_header .qode_search_form_3 .qode_search_close {
  font-size: 25px
}

.fixed_top_header .qode_search_form_3 {
  position: fixed;
  left: 0;
  top: 0
}

.fixed_top_header .top_header>.left {
  padding-left: 45px
}

.fixed_top_header .top_header>.right {
  padding-right: 45px
}

.fixed_top_header .top_header .container_inner .left,
.fixed_top_header .top_header .container_inner .right {
  padding-left: 0;
  padding-right: 0
}

.dark .mobile_menu_button span,
.fixed_top_header.dark .header_bottom_center_widget {
  color: #000
}

header.fixed_top_header .top_header nav.main_menu>ul>li:first-child>a {
  padding-left: 0
}

.mobile_menu_button {
  display: none;
  float: left;
  height: 100px;
  margin: 0 20px 0 0
}

.mobile_menu_button span {
  width: 19px;
  height: 17px;
  font-size: 14px;
  z-index: 50;
  display: table-cell;
  position: relative;
  cursor: pointer;
  vertical-align: middle
}

nav.mobile_menu,
nav.mobile_menu ul {
  display: none;
  width: 100%;
  position: relative
}

nav.mobile_menu {
  float: left;
  top: 0;
  text-align: left;
  overflow: hidden;
  z-index: 100
}

nav.mobile_menu>ul.show {
  display: block;
}

nav.mobile_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;


  display: none;
}

nav.mobile_menu>ul {
  margin-bottom: -1px !important
}

nav.mobile_menu ul li {
  margin: 0;
  padding: 0;
  position: relative;
  transform: translateZ(0)
}

nav.mobile_menu ul li a {
  transition: all .2s ease;
  -moz-transition: all .2s ease;
  -o-transition: all .2s ease;
  -webkit-tap-highlight-color: transparent
}

nav.mobile_menu ul li,
nav.mobile_menu ul li ul li {
  border-bottom: 1px solid #eaeaea
}

nav.mobile_menu ul li.open_sub>ul {
  border-top: 1px solid #eaeaea
}

nav.mobile_menu ul li:last-child {
  border: 0 !important
}

nav.mobile_menu ul li a,
nav.mobile_menu ul li h3 {
  font-size: 13px;
  font-weight: 400;
  color: #d8b80c;
  padding: 10px 0;
  display: block;
  position: relative;
  text-transform: none;
  line-height: 26px;
}

nav.mobile_menu ul li a:hover,
nav.mobile_menu ul li.active>a {
  color: #1abc9c;
}

nav.mobile_menu ul li ul li {
  margin: 0 0 0 20px;
}

.qode-animate-mobile-header {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  transform: translateY(-100%);
}

.mobile-header-appear {
  -webkit-transform: translateY(0) !important;
  -moz-transform: translateY(0) !important;
  transform: translateY(0) !important;
  -webkit-transition: transform .4s ease-in-out !important;
  -moz-transition: transform .4s ease-in-out !important;
  transition: transform .4s ease-in-out !important;
}

header:not(.centered_logo) .header_fixed_right_area {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  line-height: 100px;
}

.header_menu_bottom .main_menu {
  left: auto;
}

.sticky .header_menu_bottom .main_menu {
  float: right;
  left: auto;
  position: relative;
}

.sticky .header_menu_bottom .main_menu_header_inner_right_holder .main_menu,
.sticky .header_menu_bottom .main_menu_header_inner_right_holder.with_center_logo .main_menu {
  float: left;
}

.sticky .header_menu_bottom .main_menu_header_inner_right_holder {
  float: right;
}

.header_menu_bottom nav.main_menu>ul,
.sticky .header_menu_bottom nav.main_menu>ul {
  left: auto;
}

.side_menu_button_wrapper {
  display: table;
}

.side_menu_button {
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
  height: 100px;
}

.header_bottom_right_widget_holder {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  padding: 0 17px;
}

@media only screen and (min-width: 1000px) {

  header.dark.header_style_on_scroll .header_bottom_right_widget_holder a.qbutton,
  header.dark:not(.sticky):not(.scrolled) .header_bottom_right_widget_holder a.qbutton {
    border-color: #000 !important;
    background-color: transparent !important;
    color: #000 !important;
  }

  header.light.header_style_on_scroll .header_bottom_right_widget_holder a.qbutton,
  header.light:not(.sticky):not(.scrolled) .header_bottom_right_widget_holder a.qbutton {
    border-color: #fff !important;
    background-color: transparent !important;
    color: #fff !important;
  }
}

.content,
.content .container,
.full_width {
  background-color: #f6f6f6;
}

.side_menu_button>a {
  font-size: 14px;
  position: relative;
  display: inline;
  width: 20px;
  height: 20px;
  padding: 0 12px;
}

.side_menu_button .qode-side-menu-button-svg svg path,
.side_menu_button .qode-side-menu-button-svg svg>* {
  fill: currentColor;
  stroke: currentColor
}

.side_menu_button>a.side_menu_button_link span {
  vertical-align: middle;
  position: relative;
  top: 2px
}

.side_menu_button>a.side_menu_button_link.medium {
  display: inline-block;
  font-size: 21px;
  line-height: 30px;
  height: 30px;
  width: 19px
}

.side_menu_button>a.side_menu_button_link.large {
  display: inline-block;
  font-size: 28px;
  line-height: 34px;
  height: 34px;
  width: 24px
}

.mobile_menu_button span,
.side_menu_button>a {
  transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  -ms-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  color: #9d9d9d
}

.side_menu_button>a.search_button {
  vertical-align: middle;
  display: inline-block
}

.light.header_style_on_scroll .header-widget,
.light.header_style_on_scroll .header-widget .q_social_icon_holder i.simple_social,
.light.header_style_on_scroll .header-widget .q_social_icon_holder span.simple_social,
.light.header_style_on_scroll .header-widget a,
.light.header_style_on_scroll .header-widget p,
.light.header_style_on_scroll .header-widget span,
.light.header_style_on_scroll .header-widget.widget_nav_menu ul.menu>li>a,
.light:not(.sticky):not(.scrolled) .header-widget,
.light:not(.sticky):not(.scrolled) .header-widget .q_social_icon_holder i.simple_social,
.light:not(.sticky):not(.scrolled) .header-widget .q_social_icon_holder span.simple_social,
.light:not(.sticky):not(.scrolled) .header-widget a,
.light:not(.sticky):not(.scrolled) .header-widget p,
.light:not(.sticky):not(.scrolled) .header-widget span,
.light:not(.sticky):not(.scrolled) .header-widget.widget_nav_menu ul.menu>li>a {
  color: #fff
}

.light.header_style_on_scroll .header-widget #lang_sel>ul>li>a,
.light.header_style_on_scroll .header-widget #lang_sel_click>ul>li>a,
.light.header_style_on_scroll .header-widget .q_social_icon_holder i.simple_social,
.light.header_style_on_scroll .header-widget .q_social_icon_holder span.simple_social,
.light:not(.sticky):not(.scrolled) .header-widget #lang_sel>ul>li>a,
.light:not(.sticky):not(.scrolled) .header-widget #lang_sel_click>ul>li>a,
.light:not(.sticky):not(.scrolled) .header-widget .q_social_icon_holder i.simple_social,
.light:not(.sticky):not(.scrolled) .header-widget .q_social_icon_holder span.simple_social {
  color: #fff !important
}

.dark.header_style_on_scroll .header-widget,
.dark.header_style_on_scroll .header-widget a,
.dark.header_style_on_scroll .header-widget p,
.dark.header_style_on_scroll .header-widget span,
.dark.header_style_on_scroll .header-widget.widget_nav_menu ul.menu>li>a,
.dark.header_style_on_scroll .q_social_icon_holder i.simple_social,
.dark.header_style_on_scroll .q_social_icon_holder span.simple_social,
.dark:not(.sticky):not(.scrolled) .header-widget,
.dark:not(.sticky):not(.scrolled) .header-widget a,
.dark:not(.sticky):not(.scrolled) .header-widget p,
.dark:not(.sticky):not(.scrolled) .header-widget span,
.dark:not(.sticky):not(.scrolled) .header-widget.widget_nav_menu ul.menu>li>a,
.dark:not(.sticky):not(.scrolled) .q_social_icon_holder i.simple_social,
.dark:not(.sticky):not(.scrolled) .q_social_icon_holder span.simple_social {
  color: #000
}

.dark.header_style_on_scroll .header-widget #lang_sel>ul>li>a,
.dark.header_style_on_scroll .header-widget #lang_sel_click>ul>li>a,
.dark.header_style_on_scroll .header-widget .q_social_icon_holder i.simple_social,
.dark.header_style_on_scroll .header-widget .q_social_icon_holder span.simple_social,
.dark:not(.sticky):not(.scrolled) .header-widget #lang_sel>ul>li>a,
.dark:not(.sticky):not(.scrolled) .header-widget #lang_sel_click>ul>li>a,
.dark:not(.sticky):not(.scrolled) .header-widget .q_social_icon_holder i.simple_social,
.dark:not(.sticky):not(.scrolled) .header-widget .q_social_icon_holder span.simple_social {
  color: #000 !important
}

.side_menu_button a:last-child {
  padding: 0 0 0 8px
}

.container,
.content .container .container_inner,
.header_inner_right.left_side .side_menu_button a:last-child {
  padding: 0
}

header.sticky .header_inner_right.left_side {
  display: none
}

.side_menu .q_font_awsome_icon .qode_icon_element {
  color: #818181;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out;
  margin: 0 8px 0 0;
  backface-visibility: visible
}

.box_image_holder .box_image_shadow,
.box_image_holder .image_holder_inner,
.box_image_with_border {
  transition: all .4s ease-out 0s;
  -o-transition: all .4s ease-out 0s;
  -ms-transition: all .4s ease-out 0s
}

.side_menu li:hover .q_font_awsome_icon .qode_icon_element {
  color: #fff
}

.q_slider {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.container,
.content,
.full_width {
  z-index: 100;
  position: relative;
}

.ls-wp-fullwidth-helper {
  left: 0 !important;
}

.content {
  margin-top: 0;
}

.content.content_top_margin {
  margin-top: 100px !important;
}

.content.content_top_margin_none {
  margin-top: 0 !important;
}

.container {
  width: 100%;
}

.container_inner,
.elementor-container .elementor-widget-wrap>.qode_elementor_container_inner,
.elementor-row .elementor-widget-wrap>.qode_elementor_container_inner,
.qode_elementor_container_inner {
  width: 1100px;
  margin: 0 auto 0 270px;
}

.content .container .container_inner.page_container_inner,
.full_page_container_inner {
  padding: 30px 0 0;
}

.header_bottom .container_inner {
  position: relative;
}

.content .title .container_inner {
  padding: 0 !important;
}

section.section {
  display: block;
  position: relative;
  padding: 50px 0;
  overflow: hidden
}

div.section {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover
}

.use_row_as_box {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px
}

.vc_row.disable_negative_margin {
  margin-left: 0;
  margin-right: 0
}

div.video_section {
  overflow: hidden
}

.boxed .section .section_inner,
.grid_section .section_inner {
  width: 1100px;
  margin: 0 auto;
  position: relative;
  z-index: 20
}

.full_section_inner {
  position: relative;
  z-index: 20
}

@media only screen and (min-width: 1500px) {

  .carousel-inner .slider_content_outer,
  .qode_grid_1400 .container_inner,
  .qode_grid_1400 .container_inner nav.content_menu,
  .qode_grid_1400 .elementor-container .elementor-widget-wrap>.qode_elementor_container_inner,
  .qode_grid_1400 .elementor-row .elementor-widget-wrap>.qode_elementor_container_inner,
  .qode_grid_1400 .footer_bottom_border.in_grid,
  .qode_grid_1400 .footer_top_border.in_grid,
  .qode_grid_1400 .full_width .parallax_content,
  .qode_grid_1400 .grid_section .section_inner,
  .qode_grid_1400 .qode_elementor_container_inner,
  .qode_grid_1400 .title_border_in_grid_holder,
  .qode_grid_1400 nav.content_menu ul,
  .qode_grid_1400.boxed .section .section_inner,
  .qode_grid_1400.boxed nav.content_menu.fixed {
    width: 1400px;
  }

  .carousel-inner,
  .qode_grid_1400.boxed .header_inner,
  body.qode_grid_1400.boxed .footer_inner,
  body.qode_grid_1400.boxed .wrapper_inner {
    width: 1450px;
  }

  .carousel-inner {
    width: 1450px !important;
  }

  .drop_down .wide .second ul li {
    width: 324px;
  }
}

@media only screen and (min-width: 1400px) {

  .qode_grid_1300 .carousel-inner .slider_content_outer,
  .qode_grid_1300 .container_inner,
  .qode_grid_1300 .container_inner nav.content_menu,
  .qode_grid_1300 .elementor-container .elementor-widget-wrap>.qode_elementor_container_inner,
  .qode_grid_1300 .elementor-row .elementor-widget-wrap>.qode_elementor_container_inner,
  .qode_grid_1300 .footer_bottom_border.in_grid,
  .qode_grid_1300 .footer_top_border.in_grid,
  .qode_grid_1300 .full_width .parallax_content,
  .qode_grid_1300 .grid_section .section_inner,
  .qode_grid_1300 .qode_elementor_container_inner,
  .qode_grid_1300 .title_border_in_grid_holder,
  .qode_grid_1300 nav.content_menu ul,
  .qode_grid_1300 .section .section_inner,
  .qode_grid_1300 nav.content_menu.fixed {
    width: 1300px;
  }

  .qode_grid_1300 .carousel-inner,
  .qode_grid_1300 .header_inner,
  .qode_grid_1300 .footer_inner {
    width: 1350px;
  }

  .qode_grid_1300 .carousel-inner {
    width: 1350px !important;
  }

  .qode_grid_1300 .drop_down .wide .second ul li {
    width: 299px;
  }
}

@media only screen and (min-width: 1300px) {

  .qode_grid_1200 .carousel-inner .slider_content_outer,
  .qode_grid_1200 .container_inner,
  .qode_grid_1200 .container_inner nav.content_menu,
  .qode_grid_1200 .elementor-container .elementor-widget-wrap>.qode_elementor_container_inner,
  .qode_grid_1200 .elementor-row .elementor-widget-wrap>.qode_elementor_container_inner,
  .qode_grid_1200 .footer_bottom_border.in_grid,
  .qode_grid_1200 .footer_top_border.in_grid,
  .qode_grid_1200 .full_width .parallax_content,
  .qode_grid_1200 .grid_section .section_inner,
  .qode_grid_1200 .qode_elementor_container_inner,
  .qode_grid_1200 .title_border_in_grid_holder,
  .qode_grid_1200 nav.content_menu ul,
  .qode_grid_1200.boxed .section .section_inner,
  .qode_grid_1200.boxed nav.content_menu.fixed {
    width: 1200px
  }

  .qode_grid_1200.boxed .carousel-inner,
  .qode_grid_1200.boxed .header_inner,
  body.qode_grid_1200.boxed .footer_inner,
  body.qode_grid_1200.boxed .wrapper_inner {
    width: 1250px
  }

  .carousel-inner {
    width: 1250px !important
  }

  .qode_grid_1200 .drop_down .wide .second ul li {
    width: 274px
  }
}

.section .mobile-video-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10
}

.no-touchevents .section .mobile-video-image {
  display: block
}

.section .video-overlay {
  height: 3000px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 11
}

.section .video-overlay.active {
  /* background-image: url(img/pixel-video.png); */
  background-position: 0 0;
  background-repeat: repeat;
  opacity: 1
}

.section .video-wrap {
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 10;
  opacity: 0
}

.section .video-wrap .mejs-poster {
  background-size: cover !important;
  -moz-background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important
}

.section .video-wrap .mejs-container .mejs-controls {
  display: none !important
}

.title,
.title img {
  display: block;
  position: relative
}

.mejs-container.blog_audio,
.mejs-container.blog_audio .mejs-controls,
.mejs-embed,
.mejs-embed body {
  background: #fff
}

.mejs-container.blog_audio .mejs-controls .mejs-container.blog_audio .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.mejs-container.blog_audio .mejs-controls .mejs-time-rail .mejs-time-total {
  background: #ddd
}

.mejs-controls .mejs-time-rail .mejs-time-total {
  width: calc(100% - 30px)
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total .mejs-horizontal-volume-current {
  top: 0
}

.section .video-wrap .mejs-controls .mejs-button button:focus {
  outline: 0 !important
}

.section .video-wrap .mejs-controls .mejs-time-rail .mejs-time-loaded {
  background-color: rgba(255, 255, 255, .18) !important
}

.section .video-wrap .mejs-container {
  background-color: transparent !important;
  background-image: none !important;
  height: auto !important
}

.section .video-wrap .mejs-mediaelement {
  background: 0 0 !important;
  border: 0 !important
}

.section .video-wrap .mejs-container .mejs-poster img {
  max-width: none !important;
  width: 100% !important
}

.section .video-wrap .mejs-controls button {
  opacity: .8
}

.mejs-controls .mejs-fullscreen-button:hover button,
.section .video-wrap .mejs-controls button:hover {
  opacity: 1 !important
}

.section .video-wrap .mejs-controls .mejs-time-rail .mejs-time-total {
  background: #1f1f1f !important
}

.section .video-wrap .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
  background: 0 0 !important
}

.two_columns_66_33 {
  width: 100%
}

.two_columns_66_33>.column1 {
  width: 66.66%;
  float: left
}

.two_columns_66_33>.column1>.column_inner {
  padding: 0 20px 0 0
}

.two_columns_66_33>.column2 {
  width: 33.33%;
  float: left
}

.two_columns_66_33>.column2>.column_inner {
  padding: 0 0 0 20px
}

.two_columns_33_66 {
  width: 100%
}

.two_columns_33_66>.column1 {
  width: 33.33%;
  float: left
}

.two_columns_33_66>.column1>.column_inner {
  padding: 0 20px 0 0
}

.two_columns_33_66>.column2 {
  width: 66.66%;
  float: left
}

.two_columns_33_66>.column2>.column_inner {
  padding: 0 0 0 20px
}

.two_columns_75_25 {
  width: 100%
}

.two_columns_75_25>.column1 {
  width: 75%;
  float: left
}

.two_columns_75_25>.column1>.column_inner {
  padding: 0 20px 0 0
}

.two_columns_75_25>.column2 {
  width: 25%;
  float: left
}

.two_columns_75_25>.column2>.column_inner {
  padding: 0 0 0 20px
}

.q_message .message_text_holder,
.q_message.with_icon .message_text_holder,
.two_columns_25_75>.column1>.column_inner {
  padding: 0 20px 0 0
}

.two_columns_25_75 {
  width: 100%
}

.two_columns_25_75>.column1 {
  width: 25%;
  float: left
}

.two_columns_25_75>.column2 {
  width: 75%;
  float: left
}

.two_columns_25_75>.column2>.column_inner {
  padding: 0 0 0 20px
}

.two_columns_50_50 {
  width: 100%
}

.two_columns_50_50>.column1,
.two_columns_50_50>.column2 {
  width: 50%;
  float: left
}

.two_columns_50_50>.column1>.column_inner {
  padding: 0 10px 0 0
}

.two_columns_50_50>.column2>.column_inner {
  padding: 0 0 0 10px
}

.three_columns {
  width: 100%
}

.three_columns>.column1,
.three_columns>.column2,
.three_columns>.column3 {
  width: 33.33%;
  float: left
}

.three_columns>.column1>.column_inner {
  padding: 0 15px 0 0
}

.three_columns>.column2>.column_inner {
  padding: 0 5px 0 10px
}

.three_columns>.column3>.column_inner {
  padding: 0 0 0 15px
}

.four_columns {
  width: 100%
}

.four_columns>.column1,
.four_columns>.column2,
.four_columns>.column3,
.four_columns>.column4 {
  width: 25%;
  float: left
}

.four_columns>.column1>.column_inner {
  padding: 0 15px 0 0
}

.four_columns>.column2>.column_inner {
  padding: 0 10px 0 5px
}

.four_columns>.column3>.column_inner {
  padding: 0 5px 0 10px
}

.four_columns>.column4>.column_inner {
  padding: 0 0 0 15px
}

.five_columns>.column1,
.five_columns>.column2,
.five_columns>.column3,
.five_columns>.column4,
.five_columns>.column5 {
  width: 20%;
  float: left
}

.five_columns>.column1>.column_inner,
.five_columns>.column2>.column_inner,
.five_columns>.column3>.column_inner,
.five_columns>.column4>.column_inner {
  margin: 0 2.5% 0 0
}

.five_columns>.column5>.column_inner {
  margin: 0
}

.title_outer.animate_title_area {
  overflow: hidden
}

.title {
  width: 100%;
  height: 100px;
  text-align: left;
  z-index: 101;
  border-bottom-color: #eee
}

.title_outer.with_image .title {
  height: auto !important;
  line-height: normal;
  background: 0 0
}

.title img {
  width: 100%
}

.title .not_responsive {
  display: none
}

.title .title_holder {
  display: table;
  height: 100%;
  left: 0;
  position: relative;
  width: 100%
}

.title_outer .has_background.title .title_holder,
.title_outer .has_fixed_background.title .title_holder,
.title_outer.with_image .title .title_holder {
  position: absolute;
  top: 0;
  display: block
}

.title .title_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  background-position: 0 0;
  top: 0
}

.title.has_background,
.title.has_fixed_background,
section.parallax_section_holder {
  background-position: center 0;
  background-repeat: no-repeat
}

.title .title_holder .container {
  display: table-cell;
  vertical-align: middle;
  background-color: transparent
}

.title_outer .has_background.title .title_holder .container,
.title_outer .has_fixed_background.title .title_holder .container,
.title_outer.with_image .title .title_holder .container {
  display: block;
  height: 100%
}

.content .title_outer.with_image .title .container_inner,
.title_outer .has_background.title .title_holder .container_inner,
.title_outer .has_fixed_background.title .title_holder .container_inner {
  display: table;
  height: 100%
}

.title_subtitle_holder {
  display: block;
  padding: 0;
  position: relative
}

.title_outer .has_background.title .title_subtitle_holder,
.title_outer .has_fixed_background.title .title_subtitle_holder,
.title_outer.with_image .title .title_subtitle_holder {
  display: table-cell;
  vertical-align: middle;
  width: 100%
}

.title_subtitle_holder_inner {
  position: relative
}

.title h1 {
  color: #303030;
  padding: 0;
  text-transform: uppercase;
  font-weight: 600;
}

.title.title_size_medium h1 {
  font-size: 24px;
  line-height: 1.384615384615385em
}

.title h1,
.title.title_size_small h1 {
  font-size: 17px;
  line-height: 1.304347826086957em
}

.title.title_size_large h1 {
  font-size: 47px;
  line-height: 60px
}

.title_text_shadow .title h1 {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .4)
}

.subtitle {
  display: block
}

.title .text_above_title {
  display: block;
  color: #1abc9c;
  margin-bottom: 10px
}

.breadcrumb,
.breadcrumb .current,
.breadcrumb a {
  color: #303030
}

.title.title_size_large .text_above_title {
  font-size: 23px;
  line-height: 1.4em
}

.title_text_shadow .subtitle,
.title_text_shadow .text_above_title {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .4)
}

.animate_title_text .title h1 {
  -webkit-animation: title-from-right .5s 1 cubic-bezier(.175, .885, .32, 1.275) 1s;
  -moz-animation: title-from-right .5s 1 cubic-bezier(.175, .885, .32, 1.275) 1s;
  -o-animation: title-from-right .5s 1 cubic-bezier(.175, .885, .32, 1.275) 1s;
  animation: title-from-right .5s 1 cubic-bezier(.175, .885, .32, 1.275) 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible
}

@-webkit-keyframes title-from-right {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: translate(50%, 0)
  }

  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transform: translate(0, 0)
  }
}

@-moz-keyframes title-from-right {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -moz-transform: translate(50%, 0)
  }

  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -moz-transform: translate(0, 0)
  }
}

@-o-keyframes title-from-right {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    -o-transform: translate(50%, 0)
  }

  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    -o-transform: translate(0, 0)
  }
}

@keyframes title-from-right {
  0% {
    filter: alpha(opacity=0);
    opacity: 0;
    transform: translate(50%, 0)
  }

  100% {
    filter: alpha(opacity=100);
    opacity: 1;
    transform: translate(0, 0)
  }
}

.position_center.title .title_subtitle_holder {
  text-align: center
}

.position_right.title .title_subtitle_holder {
  text-align: right
}

.position_right .breadcrumb {
  left: 0;
  right: auto
}

.title.has_fixed_background {
  background-attachment: fixed;
  position: relative;
  z-index: 101
}

.breadcrumb {
  position: absolute;
  height: 100%;
  font-size: 13px;
  top: 0;
  right: 0
}

.box_image_holder,
.box_image_holder a,
.box_image_with_border {
  position: relative;
  display: block
}

.breadcrumbs {
  display: table;
  height: 100%;
  width: 100%;
  line-height: 1em
}

.breadcrumbs .breadcrumbs_inner {
  display: table-cell;
  vertical-align: middle
}

.title_text_shadow .breadcrumbs .breadcrumbs_inner {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .4)
}

.position_center.title .breadcrumb {
  position: relative;
  text-align: center
}

.title .separator.small,
.title .separator.small.left,
.title .separator.small.right {
  margin-top: 14px;
  margin-bottom: 10px
}

.title_border_in_grid_holder {
  height: 1px;
  width: 1100px;
  margin: 0 auto;
  background-color: #eee
}

.box_image_holder .box_icon .fa-stack i.fa-stack-base,
.breadcrumb a:hover {
  color: #1abc9c
}

.touchevents .title.has_fixed_background {
  background-attachment: scroll
}

.box_image_with_border {
  border-bottom: 5px solid #ddd;
  transition: all .4s ease-out 0s;
  text-align: center
}

.box_image_with_border:hover {
  border-color: #1abc9c
}

.box_image_with_border h3 {
  margin: 0 0 15px
}

.box_image_holder {
  margin: 0 0 56px;
  text-align: left
}

.box_image_holder .box_image_shadow {
  display: block;
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url(img/box_image_shadow.png); */
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% 30px;
  -webkit-background-size: 100% 30px;
  -moz-background-size: 100% 30px;
  -o-background-size: 100% 30px;
  transition: all .4s ease-out 0s
}

.box_image_with_border:hover .box_image_shadow {
  bottom: -13px;
  opacity: .2;
  filter: alpha(opacity=20)
}

.box_image_holder .image_holder_inner {
  position: relative;
  display: inline-block;
  width: 100%;
  top: 0;
  left: 0;
  transition: all .4s ease-out 0s
}

.box_image_with_border:hover .box_image_holder .image_holder_inner {
  top: -15px
}

.box_image_holder img {
  width: 100%
}

.box_image_holder .box_icon {
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.box_image_holder .box_icon .fa-stack {
  margin: 0 0 0 -50%;
  font-size: 4em
}

.box_image_holder .box_icon .fa-stack i {
  margin: 35% 0 0
}

.separator {
  position: relative;
  display: block;
  height: 1px;
  background-color: #eaeaea;
  margin: 10px 0
}

.separator.transparent {
  background-color: transparent
}

.separator.small {
  background-color: #303030;
  height: 2px;
  position: relative;
  width: 22px;
  display: block;
  margin: 10px auto 20px
}

.wpb_column>.wpb_wrapper .separator.small {
  margin-bottom: 20px
}

.separator.small.left {
  margin-left: 0;
  margin-right: 0
}

.separator.small.right {
  margin-left: auto;
  margin-right: 0
}

.q_icon_list {
  margin-bottom: 21px
}

.q_icon_list .qode-ili-icon-holder {
  float: left
}

.q_icon_list p {
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  margin: 0 0 10px;
  padding: 0 0 0 38px;
  position: relative;
  color: #303030
}

.q_icon_list .qode-ili-icon-holder,
.q_icon_list i {
  background-color: #1abc9c;
  color: #fff;
  font-size: 18px;
  line-height: 27px;
  height: 27px;
  width: 27px;
  text-align: center;
  border-radius: 2em;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -o-border-radius: 2em
}

.q_icon_list .qode-ili-icon-holder.transparent,
.q_icon_list i.transparent {
  background: 0 0;
  border: 0;
  color: #ababab
}

.q_progress_bar {
  position: relative;
  margin: 0 0 28px;
  width: 100%;
  overflow: hidden;
  text-align: left
}

.q_progress_bar .progress_content_outer {
  background-color: #e3e3e3;
  position: relative;
  overflow: hidden;
  height: 13px
}

.q_progress_bar .progress_content {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  background-color: #1abc9c;
  height: 13px;
  box-sizing: border-box
}

.q_progress_bar .progress_title_holder {
  position: relative;
  margin: 0 0 7px
}

.q_progress_bar .progress_title {
  display: inline-block;
  z-index: 100
}

.q_progress_bar .progress_number_wrapper {
  text-align: right;
  position: absolute;
  left: 0;
  z-index: 10;
  opacity: 0;
  filter: alpha(opacity=0);
  color: #fff
}

.q_progress_bar .progress_number {
  position: absolute;
  right: 0;
  font-size: 18px;
  font-weight: 500;
  top: -1px
}

.q_progress_bars_vertical .progress_content_outer {
  height: 200px;
  position: relative;
  background-color: #e3e3e3
}

.q_progress_bars_vertical .progress_number {
  font-size: 18px;
  line-height: 1em;
  color: #303030;
  display: block;
  position: relative;
  font-weight: 500;
  margin-bottom: 14px
}

.q_progress_bars_vertical .progress_title {
  margin-bottom: .7777777777777778em;
  margin-top: 1em
}

.q_progress_bars_vertica .progress_text {
  display: inline-block;
  line-height: 18px
}

.q_progress_bars_vertical .progress_content_outer .progress_content {
  display: inline-block;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #1abc9c;
  box-sizing: border-box
}

.q_counter_holder {
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity .4s ease 0s;
  -moz-transition: opacity .4s ease 0s;
  -o-transition: opacity .4s ease 0s;
  padding: 20px 0
}

.q_counter_holder.boxed_counter {
  border: 1px solid #eaeaea;
  padding: 20px 40px;
  background-color: #fff
}

.q_counter_holder.left {
  text-align: left
}

.q_counter_holder.right {
  text-align: right
}

.q_counter_holder.center {
  text-align: center;
  padding: 20px 40px
}

.q_counter_holder span.counter {
  font-size: 60px;
  line-height: 1em;
  font-weight: 300;
  color: #1abc9c;
  display: inline-block !important;
  height: 1em
}

.q_counter_holder p.counter_text {
  font-weight: 600;
  text-transform: uppercase;
  margin: 12px 0 0;
}

.q_counter_holder .separator.small {
  background-color: #eaeaea;
  margin: 19px auto 0
}

.q_counter_holder.left .separator.small {
  margin-left: 0;
  margin-right: auto
}

.q_counter_holder.right .separator.small {
  margin-left: auto;
  margin-right: 0
}

.q_box_holder {
  display: block;
  position: relative;
  border: 1px solid transparent;
  z-index: 200
}

.q_box_holder.with_icon {
  border: 2px solid silver;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px
}

.box_holder_inner {
  padding: 30px 20px;
  display: block
}

.q_box_holder.with_icon .box_holder_inner.tiny {
  padding: 37px 20px 30px
}

.q_box_holder.with_icon .box_holder_inner.small {
  padding: 45px 20px 30px
}

.q_box_holder.with_icon .box_holder_inner.medium {
  padding: 58px 20px 30px
}

.q_box_holder.with_icon .box_holder_inner.large {
  padding: 71px 20px 30px
}

.q_box_holder.with_icon .box_holder_inner.very_large {
  padding: 85px 20px 30px
}

.box_holder_inner.left {
  text-align: left
}

.box_holder_inner.right {
  text-align: right
}

.box_holder_inner.center {
  text-align: center
}

.box_holder_icon {
  position: absolute;
  display: block;
  left: 50%;
  top: 0;
  z-index: 250
}

.box_holder_icon_inner,
.box_holder_icon_inner.circle,
.box_holder_icon_inner.image,
.box_holder_icon_inner.square {
  margin: -50% 0 0 -50%
}

.box_holder_icon_inner.circle .fa-stack,
.circle .icon_holder .fa-stack {
  -o-border-radius: 2em;
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  -ms-border-radius: 2em;
  border-radius: 2em
}

.box_holder_icon .fa-stack i {
  color: #1abc9c
}

.box_holder_icon_inner.tiny i.fa-circle {
  font-size: 40px
}

.box_holder_icon_inner.image.tiny img {
  height: 35px
}

.box_holder_icon_inner.image.small img {
  height: 52px
}

.box_holder_icon_inner.image.medium img {
  height: 78px
}

.box_holder_icon_inner.image.large img {
  height: 104px
}

.box_holder_icon_inner.image.very_large img {
  height: 130px
}

#submit_comment,
.blog_load_more_button a,
.drop_down .wide .second ul li .qbutton,
.drop_down .wide .second ul li ul li .qbutton,
.load_more a,
.qbutton {
  position: relative;
  display: inline-block;
  width: auto;
  height: 39px;
  line-height: 39px;
  margin: 0;
  padding: 0 23px;
  border: 2px solid #303030;
  font-size: 13px;
  font-weight: 700;
  font-family: inherit;
  text-align: left;
  color: #303030;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;
  font-style: normal;
  text-transform: uppercase;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  background-color: transparent;
  -webkit-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -moz-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -ms-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -o-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  box-sizing: initial !important
}

.portfolio_slides .hover_feature_holder_inner,
.qbutton.big_large_full_width {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box
}

.qbutton.enlarge {
  -webkit-transition: color .1s linear, background-color .1s linear, border-color .1s linear, padding .1s linear;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear, padding .1s linear
}

input.qbutton {
  line-height: 36px
}

#submit_comment:hover,
.blog_load_more_button a:hover,
.drop_down .wide .second ul li .qbutton:hover,
.drop_down .wide .second ul li ul li .qbutton:hover,
.load_more a:hover,
.qbutton:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff;
  text-decoration: none
}

.qbutton.left {
  text-align: left
}

.call_to_action .column2.button_wrapper,
.qbutton.right {
  text-align: right
}

.qbutton.center {
  text-align: center
}

.qbutton.big_large {
  height: 58px;
  line-height: 58px;
  font-size: 20px;
  padding: 0 22px
}

.qbutton.big_large_full_width {
  width: 100%;
  height: 90px;
  line-height: 90px;
  font-size: 20px;
  padding: 0;
  text-align: center
}

.qbutton.large {
  height: 47px;
  line-height: 47px;
  font-size: 16px;
  padding: 0 29px
}

.qbutton.small {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  padding: 0 17px
}

.qbutton.white {
  border-color: #fff;
  color: #fff
}

.portfolio_slides .hover_feature_holder_inner .qbutton:hover,
.qbutton.green,
.qbutton.white:hover {
  border-color: #1abc9c
}

.qbutton.white:hover {
  background-color: #1abc9c
}

.qbutton.green {
  color: #fff;
  background-color: #1abc9c
}

.qbutton.green:hover {
  background-color: #fff;
  border-color: #fff;
  color: #a2a2a2
}

.qbutton.qode-button-shadow {
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, .09)
}

.qode-qbutton-main-color {
  position: relative;
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  font-family: inherit;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;
  font-style: normal;
  text-transform: uppercase;
  border: none;
  color: #fff;
  background-color: #1abc9c;
  height: 80px;
  line-height: 80px
}

.qode-qbutton-main-color:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, .2);
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out
}

.call_to_action,
.call_to_action .text_wrapper,
.call_to_action .two_columns_75_25,
.easyPieChart,
.image_with_text,
.qode-qbutton-main-color span {
  position: relative
}

.q_percentage,
.q_percentage_with_icon,
.q_pie_chart_holder,
.q_pie_chart_with_icon_holder {
  transition: opacity .3s ease 0s;
  -moz-transition: opacity .3s ease 0s;
  -o-transition: opacity .3s ease 0s;
  filter: alpha(opacity=0)
}

.qode-qbutton-main-color span {
  z-index: 2
}

.qode-qbutton-main-color:hover {
  color: #fff
}

.qode-qbutton-main-color:hover:before {
  opacity: 1
}

.qode-qbutton-full-width {
  width: 100%;
  box-sizing: border-box !important;
  text-align: center
}

.qode-qbutton-square {
  border-radius: 0
}

.qbutton .qode_button_icon_element,
.qbutton i {
  margin: 0 0 0 10px
}

.qbutton.large .qode_button_icon_element,
.qbutton.large i {
  margin: 0 0 0 17px
}

.qbutton.medium .qode_button_icon_element,
.qbutton.medium i {
  margin: 0 0 0 15px
}

.qbutton.small .qode_button_icon_element,
.qbutton.small i {
  margin: 0 0 0 10px
}

.qbutton.tiny .qode_button_icon_element,
.qbutton.tiny i {
  margin: 0 0 0 8px
}

.qbutton.big_large .qode_button_icon_element,
.qbutton.big_large i,
.qbutton.big_large_full_width .qode_button_icon_element,
.qbutton.big_large_full_width i {
  margin: 0 0 0 19px
}

#submit_comment,
.load_more.tiny a {
  height: 39px;
  line-height: 39px;
  font-size: 12px;
  padding: 0 18px
}

#submit_comment {
  line-height: 36px
}

.easyPieChart canvas {
  position: absolute;
  top: 0;
  left: 0
}

.q_pie_chart_holder,
.q_pie_chart_with_icon_holder {
  display: block;
  margin: 0 0 30px;
  opacity: 0
}

.q_percentage {
  font-size: 35px;
  font-weight: 500;
  color: #303030;
  text-align: center;
  margin: 0 auto;
  opacity: 0
}

.q_percentage_with_icon {
  text-align: center;
  opacity: 0;
  backface-visibility: hidden;
}

.q_pie_chart_holder .tocounter {
  float: none;
  margin: 0
}

.q_percentage_with_icon,
.q_percentage_with_icon i {
  float: none;
  margin: 0;
  vertical-align: middle !important;
  color: #b9b9b9
}

.q_pie_chart_holder .pie_chart_text,
.q_pie_chart_with_icon_holder .pie_chart_text {
  text-align: center;
  margin: 28px 0 0
}

.q_pie_chart_holder .pie_chart_text .pie_title,
.q_pie_chart_with_icon_holder .pie_chart_text .pie_title {
  margin: 0 0 .35em
}

.q_pie_chart_holder .separator.small {
  background-color: #eaeaea;
  margin: 10px auto 12px
}

.image_with_text {
  display: inline-block;
  width: 100%
}

.image_with_text img {
  display: block;
  margin: 0 0 22px
}

.call_to_action {
  display: block;
  padding: 51px 21px;
  background-color: #1abc9c
}

.content .container .container_inner.page_container_inner .call_to_action .container_inner {
  width: auto
}

.call_to_action .text_wrapper .call_to_action_text {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
}

.call_to_action .text_wrapper .call_to_action_text.font_size_inherit p {
  font-size: inherit;
}

.call_to_action .text_wrapper .call_to_action_text.color_inherit p {
  color: inherit;
}

.call_to_action .text_wrapper .call_to_action_text.font_weight_inherit p {
  font-weight: inherit;
}

.call_to_action .text_wrapper .call_to_action_text.letter_spacing_inherit p {
  letter-spacing: inherit;
}

.call_to_action.with_icon .text_wrapper .call_to_action_text {
  padding: 0 0 0 54px;
  line-height: 1em;
}

.call_to_action .text_wrapper .call_to_action_icon_holder {
  position: absolute;
  height: 100%;
}

.call_to_action .text_wrapper .call_to_action_icon {
  display: table;
  height: 100%;
}

.call_to_action .text_wrapper .call_to_action_icon_inner {
  display: table-cell;
  height: 100%;
  vertical-align: middle
}

.call_to_action .text_wrapper .call_to_action_icon_inner i {
  color: #fff
}

.call_to_action .column2.button_wrapper .qbutton {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

.call_to_action.simple .text_wrapper,
.call_to_action.simple .text_wrapper>* {
  position: relative;
  display: inline-block;
  vertical-align: middle
}

.call_to_action.simple .text_wrapper {
  width: 100%;
  text-align: center
}

.call_to_action.simple .text_wrapper>.qbutton {
  margin: 0 0 0 35px
}

.full_width .parallax_content {
  width: 1100px;
  margin: 0 auto
}

section.section section.parallax {
  margin: -50px 0
}

.boxed section.section section.parallax {
  margin: -50px -25px
}

.boxed .full_width .parallax_content {
  width: auto;
  margin: 0;
  padding: 0 25px
}

section.parallax_section_holder {
  position: static;
  padding: 0;
  background-color: transparent;
  background-attachment: fixed;
  overflow: hidden
}

section.parallax_section_holder.elementor-element {
  position: relative
}

section.parallax_section_holder.qode_vertical_middle_align {
  display: table;
  width: 100%
}

.parallax_content,
.parallax_content_full_width {
  z-index: 100;
  position: relative
}

.qode_vertical_middle_align .parallax_content_outer {
  display: table-cell;
  height: 100%;
  vertical-align: middle
}

.parallax_content.left,
.parallax_content_full_width.left {
  text-align: left
}

.parallax_content.center,
.parallax_content_full_width.center {
  text-align: center
}

.parallax_content.right,
.parallax_content_full_width.right {
  text-align: right
}

.portfolio_single {
  display: block;
  position: relative
}

.lightbox_single_portfolio,
.portfolio_detail,
.portfolio_single_text_holder {
  position: relative
}

.portfolio_single .flexslider,
.portfolio_single .portfolio_single_text_holder {
  margin: 0 0 40px
}

.portfolio_single .portfolio_images .fluid-width-video-wrapper,
.portfolio_single .portfolio_images img {
  margin: 0 0 18px
}

.portfolio_single a.lightbox_single_portfolio.video_in_lightbox {
  display: block
}

.portfolio_single a.lightbox_single_portfolio>i {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  vertical-align: middle;
  font-size: 40px;
  color: #fff;
  margin: -20px 0 0 -15px;
  z-index: 110
}

.portfolio_single .portfolio_container {
  position: relative;
  z-index: 200
}

.portfolio_single h2 {
  margin: 0 0 5px
}

.portfolio_single h3 {
  margin: 0 0 8px
}

.portfolio_detail.portfolio_single_follow {
  position: relative
}

.portfolio_detail .info {
  padding: 0 0 9px;
  margin: 0 0 9px
}

.portfolio_detail .info:last-child {
  border: 0
}

.portfolio_template_8 .portfolio_detail .info {
  display: inline-block;
  margin-bottom: 20px;
  padding: 0;
  vertical-align: middle;
  width: 100%
}

.portfolio_template_8 .portfolio_detail .info h6 {
  float: left;
  width: 35%;
  font-weight: 600
}

.portfolio_template_8 .portfolio_detail .info p,
.portfolio_template_8 .portfolio_detail .info span {
  float: right;
  width: 65%;
  font-size: 12px;
  line-height: 1.6em
}

.portfolio_template_8 .portfolio_detail .info.portfolio_categories .category {
  color: #1abc9c
}

.video_holder {
  position: relative;
  width: 100%;
  display: block;
  min-height: 1px
}

.portfolio_single .video .mobile-video-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10
}

.portfolio_single .video {
  margin: 0 0 44px;
  position: relative
}

.portfolio_single .video .video-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 10
}

.portfolio_single .video .video-wrap .mejs-poster {
  background-size: cover !important;
  -moz-background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  width: 100% !important;
  height: 100% !important
}

.portfolio_single .video .video-wrap .mejs-container {
  background-color: transparent !important;
  background-image: none !important;
  height: 100% !important;
  width: 100% !important;
  overflow: hidden
}

.portfolio_single .video .video-wrap .mejs-mediaelement {
  background: 0 0 !important;
  border: 0 !important
}

.portfolio_single .video .video-wrap .mejs-container .mejs-poster img {
  max-width: none !important;
  width: 100% !important
}

.portfolio_single .mejs-container .mejs-controls {
  visibility: visible !important
}

.portfolio_single .mejs-controls .mejs-volume-button .mejs-volume-slider {
  display: none !important
}

.flexslider .slides .mejs-poster img,
.portfolio_slider .portfolio_slides .mejs-poster img {
  display: none
}

.portfolio_single .flexslider .video .video-wrap {
  margin: 0
}

.portfolio_navigation {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 28px 0 50px;
  z-index: 100;
  position: relative
}

.portfolio_navigation .portfolio_button,
.portfolio_navigation .portfolio_next,
.portfolio_navigation .portfolio_prev {
  display: inline-block;
  height: 35px;
  line-height: 35px
}

.portfolio_navigation .portfolio_prev {
  position: absolute;
  left: 0
}

.portfolio_navigation .portfolio_next {
  position: absolute;
  right: 0
}

.portfolio_navigation .portfolio_next a,
.portfolio_navigation .portfolio_prev a {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  margin: 0 11px 0 0;
  text-align: center;
  font-size: 21px;
  color: #b4b4b4;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border: 2px solid #e5e5e5;
  outline: 0;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.portfolio_navigation .portfolio_button a {
  /* background-image: url(img/portfolio_list_button.png); */
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 19px;
  display: block;
  height: 19px;
  margin: 11px 0 0;
  -webkit-transition: background-image .3s ease-in-out;
  -moz-transition: background-image .3s ease-in-out;
  -ms-transition: background-image .3s ease-in-out;
  -o-transition: background-image .3s ease-in-out;
  transition: background-image .3s ease-in-out
}

.portfolio_navigation .portfolio_button a:hover {
  /* background-image: url(img/portfolio_list_button_hover.png) */
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio:1.5),
only screen and (-o-min-device-pixel-ratio:150 / 100),
only screen and (min-device-pixel-ratio:1.5),
only screen and (min-resolution:160dpi) {
  .portfolio_navigation .portfolio_button a {
    /* background-image:url(img/portfolio_list_button@1_5x.png); */
    -o-background-size: 19px 19px;
    -webkit-background-size: 19px 19px;
    -moz-background-size: 19px 19px;
    background-size: 19px 19px
  }

  .portfolio_navigation .portfolio_button a:hover {
    /* background-image: url(img/portfolio_list_button_hover@1_5x.png) */
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:200 / 100),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:210dpi) {
  .portfolio_navigation .portfolio_button a {
    /* background-image:url(img/portfolio_list_button@2x.png); */
    -o-background-size: 19px 19px;
    -webkit-background-size: 19px 19px;
    -moz-background-size: 19px 19px;
    background-size: 19px 19px
  }

  .portfolio_navigation .portfolio_button a:hover {
    /* background-image: url(img/portfolio_list_button_hover@2x.png) */
  }
}

.portfolio_navigation .portfolio_next a:hover,
.portfolio_navigation .portfolio_prev a:hover {
  color: #303030;
  background-color: #e3e3e3;
  border-color: #e3e3e3;
  /* background-image: url(img/button-bg-px.png)!important */
}

.portfolio_navigation.navigation_title {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding: 35px 0 30px;
  margin-top: 40px
}

.portfolio_navigation.navigation_title .portfolio_next a,
.portfolio_navigation.navigation_title .portfolio_prev a {
  border: 0 !important;
  width: auto;
  height: auto;
  color: #303030;
  background-color: transparent !important
}

.portfolio_navigation.navigation_title .post_info {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  padding-left: 20px
}

.portfolio_navigation.navigation_title .portfolio_next .post_info {
  padding-left: 0;
  padding-right: 20px;
  text-align: right
}

.portfolio_navigation.navigation_title .post_info span {
  display: block
}

.portfolio_navigation.navigation_title .post_info span.categories {
  font-size: 12px;
  color: #1abc9c;
  line-height: 1em;
  text-transform: none;
  margin-bottom: 8px
}

.portfolio_social_holder {
  width: 100%;
  display: inline-block
}

.portfolio_single .portfolio_social_holder .portfolio_like,
.portfolio_single .portfolio_social_holder .portfolio_share {
  display: inline-block;
  margin: 0
}

.portfolio_single.portfolio_template_8 .icon_social_holder {
  margin-top: 10px;
  margin-bottom: 65px
}

.qode_portfolio_related {
  padding-bottom: 25px
}

.qode_portfolio_related h4 {
  padding: 85px 0 55px;
  text-align: center
}

.qode_portfolio_related .projects_holder article .portfolio_description .project_category {
  margin: 0;
  font-size: 12px;
  color: #1abc9c;
  line-height: 1.5em;
  text-transform: none;
  font-weight: 300
}

.portfolio_gallery {
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 15px
}

.portfolio_single .portfolio_gallery {
  margin: 0 0 3px
}

.portfolio_gallery a {
  position: relative;
  float: left;
  display: inline-block;
  overflow: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.portfolio_gallery a.v2 {
  width: 49%;
  margin: 0 2% 2% 0
}

.portfolio_gallery a.v3 {
  width: 32%;
  margin: 0 2% 2% 0
}

.portfolio_gallery a.v4 {
  width: 23.5%;
  margin: 0 2% 2% 0
}

.portfolio_gallery a.v2:nth-child(2n),
.portfolio_gallery a.v3:nth-child(3n),
.portfolio_gallery a.v4:nth-child(4n) {
  margin: 0 0 2%
}

.portfolio_gallery a frame,
.portfolio_gallery a img {
  position: relative;
  display: block;
  width: 100%;
  z-index: 100
}

.portfolio_gallery iframe {
  min-height: 200px
}

.portfolio_gallery a .gallery_text_holder {
  background-color: #000;
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  z-index: 200;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: visible;
  transition: opacity .4s;
  -moz-transition: opacity .4s;
  -o-transition: opacity .4s;
  -ms-transition: opacity .4s
}

.portfolio_gallery a img {
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.portfolio_gallery a:hover .gallery_text_holder {
  opacity: 1;
  filter: alpha(opacity=100)
}

.portfolio_gallery a .gallery_text_inner {
  display: table;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%
}

.portfolio_gallery a .gallery_text_inner h6 {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 15px;
  color: #fff
}

.projects_holder_outer {
  margin: 0;
  display: block;
  width: 100%
}

.projects_holder_outer .container .container_inner {
  padding: 0
}

.full_width .projects_holder_outer.v4 .hover_text,
.full_width .projects_holder_outer.v4 .standard,
.full_width .projects_holder_outer.v5 .hover_text,
.full_width .projects_holder_outer.v5 .standard,
.full_width .projects_holder_outer.v6 .hover_text,
.full_width .projects_holder_outer.v6 .standard {
  width: 95%;
  margin: 0 auto
}

.full_width .section_inner .projects_holder_outer.v4 .hover_text,
.full_width .section_inner .projects_holder_outer.v4 .standard,
.full_width .section_inner .projects_holder_outer.v5 .hover_text,
.full_width .section_inner .projects_holder_outer.v5 .standard,
.full_width .section_inner .projects_holder_outer.v6 .hover_text,
.full_width .section_inner .projects_holder_outer.v6 .standard {
  width: 100%
}

.full_width .projects_holder_outer.v4 .hover_text.no_space,
.full_width .projects_holder_outer.v5 .hover_text.no_space,
.full_width .projects_holder_outer.v6 .hover_text.no_space {
  width: 100%;
  margin: 0
}

.projects_holder {
  font-size: .1px;
  line-height: 0;
  list-style-type: none;
  text-align: justify
}

.projects_holder:after,
.projects_holder:before {
  content: "";
  display: inline-block !important;
  width: 100%;
  vertical-align: middle
}

.projects_holder>.mix {
  visibility: hidden;
  position: relative;
  vertical-align: top;
  -webkit-transition: all .7s ease-out;
  -moz-transition: all .7s ease-out;
  -o-transition: all .7s ease-out;
  transition: all .7s ease-out;
  text-align: left
}

.projects_holder.hideItems>.mix {
  display: none
}

.projects_holder.hover_text.no_space:not(.portfolio_full_image)>.mix {
  float: left
}

.projects_holder .mix .image {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  transform: translateZ(0);
  -webkit-transform: translateZ(0)
}

.projects_holder article .image img {
  position: relative;
  display: block;
  width: 100%;
  z-index: 100
}

.portfolio_slider .portfolio_slides li.item img,
.projects_holder article .image img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  backface-visibility: hidden
}

.projects_holder.hover_text.no_space article .image img {
  margin: 0 1px
}

.portfolio_slider .portfolio_slides li.item:hover img,
.projects_holder article .image_holder:hover .image img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1)
}

.projects_holder article .image_holder,
.projects_holder article .portfolio_description {
  display: block;
  position: relative;
  overflow: hidden
}

.projects_holder article .image_holder.portfolio_jg_image_link {
  position: static
}

.projects_holder article .portfolio_description {
  padding: 20px 0 22px;
  text-align: center;
  background-color: #fff;
  border-style: none;
  border-top: none !important;
  border-width: 1px;
  border-color: #fefefe
}

.projects_holder article .portfolio_description.text_align_left {
  text-align: left
}

.projects_holder article .portfolio_description.text_align_center {
  text-align: center
}

.projects_holder article .portfolio_description.text_align_right {
  text-align: right
}

.standard_no_space.projects_holder article .portfolio_description {
  margin: 0 -1px 0 0
}

.projects_holder article .portfolio_description .portfolio_title {
  display: block;
  margin: 0 0 7px
}

.portfolio_slider .image_holder .separator.small {
  margin: 10px auto;
  background-color: #fff
}

.projects_holder article .hover_feature_holder_title .separator.small,
.projects_masonry_holder article .hover_feature_holder_title .separator.small {
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 10px
}

.projects_holder article .portfolio_description .separator.small {
  margin-top: 10px;
  margin-bottom: 10px
}

.wpb_column>.wpb_wrapper .portfolio_slider .image_holder .separator.small {
  background-color: #fff
}

.portfolio_slider .image_holder .separator.small.transparent {
  background-color: transparent !important
}

.projects_holder.hover_text article .hover_feature_holder_title .separator,
.projects_holder.justified-gallery article .hover_feature_holder_title .separator {
  background-color: #fff
}

.projects_holder.hover_text article .hover_feature_holder_title .portfolio_title a,
.projects_holder.hover_text article span.text_holder span.text_inner .hover_feature_holder_title .project_category,
.projects_holder.justified-gallery article .hover_feature_holder_title .portfolio_title a,
.projects_holder.justified-gallery article span.text_holder span.text_inner .hover_feature_holder_title .project_category {
  color: #fff
}

.projects_holder.hover_text article span.text_holder span.text_inner .hover_feature_holder_title .project_category,
.projects_holder.justified-gallery article span.text_holder span.text_inner .hover_feature_holder_title .project_category {
  font-weight: 500
}

.projects_holder.hover_text article .hover_feature_holder_title .portfolio_title,
.projects_holder.justified-gallery article .hover_feature_holder_title .portfolio_title {
  margin: 0 0 7px
}

.projects_holder article .portfolio_description .project_category,
.projects_holder.hover_text article .project_category,
.projects_holder.justified-gallery article .project_category {
  line-height: 22px;
  display: block;
  font-size: 13px;
  font-weight: 500
}

article .qode-portfolio-new-badge {
  position: absolute;
  top: 6px;
  right: 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 9px;
  text-transform: uppercase;
  color: #fff;
  background-color: #303030;
  z-index: 1000;
  border-radius: 100%;
  font-weight: 700;
}

.portfolio_single .portfolio_like {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0;
}

.portfolio_single .dots {
  padding: 0 7px 0 4px;
}

.portfolio_single .dots i {
  font-size: 3px;
  vertical-align: middle;
}

.portfolio_single .portfolio_like a {
  display: block;
  font-size: 13px;
  line-height: 13px;
  text-align: center
}

.portfolio_single .portfolio_like span {
  line-height: 19px
}

.portfolio_slider .portfolio_like a:hover i,
.portfolio_slider .portfolio_like span {
  color: #e0e0e0
}

.projects_holder article .portfolio_like a,
.projects_holder article .portfolio_like:hover a {
  color: #fff
}

.projects_holder article span.text_holder {
  background-color: #000;
  background-color: rgba(21, 21, 21, .78);
  bottom: 0;
  display: inline-block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  transition: opacity .4s ease-in-out;
  -webkit-transition: opacity .4s ease-in-out;
  -moz-transition: opacity .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out;
  -ms-transition: opacity .4s ease-in-out;
  visibility: visible;
  overflow: hidden;
  width: 100%;
  z-index: 200
}

.touchevents .projects_holder article span.text_holder {
  display: none !important;
  height: 0 !important;
  width: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important
}

.projects_holder article:hover span.text_holder {
  height: 100% !important
}

.projects_holder article .image_holder:hover span.text_holder,
.projects_holder article:hover .image_holder.portfolio_jg_image_link+span.text_holder {
  opacity: 1;
  filter: alpha(opacity=100)
}

.projects_holder.hover_text.with_mask article .image_holder:hover .image_hover {
  opacity: 0;
  filter: alpha(opacity=0)
}

.projects_holder article span.text_holder span.text_outer {
  display: table;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  overflow: hidden
}

.projects_holder article span.text_holder span span.text_inner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0
}

.projects_holder article span.text_holder span span.text_inner .project_category {
  color: #A6A6A6;
  display: block;
  font-size: 13px;
  line-height: 22px
}

.projects_holder article .feature_holder,
.projects_holder article .feature_holder .feature_holder_icons {
  display: inline-block
}

.projects_holder.hover_text article .feature_holder,
.projects_holder.justified-gallery article .feature_holder {
  width: 100%
}

.projects_holder.hover_text article .feature_holder .feature_holder_icons,
.projects_holder.justified-gallery article .feature_holder .feature_holder_icons {
  margin: 20px 0 0
}

.portfolio_slider,
.portfolio_slides .image_holder {
  position: relative;
  display: block;
  backface-visibility: hidden;
}

.portfolio_slides .image_pixel_hover {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  background-color: transparent;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  z-index: 100
}

.touchevents .portfolio_slides .image_pixel_hover {
  display: none !important
}

.portfolio_slides li:hover .image_pixel_hover {
  background-color: #000;
  background-color: rgba(21, 21, 21, .78)
}

.portfolio_slides .image_holder .image {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  -webkit-transition: -webkit-transform .4s;
  -moz-transition: -moz-transform .4s;
  transition: transform .4s;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden
}

.portfolio_slides .hover_feature_holder {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 101
}

.touchevents .portfolio_slides .hover_feature_holder {
  display: none !important
}

.portfolio_slides .hover_feature_holder_icons {
  position: relative;
  opacity: 0;
  text-align: center;
  width: 100%;
  margin: 0 0 30px;
  -webkit-transition: opacity .4s ease-in-out .1s;
  -moz-transition: opacity .4s ease-in-out .1s;
  -o-transition: opacity .4s ease-in-out .1s;
  -ms-transition: opacity .4s ease-in-out .1s;
  transition: opacity .4s ease-in-out .1s;
  transform: translateZ(0);
  -moz-transform: translateZ(0);
  display: none
}

.gallery_holder ul li img,
.portfolio_slides .hover_feature_holder_inner {
  transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out
}

.portfolio_slides li:hover .hover_feature_holder_icons {
  opacity: 1
}

.portfolio_slides .hover_feature_holder_icons_inner {
  display: inline-block;
  position: relative
}

.portfolio_slides .hover_feature_holder_outer {
  position: relative;
  opacity: 1 !important;
  top: 0;
  height: 100%;
  display: table;
  width: 100%
}

.portfolio_slides .hover_feature_holder_inner {
  height: 100%;
  width: 100%;
  top: 0;
  box-sizing: border-box;
  opacity: 0;
  display: table-cell;
  vertical-align: middle;
  padding: 0 5%;
  text-align: center;
  color: #fff;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden
}

.portfolio_slides .hover_feature_holder_inner .portfolio_title {
  text-transform: uppercase;
}

.portfolio_slides .hover_feature_holder_inner .portfolio_title a {
  color: #fff;
}

.portfolio_slides .hover_feature_holder_inner .qbutton {
  margin-top: 24px;
  margin-left: 5px;
  margin-right: 5px;
}

.portfolio_slides li:hover .hover_feature_holder_inner {
  opacity: 1;
}

.projects_holder.hover_text article .image_holder {
  overflow: hidden;
}

.projects_holder.hover_text.no_space article .image_holder {
  margin: 0 -1px;
}

.portfolio_slider .portfolio_like,
.portfolio_slider a.lightbox,
.portfolio_slider a.preview,
.projects_holder article .portfolio_like,
.projects_holder article a.lightbox,
.projects_holder article a.preview {
  position: relative;
  display: inline-block;
  margin: 0 0 5px;
  backface-visibility: hidden
}

.portfolio_like .qode-like-count span,
.portfolio_paging_loading {
  display: none
}

.portfolio_slider a.lightbox,
.projects_holder article a.lightbox {
  margin: 0 10px 0 0
}

.portfolio_slider .portfolio_like,
.projects_holder article .portfolio_like {
  margin: 0 0 0 10px
}

.portfolio_slider .portfolio_like i,
.projects_holder article .portfolio_like i {
  line-height: 20px;
  margin: 4px 0 0
}

.portfolio_slider article .portfolio_like .qode-like-count,
.projects_holder article .portfolio_like .qode-like-count {
  font-size: 13px;
  line-height: 13px;
  color: #fff
}

.filter_holder ul li.active span,
.filter_holder ul li:hover span,
.latest_post_two_holder .latest_post_two_text a:hover {
  color: #1abc9c !important
}

.projects_holder .filler {
  display: inline-block;
  height: 0;
  vertical-align: middle
}

.projects_holder.hover_text.no_space .mix {
  float: left
}

.projects_holder.v6 .mix,
.projects_holder.v6.hover_text .mix {
  width: 15.7%;
  margin: 0 0 1.2%
}

.projects_holder.v6.hover_text .mix {
  margin: 0 0 1.7%
}

.projects_holder.v6 .filler,
.projects_holder.v6.hover_text .filler {
  width: 15.7%
}

.projects_holder.v6.hover_text.no_space .mix,
.projects_holder.v6.standard_no_space .mix {
  width: 16.66%;
  margin: 0
}

.projects_holder.v6.standard_no_space .mix {
  margin: 0
}

.projects_holder.v6.hover_text.no_space .filler,
.projects_holder.v6.standard_no_space .filler {
  width: 16.66%
}

.safari_browser .projects_holder.v6.hover_text.no_space .filler,
.safari_browser .projects_holder.v6.hover_text.no_space .mix,
.safari_browser .projects_holder.v6.standard_no_space .filler,
.safari_browser .projects_holder.v6.standard_no_space .mix {
  width: 16.6%
}

.projects_holder.v5 .mix,
.projects_holder.v5.hover_text .mix {
  width: 18%;
  margin: 0 0 2.9%
}

.projects_holder.v5.hover_text .mix {
  margin: 0 0 2%
}

.projects_holder.v5 .filler,
.projects_holder.v5.hover_text .filler {
  width: 18.5%
}

.projects_holder.v5.hover_text.no_space .mix,
.projects_holder.v5.standard_no_space .mix {
  width: 19.99%;
  margin: 0
}

.projects_holder.v5.standard_no_space .mix {
  margin: 0
}

.projects_holder.v5.hover_text.no_space .filler,
.projects_holder.v5.standard_no_space .filler {
  width: 19.99%
}

.safari_browser .projects_holder.v5.hover_text.no_space .filler,
.safari_browser .projects_holder.v5.hover_text.no_space .mix,
.safari_browser .projects_holder.v5.standard_no_space .filler,
.safari_browser .projects_holder.v5.standard_no_space .mix {
  width: 19.93%
}

.projects_holder.v4 .mix,
.projects_holder.v4.hover_text .mix {
  width: 23.5%;
  margin: 0 0 2.5%
}

.projects_holder.v4.hover_text .mix {
  margin: 0 0 2.2%
}

.projects_holder.v4 .filler,
.projects_holder.v4.hover_text .filler {
  width: 23.5%
}

.projects_holder.v4.hover_text.no_space .mix,
.projects_holder.v4.standard_no_space .mix {
  width: 24.99%;
  margin: 0
}

.projects_holder.v4.standard_no_space .mix {
  margin: 0
}

.projects_holder.v4.hover_text.no_space .filler,
.projects_holder.v4.standard_no_space .filler {
  width: 24.99%
}

.projects_holder.v3 .mix,
.projects_holder.v3.hover_text .mix {
  width: 32%;
  margin: 0 0 2.4%
}

.projects_holder.v3.hover_text .mix {
  margin: 0 0 2.2%
}

.projects_holder.v3 .filler,
.projects_holder.v3.hover_text .filler {
  width: 32%
}

.projects_holder.v3.hover_text.no_space .mix,
.projects_holder.v3.standard_no_space .mix {
  width: 33.32%;
  margin: 0
}

.projects_holder.v3.standard_no_space .mix {
  margin: 0
}

.projects_holder.v3.hover_text.no_space .filler,
.projects_holder.v3.standard_no_space .filler {
  width: 33.32%
}

.safari_browser .projects_holder.v3.hover_text.no_space .filler,
.safari_browser .projects_holder.v3.hover_text.no_space .mix,
.safari_browser .projects_holder.v3.standard_no_space .filler,
.safari_browser .projects_holder.v3.standard_no_space .mix {
  width: 33.3%
}

.projects_holder.v1 .mix,
.projects_holder.v1.hover_text .mix {
  width: 100%;
  margin: 0 0 2.4%
}

.projects_holder.v1.hover_text .mix {
  margin: 0 0 2.1%
}

.projects_holder.v1 .filler,
.projects_holder.v1.hover_text .filler {
  width: 100%
}

.projects_holder.v1.hover_text.no_space .mix,
.projects_holder.v1.standard_no_space .mix {
  width: 100%;
  margin: 0
}

.projects_holder.v1.standard_no_space .mix {
  margin: 0
}

.projects_holder.v1.hover_text.no_space .filler,
.projects_holder.v1.standard_no_space .filler {
  width: 100%
}

.projects_holder.v2 .mix,
.projects_holder.v2.hover_text .mix {
  width: 49%;
  margin: 0 0 2.4%
}

.projects_holder.v2.hover_text .mix {
  margin: 0 0 2.1%
}

.projects_holder.v2 .filler,
.projects_holder.v2.hover_text .filler {
  width: 49%
}

.projects_holder.v2.hover_text.no_space .mix,
.projects_holder.v2.standard_no_space .mix {
  width: 49.99%;
  margin: 0
}

.projects_holder.v2.standard_no_space .mix {
  margin: 0
}

.projects_holder.v2.hover_text.no_space .filler,
.projects_holder.v2.standard_no_space .filler {
  width: 49.99%
}

.portfolio_paging,
.portfolio_paging_loading {
  text-align: center;
  margin: 40px 0 0
}

.portfolio_with_space .portfolio_paging,
.portfolio_with_space .portfolio_paging_loading {
  margin: 15px 0 0
}

.filter_outer {
  display: table;
  width: 100%;
  position: relative;
  height: 37px;
  margin: -30px 0 40px;
  text-align: center
}

.full_width .filter_outer {
  margin: 0 0 40px
}

.filter_holder {
  display: table-cell;
  vertical-align: middle
}

.filter_holder ul {
  display: inline-block;
  list-style: none
}

.portfolio_holder_fwn .filter_holder ul li {
  opacity: 0
}

.filter_holder ul li {
  cursor: pointer;
  display: block;
  margin: 0;
  float: left;
  text-align: center;
}

.filter_holder ul li span {
  display: inline-block;
  padding: 0 22px;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
}

.filter_holder ul li span.filter_number_of_items {
  display: block;
  font-size: 26px;
  line-height: 1em;
  height: 1em;
  margin-bottom: 12px;
}

.projects_masonry_holder {
  opacity: 0;
}

.projects_masonry_holder .portfolio_masonry_item,
.projects_masonry_holder .qode-portfolio-masonry-gallery-grid-sizer {
  width: 33.33%
}

.projects_masonry_holder .portfolio_masonry_item.large_width,
.projects_masonry_holder .portfolio_masonry_item.large_width_height,
.projects_masonry_holder .qode-portfolio-masonry-gallery-grid-sizer.large_width,
.projects_masonry_holder .qode-portfolio-masonry-gallery-grid-sizer.large_width_height {
  width: 66.66%
}

.full_width .projects_masonry_holder .portfolio_masonry_item,
.full_width .projects_masonry_holder .qode-portfolio-masonry-gallery-grid-sizer {
  width: 20%
}

.full_width .projects_masonry_holder .portfolio_masonry_item.large_width,
.full_width .projects_masonry_holder .portfolio_masonry_item.large_width_height,
.full_width .projects_masonry_holder .qode-portfolio-masonry-gallery-grid-sizer.large_width,
.full_width .projects_masonry_holder .qode-portfolio-masonry-gallery-grid-sizer.large_width_height {
  width: 40%
}

.full_width .projects_masonry_holder.gs4 .portfolio_masonry_item,
.full_width .projects_masonry_holder.gs4 .qode-portfolio-masonry-gallery-grid-sizer {
  width: 25%
}

.full_width .projects_masonry_holder.gs4 .portfolio_masonry_item.large_width,
.full_width .projects_masonry_holder.gs4 .portfolio_masonry_item.large_width_height,
.full_width .projects_masonry_holder.gs4 .qode-portfolio-masonry-gallery-grid-sizer.large_width,
.full_width .projects_masonry_holder.gs4 .qode-portfolio-masonry-gallery-grid-sizer.large_width_height {
  width: 50%
}

.full_width .projects_masonry_holder.gs3 .portfolio_masonry_item,
.full_width .projects_masonry_holder.gs3 .qode-portfolio-masonry-gallery-grid-sizer {
  width: 33.33%
}

.full_width .projects_masonry_holder.gs3 .portfolio_masonry_item.large_width,
.full_width .projects_masonry_holder.gs3 .portfolio_masonry_item.large_width_height,
.full_width .projects_masonry_holder.gs3 .qode-portfolio-masonry-gallery-grid-sizer.large_width,
.full_width .projects_masonry_holder.gs3 .qode-portfolio-masonry-gallery-grid-sizer.large_width_height {
  width: 66.66%
}

.projects_masonry_holder .portfolio_masonry_item:after,
.projects_masonry_holder:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden
}

.projects_masonry_holder .image_holder img {
  vertical-align: top;
  width: 100%;
  height: auto
}

.projects_masonry_holder .text_holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100.1%;
  height: 100%;
  background-color: rgba(0, 0, 0, .8);
  text-align: center;
  transition: opacity .4s ease-in-out;
  -webkit-transition: opacity .4s ease-in-out;
  -moz-transition: opacity .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out;
  -ms-transition: opacity .4s ease-in-out;
  visibility: visible;
  overflow: hidden;
  z-index: 200;
  opacity: 0;
  filter: alpha(opacity=0)
}

.projects_masonry_holder .text_holder .text_outer {
  display: table;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  overflow: hidden
}

.projects_masonry_holder .text_holder .text_inner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0
}

.projects_masonry_holder article.portfolio_masonry_item:hover .text_holder {
  opacity: 1;
  filter: alpha(opacity=100)
}

.projects_masonry_holder .portfolio_title {
  margin: 0 0 7px
}

.projects_masonry_holder .portfolio_title a {
  color: #fff
}

.masonry_with_space_only_image .project_category,
.projects_masonry_holder .project_category {
  font-weight: 500
}

.masonry_with_space_only_image .separator,
.projects_masonry_holder .separator {
  background-color: #fff
}

.projects_masonry_holder .feature_holder_icons {
  margin: 20px 0 0;
  display: inline-block
}

.projects_masonry_holder .feature_holder {
  width: 100%;
  display: inline-block
}

.projects_masonry_holder a.lightbox {
  margin: 0 10px 0 0
}

.projects_masonry_holder .portfolio_like {
  margin: 0 0 0 10px
}

.projects_masonry_holder .portfolio_like a,
.projects_masonry_holder .portfolio_like:hover a {
  color: #fff
}

.projects_masonry_holder.portfolio_masonry_gallery_with_space {
  margin: 0 -15px
}

.projects_masonry_holder.portfolio_masonry_gallery_with_space .portfolio_masonry_item {
  box-sizing: border-box;
  padding: 15px
}

.projects_masonry_holder.portfolio_masonry_gallery_with_space .portfolio_masonry_item .image_holder {
  position: relative;
  width: 100%;
  height: 100%
}

.projects_masonry_holder.portfolio_masonry_gallery_with_space .portfolio_masonry_item .image_holder img {
  max-height: 100%
}

.projects_masonry_holder.portfolio_masonry_gallery_with_space .portfolio_masonry_item.large_height .image_holder {
  overflow: hidden
}

.projects_masonry_holder.portfolio_masonry_gallery_with_space .portfolio_masonry_item.large_height .image_holder img {
  height: 100%;
  max-width: inherit;
  width: auto
}

.masonry_with_space .projects_holder {
  position: relative;
  opacity: 0
}

.masonry_with_space .projects_holder .mix {
  display: block;
  padding: 0 7px;
  margin: 0 0 14px !important;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  visibility: visible
}

.masonry_with_space .projects_holder.v6 .mix,
.masonry_with_space .projects_holder.v6 .qode-portfolio-masonry-gallery-grid-sizer {
  width: 16.66%
}

.masonry_with_space .projects_holder.v5 .mix,
.masonry_with_space .projects_holder.v5 .qode-portfolio-masonry-gallery-grid-sizer {
  width: 20%
}

.masonry_with_space .projects_holder.v4 .mix,
.masonry_with_space .projects_holder.v4 .qode-portfolio-masonry-gallery-grid-sizer {
  width: 25%
}

.masonry_with_space .projects_holder.v3 .mix,
.masonry_with_space .projects_holder.v3 .qode-portfolio-masonry-gallery-grid-sizer {
  width: 33.33%
}

.masonry_with_space .projects_holder.v2 .mix,
.masonry_with_space .projects_holder.v2 .qode-portfolio-masonry-gallery-grid-sizer {
  width: 49.99%
}

.masonry_with_space .projects_holder.v1 .mix,
.masonry_with_space .projects_holder.v1 .qode-portfolio-masonry-gallery-grid-sizer {
  width: 100%
}

.projects_holder_outer:not(.masonry_with_space) .projects_holder.portfolio_one_by_one article {
  opacity: 0 !important;
  -moz-transition: opacity .4s ease-in-out;
  -webkit-transition: opacity .4s ease-in-out;
  transition: opacity .4s ease-in-out
}

.projects_holder_outer:not(.masonry_with_space) .projects_holder.portfolio_one_by_one article.show {
  opacity: 1 !important
}

.projects_holder_outer.masonry_with_space .projects_holder.portfolio_one_by_one article,
.projects_masonry_holder.portfolio_one_by_one article {
  opacity: 0;
  -moz-transition: opacity .4s ease-in-out;
  -webkit-transition: opacity .4s ease-in-out;
  transition: opacity .4s ease-in-out
}

.projects_holder_outer.masonry_with_space .projects_holder.portfolio_one_by_one article.show,
.projects_masonry_holder.portfolio_one_by_one article.show {
  opacity: 1
}

.projects_holder.slide_from_top article {
  overflow: hidden
}

.projects_holder.slide_from_top article .image_holder,
.projects_holder.slide_from_top article .portfolio_description {
  opacity: 0 !important;
  -webkit-transform: translateY(-70%);
  transform: translateY(-70%);
  -moz-transition: -moz-transform .4s cubic-bezier(.4, 0, .2, 1), opacity .4s cubic-bezier(.4, 0, .2, 1);
  -webkit-transition: -webkit-transform .4s cubic-bezier(.4, 0, .2, 1), opacity .4s cubic-bezier(.4, 0, .2, 1);
  transition: transform .4s cubic-bezier(.4, 0, .2, 1), opacity .4s cubic-bezier(.4, 0, .2, 1)
}

.projects_holder.slide_from_top article.show .image_holder,
.projects_holder.slide_from_top article.show .portfolio_description {
  opacity: 1 !important;
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.projects_holder.diagonal_fade article {
  opacity: 0 !important;
  -moz-transition: opacity .4s ease-in-out, -moz-transform .4s ease-in-out;
  -webkit-transition: opacity .4s ease-in-out, -webkit-transform .4s ease-in-out;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  transform: scale(.8)
}

.projects_holder.diagonal_fade article.show {
  opacity: 1 !important;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.projects_holder.slide_from_left article {
  overflow: hidden
}

.projects_holder.slide_from_left article .image_holder,
.projects_holder.slide_from_left article .portfolio_description {
  opacity: 0 !important;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -moz-transition: -moz-transform .3s cubic-bezier(.4, 0, .2, 1), opacity .3s cubic-bezier(.4, 0, .2, 1);
  -webkit-transition: -webkit-transform .3s cubic-bezier(.4, 0, .2, 1), opacity .3s cubic-bezier(.4, 0, .2, 1);
  transition: transform .3s cubic-bezier(.4, 0, .2, 1), opacity .3s cubic-bezier(.4, 0, .2, 1)
}

.projects_holder.slide_from_left article.show .image_holder,
.projects_holder.slide_from_left article.show .portfolio_description {
  opacity: 1 !important;
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.projects_holder_outer.justified_gallery article {
  opacity: 0;
  -ms-transition: opacity .8s ease;
  -webkit-transition: opacity .8s ease;
  transition: opacity .8s ease
}

.projects_holder_outer.justified_gallery article.show {
  opacity: 1
}

.masonry_with_space_only_image .hover_feature_holder_title_inner .portfolio_title,
.masonry_with_space_only_image .hover_feature_holder_title_inner .portfolio_title a,
.masonry_with_space_only_image .projects_holder article span.text_holder span span.text_inner .project_category {
  color: #fff
}

.projects_holder.standard article .item_holder.image_text_zoom_hover .project_category {
  margin-bottom: 10px
}

.masonry_with_space_only_image .feature_holder {
  margin-top: 30px
}

.projects_holder.alternating_sizes .mix {
  margin-bottom: 3%;
  box-shadow: 0 7px 20px rgba(0, 0, 0, .15)
}

.projects_holder.alternating_sizes .mix:nth-child(2n) {
  -webkit-transform: scale(.75);
  -moz-transform: scale(.75);
  transform: scale(.75)
}

.portfolio_main_holder .item_holder {
  position: relative
}

.portfolio_main_holder.justified-gallery .item_holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.projects_holder article a.portfolio_link_class,
.projects_masonry_holder article a.portfolio_link_class {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  left: 0
}

.portfolio_main_holder .item_holder .text_holder {
  position: absolute
}

.portfolio_main_holder .project_category {
  margin: 0;
  display: block;
  line-height: 1;
  font-size: 12px
}

.portfolio_main_holder .item_holder .text_holder_outer {
  display: table;
  width: 100%;
  height: 100%
}

.portfolio_main_holder .item_holder .text_holder_inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center
}

.portfolio_main_holder .item_holder .portfolio_shader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 21, 21, .78)
}

.portfolio_main_holder .item_holder .icons_holder {
  font-family: Raleway, sans-serif
}

.portfolio_main_holder .item_holder .icons_holder a {
  text-align: center
}

.portfolio_main_holder .item_holder .portfolio_title {
  line-height: 1.25em
}

.portfolio_slider_holder.standard li .project_category,
.projects_holder.standard article .project_category,
.projects_holder.standard_no_space article .project_category {
  margin: 10px 0 0
}

.portfolio_main_holder article .icons_holder a.portfolio_lightbox:before,
.portfolio_slides .icons_holder a.portfolio_lightbox:before {
  font-family: FontAwesome;
  content: "\f067";
  line-height: inherit
}

.portfolio_main_holder article .icons_holder a.qode-like:before,
.portfolio_slides .icons_holder a.qode-like:before {
  font-family: FontAwesome;
  content: "\f08a";
  line-height: inherit
}

.portfolio_main_holder article .icons_holder a.qode-like.liked:before,
.portfolio_slides .icons_holder a.qode-like.liked:before {
  font-family: FontAwesome;
  content: "\f004";
  line-height: inherit
}

.portfolio_main_holder article .icons_holder a.preview:before,
.portfolio_slides .icons_holder a.preview:before {
  content: "\f0c1";
  font-family: FontAwesome;
  line-height: inherit
}

.portfolio_main_holder .item_holder .portfolio_title a,
.portfolio_main_holder .item_holder .project_category {
  color: #fff
}

.portfolio_main_holder .item_holder .portfolio_title {
  margin-bottom: 15px
}

.projects_holder article .item_holder.subtle_vertical_hover a.portfolio_link_class,
.projects_masonry_holder article .item_holder.subtle_vertical_hover a.portfolio_link_class {
  z-index: 5
}

.portfolio_main_holder .item_holder.subtle_vertical_hover .image_holder {
  z-index: 2
}

.portfolio_main_holder .item_holder.subtle_vertical_hover .text_holder {
  font-size: 14px;
  width: 100%;
  position: absolute;
  left: 0;
  height: 100%;
  background-color: transparent;
  top: 0;
  text-align: center;
  box-sizing: border-box;
  z-index: 4;
  opacity: 0
}

.portfolio_main_holder article .item_holder.subtle_vertical_hover:hover .text_holder,
.portfolio_slider li.item:hover .item_holder.subtle_vertical_hover .text_holder {
  opacity: 1
}

.portfolio_main_holder .item_holder.subtle_vertical_hover .text_holder_inner {
  padding: 5px
}

.portfolio_main_holder .item_holder.subtle_vertical_hover .portfolio_title {
  text-transform: uppercase;
  opacity: 0;
  padding: 0 20px;
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateY(0);
  transition: all .3s ease-out;
  -webkit-transition: all .3s ease-out
}

.portfolio_main_holder article:hover .item_holder.subtle_vertical_hover .portfolio_title,
.portfolio_slider li.item:hover .item_holder.subtle_vertical_hover .portfolio_title {
  opacity: 1;
  text-shadow: none;
  -ms-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -o-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-transform: translateY(4px);
  transition: all .3s ease-out;
  -webkit-transition: all .3s ease-out
}

.portfolio_main_holder .item_holder.subtle_vertical_hover .text_holder .separator {
  display: inline-block;
  background-color: #fff;
  margin-top: 20px
}

.portfolio_main_holder .item_holder.subtle_vertical_hover .project_category {
  opacity: 0;
  -ms-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -o-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-transform: translateY(4px);
  transition: all .4s ease-out;
  -webkit-transition: all .4s ease-out;
  margin-bottom: 10px
}

.portfolio_main_holder article:hover .item_holder.subtle_vertical_hover .project_category,
.portfolio_slider li.item:hover .item_holder.subtle_vertical_hover .project_category {
  opacity: 1;
  text-shadow: none;
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateY(0)
}

.portfolio_main_holder .item_holder.subtle_vertical_hover .icons_holder {
  -ms-transform: translateY(4px);
  -moz-transform: translateY(4px);
  -o-transform: translateY(4px);
  transform: translateY(4px);
  -webkit-transform: translateY(4px);
  transition: all .4s ease-out;
  -webkit-transition: all .4s ease-out
}

.portfolio_main_holder article .item_holder.subtle_vertical_hover:hover .icons_holder,
.portfolio_slider li.item:hover .item_holder.subtle_vertical_hover .icons_holder {
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateY(0)
}

.portfolio_main_holder .item_holder.subtle_vertical_hover .icons_holder a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #1abc9c;
  border-radius: 100px;
  margin: 0 3px;
  color: #fff;
  transition: .2s ease-out;
  -webkit-transition: .2s ease-out
}

.portfolio_main_holder .item_holder.subtle_vertical_hover .portfolio_shader {
  z-index: 3;
  -webkit-transition: opacity .3s cubic-bezier(.785, .135, .15, .86);
  transition: opacity .3s cubic-bezier(.785, .135, .15, .86);
  opacity: 0
}

.portfolio_main_holder article .item_holder.subtle_vertical_hover:hover .portfolio_shader,
.portfolio_slider li.item:hover .item_holder.subtle_vertical_hover .portfolio_shader {
  opacity: 1
}

.portfolio_main_holder article .item_holder.image_subtle_rotate_zoom_hover a.portfolio_link_class {
  z-index: 5
}

.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .image_holder {
  z-index: 2;
  overflow: hidden
}

.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .text_holder {
  padding: 30px;
  font-size: 14px;
  width: 100%;
  position: absolute;
  left: 0;
  height: 100%;
  background-color: transparent;
  top: 0;
  box-sizing: border-box;
  z-index: 4;
  opacity: 1
}

.portfolio_with_hover_text .portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .separator {
  background-color: #fff;
  opacity: 0;
  -webkit-transition: opacity .4s ease-out;
  -moz-transition: opacity .4s ease-out;
  -ms-transition: opacity .4s ease-out;
  -o-transition: opacity .4s ease-out;
  transition: opacity .4s ease-out;
}

.portfolio_with_hover_text .portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover:hover .separator {
  opacity: 1;
}

.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .portfolio_title {
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  opacity: 0;
  -ms-transform: translateY(-3px);
  -moz-transform: translateY(-3px);
  -o-transform: translateY(-3px);
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
  transition: all .4s ease-out;
  -webkit-transition: all .4s ease-out;
}

.portfolio_main_holder article:hover .item_holder.image_subtle_rotate_zoom_hover .portfolio_title,
.portfolio_slider li.item:hover .item_holder.image_subtle_rotate_zoom_hover .portfolio_title {
  opacity: 1;
  text-shadow: none;
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateY(0)
}

.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .project_category {
  opacity: 0;
  text-shadow: 0 0 10px #fff;
  -ms-transform: translateY(3px);
  -moz-transform: translateY(3px);
  -o-transform: translateY(3px);
  transform: translateY(3px);
  -webkit-transform: translateY(3px);
  transition: all .4s ease-out;
  -webkit-transition: all .4s ease-out
}

.portfolio_main_holder article:hover .item_holder.image_subtle_rotate_zoom_hover .project_category {
  opacity: 1;
  text-shadow: none;
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateY(0)
}

.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .portfolio_shader {
  z-index: 3;
  -webkit-transition: opacity .3s cubic-bezier(.785, .135, .15, .86);
  transition: opacity .3s cubic-bezier(.785, .135, .15, .86);
  opacity: 0
}

.portfolio_main_holder article .item_holder.image_subtle_rotate_zoom_hover:hover .portfolio_shader,
.portfolio_slider li.item:hover .item_holder.image_subtle_rotate_zoom_hover .portfolio_shader {
  opacity: 1
}

.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .icons_holder {
  -ms-transform: translateY(3px);
  -moz-transform: translateY(3px);
  -o-transform: translateY(3px);
  transform: translateY(3px);
  -webkit-transform: translateY(3px);
  transition: transform .4s ease-out;
  -webkit-transition: transform .4s ease-out;
  opacity: 0
}

.portfolio_main_holder article .item_holder.image_subtle_rotate_zoom_hover:hover .icons_holder,
.portfolio_slider li.item:hover .item_holder.image_subtle_rotate_zoom_hover .icons_holder {
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateY(0);
  opacity: 1
}

.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .icons_holder a {
  display: inline-block;
  vertical-align: top;
  background-color: transparent;
  border: 1px solid #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  border-radius: 100px;
  margin: 0 3px;
  transition: .2s ease-out;
  -webkit-transition: .2s ease-out
}

.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .icons_holder a:hover {
  background-color: #1abc9c;
  color: #fff;
  border-color: #1abc9c
}

.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover .image_holder .image img,
.portfolio_main_holder .item_holder.image_subtle_rotate_zoom_hover+.image_holder.portfolio_jg_image_link img {
  -webkit-transition: -webkit-transform .7s;
  transition: transform .7s
}

.portfolio_main_holder article .item_holder.image_subtle_rotate_zoom_hover:hover .image_holder .image img,
.portfolio_main_holder article .item_holder.image_subtle_rotate_zoom_hover:hover+.image_holder.portfolio_jg_image_link img,
.portfolio_slider li.item:hover .item_holder.image_subtle_rotate_zoom_hover .image_holder .image img {
  -webkit-transform: rotate(-9deg) scale(1.15);
  -ms-transform: rotate(-9deg) scale(1.15);
  -moz-transform: rotate(-9deg) scale(1.15);
  -o-transform: rotate(-9deg) scale(1.15);
  transform: rotate(-9deg) scale(1.15);
  -webkit-transition: -webkit-transform .7s;
  transition: transform .7s;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden
}

.portfolio_main_holder article .item_holder.image_text_zoom_hover a.portfolio_link_class {
  z-index: 5
}

.portfolio_main_holder .item_holder.image_text_zoom_hover .image_holder {
  z-index: 2;
  overflow: hidden
}

.portfolio_main_holder .item_holder.image_text_zoom_hover .text_holder {
  padding: 30px;
  font-size: 14px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: transparent;
  top: 0;
  text-align: center;
  box-sizing: border-box;
  opacity: 0;
  -webkit-transition: .4s cubic-bezier(.165, .84, .44, 1);
  transition: .4s cubic-bezier(.165, .84, .44, 1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: scale(.8);
  -ms-transform: scale(.8);
  -moz-transform: scale(.8);
  -o-transform: scale(.8);
  transform: scale(.8);
  z-index: 4
}

.portfolio_main_holder article .item_holder.image_text_zoom_hover:hover .text_holder,
.portfolio_slider li.item:hover .item_holder.image_text_zoom_hover .text_holder {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}

.portfolio_main_holder .item_holder.image_text_zoom_hover .text_holder_outer {
  display: table;
  width: 100%;
  height: 100%
}

.portfolio_main_holder .item_holder.image_text_zoom_hover .text_holder_inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center
}

.portfolio_with_hover_text .portfolio_main_holder .item_holder.image_text_zoom_hover .separator {
  background-color: #fff
}

.portfolio_main_holder .project_category,
.projects_masonry_holder article .project_category {
  margin-bottom: 10px
}

.portfolio_main_holder .item_holder.image_text_zoom_hover .icons_holder a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #1abc9c;
  border-radius: 50%;
  margin: 0 2px;
  color: #fff;
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateY(0);
  transition: background-color .2s, transform .2s;
  -webkit-transition: background-color .2s, -webkit-transform .2s
}

.portfolio_main_holder .item_holder.image_text_zoom_hover .icons_holder a:hover {
  -ms-transform: translateY(-10%);
  -moz-transform: translateY(-10%);
  -o-transform: translateY(-10%);
  transform: translateY(-10%);
  -webkit-transform: translateY(-10%)
}

.portfolio_main_holder .item_holder.image_text_zoom_hover .portfolio_shader {
  z-index: 3;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity .4s cubic-bezier(.165, .84, .44, 1);
  transition: opacity .4s cubic-bezier(.165, .84, .44, 1);
  opacity: 0
}

.portfolio_main_holder article .item_holder.image_text_zoom_hover:hover .portfolio_shader,
.portfolio_slider li.item:hover .item_holder.image_text_zoom_hover .portfolio_shader {
  opacity: 1
}

.portfolio_main_holder .item_holder.image_text_zoom_hover .image_holder .image,
.portfolio_main_holder .item_holder.image_text_zoom_hover+.image_holder.portfolio_jg_image_link img {
  -webkit-transition: .5s cubic-bezier(.19, 1, .22, 1);
  transition: .5s cubic-bezier(.19, 1, .22, 1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  display: inline-block
}

.portfolio_main_holder article .item_holder.image_text_zoom_hover:hover .image_holder .image,
.portfolio_main_holder article .item_holder.image_text_zoom_hover:hover+.image_holder.portfolio_jg_image_link img,
.portfolio_slider li.item:hover .item_holder.image_text_zoom_hover .image_holder .image {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3)
}

.portfolio_main_holder.standard .item_holder.thin_plus_only {
  overflow: hidden
}

.portfolio_main_holder .item_holder.thin_plus_only .thin_plus_only_icon {
  font-weight: 100;
  font-family: Raleway;
  font-size: 100px;
  -webkit-transition: all .5s .3s;
  transition: all .5s .3s;
  display: block;
  color: #fff
}

.portfolio_main_holder .item_holder.thin_plus_only .text_holder {
  width: 100%;
  height: 100%;
  left: 0;
  margin: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 5;
  -ms-transform: translate(20%, 20%);
  -moz-transform: translate(20%, 20%);
  -o-transform: translate(20%, 20%);
  transform: translate(20%, 20%);
  -webkit-transform: translate(20%, 20%);
  -webkit-transition: all .5s .3s;
  transition: all .5s .3s;
  opacity: 0;
  font-size: 0;
  padding: 10%;
  background-color: transparent
}

.portfolio_main_holder article .item_holder.thin_plus_only:hover .text_holder,
.portfolio_slider li:hover .item_holder.thin_plus_only .text_holder {
  -ms-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -webkit-transition: all .2s .1s;
  transition: all .2s .1s;
  font-size: 150px;
  opacity: 1
}

.portfolio_main_holder .item_holder.thin_plus_only .image_holder {
  z-index: 2
}

.portfolio_main_holder .item_holder.thin_plus_only .portfolio_shader {
  z-index: 3;
  transition: opacity .5s;
  -webkit-transition: opacity .5s;
  opacity: 0
}

.portfolio_main_holder article .item_holder.thin_plus_only:hover .portfolio_shader,
.portfolio_slider li.item:hover .item_holder.thin_plus_only .portfolio_shader {
  opacity: 1
}

.portfolio_main_holder .item_holder.slow_zoom .text_holder {
  width: 100%;
  font-size: 18px;
  left: 0;
  height: 100%;
  margin: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 5;
  -webkit-transition: opacity .6s .3s;
  transition: opacity .6s .3s;
  opacity: 0;
  padding: 10%;
  background-color: transparent
}

.portfolio_with_hover_text .portfolio_main_holder article .item_holder.slow_zoom .separator,
.portfolio_with_hover_text .portfolio_main_holder article:hover .item_holder.split_up .separator {
  background-color: #fff
}

.portfolio_main_holder article .item_holder.slow_zoom:hover .text_holder {
  opacity: 1
}

.portfolio_main_holder .item_holder.slow_zoom .image_holder img,
.portfolio_main_holder .item_holder.slow_zoom+.image_holder.portfolio_jg_image_link img {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  -webkit-transition: all 2s cubic-bezier(.21, 1, .12, 1) 0s;
  transition: all 2s cubic-bezier(.23, 1, .12, 1) 0s
}

.portfolio_main_holder article .item_holder.slow_zoom:hover .image_holder img,
.portfolio_main_holder article .item_holder.slow_zoom:hover+.image_holder.portfolio_jg_image_link img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 5s cubic-bezier(.21, 1, .12, 1) 0s;
  transition: all 5s cubic-bezier(.21, 1, .12, 1) 0s
}

.portfolio_main_holder .item_holder.slow_zoom .portfolio_shader {
  z-index: 3;
  opacity: 0;
  -webkit-transition: all 5s cubic-bezier(.21, 1, .12, 1) 0s;
  transition: all 5s cubic-bezier(.21, 1, .12, 1) 0s
}

.portfolio_main_holder article .item_holder.slow_zoom:hover .portfolio_shader,
.portfolio_slider li.item:hover .item_holder.slow_zoom .portfolio_shader {
  opacity: 1
}

.portfolio_main_holder .item_holder.slow_zoom .icons_holder {
  display: flex;
  position: absolute;
  bottom: 0;
  opacity: 0;
  z-index: 20;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, transform .3s
}

.portfolio_main_holder .item_holder.slow_zoom .icons_holder.left {
  left: 0;
  -webkit-transform: translate3d(-10px, -10px, 0);
  -ms-transform: translate3d(-10px, 10px, 0);
  -moz-transform: translate3d(-10px, 10px, 0);
  -o-transform: translate3d(-10px, 10px, 0);
  transform: translate3d(-10px, 10px, 0)
}

.portfolio_main_holder .item_holder.slow_zoom .icons_holder.right {
  right: 0;
  -webkit-transform: translate3d(10px, 10px, 0);
  -ms-transform: translate3d(10px, 10px, 0);
  -moz-transform: translate3d(10px, 10px, 0);
  -o-transform: translate3d(10px, 10px, 0);
  transform: translate3d(10px, 10px, 0)
}

.portfolio_main_holder .item_holder.slow_zoom .icons_holder.center {
  width: 100%;
  text-align: center;
  -webkit-transform: translate3d(0, 10px, 0);
  -ms-transform: translate3d(0, 10px, 0);
  -moz-transform: translate3d(0, 10px, 0);
  -o-transform: translate3d(0, 10px, 0);
  transform: translate3d(0, 10px, 0)
}

.portfolio_main_holder article:hover .item_holder.slow_zoom .icons_holder,
.portfolio_slider li.item:hover .item_holder.slow_zoom .icons_holder {
  opacity: 1;
  display: flex;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.portfolio_main_holder .item_holder.slow_zoom .icons_holder a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  font-size: 15px;
  color: #fff;
  background: #1abc9c;
  transition: all .4s;
  -webkit-transition: all .4s
}

.portfolio_main_holder .item_holder.slow_zoom .icons_holder a:hover {
  background: #fff;
  color: #000
}

.portfolio_main_holder .item_holder.slow_zoom .image_holder {
  z-index: 2;
  overflow: hidden
}

.portfolio_main_holder .item_holder.split_up .text_holder {
  width: 100%;
  font-size: 18px;
  left: 0;
  height: 100%;
  margin: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 5;
  -webkit-transition: opacity .4s cubic-bezier(.165, .84, .44, 1);
  transition: opacity .4s cubic-bezier(.165, .84, .44, 1);
  opacity: 0;
  padding: 10%;
  background-color: transparent
}

.portfolio_main_holder article .item_holder.split_up:hover .text_holder {
  -webkit-transition: transform .3s ease;
  transition: transform .3s ease;
  opacity: 1
}

.portfolio_main_holder .item_holder.split_up .portfolio_title {
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateY(0);
  transition: .2s;
  -webkit-transition: .2s
}

.portfolio_main_holder article .item_holder.split_up:hover .portfolio_title {
  -ms-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  transform: translateY(-4px);
  -webkit-transform: translateY(-4px)
}

.portfolio_main_holder .item_holder.split_up .project_category {
  -ms-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  transform: translateY(-4px);
  -webkit-transform: translateY(-4px);
  transition: .2s;
  -webkit-transition: .2s
}

.portfolio_main_holder article:hover .item_holder.split_up .project_category {
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translateY(0)
}

.portfolio_main_holder .item_holder.split_up .image_holder {
  z-index: 2
}

.portfolio_main_holder .item_holder.split_up .portfolio_shader,
.portfolio_slider li.item .item_holder.split_up .portfolio_shader {
  height: 0;
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;
  width: 100%;
  z-index: 3
}

.portfolio_main_holder article .item_holder.split_up:hover .portfolio_shader,
.portfolio_slider li.item:hover .item_holder.split_up .portfolio_shader {
  height: 100%;
  top: 0;
  opacity: 1
}

.portfolio_main_holder .item_holder.cursor_change_hover a.portfolio_link_class {
  z-index: 6;
  /* cursor: url(img/cursor_plus.cur),crosshair;
  cursor: url(img/cursor_plus.png) 22 22,crosshair */
}

.portfolio_main_holder .cursor_img {
  position: absolute;
  z-index: 1000
}

.portfolio_main_holder .item_holder.cursor_change_hover .image_holder {
  z-index: 2
}

.portfolio_main_holder .item_holder.cursor_change_hover .text_holder {
  padding: 30px;
  font-size: 14px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: transparent;
  top: 0;
  text-align: center;
  box-sizing: border-box;
  opacity: 0;
  -webkit-transition: .4s cubic-bezier(.165, .84, .44, 1);
  transition: .4s cubic-bezier(.165, .84, .44, 1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: scale(.8);
  -ms-transform: scale(.8);
  -moz-transform: scale(.8);
  -o-transform: scale(.8);
  transform: scale(.8);
  z-index: 4
}

.portfolio_main_holder article:hover .item_holder.cursor_change_hover .text_holder,
.portfolio_slider li.item:hover .item_holder.cursor_change_hover .text_holder {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}

.portfolio_with_hover_text .portfolio_main_holder .item_holder.cursor_change_hover .separator {
  background-color: #fff
}

.portfolio_main_holder .item_holder.cursor_change_hover .text_holder_outer {
  display: table;
  width: 100%;
  height: 100%
}

.portfolio_main_holder .item_holder.cursor_change_hover .text_holder_inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center
}

.portfolio_main_holder .item_holder.flip_from_left .portfolio_shader,
.portfolio_main_holder .item_holder.slide_up .portfolio_shader {
  display: none
}

.portfolio_main_holder .item_holder.cursor_change_hover .portfolio_shader {
  z-index: 3;
  -webkit-transition: opacity .4s cubic-bezier(.165, .84, .44, 1);
  transition: opacity .4s cubic-bezier(.165, .84, .44, 1);
  opacity: 0
}

.portfolio_main_holder article .item_holder.cursor_change_hover:hover .portfolio_shader,
.portfolio_slider li.item:hover .item_holder.cursor_change_hover .portfolio_shader {
  opacity: 1
}

.projects_holder.hover_text article .item_holder.grayscale .image_holder {
  z-index: 2;
  margin: 0
}

.projects_holder.hover_text article .item_holder.grayscale .image img {
  margin: 0;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  -webkit-filter: grayscale(1);
  -webkit-filter: grayscale(100%);
  filter: gray;
  filter: grayscale(100%);
  /* filter: url(img/desaturate.svg#grayscale) */
}

.projects_holder.hover_text article .item_holder.grayscale:hover .image_holder .image img {
  -webkit-filter: none;
  filter: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none
}

.projects_holder.hover_text article .item_holder.grayscale .text_holder {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 5;
  opacity: 0;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  transition: opacity .5s
}

.projects_holder.hover_text article .item_holder.grayscale .portfolio_shader {
  z-index: 3;
  background-color: rgba(21, 21, 21, .4);
  opacity: 0;
  -webkit-transition: opacity .5s;
  -moz-transition: opacity .5s;
  transition: opacity .5s
}

.projects_holder.hover_text article .item_holder.grayscale:hover .portfolio_shader,
.projects_holder.hover_text article .item_holder.grayscale:hover .text_holder {
  opacity: 1
}

.portfolio_main_holder .item_holder.slide_up {
  overflow: hidden
}

.portfolio_main_holder .item_holder.slide_up .portfolio_title_holder {
  z-index: 10;
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform .3s cubic-bezier(.33, 0, .2, 1) 50ms;
  transition: transform .3s cubic-bezier(.33, 0, .2, 1) 50ms;
  width: 100%
}

.portfolio_main_holder .item_holder.slide_up .portfolio_title_holder .portfolio_title {
  text-align: center;
  margin: 25px 0;
  padding: 0 10px;
  box-sizing: border-box
}

.portfolio_main_holder .item_holder.slide_up .portfolio_title_holder .portfolio_title a {
  color: #303030
}

.portfolio_main_holder .item_holder.slide_up .image_holder {
  z-index: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform .3s cubic-bezier(.33, 0, .2, 1) 50ms;
  transition: transform .3s cubic-bezier(.33, 0, .2, 1) 50ms
}

.portfolio_main_holder .item_holder.slide_up:hover .portfolio_title_holder {
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.portfolio_main_holder .item_holder.slide_up:hover .image_holder {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px)
}

.portfolio_main_holder .item_holder.slide_up:hover .image_holder img {
  -webkit-transform: scale(1);
  transform: scale(1)
}

.portfolio_main_holder.justified-gallery article .portfolio_jg_image_link.slide_up {
  overflow: hidden
}

.portfolio_main_holder.justified-gallery article .portfolio_jg_image_link.slide_up img {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform .3s cubic-bezier(.33, 0, .2, 1) 50ms;
  transition: transform .3s cubic-bezier(.33, 0, .2, 1) 50ms
}

.portfolio_main_holder.justified-gallery article:hover .portfolio_jg_image_link.slide_up img {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px)
}

.portfolio_main_holder .item_holder.flip_from_left .portfolio_title_holder {
  z-index: 10;
  background-color: #fff;
  bottom: 5%;
  left: -4%;
  position: absolute;
  opacity: 0;
  -webkit-transform: translate3d(-20%, 0, 0);
  -moz-transform: translate3d(-20%, 0, 0);
  transform: translate3d(-20%, 0, 0);
  -webkit-transition: opacity .2s, transform 0s .2s, box-shadow 0s ease .2s;
  transition: opacity .2s, transform 0s .2s, box-shadow 0s ease .2s;
  will-change: transform
}

.portfolio_main_holder .item_holder.flip_from_left .portfolio_title_holder .portfolio_title {
  margin: 15px 0;
  padding: 0 20px;
  box-sizing: border-box
}

.portfolio_main_holder .item_holder.flip_from_left .portfolio_title_holder .portfolio_title a {
  color: #222;
  vertical-align: middle
}

.portfolio_main_holder .item_holder.flip_from_left:hover .portfolio_title_holder {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-box-shadow: 6px 6px 3px rgba(0, 0, 0, .04);
  -moz-box-shadow: 6px 6px 3px rgba(0, 0, 0, .04);
  box-shadow: 6px 6px 3px rgba(0, 0, 0, .04);
  -webkit-transition: opacity .25s cubic-bezier(.33, 0, .2, 1), -webkit-transform .3s cubic-bezier(0, 0, 0, 1), -webkit-box-shadow .2s ease-out 50ms;
  transition: opacity .25s cubic-bezier(.33, 0, .2, 1), transform .3s cubic-bezier(0, 0, 0, 1), box-shadow .2s ease-out 50ms
}

.projects_holder.monitor_frame img.monitor_frame {
  pointer-events: none;
  position: relative;
  z-index: 3
}

.projects_holder.monitor_frame .item_holder {
  height: 67%;
  left: 3.6%;
  position: absolute;
  top: 5%;
  width: 93%;
  z-index: 1
}

.projects_holder.monitor_frame .item_holder .image_holder {
  height: 100%
}

.projects_holder.monitor_frame .item_holder .image_holder img {
  -webkit-transform: translateZ(0) scale(1) !important;
  transform: translateZ(0) scale(1) !important
}

.projects_holder.monitor_frame.ie-specific-styles .item_holder {
  z-index: 4
}

.q_tabs {
  display: block;
  visibility: hidden
}

.q_tabs .tabs-nav {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  display: inline-block;
  position: relative
}

.q_tabs .tabs-nav li {
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  display: inline-block;
  float: left
}

.q_tabs .tabs-nav li a {
  height: 40px;
  line-height: 40px;
  display: block;
  margin: 0;
  padding: 0 14px;
  font-size: 13px;
  text-decoration: none;
  font-style: normal;
  color: #b4b4b4;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.q_tabs .tabs-nav li.active a {
  color: #303030
}

.q_tabs .tabs-nav li a:hover,
.q_tabs .tabs-nav li.active a:hover {
  color: #1abc9c
}

.q_tabs.horizontal.center {
  text-align: center
}

.q_tabs.horizontal.left {
  text-align: left
}

.q_tabs.horizontal.right {
  text-align: right
}

.q_tabs.horizontal .tabs-container {
  padding-top: 4px;
  text-align: left
}

.q_tabs.horizontal.right .tabs-container {
  text-align: right
}

.q_tabs.horizontal .tabs-nav li:first-child a {
  padding-left: 0
}

.q_tabs.vertical .tabs-nav {
  width: 24.02957486136784%;
  margin: 0
}

.q_tabs.vertical.right .tabs-nav {
  float: right
}

.q_tabs.vertical.left .tabs-nav {
  float: left
}

.q_tabs.vertical .tabs-nav li {
  display: block;
  width: 100%
}

.q_tabs.vertical .tabs-nav li a {
  text-align: center;
  height: auto;
  line-height: normal;
  padding: 13px 17px
}

.q_tabs.vertical .tabs-nav li.active a {
  border: 2px solid #e3e3e3;
  padding: 11px 14px;
  position: relative;
  z-index: 100
}

.q_tabs.vertical.left .tabs-nav li.active a {
  border-right-color: #fff;
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px
}

.q_tabs.vertical.right .tabs-nav li.active a {
  border-left-color: #fff;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px
}

.q_tabs.vertical .tabs-container {
  width: 73.68576709796673%
}

.q_tabs.vertical.left .tabs-container {
  float: left;
  text-align: left
}

.q_tabs.vertical.right .tabs-container {
  float: right;
  text-align: right
}

.q_tabs.vertical .tab-content {
  position: relative;
  z-index: 50
}

.q_tabs.vertical.left .tab-content {
  padding-left: 17px;
  border-left: 2px solid #e3e3e3;
  left: -2px
}

.q_tabs.vertical.right .tab-content {
  padding-right: 17px;
  border-right: 2px solid #e3e3e3;
  right: -2px
}

.q_tabs.boxed {
  text-align: left
}

.q_tabs.boxed .tabs-nav {
  display: block
}

.q_tabs.boxed .tabs-nav li a {
  border-bottom: none;
  position: relative;
  z-index: 200
}

.q_tabs.boxed .tabs-nav li.active a {
  position: relative;
  z-index: 100;
  line-height: 40px;
  height: 40px;
  padding: 0 12px;
  border: 2px solid #e3e3e3;
  border-bottom-color: #fff;
  -webkit-border-top-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  border-top-right-radius: 3px;
}

.q_tabs.boxed .tabs-container {
  overflow: hidden;
  text-align: left;
  padding: 19px 0 0;
  border-top: 2px solid #e3e3e3;
  position: relative;
  top: -2px;
  z-index: 50;
}

.q_accordion_holder {
  position: relative;
  display: block;
  margin: 0 0 30px;
}

.q_accordion_holder.accordion.with_icon {
  border-top: 1px solid #eaeaea;
}

.q_accordion_holder.accordion .ui-accordion-header {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 2em;
  min-height: 45px;
  padding: 0;
  margin: 0 0 5px;
  transform: translateZ(0);
  -moz-transform: translateZ(0);
  box-sizing: border-box
}

.q_accordion_holder.accordion h3.ui-accordion-header {
  padding-top: 3px
}

.q_accordion_holder.accordion h4.ui-accordion-header {
  padding-top: 5px
}

.q_accordion_holder.accordion h5.ui-accordion-header {
  font-size: 14px;
  padding-top: 9px
}

.q_accordion_holder.accordion h6.ui-accordion-header {
  padding-top: 10px
}

.q_accordion_holder.accordion.with_icon h3.ui-accordion-header {
  padding-bottom: 3px
}

.q_accordion_holder.accordion.with_icon h4.ui-accordion-header {
  padding-bottom: 5px
}

.q_accordion_holder.accordion.with_icon h5.ui-accordion-header {
  padding-bottom: 9px
}

.q_accordion_holder.accordion.with_icon h6.ui-accordion-header {
  padding-bottom: 10px
}

.q_accordion_holder.accordion.with_icon .ui-accordion-header {
  line-height: 3em;
  min-height: 3em;
  margin: 0;
  border: 1px solid #eaeaea;
  border-top: 0 !important
}

.q_accordion_holder.accordion .ui-accordion-header,
.q_accordion_holder.accordion.with_icon .ui-accordion-header {
  -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  -o-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  transition: color .3s ease-in-out, background-color .3s ease-in-out;
  color: #303030;
  font-weight: 600;
  text-transform: uppercase;
}

.q_accordion_holder.accordion .ui-accordion-header:hover {
  color: #1abc9c
}

.q_accordion_holder.accordion.with_icon .ui-accordion-header:last-child {
  border-bottom: 0 !important
}

.q_accordion_holder.accordion.with_icon .ui-accordion-header span.tab-title {
  width: 72%;
  line-height: 1.5625em;
  margin: .85em 0 .85em 20px;
  display: inline-block
}

.q_accordion_holder.accordion.without_icon .ui-accordion-header span.tab-title {
  padding: 0 0 0 63px;
  display: block
}

.q_accordion_holder.accordion.with_icon .ui-state-active {
  border-bottom-color: transparent
}

.q_accordion_holder.accordion .ui-state-active,
.q_accordion_holder.accordion.with_icon .ui-state-active {
  -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  -o-transition: color .3s ease-in-out, background-color .3s ease-in-out;
  transition: color .3s ease-in-out, background-color .3s ease-in-out
}

.q_accordion_holder.accordion.with_icon .ui-accordion-header-active,
.q_accordion_holder.with_icon div.accordion_content {
  background-color: #fbfbfb
}

.q_accordion_holder .ui-accordion-header .accordion_mark,
.q_accordion_holder.accordion.with_icon .ui-accordion-header div.icon-wrapper {
  float: left;
  line-height: 3.25em;
  position: relative;
  top: .09375em
}

.q_accordion_holder.accordion.without_icon .ui-accordion-header div.icon-wrapper {
  display: none !important
}

.q_accordion_holder.accordion.with_icon .ui-accordion-header i {
  margin: 0 0 0 15px;
  font-size: 18px;
  color: #1abc9c
}

.highlight,
.testimonials_holder.light .flex-direction-nav a:before {
  color: #fff
}

.q_accordion_holder.accordion .ui-accordion-header .accordion_mark_icon {
  display: block;
  width: 41px;
  height: 41px;
  /* background-image: url(img/plus.png); */
  background-repeat: no-repeat;
  background-position: center
}

.q_accordion_holder.with_icon .ui-accordion-header.ui-state-active .accordion_icon_mark {
  /* background-image: url(img/minus.png) */
}

.q_accordion_holder.accordion .ui-accordion-header .accordion_mark {
  display: block;
  width: 45px;
  height: 45px;
  border: 2px solid #e3e3e3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: transparent;
  transition: background-color .3s ease-in-out;
  box-sizing: border-box
}

.q_accordion_holder.accordion .ui-accordion-header .accordion_mark:hover {
  /* background-image: url(img/button-bg-px.png)!important */
}

.q_accordion_holder.accordion .ui-accordion-header .accordion_mark.left_mark {
  position: absolute;
  top: 0;
  left: 0
}

.q_accordion_holder.accordion .ui-accordion-header .accordion_mark.right_mark {
  position: absolute;
  right: .65em;
  top: .65em
}

.q_accordion_holder.accordion.with_icon .ui-accordion-header .accordion_mark.left_mark,
.q_accordion_holder.accordion.without_icon .ui-accordion-header .accordion_mark.right_mark {
  display: none
}

.q_accordion_holder.accordion .ui-accordion-header.ui-state-active .accordion_mark_icon {
  /* background-image: url(img/minus.png) */
}

.q_accordion_holder.accordion .ui-accordion-header.ui-state-active .accordion_mark {
  background-color: #e3e3e3;
  transition: background-color .3s ease-in-out
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio:1.5),
only screen and (-o-min-device-pixel-ratio:150 / 100),
only screen and (min-device-pixel-ratio:1.5),
only screen and (min-resolution:160dpi) {

  .q_accordion_holder.accordion .ui-accordion-header .accordion_mark_icon,
  .q_accordion_holder.with_icon .accordion_icon_mark {
    -o-background-size: 9px 9px;
    -webkit-background-size: 9px 9px;
    -moz-background-size: 9px 9px;
    background-size: 9px 9px;
    /* background-image: url(img/plus@1_5x.png) */
  }

  .q_accordion_holder.accordion .ui-accordion-header.ui-state-active .accordion_mark_icon,
  .q_accordion_holder.with_icon .ui-accordion-header.ui-state-active .accordion_icon_mark {
    -o-background-size: 9px 9px;
    -webkit-background-size: 9px 9px;
    -moz-background-size: 9px 9px;
    background-size: 9px 9px;
    /* background-image: url(img/minus@1_5x.png) */
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:200 / 100),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:210dpi) {

  .q_accordion_holder.accordion .ui-accordion-header .accordion_mark_icon,
  .q_accordion_holder.with_icon .accordion_icon_mark {
    -o-background-size: 9px 9px;
    -webkit-background-size: 9px 9px;
    -moz-background-size: 9px 9px;
    background-size: 9px 9px;
    /* background-image: url(img/plus@2x.png) */
  }

  .q_accordion_holder.accordion .ui-accordion-header.ui-state-active .accordion_mark_icon,
  .q_accordion_holder.with_icon .ui-accordion-header.ui-state-active .accordion_icon_mark {
    -o-background-size: 9px 9px;
    -webkit-background-size: 9px 9px;
    -moz-background-size: 9px 9px;
    background-size: 9px 9px;
    /* background-image: url(img/minus@2x.png) */
  }
}

.q_accordion_holder.accordion div.accordion_content {
  padding: 0 0 0 63px;
  margin: 0
}

.q_accordion_holder.with_icon div.accordion_content {
  padding: 0 0 0 50px;
  border: 1px solid #eaeaea;
  border-top: 0
}

.q_accordion_holder.accordion.with_icon div.accordion_content.no_icon {
  padding: 0 0 0 22px
}

.q_accordion_holder.accordion div.accordion_content_inner {
  padding: 8px 0 25px;
  display: block
}

.q_accordion_holder.with_icon div.accordion_content_inner {
  padding: 10px 27px 19px 0;
}

.q_accordion_holder.accordion.boxed .ui-accordion-header {
  text-align: center;
  background-color: #e3e3e3;
}

.q_accordion_holder.boxed .ui-accordion-header .accordion_mark {
  display: none;
}

.q_accordion_holder.boxed div.accordion_content {
  padding-left: 26px;
  display: none;
}

.q_accordion_holder.boxed div.accordion_content_inner {
  padding-top: 20px;
}

.highlight {
  background-color: #1abc9c;
  padding: 0 3px;
}

.testimonials_holder {
  text-align: center;
}

.testimonials {
  position: relative;
}

.testimonials .testimonial_container {
  overflow: hidden;
  width: 100%;
  background-color: transparent;
  text-align: left;
  margin: 0 0 16px
}

.testimonials_holder .flex-direction-nav {
  display: block;
  position: static
}

.testimonials_holder .flex-direction-nav li {
  display: inline-block;
  height: 30px;
  margin-top: 37px
}

.testimonials_holder .flex-direction-nav li:first-child {
  margin-right: 5px
}

.testimonials_holder .flex-direction-nav li:last-child {
  margin-left: 4px
}

.testimonials_holder .flex-direction-nav a {
  position: relative;
  width: 30px;
  height: 30px;
  border: 2px solid #303030;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  opacity: 1;
  background-color: transparent;
  text-indent: -9999px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box
}

.testimonials_holder .flex-direction-nav a:hover {
  background-color: #303030
}

.testimonials_holder.light .flex-direction-nav a {
  border-color: #fff
}

.testimonials_holder.light .flex-direction-nav a:hover {
  background-color: #1abc9c;
  border-color: #1abc9c
}

.testimonials_holder .flex-direction-nav a:before {
  font-family: FontAwesome, sans-serif;
  position: absolute;
  height: 26px;
  width: 26px;
  line-height: 26px;
  top: 0;
  left: 0;
  color: #000;
  text-indent: 0;
  font-size: 16px
}

.testimonials_holder .flex-direction-nav a:hover:before {
  color: #fff
}

.testimonials_holder .flex-direction-nav a.flex-prev:before {
  content: '\f104'
}

.testimonials_holder .flex-direction-nav a.flex-next:before {
  content: '\f105'
}

.testimonials_holder.full_width {
  background: 0 0
}

.content .testimonial_content .container .container_inner {
  padding: 0
}

.testimonial_text_holder {
  position: relative;
  display: block
}

.testimonials .author_image_holder {
  margin-left: 15px;
  position: relative
}

.author_image_holder .image_holder {
  border-radius: 3em;
  border: 1px solid #eaeaea;
  width: 60px;
  height: 60px;
  display: inline-block;
  float: left;
  margin: 0 15px 0 0;
  overflow: hidden
}

.testimonials .testimonial_text_inner p.testimonial_author {
  font-size: 13px;
  color: #303030;
  margin-top: 22px;
  font-weight: 500
}

.testimonials .testimonial_text_inner p.testimonial_author span.author_company {
  color: #1abc9c
}

.testimonial_content_inner .testimonial_author .website {
  color: #303030;
  font-weight: 300;
  display: block
}

.testimonial_content_inner .testimonial_author h4 {
  color: #00a8e8
}

.testimonial_text_inner .testimonial_name {
  display: block
}

.testimonial_content_inner .testimonial_author .company_position {
  color: #1abc9c
}

.testimonials .testimonial_text_inner {
  display: block
}

.testimonial_arrow,
.testimonial_image_holder {
  display: inline-block;
  position: relative;
  overflow: hidden
}

.testimonials .testimonial_text_inner p {
  line-height: 1.666666666666667em;
  font-size: 21px
}

.testimonials_holder.standard .testimonials .testimonial_text_inner {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px
}

.testimonials_holder.full_width .testimonials .testimonial_text_inner {
  border-left: none;
  border-right: none;
  position: relative;
  text-align: center
}

.testimonials_holder.full_width .testimonials .testimonial_text_inner p {
  margin: 0 0 19px
}

.testimonial_arrow {
  width: 20px;
  height: 20px;
  margin: 0 0 0 -10px;
  z-index: 999;
  left: 45px;
  bottom: 11px;
  background-color: #fbfbfb;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #eaeaea;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg)
}

.testimonials.transparent .testimonial_text_inner {
  background-color: transparent !important;
  padding: 0;
  border: none
}

.testimonial_image_holder {
  text-align: center;
  vertical-align: middle;
  margin: 0 0 50px
}

.testimonial_image_holder img {
  vertical-align: middle
}

.testimonials_masonry_holder {
  padding: 0 17px
}

.testimonials_masonry_holder .testimonial_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box
}

.testimonials_masonry_holder .testimonials_sep {
  width: 17px;
  height: 2px;
  background-color: #303030;
  margin: 8px auto 13px
}

.testimonials_masonry_holder .testimonial_author {
  margin-top: 17px
}

.testimonials_masonry_holder .testimonial_content_holder {
  display: table;
  width: 100%;
  height: 100%
}

.testimonials_masonry_holder .testimonial_content_inner {
  display: table-cell;
  vertical-align: middle
}

.testimonials_masonry_holder .testimonials_header_desc {
  color: #303030
}

.testimonials_block {
  width: 33.33%;
  display: inline-block;
  vertical-align: middle
}

.tstm_block_2 {
  float: left
}

.tstm_block_2 .testimonials_item {
  clear: both
}

.tstm_block_2 .tstm_item_large {
  float: none
}

.testimonials_item {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  float: left;
  width: calc(50% - 34px);
  padding-top: calc(50% - 34px);
  margin: 17px;
  background-color: #fff;
  text-align: center;
  overflow: hidden
}

.testimonials_item .testimonial_content {
  padding: 25px
}

.testimonials_item .testimonial_image_holder {
  width: 49px;
  height: 49px;
  margin-bottom: 20px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%)
}

.tstm_item_large {
  padding-top: calc(100% - 34px)
}

.tstm_item_large .testimonial_image_holder {
  width: 90px;
  height: 90px;
  margin-bottom: 30px
}

.tstm_item_large .testimonials_sep {
  margin-bottom: 23px
}

.testimonials_header {
  width: calc(100% - 34px)
}

.testimonials_header .qbutton {
  margin-top: 38px
}

.boxed .section .section_inner .testimonials_masonry_holder,
.container .container_inner .testimonials_masonry_holder,
.grid_section .section_inner .testimonials_masonry_holder {
  padding: 0;
  margin: 0 -6px
}

.boxed .section .section_inner .testimonials_masonry_holder .testimonials_item,
.container .container_inner .testimonials_masonry_holder .testimonials_item,
.grid_section .section_inner .testimonials_masonry_holder .testimonials_item {
  margin: 6px;
  width: calc(50% - 12px);
  padding-top: calc(50% - 12px)
}

.boxed .section .section_inner .testimonials_masonry_holder .testimonials_item .testimonial_content,
.container .container_inner .testimonials_masonry_holder .testimonials_item .testimonial_content,
.grid_section .section_inner .testimonials_masonry_holder .testimonials_item .testimonial_content {
  padding: 15px
}

.boxed .section .section_inner .testimonials_masonry_holder .testimonials_header,
.container .container_inner .testimonials_masonry_holder .testimonials_header,
.grid_section .section_inner .testimonials_masonry_holder .testimonials_header {
  width: calc(100% - 12px)
}

.boxed .section .section_inner .testimonials_masonry_holder .tstm_item_large,
.container .container_inner .testimonials_masonry_holder .tstm_item_large,
.grid_section .section_inner .testimonials_masonry_holder .tstm_item_large {
  padding-top: calc(100% - 12px)
}

.testimonials_c_holder .testimonial_content_inner {
  background: #fff;
  box-sizing: border-box;
  padding: 60px 45px;
  text-align: center
}

.testimonials_c_holder .testimonial_content_inner .testimonial_image_holder {
  border-radius: 50%;
  margin-bottom: 33px
}

.testimonials_c_holder .testimonial_content_inner .testimonial_rating_holder {
  line-height: 1em;
  margin: 15px 0 25px;
  color: #bebebe
}

.testimonials_c_holder .testimonial_content_inner .testimonial_rating_holder .testimonial_star_holder {
  margin: 0 1px
}

.testimonials_c_holder .testimonial_content_inner .testimonial_author {
  margin-top: 21px;
  font-size: 13px;
  font-weight: 500
}

.testimonials_c_holder .flex-control-nav {
  margin-top: 50px;
  text-align: center
}

.testimonials_c_holder .flex-control-nav li {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin: 0 5px
}

.testimonials_c_holder .flex-control-nav li a {
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  background-color: #cfcfcf;
  border-radius: 50%
}

.testimonials_c_holder .flex-control-nav li a.flex-active {
  background-color: #303030
}

.testimonials_c_holder.light .flex-control-nav li a {
  background-color: #e8e8e8
}

.testimonials_c_holder.light .flex-control-nav li a.flex-active {
  background-color: #fff
}

blockquote {
  quotes: none;
  position: relative;
  padding: 14px 14px 14px 10px;
  border-left: 4px solid transparent
}

blockquote h5 {
  line-height: 1.5625em;
  text-transform: none;
  font-size: 21px;
  font-weight: 400;
  margin: 0 !important
}

blockquote.with_quote_icon h5 {
  padding: 0 0 0 40px
}

blockquote i.fa {
  float: left;
  font-size: 22px;
  color: #c1c0c0;
  margin: 6px 0 0 !important
}

.gallery_holder {
  display: block;
  position: relative
}

.wpb_image_grid .gallery_holder {
  width: 100.1%
}

.gallery_holder ul {
  list-style: none;
  display: block;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0
}

.gallery_holder ul li {
  position: relative;
  display: block;
  float: left;
  overflow: hidden
}

.gallery_holder ul li a {
  position: relative;
  backface-visibility: hidden;
  display: block;
  overflow: hidden;
  transform: translateZ(0);
  -moz-transform: translateZ(0)
}

.wpb_image_grid .gallery_holder ul li a {
  margin-right: -1px;
  margin-bottom: -1px !important
}

.gallery_holder ul li img {
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.gallery_holder ul li a img,
.q_font_awsome_icon_square,
.q_icon_with_title.boxed .icon_holder .fa-stack {
  transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out
}

.gallery_holder ul li a img {
  display: block;
  width: 100%;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1)
}

.gallery_holder ul li.grayscale a img,
.gallery_holder ul li.grayscale img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%)
}

.gallery_holder ul li.grayscale:hover a img,
.gallery_holder ul li.grayscale:hover img {
  -webkit-filter: grayscale(0);
  filter: none
}

.gallery_holder ul li:hover a img {
  -webkit-transform: scale(1.03, 1.03);
  -moz-transform: scale(1.03, 1.03);
  -ms-transform: scale(1.03, 1.03);
  -o-transform: scale(1.03, 1.03);
  transform: scale(1.03, 1.03)
}

.gallery_holder ul li .gallery_hover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: #000;
  background-color: rgba(0, 0, 0, .81);
  visibility: visible;
  opacity: 0;
  filter: alpha(opacity=0);
  transition: opacity .4s;
  -moz-transition: opacity .4s;
  -o-transition: opacity .4s;
  -ms-transition: opacity .4s;
  transform: translateZ(0);
  -moz-transform: translateZ(0);
  backface-visibility: hidden;
  z-index: 1000
}

.gallery_holder ul li:hover .gallery_hover {
  opacity: 1;
  filter: alpha(opacity=100)
}

.gallery_holder ul li .gallery_hover i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -15px 0 0 -15px;
  font-size: 30px;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block
}

.gallery_holder ul.v6 li,
.qode-image-gallery-slides .gallery_holder ul.v6 li,
.wpb_gallery_slides .gallery_holder ul.v6 li {
  width: 16.66666666666667%;
  margin: 0
}

.gallery_holder ul.v5 li,
.qode-image-gallery-slides .gallery_holder ul.v5 li,
.wpb_gallery_slides .gallery_holder ul.v5 li {
  width: 20%;
  margin: 0
}

.gallery_holder ul.v4 li,
.qode-image-gallery-slides .gallery_holder ul.v4 li,
.wpb_gallery_slides .gallery_holder ul.v4 li {
  width: 25%;
  margin: 0
}

.gallery_holder ul.v3 li,
.qode-image-gallery-slides .gallery_holder ul.v3 li,
.wpb_gallery_slides .gallery_holder ul.v3 li {
  width: 33.33333333333333%;
  margin: 0
}

.gallery_holder ul.v2 li,
.qode-image-gallery-slides .gallery_holder ul.v2 li,
.wpb_gallery_slides .gallery_holder ul.v2 li {
  width: 50%;
  margin: 0
}

.gallery_holder .gallery_with_space ul li,
.qode-image-gallery-slides .gallery_holder .gallery_with_space ul li,
.wpb_gallery_slides .gallery_holder .gallery_with_space ul li {
  margin-bottom: 2%
}

.gallery_holder .gallery_with_space ul li:last-child,
.qode-image-gallery-slides .gallery_holder .gallery_with_space ul li:last-child,
.wpb_gallery_slides .gallery_holder .gallery_with_space ul li:last-child {
  margin-bottom: 0
}

.gallery_holder ul.gallery_with_space.v2 li,
.qode-image-gallery-slides .gallery_holder ul.gallery_with_space.v2 li,
.wpb_gallery_slides .gallery_holder ul.gallery_with_space.v2 li {
  width: 49%;
  margin: 0 2% 2% 0
}

.gallery_holder ul.gallery_with_space.v2 li:nth-child(2n),
.qode-image-gallery-slides .gallery_holder ul.gallery_with_space.v2 li:nth-child(2n),
.wpb_gallery_slides .gallery_holder ul.gallery_with_space.v2 li:nth-child(2n) {
  margin-right: 0
}

.gallery_holder ul.gallery_with_space.v3 li,
.qode-image-gallery-slides .gallery_holder ul.gallery_with_space.v3 li,
.wpb_gallery_slides .gallery_holder ul.gallery_with_space.v3 li {
  width: 32%;
  margin: 0 2% 2% 0
}

.gallery_holder ul.gallery_with_space.v3 li:nth-child(3n),
.qode-image-gallery-slides .gallery_holder ul.gallery_with_space.v3 li:nth-child(3n),
.wpb_gallery_slides .gallery_holder ul.gallery_with_space.v3 li:nth-child(3n) {
  margin-right: 0
}

.gallery_holder ul.gallery_with_space.v4 li,
.qode-image-gallery-slides .gallery_holder ul.gallery_with_space.v4 li,
.wpb_gallery_slides .gallery_holder ul.gallery_with_space.v4 li {
  width: 23.5%;
  margin: 0 2% 2% 0
}

.gallery_holder ul.gallery_with_space.v4 li:nth-child(4n),
.qode-image-gallery-slides .gallery_holder ul.gallery_with_space.v4 li:nth-child(4n),
.wpb_gallery_slides .gallery_holder ul.gallery_with_space.v4 li:nth-child(4n) {
  margin-right: 0
}

.gallery_holder ul.gallery_with_space.v5 li,
.qode-image-gallery-slides .gallery_holder ul.gallery_with_space.v5 li,
.wpb_gallery_slides .gallery_holder ul.gallery_with_space.v5 li {
  width: 18.4%;
  margin: 0 2% 2% 0
}

.gallery_holder ul.gallery_with_space.v5 li:nth-child(5n),
.qode-image-gallery-slides .gallery_holder ul.gallery_with_space.v5 li:nth-child(5n),
.wpb_gallery_slides .gallery_holder ul.gallery_with_space.v5 li:nth-child(5n) {
  margin-right: 0
}

.wpb_content_element.wpb_single_image img {
  vertical-align: middle
}

.wpb_single_image.qode_image_hover_darken .vc_single_image-wrapper {
  position: relative
}

.wpb_single_image.qode_image_hover_darken .vc_single_image-wrapper:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, .25);
  -webkit-transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  -o-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out
}

.wpb_single_image.qode_image_hover_darken .vc_single_image-wrapper:hover:before {
  opacity: 1
}

.wpb_single_image.qode_image_hover_bottom_title .vc_single_image-wrapper {
  position: relative;
  overflow: hidden
}

.wpb_single_image.qode_image_hover_bottom_title .vc_single_image-wrapper .bottom_title_holder {
  position: absolute;
  bottom: 0;
  height: 0;
  width: 100%;
  background-color: #000;
  -webkit-transition: height .3s ease;
  -moz-transition: height .3s ease;
  transition: height .3s ease
}

.wpb_single_image.qode_image_hover_bottom_title .vc_single_image-wrapper .bottom_title_holder .image_caption {
  font-size: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  display: table;
  height: 100%;
  width: 100%
}

.wpb_single_image.qode_image_hover_bottom_title .vc_single_image-wrapper .bottom_title_holder .image_caption>div {
  display: table-cell;
  vertical-align: middle
}

.wpb_single_image.qode_image_hover_bottom_title .vc_single_image-wrapper:hover .bottom_title_holder {
  height: 25%
}

.wpb_single_image.qode_image_hover_zoom_in .vc_single_image-wrapper {
  overflow: hidden
}

.wpb_single_image.qode_image_hover_zoom_in .vc_single_image-wrapper img {
  -webkit-transition: -webkit-transform .3s cubic-bezier(.4, 0, 1, 1);
  -moz-transition: -moz-transform .3s cubic-bezier(.4, 0, 1, 1);
  -o-transition: -o-transform .3s cubic-bezier(.4, 0, 1, 1);
  transition: transform .3s cubic-bezier(.4, 0, 1, 1)
}

.wpb_single_image.qode_image_hover_zoom_in .vc_single_image-wrapper:hover img {
  -webkit-transform: scale(1.05) translateZ(0);
  -moz-transform: scale(1.05) translateZ(0);
  -o-transform: scale(1.05) translateZ(0);
  transform: scale(1.05) translateZ(0)
}

.qode-horizontal-marquee {
  position: relative;
  overflow: hidden
}

.qode-preview-slider .qode-presl-main-slider .flex-viewport,
.qode-text-marquee .stretch--resizer {
  overflow: visible !important
}

.qode-horizontal-marquee .qode-horizontal-marquee-inner {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0
}

.qode-horizontal-marquee .qode-horizontal-marquee-inner .qode-hm-item {
  position: relative;
  float: left;
  height: 100%
}

.qode-horizontal-marquee .qode-horizontal-marquee-inner .qode-hm-item:last-child {
  margin-right: 0 !important
}

.qode-horizontal-marquee .qode-horizontal-marquee-inner .qode-hm-item .qode-hm-item-inner {
  position: absolute;
  width: 100%;
  left: 0
}

.qode-horizontal-marquee .qode-horizontal-marquee-inner .qode-hm-item .qode-hm-item-inner.qode-top-aligned {
  top: 0
}

.qode-horizontal-marquee .qode-horizontal-marquee-inner .qode-hm-item .qode-hm-item-inner.qode-bottom-aligned {
  bottom: 0
}

.qode-horizontal-marquee .qode-horizontal-marquee-inner .qode-hm-item .qode-hm-item-inner.qode-middle-aligned {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.qode-horizontal-marquee:not(.qode-loop) .qode-horizontal-marquee-inner {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: e-resize;
  cursor: -webkit-grab;
  cursor: grab
}

.qode-horizontal-marquee:not(.qode-loop) .qode-horizontal-marquee-inner.qode-dragged {
  cursor: -webkit-grabbing;
  cursor: grabbing
}

.qode-horizontal-marquee.qode-loop {
  visibility: hidden
}

.no-touchevents .qode-horizontal-marquee.qode-loop,
.touchevents .qode-horizontal-marquee.qode-loop {
  visibility: visible
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx .qode-hm-item-inner>div {
  opacity: 0
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(1) {
  opacity: 1;
  -webkit-transition: opacity .6s .1s;
  -moz-transition: opacity .6s .1s;
  transition: opacity .6s .1s
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(2) {
  opacity: 1;
  -webkit-transition: opacity .6s .2s;
  -moz-transition: opacity .6s .2s;
  transition: opacity .6s .2s
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(3) {
  opacity: 1;
  -webkit-transition: opacity .6s .3s;
  -moz-transition: opacity .6s .3s;
  transition: opacity .6s .3s
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(4) {
  opacity: 1;
  -webkit-transition: opacity .6s .4s;
  -moz-transition: opacity .6s .4s;
  transition: opacity .6s .4s
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(5) {
  opacity: 1;
  -webkit-transition: opacity .6s .5s;
  -moz-transition: opacity .6s .5s;
  transition: opacity .6s .5s
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(6) {
  opacity: 1;
  -webkit-transition: opacity .6s .6s;
  -moz-transition: opacity .6s .6s;
  transition: opacity .6s .6s
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(7) {
  opacity: 1;
  -webkit-transition: opacity .6s .7s;
  -moz-transition: opacity .6s .7s;
  transition: opacity .6s .7s
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(8) {
  opacity: 1;
  -webkit-transition: opacity .6s .8s;
  -moz-transition: opacity .6s .8s;
  transition: opacity .6s .8s
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(9) {
  opacity: 1;
  -webkit-transition: opacity .6s .9s;
  -moz-transition: opacity .6s .9s;
  transition: opacity .6s .9s
}

.no-touchevents .qode-horizontal-marquee.qode-loop.qode-appear-fx.qode-appeared .qode-hm-item-inner>div:nth-child(10) {
  opacity: 1;
  -webkit-transition: opacity .6s 1s;
  -moz-transition: opacity .6s 1s;
  transition: opacity .6s 1s
}

.qode-text-marquee {
  position: relative
}

.qode-text-marquee .qode-text-marquee-wrapper:last-child.qode-marquee-additional-title {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.touchevents .qode-text-marquee .qode-text-marquee-wrapper {
  padding: 0 10%
}

.qode-text-marquee .qode-text-marquee-title {
  visibility: hidden;
  color: #e4e4e4;
  line-height: normal;
  font-weight: 700
}

.qode-preview-slider {
  position: relative;
  width: 920px;
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 60px
}

.qode-preview-slider img {
  width: 100%;
  display: block;
  visibility: hidden
}

.qode-preview-slider img.visible {
  visibility: visible
}

.qode-preview-slider .qode-presl-link {
  position: relative;
  display: block
}

.qode-preview-slider .qode-presl-link:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.qode-preview-slider .qode-presl-link:hover:after {
  opacity: .05
}

.qode-preview-slider .qode-presl-main-slider {
  position: relative
}

.qode-preview-slider .qode-presl-main-slider .qode-presl-main-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 6.5%
}

.qode-preview-slider .qode-presl-main-slider .flex-direction-nav {
  opacity: 1;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease
}

.qode-preview-slider .qode-presl-main-slider .flex-direction-nav.moving {
  opacity: 0;
  -webkit-transition: none;
  transition: none
}

.qode-preview-slider .qode-presl-main-slider .flex-direction-nav a {
  width: 54px;
  height: 54px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -27px;
  z-index: 10;
  border: none
}

.qode-preview-slider .qode-presl-main-slider .flex-direction-nav a.flex-prev {
  margin-left: -27px
}

.qode-preview-slider .qode-presl-main-slider .flex-direction-nav a.flex-next {
  right: 0;
  margin-right: -27px
}

.qode-preview-slider .qode-presl-main-slider .flex-direction-nav a i {
  line-height: 54px;
  color: #303030;
  font-size: 54px
}

.qode-preview-slider .qode-presl-main-slider .flex-control-nav {
  position: absolute;
  bottom: -60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.qode-preview-slider .qode-presl-main-slider .flex-control-nav li {
  float: left;
  width: 9px;
  height: 9px;
  margin: 0 5px
}

.qode-preview-slider .qode-presl-main-slider .flex-control-nav li a {
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  background-color: #cfcfcf;
  border-radius: 50%;
  -webkit-transition: background-color .6s ease;
  transition: background-color .6s ease
}

.qode-preview-slider .qode-presl-main-slider .flex-control-nav li a.flex-active {
  background-color: #303030
}

.qode-preview-slider .qode-presl-main-slider .slides li.clone_end {
  position: absolute;
  top: 0;
  left: 50%
}

.qode-preview-slider .qode-presl-main-slider .slides li.clone_start {
  position: absolute;
  top: 0;
  right: 100%
}

.qode-preview-slider .qode-presl-small-slider-holder {
  position: absolute;
  width: 32.6%;
  bottom: 0;
  right: 4%
}

.qode-preview-slider .qode-presl-small-slider-holder .qode-presl-small-slider {
  position: absolute;
  top: 11.3%;
  left: 24%;
  width: 52%;
  z-index: 1
}

.qode-preview-slider .qode-presl-small-slider-holder .qode-presl-small-slider .flex-control-nav,
.qode-preview-slider .qode-presl-small-slider-holder .qode-presl-small-slider .flex-direction-nav {
  display: none !important
}

.qode-preview-slider .qode-presl-small-slider-holder .qode-presl-phone {
  display: block;
  z-index: 2
}

.qode-in-device-slider {
  position: relative;
  width: 100%
}

.qode-in-device-slider img {
  width: 100%;
  display: block;
  visibility: hidden
}

.qode-in-device-slider img.visible {
  visibility: visible
}

.qode-in-device-slider .qode-ids-link {
  position: static;
  display: block;
  max-height: 100%;
  overflow: hidden
}

.qode-in-device-slider .qode-ids-link:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  opacity: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease
}

.qode-in-device-slider .qode-ids-link .qode-ids-title,
.qode-in-device-slider .qode-ids-link img,
.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-ie-specific .qode-ids-link .qode-ids-title,
.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-ie-specific .qode-ids-link img {
  transition: all .3s cubic-bezier(.33, 0, .2, 1) 50ms
}

.qode-in-device-slider .qode-ids-link:hover:after {
  opacity: .05
}

.qode-in-device-slider .qode-ids-link .qode-ids-title {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%)
}

.qode-in-device-slider .qode-ids-link .qode-ids-title h5 {
  text-align: center;
  margin: 27px 0;
  padding: 0 10px;
  font-size: 12px;
  line-height: 1.25em;
  box-sizing: border-box;
  color: #303030
}

.qode-in-device-slider .qode-ids-link.hovered .qode-ids-title {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0)
}

.qode-in-device-slider .qode-ids-link.hovered img {
  -webkit-transform: translateY(-20px);
  -moz-transform: translateY(-20px);
  transform: translateY(-20px)
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider {
  position: absolute;
  z-index: 1;
  overflow: hidden
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-ie-specific {
  z-index: 3
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-ie-specific .qode-ids-link .qode-ids-title {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  background-color: #fff;
  overflow: hidden;
  height: 0;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-ie-specific .qode-ids-link .qode-ids-title h5 {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
  color: #303030
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-ie-specific .qode-ids-link.hovered .qode-ids-title {
  height: 76px;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-ie-specific .qode-ids-link.hovered img {
  margin-top: -20px;
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-framed-desktop {
  top: 5.3%;
  left: 4%;
  width: 91.7%;
  height: 66.5%
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-framed-laptop {
  top: 6.6%;
  left: 13.4%;
  width: 73.6%;
  height: 78%
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-framed-tablet-landscape {
  top: 10.4%;
  left: 19.7%;
  width: 60.6%;
  height: 76.2%
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-framed-tablet-portrait {
  top: 8.5%;
  left: 21.9%;
  width: 57.3%;
  height: 80.5%
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-framed-phone-landscape {
  top: 4.7%;
  left: 17.5%;
  width: 65.5%;
  height: 86.3%
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider.qode-ids-framed-phone-portrait {
  top: 8.4%;
  left: 12.4%;
  width: 75%;
  height: 80.3%
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider .flex-viewport,
.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider .slides,
.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider .slides>li {
  height: 100%
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider .qode-ids-item {
  position: relative;
  max-height: 100%
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider .flex-direction-nav a {
  width: 54px;
  height: 54px;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -27px;
  z-index: 10;
  border: none;
  background-color: rgba(255, 255, 255, .1);
  border-radius: 50%
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider .flex-direction-nav a.flex-prev {
  margin-left: 7px
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider .flex-direction-nav a.flex-next {
  right: 0;
  margin-right: 7px
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-slider .flex-direction-nav a i {
  line-height: 54px;
  color: #303030;
  font-size: 54px
}

.qode-in-device-slider .qode-ids-slider-holder .qode-ids-frame {
  position: relative;
  display: block;
  z-index: 2;
  pointer-events: none
}

.q_dropcap {
  position: relative;
  display: inline-block;
  float: left;
  height: 48px;
  width: 48px;
  line-height: 48px;
  font-size: 23px;
  color: #1abc9c;
  text-align: center;
  margin: 5px 20px 0 0;
  border: 1px solid transparent
}

.q_dropcap.circle,
.q_dropcap.square {
  margin: 5px 20px 0 0;
  font-size: 22px;
  background-color: #1abc9c;
  border: 1px solid transparent;
  color: #fff;
  font-weight: 300
}

.q_dropcap.normal {
  font-weight: 700;
  position: relative;
  left: 8px
}

.q_dropcap.circle {
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -ms-border-radius: 2em;
  -o-border-radius: 2em;
  border-radius: 2em
}

.q_dropcap.square {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px
}

.q_message {
  position: relative;
  padding: 26px 30px;
  color: #fff;
  background-color: #1abc9c
}

.q_message .q_message_inner {
  position: relative
}

.q_message.with_icon .q_message_icon_holder {
  float: left;
  padding: 0 27px 0 0
}

.q_message.with_icon .q_message_icon {
  display: table;
  height: 100%
}

.q_message.with_icon .q_message_icon_inner {
  display: table-cell;
  height: 100%;
  vertical-align: middle
}

.q_message.with_icon>i {
  line-height: 1em
}

.q_message.with_icon img {
  float: left
}

.q_message a.close {
  position: absolute;
  top: -19px;
  right: -23px;
  line-height: 13px;
  color: #fff
}

.q_message a.close i {
  width: 14px;
  height: 14px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
}

.q_message .message_text {
  display: table;
  height: 100%;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

.q_message .message_text_inner {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
}

.q_price_table {
  position: relative;
  width: 100%;
  padding: 0;
  color: #fff;
  border-top: 1px solid #ebebeb;
  margin-left: 2.5%;
  float: left;
}

.qode_pricing_tables.two_columns .q_price_table {
  width: 48.75%;
}

.qode_pricing_tables.three_columns .q_price_table {
  width: 31.666666666666668%;
}

.qode_pricing_tables.four_columns .q_price_table {
  width: 23.125%;
}

.qode_pricing_tables .q_price_table:first-child {
  margin-left: 0;
}

.q_price_table .price_table_inner {
  background-color: #262626;
  position: relative;
}

.q_price_table.active .active_text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #1abc9c;
  font-size: 13px;
  line-height: 31px;
}

.q_price_table.active .price_table_inner>ul {
  padding: 31px 0 0;
  margin: -31px 0
}

.price_table_inner ul {
  list-style: none;
  margin: 0;
  padding-left: 0 !important
}

.price_table_inner ul li {
  text-align: center;
  margin: 0;
  padding: 2px 20px 13px
}

.price_table_inner ul li.pricing_table_content {
  padding: 20px 27px 10px
}

.price_table_inner ul li.pricing_table_content li {
  font-size: 13px
}

.price_table_inner ul li.pricing_table_content li:last-child {
  border-bottom: none
}

.price_table_inner ul li.prices {
  padding-top: 0;
  padding-bottom: 3px
}

.price_table_inner ul li.table_title {
  padding: 25px 25px 3px
}

.price_table_inner ul li.table_title .title_content {
  color: #fff;
  font-weight: 700;
  font-size: 17px
}

.price_in_table {
  text-align: center
}

.price_in_table .value {
  font-size: 20px;
  font-weight: 600;
  vertical-align: top;
  position: relative;
  top: 17px
}

.price_in_table .price {
  font-size: 83px;
  line-height: 1em;
  padding: 0 3px;
  font-weight: 200;
  vertical-align: middle;
}

.price_in_table .mark {
  vertical-align: bottom;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  top: 10px;
}

.elementor-widget-heading .elementor-heading-title>a,
.qode_icon_shortcode.fa-stack a,
blockquote.wp-block-pullquote>* {
  letter-spacing: inherit;
  text-transform: inherit;
  font-style: inherit;
}

.price_table_inner .price_button {
  padding: 0 0 32px;
}

.price_button .qbutton {
  margin: 0 7px;
}

.q_icon_with_title {
  display: block;
  position: relative;
}

.q_icon_with_title .icon_holder {
  display: inline-block;
  position: relative;
  z-index: 2
}

.q_icon_with_title:not(.right) .icon_holder {
  float: left
}

.q_icon_with_title.right .icon_holder {
  float: right
}

.q_icon_with_title.large .icon_text_holder .icon_title {
  margin-bottom: .35em
}

.q_icon_with_title .icon_text_holder .separator.small {
  display: inline-block
}

.q_icon_with_title .icon_with_title_link {
  display: inline-block;
  margin: 13px 0 0;
  color: #1abc9c
}

.q_icon_with_title .icon_with_title_link:hover {
  color: #303030
}

.box_holder_icon_inner.q_icon_animation,
.q_icon_with_title .icon_holder.q_icon_animation {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  transition: all .4s ease;
  backface-visibility: hidden
}

.box_holder_icon_inner.q_icon_animation.q_show_animation,
.q_icon_with_title .icon_holder.q_icon_animation.q_show_animation {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}

.box_holder_icon_inner .fa-stack,
.q_font_awsome_icon_square,
.q_font_awsome_icon_stack i.fa-stack-base,
.q_icon_with_title .icon_holder .fa-stack {
  border: 2px solid silver;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -ms-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear;
  backface-visibility: hidden;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial
}

.q_font_awsome_icon_stack i.fa-stack-base {
  border: none
}

.q_icon_with_title .icon_text_holder {
  position: relative
}

.q_box_holder.with_icon span.fa-stack .qode_iwt_icon_element,
.q_icon_with_title span.fa-stack .qode_iwt_icon_element {
  font-size: .7em;
  color: silver;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -ms-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s ease-in-out
}

.q_box_holder.with_icon {
  text-align: left
}

.q_box_holder.with_icon .box_holder_icon_inner.circle .fa-stack {
  text-align: center
}

.q_icon_with_title.circle span.fa-stack .qode_iwt_icon_element {
  font-size: .9em
}

.q_icon_with_title span.fa-stack.custom-font .qode_iwt_icon_element {
  font-size: .7em
}

.q_icon_with_title .icon_text_inner {
  padding: 0 0 18px
}

.q_icon_with_title .icon_title_holder {
  display: table;
  width: 100%;
  height: 100%;
  margin: 0 0 12px
}

.q_icon_with_title .icon_title_holder .icon_holder {
  display: table-cell;
  text-align: center;
  height: 100%;
  vertical-align: middle;
  float: none;
  padding: 0 15px 0 0;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box
}

.q_icon_with_title .icon_title_holder .icon_holder>span {
  position: relative;
  top: 2px
}

.q_icon_with_title .icon_text_holder .icon_title_holder .icon_title {
  display: table-cell;
  margin: 0;
  vertical-align: middle;
  width: 100%
}

.q_font_awsome_icon_square .qode_icon_element,
.q_font_awsome_icon_stack .qode_icon_element {
  color: #fff
}

.qode_icon_shortcode.fa-stack a {
  display: block;
  width: 100%;
  height: 100%;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  text-decoration: inherit
}

.qode_icon_shortcode.fa-stack .qode_icon_element,
.qode_icon_shortcode.fa-stack .qodef-icon-linea-icon:before {
  display: block;
  line-height: inherit
}

.touchevents .no_animation_on_touch .q_icon_animation {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}

.qode_iwt_hover_enabled {
  transition: background-color .2s ease, box-shadow .2s ease
}

.qode_iwt_hover_enabled:hover {
  background-color: #fff;
  box-shadow: 0 7px 16px rgba(0, 0, 0, .1)
}

.q_icon_with_title.qode_iwt_hover_enabled .icon_text_inner {
  padding: 0
}

.q_icon_with_title.qode_iwt_hover_enabled {
  padding: 45px 20px
}

.q_icon_with_title.center {
  text-align: center
}

.more_facts_button_holder.left,
.q_icon_with_title.center.qode-iwt-content-alignment-left {
  text-align: left
}

.q_icon_with_title.center.qode-iwt-content-alignment-right {
  text-align: right
}

.q_icon_with_title.center.center .icon_holder {
  float: none;
  display: block;
  margin: 0 0 20px
}

.q_icon_with_title.custom_icon_image.center .icon_holder {
  margin: 0 0 13px
}

.q_icon_with_title.center .icon_holder {
  width: 100% !important
}

.q_icon_with_title.center .icon_holder .font_awsome_icon i {
  color: #7b7b7b;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.q_icon_with_title.center .icon_holder .font_awsome_icon i:hover {
  color: #1abc9c
}

.q_font_awsome_icon_square,
.q_icon_with_title.boxed .icon_holder .fa-stack {
  background-color: #1abc9c;
  border-radius: 4px;
  text-align: center;
  transition: all .3s ease-in-out;
  backface-visibility: hidden
}

.q_social_icon_holder .simple_social,
.single_links_pages span {
  transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out
}

.q_box_holder.with_icon .box_holder_icon_inner .fa-stack i.fa-stack-base {
  color: #1abc9c;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  backface-visibility: hidden
}

.box_holder_icon_inner.square .fa-stack,
.q_font_awsome_icon_square,
.q_icon_with_title.square .icon_holder .fa-stack {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px
}

.box_holder_icon_inner.circle .fa-stack:hover,
.box_holder_icon_inner.square .fa-stack:hover,
.circle .icon_holder .fa-stack:hover,
.q_icon_with_title.square .icon_holder .fa-stack:hover {
  background-color: #1abc9c;
  border-color: transparent !important
}

.q_font_awsome_icon_stack .fa-circle,
.q_icon_with_title.boxed .icon_holder .fa-stack {
  color: #1abc9c;
  -webkit-transition: color .4s ease-in-out;
  -moz-transition: color .4s ease-in-out;
  -o-transition: color .4s ease-in-out;
  transition: color .4s ease-in-out
}

.q_box_holder.with_icon .box_holder_icon_inner .fa-stack:hover i.fa-stack-base,
.q_font_awsome_icon_stack:hover .fa-circle,
.q_icon_with_title.circle .icon_holder .fa-stack:hover i.fa-circle {
  color: #f3f3f3 !important
}

.q_icon_with_title.boxed .icon_holder .fa-stack {
  -webkit-transition: background-color .2s ease-in-out;
  -moz-transition: background-color .2s ease-in-out;
  -o-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out
}

.q_font_awsome_icon i,
.q_font_awsome_icon span,
.side_menu .widget li,
.side_menu a,
.social_share_list_holder ul li i,
.widget a {
  transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out
}

.box_holder_icon_inner.square:hover i,
.q_box_holder.with_icon span.fa-stack:hover i:last-child,
.q_font_awsome_icon_square:hover i,
.q_font_awsome_icon_stack:hover i:last-child,
.q_icon_with_title.boxed .icon_holder .fa-stack:hover i,
.q_icon_with_title.circle .icon_holder .fa-stack:hover .qode_iwt_icon_element,
.q_icon_with_title.square .icon_holder .fa-stack:hover .qode_iwt_icon_element,
.q_icon_with_title.square .icon_holder .fa-stack:hover i {
  color: #fff
}

.q_box_holder.with_icon .tiny span.fa-stack i:last-child {
  top: 1px
}

.q_icon_with_title.with_border_line .icon_text_inner {
  border-bottom: 1px solid #fbfbfb
}

.q_icon_with_title.tiny .icon_text_holder {
  padding: 0 0 0 44px
}

.q_icon_with_title.small .icon_text_holder {
  padding: 0 0 0 46px
}

.q_icon_with_title.medium .icon_holder,
.q_icon_with_title.small.square .icon_holder,
.q_icon_with_title.tiny.square .icon_holder {
  text-align: center
}

.q_icon_with_title.medium .icon_text_holder,
.q_icon_with_title.tiny.square .icon_text_holder {
  padding: 0 0 0 56px
}

.q_icon_with_title.large .icon_text_holder {
  padding: 0 0 0 65px
}

.q_icon_with_title.large .icon_holder {
  text-align: center
}

.q_icon_with_title.very_large .icon_text_holder {
  padding: 0 0 0 84px
}

.q_icon_with_title.tiny.boxed .icon_text_holder,
.q_icon_with_title.tiny.circle .icon_text_holder,
.q_icon_with_title.tiny.custom_icon_image .icon_text_holder {
  padding: 0 0 0 55px
}

.q_icon_with_title.small.boxed .icon_text_holder,
.q_icon_with_title.small.circle .icon_text_holder,
.q_icon_with_title.small.custom_icon_image .icon_text_holder,
.q_icon_with_title.small.square .icon_text_holder {
  padding: 0 0 0 72px
}

.q_icon_with_title.medium.circle .icon_text_holder {
  padding: 0 0 0 119px
}

.q_icon_with_title.medium.boxed .icon_text_holder,
.q_icon_with_title.medium.custom_icon_image .icon_text_holder,
.q_icon_with_title.medium.square .icon_text_holder {
  padding: 0 0 0 95px
}

.q_icon_with_title.large.boxed .icon_text_holder,
.q_icon_with_title.large.circle .icon_text_holder,
.q_icon_with_title.large.custom_icon_image .icon_text_holder,
.q_icon_with_title.large.square .icon_text_holder {
  padding: 0 0 0 122px
}

.q_icon_with_title.very_large.boxed .icon_text_holder,
.q_icon_with_title.very_large.circle .icon_text_holder,
.q_icon_with_title.very_large.custom_icon_image .icon_text_holder,
.q_icon_with_title.very_large.square .icon_text_holder {
  padding: 0 0 0 150px
}

.q_icon_with_title.left_from_title .icon_text_holder {
  padding: 0 !important
}

.q_icon_with_title.right .icon_text_holder {
  padding-left: 0 !important;
  text-align: right
}

.q_icon_with_title.tiny.circle.right .icon_text_holder,
.q_icon_with_title.tiny.custom_icon_image.right .icon_text_holder {
  padding: 0 55px 0 0
}

.q_icon_with_title.small.circle.right .icon_text_holder,
.q_icon_with_title.small.custom_icon_image.right .icon_text_holder,
.q_icon_with_title.small.square.right .icon_text_holder {
  padding: 0 72px 0 0
}

.q_icon_with_title.medium.circle.right .icon_text_holder {
  padding: 0 119px 0 0
}

.q_icon_with_title.medium.custom_icon_image.right .icon_text_holder,
.q_icon_with_title.medium.square.right .icon_text_holder {
  padding: 0 95px 0 0
}

.q_icon_with_title.large.circle.right .icon_text_holder,
.q_icon_with_title.large.custom_icon_image.right .icon_text_holder,
.q_icon_with_title.large.square.right .icon_text_holder {
  padding: 0 122px 0 0
}

.q_icon_with_title.very_large.circle.right .icon_text_holder,
.q_icon_with_title.very_large.custom_icon_image.right .icon_text_holder,
.q_icon_with_title.very_large.square.right .icon_text_holder {
  padding: 0 150px 0 0
}

.q_icon_with_title.tiny.square.right .icon_text_holder {
  padding: 0 56px 0 0
}

.q_icon_with_title.left_from_title .icon_text_holder .icon_holder {
  width: auto !important
}

.q_icon_with_title.left_from_title.custom_icon_image.tiny .icon_text_holder .icon_holder {
  width: 35px !important
}

.q_icon_with_title.left_from_title.custom_icon_image.small .icon_text_holder .icon_holder {
  width: 52px !important
}

.q_icon_with_title.left_from_title.custom_icon_image.medium .icon_text_holder .icon_holder {
  width: 78px !important
}

.q_icon_with_title.left_from_title.custom_icon_image.large .icon_text_holder .icon_holder {
  width: 104px !important
}

.q_icon_with_title.left_from_title.custom_icon_image.very_large .icon_text_holder .icon_holder {
  width: 130px !important
}

.q_icon_with_title.large.boxed.center .icon_text_holder,
.q_icon_with_title.large.center .icon_text_holder,
.q_icon_with_title.large.circle.center .icon_text_holder,
.q_icon_with_title.large.custom_icon_image.center .icon_text_holder,
.q_icon_with_title.medium.boxed.center .icon_text_holder,
.q_icon_with_title.medium.center .icon_text_holder,
.q_icon_with_title.medium.circle.center .icon_text_holder,
.q_icon_with_title.medium.custom_icon_image.center .icon_text_holder,
.q_icon_with_title.small.boxed.center .icon_text_holder,
.q_icon_with_title.small.center .icon_text_holder,
.q_icon_with_title.small.circle.center .icon_text_holder,
.q_icon_with_title.small.custom_icon_image.center .icon_text_holder,
.q_icon_with_title.tiny.boxed.center .icon_text_holder,
.q_icon_with_title.tiny.center .icon_text_holder,
.q_icon_with_title.tiny.circle.center .icon_text_holder,
.q_icon_with_title.tiny.custom_icon_image.center .icon_text_holder,
.q_icon_with_title.very_large.boxed.center .icon_text_holder,
.q_icon_with_title.very_large.center .icon_text_holder,
.q_icon_with_title.very_large.circle.center .icon_text_holder,
.q_icon_with_title.very_large.custom_icon_image.center .icon_text_holder {
  padding: 0
}

.q_icon_with_title.left_from_title .icon_holder {
  display: block
}

.q_icon_with_title.left_from_title.small.normal_icon .icon_holder,
.q_icon_with_title.left_from_title.tiny .icon_holder,
.q_icon_with_title.tiny .icon_holder img {
  width: 35px
}

.q_icon_with_title.left_from_title.large.normal_icon .icon_holder,
.q_icon_with_title.left_from_title.small .icon_holder,
.q_icon_with_title.small .icon_holder img {
  width: 52px
}

.q_icon_with_title.left_from_title.medium .icon_holder,
.q_icon_with_title.left_from_title.very_large.normal_icon .icon_holder,
.q_icon_with_title.medium .icon_holder img {
  width: 78px
}

.q_icon_with_title.large .icon_holder img,
.q_icon_with_title.left_from_title.large .icon_holder {
  width: 104px
}

.q_icon_with_title.left_from_title.very_large .icon_holder,
.q_icon_with_title.very_large .icon_holder img {
  width: 130px
}

.q_icon_with_title.left_from_title.tiny.normal_icon .icon_holder {
  width: 25px
}

.q_icon_with_title.left_from_title.medium.normal_icon .icon_holder {
  width: 45px
}

.q_icon_with_title.circle .qode_iwt_icon_holder .qode_iwt_icon_element,
.q_icon_with_title.square .qode_iwt_icon_holder .qode_iwt_icon_element {
  display: block;
  height: 100%;
  line-height: inherit;
  text-align: center
}

.q_font_awsome_icon {
  display: inline-block
}

.q_font_awsome_icon i,
.q_font_awsome_icon span {
  color: #1abc9c;
  -moz-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out;
  backface-visibility: hidden
}

.q_font_awsome_icon i:hover,
.q_font_awsome_icon span:hover {
  color: #1abc9c
}

.q_font_awsome_icon.pull-center {
  display: block;
  text-align: center
}

.q_font_awsome_icon_square.pull-center,
.q_font_awsome_icon_stack.pull-center {
  display: block;
  margin: 0 auto !important
}

.q_font_awsome_icon.q_icon_animation,
.q_font_awsome_icon_square.q_icon_animation,
.q_font_awsome_icon_stack.q_icon_animation {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform .4s ease;
  -moz-transition: -moz-transform .4s ease;
  -o-transition: -o-transform .4s ease;
  -ms-transition: -ms-transform .4s ease;
  transition: transform .4s ease;
  backface-visibility: hidden
}

.q_font_awsome_icon.q_icon_animation.q_show_animation,
.q_font_awsome_icon_square.q_icon_animation.q_show_animation,
.q_font_awsome_icon_stack.q_icon_animation.q_show_animation {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}

#back_to_top span,
.box_holder_icon_inner.square .fa-stack,
.q_icon_with_title.square .icon_holder .fa-stack,
.q_social_icon_holder .fa-stack {
  text-align: center
}

.drop_down .wide .second ul li div.flexslider li:nth-child(4n+1) {
  clear: none
}

.drop_down .wide .second ul li .flexslider ul {
  padding: 0;
  border: none
}

.drop_down .wide .second ul li ul.flex-direction-nav a {
  position: absolute;
  top: 0;
  border: none;
  margin: 0
}

.drop_down .wide .second ul li ul.flex-direction-nav {
  position: absolute;
  top: 45px;
  left: 0;
  overflow: visible;
  width: 100%
}

.drop_down .wide .second ul li div.flexslider {
  margin: 0
}

.drop_down .wide .second ul li .flexslider.widget_flexslider ul.flex-direction-nav li {
  width: 100%
}

.drop_down .wide .second ul li ul li .flexslider.widget_flexslider h3,
.drop_down .wide .second ul li ul li .flexslider.widget_flexslider h3 a,
.flexslider.widget_flexslider h3 {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  padding: 10px 0 2px !important
}

.drop_down .wide .second .inner ul li.flexslider.widget_flexslider ul li h3 a,
.flexslider.widget_flexslider ul li h3 a {
  color: #000;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px
}

.drop_down .wide .second .inner ul li.sub .flexslider.widget_flexslider ul.flex-direction-nav a.flex-prev,
.flexslider.widget_flexslider ul.flex-direction-nav a.flex-prev {
  background-color: #000;
  background-color: rgba(0, 0, 0, .4);
  width: 40px;
  height: 40px;
  line-height: 40px
}

.drop_down .second .flexslider.widget_flexslider ul.flex-direction-nav i {
  display: inline
}

.drop_down .wide.icons .second .flexslider.widget_flexslider ul.flex-direction-nav i {
  width: auto;
  float: none;
  height: auto;
  color: #fff
}

.drop_down .wide .second .inner ul li.sub .flexslider.widget_flexslider ul.flex-direction-nav a.flex-next,
.flexslider.widget_flexslider ul.flex-direction-nav a.flex-next {
  background-color: #000;
  background-color: rgba(0, 0, 0, .4);
  width: 40px;
  height: 40px;
  line-height: 40px
}

.drop_down .wide .second .inner ul li.sub .flexslider.widget_flexslider ul.flex-direction-nav a.flex-next:hover,
.drop_down .wide .second .inner ul li.sub .flexslider.widget_flexslider ul.flex-direction-nav a.flex-prev:hover,
.flexslider.widget_flexslider ul.flex-direction-nav a.flex-next:hover,
.flexslider.widget_flexslider ul.flex-direction-nav a.flex-prev:hover,
div.pp_overlay {
  background-color: #000
}

.drop_down .wide .second .inner ul li.sub .flexslider.widget_flexslider .menu_recent_post_text {
  padding: 10px 0
}

.drop_down .wide .second .inner ul li.sub .flexslider.widget_flexslider .menu_recent_post_text a {
  display: inline;
  padding: 0
}

.drop_down .wide .second .inner ul li.sub .flexslider.widget_flexslider .menu_recent_post_text a:hover {
  color: #fff
}

.drop_down .wide .second .inner ul li.sub .flexslider.widget_flexslider a,
.drop_down .wide .second ul li .flexslider.widget_flexslider a {
  padding: 0;
  margin: 0
}

.drop_down .wide .second .inner ul li.sub .flexslider.widget_flexslider a i,
.drop_down .wide .second ul li .flexslider.widget_flexslider a i {
  font-size: 20px
}

ul.flickr_widget {
  display: inline-block;
  position: relative;
  padding: 0
}

ul.flickr_widget li {
  float: left;
  margin: 0 5px 5px 0
}

ul.flickr_widget li:nth-child(3n) {
  margin: 0 0 5px
}

ul.flickr_widget li a img {
  float: left
}

.menu_recent_post_text {
  display: block
}

.menu_recent_post_text a {
  display: inline-block !important;
  color: #fff !important
}

.q_progress_bars_icons {
  display: block;
  position: relative;
  opacity: 0;
  filter: alpha(opacity=0)
}

.q_progress_bars_icons_inner {
  position: relative;
  width: 100%
}

.q_progress_bars_icons_inner .bar {
  position: relative;
  float: left;
  margin: 0 10px 10px 0
}

.q_progress_bars_icons_inner.normal .bar {
  margin: 0 0 10px
}

.q_progress_bars_icons_inner.tiny .bar {
  height: 39px;
  width: 39px
}

.q_progress_bars_icons_inner.small .bar {
  width: 56px;
  height: 56px
}

.q_progress_bars_icons_inner.medium .bar {
  width: 83px;
  height: 83px
}

.q_progress_bars_icons_inner.large .bar {
  width: 109px;
  height: 109px
}

.q_progress_bars_icons_inner.very_large .bar {
  width: 135px;
  height: 135px
}

.q_progress_bars_icons_inner .bar .bar_active,
.q_progress_bars_icons_inner .bar .bar_noactive {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 50
}

.q_progress_bars_icons_inner .bar.active .bar_noactive {
  z-index: 100
}

.q_progress_bars_icons_inner.square .bar .bar_active,
.q_progress_bars_icons_inner.square .bar .bar_noactive {
  border: 1px solid #d7d7d7;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px
}

.q_progress_bars_icons_inner.circle .bar .bar_active,
.q_progress_bars_icons_inner.circle .bar .bar_noactive {
  border: 1px solid #d7d7d7;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -ms-border-radius: 2em;
  -o-border-radius: 2em;
  border-radius: 2em
}

.q_progress_bars_icons_inner.circle .bar.active .bar_active,
.q_progress_bars_icons_inner.circle .bar.active .bar_noactive,
.q_progress_bars_icons_inner.square .bar.active .bar_active,
.q_progress_bars_icons_inner.square .bar.active .bar_noactive {
  border: 1px solid #1abc9c
}

.q_progress_bars_icons_inner .bar.active i.fa-circle,
.q_progress_bars_icons_inner.circle .bar.active i,
.q_progress_bars_icons_inner.normal .bar.active i,
.q_progress_bars_icons_inner.square .bar.active i {
  color: #1abc9c
}

.q_progress_bars_icons_inner .bar i.fa-circle,
.q_progress_bars_icons_inner.circle .bar i,
.q_progress_bars_icons_inner.normal .bar i,
.q_progress_bars_icons_inner.square .bar i {
  color: #d7d7d7
}

.more_facts_outer {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden
}

.more_facts_inner_holder {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle
}

.more_facts_inner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 70px 0 0
}

.more_facts_holder {
  background-color: #f6f6f6
}

.more_facts_button {
  display: block;
  position: relative;
  margin: 0;
  padding: 39px 0 15px;
  color: #303030;
  font-size: 19px;
  line-height: 22px;
  cursor: pointer;
  z-index: 90
}

.more_facts_button:hover {
  color: #1abc9c
}

.more_facts_button .more_facts_button_arrow,
.more_facts_button .more_facts_button_text {
  display: block
}

.more_facts_button .more_facts_button_text {
  display: block;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 0 5px;
}

.more_facts_button .more_facts_button_arrow {
  -webkit-transition: -webkit-transform .3s ease-in-out;
  -moz-transition: -moz-transform .3s ease-in-out;
  -ms-transition: -ms-transform .3s ease-in-out;
  -o-transition: -o-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
}

.more_facts_button .more_facts_button_arrow.rotate_arrow {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.more_facts_button_holder {
  display: block;
  position: relative
}

.more_facts_button_holder.center {
  text-align: center
}

.more_facts_button_holder.right {
  text-align: right
}

.angled-section {
  position: absolute;
  z-index: 20;
  display: block
}

.angled-section polygon {
  fill: #f6f6f6
}

.angled-section.svg-top {
  top: -85px
}

.angled-section.svg-bottom {
  bottom: -85px
}

.angled-section.svg-footer-bottom,
.angled-section.svg-title-bottom {
  bottom: 0
}

.ordered ol,
.ordered ul {
  counter-reset: li;
  display: block;
  list-style-type: decimal;
  background-position: left center;
  background-repeat: no-repeat;
  margin: 0 0 30px;
  padding: 0 0 0 20px
}

.ordered ol li {
  margin: 0 0 21px;
  padding: 0 0 0 4px;
  color: #303030
}

.q_list.normal ul>li {
  font-weight: 400 !important
}

.q_list.light ul>li {
  font-weight: 300 !important
}

.q_list.bold ul>li {
  font-weight: 600 !important
}

.q_list.circle ul,
.q_list.number ul {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0
}

.q_list.number ul {
  counter-reset: li
}

.q_list.number.circle_number li {
  padding-left: 43px;
  margin-bottom: 22px
}

.q_list.circle ul>li,
.q_list.number ul>li {
  position: relative;
  font-weight: 400;
  margin: 0 0 22px;
  padding: 0 0 0 22px;
  color: #303030
}

.q_list.circle ul>li:before {
  position: absolute;
  left: 0;
  width: 7px;
  height: 7px;
  top: 9px;
  background-color: #ababab;
  display: block;
  content: '';
  border-radius: 25px
}

.q_list.number ul>li:before {
  height: 20px;
  width: 20px;
  line-height: 20px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 1px;
  padding: 0;
  color: #1abc9c;
  text-align: center;
  content: counter(li, decimal-leading-zero);
  counter-increment: li;
  font-weight: 500
}

.q_list.number.circle_number ul>li:before {
  color: #fff;
  background-color: #1abc9c;
  border-radius: 2em;
  height: 31px;
  width: 31px;
  line-height: 31px;
  top: -3px
}

.q_list.circle.animate_list ul li,
.q_list.number.animate_list ul li {
  opacity: 0;
  filter: alpha(opacity=0);
  top: -40px
}

.touchevents .no_animation_on_touch .q_list.circle.animate_list ul li,
.touchevents .no_animation_on_touch .q_list.number.animate_list ul li {
  opacity: 1;
  filter: alpha(opacity=100);
  top: 0
}

.q_social_icon_holder {
  display: inline-block;
  position: relative
}

.q_social_icon_holder.normal_social {
  margin: 0 6px
}

header .header_top .q_social_icon_holder {
  float: left;
  margin: 0;
  width: 32px
}

.q_social_icon_holder .simple_social {
  color: #bcbcbc;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.q_social_icon_holder span.simple_social {
  display: inline-block
}

.q_social_icon_holder:hover .simple_social {
  color: #1abc9c
}

header .header_top .q_social_icon_holder a {
  display: block
}

header .header_top .q_social_icon_holder:last-child {
  border-right: 0
}

header .header_top .q_social_icon_holder .simple_social.fa-lg {
  font-size: 13px
}

.q_social_icon_holder .fa-stack {
  background-color: #e3e3e3;
  border: 0 solid #f0f0f0;
  margin: .2307692307692308em;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.q_social_icon_holder.circle_social .fa-stack {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.q_social_icon_holder .fa-stack .header_top .q_social_icon_holder .fa-stack {
  background: 0 0;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  font-size: 13px
}

#wp-calendar td#today,
.social_share_dropdown ul {
  border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px
}

.header_top .q_social_icon_holder .fa-stack:hover i,
.header_top .q_social_icon_holder .fa-stack:hover span {
  color: #fff
}

.q_social_icon_holder .fa-stack i,
.q_social_icon_holder .fa-stack span {
  color: #b9b9b9;
  -webkit-transition: color .3s ease-out;
  -moz-transition: color .3s ease-out;
  -o-transition: color .3s ease-out;
  transition: color .3s ease-out
}

.header_top .q_social_icon_holder .fa-stack i,
.header_top .q_social_icon_holder .fa-stack span {
  color: #7b7b7b
}

.q_social_icon_holder.circle_social .fa-stack:hover,
.q_social_icon_holder.square_social .fa-stack:hover {
  background-color: #1abc9c;
  border-color: transparent
}

.q_social_icon_holder.circle_social .fa-stack:hover i,
.q_social_icon_holder.circle_social .fa-stack:hover span,
.q_social_icon_holder.square_social .fa-stack:hover i,
.q_social_icon_holder.square_social .fa-stack:hover span {
  color: #fff
}

.q_social_icon_holder .fa-stack i.fa-circle,
.q_social_icon_holder .fa-stack span.fa-circle {
  color: #fbfbfb
}

.q_social_icon_holder .fa-stack.fa-2x {
  width: 36px;
  height: 36px;
  line-height: 36px
}

.q_social_icon_holder .fa-stack i:last-child,
.q_social_icon_holder .fa-stack span {
  font-size: .7692307692307692em;
  display: block;
  line-height: inherit
}

.q_social_icon_holder .fa-stack.fa-lg i:last-child,
.q_social_icon_holder .fa-stack.fa-lg span {
  font-size: 1em
}

.social_share_holder {
  position: relative;
  display: inline-block
}

.social_share_holder .social_share_icon {
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle
}

.social_share_holder:hover .social_share_title {
  cursor: pointer
}

.social_share_holder:hover .social_share_dropdown {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transition: all .2s cubic-bezier(.3, .67, .24, .86);
  -moz-transition: all .2s cubic-bezier(.3, .67, .24, .86);
  transition: all .2s cubic-bezier(.3, .67, .24, .86)
}

.social_share_title {
  display: inline-block
}

.social_share_dropdown {
  display: block;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 0;
  padding: 10px 0 0;
  position: absolute;
  top: 75%;
  width: 35px;
  visibility: hidden;
  z-index: 950;
  -webkit-transition: opacity .15s, visibility .15s, top 0s .15s;
  -moz-transition: opacity .15s, visibility .15s, top 0s .15s;
  transition: opacity .15s, visibility .15s, top 0s .15s;
  opacity: 0
}

.social_share_dropdown .inner_arrow {
  display: block;
  position: absolute;
  top: -6px;
  left: 8px;
  width: 0;
  height: 0;
  border-color: transparent transparent #323232;
  border-style: solid;
  border-width: 9px;
  z-index: 999
}

.social_share_dropdown ul {
  list-style: none;
  position: relative;
  display: block;
  background-color: #323232;
  z-index: 990;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 4px 0 !important;
  width: 35px
}

.page_share {
  position: relative
}

.social_share_dropdown ul li {
  display: block;
  position: relative;
  text-align: center
}

.social_share_dropdown ul li.share_title {
  display: block;
  text-align: center;
  color: #fff;
  background-color: #1abc9c;
  padding: 10px 20px
}

.social_share_dropdown ul li a {
  display: block;
  height: 20px;
  line-height: 20px;
  padding: 5px 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  margin: 0 !important
}

.social_share_dropdown ul li:last-child a {
  border-bottom: 0
}

.social_share_dropdown ul li i {
  font-size: 17px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  color: #fff;
  text-align: center
}

.social_share_dropdown ul li img {
  width: 22px;
  float: left;
  margin: 0 13px 0 0
}

.social_share_dropdown ul li :hover i {
  color: #1abc9c
}

.social_share_list_holder ul {
  list-style: none;
  display: inline-block
}

.social_share_list_holder ul li {
  display: inline;
  margin-right: 10px
}

.social_share_list_holder ul li i {
  color: #bcbcbc;
  -moz-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.social_share_list_holder ul li i:hover {
  color: #1abc9c
}

.vc_empty_space_inner .empty_space_image {
  display: block;
  height: 100%
}

.grid-sizer {
  width: 25%
}

.masonry_gallery_holder {
  margin: 0 -10px;
  opacity: 0
}

.masonry_gallery_holder .masonry_gallery_item {
  box-sizing: border-box;
  padding: 10px;
  z-index: 1
}

.masonry_gallery_holder .masonry_gallery_item.parallax_item {
  z-index: 0
}

.masonry_gallery_holder.one_column .grid-sizer,
.masonry_gallery_holder.one_column .masonry_gallery_item.rectangle_landscape,
.masonry_gallery_holder.one_column .masonry_gallery_item.rectangle_portrait,
.masonry_gallery_holder.one_column .masonry_gallery_item.square_big,
.masonry_gallery_holder.one_column .masonry_gallery_item.square_small {
  width: 100%
}

.masonry_gallery_holder.two_columns .grid-sizer,
.masonry_gallery_holder.two_columns .masonry_gallery_item.rectangle_portrait,
.masonry_gallery_holder.two_columns .masonry_gallery_item.square_small {
  width: 50%
}

.masonry_gallery_holder.two_columns .masonry_gallery_item.rectangle_landscape,
.masonry_gallery_holder.two_columns .masonry_gallery_item.square_big {
  width: 100%
}

.masonry_gallery_holder.three_columns .grid-sizer,
.masonry_gallery_holder.three_columns .masonry_gallery_item.rectangle_portrait,
.masonry_gallery_holder.three_columns .masonry_gallery_item.square_small {
  width: 33.33333333%
}

.masonry_gallery_holder.three_columns .masonry_gallery_item.rectangle_landscape,
.masonry_gallery_holder.three_columns .masonry_gallery_item.square_big {
  width: 66.66666666%
}

.masonry_gallery_item.square_small {
  width: 25%
}

.masonry_gallery_item.rectangle_landscape,
.masonry_gallery_item.square_big {
  width: 50%
}

.masonry_gallery_item.rectangle_portrait {
  width: 25%
}

.masonry_gallery_holder .masonry_gallery_item .masonry_gallery_image_holder {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0)
}

.masonry_gallery_holder .masonry_gallery_item.rectangle_landscape .masonry_gallery_image_holder img {
  max-height: inherit
}

.masonry_gallery_holder .masonry_gallery_item.rectangle_portrait .masonry_gallery_image_holder img {
  max-width: inherit;
  width: auto
}

.masonry_gallery_item img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  max-height: 100%
}

.masonry_gallery_item .masonry_gallery_item_outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 10px
}

.masonry_gallery_item .masonry_gallery_item_inner {
  position: relative;
  height: 100%
}

.masonry_gallery_item_inner .masonry_gallery_item_content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 10%
}

.masonry_gallery_item.with_button .masonry_gallery_item_inner,
.masonry_gallery_item.with_icon .masonry_gallery_item_inner {
  background-color: rgba(0, 0, 0, .8)
}

.masonry_gallery_item.standard .masonry_gallery_item_inner {
  opacity: 0;
  background-color: rgba(0, 0, 0, .8);
  -webkit-transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out
}

.masonry_gallery_item.standard:hover .masonry_gallery_item_inner {
  opacity: 1
}

.masonry_gallery_item_content h3,
.masonry_gallery_item_icon {
  color: #fff
}

.masonry_gallery_item .masonry_gallery_item_icon {
  color: #fff;
  margin: 0 0 10px
}

.masonry_gallery_item_content h3 {
  text-transform: uppercase;
  padding: 0 0 20px
}

.masonry_gallery_item .masonry_gallery_item_button {
  margin: 20px 0 0
}

.masonry_gallery_item_content .masonry_gallery_item_text {
  color: #818181
}

.blog_slider,
.blog_slides .image_holder {
  position: relative;
  display: block;
  backface-visibility: hidden
}

.blog_slider .blog_slides {
  opacity: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  zoom: 1
}

.blog_slider .image {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  transform: translateZ(0);
  -webkit-transform: translateZ(0)
}

.blog_slider .blog_slides>li {
  background-color: transparent;
  position: relative;
  margin: 0;
  float: left
}

.blog_slider_holder .item_holder {
  position: relative;
  overflow: hidden
}

.blog_slider {
  margin: 0;
  width: 100.2%;
  position: relative;
  zoom: 1
}

.blog_slider.simple_slider {
  width: 100%
}

.blog_slider .blog_slides img {
  width: 100%;
  display: block;
  -webkit-transition: -webkit-transform .15s ease-out;
  -moz-transition: -moz-transform .15s ease-out;
  -ms-transition: -ms-transform .15s ease-out;
  -o-transition: -o-transform .15s ease-out;
  transition: transform .15s ease-out
}

.blog_slider .flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
  direction: ltr
}

.blog_text_holder {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
  background-color: rgba(21, 21, 21, .78)
}

.blog_slides .blog_text_holder_inner {
  color: #fff
}

.blog_text_holder_outer {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  text-align: center
}

.blog_text_holder_inner {
  display: table-cell;
  vertical-align: middle
}

.blog_slides .item:hover .blog_text_holder {
  opacity: .8;
  z-index: 20
}

.blog_slider .blog_slider_post_comments,
.blog_slider_date_holder,
.blog_slides .blog_text_holder_inner .blog_slider_categories a,
.blog_slides .blog_text_holder_inner .blog_slider_title a {
  color: #fff;
  -webkit-transition: color .15s ease;
  -moz-transition: color .15s ease;
  transition: color .15s ease
}

.blog_slider .blog_slider_categories,
.blog_slider .blog_slider_date_holder {
  display: inline-block
}

.blog_slider .caroufredsel-next,
.full_width .section_inner .blog_slider .caroufredsel-next {
  right: 20px
}

body.boxed .blog_slider .caroufredsel-next,
body.boxed .full_width .section_inner .blog_slider .caroufredsel-next {
  right: 0
}

.blog_slider .caroufredsel-prev,
.full_width .section_inner .blog_slider .caroufredsel-prev {
  left: 20px
}

body.boxed .blog_slider .caroufredsel-prev,
body.boxed .full_width .section_inner .blog_slider .caroufredsel-prev {
  left: 0
}

.blog_slider .caroufredsel-next,
.blog_slider .caroufredsel-prev,
.full_width .section_inner .blog_slider .caroufredsel-next,
.full_width .section_inner .blog_slider .caroufredsel-prev {
  border-radius: 50%
}

.blog_slider .flex-container a:active,
.blog_slider .flex-container a:focus,
.blog_slider .flexslider a:active,
.blog_slider .flexslider a:focus {
  outline: 0
}

.blog_text_holder.info_bottom {
  height: auto;
  width: auto;
  bottom: 0;
  margin: 0 10px 10px;
  opacity: 1;
  z-index: 20
}

.blog_slides .item:hover .blog_text_holder.info_bottom {
  opacity: 1
}

.blog_text_holder.info_bottom .blog_text_holder_outer {
  padding: 15px 0
}

.blog_text_holder.info_bottom .blog_text_date_holder {
  display: table-cell;
  vertical-align: middle;
  width: 60px;
  border-right: 1px solid #ebebeb
}

.blog_text_holder.info_bottom .blog_text_holder_inner {
  padding: 0 10px;
  text-align: left
}

.blog_text_holder.info_bottom .blog_slider_date_holder span {
  display: block
}

.blog_slider_holder .blog_slider.simple_slider .blog_text_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%
}

.blog_slider.simple_slider .blog_slides .blog_text_holder_inner {
  color: #303030
}

.blog_slider_holder .blog_slider.simple_slider .blog_text_holder_inner2 {
  position: relative;
  width: 50%;
  margin: 0 auto;
  padding: 8%;
  border: 1px solid #fafafa;
  background-color: rgba(255, 255, 255, .6);
  box-sizing: border-box;
  -webkit-transition: background-color .15s ease-out, border-color .15s ease-out;
  -moz-transition: background-color .15s ease-out, border-color .15s ease-out;
  -ms-transition: background-color .15s ease-out, border-color .15s ease-out;
  -o-transition: background-color .15s ease-out, border-color .15s ease-out;
  transition: background-color .15s ease-out, border-color .15s ease-out
}

.blog_slider_simple_info {
  text-align: center
}

.blog_slider .blog_slider_simple_title {
  margin-bottom: 20px
}

.blog_slider .blog_slider_simple_info a,
.blog_slider .blog_slider_simple_title a {
  -webkit-transition: color .15s ease;
  -moz-transition: color .15s ease;
  transition: color .15s ease
}

.blog_slider_simple_holder .read_more_wrapper {
  margin-top: 20px
}

.blog_slider_simple_info .post_info_item {
  display: inline-block;
  padding: 0 5px
}

.blog_slider_simple_info .post_info_item:not(:first-child):before {
  content: '/';
  position: relative;
  left: -5px
}

.blog_slider_simple_info .post_info_item.date span {
  -webkit-transition: color .15s ease-out;
  -moz-transition: color .15s ease-out;
  -ms-transition: color .15s ease-out;
  -o-transition: color .15s ease-out;
  transition: color .15s ease-out
}

.latest_post_holder {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%
}

.latest_post_holder.date_in_box .latest_post {
  padding-left: 85px
}

.latest_post_holder.minimal .latest_post {
  border-bottom: none;
  padding-bottom: 0
}

.latest_post_holder li:last-child .latest_post {
  border-bottom: none
}

.latest_post_holder .latest_post_date {
  float: left;
  margin-right: 25px;
  width: 59px;
  border-radius: 4px;
  text-align: center;
  margin-top: 5px
}

.latest_post_holder .latest_post_date .post_publish_day {
  font-size: 22px;
  color: #fff;
  height: 39px;
  line-height: 39px;
  background-color: #1abc9c
}

.latest_post_holder .latest_post_date .post_publish_month {
  border: 1px solid #eaeaea;
  background-color: #f4f4f4;
  color: #303030;
  font-size: 12px;
  -webkit-border-bottom-left-radius: 2px;
  -webkit-border-bottom-right-radius: 2px;
  -moz-border-radius-bottomleft: 2px;
  -moz-border-radius-bottomright: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  height: 18px;
  line-height: 18px
}

.latest_post_holder>ul {
  list-style: none;
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0
}

.latest_post_holder>ul>li {
  display: block;
  position: relative;
  padding-top: 15px
}

.latest_post_holder.minimal>ul>li {
  padding-top: 11px
}

.latest_post_holder>ul>li:first-child {
  padding-top: 0
}

.latest_post_holder.boxes>ul,
.latest_post_holder.dividers>ul {
  clear: both
}

.latest_post_holder.boxes>ul>li,
.latest_post_holder.dividers>ul>li {
  padding: 0;
  float: left;
  border: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 2% 0 0;
  background-color: #fff
}

.latest_post_holder.boxes.two_columns>ul>li,
.latest_post_holder.dividers.two_columns>ul>li {
  width: 49%
}

.latest_post_holder.boxes.three_columns>ul>li,
.latest_post_holder.dividers.three_columns>ul>li {
  width: 32%
}

.latest_post_holder.boxes.four_columns>ul>li,
.latest_post_holder.dividers.four_columns>ul>li {
  width: 23.5%
}

.latest_post_holder.boxes.four_columns>ul>li:nth-child(4n),
.latest_post_holder.boxes.three_columns>ul>li:nth-child(3n),
.latest_post_holder.boxes.two_columns>ul>li:nth-child(2n),
.latest_post_holder.dividers.four_columns>ul>li:nth-child(4n),
.latest_post_holder.dividers.three_columns>ul>li:nth-child(3n),
.latest_post_holder.dividers.two_columns>ul>li:nth-child(2n) {
  margin-right: 0
}

.latest_post_holder.boxes:not(.one_row)>ul>li,
.latest_post_holder.dividers:not(.one_row)>ul>li {
  margin-bottom: 25px
}

.latest_post_holder.boxes>ul>li .latest_post {
  padding: 19px 20px 10px;
  border-top: 0
}

.latest_post_holder.boxes>ul>li .latest_post p.excerpt {
  margin: 10px 0 9px
}

.blog_holder.blog_large_image_with_dividers .post_text_holder .blog_column2>h2,
.latest_post_holder.dividers .latest_post_title {
  margin-bottom: 20px
}

.latest_post_holder.boxes>ul>li .latest_post .post_infos>a,
.latest_post_holder.boxes>ul>li .latest_post .post_infos>span {
  display: inline-block
}

.latest_post_holder.boxes>ul>li .latest_post .latest-vert-separator {
  margin-left: 3px
}

.latest_post_holder.boxes .boxes_image {
  overflow: hidden
}

.latest_post_holder.boxes .boxes_image a {
  display: block;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .3s ease-in-out;
  -moz-transition: -moz-transform .3s ease-in-out;
  -ms-transition: -ms-transform .3s ease-in-out;
  -o-transition: -o-transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  backface-visibility: hidden
}

.latest_post_holder.boxes .boxes_image a img {
  vertical-align: top
}

.latest_post_holder.boxes .boxes_image a:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1)
}

.latest_post_holder.dividers .latest_post_date,
.latest_post_holder.dividers .latest_post_text_inner {
  display: table-cell
}

.latest_post_holder.dividers .latest_post_inner {
  margin: 35px 0 !important
}

.latest_post_holder.dividers .boxes_image img {
  display: inline-block;
  vertical-align: middle
}

.latest_post_holder.dividers .latest_post_date {
  margin: 0 15px 0 0;
  border-right: 1px solid #ebebeb;
  width: 50px
}

.latest_post_holder.dividers .latest_post_date .latest_post_day {
  font-size: 15px;
  font-weight: 600;
  color: #303030
}

.latest_post_holder.dividers .post_infos {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #ebebeb
}

.latest_post_holder.dividers .post_infos>a:not(:last-child):after,
.latest_post_holder.dividers .post_infos>span:not(:last-child)>a:after {
  content: '/';
  margin: 0 3px
}

.latest_post_holder.dividers .latest_post_text_inner .post_infos a {
  margin-right: 0
}

.latest_post_holder>ul>li .date_hour_holder i {
  margin-right: 8px
}

.latest_post,
.latest_post a img,
.latest_post>a {
  display: block;
  position: relative
}

.latest_post_image {
  width: 95px;
  float: left
}

.latest_post_image img {
  width: 100%;
  float: left
}

.latest_post_text {
  display: block;
  width: auto
}

.latest_post_holder.image_in_box .latest_post_text {
  padding: 0 0 0 116px
}

.latest_post_holder.minimal .latest_post_inner {
  margin: 0 !important
}

.latest_post_inner {
  margin: 0 0 10px !important
}

.latest_post_holder.image_in_box .latest_post_text_inner {
  display: table-cell;
  vertical-align: middle
}

.latest_post_holder.image_in_box .latest_post_inner {
  display: table;
  width: 100%;
  margin: 0 !important
}

.latest_post_inner .post_infos {
  display: block;
  font-weight: 500
}

.latest_post_inner .post_infos a {
  color: #303030;
  margin: 0 7px 0 0
}

.latest_post_inner .post_infos a.post_comments {
  margin: 0
}

.latest_post_inner .post_infos a:hover {
  color: #1abc9c
}

.latest_post_holder.minimal .latest_post_inner .post_infos {
  margin: 0 0 3px
}

.latest_post_holder.image_in_box .latest_post_text .latest_post_title {
  margin: -3px 0 2px
}

.latest_post_holder.image_in_box .latest_post_text .excerpt {
  margin: 0 0 8px
}

.latest_post_holder .post_infos .dots {
  padding: 0 3px
}

.latest_post_holder .post_infos .dots i {
  font-size: 3px;
  vertical-align: middle
}

.latest_post_holder.image_on_the_left_boxed>ul>li:not(:first-child) {
  padding-top: 28px
}

.latest_post_holder.image_on_the_left_boxed .latest_post {
  background-color: #fff;
  padding: 25px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  vertical-align: middle
}

.latest_post_holder.image_on_the_left_boxed .latest_post_text_inner {
  display: table-cell;
  vertical-align: middle
}

.latest_post_holder.image_on_the_left_boxed .latest_post_inner {
  display: table;
  width: 100%;
  margin: 0 !important
}

.latest_post_holder.image_on_the_left_boxed .qode_share {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 28px;
  height: 28px
}

.latest_post_holder.image_on_the_left_boxed .qode_share a:hover {
  opacity: .8
}

.latest_post_holder.image_on_the_left_boxed .qode_share .social_share_title {
  text-indent: -10000px;
  position: relative;
  display: block;
  width: 28px;
  height: 28px
}

.latest_post_holder.image_on_the_left_boxed .qode_share .social_share_title:before {
  font-size: 18px;
  color: #e0dddd;
  display: inline-block;
  position: absolute;
  right: 0;
  left: 0;
  content: "\e0a0";
  font-family: ElegantIcons;
  overflow: hidden;
  font-weight: 400;
  text-indent: 0
}

.latest_post_holder.image_on_the_left_boxed .date_hour_holder {
  background-color: #1abc9c;
  padding: 2px 4px;
  color: #161414;
  font-size: 12px
}

.latest_post_holder.image_on_the_left_boxed .latest_post_image {
  width: auto;
  max-width: 190px
}

.latest_post_holder.image_on_the_left_boxed .latest_post_text {
  padding: 0 0 0 215px
}

.latest_post_holder.image_on_the_left_boxed .latest_post_text .latest_post_title {
  margin: 15px 0
}

.latest_post_holder.image_on_the_left_boxed .latest_post_text .excerpt {
  margin: 0 0 8px
}

.latest_post_holder.image_on_the_left_boxed .post_infos a {
  margin-right: 10px
}

.latest_post_holder.image_on_the_left_boxed .featured .latest_post {
  padding: 50px 30px 30px
}

.latest_post_holder.image_on_the_left_boxed .featured .latest_post_title {
  font-size: 36px;
  padding: 0 50px;
  margin: 20px 0 35px
}

.latest_post_holder.image_on_the_left_boxed .featured .latest_post_text {
  padding: 0;
  text-align: center
}

.latest_post_holder.image_on_the_left_boxed .featured .latest_post_image {
  float: none;
  max-width: 100%;
  width: 100%;
  padding-bottom: 30px
}

.latest_post_holder.image_on_the_left_boxed .featured .excerpt,
.latest_post_holder.image_on_the_left_boxed .featured .post_infos {
  text-align: left;
  margin-bottom: 25px
}

.latest_post_holder.image_on_the_left_boxed .featured .read_more {
  font-style: italic;
  position: relative;
  float: left;
  padding-left: 10px
}

.latest_post_holder.image_on_the_left_boxed .featured .read_more:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  background-color: #1abc9c
}

.latest_post_two_holder,
.latest_post_two_holder>ul>li {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  position: relative
}

.latest_post_two_holder>ul {
  position: relative;
  list-style: none;
  margin: 0 -16px;
  padding: 0;
  clear: both
}

.latest_post_two_holder>ul>li {
  float: left;
  margin: 0 0 32px;
  padding: 0 16px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.latest_post_two_holder.two_columns>ul>li {
  width: 50%
}

.latest_post_two_holder.three_columns>ul>li {
  width: 33.33%
}

.latest_post_two_holder.four_columns>ul>li {
  width: 25%
}

.latest_post_two_holder.four_columns>ul>li:nth-child(4n+1),
.latest_post_two_holder.three_columns>ul>li:nth-child(3n+1),
.latest_post_two_holder.two_columns>ul>li:nth-child(2n+1) {
  clear: both
}

.latest_post_two_holder .latest_post_two_image {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%
}

.latest_post_two_holder .latest_post_two_image a,
.latest_post_two_holder .latest_post_two_image img {
  display: block
}

.latest_post_two_holder .latest_post_two_inner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  background-color: #fff
}

.latest_post_two_holder .latest_post_two_info,
.latest_post_two_holder .latest_post_two_text {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.latest_post_two_holder .latest_post_two_text {
  padding: 51px 33px 59px
}

.latest_post_two_holder .latest_post_two_text .separator.small {
  width: 24px;
  margin: 12px 0 26px
}

.latest_post_two_holder .latest_post_two_info {
  padding: 28px 33px 38px;
  border-top: 1px solid #eaeaea
}

.latest_post_two_holder .latest_post_two_info_inner {
  position: relative;
  display: table;
  width: 100%;
  height: 100%
}

.latest_post_two_holder .latest_post_two_info_inner>* {
  position: relative;
  display: table-cell;
  vertical-align: top;
  font-size: 12px
}

.latest_post_two_holder .latest_post_two_info_inner img {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px 0 0
}

.latest_post_two_holder .latest_post_two_info_inner .post_info_author_name {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 30px
}

.latest_post_two_holder .latest_post_two_info_inner .post_info_date {
  text-align: right;
  line-height: 30px
}

.q_masonry_blog {
  margin-bottom: 50px;
  transition: none !important;
  overflow: visible !important;
  opacity: 0;
  filter: alpha(opacity=0)
}

.q_masonry_blog .q_masonry_blog_grid_sizer,
.q_masonry_blog article {
  width: 31.2%
}

.q_masonry_blog .blog_holder_grid_gutter {
  width: 3.2%
}

.full_width .q_masonry_blog .q_masonry_blog_grid_sizer,
.full_width .q_masonry_blog article {
  width: 18.7%
}

.full_width .grid_section .q_masonry_blog article,
.full_width .grid_section .q_masonry_blog_grid_sizer,
.full_width .qode_elementor_container_inner .q_masonry_blog article,
.full_width .qode_elementor_container_inner .q_masonry_blog_grid_sizer {
  width: 31.2%
}

.full_width .q_masonry_blog .q_masonry_blog_grid_gutter {
  width: 1.6%
}

.full_width .grid_section .q_masonry_blog .q_masonry_blog_grid_gutter,
.q_masonry_blog .q_masonry_blog_grid_gutter {
  width: 3.2%
}

.two_columns_25_75 .q_masonry_blog .q_masonry_blog_grid_sizer,
.two_columns_25_75 .q_masonry_blog article,
.two_columns_33_66 .q_masonry_blog .q_masonry_blog_grid_sizer,
.two_columns_33_66 .q_masonry_blog article,
.two_columns_66_33 .q_masonry_blog .q_masonry_blog_grid_sizer,
.two_columns_66_33 .q_masonry_blog article,
.two_columns_75_25 .q_masonry_blog .q_masonry_blog_grid_sizer,
.two_columns_75_25 .q_masonry_blog article {
  width: 48%
}

.two_columns_25_75 .q_masonry_blog .q_masonry_blog_grid_gutter,
.two_columns_33_66 .q_masonry_blog .q_masonry_blog_grid_gutter,
.two_columns_66_33 .q_masonry_blog .q_masonry_blog_grid_gutter,
.two_columns_75_25 .q_masonry_blog .q_masonry_blog_grid_gutter {
  width: 4%
}

.full_width .grid_section .q_masonry_blog article,
.q_masonry_blog article {
  margin: 0 0 20px;
  padding: 0;
  text-align: left;
  vertical-align: top;
  z-index: 100;
  border: 0;
  background-color: #fff;
  float: left;
  display: block
}

.full_width .q_masonry_blog article {
  margin-left: .5%;
  margin-right: .5%
}

.q_masonry_blog article.format-link:hover,
.q_masonry_blog article.format-quote:hover {
  background-color: #1abc9c
}

.q_masonry_blog article.format-link .q_masonry_blog_post_title,
.q_masonry_blog article.format-quote .q_masonry_blog_post_title {
  padding: 0 0 0 60px
}

.q_masonry_blog article.format-link .q_masonry_blog_post_text i.link_mark,
.q_masonry_blog article.format-quote .q_masonry_blog_post_text i.qoute_mark {
  margin: 7px 0 0;
  color: silver;
  font-size: 36px
}

.q_masonry_blog article.format-link .q_masonry_blog_post_text p,
.q_masonry_blog article.format-quote .q_masonry_blog_post_text p {
  font-size: 21px;
  line-height: 35px;
  color: #303030
}

.q_masonry_blog article.format-link:hover .q_masonry_blog_post_info,
.q_masonry_blog article.format-link:hover .q_masonry_blog_post_info a,
.q_masonry_blog article.format-link:hover .q_masonry_blog_post_text i.link_mark,
.q_masonry_blog article.format-link:hover .q_masonry_blog_post_text p a,
.q_masonry_blog article.format-quote:hover .q_masonry_blog_post_info,
.q_masonry_blog article.format-quote:hover .q_masonry_blog_post_info a,
.q_masonry_blog article.format-quote:hover .q_masonry_blog_post_text .quote_author,
.q_masonry_blog article.format-quote:hover .q_masonry_blog_post_text i.qoute_mark,
.q_masonry_blog article.format-quote:hover .q_masonry_blog_post_text p a {
  color: #fff
}

.q_masonry_blog article:hover {
  z-index: 200
}

.q_masonry_blog article .q_masonry_blog_post_image {
  margin: 0;
  width: auto
}

.q_masonry_blog article .q_masonry_blog_post_image img {
  vertical-align: middle
}

.q_masonry_blog article .q_masonry_blog_post_info {
  margin: 15px 0 0;
  color: #303030
}

.q_masonry_blog article .q_masonry_blog_post_info a {
  color: #bebebe
}

.q_masonry_blog article .q_masonry_blog_post_info a:hover {
  color: #1abc9c
}

.q_masonry_blog article .quote_author {
  font-family: inherit;
  font-weight: 400;
  display: block;
  font-size: 21px;
  line-height: 35px;
  color: silver
}

.q_masonry_blog article.format-link .q_masonry_blog_post_info,
.q_masonry_blog article.format-quote .q_masonry_blog_post_info {
  margin: 0 0 20px
}

.q_masonry_blog article .q_masonry_blog_post_text {
  padding: 20px 15px
}

.q_masonry_blog article h5 {
  margin: 0 0 10px
}

.q_masonry_blog article .mejs-controls div.mejs-horizontal-volume-slider {
  margin: 0 10px 0 0
}

.q_masonry_blog article .flexslider {
  margin-bottom: 0
}

.blog_holder article {
  display: inline-block;
  width: 100%;
  margin: 0 0 60px
}

.blog_holder.masonry article,
.blog_holder.masonry_full_width article {
  display: block;
  float: left
}

.blog_holder.blog_large_image_simple article {
  margin: 0 0 23px
}

.blog_holder.blog_single article {
  margin: 0
}

.blog_holder.blog_small_image article {
  margin: 0 0 32px
}

.single-post .blog_single p {
  margin-bottom: 22px
}

.single-post .blog_single p:last-child {
  margin-bottom: 0
}

.blog_single.blog_holder article .post_text .post_text_inner {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  background-color: transparent
}

.blog_single.blog_holder article.format-link .post_text .post_text_inner,
.blog_single.blog_holder article.format-quote .post_text .post_text_inner {
  padding: 23px 23px 35px;
  background-color: #fff;
  margin: 0 0 30px
}

.blog_holder article .post_content_holder {
  width: 100%
}

.blog_holder article .post_image,
.blog_holder article .post_image img,
.blog_holder article .post_image>a {
  position: relative;
  width: 100%;
  display: block
}

.blog_holder article .post_text .post_text_inner {
  padding: 23px 23px 35px;
  background-color: #fff
}

.blog_holder.blog_large_image_simple article .post_text .post_text_inner {
  background-color: transparent;
  text-align: center;
  padding: 45px 195px
}

.blog_holder.masonry article .post_text .post_text_inner,
.blog_holder.masonry_full_width article .post_text .post_text_inner {
  padding: 17px 15px 18px;
  background-color: #fff
}

.blog_large_image_simple .minimalist_date {
  padding-bottom: 9px;
  color: #1abc9c
}

.blog_large_image_simple .separator.small {
  margin-top: 22px
}

.blog_holder article .post_text h5 {
  margin: 0 0 10px
}

.blog_holder article .post_text h2 .date {
  color: #bebebe
}

.blog_holder article .post_info {
  display: inline-block;
  width: 100%;
  margin: 0 0 18px;
  color: #bebebe;
  font-weight: 500
}

.blog_holder article .post_info a {
  color: #bebebe
}

.blog_holder article .post_info a:hover {
  color: #1abc9c
}

.blog_holder article.format-link .post_info,
.blog_holder article.format-quote .post_info {
  margin: 0 0 15px
}

.blog_holder article .post_info .dots {
  padding: 0 7px 0 4px
}

.blog_holder article .post_info .dots i {
  font-size: 3px;
  vertical-align: middle
}

.blog_holder article .post_more {
  margin: 30px 0 0
}

.blog_holder article .video .mobile-video-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10
}

.blog_holder article .video {
  position: relative
}

.blog_holder article .video .video-wrap {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 10
}

.blog_holder article .video .video-wrap .mejs-poster {
  background-size: cover !important;
  -moz-background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  width: 100% !important;
  height: 100% !important
}

.blog_holder article .video .video-wrap .mejs-container {
  background-color: transparent !important;
  background-image: none !important;
  height: 100% !important;
  width: 100% !important;
  overflow: hidden
}

.blog_holder article .video .video-wrap .mejs-mediaelement {
  background: 0 0 !important;
  border: 0 !important
}

.blog_holder article .video .video-wrap .mejs-container .mejs-poster img {
  max-width: none !important;
  width: 100% !important
}

.blog_holder article .mejs-container .mejs-controls {
  visibility: visible !important
}

.blog_holder article .mejs-controls .mejs-volume-button .mejs-volume-slider,
.blog_holder article .post_image .mejs-poster img {
  display: none !important
}

.blog_holder article.format-gallery .flexslider {
  margin: 0;
  overflow: hidden
}

.portfolio_single .flexslider {
  overflow: hidden
}

.blog_holder.blog_small_image article .post_image,
.blog_holder.blog_small_image article .post_text {
  width: 50%;
  float: left;
  margin: 0
}

.blog_holder.blog_small_image article .post_text .post_text_inner {
  padding: 23px
}

.grid2 .blog_holder.blog_small_image article .post_comments {
  margin-left: 0
}

.blog_holder article .post_description {
  margin: 2px 0 10px
}

.blog_holder.blog_large_image_with_dividers .post_text_holder {
  display: table;
  width: 100%;
  height: 100%
}

.blog_holder.blog_large_image_with_dividers article .post_text .post_text_inner {
  padding: 35px 0
}

.blog_holder.blog_large_image_with_dividers .post_text_holder .blog_column1 {
  display: table-cell;
  vertical-align: top;
  text-align: center;
  width: 70px
}

.blog_holder.blog_large_image_with_dividers .post_text_holder .blog_column2 {
  display: table-cell;
  padding: 0 35px 0 20px
}

.blog_holder.blog_large_image_with_dividers .post_text_holder .blog_column1 .date {
  padding: 0 20px;
  border-right: 1px solid #ebebeb
}

.blog_holder.blog_large_image_with_dividers .post_text_holder .blog_column1 .date>span {
  display: block
}

.blog_holder.blog_large_image_with_dividers .post_text_holder .blog_column1 .date_day {
  font-size: 21px;
  font-weight: 600;
  color: #303030;
  padding-bottom: 5px
}

.blog_holder.blog_large_image_with_dividers article .post_info {
  display: table;
  width: 100%;
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
  color: #303030;
  margin-bottom: 0
}

.blog_holder.blog_large_image_with_dividers article:not(.format-quote):not(.format-link) .post_info {
  margin-top: 30px
}

.blog_holder.blog_large_image_with_dividers article .post_info .post_info_left {
  display: table-cell
}

.blog_holder.blog_large_image_with_dividers article .post_info .post_info_right {
  display: table-cell;
  text-align: right
}

.blog_holder.blog_large_image_with_dividers article .post_info a {
  display: inline-block;
  color: #303030
}

.blog_holder.blog_large_image_with_dividers article .post_info a:hover {
  color: #1abc9c
}

.blog_holder.blog_large_image_with_dividers article .post_info .post_info_right>a:not(:first-child):before,
.blog_holder.blog_large_image_with_dividers article .post_info .post_info_right>div:not(:first-child):before {
  content: '/ '
}

.blog_holder.blog_large_image_with_dividers article.format-link .post_text .post_title,
.blog_holder.blog_large_image_with_dividers article.format-quote .post_text .post_title {
  padding: 0
}

.blog_holder.blog_large_image_with_dividers article.format-link .post_text .post_title a {
  color: #1abc9c;
  text-decoration: underline
}

.blog_holder.blog_large_image_with_dividers article.format-link .post_text:hover .post_title a {
  color: #fff
}

.blog_holder.blog_large_image_with_dividers article.format-quote .post_text .quote_author {
  margin-top: 30px;
  color: #1abc9c
}

.blog_holder.blog_large_image_with_dividers article.format-link .post_info,
.blog_holder.blog_large_image_with_dividers article.format-quote .post_info {
  margin-top: 20px
}

.blog_holder.blog_large_image_with_dividers article.format-link .post_text span.link_mark,
.blog_holder.blog_large_image_with_dividers article.format-quote .post_text span.qoute_mark {
  font-size: 28px;
  padding: 0 18px;
  border-right: 1px solid #ebebeb;
  color: #303030
}

.latest_post_inner .post_comments i {
  font-size: 16px;
  color: #adadad;
  padding: 0 8px 0 0
}

.blog_holder article .post_description .post_comments:hover,
.blog_holder article .post_description a:hover,
.latest_post_inner .post_comments:hover i {
  color: #1abc9c
}

.blog_holder article .post_info .post_info_left a.post_author_avatar {
  display: inline-block;
  float: left;
  padding: 0 10px 0 0
}

.blog_holder article .post_info .post_info_left a.post_author {
  display: inline-block;
  line-height: 64px;
  float: left;
}

.post_author_avatar img {
  border-radius: 60px;
  border: 2px solid #eaeaea
}

.blog_holder.masonry article .post_description,
.blog_holder.masonry_full_width article .post_description {
  margin: 0 0 4px
}

.blog_like {
  display: inline-block
}

.blog_like a {
  display: block;
  line-height: 14px
}

.blog_like a i {
  color: #adadad;
  font-size: 16px
}

.blog_holder.blog_large_image article .blog_like a i,
.blog_holder.blog_single article .blog_like a i,
.blog_holder.masonry article .blog_like a i,
.blog_holder.masonry_full_width article .blog_like a i {
  display: inline-block
}

.blog_like a.liked i,
.blog_like a:hover i,
.blog_like a:hover span {
  color: #1abc9c
}

.blog_like span {
  padding: 0 0 0 4px
}

.blog_holder.blog_large_image article .blog_like span,
.blog_holder.blog_single article .blog_like span,
.blog_holder.masonry article .blog_like span,
.blog_holder.masonry_full_width article .blog_like span {
  display: inline-block;
  padding: 0 0 0 5px
}

.blog_holder article .post_info .qbutton.dark {
  text-transform: none
}

.blog_share {
  display: inline-block
}

.blog_holder article.format-link .post_text i.link_mark,
.blog_holder article.format-quote .post_text i.qoute_mark {
  margin: 7px 0 0;
  color: silver;
  font-size: 36px
}

.blog_large_image_simple.blog_holder article.format-link .post_text i.link_mark,
.blog_large_image_simple.blog_holder article.format-quote .post_text i.qoute_mark {
  margin: 20px 0;
  float: none
}

.blog_holder article.format-link .post_text .post_title,
.blog_holder article.format-quote .post_text .post_title {
  padding: 0 0 0 60px
}

.blog_large_image_simple.blog_holder article.format-link .post_text .post_title,
.blog_large_image_simple.blog_holder article.format-quote .post_text .post_title {
  padding: 0
}

.blog_holder article.format-link .post_text .post_title p,
.blog_holder article.format-quote .post_text .post_title p {
  font-size: 21px;
  line-height: 35px;
  color: #303030
}

.blog_holder article.format-quote .post_text .quote_author {
  font-family: inherit;
  font-weight: 400;
  display: block;
  font-size: 21px;
  line-height: 35px;
  color: silver
}

body:not(.search-results) .blog_holder article.format-link .post_text:hover .post_text_inner,
body:not(.search-results) .blog_holder article.format-quote .post_text:hover .post_text_inner {
  background-color: #1abc9c !important;
  border-color: #1abc9c !important
}

.blog_holder.blog_large_image_with_dividers article.format-link .post_text:hover span.link_mark,
.blog_holder.blog_large_image_with_dividers article.format-quote .post_text:hover .quote_author,
.blog_holder.blog_large_image_with_dividers article.format-quote .post_text:hover span.qoute_mark,
.blog_holder.blog_single article.format-link .post_text:hover p,
.blog_holder.blog_single article.format-quote .post_text:hover p,
.blog_holder.masonry article.format-link .post_text:hover .post_info,
.blog_holder.masonry article.format-link .post_text:hover .post_info a,
.blog_holder.masonry article.format-quote .post_text:hover .post_info,
.blog_holder.masonry article.format-quote .post_text:hover .post_info a,
.blog_holder.masonry_full_width article.format-link .post_text:hover .post_info,
.blog_holder.masonry_full_width article.format-link .post_text:hover .post_info a,
.blog_holder.masonry_full_width article.format-quote .post_text:hover .post_info,
.blog_holder.masonry_full_width article.format-quote .post_text:hover .post_info a,
.blog_large_image_simple article.format-link .post_text:hover .minimalist_date,
.blog_large_image_simple article.format-quote .post_text:hover .minimalist_date,
body:not(.search-results) .blog_holder article.format-link .blog_like a:hover span,
body:not(.search-results) .blog_holder article.format-link .post_text:hover .post_info,
body:not(.search-results) .blog_holder article.format-link .post_text:hover .post_info a,
body:not(.search-results) .blog_holder article.format-link .post_text:hover i.link_mark,
body:not(.search-results) .blog_holder article.format-link .post_text:hover p a,
body:not(.search-results) .blog_holder article.format-quote .blog_like a:hover span,
body:not(.search-results) .blog_holder article.format-quote .post_text:hover .post_info,
body:not(.search-results) .blog_holder article.format-quote .post_text:hover .post_info a,
body:not(.search-results) .blog_holder article.format-quote .post_text:hover .quote_author,
body:not(.search-results) .blog_holder article.format-quote .post_text:hover i.qoute_mark,
body:not(.search-results) .blog_holder article.format-quote .post_text:hover p a {
  color: #fff
}

.blog_holder.blog_single article h2 {
  display: block
}

.blog_load_more_button_holder {
  text-align: center;
  padding: 0 0 40px
}

.blog_load_more_button_loading {
  display: none
}

.single_links_pages {
  margin: 50px 0 0;
  text-align: center;
  display: block;
  text-decoration: none
}

.blog_large_image .single_links_pages {
  margin: 10px 0 0;
  text-align: left
}

.single_links_pages span {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  margin: 0 11px 0 0;
  text-align: center;
  color: #303030;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border: 2px solid #e3e3e3;
  background-color: #e3e3e3;
  outline: 0;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  -webkit-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.single_links_pages a span {
  color: #b4b4b4;
  background-color: transparent;
  border-color: #e5e5e5
}

.single_links_pages a:hover span {
  background-color: #e3e3e3;
  border-color: #e3e3e3;
  color: #303030
}

.author_description {
  display: block;
  position: relative;
  margin: 34px 0 0;
  background-color: #fff;
  padding: 23px
}

.author_description_inner {
  display: block
}

.author_description_inner .image {
  display: block;
  width: 75px;
  height: 75px;
  float: left;
  border-radius: 75px;
  overflow: hidden
}

.author_text_holder {
  padding: 0 0 0 100px;
  min-height: 100px;
  position: relative
}

.author_text_holder .author_name {
  display: block;
  margin: 0 0 4px;
}

.author_text_holder .author_email {
  display: block;
  margin: 0 0 6px;
}

.single_tags {
  margin: 26px 0 0;
}

.single_tags h5 {
  display: inline-block;
}

.widget .tagcloud a,
.widget .wp-block-tag-cloud a {
  display: inline-block;
  font-size: 13px !important;
}

.widget .tagcloud a:after,
.widget .wp-block-tag-cloud a:after {
  content: ",";
}

.widget .tagcloud a:last-child:after,
.widget .wp-block-tag-cloud a:last-child:after {
  content: "";
}

.widget.qode_button_widget {
  display: inline-block;
}

.blog_social_and_comments {
  display: inline-block;
  width: 100%;
  margin: 0 0 20px;
}

.comment_number_holder {
  display: inline-block;
  float: left;
}

.blog_single_social {
  display: inline-block;
  float: right;
}

.blog_single_social .blog_like {
  float: right;
  margin: 0 0 0 15px
}

.comment_holder {
  padding: 28px 0 38px;
  margin: 0
}

.comment_holder .comment.pingback-comment .text {
  padding-left: 20px
}

.comment_number_holder .comment_number {
  color: #000
}

.comment_holder .comment_number {
  margin: 0 0 20px
}

.comment_number .comment_number_inner i {
  position: relative;
  top: -1px;
  padding: 0 10px 0 0
}

.comment_holder ul.comment-list {
  list-style: none;
  position: relative;
  z-index: 150;
  padding: 0
}

.comment_holder .comment-list {
  margin: 0
}

.comment_holder .comment-list li {
  display: inline-block;
  position: relative;
  width: 100%
}

.comment_holder .comment-list li ul.children li:last-child {
  padding: 0
}

.comment_holder .comment-list>li {
  margin: 0 0 18px
}

.comment_holder .comment-list>li:last-child {
  margin: 0
}

.comment_holder .comment {
  padding: 23px;
  background-color: #fff
}

.comment_holder .comment .image {
  display: block;
  width: 75px;
  height: 75px;
  float: left;
  border-radius: 75px;
  overflow: hidden
}

.comment_holder .comment .text {
  padding: 0 0 0 100px;
  min-height: 80px;
  position: relative
}

.comment_holder .comment .text .name {
  display: block;
  color: #303030
}

.comment_holder .comment .text .comment-reply-link,
.comment_holder .comment .text .replay {
  color: #1abc9c;
  font-size: 9px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 0 0 5px
}

.comment_holder .comment .text .text_holder {
  display: block;
  margin: 3px 0 0
}

.comment_holder .comment .logged-in-as {
  margin: 10px 0
}

.comment_holder .comment .form-submit {
  margin: 0 0 20px
}

.comment_holder .comment-list li ul.children {
  margin: 18px 0 0;
  padding: 0 0 0 70px
}

.comment_holder .comment-list li ul.children li {
  margin: 0;
  border-bottom: none
}

#respond h3 {
  margin: 0 0 15px
}

#respond h3.comment-reply-title {
  margin: 0
}

#respond small {
  display: block;
  margin: 0 0 12px;
  position: relative
}

.comment_holder .comment .comment-respond {
  margin-top: 20px
}

#respond input[type=text],
#respond input[type=email],
#respond textarea,
.contact_form input[type=text],
.contact_form textarea {
  width: 100%;
  margin: 0 0 20px;
  padding: 15px 12px;
  border: 0;
  border-radius: 0;
  outline: 0;
  resize: none;
  font-size: 13px;
  line-height: 17px;
  background-color: #fff;
  color: #818181;
  font-family: Raleway, serif;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none
}

#respond textarea {
  padding: 12px;
  vertical-align: middle
}

.comment_holder #respond input[type=text],
.comment_holder #respond textarea {
  background-color: #fff
}

.comment_holder .comment #respond input[type=text],
.comment_holder .comment #respond textarea {
  background-color: #f6f6f6
}

.comment_holder #respond textarea {
  margin: 0 0 12px
}

div.comment_form {
  display: inline-block;
  margin: 0 0 30px;
  width: 100%
}

div.comment_form form p.form-submit,
div.comment_holder .comment p.form-submit {
  margin: 0;
  text-align: right
}

div.comment_form form p.form-submit .success p {
  margin: 10px 0 0
}

div.comment_form form p.logged-in-as {
  margin: 0 0 17px
}

#cancel-comment-reply-link {
  margin: 0;
  display: inline-block;
  width: 100%
}

div.comment_form .comment-form-cookies-consent {
  margin: 12.5px 0
}

div.comment_form .comment-form-cookies-consent input {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
}

body.page-template-blog-masonry-full-width-php .content .full_width {
  padding: 0 20px 25px 45px;
}

.content .full_width {
  padding-left: 310px;
}

.blog_holder.masonry,
.blog_holder.masonry_full_width {
  margin-bottom: 20px;
  transition: none !important;
  overflow: visible !important;
  opacity: 0;
  filter: alpha(opacity=0);
}

#infscr-loading {
  position: absolute;
  bottom: -15px;
  left: 50%;
}

.blog_infinite_scroll_button {
  display: none;
}

.blog_holder.masonry article,
.blog_holder.masonry_full_width article {
  margin: 0 0 30px;
  padding: 0;
  text-align: left;
  vertical-align: top;
  z-index: 100;
  border: 0;
}

.blog_holder.masonry .blog_holder_grid_sizer,
.blog_holder.masonry article {
  width: 31.1%;
}

.blog_holder.masonry .blog_holder_grid_gutter {
  width: 3.2%;
}

.blog_holder.masonry_full_width .blog_holder_grid_sizer,
.blog_holder.masonry_full_width article {
  width: 18.7%;
}

.blog_holder.masonry_full_width .blog_holder_grid_gutter {
  width: 1.6%
}

.two_columns_25_75 .blog_holder.masonry .blog_holder_grid_sizer,
.two_columns_25_75 .blog_holder.masonry article,
.two_columns_33_66 .blog_holder.masonry .blog_holder_grid_sizer,
.two_columns_33_66 .blog_holder.masonry article,
.two_columns_66_33 .blog_holder.masonry .blog_holder_grid_sizer,
.two_columns_66_33 .blog_holder.masonry article,
.two_columns_75_25 .blog_holder.masonry .blog_holder_grid_sizer,
.two_columns_75_25 .blog_holder.masonry article {
  width: 48%
}

.two_columns_25_75 .blog_holder.masonry .blog_holder_grid_gutter,
.two_columns_33_66 .blog_holder.masonry .blog_holder_grid_gutter,
.two_columns_66_33 .blog_holder.masonry .blog_holder_grid_gutter,
.two_columns_75_25 .blog_holder.masonry .blog_holder_grid_gutter {
  width: 4%
}

.blog_holder.masonry article:hover {
  z-index: 200
}

.blog_holder.masonry article .post_image {
  margin: 0;
  width: auto
}

.blog_holder.masonry article .post_info,
.blog_holder.masonry_full_width article .post_info {
  margin: 15px 0 0;
  color: #303030
}

.blog_holder.masonry article.format-link .post_info,
.blog_holder.masonry article.format-quote .post_info,
.blog_holder.masonry_full_width article.format-link .post_info,
.blog_holder.masonry_full_width article.format-quote .post_info {
  margin: 0 0 20px
}

.blog_holder.masonry article .post_text .post_text_innet,
.blog_holder.masonry_full_width article .post_text .post_text_innet {
  padding: 20px 15px
}

.blog_holder.masonry article.format-link .post_text,
.blog_holder.masonry article.format-quote .post_text,
.blog_holder.masonry_full_width article.format-link .post_text,
.blog_holder.masonry_full_width article.format-quote .post_text {
  border: none;
  padding: 0
}

.blog_holder article.format-quote h3 {
  margin: 0 0 10px;
  line-height: 35px
}

.blog_holder.masonry article h4,
.blog_holder.masonry_full_width article h4 {
  margin: 0 0 4px
}

.blog_holder.masonry article h3 a {
  font-weight: 300
}

.blog_holder.masonry article .mejs-controls div.mejs-horizontal-volume-slider,
.blog_holder.masonry_full_width article .mejs-controls div.mejs-horizontal-volume-slider {
  margin: 0 10px 0 0
}

.blog_holder.blog_masonry_date_in_image .time {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  margin: 5px;
  padding: 5px 13px;
  background-color: #fff
}

.blog_holder.blog_masonry_date_in_image .time span {
  display: block
}

.blog_holder.blog_masonry_date_in_image .time .time_day {
  color: #303030;
  font-size: 19px;
  font-weight: 600
}

.blog_holder.blog_masonry_date_in_image article .post_text .post_text_inner {
  text-align: center;
  padding: 35px 5px
}

.blog_holder.blog_masonry_date_in_image article.format-link .post_text .post_text_inner,
.blog_holder.blog_masonry_date_in_image article.format-quote .post_text .post_text_inner {
  padding: 35px 15px
}

.blog_holder.blog_masonry_date_in_image article.format-link .post_text:hover .post_text_inner,
.blog_holder.blog_masonry_date_in_image article.format-quote .post_text:hover .post_text_inner {
  -webkit-transition: background-color .2s ease-in-out;
  -moz-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out
}

.blog_holder.blog_masonry_date_in_image article .post_text h5 {
  margin-bottom: 30px
}

.blog_holder.blog_masonry_date_in_image .post_text_inner .social_share_list_holder>span {
  display: none
}

.blog_holder.blog_masonry_date_in_image article .post_text_inner .post_info {
  margin-top: 35px;
  margin-bottom: 0
}

.blog_holder.blog_masonry_date_in_image .social_share_list_holder ul li i {
  color: #8d8d8d;
  -webkit-transition: color 0s ease-in-out;
  -moz-transition: color 0s ease-in-out;
  -ms-transition: color 0s ease-in-out;
  -o-transition: color 0s ease-in-out;
  transition: color 0s ease-in-out
}

.blog_holder.blog_masonry_date_in_image article:not(.format-quote):not(.format-link) .social_share_list_holder ul li i:hover {
  color: #1abc9c
}

.blog_holder.blog_masonry_date_in_image article.format-link .post_text:hover .social_share_list_holder ul li i,
.blog_holder.blog_masonry_date_in_image article.format-quote .post_text:hover .social_share_list_holder ul li i {
  color: #fff !important
}

.blog_holder.blog_masonry_date_in_image.qodef-zoom article:not(.format-gallery)>.post_image {
  overflow: hidden
}

.blog_holder.blog_masonry_date_in_image.qodef-zoom article:not(.format-gallery)>.post_image a {
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  transition: all .2s ease
}

.blog_holder.blog_masonry_date_in_image.qodef-zoom article:not(.format-gallery)>.post_image a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .3);
  opacity: 0;
  -webkit-transition: opacity .2s ease;
  -moz-transition: opacity .2s ease;
  transition: opacity .2s ease
}

.blog_holder.blog_masonry_date_in_image.qodef-zoom article:not(.format-gallery):hover>.post_image a {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: -webkit-transform .35s cubic-bezier(.3, .67, .24, .86);
  transition: transform .35s cubic-bezier(.3, .67, .24, .86)
}

.blog_holder.blog_masonry_date_in_image.qodef-zoom article:not(.format-gallery):hover>.post_image a:after {
  opacity: 1;
  -webkit-transition: opacity .6s cubic-bezier(.3, .67, .24, .86);
  -moz-transition: opacity .6s cubic-bezier(.3, .67, .24, .86);
  transition: opacity .6s cubic-bezier(.3, .67, .24, .86)
}

.blog_masonry_date_in_image.qodef-zoom article.format-gallery ul.slides li {
  overflow: hidden;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  transition: all .2s ease
}

.blog_masonry_date_in_image.qodef-zoom article.format-gallery ul.slides li a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .3);
  z-index: 10;
  opacity: 0;
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  transition: opacity .3s ease
}

.blog_masonry_date_in_image.qodef-zoom article.format-gallery ul.slides li:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: -webkit-transform .35s cubic-bezier(.3, .67, .24, .86);
  transition: transform .35s cubic-bezier(.3, .67, .24, .86)
}

.blog_masonry_date_in_image.qodef-zoom article.format-gallery ul.slides li:hover a:after {
  opacity: 1;
  -webkit-transition: opacity .6s cubic-bezier(.3, .67, .24, .86);
  -moz-transition: opacity .6s cubic-bezier(.3, .67, .24, .86);
  transition: opacity .6s cubic-bezier(.3, .67, .24, .86)
}

.blog_masonry_date_in_image.qodef-zoom article.format-gallery .time {
  z-index: 20
}

.isotope-item {
  z-index: 2
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1
}

.isotope {
  -webkit-transition-property: height;
  -moz-transition-property: height;
  -ms-transition-property: height;
  -o-transition-property: height;
  transition-property: height
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity
}

.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s
}

.icon_social_holder {
  width: 100%;
  display: inline-block;
  text-align: center;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 35px;
  margin-top: 35px;
  padding: 35px 0
}

.icon_social_holder .qode_like,
.icon_social_holder .qode_share {
  display: inline-block;
  margin: 0
}

.icon_social_holder>div {
  display: inline-block;
  margin: 0 25px;
  font-size: 12px;
  font-weight: 400
}

.icon_social_holder .qode_icon_printer,
.icon_social_holder .social_share_icon {
  display: block;
  font-size: 27px;
  margin-bottom: 10px
}

.icon_social_holder .qode-like {
  display: inline-block
}

.icon_social_holder .qode-like:before {
  content: 'W';
  font-family: linea-basic-10 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: 27px;
  margin-bottom: 15px;
  display: block
}

.qode_like a,
.qode_print a,
.qode_share a {
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.pagination {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0 0 50px;
  position: relative
}

.pagination ul {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0
}

.pagination ul li {
  float: left
}

.pagination ul li a,
.pagination ul li span {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  margin: 0 11px 0 0;
  text-align: center;
  color: #b4b4b4;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border: 2px solid #e5e5e5;
  outline: 0;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.pagination ul li a:hover,
.pagination ul li span {
  color: #303030;
  background-color: #e3e3e3;
  border-color: #e3e3e3
}

.google_map_holder,
.google_map_shortcode_holder {
  position: relative
}

.google_map_ovrlay,
.google_map_shortcode_overlay {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%
}

.google_map_ovrlay {
  display: none
}

.google_map_shortcode_overlay {
  display: none;
  top: 0;
  left: 0
}

.google_map {
  display: block;
  width: 100%;
  height: 450px;
  margin: 0 0 25px
}

.qode_google_map {
  display: block;
  width: 100%;
  height: 450px
}

.google_map embed,
.google_map iframe,
.google_map object,
.qode_google_map embed,
.qode_google_map iframe,
.qode_google_map object {
  width: 100%;
  display: block
}

.google_map img,
.qode_google_map img {
  max-width: none
}

.contact_form h5 {
  margin: 0 0 18px
}

.contact_info .q_social_icon_holder .fa-stack {
  margin: .2307692307692308em .4615384615384616em .2307692307692308em 0
}

.contact-error {
  display: block;
  margin: 0;
  position: relative;
  top: -20px
}

.contact_section {
  text-align: center;
  padding: 0 0 55px
}

.contact_section.contact_section_position_left,
.side_menu.side_area_alignment_left,
.side_menu_slide_from_right .side_menu.side_area_alignment_left {
  text-align: left
}

.contact_section.contact_section_position_right {
  text-align: right
}

.contact_section .separator,
.contact_section .separator.small.left,
.contact_section .separator.small.right {
  margin-top: 6px;
  margin-bottom: 35px
}

span.submit_button_contact {
  display: block;
  text-align: right
}

span.submit_button_contact .contact_form_button[disabled] {
  cursor: not-allowed
}

.contact_form_acceptance {
  text-align: right;
  margin-bottom: 10px
}

.contact_form_acceptance .contact_form_acceptance_value {
  display: inline-block;
  vertical-align: middle;
  margin-top: 2px
}

.qode-contact-form-contact-template.qode-contact-form-contact-with-acceptance textarea {
  margin-bottom: 7px
}

.contact_form {
  margin: 0 0 50px
}

.contact_detail.map_grid .google_map {
  margin: 0 0 35px
}

.header-widget.widget_nav_menu {
  padding: 0 15px 0 0;
  position: relative;
  font-size: 12px
}

.header-widget.widget_nav_menu ul ul {
  display: none;
  height: auto;
  border-top: none;
  background-color: #262626;
  z-index: 1010;
  position: absolute;
  top: 33px;
  left: -1px;
  width: 180px;
  padding: 0
}

.header-widget.widget_nav_menu ul li:hover ul {
  display: block
}

.header-widget.widget_nav_menu ul li {
  list-style: none
}

.header-widget.widget_nav_menu ul.menu>li {
  display: inline-block;
  float: left;
  position: relative
}

.header-widget.widget_nav_menu ul.menu li a {
  display: inline-block;
  padding: 0 0 0 15px;
  color: #777
}

.header-widget.widget_nav_menu ul.menu>li.menu-item-has-children>a:after {
  content: "\f107";
  font-family: FontAwesome, sans-serif;
  margin-left: 5px
}

.header-widget.widget_nav_menu ul.menu li a:hover {
  color: #1abc9c
}

.header-widget.widget_nav_menu ul.menu li ul li a {
  color: #9d9d9d;
  display: block;
  white-space: nowrap;
  font-size: 11px;
  font-weight: 600;
  line-height: 38px;
  padding: 0 15px;
  border-bottom: 1px solid #303030;
  text-transform: uppercase
}

.header-widget.widget_nav_menu ul.menu li ul li:last-child a {
  border-bottom: 0
}

.header-widget ul.menu li:last-child {
  margin-right: 0
}

.header-left-from-logo-widget,
.header-right-from-logo-widget {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 50px
}

.header-right-from-logo-widget {
  left: auto;
  right: 0
}

header .container_inner .header-left-from-logo-widget,
header .container_inner .header-right-from-logo-widget {
  padding: 0
}

.header-left-from-logo-widget-inner,
.header-right-from-logo-widget-inner {
  display: table;
  height: 100%
}

.header-left-from-logo-widget-inner2,
.header-right-from-logo-widget-inner2 {
  display: table-cell;
  height: 100%;
  vertical-align: middle
}

header.sticky .header-left-from-logo-widget,
header.sticky .header-right-from-logo-widget {
  display: none
}

.elementor-widget.elementor-widget-sidebar .widget.posts_holder li.menu-item-has-children>a,
.elementor-widget.elementor-widget-sidebar .widget.posts_holder li.page_item_has_children>a,
.side_menu .side_menu_title,
.wpb_widgetised_column .widget.posts_holder li.menu-item-has-children>a,
.wpb_widgetised_column .widget.posts_holder li.page_item_has_children>a,
.widget.posts_holder li.menu-item-has-children>a,
.widget.posts_holder li.page_item_has_children>a {
  display: block
}

.container aside {
  position: relative;
  z-index: 11
}

.side_menu .widget li,
.side_menu a,
.widget a {
  font-weight: 400;
  -moz-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.widget a:hover {
  color: #1abc9c
}

.side_menu .widget a:hover,
.side_menu .widget li:hover,
.side_menu .widget li:hover>a {
  color: #fff
}

.side_menu .widget ul,
.widget ul {
  list-style: none;
  padding: 0
}

.side_menu .widget li,
.widget li {
  margin: 0 0 10px
}

.side_menu .widget li:last-child,
.side_menu .widget.qode_latest_posts_widget li,
 .widget li:last-child,
.widget.qode_latest_posts_widget li {
  margin: 0
}

#wp-calendar caption,
.widget.widget_rss li a.rsswidget {
  color: #000
}

.footer_top #wp-calendar caption,
.footer_top .widget.widget_rss li a.rsswidget {
  color: #fff
}

#wp-calendar td,
#wp-calendar th {
  padding: 3px 4px
}

#wp-calendar td#today {
  color: #fff;
  background-color: #1abc9c;
  -moz-border-radius: 4px;
  border-radius: 4px
}

.elementor-widget.elementor-widget-sidebar .widget a:hover,
.elementor-widget.elementor-widget-sidebar .widget.posts_holder li:hover,
.wpb_widgetised_column .widget a:hover,
.wpb_widgetised_column .widget.posts_holder li:hover {
  color: #1abc9c
}

#wp-calendar {
  width: 100%;
  text-align: center
}

#wp-calendar caption,
.widget.widget_rss li span.rss-date {
  margin: 0 0 15px
}

.circle_left,
.circle_top,
.widget.widget_rss li {
  margin: 0 0 25px
}

.elementor-widget.elementor-widget-sidebar .widget a,
.elementor-widget.elementor-widget-sidebar .widget li,
.elementor-widget.elementor-widget-sidebar a,
.wpb_widgetised_column .widget a,
.wpb_widgetised_column .widget li,
.wpb_widgetised_column a {
  font-weight: 400;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.side_menu_slide_from_right .wrapper,
.side_menu_slide_from_right.right_side_menu_opened .wrapper {
  -moz-transition: left .2s cubic-bezier(.645, .045, .355, 1);
  -o-transition: left .2s cubic-bezier(.645, .045, .355, 1);
  transition: left .2s cubic-bezier(.645, .045, .355, 1)
}

.elementor-widget.elementor-widget-sidebar .widget ul,
.wpb_widgetised_column .widget ul {
  list-style: none;
  padding: 0
}

.elementor-widget.elementor-widget-sidebar .widget li,
.wpb_widgetised_column .widget li {
  margin: 0 0 10px
}

.elementor-widget.elementor-widget-sidebar .widget li:last-child,
.elementor-widget.elementor-widget-sidebar .widget.qode_latest_posts_widget li,
.wpb_widgetised_column .widget li:last-child,
.wpb_widgetised_column .widget.qode_latest_posts_widget li {
  margin: 0
}

.elementor-widget.elementor-widget-sidebar .widget.widget_search,
.wpb_widgetised_column .widget.widget_search {
  border-bottom: 0;
  padding: 0
}

.elementor-widget.elementor-widget-sidebar .widget h5,
.wpb_widgetised_column .widget h5 {
  margin-bottom: 14px
}

.elementor-widget.elementor-widget-sidebar .widget.posts_holder li,
.wpb_widgetised_column .widget.posts_holder li {
  padding: 7px 0;
  margin: 0
}

.elementor-widget.elementor-widget-sidebar .widget.posts_holder li.menu-item-has-children>ul.sub-menu li:last-child,
.elementor-widget.elementor-widget-sidebar .widget.posts_holder li.page_item_has_children>ul.children li:last-child,
.wpb_widgetised_column .widget.posts_holder li.menu-item-has-children>ul.sub-menu li:last-child,
.wpb_widgetised_column .widget.posts_holder li.page_item_has_children>ul.children li:last-child {
  border-bottom: 0
}

.elementor-widget.elementor-widget-sidebar .widget.posts_holder li.menu-item-has-children>ul.sub-menu,
.elementor-widget.elementor-widget-sidebar .widget.posts_holder li.page_item_has_children>ul.children,
.wpb_widgetised_column .widget.posts_holder li.menu-item-has-children>ul.sub-menu,
.wpb_widgetised_column .widget.posts_holder li.page_item_has_children>ul.children {
  padding-left: 10px
}

.elementor-widget.elementor-widget-sidebar .widget a,
.wpb_widgetised_column .widget a {
  color: inherit
}

.elementor-widget.elementor-widget-sidebar .widget,
.wpb_widgetised_column .widget {
  margin: 0 0 42px
}

.elementor-widget.elementor-widget-sidebar .widget_nav_menu .sub-menu,
.elementor-widget.elementor-widget-sidebar .widget_pages .children,
.wpb_widgetised_column .widget_nav_menu .sub-menu,
.wpb_widgetised_column .widget_pages .children {
  margin-left: 20px;
  margin-top: 6px
}

.elementor-widget.elementor-widget-sidebar .qode-widget-sticky-sidebar,
.wpb_widgetised_column .qode-widget-sticky-sidebar {
  margin: 0
}

.widget.widget_search {
  border-bottom: 0;
  padding: 0
}

.widget h5 {
  margin-bottom: 14px
}

.widget.posts_holder li {
  padding: 7px 0;
  margin: 0
}

.widget.posts_holder li.menu-item-has-children>ul.sub-menu li:last-child,
.widget.posts_holder li.page_item_has_children>ul.children li:last-child {
  border-bottom: 0
}

.widget.posts_holder li:hover {
  color: #1abc9c
}

.widget.posts_holder li.menu-item-has-children>ul.sub-menu,
.widget.posts_holder li.page_item_has_children>ul.children {
  padding-left: 10px
}

.widget a {
  color: inherit
}

.widget {
  margin: 0 0 42px
}

.widget_nav_menu .sub-menu,
.widget_pages .children {
  margin-left: 20px;
  margin-top: 6px
}

aside .qode-widget-sticky-sidebar {
  margin: 0
}

.side_menu {
  background-color: #1b1b1b;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  padding: 30px;
  position: fixed;
  top: 0;
  right: -270px;
  width: 270px;
  z-index: 90;
  visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box
}

.right_side_menu_opened .wrapper,
.right_side_menu_opened footer.uncover {
  left: -270px
}

.side_menu_slide_from_right .wrapper {
  background-color: #fff;
  position: relative;
  z-index: 1000;
  transition: left .2s cubic-bezier(.645, .045, .355, 1);
  -ms-transform: translateX(0, 0);
  -moz-transform: translateX(0, 0);
  -o-transform: translateX(0, 0);
  transform: translateX(0, 0);
  -webkit-transform: translateX(0, 0)
}

.side_menu_slide_from_right.right_side_menu_opened .wrapper {
  transform: translateX(-270px, 0);
  -ms-transform: translateX(-270px, 0);
  -moz-transform: translateX(-270px, 0);
  -webkit-transform: translateX(-270px, 0);
  -o-transform: translateX(-270px, 0);
  transition: left .2s cubic-bezier(.645, .045, .355, 1)
}

.side_menu_slide_from_right .carousel-inner {
  -moz-transition: left .2s cubic-bezier(.645, .045, .355, 1);
  -o-transition: left .2s cubic-bezier(.645, .045, .355, 1);
  -webkit-transition: left .2s cubic-bezier(.645, .045, .355, 1);
  transition: left .2s cubic-bezier(.645, .045, .355, 1)
}

.side_menu_slide_from_right .wrapper .cover {
  z-index: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  background: #000;
  -moz-transition: opacity .2s ease-in-out, background .2s ease-in-out, z-index .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background .2s ease-in-out, z-index .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out, background .2s ease-in-out, z-index .2s ease-in-out;
  transition: opacity .2s ease-in-out, background .2s ease-in-out, z-index .2s ease-in-out
}

.side_menu_slide_from_right.right_side_menu_opened .wrapper .cover {
  z-index: 1002;
  opacity: .6;
  filter: alpha(opacity=60);
  -moz-transition: opacity .2s ease-in-out, background .2s ease-in-out, z-index .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background .2s ease-in-out, z-index .2s ease-in-out;
  -webkit-transition: opacity .2s ease-in-out, background .2s ease-in-out, z-index .2s ease-in-out;
  transition: opacity .2s ease-in-out, background .2s ease-in-out, z-index .2s ease-in-out
}

.side_menu_slide_from_right .side_menu {
  padding: 5% 30px 30px;
  top: 0;
  right: -45%;
  width: 45%;
  z-index: 9999;
  text-align: center;
  visibility: hidden;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.right_side_menu_opened.side_menu_slide_from_right .side_menu {
  right: 0;
  visibility: visible;
  -moz-transition: right .2s ease-in-out;
  -o-transition: right .2s ease-in-out;
  -webkit-transition: right .2s ease-in-out;
  transition: right .2s ease-in-out
}

.side_menu_slide_from_right header.fixed,
.side_menu_slide_from_right header.fixed_hiding,
.side_menu_slide_from_right header.fixed_top_header .top_header,
.side_menu_slide_from_right header.sticky {
  -moz-transition: left .2s cubic-bezier(.645, .045, .355, 1), top .33s cubic-bezier(.694, .0482, .335, 1);
  -o-transition: left .2s cubic-bezier(.645, .045, .355, 1), top .33s cubic-bezier(.694, .0482, .335, 1);
  -webkit-transition: left .2s cubic-bezier(.645, .045, .355, 1), top .33s cubic-bezier(.694, .0482, .335, 1);
  transition: left .2s cubic-bezier(.645, .045, .355, 1), top .33s cubic-bezier(.694, .0482, .335, 1)
}

.side_menu_slide_from_right footer.uncover {
  -moz-transition: left .2s cubic-bezier(.645, .045, .355, 1);
  -o-transition: left .2s cubic-bezier(.645, .045, .355, 1);
  -webkit-transition: left .2s cubic-bezier(.645, .045, .355, 1);
  transition: left .2s cubic-bezier(.645, .045, .355, 1)
}

body.side_menu_slide_with_content {
  overflow-x: hidden;
  position: relative;
  left: 0;
  -webkit-overflow-scrolling: auto !important
}

body.side_menu_slide_with_content.side_menu_open .wrapper,
body.side_menu_slide_with_content.side_menu_open footer.uncover {
  left: -470px
}

body.side_menu_slide_with_content,
body.side_menu_slide_with_content .side_menu,
body.side_menu_slide_with_content .wrapper,
body.side_menu_slide_with_content footer.uncover,
body.side_menu_slide_with_content header.fixed,
body.side_menu_slide_with_content header.fixed_hiding,
body.side_menu_slide_with_content header.fixed_top_header .top_header {
  -webkit-transition: left .2s ease, right .2s ease;
  -moz-transition: left .2s ease, right .2s ease;
  transition: left .2s ease, right .2s ease
}

body.side_menu_slide_with_content header.sticky {
  -webkit-transition: left .2s ease, right .2s ease, top .33s cubic-bezier(.694, .0482, .335, 1);
  -moz-transition: left .2s ease, right .2s ease, top .33s cubic-bezier(.694, .0482, .335, 1);
  transition: left .2s ease, right .2s ease, top .33s cubic-bezier(.694, .0482, .335, 1)
}

body.side_menu_slide_with_content .side_menu {
  position: fixed;
  visibility: visible;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 470px;
  right: -470px;
  height: 100%;
  top: 0;
  z-index: 9999
}

.side_menu .widget li,
footer {
  position: relative
}

body.side_menu_slide_with_content.side_menu_open .side_menu {
  right: 0
}

body.side_menu_slide_with_content.side_menu_open .carousel-inner:not(.relative_position) {
  left: -470px !important;
  -webkit-transition: left .2s ease;
  -moz-transition: left .2s ease;
  transition: left .2s ease
}

body.side_menu_slide_with_content .carousel-inner {
  left: 0 !important;
  -webkit-transition: left .2s ease;
  -moz-transition: left .2s ease;
  transition: left .2s ease
}

body.side_menu_slide_with_content.side_menu_open header.fixed,
body.side_menu_slide_with_content.side_menu_open header.fixed_hiding,
body.side_menu_slide_with_content.side_menu_open header.fixed_top_header .top_header,
body.side_menu_slide_with_content.side_menu_open header.sticky {
  left: -470px;
  -webkit-transition: left .2s ease;
  -moz-transition: left .2s ease;
  transition: left .2s ease
}

body.side_menu_slide_with_content.width_270.side_menu_open .wrapper,
body.side_menu_slide_with_content.width_270.side_menu_open footer.uncover {
  left: -270px
}

body.side_menu_slide_with_content.width_270 .side_menu {
  width: 270px;
  right: -270px
}

body.side_menu_slide_with_content.width_270.side_menu_open .side_menu {
  right: 0
}

body.side_menu_slide_with_content.width_270.side_menu_open .carousel-inner:not(.relative_position) {
  left: -270px !important
}

body.side_menu_slide_with_content.width_270.side_menu_open header.fixed,
body.side_menu_slide_with_content.width_270.side_menu_open header.fixed_hiding,
body.side_menu_slide_with_content.width_270.side_menu_open header.fixed_top_header .top_header,
body.side_menu_slide_with_content.width_270.side_menu_open header.sticky {
  left: -270px
}

body.side_menu_slide_with_content.width_370.side_menu_open .wrapper,
body.side_menu_slide_with_content.width_370.side_menu_open footer.uncover {
  left: -370px
}

body.side_menu_slide_with_content.width_370 .side_menu {
  width: 370px;
  right: -370px
}

body.side_menu_slide_with_content.width_370.side_menu_open .side_menu {
  right: 0
}

body.side_menu_slide_with_content.width_370.side_menu_open .carousel-inner:not(.relative_position) {
  left: -370px !important
}

body.side_menu_slide_with_content.width_370.side_menu_open header.fixed,
body.side_menu_slide_with_content.width_370.side_menu_open header.fixed_hiding,
body.side_menu_slide_with_content.width_370.side_menu_open header.fixed_top_header .top_header,
body.side_menu_slide_with_content.width_370.side_menu_open header.sticky {
  left: -370px
}

.footer_bottom,
.side_menu.side_area_alignment_center {
  text-align: center
}

.side_menu.side_area_alignment_right {
  text-align: right
}

.side_menu h5,
.side_menu h6 {
  margin: 0 0 12px;
  color: #fff
}

.side_menu .widget ul li.menu-item-has-children,
.side_menu .widget ul li.page_item_has_children {
  padding-right: 0;
  border-bottom: none
}

.side_menu .widget ul ul {
  padding: 6px 0 0 20px
}

.side_menu .widget {
  margin: 0 0 43px
}

.side_menu #wp-calendar caption,
.side_menu #wp-calendar td,
.side_menu #wp-calendar th,
.side_menu .widget.widget_rss li a.rsswidget,
.side_menu a,
.side_menu li,
.side_menu p,
.side_menu span {
  color: #818181
}

.side_menu .side_menu_title {
  margin: 0 0 12px
}

.side_menu .widget.widget_nav_menu li a,
.side_menu .widget.widget_nav_menu li:last-child {
  margin: 0
}

.side_menu a.close_side_menu {
  display: inline-block;
  position: absolute;
  top: 29px;
  right: 25px;
  width: 13px;
  height: 13px;
  line-height: 13px;
  /* background-image: url(img/close_side_menu.png); */
  background-repeat: no-repeat;
  z-index: 1000;
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease
}

.side_menu a.close_side_menu:hover {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -moz-transform: rotate(180deg)
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:200 / 100),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:210dpi) {
  .side_menu a.close_side_menu {
    /* background-image:url(img/close_side_menu@2x.png); */
    -o-background-size: 13px 13px;
    -webkit-background-size: 13px 13px;
    -moz-background-size: 13px 13px;
    background-size: 13px 13px
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio:1.5),
only screen and (-o-min-device-pixel-ratio:150 / 100),
only screen and (min-device-pixel-ratio:1.5),
only screen and (min-resolution:160dpi) {
  .side_menu a.close_side_menu {
    /* background-image:url(img/close_side_menu@2x.png); */
    -o-background-size: 13px 13px;
    -webkit-background-size: 13px 13px;
    -moz-background-size: 13px 13px;
    background-size: 13px 13px
  }
}

.side_menu.dark a.close_side_menu {
  /* background-image: url(img/close_side_menu_dark.png) */
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:200 / 100),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:210dpi) {
  .side_menu.dark a.close_side_menu {
    /* background-image:url(img/close_side_menu_dark@2x.png); */
    -o-background-size: 13px 13px;
    -webkit-background-size: 13px 13px;
    -moz-background-size: 13px 13px;
    background-size: 13px 13px
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio:1.5),
only screen and (-o-min-device-pixel-ratio:150 / 100),
only screen and (min-device-pixel-ratio:1.5),
only screen and (min-resolution:160dpi) {
  .side_menu.dark a.close_side_menu {
    /* background-image:url(img/close_side_menu_dark@2x.png); */
    -o-background-size: 13px 13px;
    -webkit-background-size: 13px 13px;
    -moz-background-size: 13px 13px;
    background-size: 13px 13px
  }
}

.footer_top .widget.widget_nav_menu li {
  margin: 0;
  line-height: 22px
}

.widget.widget_archive select,
.widget.widget_categories select,
.widget.widget_text select {
  width: 100%;
  overflow: hidden;
  border: 1px solid transparent;
  font-size: 13px;
  background-color: #fff;
  outline: 0;
  color: #818181;
  font-family: inherit;
  padding: 2px 4%;
  height: 37px
}

.widget  .search-form {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  background-color: #fff
}

.footer_top .widget  .search-form {
  background-color: transparent;
  border: 1px solid #6a6a6a
}

.header_top  .search-form {
  padding: 0 10px;
  height: 33px
}

.widget.widget_search form.form_focus {
  border-color: #1abc9c
}

.header_top  .search-form input[type=submit],
.widget.widget_search form input[type=submit] {
  width: 37px;
  height: 37px;
  line-height: 37px;
  display: inline-block;
  margin: 0;
  padding: 0 4%;
  outline: 0;
  border: none;
  text-decoration: none;
  background-color: transparent;
  color: #b9b9b9;
  font-family: FontAwesome, sans-serif;
  cursor: pointer;
  white-space: nowrap;
  float: right;
  transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out
}

.footer_top .widget  .search-form input[type=submit] {
  color: #6a6a6a
}

.header_top  .search-form input[type=submit] {
  width: 22px;
  height: 25px;
  line-height: 25px;
  margin: 4px 0 0;
  background: 0 0
}

.header_top  .search-form .screen-reader-text,
.widget.widget_search form .screen-reader-text {
  display: none
}

.header_top  .search-form input[type=text],
.widget.widget_search form input[type=text] {
  display: inline-block;
  text-decoration: none;
  border: 0;
  outline: 0;
  color: #adadad;
  background-color: transparent;
  font-family: inherit;
  margin: 0;
  padding: 2px 4%;
  width: 74%;
  height: 33px;
  float: left
}

.footer_top .widget  .search-form input[type=text] {
  color: #6a6a6a
}

.header_top  .search-form input[type=text] {
  height: 29px;
  font-size: 12px
}

.footer_top .widget.widget_search form input[type=text],
.side_menu .widget  .search-form input[type=text] {
  width: 70% !important
}

.widget .tagcloud {
  display: inline-block;
  width: 100%
}

.widget .tagcloud a,
.widget .wp-block-tag-cloud a {
  margin: 0 0 3px
}

.side_menu .widget .tagcloud a {
  color: #fff
}

footer {
  display: block;
  width: 100%;
  margin: 0 auto;
  z-index: 100
}

footer.uncover {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  -webkit-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  -moz-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  -o-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  -ms-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  backface-visibility: hidden
}

body.paspartu_enabled footer.uncover {
  z-index: 98
}

.boxed footer {
  width: 100% !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.right_side_menu_opened footer.uncover {
  left: -270px;
}

footer .container_inner {
  position: relative;
}

.footer_top_holder {
  display: block;
  background-color: #262626;
  position: relative;
}

.footer_bottom_border.in_grid,
.footer_top_border.in_grid {
  width: 1100px;
  margin: 0 auto;
}

.footer_top.footer_top_full {
  padding: 48px 24px;
}

.footer_top h5 {
  color: #fff;
  margin: 0 0 22px;
}

.footer_top ul {
  list-style: none
}

.footer_top .textwidget,
.footer_top a,
.footer_top li,
.footer_top p,
.footer_top span {
  color: #818181;
  word-wrap: break-word
}

.footer_top a:not(.qbutton) {
  -webkit-transition: color .2s ease-in-out;
  -moz-transition: color .2s ease-in-out;
  -o-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out
}

.footer_top a:hover {
  color: #fff !important
}

.footer_top .four_columns .column2 .column_inner>div,
.footer_top .three_columns .column2 .column_inner>div,
.footer_top .two_columns_50_50 .column2 .column_inner>div {
  margin: 0 0 0 15px
}

.footer_top .four_columns .column3 .column_inner>div,
.footer_top .three_columns .column3 .column_inner>div {
  margin: 0 0 0 10px
}

.footer_top .four_columns .column4 .column_inner>div {
  margin: 0 0 0 5px
}

.footer_top .widget_nav_menu li.menu-item a {
  margin-bottom: 0
}

.footer_top .widget_meta>ul>li,
.footer_top .widget_nav_menu ul li,
.footer_top .widget_pages ul li,
.footer_top .widget_recent_comments>ul>li,
.footer_top .widget_recent_entries>ul>li {
  padding: 0 0 17px;
  position: relative
}

.footer_top .widget_nav_menu ul li ul,
.footer_top .widget_pages ul li ul {
  padding: 0 0 0 10px
}

.footer_top .widget_meta>ul>li>a,
.footer_top .widget_nav_menu ul li a,
.footer_top .widget_pages>ul>li>a,
.footer_top .widget_recent_comments>ul>li>a,
.footer_top .widget_recent_entries>ul>li>a,
.footer_top .widget_recent_entries>ul>li>span {
  display: block
}

.footer_top .widget_recent_entries>ul>li>span {
  color: #9d9d9d
}

.footer_bottom_holder {
  display: block;
  background-color: #1b1b1b
}

.footer_top_holder svg.angled-section polygon {
  fill: #1b1b1b
}

.footer_bottom {
  display: table-cell;
  font-size: 12px;
  line-height: 22px;
  height: 53px;
  width: 1%;
  vertical-align: middle
}

#pp_full_res .pp_inline,
.footer_bottom_columns.three_columns .column1 .footer_bottom,
.footer_bottom_columns.three_columns .column1 .footer_bottom ul,
.footer_bottom_columns.two_columns_50_50 .column1 .footer_bottom,
.footer_bottom_columns.two_columns_50_50 .column1 .footer_bottom ul,
.pp_content_container {
  text-align: left
}

.footer_bottom_columns.three_columns .column3 .footer_bottom,
.footer_bottom_columns.three_columns .column3 .footer_bottom ul,
.footer_bottom_columns.two_columns_50_50 .column2 .footer_bottom,
.footer_bottom_columns.two_columns_50_50 .column2 .footer_bottom ul {
  text-align: right
}

.footer_bottom p,
.footer_bottom span {
  margin: 0
}

.footer_bottom .footer_text_title {
  display: none
}

.footer_bottom ul {
  list-style: none;
  text-align: center
}

.footer_bottom ul li {
  display: inline-block;
  margin-right: 46px
}

#back_to_top .hover,
.pp_description,
.right_side_menu_opened #back_to_top,
div.pp_overlay,
div.pp_pic_holder {
  display: none;
}

.footer_bottom ul li:last-child {
  margin-right: 0;
}

.footer_bottom ul li a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out;
}

.footer_bottom ul li a:hover,
.footer_top .q_social_icon_holder i.simple_social,
.footer_top .q_social_icon_holder span.simple_social,
.side_menu .q_social_icon_holder i.simple_social,
.side_menu .q_social_icon_holder span.simple_social {
  color: #818181;
}

.footer_top .q_social_icon_holder i.simple_social,
.footer_top .q_social_icon_holder span.simple_social {
  margin-right: 16px;
  -webkit-transition: all .3s ease 0s;
  -moz-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  transition: all .3s ease 0s
}

.footer_top .q_social_icon_holder:hover i.simple_social,
.footer_top .q_social_icon_holder:hover span.simple_social,
.side_menu .q_social_icon_holder:hover i.simple_social,
.side_menu .q_social_icon_holder:hover span.simple_social {
  color: #fff !important
}

.footer_top .q_social_icon_holder.normal_social {
  margin: 0
}

.footer_top .q_social_icon_holder:last-child i.simple_social,
.footer_top .q_social_icon_holder:last-child span.simple_social {
  margin-right: 0
}

#back_to_top {
  color: #cdcdcd;
  height: auto;
  position: fixed;
  bottom: 65px;
  margin: 0;
  z-index: 10000;
  -webkit-transition: all .3s ease 0s;
  -moz-transition: all .3s ease 0s;
  -o-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  right: 25px;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  backface-visibility: hidden;
}

#back_to_top.off {
  opacity: 0;
  filter: alpha(opacity=0);
  right: 25px
}

#back_to_top.on {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
  right: 25px
}

#back_to_top>span {
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-decoration: none;
  -o-border-radius: 52px;
  -moz-border-radius: 52px;
  -webkit-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: all .2s ease 0s;
  -moz-transition: all .2s ease 0s;
  transition: all .2s ease 0s;
  border: 2px solid #e8e8e8;
  background: 0 0
}

#back_to_top span i,
#back_to_top span span,
.q_steps_holder .circle_small .step_title,
.q_steps_holder .circle_small span {
  -webkit-transition: color .2s ease 0s;
  -moz-transition: color .2s ease 0s;
  transition: color .2s ease 0s
}

#back_to_top span i,
#back_to_top span span {
  font-size: 22px;
  color: #b0b0b0;
  line-height: 52px
}

#back_to_top:hover>span {
  background-color: #e8e8e8
}

.q_steps_holder {
  width: 100%;
  text-align: center
}

.q_steps_holder_inner {
  position: relative;
  display: inline-block
}

.q_steps_holder .circle_small,
.q_steps_holder .circle_small_inner {
  width: 192px;
  height: 192px
}

.q_steps_holder .circle_small_wrapper {
  margin: 0 auto;
  border-radius: 530px;
  border: 2px solid transparent;
  border-top: 2px solid #1abc9c;
  width: 193px;
  height: 192px
}

.q_steps_holder .circle_small {
  margin: 0 auto;
  border: 1px solid #c7c7c7;
  color: #666;
  font-family: inherit;
  font-size: 26px;
  border-radius: 500px;
  text-align: center
}

.q_steps_holder .circle_small:hover .step_title,
.q_steps_holder .circle_small:hover span {
  color: #1abc9c !important
}

.q_steps_holder .circle_small_inner {
  display: table-cell;
  vertical-align: middle
}

.q_steps_holder .circle_small_inner span {
  font-size: 50px;
  font-weight: 500;
  line-height: 50px
}

.q_steps_holder a.circle_small_inner {
  color: #666;
}

.q_steps_holder a.circle_small_inner:hover {
  color: inherit;
}

.q_steps_holder .circle_small span {
  display: block;
  margin-bottom: 5px;
}

.q_steps_holder .circle_small .step_title {
  color: #666;
  font-size: 16px;
}

.q_steps_holder .circle_small_holder p {
  margin-top: 5px;
  text-align: center;
  padding: 0 21px;
}

.q_steps_holder .circle_small_holder_inner {
  position: relative;
}

.q_steps_holder .circle_small_holder:last-child .circle_small_holder_inner .arrow_holder {
  background: 0 0;
}

.q_steps_holder.show .circle_small_holder {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}

.q_steps_holder .circle_small_holder {
  width: 265px;
  display: inline-block;
  -webkit-transform: scale(.7);
  -moz-transform: scale(.7);
  -ms-transform: scale(.7);
  -o-transform: scale(.7);
  transform: scale(.7);
  opacity: 0;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out
}

.q_steps_holder .step1 {
  left: 40px;
  top: 85px;
  -webkit-transition-delay: .5s;
  -moz-transition-delay: .5s;
  -ms-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s
}

.q_steps_holder .step1 .circle_small_wrapper {
  transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  -webkit-transform: rotate(-25deg)
}

.q_steps_holder .step1 .circle_small {
  transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -webkit-transform: rotate(25deg)
}

.q_steps_holder .step2 {
  -webkit-transition-delay: 1s;
  -moz-transition-delay: 1s;
  -ms-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s
}

.q_steps_holder .step2 .circle_small_wrapper {
  transform: rotate(-175deg);
  -ms-transform: rotate(-175deg);
  -webkit-transform: rotate(-175deg)
}

.q_steps_holder .step2 .circle_small {
  transform: rotate(175deg);
  -ms-transform: rotate(175deg);
  -webkit-transform: rotate(175deg)
}

.q_steps_holder .step3 {
  -webkit-transition-delay: 1.5s;
  -moz-transition-delay: 1.5s;
  -ms-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s
}

.q_steps_holder .step3 .circle_small_wrapper {
  transform: rotate(25deg);
  -ms-transform: rotate(25deg);
  -webkit-transform: rotate(25deg)
}

.q_steps_holder .step3 .circle_small {
  transform: rotate(-25deg);
  -ms-transform: rotate(-25deg);
  -webkit-transform: rotate(-25deg)
}

.q_steps_holder .step4 .circle_small_wrapper {
  transform: rotate(-170deg);
  -ms-transform: rotate(-170deg);
  -webkit-transform: rotate(-170deg)
}

.q_steps_holder .step4 .circle_small {
  transform: rotate(170deg);
  -ms-transform: rotate(170deg);
  -webkit-transform: rotate(170deg)
}

.q_steps_holder .step4 {
  -webkit-transition-delay: 2s;
  -moz-transition-delay: 2s;
  -ms-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s
}

.vc_text_separator.full {
  padding-bottom: 20px;
  border-top: 1px solid #eaeaea;
  margin-top: 20px;
  border-bottom: 0
}

.vc_text_separator.full.separator_align_center {
  text-align: center
}

.vc_text_separator.full.separator_align_right {
  text-align: right
}

.vc_text_separator.full div {
  padding: 0 35px;
  line-height: 36px;
  top: -19px;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  background-color: #1abc9c;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  border: 1px solid transparent;
  display: inline-block;
  position: relative
}

.separator_with_icon {
  color: #818181;
  display: block;
  font-size: 18px;
  line-height: 21px;
  margin: 0 auto;
  position: relative;
  width: 783px;
  max-width: 100%;
  text-align: center
}

.separator_with_icon:after,
.separator_with_icon:before {
  border-bottom: 1px solid #818181;
  display: inline-block;
  position: absolute;
  top: 10px;
  width: 376px;
  max-width: 48%;
  content: ""
}

.separator_with_icon:before {
  border-color: inherit;
  left: 0
}

.separator_with_icon:after {
  border-color: inherit;
  right: 0
}

@media only screen and (max-width: 1000px) {

  .separator_with_icon:after,
  .separator_with_icon:before {
    max-width: 45%
  }
}

.page_not_found {
  text-align: center;
  margin: 0 0 83px
}

.page_not_found h2 {
  margin: 40px 0 20px;
  font-size: 32px
}

.page_not_found p {
  margin: 15px 0 35px
}

.custom_font_holder {
  display: block;
  position: relative
}

.custom_font_holder .qode-cf-typed-wrap {
  width: 0;
  white-space: nowrap
}

.custom_font_holder .qode-cf-typed {
  display: inline-block
}

body div.pp_default .pp_loaderIcon {
  background-color: #fff;
  border-radius: 20px
}

div.pp_default .pp_bottom,
div.pp_default .pp_bottom .pp_left,
div.pp_default .pp_bottom .pp_middle,
div.pp_default .pp_bottom .pp_right,
div.pp_default .pp_top,
div.pp_default .pp_top .pp_left,
div.pp_default .pp_top .pp_middle,
div.pp_default .pp_top .pp_right {
  height: 13px
}

div.pp_default .pp_top .pp_left {
  /* background: url(img/prettyPhoto/sprite.png) -78px -93px no-repeat */
}

div.pp_default .pp_top .pp_middle {
  /* background: url(img/prettyPhoto/sprite_x.png) top left repeat-x */
}

div.pp_default .pp_top .pp_right {
  /* background: url(img/prettyPhoto/sprite.png) -112px -93px no-repeat */
}

div.pp_default .pp_content .ppt {
  color: #f8f8f8
}

div.pp_default .pp_content_container .pp_left {
  /* background: url(img/prettyPhoto/sprite_y.png) -7px 0 repeat-y; */
  padding-left: 13px
}

div.pp_default .pp_content_container .pp_right {
  /* background: url(img/prettyPhoto/sprite_y.png) top right repeat-y; */
  padding-right: 13px
}

div.pp_default .pp_next:hover {
  /* background: url(img/prettyPhoto/sprite_next.png) center right no-repeat; */
  cursor: pointer
}

div.pp_default .pp_previous:hover {
  /* background: url(img/prettyPhoto/sprite_prev.png) center left no-repeat; */
  cursor: pointer
}

div.pp_default .pp_expand {
  /* background: url(img/prettyPhoto/sprite.png) 0 -29px no-repeat; */
  cursor: pointer;
  width: 28px;
  height: 28px
}

div.pp_default .pp_expand:hover {
  /* background: url(img/prettyPhoto/sprite.png) 0 -56px no-repeat; */
  cursor: pointer
}

div.pp_default .pp_contract {
  /* background: url(img/prettyPhoto/sprite.png) 0 -84px no-repeat; */
  cursor: pointer;
  width: 28px;
  height: 28px
}

div.pp_default .pp_contract:hover {
  /* background: url(img/prettyPhoto/sprite.png) 0 -113px no-repeat; */
  cursor: pointer
}

div.pp_default .pp_close {
  width: 30px;
  height: 30px;
  /* background: url(img/prettyPhoto/sprite.png) 2px 1px no-repeat; */
  cursor: pointer
}

div.pp_default .pp_gallery ul li a {
  /* background: url(img/prettyPhoto/default_thumb.png) center center #f8f8f8; */
  border: 1px solid #aaa
}

div.pp_default .pp_social {
  margin-top: 7px
}

div.pp_default .pp_gallery a.pp_arrow_next,
div.pp_default .pp_gallery a.pp_arrow_previous {
  position: static;
  left: auto
}

div.pp_default .pp_nav .pp_pause,
div.pp_default .pp_nav .pp_play {
  /* background: url(img/prettyPhoto/sprite.png) -51px 1px no-repeat; */
  height: 30px;
  width: 30px
}

div.pp_default .pp_nav .pp_pause {
  background-position: -51px -29px
}

div.pp_default a.pp_arrow_next,
div.pp_default a.pp_arrow_previous {
  /* background: url(img/prettyPhoto/sprite.png) -31px -3px no-repeat; */
  height: 20px;
  width: 20px;
  margin: 4px 0 0
}

div.pp_default a.pp_arrow_next {
  left: 52px;
  background-position: -82px -3px
}

div.pp_default .pp_content_container .pp_details {
  margin-top: 5px
}

div.pp_default .pp_nav {
  clear: none;
  height: 30px;
  width: 110px;
  position: relative
}

div.pp_default .pp_nav .currentTextHolder {
  font-family: inherit;
  color: #999;
  font-size: 13px;
  left: 65px;
  line-height: 25px;
  position: absolute;
  top: 2px;
  margin: 0;
  padding: 0 0 0 10px
}

div.pp_default .pp_arrow_next:hover,
div.pp_default .pp_arrow_previous:hover,
div.pp_default .pp_close:hover,
div.pp_default .pp_nav .pp_pause:hover,
div.pp_default .pp_nav .pp_play:hover {
  opacity: .7
}

div.pp_default .pp_description {
  font-size: 15px;
  font-weight: 300;
  line-height: 14px;
  margin: 10px 50px 10px 0
}

div.pp_default .pp_bottom .pp_left {
  /* background: url(img/prettyPhoto/sprite.png) -78px -127px no-repeat */
}

div.pp_default .pp_bottom .pp_middle {
  /* background: url(img/prettyPhoto/sprite_x.png) bottom left repeat-x */
}

div.pp_default .pp_bottom .pp_right {
  /* background: url(img/prettyPhoto/sprite.png) -112px -127px no-repeat */
}

div.pp_default .pp_loaderIcon {
  /* background: url(img/prettyPhoto/loader.gif) center center no-repeat */
}

div.pp_pic_holder a:focus {
  outline: 0
}

div.pp_overlay {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9500
}

div.pp_pic_holder {
  position: absolute;
  width: 100px;
  z-index: 10000
}

.pp_content {
  height: 40px;
  min-width: 40px
}

* html .pp_content {
  width: 40px
}

.pp_content_container {
  position: relative;
  width: 100%
}

.pp_content_container .pp_left {
  padding-left: 20px
}

.pp_content_container .pp_right {
  padding-right: 20px
}

.pp_content_container .pp_details {
  float: left;
  margin: 10px 0 2px
}

.pp_description {
  margin: 0
}

.pp_social {
  float: left;
  margin: 0
}

.pp_social .facebook {
  float: left;
  margin-left: 5px;
  width: 55px;
  overflow: hidden
}

.pp_social .twitter {
  float: left
}

.pp_nav {
  clear: right;
  float: left;
  margin: 3px 10px 0 0
}

.pp_nav p {
  float: left;
  white-space: nowrap;
  margin: 2px 4px
}

.pp_nav .pp_pause,
.pp_nav .pp_play {
  float: left;
  margin-right: 4px;
  text-indent: -10000px
}

a.pp_arrow_next,
a.pp_arrow_previous {
  display: block;
  float: left;
  height: 15px;
  margin-top: 3px;
  overflow: hidden;
  text-indent: -10000px;
  width: 14px
}

.pp_hoverContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2000
}

.pp_gallery {
  display: none;
  left: 50%;
  margin-top: -50px;
  position: absolute;
  z-index: 10000
}

.pp_gallery div {
  float: left;
  overflow: hidden;
  position: relative
}

.pp_gallery ul {
  float: left;
  height: 35px;
  position: relative;
  white-space: nowrap;
  margin: 0 0 0 5px;
  padding: 0
}

.pp_gallery ul a {
  border: 1px solid rgba(0, 0, 0, .5);
  display: block;
  float: left;
  height: 33px;
  overflow: hidden
}

.pp_gallery ul a img {
  border: 0
}

.pp_gallery li {
  display: block;
  float: left;
  margin: 0 5px 0 0;
  padding: 0
}

.pp_gallery li.default a {
  /* background: url(../images/prettyPhoto/facebook/default_thumbnail.gif) no-repeat; */
  display: block;
  height: 33px;
  width: 50px
}

a.pp_next,
a.pp_previous {
  display: block;
  height: 100%;
  width: 49%;
  text-indent: -10000px
}

.pp_gallery .pp_arrow_next,
.pp_gallery .pp_arrow_previous {
  margin-top: 7px !important
}

a.pp_next {
  float: right
}

a.pp_previous {
  float: left
}

a.pp_contract,
a.pp_expand {
  cursor: pointer;
  display: none;
  height: 20px;
  position: absolute;
  right: 30px;
  text-indent: -10000px;
  top: 10px;
  width: 20px;
  z-index: 20000
}

.pp_loaderIcon,
a.pp_close {
  display: block;
  position: absolute
}

a.pp_close {
  right: 0;
  top: 0;
  line-height: 22px;
  text-indent: -10000px
}

.pp_loaderIcon {
  height: 24px;
  left: 50%;
  top: 50%;
  width: 24px;
  margin: -12px 0 0 -12px
}

#pp_full_res {
  line-height: 1 !important
}

#pp_full_res .pp_inline p {
  margin: 0 0 15px
}

div.ppt {
  color: #fff;
  display: none;
  font-size: 17px;
  z-index: 9999;
  margin: 0 0 5px 15px
}

div.pp_default .pp_content {
  background-color: #fff
}

div.pp_default #pp_full_res .pp_inline {
  color: #000
}

.pp_gallery li.selected a,
.pp_gallery ul a:hover,
div.pp_default .pp_gallery ul li a:hover,
div.pp_default .pp_gallery ul li.selected a {
  border-color: #fff
}

div.pp_default .pp_details {
  position: relative
}

.pp_bottom,
.pp_top {
  height: 20px;
  position: relative
}

* html .pp_bottom,
* html .pp_top {
  padding: 0 20px
}

.pp_bottom .pp_left,
.pp_top .pp_left {
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px
}

.pp_bottom .pp_middle,
.pp_top .pp_middle {
  height: 20px;
  left: 20px;
  position: absolute;
  right: 20px
}

* html .pp_bottom .pp_middle,
* html .pp_top .pp_middle {
  left: 0;
  position: static
}

.pp_bottom .pp_right,
.pp_top .pp_right {
  height: 20px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px
}

body a.pp_next:after,
body a.pp_previous:after {
  border: 2px solid #FFF;
  color: #FFF;
  font-family: FontAwesome, serif;
  font-size: 30px;
  height: 54px;
  line-height: 54px;
  margin: -27px 0 0;
  text-align: center;
  top: 50%;
  width: 54px;
  text-indent: 0
}

.pp_fade,
.pp_gallery li.default a img {
  display: none
}

body div.pp_overlay {
  opacity: .7 !important
}

body div.pp_default .pp_content_container .pp_left,
body div.pp_default .pp_content_container .pp_right {
  background: 0 0;
  padding: 0
}

body div.pp_default .pp_bottom,
body div.pp_default .pp_bottom .pp_left,
body div.pp_default .pp_bottom .pp_middle,
body div.pp_default .pp_bottom .pp_right,
body div.pp_default .pp_top,
body div.pp_default .pp_top .pp_left,
body div.pp_default .pp_top .pp_middle,
body div.pp_default .pp_top .pp_right {
  background: 0 0;
  display: none
}

body div.pp_default .pp_expand {
  display: none !important
}

body div.pp_default .pp_content {
  background: 0 0
}

body a.pp_next,
body a.pp_previous {
  background: 0 0 !important;
  opacity: 0;
  -webkit-transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -ms-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out
}

body div.pp_default .pp_content:hover a.pp_next,
body div.pp_default .pp_content:hover a.pp_previous {
  opacity: 1
}

body a.pp_next:after {
  border-radius: 50px;
  content: "\f105";
  display: block !important;
  position: absolute;
  right: 25px
}

body a.pp_previous:after {
  border-radius: 50px;
  content: "\f104";
  display: block !important;
  position: absolute;
  left: 25px
}

.carousel-inner .item .image img,
body .pp_gallery,
body div.pp_default .pp_description {
  display: none !important
}

body div.pp_default .pp_content_container .pp_details {
  opacity: .7
}

body div.pp_default .pp_nav {
  float: none;
  width: auto;
  margin: 0
}

body div.pp_default .pp_nav .currentTextHolder {
  left: auto;
  position: relative;
  float: left;
  padding: 0 20px;
  font-family: Raleway, sans-serif;
  font-size: 15px;
  color: #fff;
  font-weight: 600
}

.qode-pretty-photo-hide-navigation .pp_hoverContainer,
:root .mejs-poster img,
body div.pp_default .pp_nav .pp_pause,
body div.pp_default .pp_nav .pp_play {
  display: none
}

body div.pp_default a.pp_arrow_next,
body div.pp_default a.pp_arrow_previous {
  background: 0 0;
  position: relative;
  left: 0
}

body div.pp_default .pp_close:after,
body div.pp_default a.pp_arrow_next:after,
body div.pp_default a.pp_arrow_previous:after {
  color: #FFF;
  text-align: center;
  position: absolute;
  top: 0;
  font-family: FontAwesome, serif;
  text-indent: 0
}

body div.pp_default a.pp_arrow_previous:after {
  content: "\f104";
  font-size: 20px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  left: 0
}

body div.pp_default a.pp_arrow_next:after {
  content: "\f105";
  font-size: 20px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  right: 0
}

body div.pp_default .pp_close {
  background: 0 0;
  opacity: 1 !important
}

body div.pp_default .pp_close:after {
  content: "\f00d";
  font-size: 15px;
  height: 15px;
  line-height: 30px;
  width: 30px;
  left: 0
}

.mejs-container {
  position: relative;
  text-align: left;
  vertical-align: top;
  text-indent: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  background-color: #fff
}

.blog_audio.mejs-container {
  height: 40px !important
}

.mejs-container.wp-audio-shortcode {
  height: 30px !important;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0
}

.blog_holder.masonry .mejs-container {
  border: none
}

.mejs-mediaelement,
.qode_call_to_action.in_grid {
  -webkit-border-radius: 4px;
  border-radius: 4px
}

.blog_holder.small_images article .post_text .post_inner .mejs-container {
  margin: 0 0 15px
}

.me-plugin {
  position: absolute
}

.mejs-embed,
.mejs-embed body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden
}

.mejs-fullscreen {
  overflow: hidden !important
}

.mejs-container-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1000
}

.mejs-container-fullscreen .mejs-mediaelement,
.mejs-container-fullscreen video {
  width: 100%;
  height: 100%
}

.mejs-clear {
  clear: both
}

.mejs-background {
  position: absolute;
  top: 0;
  left: 0
}

.mejs-mediaelement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px
}

.blog_holder.masonry .mejs-mediaelement {
  border: none
}

.mejs-poster {
  position: absolute;
  top: 0;
  left: 0;
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat
}

.mejs-poster img {
  padding: 0;
  border: 0
}

.mejs-overlay {
  position: absolute;
  top: 0;
  left: 0
}

.mejs-overlay-play {
  cursor: pointer
}

.mejs-container .mejs-controls {
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 100%
}

.mejs-container .mejs-controls div {
  list-style-type: none;
  background-image: none;
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  width: 35px;
  height: 40px;
  border: 0
}

.mejs-controls .mejs-button button {
  cursor: pointer;
  display: block;
  font-size: 0;
  line-height: 0;
  text-decoration: none;
  margin: 14px 15px 13px;
  padding: 0;
  position: absolute;
  width: 12px;
  height: 13px;
  border: 0;
  background: 0 0
}

.mejs-controls .mejs-pause button,
.mejs-controls .mejs-play button,
.mejs-controls .mejs-stop button {
  background-repeat: no-repeat;
  background-position: center
}

.no-svg .mejs-controls .mejs-button button {
  /* background-image: url(img/audio/controls.png) */
}

.mejs-controls .mejs-button button:focus {
  outline: 0
}

.mejs-container .mejs-controls .mejs-time {
  color: #000;
  display: block;
  height: 40px;
  width: auto;
  padding: 0;
  overflow: hidden;
  text-align: center;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

.mejs-container .mejs-controls .mejs-time span {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  line-height: 40px;
  display: block;
  float: left;
  margin: 0;
  width: auto
}

.mejs-controls .mejs-play button {
  /* background-image: url(img/audio/play.png) */
}

.mejs-controls .mejs-pause button {
  /* background-image: url(img/audio/pause.png) */
}

.mejs-controls .mejs-stop button {
  /* background-image: url(img/audio/stop.png) */
}

.mejs-controls div.mejs-time-rail {
  direction: ltr;
  width: 200px
}

.mejs-controls .mejs-time-rail span {
  display: block;
  position: absolute;
  height: 8px;
  margin: 16px 15px;
  cursor: pointer
}

.mejs-controls .mejs-time-rail .mejs-time-total {
  background-color: #ddd
}

.mejs-controls .mejs-time-rail .mejs-time-buffering {
  width: 100%;
  background-image: -o-linear-gradient(-45deg, rgba(224, 222, 222, .15) 25%, transparent 25%, transparent 50%, rgba(224, 222, 222, .15) 50%, rgba(224, 222, 222, .15) 75%, transparent 75%, transparent);
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(224, 222, 222, .15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(224, 222, 222, .15)), color-stop(.75, rgba(224, 222, 222, .15)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(-45deg, rgba(224, 222, 222, .15) 25%, transparent 25%, transparent 50%, rgba(224, 222, 222, .15) 50%, rgba(224, 222, 222, .15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(-45deg, rgba(224, 222, 222, .15) 25%, transparent 25%, transparent 50%, rgba(224, 222, 222, .15) 50%, rgba(224, 222, 222, .15) 75%, transparent 75%, transparent);
  background-image: -ms-linear-gradient(-45deg, rgba(224, 222, 222, .15) 25%, transparent 25%, transparent 50%, rgba(224, 222, 222, .15) 50%, rgba(224, 222, 222, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(224, 222, 222, .15) 25%, transparent 25%, transparent 50%, rgba(224, 222, 222, .15) 50%, rgba(224, 222, 222, .15) 75%, transparent 75%, transparent);
  -webkit-background-size: 15px 15px;
  -moz-background-size: 15px 15px;
  -o-background-size: 15px 15px;
  background-size: 15px 15px;
  -webkit-animation: buffering-stripes 2s linear infinite;
  -moz-animation: buffering-stripes 2s linear infinite;
  -ms-animation: buffering-stripes 2s linear infinite;
  -o-animation: buffering-stripes 2s linear infinite;
  animation: buffering-stripes 2s linear infinite
}

@-webkit-keyframes buffering-stripes {
  from {
    background-position: 0 0
  }

  to {
    background-position: 30px 0
  }
}

@-moz-keyframes buffering-stripes {
  from {
    background-position: 0 0
  }

  to {
    background-position: 30px 0
  }
}

@-ms-keyframes buffering-stripes {
  from {
    background-position: 0 0
  }

  to {
    background-position: 30px 0
  }
}

@-o-keyframes buffering-stripes {
  from {
    background-position: 0 0
  }

  to {
    background-position: 30px 0
  }
}

@keyframes buffering-stripes {
  from {
    background-position: 0 0
  }

  to {
    background-position: 30px 0
  }
}

.mejs-controls .mejs-time-rail .mejs-time-loaded {
  width: 0;
  margin: 0
}

.mejs-controls .mejs-time-rail .mejs-time-current {
  background-color: #1abc9c;
  margin: 0
}

.mejs-controls .mejs-time-rail .mejs-time-handle {
  display: none;
  position: absolute;
  margin: 0;
  width: 10px;
  background-color: #1abc9c;
  cursor: pointer;
  top: 0;
  text-align: center
}

.mejs-controls .mejs-time-rail .mejs-time-float {
  position: absolute;
  display: none;
  width: 36px;
  height: 15px;
  top: -35px;
  margin-left: -18px;
  text-align: center;
  color: #000
}

.mejs-controls .mejs-time-rail .mejs-time-float-current {
  margin: 2px;
  width: 30px;
  display: block;
  text-align: center;
  left: 0
}

.mejs-controls .mejs-time-rail .mejs-time-float-corner {
  display: none
}

.mejs-long-video .mejs-controls .mejs-time-rail .mejs-time-float {
  width: 48px
}

.mejs-long-video .mejs-controls .mejs-time-rail .mejs-time-float-current {
  width: 44px
}

.mejs-long-video .mejs-controls .mejs-time-rail .mejs-time-float-corner {
  left: 18px
}

.mejs-controls .mejs-time-rail span {
  width: 100%
}

.mejs-container.wp-audio-shortcode,
.mejs-container.wp-audio-shortcode * {
  font-family: inherit
}

.wp-audio-shortcode .mejs-controls .mejs-time-rail .mejs-time-current {
  background-color: #1abc9c
}

.wp-audio-shortcode .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.wp-audio-shortcode .mejs-controls .mejs-time-rail .mejs-time-total {
  background-color: #ddd
}

.mejs-container.wp-audio-shortcode,
.mejs-container.wp-audio-shortcode .mejs-controls,
.mejs-embed body,
.wp-audio-shortcode .mejs-embed {
  background: #fff
}

.wp-audio-shortcode .mejs-controls .mejs-time-rail>span {
  width: calc(100% - 30px)
}

.wp-audio-shortcode .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
  top: 0;
  background-color: #1abc9c
}

.wp-audio-shortcode .mejs-controls .mejs-replay button {
  /* background-image: url(img/audio/play.png) */
}

.wp-audio-shortcode .mejs-time-rail .mejs-time-hovered.negative {
  display: none
}

.wp-audio-shortcode .mejs-controls .mejs-time-rail .mejs-time-float {
  border: none;
  background: 0 0;
  margin-left: 0
}

.mejs-controls .mejs-mute button,
.mejs-controls .mejs-volume-button {
  /* background-image: url(img/audio/sound.png); */
  background-repeat: no-repeat;
  background-position: center
}

.mejs-controls .mejs-unmute button {
  /* background-image: url(img/audio/mute.png); */
  background-repeat: no-repeat;
  background-position: center
}

.mejs-controls .mejs-volume-button {
  position: relative
}

.mejs-controls .mejs-volume-button button {
  width: 18px;
  height: 16px;
  margin: 12px 6px 12px 11px
}

.mejs-controls .mejs-volume-button .mejs-volume-slider {
  display: none;
  height: 115px;
  width: 25px;
  /* background-image: url(img/audio/sound.png); */
  background-repeat: no-repeat;
  background-position: center;
  top: -115px;
  left: 0;
  z-index: 1;
  position: absolute;
  margin: 0
}

.mejs-controls .mejs-volume-button .mejs-volume-slider .mejs-volume-current,
.mejs-controls .mejs-volume-button .mejs-volume-slider .mejs-volume-total {
  position: absolute;
  left: 11px;
  top: 8px;
  width: 2px;
  height: 100px;
  margin: 0
}

.mejs-controls .mejs-volume-button .mejs-volume-slider .mejs-volume-handle {
  position: absolute;
  left: 4px;
  top: -3px;
  width: 16px;
  height: 6px;
  cursor: N-resize;
  margin: 0
}

.mejs-controls div.mejs-horizontal-volume-slider {
  height: 40px;
  width: 45px;
  position: relative
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
  position: absolute;
  left: 0;
  top: 16px;
  width: 35px;
  height: 8px;
  margin: 0;
  padding: 0;
  font-size: 1px;
  background-color: #ddd
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
  position: absolute;
  left: 0;
  top: 16px;
  width: 40px;
  height: 8px;
  margin: 0;
  padding: 0;
  font-size: 1px;
  background-color: #1abc9c
}

.mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-handle {
  display: none
}

.me-cannotplay a {
  color: #fff;
  font-weight: 700
}

.me-cannotplay span {
  padding: 15px;
  display: block
}

.mejs-controls .mejs-loop-off button {
  background-position: -64px -16px
}

.mejs-controls .mejs-loop-on button {
  background-position: -64px 0
}

.mejs-controls .mejs-backlight-off button {
  background-position: -80px -16px
}

.mejs-controls .mejs-backlight-on button {
  background-position: -80px 0
}

.q_line_graf_holder,
.q_pie_graf_holder {
  display: block;
  position: relative;
  overflow: hidden
}

.q_line_graf,
.q_pie_graf {
  margin: 0 22px 0 0;
  float: left
}

.q_pie_graf_legend {
  position: relative;
  float: left;
  width: 40%
}

.q_line_graf_legend {
  position: relative;
  float: left;
  width: 20%
}

.q_line_graf_legend ul,
.q_pie_graf_legend ul {
  list-style: none;
  padding: 0
}

.q_line_graf_legend ul li,
.q_pie_graf_legend ul li {
  display: block;
  margin: 0 0 10px
}

.q_line_graf_legend ul li .color_holder,
.q_pie_graf_legend ul li .color_holder {
  width: 25px;
  height: 25px;
  background-color: #1abc9c;
  float: left
}

.q_line_graf_legend ul li p,
.q_pie_graf_legend ul li p {
  line-height: 25px;
  margin: 0;
  padding: 0 0 0 50px
}

.header_top .right #lang_sel ul ul img.iclflag,
.header_top .right #lang_sel_click ul ul img.iclflag {
  float: right;
  top: 11px;
  margin-right: 0;
  margin-left: 15px
}

.header_top .left #lang_sel ul ul img.iclflag,
.header_top .left #lang_sel_click ul ul img.iclflag {
  float: left;
  top: 11px
}

.header_top .right #lang_sel ul ul,
.header_top .right #lang_sel_click ul ul {
  left: auto;
  right: 0
}

.header_top #lang_sel_click ul ul {
  padding: 5px 15px !important
}

.header_top .right #lang_sel ul ul li,
.header_top .right #lang_sel_click ul ul li {
  width: 100%;
  text-align: right
}

.header_top .left #lang_sel ul ul li,
.header_top .left #lang_sel_click ul ul li {
  width: 100%
}

.header_top #lang_sel ul ul li img.iclflag,
.header_top #lang_sel_click ul ul li img.iclflag {
  position: relative;
  top: 11px
}

.header_top #lang_sel li,
.header_top #lang_sel_click li {
  width: auto
}

.header_top #lang_sel,
.header_top #lang_sel_click {
  font-family: inherit;
  float: left;
  padding: 0;
  z-index: 1000;
  height: 33px;
  position: relative
}

.header_top #lang_sel ul>li>a,
.header_top #lang_sel_click ul>li>a {
  width: auto;
  float: none;
  padding: 0 15px !important
}

.header_top #lang_sel>ul>li:hover,
.header_top #lang_sel_click>ul>li:hover {
  border-bottom: none !important
}

.header_top #lang_sel>ul>li>a,
.header_top #lang_sel_click>ul>li>a {
  color: #777 !important;
  line-height: 33px !important;
  height: 33px;
  z-index: 2500;
  font-size: 13px
}

.header_top #lang_sel>ul>li>a:hover,
.header_top #lang_sel_click>ul>li>a:hover {
  color: #1abc9c !important
}

.header_top .left #lang_sel>ul>li>a,
.header_top .left #lang_sel_click>ul>li>a {
  left: 0;
  right: auto
}

.header_top #lang_sel>ul>li:hover>a,
.header_top #lang_sel>ul>li>a:hover,
.header_top #lang_sel_click>ul>li:hover>a,
.header_top #lang_sel_click>ul>li>a:hover {
  top: 0
}

.header_top #lang_sel ul>li ul>li,
.header_top #lang_sel_click ul>li ul>li {
  padding: 0 !important
}

.header_top #lang_sel ul>li a.lang_sel_sel,
.header_top #lang_sel_click ul>li a.lang_sel_sel {
  background: 0;
  line-height: 30px;
  border: 0;
  padding: 0
}

.header_top .right #lang_sel ul>li a.lang_sel_sel,
.header_top .right #lang_sel_click ul>li a.lang_sel_sel {
  text-align: right
}

.header_top .right #lang_sel ul>li a.lang_sel_sel {
  padding-right: 0 !important
}

.header_top .left #lang_sel ul>li a.lang_sel_sel {
  padding-left: 0 !important
}

.header_top #lang_sel .lang_sel_sel:after,
.header_top #lang_sel_click .lang_sel_sel:after {
  content: "\f107";
  font-family: FontAwesome, sans-serif;
  margin-left: 5px;
  border: none;
  position: static
}

.header_top .left #lang_sel ul>li a.lang_sel_sel,
.header_top .left #lang_sel_click ul>li a.lang_sel_sel {
  text-align: left
}

.header_bottom .main_menu .submenu-languages {
  display: none
}

.header_top #lang_sel ul ul,
.header_top #lang_sel_click ul ul {
  height: auto;
  border-top: none;
  background-color: #262626;
  z-index: 1000;
  position: absolute;
  list-style: none;
  top: 33px;
  left: -1px;
  width: 180px
}

.header_top #lang_sel ul li ul li a,
.header_top #lang_sel ul li ul li a:visited,
.header_top #lang_sel_click ul li ul li a,
.header_top #lang_sel_click ul li ul li a:visited {
  background: 0;
  border: 0;
  color: #9d9d9d;
  display: block;
  height: 38px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  line-height: 38px;
  padding: 0 15px !important;
  border-bottom: 1px solid #303030;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.header_top #lang_sel ul li ul li:last-child a,
.header_top #lang_sel_click ul li ul li:last-child a {
  border-bottom: none
}

.header_top #lang_sel ul li ul li a:hover,
.header_top #lang_sel_click ul li ul li a:hover {
  color: #fff
}

.header_top #lang_sel_list {
  font-family: inherit;
  height: auto;
  width: auto;
  float: left
}

.header_top #lang_sel_list ul {
  border: 0;
  padding: 0 !important;
  width: auto
}

.header_top #lang_sel_list li {
  display: inline-block;
  float: none;
  width: auto
}

.header_top #lang_sel_list ul li a,
.header_top #lang_sel_list ul li a:visited {
  padding: 0 8px;
  border: 0;
  background: none;
  line-height: 33px;
  font-size: 13px;
  color: #818181;
  margin: 0 !important;
  -webkit-transition: color .3s ease-in-out;
  -moz-transition: color .3s ease-in-out;
  -ms-transition: color .3s ease-in-out;
  -o-transition: color .3s ease-in-out;
  transition: color .3s ease-in-out
}

.header_top #lang_sel_list ul li a.lang_sel_sel,
.header_top #lang_sel_list ul li a:hover {
  color: #1abc9c
}

.header_top #lang_sel img.iclflag,
.header_top #lang_sel_click img.iclflag,
.header_top #lang_sel_list img.iclflag {
  display: inline;
  float: none;
  top: 1px;
  position: relative;
  margin-right: 5px
}

.widget.posts_holder #lang_sel li:after,
.widget.posts_holder #lang_sel_click li:after {
  content: none
}

.widget #lang_sel_list,
footer #lang_sel_list,
section.side_menu #lang_sel_list {
  height: auto;
  font-family: inherit;
  width: 100%
}

.widget #lang_sel_list li,
footer #lang_sel_list li,
section.side_menu #lang_sel_list li {
  float: none;
  margin-bottom: 0 !important;
  padding: 9px 10px 9px 0 !important;
  width: auto
}

footer #lang_sel_list li {
  padding: 5px 5px 5px 0 !important
}

.widget #lang_sel li a,
.widget #lang_sel_click li a,
.widget #lang_sel_list li a,
footer #lang_sel li a,
footer #lang_sel_click li a,
footer #lang_sel_list li a,
section.side_menu #lang_sel li a,
section.side_menu #lang_sel_click li a,
section.side_menu #lang_sel_list li a {
  font-family: inherit;
  font-size: 13px;
  font-weight: 400;
  border: 0;
  color: #777
}

.widget #lang_sel_list li a,
footer #lang_sel_list li a,
section.side_menu #lang_sel_list li a {
  display: inline
}

.widget #lang_sel,
.widget #lang_sel_click,
footer #lang_sel,
footer #lang_sel_click,
section.side_menu #lang_sel,
section.side_menu #lang_sel_click {
  width: 100%
}

.widget #lang_sel>ul>li>a,
.widget #lang_sel_click>ul>li>a,
footer #lang_sel>ul>li>a,
footer #lang_sel_click>ul>li>a,
section.side_menu #lang_sel>ul>li>a,
section.side_menu #lang_sel_click>ul>li>a {
  height: 35px;
  line-height: 35px !important;
  font-family: inherit;
  padding-left: 13px;
  font-weight: 300;
  /* background-image: url(img/wc_select_arrow.png)!important; */
  background-repeat: no-repeat;
  background-position: right
}

footer #lang_sel>ul>li>a,
footer #lang_sel_click>ul>li>a,
section.side_menu #lang_sel>ul>li>a,
section.side_menu #lang_sel_click>ul>li>a {
  /* background-image: url(img/wc_select_arrow_footer.png)!important; */
  background-repeat: no-repeat;
  background-position: right
}

.widget #lang_sel>ul>li a,
.widget #lang_sel_click>ul>li a {
  background-color: #fff
}

footer #lang_sel>ul>li a,
footer #lang_sel_click>ul>li a,
section.side_menu #lang_sel>ul>li a,
section.side_menu #lang_sel_click>ul>li a {
  background-color: #262626 !important
}

footer #lang_sel ul li a,
footer #lang_sel ul ul a,
footer #lang_sel_click ul li a,
footer #lang_sel_click ul ul a,
footer #lang_sel_click ul ul a span,
section.side_menu #lang_sel ul li a,
section.side_menu #lang_sel ul ul a,
section.side_menu #lang_sel ul ul a:visited,
section.side_menu #lang_sel_click ul ul a,
section.side_menu #lang_sel_click ul ul a:visited,
section.side_menu #lang_sel_click>ul>li>a {
  color: #777 !important
}

.widget #lang_sel ul ul a,
.widget #lang_sel>ul li a,
.widget #lang_sel_click ul ul a,
.widget #lang_sel_click>ul li a,
.widget #lang_sel_list li a {
  color: #adadad;
  font-family: Raleway, sans-serif;
  background-color: #fff
}

.widget #lang_sel a.lang_sel_sel:hover,
.widget #lang_sel ul ul a:hover,
.widget #lang_sel_click a.lang_sel_sel:hover,
.widget #lang_sel_click ul ul a:hover,
.widget #lang_sel_list li a.lang_sel_sel,
.widget #lang_sel_list li a:hover {
  color: #1abc9c
}

footer #lang_sel a.lang_sel_sel,
footer #lang_sel a.lang_sel_sel:hover,
footer #lang_sel ul ul a:hover,
footer #lang_sel_click a.lang_sel_sel,
footer #lang_sel_click ul ul a:hover,
footer #lang_sel_click ul ul a:hover span,
footer #lang_sel_list a.lang_sel_sel,
footer #lang_sel_list li a:hover,
footer #lang_sel_list ul ul a:hover,
footer #lang_sel_list ul ul a:hover span,
section.side_menu #lang_sel ul li a.lang_sel_sel,
section.side_menu #lang_sel ul ul a:hover,
section.side_menu #lang_sel_click ul li a.lang_sel_sel,
section.side_menu #lang_sel_click ul ul a:hover,
section.side_menu #lang_sel_click ul ul a:hover span,
section.side_menu #lang_sel_list li a:hover,
section.side_menu a.lang_sel_sel {
  color: #fff !important
}

.widget #lang_sel li,
.widget #lang_sel_click li,
footer #lang_sel li,
footer #lang_sel_click li,
section.side_menu #lang_sel li,
section.side_menu #lang_sel_click li {
  margin-bottom: 0;
  width: 100%;
  padding: 0;
  border: none
}

.widget #lang_sel ul ul,
.widget #lang_sel_click ul ul {
  width: 100%;
  top: 32px;
  height: auto;
  border: 0;
  z-index: 1000;
  padding: 3px 0 0;
  overflow: hidden
}

footer #lang_sel ul ul,
footer #lang_sel_click ul ul,
section.side_menu #lang_sel ul ul,
section.side_menu #lang_sel_click ul ul {
  width: 100%;
  top: 32px;
  border: 0
}

section.side_menu #lang_sel li a:after,
section.side_menu #lang_sel li:before {
  display: none
}

#lang_sel ul ul a:visited,
section.side_menu #lang_sel ul ul a {
  padding: 8px 10px
}

footer #lang_sel ul ul,
footer #lang_sel_click ul ul,
section.side_menu #lang_sel_click ul ul,
section.side_menu #lang_selul ul {
  left: 0 !important
}

.widget #lang_sel ul ul a,
.widget #lang_sel ul ul a:visited,
.widget #lang_sel_click ul ul a,
.widget #lang_sel_click ul ul a:visited,
footer #lang_sel ul ul a,
footer #lang_sel ul ul a:visited,
footer #lang_sel_click ul ul a,
footer #lang_sel_click ul ul a:visited {
  padding: 10px 13px
}

.widget #lang_sel_list.lang_sel_list_vertical ul,
footer #lang_sel_list.lang_sel_list_vertical ul,
section.side_menu #lang_sel_list.lang_sel_list_vertical ul {
  height: auto;
  border-top: none
}

.widget #lang_sel_list.lang_sel_list_vertical a,
.widget #lang_sel_list.lang_sel_list_vertical a:visited,
footer #lang_sel_list.lang_sel_list_vertical a,
footer #lang_sel_list.lang_sel_list_vertical a:visited,
section.side_menu #lang_sel_list.lang_sel_list_vertical a,
section.side_menu #lang_sel_list.lang_sel_list_vertical a:visited {
  border: none;
  padding: 0
}

footer #lang_sel_list.lang_sel_list_horizontal a,
footer #lang_sel_list.lang_sel_list_horizontal a:visited,
footer #lang_sel_list.lang_sel_list_vertical a,
footer #lang_sel_list.lang_sel_list_vertical a:visited,
section.side_menu #lang_sel_list.lang_sel_list_horizontal a,
section.side_menu #lang_sel_list.lang_sel_list_horizontal a:visited,
section.side_menu #lang_sel_list.lang_sel_list_vertical a,
section.side_menu #lang_sel_list.lang_sel_list_vertical a:visited {
  background: 0 0
}

aside #lang_sel img.iclflag,
aside #lang_sel_click img.iclflag,
footer #lang_sel img.iclflag,
footer #lang_sel_click img.iclflag,
footer #lang_sel_list.lang_sel_list_horizontal a img,
footer #lang_sel_list.lang_sel_list_vertical a img,
section.side_menu #lang_sel img.iclflag,
section.side_menu #lang_sel_click img.iclflag {
  margin-right: 5px
}

.side_menu #lang_sel_list.lang_sel_list_horizontal a:hover,
.side_menu #lang_sel_list.lang_sel_list_vertical a:hover,
footer #lang_sel_list.lang_sel_list_horizontal a:hover,
footer #lang_sel_list.lang_sel_list_vertical a:hover {
  color: #fff
}

.side_menu #lang_sel_list.lang_sel_list_horizontal a,
.side_menu #lang_sel_list.lang_sel_list_vertical a,
footer #lang_sel_list.lang_sel_list_horizontal a,
footer #lang_sel_list.lang_sel_list_vertical a {
  color: #777
}

#lang_sel_footer {
  background-color: #111;
  border: none;
  z-index: 1500;
  position: relative;
  font-family: Roboto, sans-serif
}

#lang_sel_footer a {
  font-size: 13px;
  color: #777;
  -webkit-transition: color .3s ease 0s !important;
  -moz-transition: color .3s ease 0s !important;
  -o-transition: color .3s ease 0s !important;
  -ms-transition: color .3s ease 0s !important;
  transition: color .3s ease 0s !important
}

#lang_sel_footer a:hover {
  color: #fff;
  -webkit-transition: color .3s ease 0s !important;
  -moz-transition: color .3s ease 0s !important;
  -o-transition: color .3s ease 0s !important;
  -ms-transition: color .3s ease 0s !important;
  transition: color .3s ease 0s !important
}

#lang_sel_footer a img {
  margin-right: 15px
}

.ls-nav-next,
.ls-nav-prev {
  display: block !important;
  margin: -27px 0 0;
  cursor: pointer;
  opacity: .6 !important;
  filter: alpha(opacity=60) !important;
  background-image: none !important;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  top: 50%;
  position: absolute;
  z-index: 10;
  text-align: center;
  line-height: 54px
}

.ls-nav-prev {
  height: 54px;
  width: 54px;
  font-size: 14px;
  -webkit-transition: opacity .3s ease 0s !important;
  -moz-transition: opacity .3s ease 0s !important;
  -o-transition: opacity .3s ease 0s !important;
  transition: opacity .3s ease-in-out !important;
  opacity: .7;
  -webkit-border-radius: 56px;
  -moz-border-radius: 56px;
  -ms-border-radius: 56px;
  -o-border-radius: 56px;
  border-radius: 56px;
  left: 23px
}

.ls-nav-prev:after {
  position: absolute;
  left: 0 !important;
  color: #fff;
  width: 100%;
  height: 100%;
  content: "\f104";
  font-family: FontAwesome, serif;
  line-height: 54px;
  text-align: center;
  display: block !important;
  font-size: 30px
}

.ls-nav-next {
  height: 54px;
  width: 54px;
  font-size: 14px;
  -webkit-transition: opacity .3s ease 0s;
  -moz-transition: opacity .3s ease 0s;
  -o-transition: opacity .3s ease 0s;
  transition: opacity .3s ease-in-out;
  opacity: 0;
  -webkit-border-radius: 56px;
  -moz-border-radius: 56px;
  -ms-border-radius: 56px;
  -o-border-radius: 56px;
  border-radius: 56px;
  right: 23px
}

.ls-nav-next:after {
  position: absolute;
  left: 0 !important;
  color: #fff;
  width: 100%;
  height: 100%;
  content: "\f105";
  font-family: FontAwesome, serif;
  line-height: 54px;
  text-align: center;
  display: block !important;
  font-size: 30px
}

.ls-nav-next:hover,
.ls-nav-prev:hover {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important
}

.element_from_bottom,
.element_from_fade,
.element_from_left,
.element_from_right,
.element_from_top {
  display: inline-block;
  vertical-align: middle;
  width: 100%
}

.touchevents .no_animation_on_touch .element_from_bottom>div,
.touchevents .no_animation_on_touch .element_from_fade>div,
.touchevents .no_animation_on_touch .element_from_left>div,
.touchevents .no_animation_on_touch .element_from_right>div,
.touchevents .no_animation_on_touch .element_from_top>div,
.touchevents .no_animation_on_touch .element_transform>div {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none
}

.element_from_bottom>div,
.element_from_fade>div,
.element_from_left>div,
.element_from_right>div,
.element_from_top>div {
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  filter: alpha(opacity=0)
}

.element_transform>div {
  opacity: .2;
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  transform: scale(.5)
}

.element_from_fade.element_from_fade_on>div {
  opacity: 1;
  filter: alpha(opacity=100);
  transition: opacity .8s ease 0s;
  -moz-transition: opacity .8s ease 0s;
  -o-transition: opacity .8s ease 0s;
  backface-visibility: hidden
}

.q_image_with_text_over .shader,
.q_image_with_text_over .shader_hover {
  transition: opacity .4s ease-in-out;
  -moz-transition: opacity .4s ease-in-out
}

.element_from_left.element_from_left_on>div {
  -webkit-animation: element-from-left .7s 1 ease-in-out;
  -moz-animation: element-from-left .7s 1 ease-in-out;
  -o-animation: element-from-left .7s 1 ease-in-out;
  animation: element-from-left .7s 1 ease-in-out;
  opacity: 1;
  filter: alpha(opacity=100);
  backface-visibility: hidden
}

.element_from_right.element_from_right_on>div {
  -webkit-animation: element-from-right .7s 1 ease-in-out;
  -moz-animation: element-from-right .7s 1 ease-in-out;
  -o-animation: element-from-right .7s 1 ease-in-out;
  animation: element-from-right .7s 1 ease-in-out;
  opacity: 1;
  filter: alpha(opacity=100);
  backface-visibility: hidden
}

.element_from_top.element_from_top_on>div {
  -webkit-animation: element-from-top .7s 1 ease-in-out;
  -moz-animation: element-from-top .7s 1 ease-in-out;
  -o-animation: element-from-top .7s 1 ease-in-out;
  animation: element-from-top .7s 1 ease-in-out;
  opacity: 1;
  filter: alpha(opacity=100);
  backface-visibility: hidden
}

.element_from_bottom.element_from_bottom_on>div {
  -webkit-animation: element-from-bottom .7s 1 ease-in-out;
  -moz-animation: element-from-bottom .7s 1 ease-in-out;
  -o-animation: element-from-bottom .7s 1 ease-in-out;
  animation: element-from-bottom .7s 1 ease-in-out;
  opacity: 1;
  filter: alpha(opacity=100);
  backface-visibility: hidden
}

.element_transform.element_transform_on>div {
  -webkit-animation: element-transform .4s 1 cubic-bezier(.175, .885, .32, 1.275);
  -moz-animation: element-transform .4s 1 cubic-bezier(.175, .885, .32, 1.275);
  -o-animation: element-transform .4s 1 cubic-bezier(.175, .885, .32, 1.275);
  animation: element-transform .4s 1 cubic-bezier(.175, .885, .32, 1.275);
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1)
}

@-webkit-keyframes element-from-left {
  0% {
    -webkit-transform: translate(-20%, 0);
    opacity: 0
  }

  100% {
    -webkit-transform: translate(0, 0);
    opacity: 1
  }
}

@-moz-keyframes element-from-left {
  0% {
    -moz-transform: translate(-20%, 0);
    opacity: 0
  }

  100% {
    -moz-transform: translate(0, 0);
    opacity: 1
  }
}

@-o-keyframes element-from-left {
  0% {
    -o-transform: translate(-20%, 0);
    opacity: 0
  }

  100% {
    -o-transform: translate(0, 0);
    opacity: 1
  }
}

@keyframes element-from-left {
  0% {
    transform: translate(-20%, 0);
    opacity: 0
  }

  100% {
    transform: translate(0, 0);
    opacity: 1
  }
}

@-webkit-keyframes element-from-right {
  0% {
    -webkit-transform: translate(20%, 0);
    opacity: 0
  }

  100% {
    -webkit-transform: translate(0, 0);
    opacity: 1
  }
}

@-moz-keyframes element-from-right {
  0% {
    -moz-transform: translate(20%, 0);
    opacity: 0
  }

  100% {
    -moz-transform: translate(0, 0);
    opacity: 1
  }
}

@-o-keyframes element-from-right {
  0% {
    -o-transform: translate(20%, 0);
    opacity: 0
  }

  100% {
    -o-transform: translate(0, 0);
    opacity: 1
  }
}

@keyframes element-from-right {
  0% {
    transform: translate(20%, 0);
    opacity: 0
  }

  100% {
    transform: translate(0, 0);
    opacity: 1
  }
}

@-webkit-keyframes element-from-bottom {
  0% {
    -webkit-transform: translate(0, 30%);
    opacity: 0
  }

  100% {
    -webkit-transform: translate(0, 0);
    opacity: 1
  }
}

@-moz-keyframes element-from-bottom {
  0% {
    -moz-transform: translate(0, 30%);
    opacity: 0
  }

  100% {
    -moz-transform: translate(0, 0);
    opacity: 1
  }
}

@-o-keyframes element-from-bottom {
  0% {
    -o-transform: translate(0, 30%);
    opacity: 0
  }

  100% {
    -o-transform: translate(0, 0);
    opacity: 1
  }
}

@keyframes element-from-bottom {
  0% {
    transform: translate(0, 30%);
    opacity: 0
  }

  100% {
    transform: translate(0, 0);
    opacity: 1
  }
}

@-webkit-keyframes element-from-top {
  0% {
    -webkit-transform: translate(0, -30%);
    opacity: 0
  }

  100% {
    -webkit-transform: translate(0, 0);
    opacity: 1
  }
}

@-moz-keyframes element-from-top {
  0% {
    -moz-transform: translate(0, -30%);
    opacity: 0
  }

  100% {
    -moz-transform: translate(0, 0);
    opacity: 1
  }
}

@-o-keyframes element-from-top {
  0% {
    -o-transform: translate(0, -30%);
    opacity: 0
  }

  100% {
    -o-transform: translate(0, 0);
    opacity: 1
  }
}

@keyframes element-from-top {
  0% {
    transform: translate(0, -30%);
    opacity: 0
  }

  100% {
    transform: translate(0, 0);
    opacity: 1
  }
}

@-webkit-keyframes element-transform {
  0% {
    -webkit-transform: scale(.3);
    opacity: .1
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1
  }
}

@-moz-keyframes element-transform {
  0% {
    -moz-transform: scale(.3);
    opacity: .1
  }

  100% {
    -moz-transform: scale(1);
    opacity: 1
  }
}

@-o-keyframes element-transform {
  0% {
    -o-transform: scale(.3);
    opacity: .1
  }

  100% {
    -o-transform: scale(1);
    opacity: 1
  }
}

@keyframes element-transform {
  0% {
    transform: scale(.3);
    opacity: .1
  }

  100% {
    transform: scale(1);
    opacity: 1
  }
}

.q_image_with_text_over {
  display: inline-block;
  position: relative;
  margin: 0;
  width: 100%
}

.q_image_with_text_over.one_half {
  width: 50%
}

.q_image_with_text_over.one_third {
  width: 33.33%
}

.q_image_with_text_over.one_fourth {
  width: 25%
}

.q_image_with_text_over img {
  display: block;
  position: relative;
  width: 100%;
  z-index: 10
}

.q_image_with_text_over .shader,
.q_image_with_text_over .shader_hover,
.q_image_with_text_over .text,
.q_image_with_text_over table {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0
}

.q_image_with_text_over .shader {
  z-index: 20;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .4s ease-in-out
}

.q_image_with_text_over .shader_hover {
  z-index: 20;
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  transition: opacity .4s ease-in-out
}

.q_image_with_text_over .caption,
.q_image_with_text_over .desc,
.q_image_with_text_over .icon_holder {
  transition: opacity .4s ease-in-out;
  -moz-transition: opacity .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out;
  -ms-transition: opacity .4s ease-in-out;
  transform: translateZ(0);
}

.q_image_with_text_over.q_iwto_hover:hover .shader {
  opacity: 0
}

.q_image_with_text_over.q_iwto_hover:hover .shader_hover {
  opacity: 1
}

.q_image_with_text_over .text {
  z-index: 30;
  text-align: center
}

.q_image_with_text_over table td {
  padding: 0;
  vertical-align: middle;
  background: 0 0 !important
}

.q_image_with_text_over .caption,
.q_image_with_text_over .icon_holder {
  opacity: 1;
  filter: alpha(opacity=100)
}

.q_image_with_text_over .desc,
.q_image_with_text_over .text:hover .caption,
.q_image_with_text_over .text:hover .icon_holder {
  opacity: 0;
  filter: alpha(opacity=0)
}

.q_image_with_text_over .caption {
  line-height: 1em;
  margin: 2% 0 0
}

.q_image_with_text_over .caption,
.q_image_with_text_over .icon_holder,
.q_image_with_text_over .text p {
  color: #fff
}

.q_image_with_text_over .icon_holder {
  display: inline-block
}

.q_image_with_text_over .icon_holder.fa-2x {
  padding: .67em .7em
}

.q_image_with_text_over .icon_holder.fa-3x {
  padding: .6em .62em
}

.q_image_with_text_over .caption.no_icon {
  margin: 0
}

.q_image_with_text_over .desc {
  margin: 0 30px;
  backface-visibility: hidden;
  color: #fff
}

.q_image_with_text_over .text:hover .desc {
  opacity: 1;
  filter: alpha(opacity=100)
}

.q_team {
  background-color: #fff;
  overflow: hidden
}

.q_team_inner {
  overflow: hidden;
  width: 100%
}

.q_team .q_team_image {
  position: relative
}

.q_team .q_team_image img {
  width: 100%;
  vertical-align: middle
}

.q_team .q_team_description_wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .95);
  opacity: 0;
  -webkit-transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -ms-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out;
  cursor: default
}

.full_screen_navigation_holder,
.q_team.info_on_hover .q_team_text {
  transition: opacity .3s ease-in-out;
  -ms-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out
}

.q_team .q_team_image:hover .q_team_description_wrapper {
  opacity: 1
}

.q_team .q_team_description {
  display: table;
  width: 100%;
  height: 100%
}

.q_team .q_team_description_inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0 50px
}

.q_team .q_team_description_inner p {
  color: #303030
}

.q_team .q_team_text {
  padding: 23px 16px;
  border-style: none;
  border-width: 1px;
  border-color: #f6f6f6;
  border-top: none !important
}

.q_team .q_team_title_holder {
  margin: 0 0 9px;
  text-align: center
}

.q_team .q_team_title_holder .q_team_name {
  margin-bottom: 7px
}

.q_team .q_team_text_inner .separator {
  background-color: #1abc9c;
  margin-bottom: 16px !important
}

.q_team .q_team_social_holder {
  position: relative;
  text-align: center
}

.q_team .q_team_social_holder .q_social_icon_holder i.simple_social,
.q_team .q_team_social_holder .q_social_icon_holder span.simple_social {
  font-size: 19px;
  color: #bcbcbc
}

.q_team .q_team_social_holder .q_social_icon_holder:hover i.simple_social,
.q_team .q_team_social_holder .q_social_icon_holder:hover span.simple_social {
  color: #1abc9c
}

.q_team.info_on_hover .q_team_description_wrapper {
  position: relative;
  opacity: 1;
  background-color: transparent;
  box-sizing: border-box
}

.q_team.info_on_hover .q_team_text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(21, 21, 21, .78);
  padding: 0;
  opacity: 0;
  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out
}

.q_team.info_on_hover .q_team_text_holder {
  display: table;
  width: 100%;
  height: 100%
}

.q_team.info_on_hover .q_team_text_holder_inner {
  display: table-cell;
  vertical-align: middle
}

.circle_left,
.circle_top {
  display: inline-block;
  width: 100%
}

.q_team.info_on_hover .q_team_image:hover .q_team_text {
  opacity: 1
}

.q_team.info_on_hover .q_team_social_holder i.simple_social,
.q_team.info_on_hover .q_team_social_holder span.simple_social,
.q_team.info_on_hover .q_team_title_holder .q_team_name,
.q_team.info_on_hover .q_team_title_holder span {
  color: #fff
}

.q_team.info_description_below_image .q_team_image_holder {
  overflow: hidden
}

.q_team.info_description_below_image .q_team_image_holder img {
  -webkit-transition: transform .3s ease;
  -moz-transition: transform .3s ease;
  transition: transform .3s ease
}

.q_team.info_description_below_image .q_team_image_holder:hover img {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2)
}

.q_team.info_description_below_image.qode_team_disabled_hover .q_team_image_holder img {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none
}

.q_team.info_description_below_image.qode_team_disabled_hover .q_team_image_holder:hover img {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.circle_item .circle {
  font-size: 15px;
  line-height: 13px;
  width: 155px;
  height: 155px;
  display: inline-block;
  text-align: center;
  color: #000;
  background-color: #fbfbfb;
  -o-border-radius: 182px;
  -moz-border-radius: 182px;
  -webkit-border-radius: 182px;
  border-radius: 182px;
  transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transform: translateZ(0);
  -moz-transform: translateZ(0)
}

.circle_item .circle div {
  padding: 69.5px 0
}

.circle_left .circle {
  float: left
}

.circle_left .text {
  padding: 0 10px 0 170px
}

.circle_top {
  text-align: center
}

.circle_top .circle {
  margin: 0 auto
}

.circle_top .text {
  margin: 25px 0 0
}

.circle_top .text p {
  margin: 0
}

.circle_item .circle a {
  text-decoration: none
}

.circle_item .circle:hover {
  background-color: #1abc9c;
  color: #fff
}

.circle_item .circle.hover {
  cursor: pointer
}

.circle_item .circle:hover a {
  color: #fff
}

.fade_in_circle_holder.animate_circle {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.fade_in_circle_holder {
  cursor: pointer;
  display: table;
  position: relative;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(.1);
  -moz-transform: scale(.1);
  transform: scale(.1);
  -ms-transform: scale(.1);
  -webkit-transition: all .4s ease-in-out;
  -moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -ms-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  backface-visibility: hidden;
  z-index: 2000
}

.touchevents .no_animation_on_touch .fade_in_circle_holder {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.image_hover {
  position: relative;
  display: inline-block;
  width: 100%
}

.image_hover .images_holder {
  position: relative;
  display: inline-block;
  max-width: 100%;
  vertical-align: middle
}

.image_hover .images_holder img.hover_image {
  position: absolute;
  top: 0;
  left: 0
}

.image_hover .images_holder img.active_image {
  opacity: 1;
  filter: alpha(opacity=100);
  position: relative;
  display: block
}

.image_hover .images_holder img.active_image,
.image_hover .images_holder img.hover_image,
.image_hover.hovered.show .images_holder img.active_image,
.image_hover.hovered.show .images_holder img.hover_image {
  transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.image_hover .images_holder img.hover_image,
.image_hover .images_holder:hover img.active_image,
.image_hover.hovered.show .images_holder img.active_image {
  opacity: 0;
  filter: alpha(opacity=0)
}

.carousel.fade .active.item,
.carousel.fade .item,
.image_hover .images_holder:hover img.hover_image,
.image_hover.hovered.show .images_holder img.hover_image {
  opacity: 1;
  filter: alpha(opacity=100)
}

.content_bottom {
  position: relative;
  z-index: 100
}

.qode_call_to_action.container {
  background-color: #1abc9c
}

.qode_call_to_action.in_grid {
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px
}

.qode_call_to_action.container .container_inner {
  padding: 40px 0
}

.call_to_action_text_wrapper p {
  font-size: 19px;
  font-weight: 300;
  line-height: 1.692307692307692em;
  color: #fff;
  text-align: center
}

.qode_call_to_action .call_to_action_text_wrapper {
  line-height: 40px
}

.qode_call_to_action .qbutton {
  position: static
}

.call_to_action_button_wrapper,
.call_to_action_button_wrapper.left {
  text-align: left
}

.qode_call_to_action .two_columns_75_25>.column2.left>.column_inner {
  padding: 0 20px 0 0
}

.qode_call_to_action.in_grid .two_columns_75_25>.column1>.column_inner,
.qode_call_to_action.in_grid .two_columns_75_25>.column2.left>.column_inner {
  padding-left: 28px
}

.qode_call_to_action.in_grid .two_columns_75_25>.column1.left>.column_inner,
.qode_call_to_action.in_grid .two_columns_75_25>.column2>.column_inner {
  padding-right: 28px
}

.qode_call_to_action .two_columns_75_25>.column1.left>.column_inner {
  padding: 0
}

.qode_call_to_action .two_columns_75_25>.column1.left {
  text-align: right
}

.carousel {
  position: relative;
  margin-bottom: 0
}

.boxed .carousel-inner {
  width: 1150px;
  left: auto !important
}

.carousel-inner .video {
  position: static;
  left: 0;
  direction: ltr
}

.carousel-inner>.item {
  position: relative;
  display: none;
  -webkit-transition: .6s ease-in-out left;
  transition: .6s ease-in-out left;
  box-sizing: border-box
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img {
  display: block;
  height: auto;
  max-width: 100%;
  line-height: 1
}

.carousel.vertical_down:not(.has_height) .carousel-inner,
.carousel.vertical_up:not(.has_height) .carousel-inner {
  height: 100%
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
  display: block
}

.carousel-inner>.active {
  left: 0
}

.carousel-inner>.next,
.carousel-inner>.prev {
  position: absolute;
  top: 0;
  width: 100%
}

.carousel-inner>.next {
  left: 100%
}

.carousel-inner>.prev {
  left: -100%
}

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
  left: 0
}

.carousel-inner>.active.left {
  left: -100%
}

.carousel-inner>.active.right {
  left: 100%
}

.carousel.fade .item {
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out
}

.carousel.fade .active.left,
.carousel.fade .active.right {
  left: 0;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=0)
}

.carousel.fade .next,
.carousel.fade .prev {
  left: 0;
  z-index: 1
}

.carousel.vertical_up .carousel-inner>.item {
  -webkit-transition: -webkit-transform .6s ease;
  -moz-transition: -moz-transform .6s ease;
  -ms-transition: -ms-transform .6s ease;
  -o-transition: -o-transform .6s ease;
  transition: transform .6s ease
}

.carousel.vertical_up .carousel-inner>.active {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0)
}

.carousel.vertical_up .carousel-inner>.next {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  left: 0
}

.carousel.vertical_up .carousel-inner>.prev {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  left: 0
}

.carousel.vertical_up .carousel-inner>.next.left,
.carousel.vertical_up .carousel-inner>.prev.right {
  left: 0;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0)
}

.carousel.vertical_up .carousel-inner>.active.left {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  left: 0
}

.carousel.vertical_up .carousel-inner>.active.right {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  left: 0
}

.carousel.vertical_down .carousel-inner>.item {
  -webkit-transition: -webkit-transform .6s ease;
  -moz-transition: -moz-transform .6s ease;
  -ms-transition: -ms-transform .6s ease;
  -o-transition: -o-transform .6s ease;
  transition: transform .6s ease
}

.carousel.vertical_down .carousel-inner>.active {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0)
}

.carousel.vertical_down .carousel-inner>.next {
  -webkit-transform: translateY(-99%);
  -moz-transform: translateY(-99%);
  -ms-transform: translateY(-99%);
  -o-transform: translateY(-99%);
  transform: translateY(-99%);
  left: 0
}

.carousel.vertical_down .carousel-inner>.prev {
  -webkit-transform: translateY(99%);
  -moz-transform: translateY(99%);
  -ms-transform: translateY(99%);
  -o-transform: translateY(99%);
  transform: translateY(99%);
  left: 0
}

.carousel.vertical_down .carousel-inner>.next.left,
.carousel.vertical_down .carousel-inner>.prev.right {
  left: 0;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0)
}

.carousel.vertical_down .carousel-inner>.active.left {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  left: 0
}

.carousel.vertical_down .carousel-inner>.active.right {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  left: 0
}

.carousel.slide_cover .carousel-inner>.next {
  z-index: 99
}

.carousel.slide_cover .carousel-inner>.prev {
  z-index: 100
}

.carousel.slide_cover .carousel-inner>.active.left {
  left: -30%
}

.carousel.slide_cover .carousel-inner>.active.right {
  left: 30%
}

.carousel-indicators {
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none
}

.carousel-indicators li {
  display: inline-block;
  width: 8px;
  height: 8px;
  text-indent: -999px;
  cursor: pointer;
  border-radius: 10px
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 40px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .6)
}

.carousel-caption .btn {
  text-shadow: none
}

@media screen and (min-width: 768px) {

  .carousel-control .icon-next,
  .carousel-control .icon-prev {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    font-size: 30px
  }

  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px
  }
}

.carousel.full_screen {
  height: 1500px
}

.qode_slider_preloader {
  width: 100%;
  background-color: #1c1c1c;
  position: absolute;
  z-index: 20;
  height: 1500px
}

.qode_slider_preloader .ajax_loader {
  position: absolute
}

.carousel-inner .slider_content_outer {
  position: relative;
  height: 100%;
  width: 1100px;
  margin: 0 auto;
  z-index: 12
}

.carousel-inner .slide_full_width .slider_content_outer {
  width: 100% !important
}

.carousel-inner .slider_content {
  position: absolute;
  width: 100%
}

.carousel-inner .item.content_vertical_middle .slider_content_outer {
  display: table
}

.carousel-inner .item.content_vertical_middle .slider_content {
  display: table-cell;
  vertical-align: middle;
  position: static
}

.carousel-inner .item.content_vertical_middle .slider_content .thumb {
  height: auto
}

.carousel-inner .item.content_vertical_middle {
  padding-top: 0 !important
}

.carousel-inner .slider_content .slide_anchor_holder {
  margin-top: 8%
}

.carousel-inner .item.dark .slider_content .text .slide_anchor_holder .slide_anchor_button {
  color: #000
}

.carousel-inner .slider_content .slide_anchor_holder .slide_anchor_button {
  font-size: 51px;
  color: #fff
}

.carousel-inner .slider_content .thumb {
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
  display: inline-block;
  vertical-align: middle
}

.carousel-inner .slider_content.left {
  text-align: left
}

.carousel-inner .slider_content.right {
  text-align: right
}

.carousel-inner .slider_content.left .thumb,
.carousel-inner .slider_content.right .thumb {
  display: inline-block
}

.carousel-inner .slider_content.center,
.carousel-inner .slider_content.center .thumb {
  text-align: center
}

.qode_slide-svg-holder svg {
  opacity: 0;
  height: auto
}

.item.active .qode_slide-svg-holder svg {
  -webkit-animation: fade .4s 1 cubic-bezier(.5, .11, .805, .32);
  -moz-animation: fade .4s 1 cubic-bezier(.5, .11, .805, .32);
  -o-animation: fade .4s 1 cubic-bezier(.5, .11, .805, .32);
  animation: fade .4s 1 cubic-bezier(.5, .11, .805, .32);
  opacity: 1
}

.carousel-inner .active .slider_content .thumb {
  -webkit-animation: rotate 1s 1 ease-out;
  -moz-animation: rotate 1s 1 ease-out;
  -o-animation: rotate 1s 1 ease-out;
  animation: rotate 1s 1 ease-out;
  opacity: 1;
  filter: alpha(opacity=100)
}

.carousel-inner .active .slider_content .thumb.fade {
  -webkit-animation: fade 1s 1 ease-out;
  -moz-animation: fade 1s 1 ease-out;
  -o-animation: fade 1s 1 ease-out;
  animation: fade 1s 1 ease-out
}

.carousel-inner .slider_content .thumb img {
  max-height: 100%
}

.carousel-inner .slider_content .text {
  text-align: center;
  opacity: 0;
  filter: alpha(opacity=0);
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0)
}

.carousel-inner .active .slider_content .text {
  -webkit-animation: text-from-bottom 1.5s 1 cubic-bezier(.165, .84, .44, 1);
  -moz-animation: text-from-bottom 1.5s 1 cubic-bezier(.165, .84, .44, 1);
  -o-animation: text-from-bottom 1.5s 1 cubic-bezier(.165, .84, .44, 1);
  animation: text-from-bottom 1.5s 1 cubic-bezier(.165, .84, .44, 1);
  opacity: 1;
  filter: alpha(opacity=100)
}

.carousel-inner .slider_content .text.one_by_one {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
  opacity: 1
}

.carousel-inner .slider_content .text.one_by_one .separator,
.carousel-inner .slider_content .text.one_by_one .slide_anchor_holder,
.carousel-inner .slider_content .text.one_by_one a.qbutton,
.carousel-inner .slider_content .text.one_by_one a.qode-btn,
.carousel-inner .slider_content .text.one_by_one h2,
.carousel-inner .slider_content .text.one_by_one h4,
.carousel-inner .slider_content .text.one_by_one p {
  opacity: 0;
  filter: alpha(opacity=0);
  position: relative;
  top: 70px
}

.carousel-inner .active .slider_content .text.one_by_one .separator,
.carousel-inner .active .slider_content .text.one_by_one .slide_anchor_holder,
.carousel-inner .active .slider_content .text.one_by_one a.qbutton,
.carousel-inner .active .slider_content .text.one_by_one a.qode-btn,
.carousel-inner .active .slider_content .text.one_by_one h2,
.carousel-inner .active .slider_content .text.one_by_one h4,
.carousel-inner .active .slider_content .text.one_by_one p {
  -webkit-animation: text-from-bottom-one-by-one 1.2s 1 cubic-bezier(.165, .84, .44, 1) 0s;
  -moz-animation: text-from-bottom-one-by-one 1.2s 1 cubic-bezier(.165, .84, .44, 1) 0s;
  -o-animation: text-from-bottom-one-by-one 1.2s 1 cubic-bezier(.165, .84, .44, 1) 0s;
  animation: text-from-bottom-one-by-one 1.2s 1 cubic-bezier(.165, .84, .44, 1) 0s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  opacity: 1;
  filter: alpha(opacity=100)
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.no_separator h2 {
  animation-delay: .15s;
  -webkit-animation-delay: .15s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.no_separator p {
  animation-delay: .3s;
  -webkit-animation-delay: .3s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.no_separator a.qbutton,
.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.no_separator a.qode-btn {
  animation-delay: .45s;
  -webkit-animation-delay: .45s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.no_separator .slide_anchor_holder {
  animation-delay: .6s;
  -webkit-animation-delay: .6s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.has_separator h2 {
  animation-delay: .15s;
  -webkit-animation-delay: .15s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.has_separator .separator {
  animation-delay: .3s;
  -webkit-animation-delay: .3s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.has_separator p {
  animation-delay: .45s;
  -webkit-animation-delay: .45s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.has_separator a.qbutton,
.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.has_separator a.qode-btn {
  animation-delay: .6s;
  -webkit-animation-delay: .6s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_above_title.has_separator .slide_anchor_holder {
  animation-delay: .75s;
  -webkit-animation-delay: .75s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.no_separator h4 {
  animation-delay: .15s;
  -webkit-animation-delay: .15s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.no_separator p {
  animation-delay: .3s;
  -webkit-animation-delay: .3s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.no_separator a.qbutton,
.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.no_separator a.qode-btn {
  animation-delay: .45s;
  -webkit-animation-delay: .45s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.no_separator .slide_anchor_holder {
  animation-delay: .6s;
  -webkit-animation-delay: .6s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.has_separator h4 {
  animation-delay: .15s;
  -webkit-animation-delay: .15s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.has_separator .separator {
  animation-delay: .3s;
  -webkit-animation-delay: .3s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.has_separator p {
  animation-delay: .45s;
  -webkit-animation-delay: .45s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.has_separator a.qbutton,
.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.has_separator a.qode-btn {
  animation-delay: .6s;
  -webkit-animation-delay: .6s
}

.carousel-inner .active .slider_content .text.one_by_one.subtitle_bellow_title.has_separator .slide_anchor_holder {
  animation-delay: .75s;
  -webkit-animation-delay: .75s
}

.carousel-inner .active .slider_content .text.one_by_one.no_subtitle.no_separator p {
  animation-delay: .2s;
  -webkit-animation-delay: .2s
}

.carousel-inner .active .slider_content .text.one_by_one.no_subtitle.no_separator a.qbutton,
.carousel-inner .active .slider_content .text.one_by_one.no_subtitle.no_separator a.qode-btn {
  animation-delay: .4s;
  -webkit-animation-delay: .4s
}

.carousel-inner .active .slider_content .text.one_by_one.no_subtitle.no_separator .slide_anchor_holder {
  animation-delay: .6s;
  -webkit-animation-delay: .6s
}

.carousel-inner .active .slider_content .text.one_by_one.no_subtitle.has_separator .separator {
  animation-delay: .15s;
  -webkit-animation-delay: .15s
}

.carousel-inner .active .slider_content .text.one_by_one.no_subtitle.has_separator p {
  animation-delay: .3s;
  -webkit-animation-delay: .3s
}

.carousel-inner .active .slider_content .text.one_by_one.no_subtitle.has_separator a.qbutton,
.carousel-inner .active .slider_content .text.one_by_one.no_subtitle.has_separator a.qode-btn {
  animation-delay: .45s;
  -webkit-animation-delay: .45s
}

.carousel-inner .active .slider_content .text.one_by_one.no_subtitle.has_separator .slide_anchor_holder {
  animation-delay: .6s;
  -webkit-animation-delay: .6s
}

.carousel-inner .slider_content .text .qbutton {
  margin: 10px 0 0;
  text-align: center
}

.carousel-inner .item.dark .slider_content .text .qbutton {
  border-color: #303030;
  background-color: #303030;
  color: #fff
}

.carousel-inner .item.dark .slider_content .text .qbutton.white,
.carousel-inner .item.dark .slider_content .text .qbutton:hover {
  background-color: transparent;
  color: #303030
}

.carousel-inner .item.dark .slider_content .text .qbutton.white:hover {
  background-color: #303030;
  color: #fff !important;
  border-color: #303030
}

.carousel-inner .slider_content .text .qbutton.white {
  margin: 10px 0 0 10px
}

.carousel-inner .slider_content .text .qode-btn {
  margin: 0 4px
}

.carousel-inner h2 {
  margin: 10px 0 20px;
  font-size: 45px;
  line-height: 1.071428571428571em
}

.carousel-inner h2.with_title_border {
  margin: 20px 0 30px
}

.carousel-inner h2.with_title_border span {
  display: inline-block;
  padding: .3em 1.1em
}

.carousel-inner .q_slide_subtitle,
.carousel-inner h2 {
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .4);
}

.carousel-inner h2.with_background_color span {
  padding: 10px;
}

.carousel-inner .q_slide_subtitle {
  font-size: 26px;
}

.carousel-inner .q_slide_subtitle span {
  font-size: 1em;
  margin-bottom: 13px;
  display: inline-block;
}

.carousel-inner .q_slide_subtitle.with_background_color span {
  padding: 10px;
  margin-bottom: 12px;
}

.carousel-inner p {
  color: #fff;
  text-align: center;
  font-size: 21px;
  line-height: 1.363157894736842em;
  font-weight: 400;
  margin: 10px 0 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, .4)
}

.carousel-inner .dark .q_slide_subtitle,
.carousel-inner .dark h2,
.carousel-inner .dark p {
  color: #000
}

.carousel-inner .left .q_slide_subtitle,
.carousel-inner .left .text,
.carousel-inner .left h2,
.carousel-inner .left p {
  text-align: left
}

.carousel-inner .right .q_slide_subtitle,
.carousel-inner .right .text,
.carousel-inner .right h2,
.carousel-inner .right p {
  text-align: right
}

.carousel-inner {
  left: 0 !important;
  position: fixed;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -webkit-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -webkit-transition: left .33s cubic-bezier(.694, .0482, .335, 1), margin .33s cubic-bezier(.694, .0482, .335, 1);
  -moz-transition: left .33s cubic-bezier(.694, .0482, .335, 1), margin .33s cubic-bezier(.694, .0482, .335, 1);
  -o-transition: left .33s cubic-bezier(.694, .0482, .335, 1), margin .33s cubic-bezier(.694, .0482, .335, 1);
  -ms-transition: left .33s cubic-bezier(.694, .0482, .335, 1), margin .33s cubic-bezier(.694, .0482, .335, 1);
  transition: left .33s cubic-bezier(.694, .0482, .335, 1), margin .33s cubic-bezier(.694, .0482, .335, 1)
}

.carousel-inner.relative_position {
  position: relative
}

.carousel-inner .item img {
  display: inline-block !important
}

.touchevents .carousel-inner .item {
  background-position: center 0 !important
}

.carousel-inner .item .image {
  position: absolute;
  top: 0;
  left: 0;
  background-position: center 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%
}

@media only screen and (min-width: 1000px) {
  .carousel-inner .item.animate_image.zoom_center .image {
    position: absolute;
    top: 0;
    left: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 110%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
  }

  .carousel-inner .item.animate_image.zoom_bottom_left .image,
  .carousel-inner .item.animate_image.zoom_bottom_right .image,
  .carousel-inner .item.animate_image.zoom_top_left .image,
  .carousel-inner .item.animate_image.zoom_top_right .image {
    position: absolute;
    top: -12%;
    left: -12%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 125%;
    height: 125%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
  }
}

@media only screen and (max-width: 1000px) {

  .carousel-inner .item.animate_image.zoom_bottom_left .image,
  .carousel-inner .item.animate_image.zoom_bottom_right .image,
  .carousel-inner .item.animate_image.zoom_center .image,
  .carousel-inner .item.animate_image.zoom_top_left .image,
  .carousel-inner .item.animate_image.zoom_top_right .image {
    transform: none !important;
    -webkit-transform: none !important
  }
}

.carousel-inner .item .image img {
  width: 0;
  height: 0
}

.carousel-inner .item .image_pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 0 0;
  background-repeat: repeat;
  z-index: 2
}

.carousel-control {
  top: 0;
  width: 23%;
  color: #303030;
  font-size: 13px;
  height: 100%;
  background: 0 0;
  text-shadow: none;
  position: absolute;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border: none;
  z-index: 2 !important;
  margin: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color .6s ease-in-out, opacity .3s ease-in-out;
  -moz-transition: color .6s ease-in-out, opacity .3s ease-in-out;
  -o-transition: color .6s ease-in-out, opacity .3s ease-in-out;
  transform: color .6s ease-in-out, opacity .3s ease-in-out;
  transition: color .6s ease-in-out, opacity .3s ease-in-out
}

.carousel-control.right {
  right: 0;
  left: auto
}

.carousel-control .next_nav,
.qode-item-showcase .qode-item-showcase-holder.qode-holder-left .qode-item-line {
  right: 23px
}

.carousel-control .prev_nav,
.qode-item-showcase .qode-item-showcase-holder.qode-holder-right .qode-item-line {
  left: 23px
}

.carousel-control:hover {
  color: #303030;
  opacity: 1 !important
}

.carousel-control.dark,
.carousel-control.dark:hover {
  color: #fff
}

.carousel-control .next_nav,
.carousel-control .prev_nav {
  height: 54px;
  width: 54px;
  border: 2px solid #fff;
  line-height: 54px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  margin-top: -27px;
  text-align: center;
  z-index: 10;
  -webkit-transition: opacity .3s ease 0s;
  -moz-transition: opacity .3s ease 0s;
  -o-transition: opacity .3s ease 0s;
  transition: opacity .3s ease-in-out;
  opacity: 0;
  -webkit-border-radius: 56px;
  -moz-border-radius: 56px;
  -ms-border-radius: 56px;
  -o-border-radius: 56px;
  border-radius: 56px
}

.q_slider:hover .carousel-control .next_nav,
.q_slider:hover .carousel-control .next_nav:hover,
.q_slider:hover .carousel-control .prev_nav,
.q_slider:hover .carousel-control .prev_nav:hover {
  opacity: 1
}

@media only screen and (max-width: 1000px) {

  .carousel-control .next_nav,
  .carousel-control .prev_nav {
    margin-top: -27px !important
  }
}

.carousel-control i {
  font-size: 30px;
  color: #fff;
  line-height: 54px;
  -webkit-transition: color .2s ease-in-out;
  -moz-transition: color .2s ease-in-out;
  -o-transition: color .2s ease-in-out;
  transition: color .2s ease-in-out
}

.carousel-control.dark .next_nav i,
.carousel-control.dark .prev_nav i {
  color: #000
}

.carousel-control.light .next_nav i,
.carousel-control.light .prev_nav i {
  color: #fff
}

.carousel-control.dark .next_nav,
.carousel-control.dark .prev_nav {
  border-color: #000
}

.carousel-control.light .next_nav,
.carousel-control.light .prev_nav {
  border-color: #fff
}

.carousel-control .thumb_holder {
  width: 150px;
  display: block;
  position: absolute;
  margin: -20px 0 0;
  top: 50%;
  z-index: 9
}

.carousel-control .thumb_holder .thumb_top {
  background-color: #fff;
  display: block;
  position: relative
}

.carousel-control.dark .thumb_holder .thumb_top {
  background-color: #000
}

.carousel-control.left .thumb_holder .thumb_top {
  -webkit-border-radius: 0 3px 0 0;
  -moz-border-radius: 0 3px 0 0;
  -ms-border-radius: 0 3px 0 0;
  border-radius: 0 3px 0 0
}

.carousel-control.right .thumb_holder .thumb_top {
  -webkit-border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0;
  -ms-border-radius: 3px 0 0 0;
  border-radius: 3px 0 0
}

.carousel-control.left .thumb_holder {
  left: -100%;
  -webkit-transition: left .2s ease-in-out;
  -moz-transition: left .2s ease-in-out;
  -o-transition: left .2s ease-in-out;
  transform: left .2s ease-in-out;
  transition: left .2s ease-in-out;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  display: none
}

.slider_thumbs .carousel-control.left:hover .thumb_holder {
  left: 0
}

.in_progress.slider_thumbs .carousel-control.left .thumb_holder {
  left: -100% !important
}

.carousel-control.right .thumb_holder {
  right: -100%;
  -webkit-transition: right .2s ease-in-out;
  -moz-transition: right .2s ease-in-out;
  -o-transition: right .2s ease-in-out;
  transform: right .2s ease-in-out;
  transition: right .2s ease-in-out;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  display: none
}

.slider_thumbs .carousel-control.right:hover .thumb_holder {
  right: 0
}

.in_progress.slider_thumbs .carousel-control.right .thumb_holder {
  right: -100% !important
}

.carousel-control.left .arrow_left {
  float: left;
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 15px
}

.carousel-control.left .numbers {
  float: right;
  height: 40px;
  line-height: 40px;
  padding: 0 20px 0 0
}

.carousel-control.right .arrow_right {
  float: right;
  height: 40px;
  line-height: 40px;
  padding: 0 15px 0 0
}

.carousel-control.right .numbers {
  float: left;
  height: 40px;
  line-height: 40px;
  padding: 0 0 0 20px
}

.carousel-control .img,
.carousel-control .img_outer {
  width: 150px;
  height: 47px;
  position: relative;
  display: block;
  overflow: hidden
}

.carousel-control .img_outer {
  top: 0;
  clear: both;
  margin: 0 0 -2px
}

.carousel-control .img {
  top: -47px
}

.touchevents .carousel-control.left .thumb_holder,
.touchevents .carousel-control.right .thumb_holder {
  display: none
}

.in_progress .carousel-control:hover .img {
  top: -47px !important
}

.carousel-control:hover .img {
  top: 0;
  -webkit-transition: top .2s ease-in-out .2s;
  -moz-transition: top .2s ease-in-out .2s;
  -o-transition: top .2s ease-in-out .2s;
  transform: top .2s ease-in-out .2s;
  transition: top .2s ease-in-out .2s
}

.carousel-control.right .img {
  float: right
}

.carousel-control .img .video,
.carousel-control .img img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2
}

.carousel-control .img .video {
  left: -1px
}

.carousel-control .img .old {
  z-index: 1
}

@media only screen and (max-width: 1300px) {
  .carousel-control {
    width: 15%
  }
}

@media only screen and (max-width: 1000px) {

  .carousel-control.left .thumb_holder,
  .carousel-control.right .thumb_holder {
    display: none
  }

  .carousel-control {
    width: 10%
  }

  .carousel-control .prev_nav {
    left: 0;
    opacity: 1
  }

  .carousel-control .next_nav {
    right: 0;
    opacity: 1
  }
}

.touchevents .carousel-control {
  width: 10%
}

.touchevents .carousel-control.left {
  background-position: 30px center !important
}

.touchevents .carousel-control.right,
.vertical_split_slider .ms-left .ms-section {
  background-position: 100% center !important
}

@media (max-width: 1200px) {
  .carousel:not(.advanced_responsiveness) .carousel-inner h2 span {
    font-size: .625em !important;
    line-height: 1.4em !important
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner .q_slide_subtitle span {
    font-size: .8em
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner h2 {
    margin-top: 0;
    line-height: inherit !important
  }
}

@media (max-width: 1024px) {
  .carousel:not(.advanced_responsiveness) .carousel-inner {
    position: relative
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner .qbutton {
    font-size: 12px;
    line-height: 31px;
    height: 31px
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner .qbutton i {
    margin: 0 0 0 20px
  }
}

@media only screen and (max-height: 850px) {
  .full_screen:not(.advanced_responsiveness) .carousel-inner .slider_content {
    height: 35%
  }
}

@media only screen and (max-width: 800px) {
  .carousel:not(.advanced_responsiveness) .carousel-inner .slider_content_outer {
    display: table
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner .slider_content .thumb {
    height: auto
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner .slider_content {
    display: table-cell;
    vertical-align: middle;
    position: static;
    top: 0 !important;
    left: 0 !important
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner h2.large span {
    font-size: .425em !important
  }
}

@media (max-width: 768px) {
  .carousel:not(.advanced_responsiveness) .carousel-inner .qbutton {
    font-size: 11px;
    line-height: 27px;
    height: 27px
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner .qbutton i {
    margin: 0 0 0 17px
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner h2.with_title_border span {
    padding: .3em .8em
  }

  .carousel:not(.advanced_responsiveness) .carousel-control.left {
    left: 0
  }

  .carousel:not(.advanced_responsiveness) .carousel-control.right {
    right: 0
  }
}

@media only screen and (max-height: 600px) {
  .full_screen:not(.advanced_responsiveness) .carousel-inner .slider_content {
    height: 25%
  }
}

@media (max-width: 567px) {
  .carousel:not(.advanced_responsiveness) .carousel-inner .qbutton {
    font-size: 10px;
    line-height: 23px;
    height: 23px
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner .qbutton i {
    margin: 0 0 0 14px
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner p {
    line-height: 1.4em !important
  }

  .carousel:not(.advanced_responsiveness) .carousel-control {
    display: none
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner h2.large span {
    font-size: .38em !important
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner h2 span {
    font-size: .525em !important
  }
}

@media (max-width: 480px) {
  .carousel:not(.advanced_responsiveness) .carousel-inner h2.large span {
    font-size: .3em !important
  }
}

@media (max-width: 320px) {
  .carousel:not(.advanced_responsiveness) .carousel-inner h2.large span {
    font-size: .2em !important
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner h2 span {
    font-size: .4em !important;
    line-height: 1em !important
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner p span {
    font-size: 60% !important
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner p {
    line-height: 1em !important
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner .qbutton {
    font-size: 9px;
    line-height: 20px;
    height: 20px
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner .qbutton i {
    margin: 0 0 0 10px
  }

  .carousel:not(.advanced_responsiveness) .carousel-inner h2.with_title_border {
    margin: 0 0 10px
  }
}

@media (max-width: 1200px) {
  .carousel.advanced_responsiveness .carousel-inner h2 {
    margin-top: 0
  }
}

@media (max-width: 1000px) {
  .carousel.advanced_responsiveness .carousel-inner {
    position: relative
  }

  .carousel.advanced_responsiveness .carousel-inner>.item {
    padding-top: 0 !important
  }

  .carousel.advanced_responsiveness .carousel-control .next_nav,
  .carousel.advanced_responsiveness .carousel-control .prev_nav {
    margin-top: 0 !important
  }

  .carousel.advanced_responsiveness .carousel-inner .slider_content_outer {
    box-sizing: border-box;
    display: table;
    padding: 0 5px
  }

  .carousel.advanced_responsiveness .carousel-inner .slider_content .thumb {
    height: auto
  }

  .carousel.advanced_responsiveness .carousel-inner .slider_content {
    display: table-cell;
    vertical-align: middle;
    position: static;
    top: 0 !important;
    left: 0 !important
  }

  .carousel.advanced_responsiveness .carousel .carousel-inner .graphic_content {
    text-align: center
  }
}

@media (max-width: 567px) {
  .carousel.advanced_responsiveness .carousel-control {
    display: none
  }
}

@media (max-width: 350px) {
  .carousel.advanced_responsiveness .carousel-inner .slider_content_outer {
    width: 100% !important
  }
}

.carousel-indicators li {
  background-color: #fff;
  opacity: .4;
  margin: 0 3px;
  -webkit-transition: all .6s ease-in-out;
  -moz-transition: all .6s ease-in-out;
  -o-transition: all .6s ease-in-out;
  transform: all .6s ease-in-out;
  transition: all .6s ease-in-out
}

.carousel-indicators.dark li {
  background-color: #000
}

.carousel-indicators .active {
  opacity: 1;
  margin: 0 3px
}

@-webkit-keyframes text-from-bottom {

  0%,
  50% {
    -webkit-transform: translate(0, 15%);
    opacity: 0
  }

  100% {
    -webkit-transform: translate(0, 0);
    opacity: 1
  }
}

@-moz-keyframes text-from-bottom {

  0%,
  50% {
    -moz-transform: translate(0, 15%);
    opacity: 0
  }

  100% {
    -moz-transform: translate(0, 0);
    opacity: 1
  }
}

@-o-keyframes text-from-bottom {

  0%,
  50% {
    -o-transform: translate(0, 15%);
    opacity: 0
  }

  100% {
    -o-transform: translate(0, 0);
    opacity: 1
  }
}

@keyframes text-from-bottom {

  0%,
  50% {
    transform: translate(0, 15%);
    opacity: 0
  }

  100% {
    transform: translate(0, 0);
    opacity: 1
  }
}

@-webkit-keyframes rotate {

  0%,
  50% {
    -webkit-transform: perspective(600px) rotateX(90deg) scale(.5);
    opacity: 0
  }

  100% {
    -webkit-transform: perspective(600px) rotateX(0) scale(1);
    opacity: 1
  }
}

@-moz-keyframes rotate {

  0%,
  50% {
    -moz-transform: perspective(600px) rotateX(90deg) scale(.5);
    opacity: 0
  }

  100% {
    -moz-transform: perspective(600px) rotateX(0) scale(1);
    opacity: 1
  }
}

@-o-keyframes rotate {

  0%,
  50% {
    -o-transform: perspective(600px) rotateX(90deg) scale(.5);
    opacity: .2
  }

  100% {
    -o-transform: perspective(600px) rotateX(0) scale(1);
    opacity: 1
  }
}

@keyframes rotate {

  0%,
  50% {
    transform: perspective(600px) rotateX(90deg) scale(.5);
    opacity: 0
  }

  100% {
    transform: perspective(600px) rotateX(0) scale(1);
    opacity: 1
  }
}

@-webkit-keyframes fade {

  0%,
  50% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-moz-keyframes fade {

  0%,
  50% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-o-keyframes fade {

  0%,
  50% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes fade {

  0%,
  50% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-webkit-keyframes text-from-bottom-one-by-one {
  0% {
    top: 70px;
    opacity: 0
  }

  50% {
    top: 45px;
    opacity: 0
  }

  100% {
    top: 0;
    opacity: 1
  }
}

@-moz-keyframes text-from-bottom-one-by-one {
  0% {
    top: 70px;
    opacity: 0
  }

  50% {
    top: 45px;
    opacity: 0
  }

  100% {
    top: 0;
    opacity: 1
  }
}

@-o-keyframes text-from-bottom-one-by-one {
  0% {
    top: 70px;
    opacity: 0
  }

  50% {
    top: 45px;
    opacity: 0
  }

  100% {
    top: 0;
    opacity: 1
  }
}

@keyframes text-from-bottom-one-by-one {
  0% {
    top: 70px;
    opacity: 0
  }

  50% {
    top: 45px;
    opacity: 0
  }

  100% {
    top: 0;
    opacity: 1
  }
}

.carousel .video .mobile-video-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10
}

.carousel .video .video-overlay img,
.carousel .video .video-wrap .mejs-container .mejs-controls,
.no-touchevents .q_slider .has_custom_cursor .carousel-indicators,
.q_slider .has_custom_cursor .next_nav,
.q_slider .has_custom_cursor .prev_nav {
  display: none !important
}

.carousel .video .video-overlay {
  height: 3000px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 11
}

.carousel .video .video-overlay.active {
  /* background-image: url(img/pixel-video.png); */
  background-position: 0 0;
  background-repeat: repeat;
  opacity: 1
}

.carousel .video .video-wrap .mejs-controls button,
.side_menu_button .popup_menu:hover {
  opacity: .8
}

.carousel .video .video-wrap {
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 10
}

.carousel .video .video-wrap .mejs-poster {
  background-size: cover !important;
  -moz-background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important
}

.carousel .video .video-wrap .mejs-controls .mejs-button button:focus {
  outline: 0 !important
}

.carousel .video .video-wrap .mejs-controls .mejs-time-rail .mejs-time-loaded {
  background-color: rgba(255, 255, 255, .18) !important
}

.carousel .video .video-wrap .mejs-container {
  background-color: transparent !important;
  background-image: none !important;
  height: auto !important
}

.carousel .video .video-wrap .mejs-mediaelement {
  background: 0 0 !important;
  border: 0 !important
}

.carousel .video .video-wrap .mejs-container .mejs-poster img {
  max-width: none !important;
  width: 100% !important
}

.carousel .video .video-wrap .mejs-controls button:hover,
.mejs-controls .mejs-fullscreen-button:hover button {
  opacity: 1 !important
}

.carousel .video .video-wrap .mejs-controls .mejs-time-rail .mejs-time-total {
  background: #1f1f1f !important
}

.carousel .video .video-wrap .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
  background: 0 0 !important
}

.carousel .item .text .separator {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 68%
}

.carousel .item .left .text .separator {
  margin-left: 0
}

.carousel .item .right .text .separator {
  margin-right: 0
}

.flex-container a:active,
.flex-container a:focus,
.flexslider a:active,
.flexslider a:focus,
.portfolio_slider .flex-container a:active,
.portfolio_slider .flex-container a:focus,
.portfolio_slider .flexslider a:active,
.portfolio_slider .flexslider a:focus,
.qode_carousels .flex-container a:active,
.qode_carousels .flex-container a:focus,
.qode_carousels a:active,
.qode_carousels a:focus {
  outline: 0
}

.caroufredsel-direction-nav,
.flex-control-nav,
.flex-direction-nav,
.portfolio_slides,
.slides {
  margin: 0;
  padding: 0;
  list-style: none
}

.flexslider .slides>li {
  background-color: transparent;
  display: none;
  position: relative;
  margin: 0
}

.caroufredsel_wrapper {
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
  margin: 0 !important
}

.portfolio_slider .portfolio_slides,
.qode_carousels .slides {
  opacity: 0
}

.portfolio_slider .portfolio_slides>li,
.qode_carousels .slides>li {
  background-color: transparent;
  position: relative;
  margin: 0;
  float: left
}

.qode_carousels .slides>li {
  margin: 0 2px 0 0
}

.flexslider .slides img,
.portfolio_slider .portfolio_slides img,
.qode_carousels .slides img {
  width: 100%;
  display: block
}

.gallery_frame {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  text-align: center
}

.qode-image-gallery-slides.flexslider.have_frame,
.wpb_flexslider.flexslider.have_frame {
  width: 645px;
  margin: 0 auto;
  overflow: visible
}

.qode-image-gallery-slides.flexslider.have_frame {
  padding: 34px 0 102px
}

.frame_holder>.wpb_wrapper {
  padding: 34px 0 102px;
  position: relative
}

.frame_holder.frame_holder2>.wpb_wrapper {
  padding: 48px 0 75px
}

.frame_holder.frame_holder3 .wpb_flexslider.flexslider.have_frame {
  width: 800px
}

.frame_holder.frame_holder3>.wpb_wrapper {
  padding: 45px 0 67px
}

.frame_holder.frame_holder4 .wpb_flexslider.flexslider.have_frame {
  width: 795px
}

.frame_holder.frame_holder4>.wpb_wrapper {
  padding: 42px 0 173px
}

.portfolio_slides:after,
.slides:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0
}

html[xmlns] .portfolio_slides,
html[xmlns] .slides {
  display: block
}

* html .portfolio_slides,
* html .slides {
  height: 1%
}

.no-js .portfolio_slides>li:first-child,
.no-js .slides>li:first-child {
  display: block
}

.flexslider,
.portfolio_slider {
  margin: 0 0 60px;
  position: relative;
  zoom: 1
}

.wpb_flexslider.flexslider {
  overflow: hidden
}

.portfolio_slider {
  margin: 0;
  width: 100.2%
}

.boxed .portfolio_slider {
  margin: 0 -25px 60px;
  overflow: hidden
}

.flex-viewport,
.portfolio_slider .flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease
}

.loading .flex-viewport {
  max-height: 300px
}

.flexslider .slides,
.portfolio_slider .portfolio_slides,
.qode_carousels .slides {
  zoom: 1
}

.flexslider ul {
  padding: 0
}

.caroufredsel-direction-nav a,
.flex-direction-nav a {
  width: 42px;
  height: 42px;
  line-height: 42px;
  margin: -23px 0 0;
  display: block;
  position: absolute;
  top: 50%;
  cursor: pointer;
  -webkit-transition: all .3s ease 0s;
  -moz-transition: all .3s ease 0s;
  transition: all .3s ease 0s;
  color: #8a8a8a;
  background-color: transparent;
  text-align: center;
  z-index: 200;
  border: 2px solid #fff
}

.have_frame.flexslider .flex-next,
.have_frame.flexslider .flex-prev {
  background-repeat: no-repeat;
  background-position: center center;
  width: 25px;
  height: 67px
}

.flex-direction-nav a {
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial
}

.blog_slider:hover .caroufredsel-direction-nav a.caroufredsel-next:hover,
.blog_slider:hover .caroufredsel-direction-nav a.caroufredsel-prev:hover,
.flexslider:hover .flex-direction-nav a.flex-next:hover,
.flexslider:hover .flex-direction-nav a.flex-prev:hover,
.portfolio_slider:hover .caroufredsel-direction-nav a.caroufredsel-next:hover,
.portfolio_slider:hover .caroufredsel-direction-nav a.caroufredsel-prev:hover,
.portfolio_slider:hover .flex-direction-nav a.flex-next:hover,
.portfolio_slider:hover .flex-direction-nav a.flex-prev:hover {
  background-color: #fff
}

.blog_slider:hover .caroufredsel-direction-nav a.caroufredsel-next:hover i,
.blog_slider:hover .caroufredsel-direction-nav a.caroufredsel-prev:hover i,
.flexslider:hover .flex-direction-nav a.flex-next:hover i,
.flexslider:hover .flex-direction-nav a.flex-prev:hover i,
.portfolio_slider:hover .caroufredsel-direction-nav a.caroufredsel-next:hover i,
.portfolio_slider:hover .caroufredsel-direction-nav a.caroufredsel-prev:hover i,
.portfolio_slider:hover .flex-direction-nav a.flex-next:hover i,
.portfolio_slider:hover .flex-direction-nav a.flex-prev:hover i {
  color: #8a8a8a
}

.caroufredsel-direction-nav a i,
.flex-direction-nav a i {
  line-height: 42px;
  color: #fff;
  font-size: 22px
}

.have_frame .flex-direction-nav a i {
  display: none
}

.flexslider .flex-next,
.portfolio_slider .caroufredsel-next,
.portfolio_slider .flex-next {
  right: 15px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -ms-border-radius: 2em;
  border-radius: 2em
}

.have_frame.flexslider .flex-next {
  right: -80px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  /* background-image: url(img/frame_arrow_right.png); */
  border: none
}

.have_frame.flexslider .flex-next:hover {
  background-color: transparent !important;
  /* background-image: url(img/frame_arrow_right_hover.png) */
}

.flexslider .flex-prev,
.portfolio_slider .caroufredsel-prev,
.portfolio_slider .flex-prev {
  left: 15px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  -ms-border-radius: 2em;
  border-radius: 2em
}

.have_frame.flexslider .flex-prev {
  left: -80px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  /* background-image: url(img/frame_arrow_left.png); */
  border: none
}

.have_frame.flexslider .flex-prev:hover {
  background-color: transparent !important;
  /* background-image: url(img/frame_arrow_left_hover.png) */
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio:1.5),
only screen and (-o-min-device-pixel-ratio:150 / 100),
only screen and (min-device-pixel-ratio:1.5),
only screen and (min-resolution:160dpi) {
  .have_frame.flexslider .flex-prev {
    /* background-image:url(img/frame_arrow_left@1_5x.png); */
    -o-background-size: 25px 67px;
    -webkit-background-size: 25px 67px;
    -moz-background-size: 25px 67px;
    background-size: 25px 67px
  }

  .have_frame.flexslider .flex-prev:hover {
    /* background-image: url(img/frame_arrow_left_hover@1_5x.png) */
  }

  .have_frame.flexslider .flex-next {
    /* background-image: url(img/frame_arrow_right@1_5x.png); */
    -o-background-size: 25px 67px;
    -webkit-background-size: 25px 67px;
    -moz-background-size: 25px 67px;
    background-size: 25px 67px
  }

  .have_frame.flexslider .flex-next:hover {
    /* background-image: url(img/frame_arrow_right_hover@1_5x.png) */
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:200 / 100),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:210dpi) {
  .have_frame.flexslider .flex-prev {
    /* background-image:url(img/frame_arrow_left@2x.png); */
    -o-background-size: 25px 67px;
    -webkit-background-size: 25px 67px;
    -moz-background-size: 25px 67px;
    background-size: 25px 67px
  }

  .have_frame.flexslider .flex-prev:hover {
    /* background-image: url(img/frame_arrow_left_hover@2x.png) */
  }

  .have_frame.flexslider .flex-next {
    /* background-image: url(img/frame_arrow_right@2x.png); */
    -o-background-size: 25px 67px;
    -webkit-background-size: 25px 67px;
    -moz-background-size: 25px 67px;
    background-size: 25px 67px
  }

  .have_frame.flexslider .flex-next:hover {
    /* background-image: url(img/frame_arrow_right_hover@2x.png) */
  }
}

.flex-direction-nav .flex-disabled {
  cursor: default
}

.qode-image-gallery .flexslider .flex-control-nav,
.wpb_gallery .wpb_flexslider.flexslider .flex-control-nav {
  margin-top: 50px;
  text-align: center
}

.qode-image-gallery .flexslider .flex-control-nav li,
.wpb_gallery .wpb_flexslider.flexslider .flex-control-nav li {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin: 0 5px
}

.qode-image-gallery .flexslider .flex-control-nav li a,
.wpb_gallery .wpb_flexslider.flexslider .flex-control-nav li a {
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  background-color: #cfcfcf;
  border-radius: 50%
}

.qode-image-gallery .flexslider .flex-control-nav li a.flex-active,
.wpb_gallery .wpb_flexslider.flexslider .flex-control-nav li a.flex-active {
  background-color: #303030
}

.qode-image-gallery .flexslider.has_control_nav .flex-direction-nav a,
.wpb_gallery .wpb_flexslider.flexslider.has_control_nav .flex-direction-nav a {
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px)
}

.qode-image-gallery .flexslider.drag_enabled,
.wpb_gallery .wpb_flexslider.flexslider.drag_enabled {
  cursor: e-resize;
  cursor: -webkit-grab;
  cursor: grab
}

.qode_carousels .slides>li .first_image_holder,
.qode_carousels .slides>li .second_image_holder {
  display: block;
  position: relative;
  width: 100%;
  opacity: 1;
  transition: opacity .4s ease-in-out;
  -ms-transition: opacity .4s ease-in-out;
  -moz-transition: opacity .4s ease-in-out;
  -o-transition: opacity .4s ease-in-out;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden
}

.qode_carousels .slides>li .second_image_holder {
  position: absolute;
  top: 0;
  left: 0
}

.qode_carousels .slides>li .carousel_item_holder:hover .first_image_holder.has_hover_image,
.qode_carousels .slides>li .second_image_holder {
  opacity: 0
}

.qode_carousels .slides>li .carousel_item_holder:hover .second_image_holder.has_hover_image {
  opacity: 1
}

.qode_carousels {
  padding: 0;
  position: relative;
  display: block;
  zoom: 1
}

.qode_carousels_holder.two_rows .slides>li .carousel_item_holder {
  margin-bottom: 15px;
  position: relative
}

.qode_carousels .flex-direction-nav {
  height: 0;
  display: none !important
}

.qode_carousels .flex-control-nav {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: -60px
}

.qode_carousels .flex-control-nav li {
  margin: 0 3px;
  zoom: 1;
  display: inline
}

.qode_carousels .flex-control-paging li a {
  width: 9px;
  height: 9px;
  display: inline-block;
  border: 1px solid #eaeaea;
  background-color: #fff;
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px
}

.qode_carousels .flex-control-paging li a.flex-active {
  background-color: #1abc9c;
  cursor: default;
  border: none
}

.qode_carousels.gray .flex-control-paging li a.flex-active {
  cursor: default
}

.last_toggle_el_margin,
.wpb_button,
.wpb_content_element,
.wpb_row,
ul.wpb_thumbnails-fluid>li {
  margin-bottom: 0 !important
}

.qode_clients .qode_client_holder {
  float: left;
  text-align: center;
  margin-bottom: 35px
}

.qode_clients.two_columns .qode_client_holder {
  width: 50%
}

.qode_clients.three_columns .qode_client_holder {
  width: 33.33%
}

.qode_clients.four_columns .qode_client_holder {
  width: 25%
}

.qode_clients.five_columns .qode_client_holder {
  width: 20%
}

.qode_clients.six_columns .qode_client_holder {
  width: 16.66666666666667%
}

.qode_clients .qode_client_holder_inner {
  position: relative;
  margin: 0 20px
}

.qode_clients .qode_client_holder_inner:after,
.qode_clients .qode_client_holder_inner:before {
  display: block;
  content: "";
  position: absolute
}

.qode_clients .qode_client_holder_inner:before {
  height: 100%;
  right: -20px;
  width: 1px;
  border-right: 1px solid #eaeaea
}

.qode_clients .qode_client_holder_inner:after {
  width: 100%;
  bottom: -20px;
  height: 1px;
  border-bottom: 1px solid #eaeaea
}

.qode_clients .qode_client_holder.border-bottom-none .qode_client_holder_inner:after {
  border-bottom: 0
}

.qode_clients.five_columns .qode_client_holder:nth-child(5n) .qode_client_holder_inner:before,
.qode_clients.four_columns .qode_client_holder:nth-child(4n) .qode_client_holder_inner:before,
.qode_clients.six_columns .qode_client_holder:nth-child(6n) .qode_client_holder_inner:before,
.qode_clients.three_columns .qode_client_holder:nth-child(3n) .qode_client_holder_inner:before,
.qode_clients.two_columns .qode_client_holder:nth-child(2n) .qode_client_holder_inner:before {
  border-right-width: 0
}

.qode_clients .qode_client_holder a {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  line-height: 0;
  -webkit-transition: opacity .6s ease-out;
  -moz-transition: opacity .6s ease-out;
  -o-transition: opacity .6s ease-out;
  transform: opacity .6s ease-out;
  transition: opacity .6s ease-out
}

.qode_clients .qode_client_holder a:hover {
  opacity: .4
}

.qode_clients.default .qode_client_hover_image,
.qode_clients.qode_clients_separators_disabled .qode_client_holder_inner:after,
.qode_clients.qode_clients_separators_disabled .qode_client_holder_inner:before {
  display: none
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_roll .qode_client_image_holder {
  position: relative;
  overflow: hidden
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_roll .qode_client_image_holder a .qode_client_main_image {
  -webkit-transition: transform .4s ease-in-out;
  -moz-transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_roll .qode_client_image_holder a .qode_client_hover_image {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -100%);
  -moz-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  -webkit-transition: transform .4s ease-in-out;
  -moz-transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_roll .qode_client_image_holder a:hover {
  opacity: 1
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_roll .qode_client_image_holder a:hover .qode_client_main_image {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%)
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_roll .qode_client_image_holder a:hover .qode_client_hover_image {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  transform: translate(-50%, 0)
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_fade .qode_client_image_holder {
  position: relative;
  overflow: hidden
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_fade .qode_client_image_holder a .qode_client_main_image {
  -webkit-transition: opacity .6s ease-in-out;
  -moz-transition: opacity .6s ease-in-out;
  transition: opacity .6s ease-in-out
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_fade .qode_client_image_holder a .qode_client_hover_image {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  transform: translate(-50%);
  opacity: 0;
  -webkit-transition: opacity .6s ease-in-out;
  -moz-transition: opacity .6s ease-in-out;
  transition: opacity .6s ease-in-out
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_fade .qode_client_image_holder a:hover {
  opacity: 1
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_fade .qode_client_image_holder a:hover .qode_client_main_image {
  opacity: 0
}

.qode_clients.qode_clients_switch_images.qode_clients_switch_fade .qode_client_image_holder a:hover .qode_client_hover_image {
  opacity: 1
}

.animated_icons_with_text .animated_icon_with_text_holder {
  float: left;
  text-align: center
}

.animated_icons_with_text.two_columns .animated_icon_with_text_holder {
  width: 50%
}

.animated_icons_with_text.three_columns .animated_icon_with_text_holder {
  width: 33.33%
}

.animated_icons_with_text.four_columns .animated_icon_with_text_holder {
  width: 25%
}

.animated_icons_with_text.five_columns .animated_icon_with_text_holder {
  width: 20%
}

.animated_icons_with_text.six_columns .animated_icon_with_text_holder {
  width: 16.66666666666667%
}

.animated_icons_with_text .animated_icon_with_text_inner {
  position: relative;
  padding: 0 20px;
  height: 85px
}

.animated_icons_with_text .animated_icon_with_text_inner:after,
.animated_icons_with_text .animated_icon_with_text_inner:before {
  display: block;
  content: "";
  position: absolute
}

.animated_icons_with_text .animated_icon_with_text_inner:before {
  height: 100%;
  right: 0;
  width: 1px;
  border-right: 1px solid #f5f5f5
}

.animated_icons_with_text .animated_icon_with_text_inner:after {
  width: 100%;
  bottom: 0;
  height: 1px;
  border-bottom: 1px solid #f5f5f5
}

.animated_icons_with_text .animated_icon_with_text_holder.border-bottom-none .animated_icon_with_text_inner:after {
  border-bottom: none
}

.animated_icons_with_text.five_columns .animated_icon_with_text_holder:nth-child(5n) .animated_icon_with_text_inner:before,
.animated_icons_with_text.four_columns .animated_icon_with_text_holder:nth-child(4n) .animated_icon_with_text_inner:before,
.animated_icons_with_text.six_columns .animated_icon_with_text_holder:nth-child(6n) .animated_icon_with_text_inner:before,
.animated_icons_with_text.three_columns .animated_icon_with_text_holder:nth-child(3n) .animated_icon_with_text_inner:before,
.animated_icons_with_text.two_columns .animated_icon_with_text_holder:nth-child(2n) .animated_icon_with_text_inner:before {
  border-right-width: 0
}

.animated_icon_holder {
  height: 100%;
  padding: 0 0 0 15px;
  display: block;
  width: 81px;
  float: left;
  position: relative
}

.animated_icon {
  height: 100%;
  width: 100%;
  position: relative
}

.animated_icon_inner span {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: .2s ease-in;
  -moz-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in
}

.animated_icon_inner span.animated_icon_back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg)
}

.animated_icon_with_text_holder:hover .animated_icon_inner span.animated_icon_back {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0)
}

.animated_icon_with_text_holder:hover .animated_icon_inner span {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg)
}

.animated_icon_inner {
  height: 81px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  margin: -41px 0 0
}

.animated_icon_inner i {
  width: 77px;
  height: 77px;
  line-height: 77px;
  background: 0 0;
  -o-border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  margin: 0;
  border: 2px solid silver;
  color: silver;
  font-size: 35px
}

.animated_icon_inner span.animated_icon_back i {
  background-color: #1abc9c;
  color: #fff;
  border-color: #1abc9c
}

.animated_icon_with_text_holder .animated_text_holder {
  text-align: left;
  width: 100%;
  height: 100%
}

.animated_icon_with_text_holder .animated_text_holder_wrap {
  display: block;
  padding: 0 0 0 116px;
  height: 100%
}

.animated_icon_with_text_holder .animated_text_holder_wrap_inner {
  width: 100%;
  height: 100%;
  position: relative
}

.animated_text_holder_inner {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  overflow: hidden;
  height: 100%
}

.animated_icon_with_text_holder .animated_text,
.animated_icon_with_text_holder .animated_title {
  display: table;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%
}

.animated_icon_with_text_holder .animated_text p,
.animated_icon_with_text_holder .animated_title_inner {
  display: table-cell;
  vertical-align: middle
}

.animated_icon_with_text_holder .animated_text,
.animated_icon_with_text_holder .animated_text p,
.animated_icon_with_text_holder .animated_title,
.animated_icon_with_text_holder .animated_title_inner {
  -webkit-transition: .2s ease-in;
  -moz-transition: .2s ease-in;
  -o-transition: .2s ease-in;
  transition: .2s ease-in
}

.animated_icon_with_text_holder .animated_text {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  -o-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0)
}

.animated_icon_with_text_holder .animated_text p {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  -o-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0)
}

.animated_icon_with_text_holder:hover .animated_text,
.animated_icon_with_text_holder:hover .animated_text p {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: 350ms ease-out;
  -moz-transition: 350ms ease-out;
  -o-transition: 350ms ease-out;
  transition: 350ms ease-out
}

.animated_icon_with_text_holder:hover .animated_title_inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transform: translate3d(0, -100%, 0);
  -moz-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  -o-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-transition: 350ms ease-out;
  -moz-transition: 350ms ease-out;
  -o-transition: 350ms ease-out;
  transition: 350ms ease-out
}

.animated_icon_with_text_holder:hover .animated_title {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  -ms-transform: translate3d(0, 100%, 0);
  -o-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: 350ms ease-out;
  -moz-transition: 350ms ease-out;
  -o-transition: 350ms ease-out;
  transition: 350ms ease-out
}

.service_table_holder {
  display: block;
  position: relative;
  border-color: #f6f6f6;
  border-width: 1px;
  border-style: none
}

.service_table_inner {
  display: block;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0
}

.service_table_inner ul {
  padding: 0 !important
}

.service_table_inner li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  border-bottom: 1px solid #f6f6f6
}

.service_table_inner>li {
  background-color: #fff
}

.service_table_inner li li {
  padding: 9px 20px
}

.service_table_inner li:last-child {
  border-bottom: 0
}

.service_table_inner li.service_table_title_holder {
  display: block;
  position: relative;
  padding: 31px 20px 20px;
  background-color: #fff;
  border: none
}

.service_table_inner li.service_table_title_holder i {
  color: #1abc9c
}

.service_table_title_inner {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  text-align: center
}

.service_table_title_inner2 {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 20px 20px 25px;
  vertical-align: middle
}

.service_table_inner li.service_table_title_holder.background_image_type {
  background-color: transparent !important;
  border: 0 !important;
  padding: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover
}

.service_table_inner li.service_table_title_holder.background_color_type .service_table_title_inner2 {
  padding: 0
}

.service_table_title_holder .service_title {
  display: block
}

.service_table_title_holder i {
  display: block;
  line-height: 1em;
  padding: 33px 0 0
}

.header_top_bottom_holder {
  position: relative
}

.qode_search_form {
  background: #262626;
  color: #fff;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0
}

.qode_search_form .qode_icon_in_search {
  color: #959595;
  font-size: 13px;
  display: inline-block;
  padding: 0 0 0 45px;
  float: left;
  line-height: 50px
}

.qode_search_form input,
.qode_search_form input:focus {
  background-color: #262626;
  border: none;
  box-shadow: none;
  color: #959595;
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  height: 20px;
  margin: 15px 0;
  width: 90%;
  padding: 0 45px 0 10px;
  font-family: inherit;
  outline: 0;
  outline-offset: 0;
  appearance: none;
  border-radius: 0;
  float: left;
}

.qode_search_form input:focus {
  outline-offset: 0 !important
}

@media only screen and (max-width: 1200px) {
  .qode_search_form input {
    width: 80%
  }
}

@media only screen and (max-width: 768px) {
  .qode_search_form input {
    width: 70%
  }
}

@media only screen and (max-width: 500px) {
  .qode_search_form input {
    width: 65%
  }
}

.qode_search_form .container_inner {
  position: relative
}

.qode_search_form .container .qode_icon_in_search {
  padding: 0
}

.qode_search_form .container input {
  width: 90%;
  padding: 0 0 0 10px
}

.page_header .qode_search_form input[type=submit] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  width: 1px;
  visibility: hidden
}

.qode_search_form .qode_search_close {
  color: #959595;
  font-size: 13px;
  position: absolute;
  line-height: 50px;
  height: 50px;
  top: 0;
  right: 45px
}

.qode_search_form .container .qode_search_close {
  right: 0
}

.content_inner,
.header_top_bottom_holder,
.qode_search_form {
  transition: all .15s ease;
  background: #fff;
  height: 100%;
}

.qode-search-slides-from-top-opened .header_top_bottom_holder {
  transform: translateY(50px)
}

.qode-search-slides-from-top-opened .qode_search_form {
  height: 50px
}

.qode-search-slides-from-top-opened .content_inner {
  margin-top: 50px
}

.qode_search_form_2 {
  background: #f1f1f1;
  color: #fff;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 100px;
  bottom: 0;
  position: absolute;
  transition: bottom .35s cubic-bezier(.55, .085, .68, .53);
  -webkit-transition: bottom .35s cubic-bezier(.55, .085, .68, .53);
  -moz-transition: bottom .35s cubic-bezier(.55, .085, .68, .53);
  left: 0;
  opacity: 1
}

.qode_search_form_2.animated {
  transition: bottom .35s cubic-bezier(.55, .085, .68, .53), opacity 0s ease 0s;
  -webkit-transition: bottom .35s cubic-bezier(.55, .085, .68, .53), opacity 0s ease 0s;
  -moz-transition: bottom .35s cubic-bezier(.55, .085, .68, .53), opacity 0s ease 0s;
  left: 0;
  opacity: 1
}

.qode_search_form_2 .container {
  z-index: auto
}

.qode_search_form_2 .form_holder_outer {
  display: table;
  height: 100px;
  width: 100%
}

.qode_search_form_2 input,
.qode_search_form_2 input:focus,
.qode_search_form_3 input,
.qode_search_form_3 input:focus {
  width: 90%;
  outline-offset: 0;
  vertical-align: middle;
  font-weight: 600;
  box-shadow: none;
  text-transform: uppercase;
  font-family: inherit;
  outline: 0
}

.qode_search_form_2.animated .form_holder_outer {
  height: 100px
}

.qode_search_form_2 .container_inner .form_holder {
  padding: 0
}

.qode_search_form_2 .form_holder {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  padding: 0 45px
}

.qode_search_form_2 input,
.qode_search_form_2 input:focus {
  background-color: transparent;
  border: none;
  color: #9d9d9d;
  font-size: 15px;
  display: inline-block;
  padding: 0;
  appearance: none;
  border-radius: 0
}

.qode_search_form_2 input:focus {
  outline-offset: 0 !important
}

.qode_search_form_2 .qode_search_submit {
  width: auto;
  cursor: pointer;
  font-size: 50px;
  position: absolute;
  right: 45px;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: color .4s ease;
  -webkit-transition: color .4s ease;
  -moz-transition: color .4s ease;
  color: #696969;
  padding: 0 0 0 1px
}

.qode_search_form_2 .container_inner .qode_search_submit {
  right: 0
}

.qode_search_form_2 .qode_search_submit:hover {
  color: #1abc9c
}

.qode_search_form_2.disabled .qode_search_submit,
.qode_search_form_2.disabled .qode_search_submit:hover {
  cursor: default;
  color: #959595
}

.qode_search_form_3 {
  display: none;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 115;
  background: #fff
}

.admin-bar .qode_search_form_3 {
  padding-top: 33px
}

.qode_search_form_3 .form_holder_outer {
  display: table;
  width: 100%;
  height: 100%
}

.qode_search_form_3 .form_holder {
  display: table-cell;
  vertical-align: middle;
  padding: 0 45px;
  position: relative;
  font-size: 0
}

.qode_search_form_3 .container_inner .form_holder {
  padding: 0;
  font-size: 0
}

.qode_search_form_3 input,
.qode_search_form_3 input:focus {
  background-color: transparent;
  border: none;
  color: #9d9d9d;
  font-size: 15px;
  display: inline-block;
  padding: 0;
  appearance: none;
  border-radius: 0;
  margin: 0
}

.qode_search_form_3 input:focus {
  outline-offset: 0 !important
}

.qode_search_form_3 input[type=submit] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  width: 1px;
  visibility: hidden
}

.qode_search_form_3 .qode_search_close {
  font-size: 34px;
  line-height: 1em;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  width: 10%;
  right: -1%
}

.qode_search_form_3 .container_inner .qode_search_close {
  right: 0
}

.qode_search_form_3 .qode_search_close a {
  color: #9d9d9d;
  display: inline-block
}

.qode_search_form_3 .qode_search_close a:hover {
  color: #1abc9c
}

:-moz-placeholder,
:-ms-input-placeholder,
::-moz-placeholder,
::-webkit-input-placeholder {
  color: #959595;
  margin: 10px 0 0
}

.qode_search_form_3 .qode_search_close a i {
  vertical-align: top
}

.side_menu_button {
  position: relative
}

.fullscreen_search_holder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 105;
  opacity: 0
}

.fullscreen_search_table {
  display: table;
  width: 100%;
  height: 100%
}

.fullscreen_search_cell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  text-align: center
}

.fullscreen_search_holder .search_label {
  font-size: 30px;
  line-height: 1em;
  color: #a1a1a1;
  vertical-align: bottom;
  font-family: inherit;
  margin-right: 5px
}

.fullscreen_search_holder .field_holder {
  width: 30%;
  display: inline-block;
  vertical-align: bottom;
  position: relative;
  border-bottom: 1px solid #a1a1a1
}

.fullscreen_search_holder .field_holder .line {
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 1px;
  width: 0;
  background-color: #1abc9c;
  -webkit-transition: width .4s ease;
  transition: width .4s ease
}

.fullscreen_search_holder .search_field {
  width: 100%;
  border-left: none;
  border-top: none;
  border-right: none;
  border-bottom: none;
  background: 0 0;
  outline: 0;
  height: 100%;
  font-size: 25px;
  color: #9d9d9d;
  line-height: 1em;
  position: relative;
  font-family: inherit
}

.fullscreen_search_holder .search_submit {
  border: none;
  background: 0 0;
  outline: 0;
  width: auto;
  cursor: pointer;
  font-family: FontAwesome;
  font-size: 20px;
  line-height: 1em;
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
  color: #a1a1a1
}

.fullscreen_search_holder .close_container a:hover,
.fullscreen_search_holder .search_submit:hover {
  color: #1abc9c
}

.fullscreen_search_holder .search_close_holder {
  float: right
}

.fullscreen_search_holder .close_container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%
}

.fullscreen_search_holder .close_container a {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .2s ease, color .2s ease;
  transition: opacity .2s ease, color .2s ease
}

.fullscreen_search_holder .close_container .search_close_holder {
  margin-right: 45px
}

.fullscreen_search_holder .close_container .container_inner .search_close_holder {
  margin-right: 0
}

.fullscreen_search_overlay {
  position: absolute;
  width: 2000px;
  height: 2000px;
  margin-top: -1000px;
  margin-left: -1000px;
  top: 50%;
  left: 0;
  border-radius: 100%;
  opacity: 0;
  z-index: 99;
  -ms-transform-origin: 50%;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  background-color: rgba(255, 255, 255, .98);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -ms-transition: -ms-transform .6s cubic-bezier(.4, 0, .2, 1), opacity .6s cubic-bezier(.4, 0, .2, 1);
  -webkit-transition: -webkit-transform .6s cubic-bezier(.4, 0, .2, 1), opacity .6s cubic-bezier(.4, 0, .2, 1);
  transition: transform .6s cubic-bezier(.4, 0, .2, 1), opacity .6s cubic-bezier(.4, 0, .2, 1)
}

.fullscreen_search_overlay.animate {
  -ms-transform: scale(3);
  -moz-transform: scale(3);
  -o-transform: scale(3);
  -webkit-transform: scale(3);
  transform: scale(3);
  opacity: 1
}

.fullscreen_search_holder.from_circle {
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
  display: none;
  opacity: 0;
  visibility: visible;
  z-index: 111
}

.paspartu_enabled.paspartu_on_top_fixed .fullscreen_search_holder.from_circle {
  z-index: 203
}

.fullscreen_search_holder.fade {
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  background-color: rgba(255, 255, 255, .98);
  z-index: 111
}

.paspartu_enabled.paspartu_on_top_fixed .fullscreen_search_holder.fade {
  z-index: 203
}

.search_fade_out .fullscreen_search_holder.fade {
  -webkit-animation: search_fade_out .25s linear both;
  animation: search_fade_out .25s linear both
}

.search_fade_in .fullscreen_search_holder.fade.animate {
  -webkit-animation: search_fade_in .25s linear both;
  animation: search_fade_in .25s linear both
}

.fullscreen_search_holder.fade.animate .fullscreen_search_close {
  opacity: 1;
  visibility: visible
}

@-webkit-keyframes search_fade_out {
  0% {
    opacity: 1;
    visibility: visible
  }

  100% {
    opacity: 0;
    visibility: hidden
  }
}

@keyframes search_fade_out {
  0% {
    opacity: 1;
    visibility: visible
  }

  100% {
    opacity: 0;
    visibility: hidden
  }
}

@-webkit-keyframes search_fade_in {
  0% {
    opacity: 0;
    visibility: hidden
  }

  100% {
    opacity: 1;
    visibility: visible
  }
}

@keyframes search_fade_in {
  0% {
    opacity: 0;
    visibility: hidden
  }

  100% {
    opacity: 1;
    visibility: visible
  }
}

.cover_boxes {
  position: relative;
  overflow: hidden
}

.cover_boxes ul {
  list-style: none;
  margin: 0 -20px 0 0;
  padding: 0;
  width: 200%
}

.cover_boxes ul li {
  float: left;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
  width: 265px;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s
}

.cover_boxes ul li .box,
.cover_boxes ul li.act {
  width: 530px
}

.cover_boxes ul li .box .thumb {
  display: inline-block;
  float: left;
  margin-right: 20px;
  position: relative;
  z-index: 10;
  width: 265px
}

.cover_boxes ul li .box .thumb img {
  display: block;
  position: relative;
  max-width: 100%
}

.cover_boxes ul li .box .box_content {
  bottom: 0;
  left: 285px;
  position: absolute;
  padding: 0;
  width: 245px;
  z-index: 9;
  top: 0
}

.cover_boxes ul li .box h3 {
  margin: 0 0 18px
}

.cover_boxes ul li .box .qbutton,
.cover_boxes ul li .box h5 {
  margin: 20px 0 0
}

@media only screen and (min-width: 1301px) {
  .qode_grid_1200 .cover_boxes ul li {
    width: 290px
  }

  .qode_grid_1200 .cover_boxes ul li .box,
  .qode_grid_1200 .cover_boxes ul li.act {
    width: 580px
  }

  .qode_grid_1200 .cover_boxes ul li .box .thumb {
    width: 290px
  }

  .qode_grid_1200 .cover_boxes ul li .box .box_content {
    width: 270px;
    left: 310px
  }
}

@media only screen and (min-width: 1401px) {
  .qode_grid_1300 .cover_boxes ul li {
    width: 315px
  }

  .qode_grid_1300 .cover_boxes ul li .box,
  .qode_grid_1300 .cover_boxes ul li.act {
    width: 630px
  }

  .qode_grid_1300 .cover_boxes ul li .box .thumb {
    width: 315px
  }

  .qode_grid_1300 .cover_boxes ul li .box .box_content {
    width: 295px;
    left: 335px
  }
}

.q_circles_holder {
  position: relative;
  display: inline-block;
  width: 100%;
  clear: both;
  list-style: none;
  margin: 0;
  padding: 0;
  border-color: #eaeaea
}

.q_circles_holder:before {
  position: absolute;
  left: 80px;
  display: block;
  width: 85%;
  height: 0;
  border-top: 1px solid;
  border-top-color: inherit;
  content: ""
}

.q_circles_holder.three_columns:before {
  top: 115px
}

.q_circles_holder.four_columns:before {
  top: 90px
}

.q_circles_holder.five_columns:before {
  top: 82px
}

.q_circles_holder.no_line:before {
  display: none;
  border: 0;
  height: 0;
  width: 0
}

.q_circles_holder .q_circle_outer {
  position: relative;
  float: left;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box
}

.q_circles_holder.three_columns .q_circle_outer {
  width: 33.3%
}

.q_circles_holder.four_columns .q_circle_outer {
  width: 25%
}

.q_circles_holder.five_columns .q_circle_outer {
  width: 20%
}

.q_circles_holder .q_circle_inner {
  position: relative;
  display: table;
  vertical-align: middle;
  text-align: center;
  margin: 0 auto
}

.q_circles_holder.three_columns .q_circle_inner {
  width: 230px;
  height: 230px
}

.q_circles_holder.four_columns .q_circle_inner {
  width: 180px;
  height: 180px
}

.q_circles_holder.five_columns .q_circle_inner {
  width: 161px;
  height: 161px
}

.q_circles_holder.five_columns .q_circle_inner.big_border {
  width: 177px;
  height: 177px
}

.q_circles_holder .q_circle_inner2 {
  position: relative;
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  background-color: #e3e3e3;
  border: 1px solid #f0f0f0;
  border-radius: 1000px;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  -o-border-radius: 1000px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  overflow: hidden
}

.q_circles_holder .q_circle_inner2 img {
  display: block;
  width: 100%;
  border-radius: 1000px;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  -o-border-radius: 1000px
}

.q_circles_holder .q_circle_inner2,
.q_circles_holder .q_circle_inner2 .q_text_in_circle,
.q_circles_holder .q_circle_inner2 i {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.q_circles_holder .q_circle_inner2 i {
  color: #b9b9b9
}

.q_circles_holder .q_circle_inner2:hover {
  background-color: #1abc9c !important;
  border-color: #1abc9c !important
}

.q_circles_holder .q_circle_inner2:hover .q_text_in_circle,
.q_circles_holder .q_circle_inner2:hover i {
  color: #fff !important
}

.q_circles_holder .q_circle_text_holder {
  display: block;
  text-align: center;
  margin: 36px 0 0;
  padding: 0 10px
}

.q_circles_holder .q_circle_text_holder .q_circle_text {
  display: block;
  margin: 7px 0 0
}

nav.content_menu {
  position: relative;
  text-align: left;
  display: block;
  z-index: 1001;
  background-color: #fff;
  transform: translateZ(0);
  width: 100%;
  transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  -moz-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  -o-transition: left .33s cubic-bezier(.694, .0482, .335, 1);
  -ms-transform: left .33s cubic-bezier(.694, .0482, .335, 1)
}

.full_width nav.content_menu.fixed {
  left: 0
}

.right_side_menu_opened .full_width nav.content_menu.fixed {
  left: -270px
}

.container_inner nav.content_menu {
  width: 1100px
}

.container_inner nav.content_menu.fixed {
  -moz-box-shadow: 0 4px 4px -4px rgba(0, 0, 0, .11);
  -webkit-box-shadow: 0 4px 4px -4px rgba(0, 0, 0, .11);
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, .11)
}

nav.content_menu ul {
  width: 1100px;
  margin: 0 auto;
  position: relative;
  list-style: none;
  padding: 0;
  text-align: center;
  backface-visibility: hidden
}

nav.content_menu ul li {
  display: inline-block;
  padding: 0;
  text-align: center;
  position: relative;
  backface-visibility: hidden
}

nav.content_menu ul li .arrow {
  /* background-image: url(img/content_menu_arrow.png); */
  background-position: 0 0;
  background-repeat: no-repeat;
  display: none;
  width: 11px;
  height: 7px;
  position: absolute;
  left: 50%;
  margin: -1px 0 0 -5px;
  backface-visibility: hidden
}

nav.content_menu.fixed ul li.active .arrow {
  display: none
}

nav.content_menu ul li a {
  color: #c3c3c3;
  font-size: 13px;
  text-decoration: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 21px 20px;
  margin: 0
}

nav.content_menu ul li a span {
  display: block;
  width: 100%;
  text-transform: uppercase;
  line-height: 18px;
  padding: 12px 0 0;
  font-weight: 600
}

nav.content_menu ul li i {
  color: #c3c3c3;
  margin: 4px 0 0;
  font-size: 24px;
  line-height: 1em;
  display: block
}

nav.content_menu ul li i.undefined {
  display: none
}

nav.content_menu ul li.active:hover a,
nav.content_menu ul li.active:hover i,
nav.content_menu ul li:hover a,
nav.content_menu ul li:hover i {
  color: #1abc9c
}

nav.content_menu ul li.active a,
nav.content_menu ul li.active i {
  color: #303030
}

nav.content_menu .nav_select_menu {
  display: none;
  border: 1px solid #eaeaea
}

nav.content_menu .nav_select_menu .nav_select_button {
  cursor: pointer;
  display: block;
  height: 40px;
  position: relative;
  width: 40px;
  line-height: 40px;
  text-align: center;
  float: right
}

nav.content_menu .nav_select_menu ul {
  display: none;
  border: 0;
  width: 100%;
  position: relative;
  top: 0;
  text-align: left;
  padding: 0 0 10px;
  float: left;
  z-index: 100;
  zoom: 1
}

nav.content_menu .nav_select_menu ul li {
  display: block;
  border: 0;
  float: none;
  padding: 0;
  margin: 0;
  text-align: left;
  position: relative;
  backface-visibility: hidden
}

nav.content_menu .nav_select_menu ul li a {
  line-height: 30px
}

nav.content_menu .nav_select_menu ul li a i {
  width: 12px;
  text-align: center
}

.boxed nav.content_menu.fixed {
  padding: 0;
  width: 1100px
}

.parallax_section_inner_margin,
.section_inner_margin {
  margin-left: -15px;
  margin-right: -15px
}

.wpcf7 form.wpcf7-form p {
  margin-bottom: 20px
}

input.wpcf7-form-control.wpcf7-date,
input.wpcf7-form-control.wpcf7-number,
input.wpcf7-form-control.wpcf7-text,
select.wpcf7-form-control.wpcf7-select,
textarea.wpcf7-form-control.wpcf7-textarea {
  width: 100%
}

input.wpcf7-form-control.wpcf7-date,
input.wpcf7-form-control.wpcf7-number,
input.wpcf7-form-control.wpcf7-quiz,
input.wpcf7-form-control.wpcf7-text,
select.wpcf7-form-control.wpcf7-select,
textarea.wpcf7-form-control.wpcf7-textarea {
  display: block;
  margin: 5px 0 0;
  padding: 15px;
  border: 0;
  border-radius: 0;
  outline: 0;
  resize: none;
  font-size: 13px;
  line-height: 17px;
  background-color: #fff;
  color: #818181;
  font-family: Raleway;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none
}

input.wpcf7-form-control.wpcf7-quiz {
  margin-left: 15px
}

span.wpcf7-form-control-wrap .wpcf7-form-control.wpcf7-checkbox input[type=checkbox],
span.wpcf7-form-control-wrap .wpcf7-form-control.wpcf7-radio input[type=radio] {
  position: relative;
  top: 2px
}

span.wpcf7-form-control-wrap .wpcf7-form-control.wpcf7-checkbox input[type=checkbox] {
  margin-left: 5px
}

input.wpcf7-form-control.wpcf7-submit {
  position: relative;
  display: inline-block;
  width: auto;
  height: 39px;
  line-height: 36px;
  margin: 0;
  padding: 0 23px;
  font-size: 13px;
  font-weight: 700;
  font-family: Raleway, sans-serif;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  background-color: transparent;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -ms-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial
}

input.wpcf7-form-control.wpcf7-submit[disabled] {
  color: #818181;
  border: 2px solid #818181;
  cursor: not-allowed
}

input.wpcf7-form-control.wpcf7-submit:not([disabled]) {
  border: 2px solid #303030;
  color: #303030
}

input.wpcf7-form-control.wpcf7-submit:not([disabled]):hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff;
  text-decoration: none
}

input.wpcf7-form-control.wpcf7-range {
  width: 100%
}

.wpcf7 form .wpcf7-response-output,
div.wpcf7-response-output {
  position: relative;
  padding: 17px 30px 17px 55px;
  color: #fff;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none
}

div.wpcf7-response-output:before {
  font-family: FontAwesome, sans-serif;
  width: 20px;
  height: 20px;
  display: inline-block;
  color: #fff;
  position: absolute;
  left: 25px
}

.wpcf7 form.sent .wpcf7-response-output,
div.wpcf7-response-output.wpcf7-mail-sent-ok {
  background-color: #1abc9c
}

.wpcf7 form.sent .wpcf7-response-output {
  border-color: transparent
}

.wpcf7 form.sent .wpcf7-response-output:before,
div.wpcf7-response-output.wpcf7-mail-sent-ok:before {
  content: "\f087"
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
div.wpcf7-response-output.wpcf7-validation-errors {
  background-color: #f1c40f
}

.wpcf7 form.invalid .wpcf7-response-output:before,
.wpcf7 form.unaccepted .wpcf7-response-output:before,
div.wpcf7-response-output.wpcf7-validation-errors:before {
  content: "\f12a"
}

.two_columns_form_with_space,
.two_columns_form_without_space {
  display: table
}

.two_columns_form_with_space input.wpcf7-form-control.wpcf7-text,
.two_columns_form_without_space input.wpcf7-form-control.wpcf7-text {
  margin: 0
}

.two_columns_form_without_space .column_left {
  display: table-cell;
  vertical-align: top
}

.two_columns_form_without_space .column_right {
  display: table-cell;
  width: 1%;
  text-align: left;
  vertical-align: top
}

.two_columns_form_with_space .column_left {
  display: table-cell;
  vertical-align: top;
  padding-right: 5px
}

.two_columns_form_with_space .column_right {
  display: table-cell;
  width: 1%;
  vertical-align: top;
  padding-left: 5px
}

.qode-three-columns-form-with-space {
  display: table;
  width: 100%
}

.qode-three-columns-form-with-space.qode-fixed-columns {
  table-layout: fixed
}

.qode-three-columns-form-with-space.qode-fixed-columns .qode-three-columns-form-with-space-column {
  width: 33.33%
}

.qode-three-columns-form-with-space .qode-three-columns-form-with-space-column {
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px;
  text-align: left;
  box-sizing: border-box
}

.qode-three-columns-form-with-space div.wpcf7 img.ajax-loader {
  display: none
}

body .gform_wrapper .gform_fields .gfield {
  margin: 0 0 20px
}

body .gform_wrapper .chzn-container .chzn-single,
body .gform_wrapper .chzn-container-single .chzn-single,
body .gform_wrapper .gf_progressbar_percentage,
body .gform_wrapper .gf_step,
body .gform_wrapper .gf_step span.gf_step_number,
body .gform_wrapper .gfield_description,
body .gform_wrapper input[type=submit],
body .gform_wrapper input[type=button],
body .gform_wrapper input[type=reset],
body .gform_wrapper input[type=email],
body .gform_wrapper input[type=number],
body .gform_wrapper input[type=password],
body .gform_wrapper input[type=tel],
body .gform_wrapper input[type=text],
body .gform_wrapper input[type=url],
body .gform_wrapper select,
body .gform_wrapper textarea {
  font-family: Raleway, sans-serif
}

body .gform_wrapper .chzn-container .chzn-single,
body .gform_wrapper .chzn-container-active .chzn-choices,
body .gform_wrapper .chzn-container-active .chzn-single,
body .gform_wrapper .chzn-container-multi .chzn-drop,
body .gform_wrapper .chzn-container-single .chzn-drop,
body .gform_wrapper .chzn-container-single .chzn-single,
body .gform_wrapper .gf_progressbar {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none
}

body .gform_wrapper .chzn-choices,
body .gform_wrapper .chzn-container .chzn-drop,
body .gform_wrapper .chzn-container .chzn-single,
body .gform_wrapper .chzn-container-multi .chzn-choices,
body .gform_wrapper .chzn-container-single .chzn-single,
body .gform_wrapper .chzn-container-single .chzn-single b,
body .gform_wrapper .chzn-container-single .chzn-single div,
body .gform_wrapper .gf_progressbar,
body .gform_wrapper input[type=email],
body .gform_wrapper input[type=number],
body .gform_wrapper input[type=password],
body .gform_wrapper input[type=tel],
body .gform_wrapper input[type=text],
body .gform_wrapper input[type=url],
body .gform_wrapper li.gfield.gfield_error,
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning,
body .gform_wrapper select,
body .gform_wrapper textarea {
  border: 0
}

body .gform_wrapper .chzn-container .chzn-single,
body .gform_wrapper .chzn-container-single .chzn-single,
body .gform_wrapper .gf_progressbar {
  background-image: none
}

body .gform_wrapper .ginput_full input:focus+label,
body .gform_wrapper .ginput_left input:focus+label,
body .gform_wrapper .ginput_right input:focus+label,
body .gform_wrapper .top_label .gfield_label,
body .gform_wrapper input[type=email],
body .gform_wrapper input[type=number],
body .gform_wrapper input[type=password],
body .gform_wrapper input[type=tel],
body .gform_wrapper input[type=text],
body .gform_wrapper input[type=url],
body .gform_wrapper table.gfield_list thead th,
body .gform_wrapper textarea {
  font-weight: 400
}

body .gform_wrapper .charleft {
  margin-top: 0
}

body .gform_wrapper .gfield_description {
  padding: 5px 0 0
}

body .gform_wrapper input[type=email],
body .gform_wrapper input[type=number],
body .gform_wrapper input[type=password],
body .gform_wrapper input[type=tel],
body .gform_wrapper input[type=text],
body .gform_wrapper input[type=url],
body .gform_wrapper select,
body .gform_wrapper textarea,
body .gform_wrapper.gf_browser_chrome .ginput_complex .ginput_right select,
body .gform_wrapper.gf_browser_chrome .ginput_complex select {
  padding: 15px;
  outline: 0;
  resize: none;
  box-sizing: border-box
}

body .gform_wrapper .chzn-container .chzn-single,
body .gform_wrapper .chzn-container-single .chzn-single,
body .gform_wrapper input[type=submit],
body .gform_wrapper input[type=button],
body .gform_wrapper input[type=reset],
body .gform_wrapper input[type=email],
body .gform_wrapper input[type=number],
body .gform_wrapper input[type=password],
body .gform_wrapper input[type=tel],
body .gform_wrapper input[type=text],
body .gform_wrapper input[type=url],
body .gform_wrapper select,
body .gform_wrapper textarea {
  font-size: 13px
}

body .gform_wrapper .chzn-container .chzn-single,
body .gform_wrapper .chzn-container-single .chzn-single,
body .gform_wrapper input[type=email],
body .gform_wrapper input[type=number],
body .gform_wrapper input[type=password],
body .gform_wrapper input[type=tel],
body .gform_wrapper input[type=text],
body .gform_wrapper input[type=url],
body .gform_wrapper textarea {
  line-height: 17px
}

body .gform_wrapper .chzn-container .chzn-single,
body .gform_wrapper .chzn-container-single .chzn-single,
body .gform_wrapper .chzn-container-single .chzn-single b,
body .gform_wrapper input[type=email],
body .gform_wrapper input[type=number],
body .gform_wrapper input[type=password],
body .gform_wrapper input[type=tel],
body .gform_wrapper input[type=text],
body .gform_wrapper input[type=url],
body .gform_wrapper textarea {
  background-color: #fff
}

body .gform_wrapper .chzn-container-single .chzn-single span,
body .gform_wrapper input[type=email],
body .gform_wrapper input[type=number],
body .gform_wrapper input[type=password],
body .gform_wrapper input[type=tel],
body .gform_wrapper input[type=text],
body .gform_wrapper input[type=url],
body .gform_wrapper select,
body .gform_wrapper textarea {
  color: #818181
}

body .gform_wrapper .chzn-container,
body .gform_wrapper input[type=email],
body .gform_wrapper input[type=number],
body .gform_wrapper input[type=password],
body .gform_wrapper input[type=tel],
body .gform_wrapper input[type=text],
body .gform_wrapper input[type=url],
body .gform_wrapper select,
body .gform_wrapper textarea {
  margin: 5px 0 0
}

body .gform_wrapper input[type=submit],
body .gform_wrapper input[type=button],
body .gform_wrapper input[type=reset] {
  position: relative;
  display: inline-block;
  width: auto;
  height: 39px;
  line-height: 36px;
  margin: 0;
  padding: 0 23px;
  border: 2px solid #303030;
  font-weight: 700;
  text-align: left;
  color: #303030;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  background-color: transparent;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -ms-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear
}

body .gform_wrapper input[type=submit]:hover,
body .gform_wrapper input[type=button]:hover,
body .gform_wrapper input[type=reset]:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff;
  text-decoration: none
}

body .gform_wrapper .chzn-container .chzn-single,
body .gform_wrapper .chzn-container-single .chzn-single {
  height: auto;
  padding: 15px 2%
}

body .gform_wrapper .chzn-container-single .chzn-single div b {
  background-position: -18px 10px
}

body .gform_wrapper .chzn-container-single .chzn-single-with-drop div b {
  background-position: -1px 10px
}

body .gform_wrapper .chzn-container-single .chzn-search input {
  border: 1px solid #aaa
}

body .gform_wrapper .chzn-choices {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #fff), color-stop(0, #fff)) !important
}

body .gform_wrapper .chzn-choices .search-field .default {
  padding-left: 10%
}

body .gform_wrapper .chzn-container-multi .chzn-choices .search-field input {
  padding: 15px
}

body .gform_wrapper .gform_confirmation_message,
body .gform_wrapper div.validation_error {
  position: relative;
  padding: 17px 30px;
  color: #fff;
  margin-left: 0;
  margin-right: 0;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none
}

body .gform_wrapper div.validation_error {
  background-color: #f1c40f
}

body .gform_wrapper .gform_confirmation_message {
  background-color: #1abc9c
}

body .gform_wrapper .ginput_complex .name_prefix,
body .gform_wrapper .ginput_complex .name_suffix,
body .gform_wrapper input.ginput_quantity {
  width: 60px !important
}

body .gform_wrapper .datepicker {
  width: 105px !important
}

body .gform_wrapper .gfield_time_ampm select {
  min-width: 80px
}

body .gform_wrapper .gfield_time_hour {
  vertical-align: middle
}

body .gform_wrapper .button.gform_button_select_files {
  height: 31px;
  line-height: 24px;
  padding: 0 9px;
  font-size: 12px;
  margin-left: 10px
}

.popup_menu_inner {
  display: inline-block;
  height: 2px;
  top: -1px;
  vertical-align: middle;
  position: relative;
  width: 20px
}

.popup_menu_widget_holder {
  position: relative;
  display: block;
  text-align: center;
  margin: 20px 0 0
}

.popup_menu .line,
.popup_menu .line:after,
.popup_menu .line:before {
  background-color: #9D9D9D;
  height: 2px;
  position: absolute;
  transition: all .3s ease-in-out;
  transform: all .3s ease-in-out;
  width: 13px
}

.popup_menu .line {
  margin: 0;
  top: 0;
  left: 0;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  display: inline-block
}

.popup_menu .line:after,
.popup_menu .line:before {
  content: "";
  display: block;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  z-index: -1;
  backface-visibility: hidden;
}

@media only screen and (min-width: 1000px) {

  .dark.header_style_on_scroll .popup_menu:not(.opened) .line,
  .dark.header_style_on_scroll .popup_menu:not(.opened) .line:after,
  .dark.header_style_on_scroll .popup_menu:not(.opened) .line:before,
  .dark:not(.sticky):not(.scrolled) .popup_menu:not(.opened) .line,
  .dark:not(.sticky):not(.scrolled) .popup_menu:not(.opened) .line:after,
  .dark:not(.sticky):not(.scrolled) .popup_menu:not(.opened) .line:before {
    background-color: #000
  }

  .light.header_style_on_scroll .popup_menu:not(.opened) .line,
  .light.header_style_on_scroll .popup_menu:not(.opened) .line:after,
  .light.header_style_on_scroll .popup_menu:not(.opened) .line:before,
  .light:not(.sticky):not(.scrolled) .popup_menu:not(.opened) .line,
  .light:not(.sticky):not(.scrolled) .popup_menu:not(.opened) .line:after,
  .light:not(.sticky):not(.scrolled) .popup_menu:not(.opened) .line:before {
    background-color: #fff
  }
}

.popup_menu .line:before {
  top: -4px
}

.popup_menu .line:after {
  top: 4px
}

.popup_menu.opened .line {
  background-color: transparent !important
}

.popup_menu.opened .fa-bars:before {
  content: "\f00d"
}

.popup_menu.opened .icon_menu:before {
  content: "\4d"
}

.popup_menu.opened .kiko-hamburger-menu:before {
  content: '\f252'
}

.popup_menu.opened .line:after {
  background-color: #fff;
  top: 0 !important;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg)
}

.popup_menu.opened .line:before {
  background-color: #fff;
  top: 0 !important;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg)
}

.popup_menu_holder_outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 105
}

.popup_menu_holder {
  width: 100%;
  height: 100%;
  background-color: rgba(48, 48, 48, .95);
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  display: table
}

.popup_menu_holder.with_background_image {
  background-size: cover;
  background-position: center
}

.popup_menu_holder_inner {
  display: table-cell;
  vertical-align: middle;
  padding: 100px 0
}

.popup_menu_opened .side_menu_button .shopping_cart_header,
.touchevents .popup_menu_holder_outer {
  display: none
}

.popup_menu_opened #back_to_top,
.popup_menu_opened .fixed_top_header .header_bottom_center_widget,
.popup_menu_opened .header_bottom_right_widget_holder,
.popup_menu_opened .header_top,
.popup_menu_opened .main_menu,
.popup_menu_opened .mobile_menu_button,
.popup_menu_opened .side_menu_button a:not(.popup_menu),
.popup_menu_opened header .tooltip {
  visibility: hidden !important
}

.popup_menu_opened .header_bottom .container_inner {
  border: none !important
}

.popup_menu_opened .popup_menu_holder_outer {
  visibility: visible;
  opacity: 1
}

.touchevents .popup_menu_opened .popup_menu_holder_outer {
  display: block
}

.popup_menu_opened header.scrolled .header_bottom,
.popup_menu_opened header.sticky .header_bottom {
  background-color: transparent !important;
  box-shadow: none
}

.popup_menu_opened .fixed_top_header .top_header,
.popup_menu_opened .header_bottom,
.popup_menu_opened .header_top {
  background-color: transparent !important;
  border: 0 !important
}

.popup_menu_opened .q_logo img.dark,
.popup_menu_opened .q_logo img.light,
.popup_menu_opened .q_logo img.mobile,
.popup_menu_opened .q_logo img.normal,
.popup_menu_opened .q_logo img.sticky {
  opacity: 0 !important
}

.popup_menu_opened header .q_logo img.popup {
  opacity: 1 !important
}

.popup_menu.qode-popup-menu-svg-opener {
  display: inline-block;
  position: relative;
  width: auto
}

.popup_menu.qode-popup-menu-svg-opener .qode-popup-menu-opener {
  transition: all .3s ease
}

.popup_menu.qode-popup-menu-svg-opener .qode-popup-menu-opener svg * {
  fill: currentColor;
  stroke: currentColor
}

.light .popup_menu.qode-popup-menu-svg-opener .qode-popup-menu-opener svg * {
  fill: #fff;
  stroke: #fff
}

.dark .popup_menu.qode-popup-menu-svg-opener .qode-popup-menu-opener svg * {
  fill: #000;
  stroke: #000
}

.popup_menu.qode-popup-menu-svg-opener .qode-popup-menu-closer {
  position: absolute;
  left: calc(50% + 6px);
  transform: translateX(-50%);
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease
}

.popup_menu.qode-popup-menu-svg-opener .qode-popup-menu-closer svg * {
  fill: currentColor;
  stroke: currentColor
}

.light .popup_menu.qode-popup-menu-svg-opener .qode-popup-menu-closer svg * {
  fill: #fff;
  stroke: #fff
}

.dark .popup_menu.qode-popup-menu-svg-opener .qode-popup-menu-closer svg * {
  fill: #000;
  stroke: #000
}

.popup_menu.qode-popup-menu-svg-opener.opened .qode-popup-menu-opener {
  visibility: hidden;
  opacity: 0
}

.popup_menu.qode-popup-menu-svg-opener.opened .qode-popup-menu-closer {
  visibility: visible;
  opacity: 1
}

nav.popup_menu {
  margin: 0 auto;
  position: relative;
  top: 0;
  text-align: left
}

nav.popup_menu ul {
  display: none;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0
}

nav.popup_menu>ul {
  display: block
}

nav.popup_menu ul li {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 45px;
  line-height: 50px
}

nav.popup_menu ul li a,
nav.popup_menu ul li h6 {
  font-size: 24px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 2px;
  color: #fff;
  padding: 0;
  display: block;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer
}

nav.popup_menu ul li a:hover,
nav.popup_menu ul li h6:hover {
  color: #9c9c9c
}

nav.popup_menu ul li a span,
nav.popup_menu ul li h6 span {
  display: inline-block;
  vertical-align: middle
}

nav.popup_menu ul li ul li a,
nav.popup_menu ul li ul li h6 {
  font-size: 14px;
  text-transform: none
}

nav.popup_menu ul li ul li ul li a,
nav.popup_menu ul li ul li ul li h6 {
  font-size: .5em;
  line-height: 1em
}

.popup_menu.medium .fa {
  font-size: 21px
}

.popup_menu.medium .icon_menu {
  font-size: 30px
}

.popup_menu.medium .line,
.popup_menu.medium .line:after,
.popup_menu.medium .line:before,
.popup_menu.medium .popup_menu_inner {
  height: 3px;
  width: 19px
}

.popup_menu.medium .line:before {
  top: -6px
}

.popup_menu.medium .line:after {
  top: 6px
}

.popup_menu.large .fa {
  font-size: 28px
}

.popup_menu.large .icon_menu {
  font-size: 40px
}

.popup_menu.large .line,
.popup_menu.large .line:after,
.popup_menu.large .line:before,
.popup_menu.large .popup_menu_inner {
  height: 4px;
  width: 24px
}

.popup_menu.large .line:before {
  top: -8px
}

.popup_menu.large .line:after {
  top: 8px
}

.qode_pricing_list .qode_pricing_list_holder {
  list-style: none
}

.qode_pricing_list .qode_pricing_list_item {
  position: relative;
  border-bottom: 1px solid #e7e7e7;
  padding: 19px 0
}

.qode_pricing_list .qode_pricing_item_title {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600
}

.qode_pricing_list .qode_pricing_item_text {
  padding-right: 170px
}

.qode_pricing_list .qode_pricing_item_price {
  position: absolute;
  width: 170px;
  height: 100%;
  text-align: right;
  right: 0;
  top: 0
}

.qode_pricing_list .qode_pricing_item_price_inner {
  display: table;
  height: 100%;
  width: 100%
}

.qode_pricing_list .qode_pricing_item_price_inner span {
  display: table-cell;
  vertical-align: middle;
  font-size: 30px;
  color: #303030;
  font-weight: 600
}

.q_elements_holder {
  width: 100%;
  display: table;
  table-layout: fixed
}

.q_elements_holder .q_elements_item {
  display: table-cell;
  vertical-align: middle;
  height: 100%
}

.q_elements_holder .q_elements_item_inner {
  width: 100%
}

.q_elements_holder.two_columns .q_elements_item {
  width: 50%
}

.q_elements_holder.two_columns.eh_two_columns_66_33 .q_elements_item:first-child {
  width: 66.66%
}

.q_elements_holder.two_columns.eh_two_columns_33_66 .q_elements_item:first-child,
.q_elements_holder.two_columns.eh_two_columns_66_33 .q_elements_item:last-child {
  width: 33.33%
}

.q_elements_holder.two_columns.eh_two_columns_33_66 .q_elements_item:last-child {
  width: 66.66%
}

.q_elements_holder.two_columns.eh_two_columns_75_25 .q_elements_item:first-child {
  width: 75%
}

.q_elements_holder.two_columns.eh_two_columns_25_75 .q_elements_item:first-child,
.q_elements_holder.two_columns.eh_two_columns_75_25 .q_elements_item:last-child {
  width: 25%
}

.q_elements_holder.two_columns.eh_two_columns_25_75 .q_elements_item:last-child {
  width: 75%
}

.q_elements_holder.three_columns .q_elements_item {
  width: 33.33333333333333%
}

.q_elements_holder.three_columns.eh_three_columns_50_25_25 .q_elements_item:first-child {
  width: 50%
}

.q_elements_holder.three_columns.eh_three_columns_50_25_25 .q_elements_item {
  width: 25%
}

.q_elements_holder.three_columns.eh_three_columns_25_25_50 .q_elements_item:last-child {
  width: 50%
}

.q_elements_holder.four_columns .q_elements_item,
.q_elements_holder.three_columns.eh_three_columns_25_25_50 .q_elements_item {
  width: 25%
}

.content_top_margin .content .container .container_inner.page_container_inner {
  padding: 0
}

.content_top_margin .content .container .container_inner .call_to_action {
  margin: 0 -15px
}

.content_top_margin .content .container .container_inner .call_to_action .container_inner {
  width: 100%
}

.blog_single .post-password-form {
  width: 300px;
  margin: 150px auto
}

.blog_large_image .post-password-form input[type=submit],
.blog_large_image .post-password-form p:last-child {
  margin: 0
}

.blog_large_image .post-password-form input[type=password] {
  border: 1px solid #818181
}

.post-password-form p {
  margin-bottom: 20px
}

.post-password-form input[type=password] {
  width: 100%;
  display: block;
  margin: 5px 0 20px;
  padding: 15px 12px;
  border: 0;
  outline: 0;
  resize: none;
  font-size: 13px;
  line-height: 17px;
  background-color: #fff;
  color: #818181;
  font-family: inherit;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.post-password-form input[type=submit] {
  position: relative;
  display: inline-block;
  width: auto;
  height: 39px;
  line-height: 39px;
  margin: 0 0 20px;
  padding: 0 23px;
  border: 2px solid #303030;
  font-size: 13px;
  font-weight: 700;
  font-family: inherit;
  text-align: left;
  color: #303030;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  background-color: transparent;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  -ms-transition: all .1s linear;
  -o-transition: all .1s linear;
  transition: all .1s linear;
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  box-sizing: initial !important
}

.fp-section,
.ms-section,
.qode-instagram-feed li,
aside.vertical_menu_area {
  box-sizing: border-box;
  -webkit-box-sizing: border-box
}

.post-password-form input[type=submit]:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff;
  text-decoration: none
}

.full_screen_holder {
  position: relative;
  display: block;
  z-index: 100
}

.full_screen_inner {
  height: 100%;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  visibility: hidden
}

.fp-section {
  position: relative;
  box-sizing: border-box;
  margin: 0 !important
}

.fp-slide {
  float: left
}

.fp-section.fp-table,
.fp-slide.fp-table {
  display: table;
  width: 100%
}

.fp-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%
}

.full_screen_holder {
  padding-left: 260px
}

.full_screen_holder {
  padding-left: 0
}

@media only screen and (max-width: 1000px) {
  .full_screen_holder {
    padding-left: 0
  }
}

.full_screen_navigation_holder {
  position: absolute;
  left: 0;
  width: 100%;
  display: block;
  text-align: center;
  z-index: 10000;
  visibility: hidden;
  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out
}

.full_screen_navigation_holder.up_arrow {
  top: 30px
}

.full_screen_navigation_holder.down_arrow,
.full_screen_navigation_holder.side_by_side {
  position: fixed;
  bottom: 30px
}

.full_screen_navigation_inner {
  position: relative;
  display: inline-block;
  vertical-align: middle
}

.full_screen_navigation_inner a {
  display: block;
  color: #393939;
  font-size: 40px;
  line-height: 1em;
  opacity: 1;
  -webkit-transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -ms-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out
}

.full_screen_navigation_inner a:hover {
  opacity: .7
}

.full_screen_navigation_inner i {
  font-size: inherit;
  color: inherit;
  line-height: inherit
}

.full_screen_preloader {
  position: absolute;
  height: 1200px;
  width: 100%;
  display: block;
  background-color: #1c1c1c;
  z-index: 10001
}

.full_screen_preloader .ajax_loader {
  position: absolute;
  display: block
}

.qode_image_gallery_no_space {
  opacity: 0;
  position: relative
}

.qode_image_gallery_no_space .qode_image_gallery_holder {
  overflow: hidden;
  position: relative;
  width: 100%
}

.qode_image_gallery_no_space ul {
  margin: 0;
  overflow: hidden;
  padding: 0
}

.qode_image_gallery_no_space ul li {
  float: left;
  list-style: none;
  margin: 0
}

@media only screen and (min-width: 800px) {
  .qode_image_gallery_no_space.highlight_active ul li:not(.active) {
    opacity: .2
  }

  .qode_image_gallery_no_space.highlight_active ul li.active {
    opacity: 1 !important
  }

  .qode_image_gallery_no_space.highlight_active ul li {
    -webkit-transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -ms-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
  }
}

@media only screen and (min-width: 801px) {
  .qode_image_gallery_no_space ul li:not(.active) a {
    cursor: default
  }
}

.qode_image_gallery_no_space a.prettyphoto {
  display: block;
  width: 100%;
  height: 100%
}

.qode_image_gallery_no_space ul li img {
  display: block;
  position: relative
}

.qode_image_gallery_no_space .controls {
  width: 100%
}

.qode_image_gallery_no_space .controls a.next-slide,
.qode_image_gallery_no_space .controls a.prev-slide {
  vertical-align: middle;
  font-size: 30px;
  position: absolute;
  top: 0;
  height: 100%;
  width: 10%
}

.qode_image_gallery_no_space .controls a.next-slide span,
.qode_image_gallery_no_space .controls a.prev-slide span {
  margin-top: -27px;
  margin-left: -27px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: transparent;
  border: 2px solid #303030;
  color: #303030;
  cursor: pointer;
  display: block;
  height: 54px;
  line-height: 54px;
  text-align: center;
  width: 54px;
  border-radius: 40px;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out;
  -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out;
  -ms-transition: color .15s ease-in-out, background-color .15s ease-in-out;
  -o-transition: color .15s ease-in-out, background-color .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out
}

.qode_image_gallery_no_space .controls a.next-slide span i,
.qode_image_gallery_no_space .controls a.prev-slide span i {
  vertical-align: baseline;
  line-height: inherit
}

.qode_image_gallery_no_space.light .controls a.next-slide span,
.qode_image_gallery_no_space.light .controls a.prev-slide span {
  color: #fff;
  border-color: #fff
}

.qode_image_gallery_no_space.dark .controls a.next-slide span,
.qode_image_gallery_no_space.dark .controls a.prev-slide span {
  color: #000;
  border-color: #000
}

.qode_image_gallery_no_space .controls a.next-slide:hover span,
.qode_image_gallery_no_space .controls a.prev-slide:hover span {
  background-color: #303030;
  color: #fff
}

.qode_image_gallery_no_space.light .controls a.next-slide:hover span,
.qode_image_gallery_no_space.light .controls a.prev-slide:hover span {
  background-color: #fff;
  color: #8a8a8a
}

.qode_image_gallery_no_space.dark .controls a.next-slide:hover span,
.qode_image_gallery_no_space.dark .controls a.prev-slide:hover span {
  background-color: #000;
  color: #fff
}

.qode_image_gallery_no_space .controls a.next-slide {
  right: 20px
}

@media only screen and (max-width: 800px) {
  .qode_image_gallery_no_space.highlight_active ul li {
    opacity: 1 !important
  }

  .qode_image_gallery_no_space ul,
  .qode_image_gallery_no_space ul li div {
    height: auto !important;
    width: 100% !important
  }

  .qode_image_gallery_no_space .controls,
  .qode_image_gallery_no_space ul li.-after,
  .qode_image_gallery_no_space ul li.-before {
    display: none
  }

  .qode_image_gallery_no_space .qode_image_gallery_holder {
    height: auto !important
  }

  .qode_image_gallery_no_space ul li {
    float: none
  }

  .qode_image_gallery_no_space ul li img {
    height: auto;
    width: 100%
  }
}

.countdown-rtl {
  direction: rtl
}

.countdown-row {
  clear: both;
  width: 100%;
  padding: 0 2px;
  text-align: center
}

.countdown-show1 .countdown-section {
  width: 98%
}

.countdown-show2 .countdown-section {
  width: 48%
}

.countdown-show3 .countdown-section {
  width: 32.5%
}

.countdown-show4 .countdown-section {
  width: 24.5%
}

.countdown-show5 .countdown-section {
  width: 19.5%
}

.countdown-show6 .countdown-section {
  width: 16.25%
}

.countdown-show7 .countdown-section {
  width: 14%
}

.countdown {
  font-weight: 700
}

.countdown-section {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-weight: inherit
}

.countdown-amount {
  display: block;
  position: relative;
  font-size: 60px;
  line-height: 60px;
  margin-bottom: 20px;
  color: inherit
}

.countdown_separator {
  display: none;
  width: 30px;
  height: 2px;
  background-color: #818181;
  margin: 30px 0;
  opacity: .3
}

.countdown.show_separator .countdown_separator {
  display: inline-block
}

.countdown.show_separator .countdown-amount {
  margin: 0
}

.countdown-period {
  display: block;
  font-size: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: inherit
}

.countdown-descr {
  display: block;
  width: 100%
}

@media only screen and (max-width: 1000px) {
  .countdown-amount {
    font-size: 50px !important;
    line-height: 50px !important
  }

  .countdown-period {
    font-size: 15px !important
  }

  .countdown_separator {
    width: 30px
  }
}

@media only screen and (max-width: 600px) {
  .countdown-amount {
    font-size: 35px !important;
    line-height: 35px !important;
    margin-bottom: 10px
  }

  .countdown-period {
    font-size: 10px !important
  }

  .countdown_separator {
    width: 15px;
    margin: 20px 0 15px
  }
}

.vertical_split_slider {
  opacity: 0;
  position: relative;
  margin: 0 -2px
}

.ms-section {
  position: relative;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0 20px
}

.vertical_split_slider .ms-right .ms-section {
  background-position: 0 center !important
}

.ms-section.ms-table {
  display: table;
  width: 100%
}

.ms-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  box-sizing: border-box
}

.ms-easing {
  -webkit-transition: all .7s ease-out;
  -moz-transition: all .7s ease-out;
  -o-transition: all .7s ease-out;
  transition: all .7s ease-out
}

#multiscroll-nav {
  position: fixed;
  z-index: 1000;
  margin-top: -32px;
  top: 50%;
  opacity: 1
}

#multiscroll-nav.right {
  right: 17px
}

#multiscroll-nav.left {
  left: 17px
}

#multiscroll-nav ul {
  margin: 0;
  padding: 0
}

#multiscroll-nav li {
  display: block;
  width: 8px;
  height: 8px;
  margin: 13px 7px;
  position: relative
}

#multiscroll-nav li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none
}

#multiscroll-nav li .active span {
  background-color: #000
}

#multiscroll-nav.light li .active span {
  background-color: #fff
}

#multiscroll-nav.dark li .active span {
  background-color: #000
}

#multiscroll-nav span {
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  border: 1px solid #000;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  z-index: 1
}

#multiscroll-nav.light span {
  border-color: #fff
}

#multiscroll-nav.dark span {
  border-color: #000
}

.multiscroll-tooltip {
  position: absolute;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  top: -2px;
  white-space: nowrap;
  max-width: 220px
}

.multiscroll-tooltip.right {
  right: 20px
}

.multiscroll-tooltip.left {
  left: 20px
}

.vertical_split_slider_responsive {
  display: none
}

@media only screen and (max-width: 1000px) {
  body:not(.vss_responsive_adv) .vertical_split_slider .ms-left {
    width: 100% !important;
    z-index: 2
  }

  body:not(.vss_responsive_adv) .vertical_split_slider .ms-left .ms-section {
    background: 0 0 !important;
    text-align: center !important
  }

  body:not(.vss_responsive_adv) .vertical_split_slider .ms-right {
    width: 100% !important;
    z-index: 1
  }

  .vertical_split_screen_initalized body.vss_responsive_adv.vss_width_1000 {
    overflow-y: visible !important;
    height: auto !important
  }

  .vss_responsive_adv.vss_width_1000 #multiscroll-nav,
  .vss_responsive_adv.vss_width_1000 .vertical_split_slider {
    display: none
  }

  .vss_width_1000 .vertical_split_slider_responsive {
    display: block
  }

  .vss_responsive_adv.vss_width_1000 .ms-section {
    background-position: center center
  }

  .vss_width_1000 .vertical_split_slider .ms-left {
    width: 100% !important;
    z-index: 2
  }

  .vss_width_1000 .vertical_split_slider .ms-left .ms-section {
    background: 0 0 !important;
    text-align: center !important
  }

  .vss_width_1000 .vertical_split_slider .ms-right {
    width: 100% !important;
    z-index: 1
  }
}

@media only screen and (max-width: 767px) {
  .vertical_split_screen_initalized body.vss_responsive_adv.vss_width_768 {
    overflow-y: visible !important;
    height: auto !important
  }

  .vss_responsive_adv.vss_width_768 #multiscroll-nav,
  .vss_responsive_adv.vss_width_768 .vertical_split_slider {
    display: none
  }

  .vss_width_768 .vertical_split_slider_responsive {
    display: block
  }

  .vss_responsive_adv .ms-section {
    background-position: center center
  }

  .vss_width_768 .vertical_split_slider .ms-left {
    width: 100% !important;
    z-index: 2
  }

  .vss_width_768 .vertical_split_slider .ms-left .ms-section {
    background: 0 0 !important;
    text-align: center !important
  }

  .vss_width_768 .vertical_split_slider .ms-right {
    width: 100% !important;
    z-index: 1
  }
}

@media only screen and (max-width: 600px) {
  .vertical_split_screen_initalized body.vss_responsive_adv.vss_width_600 {
    overflow-y: visible !important;
    height: auto !important
  }

  .vss_responsive_adv.vss_width_600 #multiscroll-nav,
  .vss_responsive_adv.vss_width_600 .vertical_split_slider {
    display: none
  }

  .vss_width_600 .vertical_split_slider_responsive {
    display: block
  }

  .vss_responsive_adv.vss_width_600 .ms-section {
    background-position: center center
  }

  .vss_width_600 .vertical_split_slider .ms-left {
    width: 100% !important;
    z-index: 2
  }

  .vss_width_600 .vertical_split_slider .ms-left .ms-section {
    background: 0 0 !important;
    text-align: center !important
  }

  .vss_width_600 .vertical_split_slider .ms-right {
    width: 100% !important;
    z-index: 1
  }
}

body.paspartu_enabled:not(.paspartu_on_bottom_fixed) .wrapper_inner {
  position: relative
}

.paspartu_outer {
  padding: 0 2%;
  z-index: 99
}

.paspartu_outer:not(.paspartu_on_bottom_fixed) {
  pointer-events: none
}

.paspartu_outer:not(.paspartu_on_bottom_fixed) .paspartu_inner {
  pointer-events: all
}

.paspartu_on_top_fixed .fixed_top_header .top_header,
.paspartu_on_top_fixed .paspartu_outer .content_wrapper,
.paspartu_on_top_fixed header,
body:not(.paspartu_on_top_fixed) .paspartu_outer .content:not(.has_slider) .content_inner {
  padding-top: 2%
}

.paspartu_left,
.paspartu_right {
  position: absolute;
  height: 100%;
  width: 2%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 101
}

.paspartu_outer:not(.paspartu_on_bottom_fixed):not(.disable_top_paspartu):not(disable_bottom_paspartu) .paspartu_left,
.paspartu_outer:not(.paspartu_on_bottom_fixed):not(.disable_top_paspartu):not(disable_bottom_paspartu) .paspartu_right {
  height: 200%;
  margin: -5% 0
}

.paspartu_bottom,
.paspartu_top {
  padding-top: 2%;
  height: 0;
  background-color: #fff;
  width: 100%;
  z-index: 200
}

.paspartu_right {
  left: auto;
  right: 0
}

.paspartu_top {
  position: fixed;
  top: 0;
  left: 0
}

.paspartu_bottom {
  position: relative
}

.paspartu_on_bottom_fixed .paspartu_bottom {
  position: fixed;
  bottom: 0;
  left: 0
}

.paspartu_on_bottom_fixed footer {
  margin-bottom: 2%
}

body.paspartu_on_top_fixed.paspartu_on_bottom_fixed .popup_menu_holder_outer {
  padding: 2%;
  box-sizing: border-box
}

.paspartu_enabled #multiscroll-nav.right {
  padding-right: 2%
}

.paspartu_outer .content:not(.has_slider) .content_inner,
.paspartu_outer .q_slider {
  background-color: #fff
}

.paspartu_outer.paspartu_on_bottom_slider .carousel-inner,
.paspartu_outer:not(.disable_top_paspartu) .carousel-inner {
  position: relative;
  height: 100% !important
}

.paspartu_outer.disable_top_paspartu .content:not(.has_slider) .content_inner {
  padding-top: 0 !important
}

.paspartu_outer.disable_bottom_paspartu,
.paspartu_outer.paspartu_on_bottom_fixed {
  padding-bottom: 0 !important
}

.paspartu_enabled .header_inner {
  position: relative
}

header.paspartu_header_alignment .header_bottom {
  padding: 0 2%
}

footer.paspartu_footer_alignment .footer_top.footer_top_full {
  padding-left: 0 !important;
  padding-right: 0 !important
}

footer.paspartu_footer_alignment .footer_bottom_holder,
footer.paspartu_footer_alignment .footer_top_holder {
  padding-left: 2%;
  padding-right: 2%
}

header,
header.paspartu_header_inside {
  padding-left: 2%;
  padding-right: 2%;
  box-sizing: border-box
}

.paspartu_enabled.paspartu_on_top_fixed .fixed_top_header .qode_search_form_3 {
  margin-top: 2%
}

.paspartu_enabled .vertical_split_slider_preloader,
.paspartu_enabled.paspartu_on_top_fixed .fixed_top_header .qode_search_form_3,
header.paspartu_header_inside.fixed_top_header .top_header {
  width: 96%;
  margin-left: 2%;
  margin-right: 2%
}

.paspartu_enabled .paspartu_outer:not(.disable_top_paspartu) .vertical_split_slider {
  margin-top: -2%
}

.paspartu_enabled .paspartu_outer:not(.disable_bottom_paspartu) .vertical_split_slider {
  margin-bottom: -2%
}

.paspartu_on_top_fixed header {
  z-index: 202
}

.paspartu_on_top_fixed:not(.paspartu_on_bottom_fixed) .popup_menu_holder_outer {
  z-index: 201
}

.paspartu_enabled.vertical_menu_inside_paspartu .vertical_area_background,
.paspartu_enabled.vertical_menu_inside_paspartu .vertical_menu_hidden_button,
.carousel-inner:not(.relative_position) {
  margin-left: 2%
}

.carousel-inner:not(.relative_position),
.paspartu_enabled.vertical_menu_inside_paspartu.vertical_menu_right .vertical_area_background,
.paspartu_enabled.vertical_menu_inside_paspartu.vertical_menu_right .vertical_menu_hidden_button,
.paspartu_enabled.vertical_menu_inside_paspartu.vertical_menu_right aside.vertical_menu_area {
  margin-left: 0;
  margin-right: 2%
}

.paspartu_enabled.vertical_menu_inside_paspartu .vertical_area_background,
.paspartu_enabled.vertical_menu_inside_paspartu .vertical_menu_hidden_button,
.paspartu_enabled.vertical_menu_inside_paspartu aside.vertical_menu_area {
  margin-top: 2%
}

.vertical_menu_hidden .carousel-inner:not(.relative_position),
.paspartu_outer.paspartu_on_bottom_slider .carousel-inner:not(.relative_position),
.paspartu_outer:not(.disable_top_paspartu) .carousel-inner:not(.relative_position),
.paspartu_outer.paspartu_on_bottom_slider .carousel-inner:not(.relative_position),
.paspartu_outer:not(.disable_top_paspartu) .carousel-inner:not(.relative_position),
.paspartu_outer.paspartu_on_bottom_slider .carousel-inner:not(.relative_position),
.paspartu_outer:not(.disable_top_paspartu) .carousel-inner:not(.relative_position),
.paspartu_outer.paspartu_on_bottom_slider .carousel-inner:not(.relative_position),
.paspartu_outer:not(.disable_top_paspartu) .carousel-inner:not(.relative_position) {
  left: 0 !important;
  margin-left: 0 !important
}

header {
  padding: 0 !important
}

.paspartu_enabled.vertical_menu_outside_paspartu aside.vertical_menu_area {
  z-index: 500
}

body.vertical_menu_outside_paspartu.paspartu_on_top_fixed .paspartu_outer {
  padding-top: 2%
}

.q_slider,
.paspartu_outer .content .content_inner {
  padding-top: 0 !important
}

@media only screen and (min-width: 1000px) {
  .vertical_menu_outside_paspartu .content_wrapper {
    margin-left: 2%;
    padding-left: 260px;
    width: 98%
  }

  .vertical_menu_outside_paspartu.vertical_menu_left.vertical_menu_width_290 .content_wrapper {
    margin-left: 2%;
    padding-left: 290px;
    width: 98%
  }

  .vertical_menu_outside_paspartu.vertical_menu_left.vertical_menu_width_350 .content_wrapper {
    margin-left: 2%;
    padding-left: 350px;
    width: 98%
  }

  .vertical_menu_outside_paspartu.vertical_menu_left.vertical_menu_width_400 .content_wrapper {
    margin-left: 2%;
    padding-left: 400px;
    width: 98%
  }

  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_290 .content_wrapper {
    margin-right: 2%;
    padding-right: 290px;
    width: 98%
  }

  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_350 .content_wrapper {
    margin-right: 2%;
    padding-right: 350px;
    width: 98%
  }

  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_400 .content_wrapper {
    margin-right: 2%;
    padding-right: 400px;
    width: 98%
  }

  .vertical_menu_outside_paspartu .paspartu_middle_inner {
    padding-left: 260px
  }

  .vertical_menu_outside_paspartu .paspartu_left {
    left: 260px
  }

  .vertical_menu_outside_paspartu.vertical_menu_width_290 .paspartu_middle_inner {
    padding-left: 290px
  }

  .vertical_menu_outside_paspartu.vertical_menu_width_290 .paspartu_left {
    left: 290px
  }

  .vertical_menu_outside_paspartu.vertical_menu_width_350 .paspartu_middle_inner {
    padding-left: 350px
  }

  .vertical_menu_outside_paspartu.vertical_menu_width_350 .paspartu_left {
    left: 350px
  }

  .vertical_menu_outside_paspartu.vertical_menu_width_400 .paspartu_middle_inner {
    padding-left: 400px
  }

  .vertical_menu_outside_paspartu.vertical_menu_width_400 .paspartu_left {
    left: 400px
  }

  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_290 .paspartu_left,
  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_350 .paspartu_left,
  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_400 .paspartu_left {
    left: 0
  }

  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_290 .paspartu_middle_inner {
    padding-left: 0;
    padding-right: 290px
  }

  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_290 .paspartu_right {
    right: 290px
  }

  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_350 .paspartu_middle_inner {
    padding-left: 0;
    padding-right: 350px
  }

  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_350 .paspartu_right {
    right: 350px
  }

  .vertical_menu_outside_paspartu.vertical_menu_right.vertical_menu_width_400 .paspartu_middle_inner {
    padding-left: 0;
    padding-right: 400px
  }

  .paspartu_right {
    right: 400px
  }
}

.vertical_menu_outside_paspartu.vertical_menu_enabled .content .container,
.vertical_menu_outside_paspartu.vertical_menu_enabled .full_screen_holder,
.vertical_menu_outside_paspartu.vertical_menu_enabled .full_width,
.vertical_menu_outside_paspartu.vertical_menu_enabled .q_slider,
.vertical_menu_outside_paspartu.vertical_menu_enabled .title_outer,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_350 .content .container,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_350 .full_screen_holder,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_350 .full_width,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_350 .q_slider,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_350 .title_outer,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_350 footer,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_400 .content .container,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_400 .full_screen_holder,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_400 .full_width,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_400 .q_slider,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_400 .title_outer,
.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_400 footer {
  padding-left: 0;
  padding-right: 0
}

body.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_right .ajax_loader,
body.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_290.vertical_menu_right:not(.vertical_menu_hidden) .ajax_loader,
body.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_290:not(.vertical_menu_hidden) .ajax_loader,
body.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_350.vertical_menu_right:not(.vertical_menu_hidden) .ajax_loader,
body.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_350:not(.vertical_menu_hidden) .ajax_loader,
body.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_400.vertical_menu_right:not(.vertical_menu_hidden) .ajax_loader,
body.vertical_menu_outside_paspartu.vertical_menu_enabled.vertical_menu_width_400:not(.vertical_menu_hidden) .ajax_loader,
body.vertical_menu_outside_paspartu.vertical_menu_enabled:not(.vertical_menu_hidden) .ajax_loader {
  margin-left: 0
}

@media only screen and (min-width: 1024px) {
  header.paspartu_header_alignment .header_inner_left {
    left: 2%
  }
}

@media only screen and (max-width: 1024px) {
  .paspartu_outer {
    padding: 0 2% 2% !important
  }

  .paspartu_bottom,
  .paspartu_on_top_fixed .fixed_top_header .top_header,
  .paspartu_on_top_fixed .paspartu_outer .content_wrapper,
  .paspartu_on_top_fixed header,
  .paspartu_top,
  body:not(.paspartu_on_top_fixed) .paspartu_outer .content:not(.has_slider) .content_inner {
    padding-top: 2% !important
  }

  .paspartu_left,
  .paspartu_right {
    width: 2% !important
  }

  .paspartu_on_bottom_fixed footer {
    margin-bottom: 2% !important
  }

  body.paspartu_on_top_fixed.paspartu_on_bottom_fixed .popup_menu_holder_outer {
    padding: 2% !important
  }

  .paspartu_enabled #multiscroll-nav.right {
    padding-right: 2% !important
  }

  header.paspartu_header_alignment .header_bottom {
    padding: 0 2% !important
  }

  .paspartu_enabled.vertical_menu_enabled header,
  footer.paspartu_footer_alignment .footer_bottom_holder,
  footer.paspartu_footer_alignment .footer_top_holder,
  header.paspartu_header_inside {
    padding-left: 2% !important;
    padding-right: 2% !important
  }

  .paspartu_enabled.paspartu_on_top_fixed .fixed_top_header .qode_search_form_3 {
    margin-top: 2% !important
  }

  .paspartu_enabled .vertical_split_slider_preloader,
  .paspartu_enabled.paspartu_on_top_fixed .fixed_top_header .qode_search_form_3,
  header.paspartu_header_inside.fixed_top_header .top_header {
    width: 96% !important;
    margin-left: 2% !important;
    margin-right: 2% !important
  }

  .paspartu_enabled .paspartu_outer:not(.disable_top_paspartu) .vertical_split_slider {
    margin-top: -2% !important
  }

  .paspartu_enabled .paspartu_outer:not(.disable_bottom_paspartu) .vertical_split_slider {
    margin-bottom: -2% !important
  }

  .paspartu_enabled.vertical_menu_inside_paspartu .vertical_area_background,
  .paspartu_enabled.vertical_menu_inside_paspartu .vertical_menu_hidden_button,
  .paspartu_enabled.vertical_menu_inside_paspartu aside.vertical_menu_area,
  .paspartu_enabled.vertical_menu_inside_paspartu.vertical_menu_enabled .carousel-inner:not(.relative_position) {
    margin-left: 2% !important
  }

  .paspartu_enabled.vertical_menu_inside_paspartu.vertical_menu_enabled.vertical_menu_right .carousel-inner:not(.relative_position),
  .paspartu_enabled.vertical_menu_inside_paspartu.vertical_menu_right .vertical_area_background,
  .paspartu_enabled.vertical_menu_inside_paspartu.vertical_menu_right .vertical_menu_hidden_button,
  .paspartu_enabled.vertical_menu_inside_paspartu.vertical_menu_right aside.vertical_menu_area {
    margin-left: 0 !important;
    margin-right: 2% !important
  }

  .paspartu_enabled.vertical_menu_inside_paspartu .vertical_area_background,
  .paspartu_enabled.vertical_menu_inside_paspartu .vertical_menu_hidden_button,
  .paspartu_enabled.vertical_menu_inside_paspartu aside.vertical_menu_area {
    margin-top: 2% !important
  }

  body.vertical_menu_outside_paspartu.paspartu_on_top_fixed .paspartu_outer {
    padding-top: 2% !important
  }
}

.vc_grid-container .vc_row.vc_grid .vc_grid-item .vc_btn,
.vc_grid-container .vc_row.vc_grid .vc_pageable-load-more-btn .vc_btn {
  padding: 0 23px;
  font-size: 13px;
  text-align: left;
  cursor: pointer;
  display: inline-block;
  width: auto;
  font-weight: 700;
  font-style: normal;
  font-family: inherit;
  letter-spacing: 1px;
  border: 2px solid #303030;
  height: 39px;
  text-decoration: none;
  white-space: nowrap;
  outline: 0;
  text-shadow: none;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  line-height: 39px
}

.vc_grid-container .vc_row.vc_grid .vc_grid-item .vc_btn {
  position: relative;
  margin: 0;
  color: #303030 !important;
  text-transform: uppercase;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: transparent;
  -moz-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -ms-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -o-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  box-sizing: initial !important
}

.vc_grid-container .vc_row.vc_grid .vc_grid-item .vc_btn:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff !important;
  text-decoration: none
}

.vc_grid-container .vc_row.vc_grid .vc_pageable-load-more-btn .vc_btn {
  position: relative;
  margin: 0;
  color: #303030 !important;
  text-transform: uppercase;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background-color: transparent;
  -moz-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -ms-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -o-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  box-sizing: initial !important
}

.vc_grid-container .vc_row.vc_grid .vc_pageable-load-more-btn .vc_btn:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff !important;
  text-decoration: none
}

.vc_grid-container .vc_grid.vc_grid-owl-theme .vc_grid-owl-dots.vc_grid-square_dots .vc_grid-owl-dot span {
  border: 2px solid #e5e5e5;
  background-color: transparent !important
}

.vc_grid-container .vc_grid.vc_grid-owl-theme .vc_grid-owl-dots.vc_grid-square_dots .vc_grid-owl-dot span:hover,
.vc_grid-container .vc_grid.vc_grid-owl-theme .vc_grid-owl-dots.vc_grid-square_dots .vc_grid-owl-dot.active span {
  background-color: #e3e3e3 !important;
  border-color: #e3e3e3 !important
}

.vc_grid-container .vc_grid.vc_grid-owl-theme .vc_grid-owl-dots.vc_grid-radio_dots .vc_grid-owl-dot span {
  border: 2px solid #e5e5e5;
  border-radius: 50%;
  background-color: transparent !important
}

.vc_grid-container .vc_grid.vc_grid-owl-theme .vc_grid-owl-dots.vc_grid-radio_dots .vc_grid-owl-dot span:hover,
.vc_grid-container .vc_grid.vc_grid-owl-theme .vc_grid-owl-dots.vc_grid-radio_dots .vc_grid-owl-dot.active span {
  background-color: #e3e3e3 !important;
  border-color: #e3e3e3 !important
}

.vc_grid-container .vc_grid.vc_grid-owl-theme .vc_grid-owl-dots.vc_grid-fill_square_dots .vc_grid-owl-dot span,
.vc_grid-container .vc_grid.vc_grid-owl-theme .vc_grid-owl-dots.vc_grid-point_dots .vc_grid-owl-dot span,
.vc_grid.vc_grid-owl-theme .vc_grid-owl-dots.vc_grid-round_fill_square_dots .vc_grid-owl-dot span {
  background-color: #e3e3e3 !important
}

.vc_grid-container .vc_grid-pagination .vc_grid-pagination-list>li>a {
  background-color: transparent !important;
  color: #b4b4b4 !important;
  -o-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  border-radius: 4px !important;
  font-size: 18px;
  margin: 0 11px 0 0;
  text-align: center;
  width: 42px;
  height: 42px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  border: 2px solid #e5e5e5
}

.vc_grid-container .vc_grid-pagination .vc_grid-pagination-list>li.vc_grid-active>a,
.vc_grid-container .vc_grid-pagination .vc_grid-pagination-list>li>a:hover {
  color: #303030 !important;
  background-color: #e3e3e3 !important;
  border-color: #e3e3e3
}

.vc_grid-container .vc_grid-pagination-list.vc_grid-pagination_rounded>li>a,
.vc_grid-container .vc_grid-pagination-list.vc_grid-pagination_rounded_dark>li>a,
.vc_grid-container .vc_grid-pagination-list.vc_grid-pagination_rounded_light>li>a {
  border-radius: 30px !important
}

.vc_grid-container .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination_square>li>a,
.vc_grid-container .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination_square_dark>li>a,
.vc_grid-container .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination_square_light>li>a {
  line-height: 38px;
  border-radius: 0 !important
}

.vc_grid-container .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination_stripes_dark>li>a,
.vc_grid-container .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination_stripes_light>li>a {
  width: 22px;
  height: 36px;
  line-height: 36px
}

.vc_grid.vc_row.vc_grid-gutter-30px {
  margin-bottom: 0 !important
}

.vc_grid-container .vc_grid-filter.vc_grid-filter-color-grey>.vc_grid-filter-item {
  border: none !important
}

.vc_grid-container .vc_grid-filter.vc_grid-filter-color-grey>.vc_grid-filter-item.vc_active,
.vc_grid-container .vc_grid-filter.vc_grid-filter-color-grey>.vc_grid-filter-item:hover {
  background: 0 0
}

.overlapping_content .title_outer {
  position: relative;
  z-index: 50
}

.overlapping_content .content>.container {
  background-color: #f8f8f8
}

.overlapping_content .content .content_inner>.container>.overlapping_content,
.overlapping_content .content .content_inner>.full_width>.full_width_inner {
  margin-top: -40px;
  position: relative;
  z-index: 100
}

.overlapping_content .title .title_holder .container {
  padding-bottom: 40px;
  box-sizing: border-box
}

.overlapping_content .content .content_inner>.container {
  text-align: center
}

.overlapping_content .content .content_inner>.container>.overlapping_content {
  display: inline-block;
  vertical-align: middle;
  margin-right: auto;
  margin-left: auto;
  padding: 0 40px;
  background-color: #fff
}

.overlapping_content .content .content_inner>.container>.overlapping_content .overlapping_content_inner {
  overflow: hidden;
  text-align: left
}

.overlapping_content_margin {
  margin: 0 -40px;
  display: block;
  position: relative
}

@media only screen and (max-width: 1200px) {
  .overlapping_content .content .content_inner>.container>.overlapping_content {
    padding: 0 20px !important
  }

  .overlapping_content_margin {
    margin: 0 -20px !important
  }
}

@media only screen and (max-width: 1000px) {
  header .overlapping_content_margin {
    margin: 0 !important
  }
}

.blog_vertical_loop .full_width_inner {
  margin: 0 !important
}

.blog_vertical_loop article {
  position: relative;
  vertical-align: middle;
  z-index: 1;
  margin: 0 0 140px
}

.blog_vertical_loop article.move_up {
  -webkit-transition: transform 450ms ease 0s;
  transition: transform 450ms ease 0s
}

.blog_vertical_loop article.fade_out {
  opacity: 0;
  transform: scale(.8) translate3d(0, -10%, 0);
  transition: all 450ms ease 0s
}

.blog_vertical_loop article .post_image {
  margin-bottom: 55px !important;
  height: 400px
}

.blog_vertical_loop article .post_image_inner {
  height: 100%;
  overflow: hidden
}

.blog_vertical_loop article .post_image_inner a {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover
}

.blog_vertical_loop article.previous_post {
  position: absolute;
  opacity: 0;
  transform: scale(.8) translate3d(0, -10%, 0)
}

.blog_vertical_loop article.fade_in {
  transition: all 450ms ease 0s;
  opacity: 1;
  transform: scale(1) translate3d(0, 0, 0)
}

.blog_vertical_loop article.next_post,
.blog_vertical_loop article.next_post .post_content_holder .post_image {
  margin: 0 !important
}

.blog_vertical_loop article.next_post .post_content_holder .post_image>.grid_section,
.blog_vertical_loop article.next_post .post_content_holder>.grid_section {
  display: none
}

.blog_vertical_loop .blog_load_next {
  display: none;
  position: absolute;
  left: 0;
  top: -30px;
  width: 100%
}

.blog_vertical_loop article.next_post .blog_load_next {
  display: block
}

.blog_vertical_loop .blog_load_prev {
  display: block;
  position: absolute;
  left: 0;
  bottom: -30px;
  width: 100%
}

.blog_vertical_loop article.next_post .blog_load_prev {
  display: none
}

.blog_vertical_loop_button {
  text-align: left
}

.blog_vertical_loop_back_button {
  text-align: right
}

.blog_vertical_loop_back_button .button_icon a,
.blog_vertical_loop_button .button_icon a {
  width: 60px;
  height: 60px;
  background-color: #303030;
  display: inline-block;
  position: relative;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: background-color .15s ease-out;
  -moz-transition: background-color .15s ease-out;
  -ms-transition: background-color .15s ease-out;
  -o-transition: background-color .15s ease-out;
  transition: background-color .15s ease-out
}

.blog_vertical_loop_back_button .button_icon a:before,
.blog_vertical_loop_button .button_icon a:before {
  content: '3';
  font-family: ElegantIcons;
  font-variant: normal;
  font-weight: 400;
  line-height: 60px;
  text-transform: none;
  color: #fff;
  font-size: 22px;
  -webkit-transition: color .15s ease-out;
  -moz-transition: color .15s ease-out;
  -ms-transition: color .15s ease-out;
  -o-transition: color .15s ease-out;
  transition: color .15s ease-out
}

.blog_vertical_loop_back_button .button_icon a:before {
  content: 'J'
}

.blog_vertical_loop_button_holder .last_page {
  display: none
}

.blog_vertical_loop article .post_image_title {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: table;
  -webkit-transition: all .5s ease 0s;
  transition: all .5s ease 0s
}

.blog_vertical_loop article.next_post .post_image_title {
  opacity: 1
}

.blog_vertical_loop article .post_image_title .post_image_title_inner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background-color: rgba(0, 0, 0, .6)
}

.blog_vertical_loop article .post_image_title .post_image_title_inner h2 {
  color: #fff
}

.blog_holder.blog_vertical_loop_type {
  position: relative
}

.blog_holder.blog_vertical_loop_type article:not(.format-quote):not(.format-link) .social_share_list_holder {
  margin-top: 15px
}

.blog_holder.blog_vertical_loop_type .qbutton.loop_more {
  margin: 40px 0 0
}

.blog_holder.blog_vertical_loop_type article:not(.format-quote):not(.format-link) .post_text .post_text_inner {
  background-color: transparent;
  padding: 0
}

.blog_holder.blog_vertical_loop_type article.format-link .post_text,
.blog_holder.blog_vertical_loop_type article.format-quote .post_text {
  margin-bottom: 30px
}

.blog_holder.blog_vertical_loop_type article .post_text h2 {
  margin-bottom: 35px
}

.blog_holder article.blog_vertical_loop_type .post_info {
  margin-bottom: 8px
}

.blog_holder.blog_vertical_loop_type article .post_info>div,
.blog_holder.blog_vertical_loop_type article .post_info>span {
  padding-right: 20px;
  position: relative;
  float: left
}

.blog_holder.blog_vertical_loop_type article .post_info>div:last-child,
.blog_holder.blog_vertical_loop_type article .post_info>span:last-child {
  padding-right: 0
}

.blog_holder.blog_vertical_loop_type article .post_info>div:after,
.blog_holder.blog_vertical_loop_type article .post_info>span:after {
  position: absolute;
  right: 5px;
  top: 0;
  content: "/"
}

.blog_holder.blog_vertical_loop_type article .post_info>div:last-child:after,
.blog_holder.blog_vertical_loop_type article .post_info>span:last-child:after {
  content: ""
}

.blog_holder.blog_vertical_loop_type .blog_like a {
  line-height: inherit
}

.qode_parallax_layers {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden
}

.qode_parallax_layers_holder {
  position: relative;
  width: 110%;
  height: 110%;
  top: -5%;
  left: -5%
}

.qode_parallax_layers_holder .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  transform: translateZ(0);
  -webkit-transform: translateZ(0)
}

.qode_parallax_layers_holder .paralax_layers_content_holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.qode_parallax_layers_holder .paralax_layers_content {
  display: table;
  width: 100%;
  height: 100%
}

.qode_parallax_layers_holder .paralax_layers_content_inner {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  text-align: center
}

.qode-instagram-feed {
  margin-left: -5px;
  margin-right: -5px;
  list-style: none
}

.qode-instagram-feed.qodef-scale-with-light-overlay a {
  display: block;
  position: relative;
  overflow: hidden
}

.qode-instagram-feed.qodef-scale-with-light-overlay a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, .15);
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease
}

.qode-instagram-feed.qodef-scale-with-light-overlay a:hover:after {
  opacity: 1
}

.qode-instagram-feed.qodef-scale-with-light-overlay a:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05)
}

.qode-instagram-feed.qodef-scale-with-light-overlay a img {
  -webkit-transition: -webkit-transform .4s ease;
  transition: transform .4s ease
}

.qode-instagram-feed.qodef-scale-with-dark-overlay a {
  display: block;
  position: relative;
  overflow: hidden
}

.qode-instagram-feed.qodef-scale-with-dark-overlay a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .35);
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease
}

.qode-instagram-feed.qodef-scale-with-dark-overlay a:hover:after {
  opacity: 1
}

.qode-instagram-feed.qodef-scale-with-dark-overlay a:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05)
}

.qode-instagram-feed.qodef-scale-with-dark-overlay a img {
  -webkit-transition: -webkit-transform .4s ease;
  transition: transform .4s ease
}

.qode-instagram-feed li {
  float: left;
  padding: 0 5px !important;
  margin: 0 0 10px !important;
  box-sizing: border-box
}

.qode-instagram-feed li img {
  display: block
}

.qode-instagram-feed.qode-col-12 li {
  width: 8.333333333333333%
}

.qode-instagram-feed.qode-col-9 li {
  width: 11.11111111111111%
}

.qode-instagram-feed.qode-col-8 li {
  width: 12.5%
}

.qode-instagram-feed.qode-col-7 li {
  width: 14.28571428%
}

.qode-instagram-feed.qode-col-6 li {
  width: 16.66666666666667%
}

.qode-instagram-feed.qode-col-5 li {
  width: 20%
}

.qode-instagram-feed.qode-col-4 li {
  width: 25%
}

.qode-instagram-feed.qode-col-3 li {
  width: 33.33%
}

.qode-instagram-feed.qode-col-2 li {
  width: 50%
}

@-webkit-keyframes justified-gallery-show-caption-animation {
  from {
    opacity: 0
  }

  to {
    opacity: .7
  }
}

@-moz-keyframes justified-gallery-show-caption-animation {
  from {
    opacity: 0
  }

  to {
    opacity: .7
  }
}

@-o-keyframes justified-gallery-show-caption-animation {
  from {
    opacity: 0
  }

  to {
    opacity: .7
  }
}

@keyframes justified-gallery-show-caption-animation {
  from {
    opacity: 0
  }

  to {
    opacity: .7
  }
}

@-webkit-keyframes justified-gallery-show-entry-animation {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@-moz-keyframes justified-gallery-show-entry-animation {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@-o-keyframes justified-gallery-show-entry-animation {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

@keyframes justified-gallery-show-entry-animation {
  from {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

.justified-gallery {
  width: 100%;
  position: relative;
  overflow: hidden
}

.justified-gallery>a,
.justified-gallery>div {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0)
}

.justified-gallery>a>a>img,
.justified-gallery>a>img,
.justified-gallery>div>a>img,
.justified-gallery>div>img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  border: 0
}

.justified-gallery>a>.caption,
.justified-gallery>div>.caption {
  display: none;
  position: absolute;
  bottom: 0;
  padding: 5px;
  background-color: #000;
  left: 0;
  right: 0;
  margin: 0;
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  font-family: sans-serif
}

.justified-gallery>a>.caption.caption-visible,
.justified-gallery>div>.caption.caption-visible {
  display: initial;
  opacity: .7;
  filter: "alpha(opacity=70)";
  animation: justified-gallery-show-caption-animation .5s 0 ease;
  -moz-animation: justified-gallery-show-caption-animation .5s 0 ease;
  -ms-animation: justified-gallery-show-caption-animation .5s 0 ease
}

.justified-gallery>.entry-visible {
  opacity: 1;
  filter: alpha(opacity=100);
  animation: justified-gallery-show-entry-animation .5s 0 ease;
  -moz-animation: justified-gallery-show-entry-animation .5s 0 ease;
  -ms-animation: justified-gallery-show-entry-animation .5s 0 ease
}

.justified-gallery>.jg-filtered {
  display: none
}

.justified-gallery>.spinner {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  padding: 10px 0;
  left: 50%;
  opacity: initial;
  filter: initial;
  overflow: initial
}

.justified-gallery>.spinner>span {
  display: inline-block;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #000;
  border-radius: 6px
}

.justified-gallery>article {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0)
}

.justified-gallery>article a img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  border: 0
}

.qode-grid-lines-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1
}

.qode-grid-lines-holder .qode-grid-line {
  display: block;
  float: left;
  height: 100%;
  border-left: 1px solid #f4f5fa;
  box-sizing: border-box
}

.qode-grid-lines-holder .qode-grid-line:last-child {
  border-right: 1px solid #f4f5fa
}

.qode-grid-lines-holder.qode-grid-lines-dark-skin .qode-grid-line {
  border-left: 1px solid #222
}

.qode-grid-lines-holder.qode-grid-lines-dark-skin .qode-grid-line:last-child {
  border-right: 1px solid #222
}

.qode-grid-lines-holder.qode-grid-columns-2 .qode-grid-line {
  width: 50%
}

.qode-grid-lines-holder.qode-grid-columns-3 .qode-grid-line {
  width: 33.33%
}

.qode-grid-lines-holder.qode-grid-columns-4 .qode-grid-line {
  width: 25%
}

.qode-grid-lines-holder.qode-grid-columns-5 .qode-grid-line {
  width: 20%
}

.qode-grid-lines-holder.qode-grid-columns-6 .qode-grid-line {
  width: 16.666%
}

.qode_left_side_fixed {
  position: fixed;
  z-index: 999;
  left: 4.5%;
  bottom: 7.5%;
  transform: rotate(-90deg) translateZ(0);
  transform-origin: bottom left 0
}

.qode_right_side_fixed {
  position: fixed;
  right: 0;
  top: 50%;
  transform: rotate(-90deg);
  z-index: 999
}

.search-results .blog_holder.qode-search-results-two-columns article {
  float: left;
  width: 50%
}

.search-results .blog_holder.qode-search-results-three-columns article {
  float: left;
  width: 33.33333%
}

.search-results .blog_holder.qode-search-results-four-columns article {
  float: left;
  width: 25%
}

.search-results .blog_holder.qode-search-results-five-columns article {
  float: left;
  width: 20%
}

.search-results .blog_holder.qode-search-results-six-columns article {
  float: left;
  width: 16.66667%
}

@media only screen and (min-width: 1025px) {

  .search-results .blog_holder.qode-search-results-five-columns article:nth-child(5n+1),
  .search-results .blog_holder.qode-search-results-four-columns article:nth-child(4n+1),
  .search-results .blog_holder.qode-search-results-six-columns article:nth-child(6n+1),
  .search-results .blog_holder.qode-search-results-three-columns article:nth-child(3n+1),
  .search-results .blog_holder.qode-search-results-two-columns article:nth-child(2n+1) {
    clear: both
  }
}

@media only screen and (max-width: 1400px) {
  .search-results .blog_holder.qode-search-results-six-columns article {
    width: 20%
  }
}

@media only screen and (max-width: 1400px) and (min-width:1281px) {
  .search-results .blog_holder.qode-search-results-six-columns article:nth-child(6n+1) {
    clear: none
  }

  .search-results .blog_holder.qode-search-results-six-columns article:nth-child(5n+1) {
    clear: both
  }
}

@media only screen and (max-width: 1280px) {

  .search-results .blog_holder.qode-search-results-five-columns article,
  .search-results .blog_holder.qode-search-results-six-columns article {
    width: 25%
  }
}

@media only screen and (max-width: 1280px) and (min-width:1025px) {
  .search-results .blog_holder.qode-search-results-five-columns article:nth-child(5n+1) {
    clear: none
  }

  .search-results .blog_holder.qode-search-results-five-columns article:nth-child(4n+1) {
    clear: both
  }

  .search-results .blog_holder.qode-search-results-six-columns article:nth-child(6n+1) {
    clear: none
  }

  .search-results .blog_holder.qode-search-results-six-columns article:nth-child(4n+1) {
    clear: both
  }
}

@media only screen and (max-width: 1024px) and (min-width:769px) {

  .search-results .blog_holder.qode-search-results-five-columns article:nth-child(3n+1),
  .search-results .blog_holder.qode-search-results-six-columns article:nth-child(3n+1) {
    clear: both
  }
}

@media only screen and (max-width: 1024px) {

  .search-results .blog_holder.qode-search-results-five-columns article,
  .search-results .blog_holder.qode-search-results-six-columns article {
    width: 33.33333%
  }

  .search-results .blog_holder.qode-search-results-four-columns article {
    width: 50%
  }
}

@media only screen and (max-width: 1024px) and (min-width:681px) {

  .search-results .blog_holder.qode-search-results-four-columns article:nth-child(4n+1),
  .search-results .blog_holder.qode-search-results-three-columns article:nth-child(3n+1),
  .search-results .blog_holder.qode-search-results-two-columns article:nth-child(2n+1) {
    clear: both
  }
}

@media only screen and (max-width: 768px) and (min-width:681px) {

  .search-results .blog_holder.qode-search-results-five-columns article:nth-child(2n+1),
  .search-results .blog_holder.qode-search-results-six-columns article:nth-child(2n+1) {
    clear: both
  }
}

@media only screen and (max-width: 768px) {

  .search-results .blog_holder.qode-search-results-five-columns article,
  .search-results .blog_holder.qode-search-results-six-columns article {
    width: 50%
  }

  .search-results .blog_holder.qode-search-results-three-columns article {
    width: 100%
  }
}

@media only screen and (max-width: 600px) {

  .search-results .blog_holder.qode-search-results-five-columns article,
  .search-results .blog_holder.qode-search-results-four-columns article,
  .search-results .blog_holder.qode-search-results-six-columns article,
  .search-results .blog_holder.qode-search-results-three-columns article,
  .search-results .blog_holder.qode-search-results-two-columns article {
    width: 100%
  }
}

.search-results .blog_holder.qode-no-space .qode-outer-space article {
  box-sizing: border-box;
  padding: 0;
  margin: 0
}

.search-results .blog_holder.qode-no-space.qode-disable-item-bottom-space article {
  margin: 0
}

.search-results .blog_holder.qode-tiny-space .qode-outer-space article {
  box-sizing: border-box;
  padding: 0 5px;
  margin: 0 0 10px
}

.search-results .blog_holder.qode-tiny-space.qode-disable-item-bottom-space article {
  margin: 0
}

.search-results .blog_holder.qode-small-space .qode-outer-space article {
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 0 20px
}

.search-results .blog_holder.qode-small-space.qode-disable-item-bottom-space article {
  margin: 0
}

.search-results .blog_holder.qode-normal-space .qode-outer-space article {
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0 0 30px
}

.search-results .blog_holder.qode-normal-space.qode-disable-item-bottom-space article {
  margin: 0
}

.search-results .blog_holder.qode-medium-space .qode-outer-space article {
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0 0 40px
}

.search-results .blog_holder.qode-medium-space.qode-disable-item-bottom-space article {
  margin: 0
}

.search-results .blog_holder.qode-large-space .qode-outer-space article {
  box-sizing: border-box;
  padding: 0 25px;
  margin: 0 0 50px
}

.search-results .blog_holder.qode-large-space.qode-disable-item-bottom-space article {
  margin: 0
}

.search-results .blog_holder.qode-huge-space .qode-outer-space article {
  box-sizing: border-box;
  padding: 0 40px;
  margin: 0 0 80px
}

.qode-no-space.qode-disable-bottom-space,
body:not([class*=bridge-core]).single-post .blog_single p {
  margin-bottom: 0
}

.search-results .blog_holder.qode-huge-space.qode-disable-item-bottom-space article {
  margin: 0
}

body.qode-chrome .container_inner,
body.qode-safari .container_inner {
  position: relative
}

body.qode-safari .boxed .carousel-inner {
  margin-left: 0
}

body.qode-safari .right_side_menu_opened.boxed .carousel-inner {
  margin-left: -270px
}

body:not([class*=bridge-core]) .blog_large_image .sticky .entry_title a {
  color: #1abc9c
}

body:not([class*=bridge-core]) .blog_holder article .post_image,
body:not([class*=bridge-core]) .blog_holder article .post_image img,
body:not([class*=bridge-core]) .blog_holder article .post_image>a {
  width: auto
}

body:not([class*=bridge-core]) .footer_top .widget_meta>ul>li,
body:not([class*=bridge-core]) .footer_top .widget_nav_menu ul li,
body:not([class*=bridge-core]) .footer_top .widget_pages ul li,
body:not([class*=bridge-core]) .footer_top .widget_recent_comments>ul>li,
body:not([class*=bridge-core]) .footer_top .widget_recent_entries>ul>li {
  padding: 0 0 10px;
  position: relative
}

body:not([class*=bridge-core]) .footer_top .widget_nav_menu ul li ul,
body:not([class*=bridge-core]) .footer_top .widget_pages ul li ul {
  padding: 10px 0 0 10px
}

body:not([class*=bridge-core]) .footer_bottom ul li {
  display: block;
  margin-right: 0
}

body.elementor-editor-active .blog_holder article.format-gallery .gallery,
body.elementor-page .blog_holder article.format-gallery .gallery {
  display: none
}

.qode-infinite-scroll-finished-label,
.qode-infinite-scroll-loading-label {
  display: none;
  text-align: center
}

.qode-shortcode-snipper-holder {
  width: 100%
}

.elementor-lightbox header.elementor-slideshow__header {
  box-sizing: border-box
}

.elementor-widget-heading .elementor-heading-title>a {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit
}

.qode-two-columns-form-without-space .qode-column-left {
  display: table-cell;
  width: 99%;
  vertical-align: top
}

.qode-two-columns-form-without-space .qode-column-right {
  display: table-cell;
  width: 1%;
  text-align: left;
  vertical-align: top
}

.qode-two-columns-form-with-space .qode-column-left {
  display: table-cell;
  width: 99%;
  vertical-align: top;
  padding: 0 10px 0 0;
  box-sizing: border-box
}

.qode-two-columns-form-with-space .qode-column-right {
  display: table-cell;
  width: 1%;
  text-align: left;
  vertical-align: top;
  padding: 0 0 0 10px;
  box-sizing: border-box
}

.qode-no-space .qode-outer-space {
  margin: 0
}

.qode-no-space .qode-outer-space .qode-item-space {
  padding: 0;
  margin: 0
}

.qode-no-space.qode-disable-item-bottom-space .qode-item-space {
  margin: 0
}

.qode-tiny-space.qode-disable-bottom-space {
  margin-bottom: -10px
}

.qode-tiny-space .qode-outer-space {
  margin: 0 -5px
}

.qode-tiny-space .qode-outer-space .qode-item-space {
  padding: 0 5px;
  margin: 0 0 10px
}

.qode-tiny-space.qode-disable-item-bottom-space .qode-item-space {
  margin: 0
}

.qode-small-space.qode-disable-bottom-space {
  margin-bottom: -20px
}

.qode-small-space .qode-outer-space {
  margin: 0 -10px
}

.qode-small-space .qode-outer-space .qode-item-space {
  padding: 0 10px;
  margin: 0 0 20px
}

.qode-small-space.qode-disable-item-bottom-space .qode-item-space {
  margin: 0
}

.qode-normal-space.qode-disable-bottom-space {
  margin-bottom: -30px
}

.qode-normal-space .qode-outer-space {
  margin: 0 -15px
}

.qode-normal-space .qode-outer-space .qode-item-space {
  padding: 0 15px;
  margin: 0 0 30px
}

.qode-normal-space.qode-disable-item-bottom-space .qode-item-space {
  margin: 0
}

.qode-medium-space.qode-disable-bottom-space {
  margin-bottom: -40px
}

.qode-medium-space .qode-outer-space {
  margin: 0 -20px
}

.qode-medium-space .qode-outer-space .qode-item-space {
  padding: 0 20px;
  margin: 0 0 40px
}

.qode-medium-space.qode-disable-item-bottom-space .qode-item-space {
  margin: 0
}

.qode-large-space.qode-disable-bottom-space {
  margin-bottom: -50px
}

.qode-large-space .qode-outer-space {
  margin: 0 -25px
}

.qode-large-space .qode-outer-space .qode-item-space {
  padding: 0 25px;
  margin: 0 0 50px
}

.qode-large-space.qode-disable-item-bottom-space .qode-item-space {
  margin: 0
}

.qode-huge-space.qode-disable-bottom-space {
  margin-bottom: -80px
}

.qode-huge-space .qode-outer-space {
  margin: 0 -40px
}

.qode-huge-space .qode-outer-space .qode-item-space {
  padding: 0 40px;
  margin: 0 0 80px
}

.qode-huge-space.qode-disable-item-bottom-space .qode-item-space {
  margin: 0
}

.qode-grid-row {
  margin-left: -15px;
  margin-right: -15px
}

.qode-grid-row:after {
  clear: both
}

.qode-grid-no-gutter {
  margin-left: 0;
  margin-right: 0
}

.qode-grid-no-gutter>div {
  padding-left: 0;
  padding-right: 0
}

.qode-grid-tiny-gutter {
  margin-left: -5px;
  margin-right: -5px
}

.qode-grid-tiny-gutter>div {
  padding-left: 5px;
  padding-right: 5px
}

.qode-grid-small-gutter {
  margin-left: -10px;
  margin-right: -10px
}

.qode-grid-small-gutter>div {
  padding-left: 10px;
  padding-right: 10px
}

.qode-grid-normal-gutter {
  margin-left: -15px;
  margin-right: -15px
}

.qode-grid-normal-gutter>div {
  padding-left: 15px;
  padding-right: 15px
}

.qode-grid-medium-gutter {
  margin-left: -20px;
  margin-right: -20px
}

.qode-grid-medium-gutter>div {
  padding-left: 20px;
  padding-right: 20px
}

.qode-grid-large-gutter {
  margin-left: -25px;
  margin-right: -25px
}

.qode-grid-large-gutter>div {
  padding-left: 25px;
  padding-right: 25px
}

.qode-grid-huge-gutter {
  margin-left: -40px;
  margin-right: -40px
}

.qode-grid-huge-gutter>div {
  padding-left: 40px;
  padding-right: 40px
}

.qode-grid-col-1,
.qode-grid-col-10,
.qode-grid-col-11,
.qode-grid-col-12,
.qode-grid-col-2,
.qode-grid-col-3,
.qode-grid-col-4,
.qode-grid-col-5,
.qode-grid-col-6,
.qode-grid-col-7,
.qode-grid-col-8,
.qode-grid-col-9 {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  min-height: 1px;
  box-sizing: border-box
}

.qode-grid-col-1 {
  float: left;
  width: 8.33333%
}

.qode-grid-col-1:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-1 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-2 {
  float: left;
  width: 16.66667%
}

.qode-grid-col-2:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-2 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-3 {
  float: left;
  width: 25%
}

.qode-grid-col-3:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-3 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-4 {
  float: left;
  width: 33.33333%
}

.qode-grid-col-4:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-4 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-5 {
  float: left;
  width: 41.66667%
}

.qode-grid-col-5:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-5 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-6 {
  float: left;
  width: 50%
}

.qode-grid-col-6:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-6 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-7 {
  float: left;
  width: 58.33333%
}

.qode-grid-col-7:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-7 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-8 {
  float: left;
  width: 66.66667%
}

.qode-grid-col-8:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-8 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-9 {
  float: left;
  width: 75%
}

.qode-grid-col-9:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-9 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-10 {
  float: left;
  width: 83.33333%
}

.qode-grid-col-10:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-10 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-11 {
  float: left;
  width: 91.66667%
}

.qode-grid-col-11:after {
  clear: both
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-11 {
    width: 100%;
    float: none
  }
}

.qode-grid-col-12 {
  float: left;
  width: 100%
}

.qode-grid-col-12:after {
  clear: both
}

.qode-grid-col-push-1 {
  left: 8.33333%
}

.qode-grid-col-pull-1 {
  right: 8.33333%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-12 {
    width: 100%;
    float: none
  }

  .qode-grid-col-push-1 {
    left: 0
  }

  .qode-grid-col-pull-1 {
    right: 0
  }
}

.qode-grid-col-push-2 {
  left: 16.66667%
}

.qode-grid-col-pull-2 {
  right: 16.66667%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-2 {
    left: 0
  }

  .qode-grid-col-pull-2 {
    right: 0
  }
}

.qode-grid-col-push-3 {
  left: 25%
}

.qode-grid-col-pull-3 {
  right: 25%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-3 {
    left: 0
  }

  .qode-grid-col-pull-3 {
    right: 0
  }
}

.qode-grid-col-push-4 {
  left: 33.33333%
}

.qode-grid-col-pull-4 {
  right: 33.33333%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-4 {
    left: 0
  }

  .qode-grid-col-pull-4 {
    right: 0
  }
}

.qode-grid-col-push-5 {
  left: 41.66667%
}

.qode-grid-col-pull-5 {
  right: 41.66667%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-5 {
    left: 0
  }

  .qode-grid-col-pull-5 {
    right: 0
  }
}

.qode-grid-col-push-6 {
  left: 50%
}

.qode-grid-col-pull-6 {
  right: 50%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-6 {
    left: 0
  }

  .qode-grid-col-pull-6 {
    right: 0
  }
}

.qode-grid-col-push-7 {
  left: 58.33333%
}

.qode-grid-col-pull-7 {
  right: 58.33333%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-7 {
    left: 0
  }

  .qode-grid-col-pull-7 {
    right: 0
  }
}

.qode-grid-col-push-8 {
  left: 66.66667%
}

.qode-grid-col-pull-8 {
  right: 66.66667%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-8 {
    left: 0
  }

  .qode-grid-col-pull-8 {
    right: 0
  }
}

.qode-grid-col-push-9 {
  left: 75%
}

.qode-grid-col-pull-9 {
  right: 75%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-9 {
    left: 0
  }

  .qode-grid-col-pull-9 {
    right: 0
  }
}

.qode-grid-col-push-10 {
  left: 83.33333%
}

.qode-grid-col-pull-10 {
  right: 83.33333%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-10 {
    left: 0
  }

  .qode-grid-col-pull-10 {
    right: 0
  }
}

.qode-grid-col-push-11 {
  left: 91.66667%
}

.qode-grid-col-pull-11 {
  right: 91.66667%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-11 {
    left: 0
  }

  .qode-grid-col-pull-11 {
    right: 0
  }
}

.qode-grid-col-push-12 {
  left: 100%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-push-12 {
    left: 0
  }
}

.qode-advanced-image-gallery .owl-nav .owl-prev,
.qode-owl-slider .owl-nav .owl-prev,
.qode-owl-slider-style .owl-nav .owl-prev {
  left: 30px
}

.qode-grid-col-pull-12 {
  right: 100%
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-pull-12 {
    right: 0
  }

  .qode-grid-col-ipad-landscape-1 {
    width: 8.33333%;
    float: left
  }
}

.qode-advanced-image-gallery .owl-nav .owl-next,
.qode-owl-slider .owl-nav .owl-next,
.qode-owl-slider-style .owl-nav .owl-next {
  right: 30px
}

@media only screen and (max-width: 1400px) {
  .qode-grid-col-laptop-landscape-large-1 {
    width: 8.33333%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-1 {
    width: 8.33333%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-1 {
    width: 8.33333%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-1 {
    width: 8.33333%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-1 {
    width: 8.33333%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-1 {
    width: 8.33333%;
    float: left
  }
}

@media only screen and (max-width: 320px) {
  .qode-grid-col-smaller-phone-portrait-1 {
    width: 8.33333%;
    float: left
  }

  .qode-grid-col-smaller-phone-portrait-2 {
    width: 16.66667%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-2 {
    width: 16.66667%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-2 {
    width: 16.66667%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-2 {
    width: 16.66667%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-2 {
    width: 16.66667%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-2 {
    width: 16.66667%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-2 {
    width: 16.66667%;
    float: left
  }
}

@media only screen and (max-width: 1400px) {
  .qode-grid-col-laptop-landscape-large-2 {
    width: 16.66667%;
    float: left
  }

  .qode-grid-col-laptop-landscape-large-3 {
    width: 25%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-3 {
    width: 25%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-3 {
    width: 25%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-3 {
    width: 25%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-3 {
    width: 25%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-3 {
    width: 25%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-3 {
    width: 25%;
    float: left
  }
}

@media only screen and (max-width: 320px) {
  .qode-grid-col-smaller-phone-portrait-3 {
    width: 25%;
    float: left
  }

  .qode-grid-col-smaller-phone-portrait-4 {
    width: 33.33333%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-4 {
    width: 33.33333%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-4 {
    width: 33.33333%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-4 {
    width: 33.33333%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-4 {
    width: 33.33333%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-4 {
    width: 33.33333%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-4 {
    width: 33.33333%;
    float: left
  }
}

@media only screen and (max-width: 1400px) {
  .qode-grid-col-laptop-landscape-large-4 {
    width: 33.33333%;
    float: left
  }

  .qode-grid-col-laptop-landscape-large-5 {
    width: 41.66667%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-5 {
    width: 41.66667%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-5 {
    width: 41.66667%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-5 {
    width: 41.66667%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-5 {
    width: 41.66667%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-5 {
    width: 41.66667%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-5 {
    width: 41.66667%;
    float: left
  }
}

@media only screen and (max-width: 320px) {
  .qode-grid-col-smaller-phone-portrait-5 {
    width: 41.66667%;
    float: left
  }

  .qode-grid-col-smaller-phone-portrait-6 {
    width: 50%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-6 {
    width: 50%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-6 {
    width: 50%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-6 {
    width: 50%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-6 {
    width: 50%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-6 {
    width: 50%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-6 {
    width: 50%;
    float: left
  }
}

@media only screen and (max-width: 1400px) {
  .qode-grid-col-laptop-landscape-large-6 {
    width: 50%;
    float: left
  }

  .qode-grid-col-laptop-landscape-large-7 {
    width: 58.33333%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-7 {
    width: 58.33333%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-7 {
    width: 58.33333%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-7 {
    width: 58.33333%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-7 {
    width: 58.33333%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-7 {
    width: 58.33333%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-7 {
    width: 58.33333%;
    float: left
  }
}

@media only screen and (max-width: 320px) {
  .qode-grid-col-smaller-phone-portrait-7 {
    width: 58.33333%;
    float: left
  }

  .qode-grid-col-smaller-phone-portrait-8 {
    width: 66.66667%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-8 {
    width: 66.66667%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-8 {
    width: 66.66667%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-8 {
    width: 66.66667%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-8 {
    width: 66.66667%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-8 {
    width: 66.66667%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-8 {
    width: 66.66667%;
    float: left
  }
}

@media only screen and (max-width: 1400px) {
  .qode-grid-col-laptop-landscape-large-8 {
    width: 66.66667%;
    float: left
  }

  .qode-grid-col-laptop-landscape-large-9 {
    width: 75%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-9 {
    width: 75%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-9 {
    width: 75%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-9 {
    width: 75%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-9 {
    width: 75%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-9 {
    width: 75%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-9 {
    width: 75%;
    float: left
  }
}

@media only screen and (max-width: 320px) {
  .qode-grid-col-smaller-phone-portrait-9 {
    width: 75%;
    float: left
  }

  .qode-grid-col-smaller-phone-portrait-10 {
    width: 83.33333%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-10 {
    width: 83.33333%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-10 {
    width: 83.33333%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-10 {
    width: 83.33333%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-10 {
    width: 83.33333%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-10 {
    width: 83.33333%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-10 {
    width: 83.33333%;
    float: left
  }
}

@media only screen and (max-width: 1400px) {
  .qode-grid-col-laptop-landscape-large-10 {
    width: 83.33333%;
    float: left
  }

  .qode-grid-col-laptop-landscape-large-11 {
    width: 91.66667%;
    float: left
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-11 {
    width: 91.66667%;
    float: left
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-11 {
    width: 91.66667%;
    float: left
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-11 {
    width: 91.66667%;
    float: left
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-11 {
    width: 91.66667%;
    float: left
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-11 {
    width: 91.66667%;
    float: left
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-11 {
    width: 91.66667%;
    float: left
  }
}

@media only screen and (max-width: 320px) {
  .qode-grid-col-smaller-phone-portrait-11 {
    width: 91.66667%;
    float: left
  }

  .qode-grid-col-smaller-phone-portrait-12 {
    width: 100%;
    float: none
  }
}

@media only screen and (max-width: 1400px) {
  .qode-grid-col-laptop-landscape-large-12 {
    width: 100%;
    float: none
  }
}

@media only screen and (max-width: 1280px) {
  .qode-grid-col-laptop-landscape-medium-12 {
    width: 100%;
    float: none
  }
}

@media only screen and (max-width: 1200px) {
  .qode-grid-col-laptop-landscape-12 {
    width: 100%;
    float: none
  }
}

@media only screen and (max-width: 1024px) {
  .qode-grid-col-ipad-landscape-12 {
    width: 100%;
    float: none
  }
}

@media only screen and (max-width: 768px) {
  .qode-grid-col-ipad-portrait-12 {
    width: 100%;
    float: none
  }
}

@media only screen and (max-width: 600px) {
  .qode-grid-col-phone-landscape-12 {
    width: 100%;
    float: none
  }
}

@media only screen and (max-width: 480px) {
  .qode-grid-col-phone-portrait-12 {
    width: 100%;
    float: none
  }
}

.qode-owl-slider,
.qode-owl-slider-style {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1
}

.qode-owl-slider-style.owl-loaded,
.qode-owl-slider.owl-loaded {
  display: block
}

.qode-owl-slider-style.owl-loading,
.qode-owl-slider.owl-loading {
  opacity: 0;
  display: block
}

.qode-owl-slider-style.owl-hidden,
.qode-owl-slider.owl-hidden {
  opacity: 0
}

.qode-owl-slider-style.owl-refresh .owl-item,
.qode-owl-slider.owl-refresh .owl-item {
  visibility: hidden
}

.qode-owl-slider-style.owl-drag .owl-item,
.qode-owl-slider.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.qode-owl-slider-style.owl-grab,
.qode-owl-slider.owl-grab {
  cursor: move;
  cursor: grab
}

.qode-owl-slider-style.owl-rtl,
.qode-owl-slider.owl-rtl {
  direction: rtl
}

.qode-owl-slider-style.owl-rtl .owl-item,
.qode-owl-slider.owl-rtl .owl-item {
  float: right
}

.no-js .qode-owl-slider,
.no-js .qode-owl-slider-style {
  display: block
}

.qode-owl-slider .owl-stage,
.qode-owl-slider-style .owl-stage {
  position: relative;
  touch-action: pan-Y
}

.qode-owl-slider .owl-stage:after,
.qode-owl-slider-style .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0
}

.qode-owl-slider .owl-stage-outer,
.qode-owl-slider-style .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0)
}

.qode-owl-slider .owl-item,
.qode-owl-slider .owl-wrapper,
.qode-owl-slider-style .owl-item,
.qode-owl-slider-style .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.qode-owl-slider .owl-item,
.qode-owl-slider-style .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none
}

.qode-owl-slider .owl-item img,
.qode-owl-slider-style .owl-item img {
  display: block;
  width: 100%
}

.qode-owl-slider .owl-item img .owl-lazy,
.qode-owl-slider-style .owl-item img .owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d
}

.qode-owl-slider .owl-item .owl-lazy,
.qode-owl-slider-style .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease
}

.qode-owl-slider .owl-nav.disabled,
.qode-owl-slider-style .owl-nav.disabled {
  display: none
}

.qode-owl-slider .owl-nav .owl-next,
.qode-owl-slider .owl-nav .owl-prev,
.qode-owl-slider-style .owl-nav .owl-next,
.qode-owl-slider-style .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  z-index: 3;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.qode-owl-slider .owl-nav .owl-next:hover .qode-next-icon,
.qode-owl-slider .owl-nav .owl-next:hover .qode-prev-icon,
.qode-owl-slider .owl-nav .owl-prev:hover .qode-next-icon,
.qode-owl-slider .owl-nav .owl-prev:hover .qode-prev-icon,
.qode-owl-slider-style .owl-nav .owl-next:hover .qode-next-icon,
.qode-owl-slider-style .owl-nav .owl-next:hover .qode-prev-icon,
.qode-owl-slider-style .owl-nav .owl-prev:hover .qode-next-icon,
.qode-owl-slider-style .owl-nav .owl-prev:hover .qode-prev-icon {
  color: #1abc9c
}

.qode-owl-slider .owl-nav .qode-next-icon,
.qode-owl-slider .owl-nav .qode-prev-icon,
.qode-owl-slider-style .owl-nav .qode-next-icon,
.qode-owl-slider-style .owl-nav .qode-prev-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #000;
  -webkit-transition: color .2s ease-out;
  -moz-transition: color .2s ease-out;
  transition: color .2s ease-out
}

.qode-owl-slider .owl-nav .qode-next-icon .qode-icon-arrow,
.qode-owl-slider .owl-nav .qode-prev-icon .qode-icon-arrow,
.qode-owl-slider-style .owl-nav .qode-next-icon .qode-icon-arrow,
.qode-owl-slider-style .owl-nav .qode-prev-icon .qode-icon-arrow {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 46px;
  line-height: 1em
}

.qode-owl-slider .owl-nav .qode-next-icon .qode-icon-arrow:before,
.qode-owl-slider .owl-nav .qode-prev-icon .qode-icon-arrow:before,
.qode-owl-slider-style .owl-nav .qode-next-icon .qode-icon-arrow:before,
.qode-owl-slider-style .owl-nav .qode-prev-icon .qode-icon-arrow:before {
  display: block;
  line-height: inherit
}

.qode-owl-slider .owl-dots,
.qode-owl-slider-style .owl-dots {
  text-align: center;
  margin: 16px 0 0
}

.qode-owl-slider .owl-dots.disabled,
.qode-owl-slider-style .owl-dots.disabled {
  display: none
}

.qode-owl-slider .owl-dots .owl-dot,
.qode-owl-slider-style .owl-dots .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: middle
}

.qode-owl-slider .owl-dots .owl-dot span,
.qode-owl-slider-style .owl-dots .owl-dot span {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 3em;
  background-color: transparent;
  border: 2px solid rgba(0, 0, 0, .5);
  -webkit-transition: background-color .2s ease-out, border .2s ease-out;
  -moz-transition: background-color .2s ease-out, border .2s ease-out;
  transition: background-color .2s ease-out, border .2s ease-out;
  box-sizing: border-box
}

.qode-owl-slider .owl-dots .owl-dot.active span,
.qode-owl-slider .owl-dots .owl-dot:hover span,
.qode-owl-slider-style .owl-dots .owl-dot.active span,
.qode-owl-slider-style .owl-dots .owl-dot:hover span {
  background-color: #000;
  border-color: #000
}

.qode-owl-slider .animated,
.qode-owl-slider-style .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.qode-owl-slider .owl-animated-in,
.qode-owl-slider-style .owl-animated-in {
  z-index: 0
}

.qode-comparison-slider.qode-comparison-slider-with-frame .twentytwenty-before,
.qode-owl-slider .owl-animated-out,
.qode-owl-slider-style .owl-animated-out {
  z-index: 1
}

.qode-owl-slider .fadeOut,
.qode-owl-slider-style .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut
}

.owl-height {
  transition: height .5s ease-in-out
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

.rangeslider,
.rangeslider__fill {
  display: block;
  -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .3);
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, .3);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, .3);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px
}

.rangeslider {
  background: #e6e6e6;
  position: relative
}

.rangeslider--horizontal {
  height: 20px;
  width: 100%
}

.rangeslider--vertical {
  width: 20px;
  min-height: 150px;
  max-height: 100%
}

.rangeslider--disabled {
  filter: alpha(Opacity=40);
  opacity: .4
}

.rangeslider__fill {
  background: #0f0;
  position: absolute
}

.rangeslider--horizontal .rangeslider__fill {
  top: 0;
  height: 100%
}

.rangeslider--vertical .rangeslider__fill {
  bottom: 0;
  width: 100%
}

.rangeslider__handle {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g) #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  width: 40px;
  height: 40px;
  position: absolute;
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, .1)));
  background-image: -moz-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, .1));
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, .1));
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, .1));
  -moz-box-shadow: 0 0 8px rgba(0, 0, 0, .3);
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .3);
  box-shadow: 0 0 8px rgba(0, 0, 0, .3);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%
}

.rangeslider__handle:after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, rgba(0, 0, 0, .13)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -moz-linear-gradient(rgba(0, 0, 0, .13), rgba(255, 255, 255, 0));
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, .13), rgba(255, 255, 255, 0));
  background-image: linear-gradient(rgba(0, 0, 0, .13), rgba(255, 255, 255, 0));
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%
}

.qode-comparison-slider.qode-comparison-slider-with-frame .twentytwenty-handle:after,
.qode-comparison-slider.qode-comparison-slider-with-frame .twentytwenty-handle:before,
.qode-listing-places-dist-holder .rangeslider__handle:after,
.swiper-button-lock,
.swiper-pagination-lock,
.swiper-scrollbar-lock,
.twentytwenty-after-label,
.twentytwenty-before-label,
.twentytwenty-horizontal .twentytwenty-handle:after,
.twentytwenty-horizontal .twentytwenty-handle:before,
.twentytwenty-vertical .twentytwenty-handle:after,
.twentytwenty-vertical .twentytwenty-handle:before {
  display: none
}

.rangeslider--active .rangeslider__handle,
.rangeslider__handle:active {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
  background-size: 100%;
  background-image: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, rgba(0, 0, 0, .1)), color-stop(100%, rgba(0, 0, 0, .12)));
  background-image: -moz-linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .12));
  background-image: -webkit-linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .12));
  background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .12))
}

.rangeslider--horizontal .rangeslider__handle {
  top: -10px;
  touch-action: pan-y;
  -ms-touch-action: pan-y
}

.rangeslider--vertical .rangeslider__handle {
  left: -10px;
  touch-action: pan-x;
  -ms-touch-action: pan-x
}

input[type=range]:focus+.rangeslider .rangeslider__handle {
  -moz-box-shadow: 0 0 8px rgba(255, 0, 255, .9);
  -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, .9);
  box-shadow: 0 0 8px rgba(255, 0, 255, .9)
}

.qode-listing-places-dist-holder .rangeslider--horizontal {
  height: 2px;
  border: none;
  background-color: #c4c4c4;
  box-shadow: none
}

.qode-listing-places-dist-holder .rangeslider__fill {
  background-color: #ff2d00
}

.qode-listing-places-dist-holder .rangeslider__handle {
  background-color: #ff2d00;
  width: 10px;
  height: 10px;
  background-image: none;
  border: none;
  box-shadow: none;
  top: -4px
}

.qode-listing-places-dist-holder .qode-rangle-slider-response-holder {
  margin: 0 0 12px
}

.qode-listing-places-dist-holder .qode-rangle-slider-response-holder span {
  font-weight: 500
}

.qode-listing-places-dist-holder .qode-listing-places-range {
  float: left;
  width: 50%;
  text-align: left;
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 11px
}

.qode-listing-places-dist-holder .qode-listing-places-range.qode-listing-places-max {
  text-align: right
}

.qode-comparison-slider {
  visibility: hidden;
  color: #fff
}

.qode-comparison-slider img {
  position: absolute
}

.qode-comparison-slider.active {
  cursor: -webkit-grab;
  cursor: grab
}

.qode-comparison-slider.qode-comparison-slider-with-frame {
  padding-bottom: 30px
}

.qode-comparison-slider.qode-comparison-slider-with-frame .qode-comparison-slider-frame-holder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.qode-comparison-slider.qode-comparison-slider-with-frame .qode-comparison-slider-frame-holder img {
  clip: initial !important
}

.qode-comparison-slider.qode-comparison-slider-dark {
  color: #000
}

.twentytwenty-horizontal .qode-comparison-slider.qode-comparison-slider-with-frame .twentytwenty-handle {
  margin-top: -67.5px
}

@media only screen and (max-width: 768px) {
  .twentytwenty-horizontal .qode-comparison-slider.qode-comparison-slider-with-frame .twentytwenty-handle {
    margin-top: -50px
  }
}

@media only screen and (max-width: 480px) {
  .twentytwenty-horizontal .qode-comparison-slider.qode-comparison-slider-with-frame .twentytwenty-handle {
    margin-top: -40px
  }
}

.qode-comparison-slider-with-frame.twentytwenty-container img {
  width: auto
}

.qode-comparison-slider-with-frame.twentytwenty-container .qode-comparison-slider-frame-holder img {
  width: 100%
}

.twentytwenty-container {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 0;
  overflow: hidden;
  position: relative;
  user-select: none;
  -moz-user-select: none
}

.twentytwenty-container img {
  max-width: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: block
}

.twentytwenty-container * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box
}

.twentytwenty-before {
  z-index: 20
}

.twentytwenty-after {
  z-index: 10
}

.twentytwenty-handle {
  height: 50px;
  width: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  z-index: 40;
  cursor: -webkit-grab;
  cursor: grab
}

.twentytwenty-vertical .twentytwenty-handle {
  height: 80px
}

.twentytwenty-vertical .twentytwenty-handle:before {
  left: 50%;
  margin-left: 43px
}

.twentytwenty-horizontal .twentytwenty-handle:before {
  bottom: 50%;
  margin-bottom: 43px
}

.twentytwenty-vertical .twentytwenty-handle:after {
  right: 50%;
  margin-right: 43px
}

.twentytwenty-horizontal .twentytwenty-handle:after {
  top: 50%;
  margin-top: 43px
}

.twentytwenty-down-arrow,
.twentytwenty-left-arrow,
.twentytwenty-right-arrow,
.twentytwenty-up-arrow {
  display: inline-block;
  height: 50px;
  width: 40px;
  line-height: 86px;
  font-size: 36px;
  text-align: center;
  position: relative
}

.twentytwenty-down-arrow,
.twentytwenty-up-arrow {
  display: block
}

.twentytwenty-horizontal .twentytwenty-down-arrow:before,
.twentytwenty-horizontal .twentytwenty-left-arrow:before,
.twentytwenty-horizontal .twentytwenty-right-arrow:before,
.twentytwenty-horizontal .twentytwenty-up-arrow:before,
.twentytwenty-vertical .twentytwenty-down-arrow:before,
.twentytwenty-vertical .twentytwenty-left-arrow:before,
.twentytwenty-vertical .twentytwenty-right-arrow:before,
.twentytwenty-vertical .twentytwenty-up-arrow:before {
  font-family: ElegantIcons;
  font-size: 44px;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  line-height: 50px
}

.twentytwenty-horizontal .twentytwenty-left-arrow:before {
  content: '\44';
  left: 7px
}

.twentytwenty-horizontal .twentytwenty-right-arrow:before {
  content: '\45';
  left: -11px
}

.twentytwenty-vertical .twentytwenty-down-arrow:before {
  content: '\42';
  top: 10px
}

.twentytwenty-vertical .twentytwenty-up-arrow:before {
  content: '\43';
  top: -8px
}

.twentytwenty-vertical .twentytwenty-down-arrow,
.twentytwenty-vertical .twentytwenty-up-arrow {
  height: 40px;
  width: 80px
}

.swiper-slide,
.swiper-wrapper {
  height: 100%;
  position: relative
}

.twentytwenty-vertical .twentytwenty-down-arrow:before,
.twentytwenty-vertical .twentytwenty-up-arrow:before {
  line-height: 40px
}

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1
}

.swiper-container-no-flexbox .swiper-slide {
  float: left
}

.swiper-container-vertical>.swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column
}

.swiper-wrapper {
  width: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.swiper-container-multirow>.swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.swiper-container-free-mode>.swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto
}

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  -webkit-transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform
}

.swiper-slide-invisible-blank {
  visibility: hidden
}

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto
}

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform
}

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px
}

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d
}

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10
}

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal>.swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y
}

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical>.swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x
}

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto;
  pointer-events: none
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto
}

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
}

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: .3s opacity;
  -o-transition: .3s opacity;
  transition: .3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(.33);
  -ms-transform: scale(.33);
  transform: scale(.33);
  position: relative
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active,
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(.66);
  -ms-transform: scale(.66);
  transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(.33);
  -ms-transform: scale(.33);
  transform: scale(.33)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(.66);
  -ms-transform: scale(.66);
  transform: scale(.66)
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(.33);
  -ms-transform: scale(.33);
  transform: scale(.33)
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: .2
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer
}

.swiper-scrollbar-cursor-drag,
.swiper-slide-zoomed {
  cursor: move
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff
}

.swiper-container-vertical>.swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0)
}

.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px
}

.swiper-container-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: .2s top, .2s -webkit-transform;
  -o-transition: .2s transform, .2s top;
  transition: .2s transform, .2s top;
  transition: .2s transform, .2s top, .2s -webkit-transform
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap
}

.swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: .2s left, .2s -webkit-transform;
  -o-transition: .2s transform, .2s left;
  transition: .2s transform, .2s left;
  transition: .2s transform, .2s left, .2s -webkit-transform
}

.swiper-container-horizontal.swiper-container-rtl>.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: .2s right, .2s -webkit-transform;
  -o-transition: .2s transform, .2s right;
  transition: .2s transform, .2s right;
  transition: .2s transform, .2s right, .2s -webkit-transform
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, .25);
  position: absolute
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top
}

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top
}

.swiper-container-horizontal>.swiper-pagination-progressbar,
.swiper-container-vertical>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0
}

.swiper-container-horizontal>.swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical>.swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0
}

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff
}

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, .25)
}

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #fff
}

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000
}

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, .25)
}

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000
}

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  touch-action: none;
  background: rgba(0, 0, 0, .1)
}

.swiper-container-horizontal>.swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%
}

.swiper-container-vertical>.swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, .5);
  border-radius: 10px;
  left: 0;
  top: 0
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center
}

.swiper-zoom-container>canvas,
.swiper-zoom-container>img,
.swiper-zoom-container>svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain
}

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite
}

.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat
}

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E")
}

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out
}

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity
}

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}

.swiper-container-cube {
  overflow: visible
}

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%
}

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next+.swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible
}

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0
}

.swiper-container-flip {
  overflow: visible
}

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1
}

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none
}

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto
}

.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.swiper-container-coverflow .swiper-wrapper {
  perspective: 1200px
}

.qode-icon-holder {
  display: inline-block;
  vertical-align: middle
}

.qode-icon-holder.qode-icon-circle,
.qode-icon-holder.qode-icon-square {
  background-color: #1abc9c;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  -webkit-transition: background-color .8s cubic-bezier(.19, .65, .02, .93), border-color .8s cubic-bezier(.19, .65, .02, .93);
  -moz-transition: background-color .8s cubic-bezier(.19, .65, .02, .93), border-color .8s cubic-bezier(.19, .65, .02, .93);
  transition: background-color .8s cubic-bezier(.19, .65, .02, .93), border-color .8s cubic-bezier(.19, .65, .02, .93)
}

.qode-icon-holder.qode-icon-circle a,
.qode-icon-holder.qode-icon-square a {
  display: inline-block;
  width: 100%;
  height: 100%
}

.qode-icon-holder.qode-icon-circle {
  border-radius: 50%
}

.qode-icon-holder.qode-icon-tiny {
  font-size: 1.33333333em;
  line-height: .75em;
  vertical-align: -15%
}

.qode-icon-holder.qode-icon-small {
  font-size: 2em
}

.qode-icon-holder.qode-icon-medium {
  font-size: 3em
}

.qode-icon-holder.qode-icon-large {
  font-size: 4em
}

.qode-icon-holder.qode-icon-huge {
  font-size: 5em
}

.qode-icon-holder .qode-icon-element {
  line-height: inherit;
  color: #fff;
  -webkit-transition: color .8s cubic-bezier(.19, .65, .02, .93);
  -moz-transition: color .8s cubic-bezier(.19, .65, .02, .93);
  transition: color .8s cubic-bezier(.19, .65, .02, .93)
}

.qode-icon-holder .qode-icon-element:before {
  line-height: inherit
}

.vertical_menu_enabled .content .container,
.vertical_menu_enabled .full_width,
.vertical_menu_enabled .q_slider,
.vertical_menu_enabled .title_outer,
.vertical_menu_enabled footer {
  padding-left: 260px
}

.boxed.vertical_menu_enabled .content .container,
.boxed.vertical_menu_enabled .full_width,
.boxed.vertical_menu_enabled .q_slider,
.boxed.vertical_menu_enabled .title_outer,
.boxed.vertical_menu_enabled footer {
  padding-left: 0
}

.boxed.vertical_menu_enabled,
.boxed.vertical_menu_enabled footer.uncover {
  padding-left: 260px;
}

.vertical_menu_enabled .content .title .container {
  padding-left: 0;
}

.vertical_menu_enabled .content .container,
.vertical_menu_enabled .q_slider,
.vertical_menu_enabled footer {
  width: auto;
}

aside.vertical_menu_area {
  position: fixed;
  width: 300px;
  top: 0;
  left: 0;
  padding: 60px 50px;
  background-color: #fff;
  height: 100%;
  z-index: 101;
  backface-visibility: hidden;
  box-sizing: border-box;
  -webkit-transition: background-color .3s ease;
  -moz-transition: background-color .3s ease;
  -o-transition: background-color .3s ease;
  -ms-transition: background-color .3s ease;
  transition: background-color .3s ease;
  outline: 0;
}

aside.vertical_menu_area.with_scroll {
  overflow: hidden;
}

aside.vertical_menu_area .vertical_menu_area_widget_holder {
  font-size: 20px;
  position: fixed;
  bottom: 0;
  padding-bottom: 30px;
  text-align: center;
  left: 110px;
}

.content {
  padding-left: 0;
}

.vertical_menu_transparency_on aside.vertical_menu_area.light .q_social_icon_holder i.simple_social,
.vertical_menu_transparency_on aside.vertical_menu_area.light .q_social_icon_holder span.simple_social,
.vertical_menu_transparency_on aside.vertical_menu_area.light .vertical_menu_area_widget_holder,
.vertical_menu_transparency_on aside.vertical_menu_area.light .widget a {
  color: #fff;
}

.vertical_menu_transparency_on aside.vertical_menu_area.dark .q_social_icon_holder i.simple_social,
.vertical_menu_transparency_on aside.vertical_menu_area.dark .q_social_icon_holder span.simple_social,
.vertical_menu_transparency_on aside.vertical_menu_area.dark .vertical_menu_area_widget_holder,
.vertical_menu_transparency_on aside.vertical_menu_area.dark .widget a {
  color: #000;
}

header {
  display: block;
}

header .header_inner {
  display: none;
}

.vertical_area_background {
  position: fixed;
  width: 260px;
  height: 100%;
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 0;
  -webkit-transition: opacity .6s ease;
  -moz-transition: opacity .6s ease;
  -o-transition: opacity .6s ease;
  -ms-transition: opacity .6s ease;
  transition: opacity .6s ease;
  opacity: 1;
}

.vertical_logo_wrapper {
  z-index: 100;
  position: relative;
}

.vertical_logo_wrapper img {
  max-width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  -ms-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

.q_logo_vertical img.normal {
  position: relative;
}

.q_logo_vertical img.dark,
.q_logo_vertical img.light,
.vertical_menu_transparency_on .light .q_logo_vertical img.normal {
  opacity: 0;
}

.vertical_menu_transparency_on .light .q_logo_vertical img.light {
  opacity: 1;
}

.vertical_menu_transparency_on .dark .q_logo_vertical img.light,
.vertical_menu_transparency_on .dark .q_logo_vertical img.normal,
.vertical_menu_transparency_on .light .q_logo_vertical img.dark {
  opacity: 0
}

.vertical_menu_transparency_on .dark .q_logo_vertical img.dark {
  opacity: 1
}

nav.vertical_menu {
  position: relative;
  z-index: 101;
  margin: 45px 0 0
}

.vertical_menu ul {
  list-style: none
}

.vertical_menu ul li {
  position: relative
}

/* nav.vertical_menu>ul>li>a {
  color: #303030;
  font-family: josefin sans,sans-serif;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  position: relative;
  line-height: 34px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  -webkit-transition: color .3s ease;
  -moz-transition: color .3s ease;
  -o-transition: color .3s ease;
  -ms-transition: color .3s ease;
  transition: color .3s ease
} */

.vertical_menu_transparency_on .light nav.vertical_menu>ul>li>a {
  color: #fff
}

.vertical_menu_transparency_on .dark nav.vertical_menu>ul>li>a {
  color: #000
}

.vertical_menu .second .inner {
  position: relative;
  display: block;
  padding: 0;
  z-index: 997
}

.vertical_menu_float .second .inner_arrow,
.vertical_menu_on_click .second .inner_arrow,
.vertical_menu_toggle .second .inner_arrow {
  display: none
}

.vertical_menu .second .inner>ul>li>a:hover {
  color: #303030
}

.vertical_menu_on_click .second,
.vertical_menu_toggle .second {
  margin: 0;
  display: none;
  overflow: hidden;
  z-index: 10
}

.vertical_menu_on_click .second ul ul,
.vertical_menu_toggle .second ul ul {
  display: none
}

nav.vertical_menu_on_click ul li a,
nav.vertical_menu_toggle ul li a {
  display: block
}

nav.vertical_menu_float ul li a .q_menu_arrow,
nav.vertical_menu_on_click li.narrow .second .inner ul ul,
nav.vertical_menu_on_click ul li a .line,
nav.vertical_menu_on_click ul li a .q_menu_arrow,
nav.vertical_menu_toggle li.narrow .second .inner ul ul,
nav.vertical_menu_toggle ul li a .line,
nav.vertical_menu_toggle ul li a .q_menu_arrow {
  display: none
}

nav.vertical_menu_float ul>li.menu-item-has-children>a>.plus,
nav.vertical_menu_on_click ul>li.menu-item-has-children>a>.plus,
nav.vertical_menu_toggle ul>li.menu-item-has-children>a>.plus {
  float: right;
  width: 20px;
  height: 8px;
  margin: 12px 0 0;
  z-index: 10000;
  display: block;
  position: relative;
  /* background-image: url(img/vertical_menu_cross.png); */
  background-repeat: no-repeat;
  background-position: 100%;
  -o-background-size: 8px 8px;
  -webkit-background-size: 8px 8px;
  -moz-background-size: 8px 8px;
  background-size: 8px 8px
}

nav.vertical_menu_float ul li ul>li.menu-item-has-children>a>.plus,
nav.vertical_menu_on_click ul li ul>li.menu-item-has-children>a>.plus,
nav.vertical_menu_toggle ul li ul>li.menu-item-has-children>a>.plus {
  margin: 5px 0 0
}

.no-touchevents nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
.no-touchevents nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
.no-touchevents nav.vertical_menu_toggle ul>li.menu-item-has-children>a:hover>.plus,
.touchevents nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
.touchevents nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
.touchevents nav.vertical_menu_toggle ul>li.menu-item-has-children.open>a>.plus {
  /* background-image: url(img/vertical_menu_minus.png) */
}

nav.vertical_menu_float ul li.menu-item-has-children a span,
nav.vertical_menu_on_click ul li.menu-item-has-children a span,
nav.vertical_menu_toggle ul li.menu-item-has-children a span {
  display: inline-block;
  max-width: 180px;
  line-height: 1em
}

.vertical_menu_float .menu-item .second {
  position: absolute;
  top: 0;
  left: calc(100% + 30px);
  width: 100%;
  margin-left: 50px;
  opacity: 0;
  background-color: #fff;
  visibility: hidden;
  transition: all .3s ease-in-out
}

.vertical_menu_float .menu-item .second * {
  visibility: hidden;
  transition: visibility .3s ease-in-out
}

.vertical_menu_float .menu-item .second.vertical_menu_start {
  margin-left: 0;
  opacity: 1;
  visibility: visible
}

.vertical_menu_float .menu-item .second.vertical_menu_start * {
  visibility: visible
}

.vertical_menu_float .menu-item .second.vertical_menu_start ul li ul,
.vertical_menu_float .menu-item .second.vertical_menu_start ul li ul * {
  visibility: hidden
}

.vertical_menu_float .menu-item .second.vertical_menu_start ul li ul.vertical_submenu_start,
.vertical_menu_float .menu-item .second.vertical_menu_start ul li ul.vertical_submenu_start * {
  visibility: visible
}

.vertical_menu_float .second .inner ul {
  width: 100%
}

.vertical_menu_float li.narrow .second .inner ul {
  border: none;
  background-color: #fff;
  padding: 0
}

.vertical_menu_float .second .inner ul li ul {
  position: absolute;
  border: none;
  top: 0;
  left: 100%;
  margin-left: 50px;
  height: auto;
  visibility: hidden;
  width: 100%;
  opacity: 0;
  overflow: hidden;
  z-index: 10;
  background-color: #fff;
  padding: 0;
  transition: all .3s ease-in-out
}

.vertical_menu_float .second .inner ul li ul.vertical_submenu_start {
  opacity: 1;
  margin-left: 0;
  visibility: visible
}

.vertical_menu_enabled.vertical_menu_transparency_on:not(.vertical_menu_hidden) aside.vertical_menu_area .vertical_menu_float .menu-item .second,
.vertical_menu_enabled.vertical_menu_transparency_on:not(.vertical_menu_hidden) aside.vertical_menu_area .vertical_menu_float .second .inner ul li ul {
  background-color: transparent !important
}

.vertical_menu_transparency_on .light nav.vertical_menu_float ul>li.menu-item-has-children>a>.plus,
.vertical_menu_transparency_on .light nav.vertical_menu_on_click ul>li.menu-item-has-children>a>.plus,
.vertical_menu_transparency_on .light nav.vertical_menu_toggle ul>li.menu-item-has-children>a>.plus {
  /* background-image: url(img/vertical_menu_cross_white.png) */
}

.vertical_menu_transparency_on .light nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
.vertical_menu_transparency_on .light nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
.vertical_menu_transparency_on .light nav.vertical_menu_toggle ul>li.menu-item-has-children>a:hover>.plus {
  /* background-image: url(img/vertical_menu_minus_white.png) */
}

.vertical_menu_transparency_on .dark nav.vertical_menu_float ul>li.menu-item-has-children>a>.plus,
.vertical_menu_transparency_on .dark nav.vertical_menu_on_click ul>li.menu-item-has-children>a>.plus,
.vertical_menu_transparency_on .dark nav.vertical_menu_toggle ul>li.menu-item-has-children>a>.plus {
  /* background-image: url(img/vertical_menu_cross_black.png) */
}

.vertical_menu_transparency_on .dark nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
.vertical_menu_transparency_on .dark nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
.vertical_menu_transparency_on .dark nav.vertical_menu_toggle ul>li.menu-item-has-children>a:hover>.plus {
  /* background-image: url(img/vertical_menu_minus_black.png) */
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio:1.5),
only screen and (-o-min-device-pixel-ratio:150 / 100),
only screen and (min-device-pixel-ratio:1.5),
only screen and (min-resolution:160dpi) {

  nav.vertical_menu_float ul li.menu-item-has-children a .plus,
  nav.vertical_menu_on_click ul li.menu-item-has-children a .plus,
  nav.vertical_menu_toggle ul li.menu-item-has-children a .plus {
    /* background-image:url(img/vertical_menu_cross@1_5x.png) */
  }

  .no-touchevents nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
  .no-touchevents nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
  .no-touchevents nav.vertical_menu_toggle ul>li.menu-item-has-children>a:hover>.plus,
  .touchevents nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
  .touchevents nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
  .touchevents nav.vertical_menu_toggle ul>li.menu-item-has-children.open>a>.plus {
    /* background-image: url(img/vertical_menu_minus@1_5x.png) */
  }

  .vertical_menu_transparency_on .light nav.vertical_menu_float ul li.menu-item-has-children a .plus,
  .vertical_menu_transparency_on .light nav.vertical_menu_on_click ul li.menu-item-has-children a .plus,
  .vertical_menu_transparency_on .light nav.vertical_menu_toggle ul li.menu-item-has-children a .plus {
    /* background-image: url(img/vertical_menu_cross_white@1_5x.png) */
  }

  .vertical_menu_transparency_on .light nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
  .vertical_menu_transparency_on .light nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
  .vertical_menu_transparency_on .light nav.vertical_menu_toggle ul>li.menu-item-has-children>a:hover>.plus {
    /* background-image: url(img/vertical_menu_minus_white@1_5x.png) */
  }

  .vertical_menu_transparency_on .dark nav.vertical_menu_float ul li.menu-item-has-children a .plus,
  .vertical_menu_transparency_on .dark nav.vertical_menu_on_click ul li.menu-item-has-children a .plus,
  .vertical_menu_transparency_on .dark nav.vertical_menu_toggle ul li.menu-item-has-children a .plus {
    /* background-image: url(img/vertical_menu_cross_black@1_5x.png) */
  }

  .vertical_menu_transparency_on .dark nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
  .vertical_menu_transparency_on .dark nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
  .vertical_menu_transparency_on .dark nav.vertical_menu_toggle ul>li.menu-item-has-children>a:hover>.plus {
    /* background-image: url(img/vertical_menu_minus_black@1_5x.png) */
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio:2),
only screen and (-o-min-device-pixel-ratio:200 / 100),
only screen and (min-device-pixel-ratio:2),
only screen and (min-resolution:210dpi) {

  nav.vertical_menu_float ul li.menu-item-has-children a .plus,
  nav.vertical_menu_on_click ul li.menu-item-has-children a .plus,
  nav.vertical_menu_toggle ul li.menu-item-has-children a .plus {
    /* background-image:url(img/vertical_menu_cross@2x.png) */
  }

  .no-touchevents nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
  .no-touchevents nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
  .no-touchevents nav.vertical_menu_toggle ul>li.menu-item-has-children>a:hover>.plus,
  .touchevents nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
  .touchevents nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
  .touchevents nav.vertical_menu_toggle ul>li.menu-item-has-children.open>a>.plus {
    /* background-image: url(img/vertical_menu_minus@2x.png) */
  }

  .vertical_menu_transparency_on .light nav.vertical_menu_float ul li.menu-item-has-children a .plus,
  .vertical_menu_transparency_on .light nav.vertical_menu_on_click ul li.menu-item-has-children a .plus,
  .vertical_menu_transparency_on .light nav.vertical_menu_toggle ul li.menu-item-has-children a .plus {
    /* background-image: url(img/vertical_menu_cross_white@2x.png) */
  }

  .vertical_menu_transparency_on .light nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
  .vertical_menu_transparency_on .light nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
  .vertical_menu_transparency_on .light nav.vertical_menu_toggle ul>li.menu-item-has-children>a:hover>.plus {
    /* background-image: url(img/vertical_menu_minus_white@2x.png) */
  }

  .vertical_menu_transparency_on .dark nav.vertical_menu_float ul li.menu-item-has-children a .plus,
  .vertical_menu_transparency_on .dark nav.vertical_menu_on_click ul li.menu-item-has-children a .plus,
  .vertical_menu_transparency_on .dark nav.vertical_menu_toggle ul li.menu-item-has-children a .plus {
    /* background-image: url(img/vertical_menu_cross_black@2x.png) */
  }

  .vertical_menu_transparency_on .dark nav.vertical_menu_float ul>li.menu-item-has-children.open>a>.plus,
  .vertical_menu_transparency_on .dark nav.vertical_menu_on_click ul>li.menu-item-has-children.open>a>.plus,
  .vertical_menu_transparency_on .dark nav.vertical_menu_toggle ul>li.menu-item-has-children>a:hover>.plus {
    /* background-image: url(img/vertical_menu_minus_black@2x.png) */
  }
}

nav.vertical_menu_on_click li.narrow .second .inner ul,
nav.vertical_menu_toggle li.narrow .second .inner ul {
  background-color: transparent;
  padding: 0;
  width: 100%
}

.vertical_menu_float .second .inner ul li a,
.vertical_menu_on_click .second .inner ul li a,
.vertical_menu_toggle .second .inner ul li a {
  display: block;
  height: auto;
  font-family: inherit;
  font-size: 13px;
  line-height: 18px;
  color: #818181;
  margin: 0;
  padding: 5px 0;
  text-decoration: none;
  -webkit-transition: color .3s ease;
  -moz-transition: color .3s ease;
  -o-transition: color .3s ease;
  -ms-transition: color .3s ease;
  transition: color .3s ease
}

.vertical_menu_transparency_on .light .vertical_menu_float .second .inner ul li a,
.vertical_menu_transparency_on .light .vertical_menu_on_click .second .inner ul li a,
.vertical_menu_transparency_on .light .vertical_menu_toggle .second .inner ul li a {
  color: #fff
}

.vertical_menu_transparency_on .dark .vertical_menu_float .second .inner ul li a,
.vertical_menu_transparency_on .dark .vertical_menu_on_click .second .inner ul li a,
.vertical_menu_transparency_on .dark .vertical_menu_toggle .second .inner ul li a {
  color: #000
}

.vertical_menu_float .second .inner ul li a,
.vertical_menu_on_click .second .inner ul ul li a,
.vertical_menu_toggle .second .inner ul ul li a {
  padding-left: 15px;
  padding-right: 15px;
}

.vertical_menu_area_widget_holder {
  margin: 60px 0 0;
}

.vertical_menu_enabled .carousel-inner:not(.relative_position) {
  left: 260px !important;
}

.vertical_menu_area .q_social_icon_holder {
  margin: 0 8px 2px 0 !important;
}

.vertical_menu_area .q_social_icon_holder:nth-child(2n) {
  margin: 0 21px 0 21px !important;
}

.vertical_menu_enabled .content {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.vertical_menu_area .vertical_menu_area_widget_holder .normal_social:hover .simple_social {
  color: #b26b53 !important;
}

.boxed.vertical_menu_enabled .carousel-inner {
  width: 1150px !important;
  left: auto !important;
}

.vertical_menu_enabled.vertical_menu_transparency .full_section_inner {
  overflow: hidden
}

.vertical_menu_enabled.vertical_menu_transparency_on:not(.vertical_menu_hidden) aside.vertical_menu_area {
  background-color: transparent !important
}

.vertical_menu_enabled.vertical_menu_transparency_on:not(.vertical_menu_hidden) aside.vertical_menu_area .vertical_area_background {
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  -o-transition: opacity .3s ease;
  -ms-transition: opacity .3s ease;
  transition: opacity .3s ease;
  opacity: 0 !important
}

.vertical_menu_enabled.vertical_menu_transparency .q_slider {
  padding-left: 0
}

.vertical_menu_enabled.vertical_menu_enabled.vertical_menu_transparency .carousel-inner:not(.relative_position) {
  left: 0 !important
}

.boxed.vertical_menu_enabled.vertical_menu_enabled.vertical_menu_transparency .carousel-inner:not(.relative_position) {
  left: auto !important
}

.vertical_menu_enabled.vertical_menu_transparency .carousel-control.left {
  bottom: 60px;
  height: 44px;
  right: 105px;
  top: auto;
  width: 44px;
  opacity: 1 !important
}

.vertical_menu_enabled.vertical_menu_transparency .carousel-control.right {
  bottom: 60px;
  height: 44px;
  right: 50px;
  top: auto;
  width: 44px;
  opacity: 1 !important
}

.vertical_menu_enabled.vertical_menu_transparency .carousel-control .prev_nav {
  left: 0;
  margin: 0 !important;
  top: 0;
  height: 40px;
  width: 40px;
  line-height: 40px
}

.vertical_menu_enabled.vertical_menu_transparency .carousel-control .next_nav {
  margin: 0 !important;
  right: 0;
  top: 0;
  height: 40px;
  width: 40px;
  line-height: 40px
}

.vertical_menu_enabled.vertical_menu_transparency .carousel-control i {
  font-size: 24px;
  line-height: 40px
}

.vertical_menu_enabled.vertical_menu_transparency .carousel-indicators {
  display: none !important
}

.boxed.vertical_menu_enabled.page-template-landing_page-php,
.vertical_menu_enabled.page-template-landing_page-php .content .container,
.vertical_menu_enabled.page-template-landing_page-php .full_screen_holder,
.vertical_menu_enabled.page-template-landing_page-php .full_width,
.vertical_menu_enabled.page-template-landing_page-php .q_slider,
.vertical_menu_enabled.page-template-landing_page-php .title_outer {
  padding-left: 0 !important
}

.vertical_menu_enabled.page-template-landing_page-php .carousel-inner:not(.relative_position) {
  left: 0 !important
}

.vertical_menu_hidden aside.vertical_menu_area {
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, .15);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, .15);
  left: -220px;
  padding: 10px 40px;
  -webkit-transition: left .15s ease-out;
  -moz-transition: left .15s ease-out;
  -o-transition: left .15s ease-out;
  transform: left .15s ease-out;
  transition: left .15s ease-out
}

.vertical_menu_hidden .vertical_area_background {
  left: -220px;
  -webkit-transition: left .15s ease-out;
  -moz-transition: left .15s ease-out;
  -o-transition: left .15s ease-out;
  transform: left .15s ease-out;
  transition: left .15s ease-out
}

.vertical_menu_hidden.vertical_menu_hidden_with_logo .vertical_area_background,
.vertical_menu_hidden.vertical_menu_hidden_with_logo aside.vertical_menu_area {
  left: -190px
}

.vertical_menu_hidden aside.vertical_menu_area.active,
.vertical_menu_hidden aside.vertical_menu_area.active .vertical_area_background {
  left: 0
}

.boxed.vertical_menu_enabled.vertical_menu_hidden,
.boxed.vertical_menu_enabled.vertical_menu_hidden footer.uncover,
.vertical_menu_enabled.vertical_menu_hidden:not(.boxed) .content .container,
.vertical_menu_enabled.vertical_menu_hidden:not(.boxed) .full_screen_holder,
.vertical_menu_enabled.vertical_menu_hidden:not(.boxed) .full_width,
.vertical_menu_enabled.vertical_menu_hidden:not(.boxed) .q_slider,
.vertical_menu_enabled.vertical_menu_hidden:not(.boxed) .title_outer,
.vertical_menu_enabled.vertical_menu_hidden:not(.boxed) footer,
body.page-template-blog-masonry-full-width-php.vertical_menu_enabled.vertical_menu_hidden:not(.boxed) .content .full_width {
  padding-left: 40px
}

.vertical_menu_enabled.vertical_menu_hidden:not(.boxed) .carousel-inner:not(.relative_position) {
  left: 40px !important
}

.boxed.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo,
.boxed.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo footer.uncover,
.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo:not(.boxed) .content .container,
.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo:not(.boxed) .full_screen_holder,
.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo:not(.boxed) .full_width,
.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo:not(.boxed) .q_slider,
.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo:not(.boxed) .title_outer,
.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo:not(.boxed) footer {
  padding-left: 70px
}

.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo:not(.boxed) .carousel-inner:not(.relative_position) {
  left: 70px !important
}

.vertical_menu_enabled.vertical_menu_hidden .carousel-control .next_nav,
.vertical_menu_enabled.vertical_menu_hidden .carousel-control .prev_nav {
  margin-top: -27px !important
}

.vertical_menu_hidden_button {
  position: fixed;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 11;
  display: block
}

.vertical_menu_enabled.vertical_menu_hidden.vertical_menu_hidden_with_logo .vertical_menu_hidden_button {
  width: 70px;
  height: 40px;
  z-index: 1000
}

.vertical_menu_hidden_button_line {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 30px;
  display: block;
  margin-left: -11px;
  width: 22px;
  height: 3px;
  background: #303030
}

.vertical_menu_hidden_button_line:after,
.vertical_menu_hidden_button_line:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: #303030;
  left: 0;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center
}

.vertical_menu_hidden_button_line:before {
  top: -6px
}

.vertical_menu_hidden_button_line:after {
  bottom: -6px
}

.vertical_menu_area.active .vertical_menu_hidden_button .vertical_menu_hidden_button_line {
  background: rgba(220, 151, 31, 0)
}

.vertical_menu_area.active .vertical_menu_hidden_button .vertical_menu_hidden_button_line:after {
  -webkit-transform: rotate(-45deg) translate(2px, -8px);
  -moz-transform: rotate(-45deg) translate(3px, -8px);
  -ms-transform: rotate(-45deg) translate(3px, -8px);
  -o-transform: rotate(-45deg) translate(3px, -8px);
  transform: rotate(-45deg) translate(3px, -8px)
}

.vertical_menu_area.active .vertical_menu_hidden_button .vertical_menu_hidden_button_line:before {
  -webkit-transform: rotate(45deg) translate(4px, 9px);
  -moz-transform: rotate(45deg) translate(3px, 8px);
  -ms-transform: rotate(45deg) translate(3px, 8px);
  -o-transform: rotate(45deg) translate(3px, 8px);
  transform: rotate(45deg) translate(3px, 8px);
  top: -8px
}

.vertical_menu_hidden_button_line,
.vertical_menu_hidden_button_line:after,
.vertical_menu_hidden_button_line:before {
  -webkit-transition: all .3s cubic-bezier(.585, -.6, .43, 1.65);
  -moz-transition: all .3s cubic-bezier(.585, -.6, .43, 1.65);
  -ms-transition: all .3s cubic-bezier(.585, -.6, .43, 1.65);
  -o-transition: all .3s cubic-bezier(.585, -.6, .43, 1.65);
  transition: all .3s cubic-bezier(.585, -.6, .43, 1.65)
}

.vertical_menu_hidden_button:hover .vertical_menu_hidden_button_line:before {
  top: -8px
}

.vertical_menu_area.active .vertical_menu_hidden_button .vertical_menu_hidden_button_line:after,
.vertical_menu_hidden_button:hover .vertical_menu_hidden_button_line:after {
  bottom: -8px
}

.vertical_menu_hidden .vertical_menu_area_bottom_logo {
  position: fixed;
  width: 70px;
  text-align: center;
  bottom: 0;
  z-index: 1000;
  left: 0;
  -webkit-transition: left .15s ease-in-out;
  -moz-transition: left .15s ease-in-out;
  -ms-transition: left .15s ease-in-out;
  -o-transition: left .15s ease-in-out;
  transition: left .15s ease-in-out
}

.vertical_menu_hidden .vertical_menu_area_bottom_logo_inner {
  position: absolute;
  bottom: 20px;
  box-sizing: border-box;
  text-align: center;
  width: 100%
}

.vertical_menu_hidden .vertical_menu_area_bottom_logo_inner a {
  display: block;
  padding: 0 10px
}

.vertical_menu_hidden .vertical_menu_area_bottom_logo.active {
  left: -70px
}

.vertical_menu_hidden.vertical_menu_hidden_with_logo .vertical_menu_area:not(.active) .vertical_menu_area_inner {
  left: -30px
}

.vertical_menu_hidden.vertical_menu_hidden_with_logo .vertical_menu_area .vertical_menu_area_inner {
  left: 0;
  position: relative;
  height: 100%
}

.vertical_menu_hidden.vertical_menu_width_290 aside.vertical_menu_area,
.vertical_menu_hidden.vertical_menu_width_290 aside.vertical_menu_area .vertical_area_background {
  width: 290px;
  left: -250px
}

.vertical_menu_hidden.vertical_menu_width_350 aside.vertical_menu_area,
.vertical_menu_hidden.vertical_menu_width_350 aside.vertical_menu_area .vertical_area_background {
  width: 350px;
  left: -310px
}

.vertical_menu_hidden.vertical_menu_width_400 aside.vertical_menu_area,
.vertical_menu_hidden.vertical_menu_width_400 aside.vertical_menu_area .vertical_area_background {
  width: 400px;
  left: -360px
}

.vertical_menu_hidden.vertical_menu_width_290 aside.vertical_menu_area.active,
.vertical_menu_hidden.vertical_menu_width_290 aside.vertical_menu_area.active .vertical_area_background,
.vertical_menu_hidden.vertical_menu_width_350 aside.vertical_menu_area.active,
.vertical_menu_hidden.vertical_menu_width_350 aside.vertical_menu_area.active .vertical_area_background,
.vertical_menu_hidden.vertical_menu_width_400 aside.vertical_menu_area.active,
.vertical_menu_hidden.vertical_menu_width_400 aside.vertical_menu_area.active .vertical_area_background {
  left: 0
}

.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_290 aside.vertical_menu_area,
.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_290 aside.vertical_menu_area .vertical_area_background {
  width: 290px;
  left: -220px
}

.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_350 aside.vertical_menu_area,
.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_350 aside.vertical_menu_area .vertical_area_background {
  width: 350px;
  left: -280px
}

.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_400 aside.vertical_menu_area,
.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_400 aside.vertical_menu_area .vertical_area_background {
  width: 400px;
  left: -330px
}

.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_290 aside.vertical_menu_area.active,
.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_290 aside.vertical_menu_area.active .vertical_area_background,
.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_350 aside.vertical_menu_area.active,
.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_350 aside.vertical_menu_area.active .vertical_area_background,
.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_400 aside.vertical_menu_area.active,
.vertical_menu_hidden.vertical_menu_hidden_with_logo.vertical_menu_width_400 aside.vertical_menu_area.active .vertical_area_background {
  left: 0
}

.vertical_menu_area.vertically_center_content .vertical_menu_area_inner {
  height: 100%;
  position: relative;
  width: 100%
}

.vertical_menu_area.vertically_center_content nav.vertical_menu {
  box-sizing: border-box;
  display: table;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 100px 0 150px;
  position: absolute;
  text-align: inherit;
  top: 0;
  width: 100%
}

.vertical_menu_area.vertically_center_content nav.vertical_menu ul {
  display: table-cell;
  height: auto;
  margin: 0;
  min-height: 0;
  position: relative;
  text-align: inherit;
  vertical-align: middle;
  z-index: 101
}

.vertical_menu_area.vertically_center_content .vertical_menu_area_widget_holder {
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 101;
  margin: 0
}

.vertical_menu_area.vertically_center_content .vertical_menu_area_widget_holder .widget {
  margin-bottom: 15px
}

@-webkit-keyframes fade_out {
  0% {
    opacity: 1;
    visibility: visible
  }

  100% {
    opacity: 0;
    visibility: hidden
  }
}

@-moz-keyframes fade_out {
  0% {
    opacity: 1;
    visibility: visible
  }

  100% {
    opacity: 0;
    visibility: hidden
  }
}

@keyframes fade_out {
  0% {
    opacity: 1;
    visibility: visible
  }

  100% {
    opacity: 0;
    visibility: hidden
  }
}

@-webkit-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden
  }

  100% {
    opacity: 1;
    visibility: visible
  }
}

@-moz-keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden
  }

  100% {
    opacity: 1;
    visibility: visible
  }
}

@keyframes fade_in {
  0% {
    opacity: 0;
    visibility: hidden
  }

  100% {
    opacity: 1;
    visibility: visible
  }
}

.qode_popup_menu_push_text_right .popup_menu_holder_outer,
.qode_popup_menu_push_text_top .popup_menu_holder_outer,
.qode_popup_menu_text_scaledown .popup_menu_holder_outer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: fixed;
  z-index: 105;
  opacity: 0
}

.qode_popup_menu_fade_out .popup_menu_holder_outer {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-animation: fade_out .25s linear both;
  animation: fade_out .25s linear both
}

.popup_menu_opened.qode_popup_menu_fade_in .popup_menu_holder_outer {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-animation: fade_in .25s linear both;
  animation: fade_in .25s linear both
}

@-webkit-keyframes push_text_right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-60px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0)
  }
}

@-moz-keyframes push_text_right {
  0% {
    opacity: 0;
    -moz-transform: translateX(-60px)
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0)
  }
}

@keyframes push_text_right {
  0% {
    opacity: 0;
    transform: translateX(-60px)
  }

  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

@-webkit-keyframes push_nav_right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-60px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0)
  }
}

@-moz-keyframes push_nav_right {
  0% {
    opacity: 0;
    -moz-transform: translateX(-60px)
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0)
  }
}

@keyframes push_nav_right {
  0% {
    opacity: 0;
    transform: translateX(-60px)
  }

  100% {
    opacity: 1;
    transform: translateX(0)
  }
}

.popup_menu_opened.qode_popup_menu_push_text_right .popup_menu_holder_outer .popup_menu_widget_holder>div,
.popup_menu_opened.qode_popup_menu_push_text_right .popup_menu_holder_outer nav>ul>li>a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: push_text_right .4s cubic-bezier(.175, .885, .32, 1) both;
  -moz-animation: push_text_right .4s cubic-bezier(.175, .885, .32, 1) both;
  animation: push_text_right .4s cubic-bezier(.175, .885, .32, 1) both
}

.qode_popup_menu_push_text_right.qode_popup_menu_push_nav_right .popup_menu_holder_outer .popup_menu_widget_holder,
.qode_popup_menu_push_text_right.qode_popup_menu_push_nav_right .popup_menu_holder_outer nav {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all .75s ease-out;
  -moz-transition: all .75s ease-out;
  transition: all .75s ease-out;
  -webkit-transform: translateX(50px);
  -moz-transform: translateX(50px);
  transform: translateX(50px)
}

@-webkit-keyframes push_text_top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(60px)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0)
  }
}

@-moz-keyframes push_text_top {
  0% {
    opacity: 0;
    -moz-transform: translateY(60px)
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0)
  }
}

@keyframes push_text_top {
  0% {
    opacity: 0;
    transform: translateY(60px)
  }

  100% {
    opacity: 1;
    transform: translateY(0)
  }
}

.popup_menu_opened.qode_popup_menu_push_text_top .popup_menu_holder_outer .popup_menu_widget_holder>div,
.popup_menu_opened.qode_popup_menu_push_text_top .popup_menu_holder_outer nav>ul>li>a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: push_text_top .4s ease-out both .5s;
  -moz-animation: push_text_top .4s ease-out both .5s;
  animation: push_text_top .4s ease-out both .5s
}

.qode_popup_menu_push_text_top.qode_popup_menu_push_nav_top .popup_menu_holder_outer .popup_menu_widget_holder,
.qode_popup_menu_push_text_top.qode_popup_menu_push_nav_top .popup_menu_holder_outer nav {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(-30px);
  -moz-transform: translateY(-30px);
  transform: translateY(-30px);
  -webkit-transition: all .75s ease-out;
  -moz-transition: all .75s ease-out;
  transition: all .75s ease-out
}

.popup_menu_opened.qode_popup_menu_text_scaledown .popup_menu_holder_outer .popup_menu_widget_holder,
.popup_menu_opened.qode_popup_menu_text_scaledown .popup_menu_holder_outer nav {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all .25s linear .1s;
  -moz-transition: all .25s linear .1s;
  transition: all .25s linear .1s
}

.qode_popup_menu_text_scaledown .popup_menu_holder_outer .popup_menu_widget_holder,
.qode_popup_menu_text_scaledown .popup_menu_holder_outer nav {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all .25s linear .1s;
  -moz-transition: all .25s linear .1s;
  transition: all .25s linear .1s
}

.qode_content_slider {
  overflow: hidden;
  position: relative
}

.qode_content_slider .qode_content_slider_inner {
  padding-left: 1px
}

.qode_content_slider .flex-control-nav {
  text-align: center
}

.qode_content_slider .flex-control-nav li {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin: 0 5px
}

.qode_content_slider .flex-control-nav li a {
  display: block;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  background-color: #cfcfcf;
  border-radius: 50%
}

.qode_content_slider .flex-control-nav li a.flex-active {
  background-color: #303030
}

.qode_content_slider .flex-direction-nav a {
  border: none;
  -webkit-transition: background-color .2s ease;
  -moz-transition: background-color .2s ease;
  transition: background-color .2s ease
}

.qode_content_slider .flex-direction-nav i {
  -webkit-transition: color .2s ease;
  -moz-transition: color .2s ease;
  transition: color .2s ease
}

.qode_content_slider .flex-direction-nav .flex-prev {
  border-radius: 2em;
  left: 15px
}

.qode_content_slider .flex-direction-nav .flex-next {
  border-radius: 2em;
  right: 15px
}

.qode_content_slider .flex-direction-nav .flex-next:hover,
.qode_content_slider .flex-direction-nav .flex-prev:hover {
  background-color: #fff
}

.qode_content_slider .flex-direction-nav .flex-next:hover i,
.qode_content_slider .flex-direction-nav .flex-prev:hover i {
  color: #8a8a8a
}

.qode_content_slider.has_control_nav .flex-direction-nav a {
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px)
}

.qode_content_slider.drag_enabled {
  cursor: e-resize;
  cursor: -webkit-grab;
  cursor: grab
}

.qode_content_slider.control_nav_justified {
  overflow: visible
}

.qode_content_slider.control_nav_justified .flex-control-nav {
  position: relative;
  margin: auto
}

.qode_content_slider.control_nav_justified .flex-control-nav:after {
  content: '';
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 100%;
  height: 2px;
  z-index: -1
}

.qode_content_slider.control_nav_justified .flex-control-nav li {
  position: relative;
  margin: 0;
  vertical-align: middle;
  height: auto;
  width: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.qode_content_slider.control_nav_justified .flex-control-nav li a {
  width: 16px;
  height: 16px;
  background-color: #fff !important;
  -webkit-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, .1);
  -moz-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, .1);
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, .1);
  -webkit-transition: all .3s cubic-bezier(.18, .89, .32, 1.48);
  -moz-transition: all .3s cubic-bezier(.18, .89, .32, 1.48);
  transition: all .3s cubic-bezier(.18, .89, .32, 1.48)
}

.qode_content_slider.control_nav_justified .flex-control-nav li a:after {
  content: '';
  position: absolute;
  width: 6px;
  height: 6px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #cfcfcf
}

.qode_content_slider.control_nav_justified .flex-control-nav li a.flex-active {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, .12);
  -moz-box-shadow: 0 1px 2px 2px rgba(0, 0, 0, .12);
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, .12)
}

.q_price_table.qode_pricing_table_advanced {
  color: inherit;
  border-top: none
}

.q_price_table.qode_pricing_table_advanced .price_table_inner {
  background-color: transparent;
  position: relative;
  display: table;
  width: 100%;
  table-layout: fixed
}

.q_price_table.qode_pricing_table_advanced .price_table_inner .price {
  font-size: 77px;
  line-height: 60px;
  padding: 0 3px;
  font-weight: 200;
  vertical-align: middle;
  display: inline-block
}

.q_price_table.qode_pricing_table_advanced .price_table_inner .mark {
  position: relative;
  line-height: 19px;
  vertical-align: bottom;
  margin-top: 31px;
  display: block;
  font-size: 13px
}

.q_price_table.qode_pricing_table_advanced .price_table_inner .value {
  position: relative;
  top: -13px;
  font-weight: 700;
  font-size: 19px;
  color: #1abc9c
}

.q_price_table.qode_pricing_table_advanced .qode_pt_subtitle {
  display: block;
  font-size: 13px;
  color: #1abc9c
}

.q_price_table.qode_pricing_table_advanced .qode_price_table_prices {
  display: table-cell;
  vertical-align: top;
  width: 40%;
  text-align: right
}

.q_price_table.qode_pricing_table_advanced .qode_pricing_table_text {
  list-style: none;
  display: table-cell;
  vertical-align: top;
  width: 60%;
  padding-left: 30px !important;
  margin: 0
}

.q_price_table.qode_pricing_table_advanced .qode_pricing_table_text li {
  text-align: left;
  padding: 0
}

.q_price_table.qode_pricing_table_advanced .qode_pricing_table_text li.qode_pt_title {
  padding: 2px 0 18px
}

.q_price_table.qode_pricing_table_advanced .qode_pricing_table_text li ul {
  padding: 18px 0 25px
}

.q_price_table.qode_pricing_table_advanced .qode_pricing_table_text li ul li {
  padding: 5px 0
}

.q_price_table.qode_pricing_table_advanced .price_button .qbutton {
  margin: 0
}

.q_price_table.qode_pricing_table_advanced .qode_pt_additional_info {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #e3e3e3;
  font-size: 13px
}

.q_price_table.qode_pricing_table_advanced .qode_pt_additional_info .qode_pt_icon {
  color: #1abc9c;
  font-size: 19px;
  margin-right: 12px;
  vertical-align: middle
}

.q_price_table.qode_pricing_table_advanced .qode_pt_image {
  margin-bottom: 38px
}

.qode-banner {
  overflow: hidden;
  position: relative
}

.qode-banner .qode-banner-image img {
  vertical-align: middle
}

.qode-banner .qode-banner-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11
}

.qode-banner .qode-banner-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 7.2%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .3);
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out
}

.qode-banner .qode-banner-content .qode-banner-content-inner {
  display: table;
  width: 100%;
  height: 100%
}

.qode-banner .qode-banner-content .qode-banner-text-holder {
  display: table-cell;
  vertical-align: middle;
  text-align: center
}

.qode-banner .qode-banner-content .qode-banner-text-holder * {
  color: #fff
}

.qode-banner.qode-banner-va-bottom .qode-banner-content .qode-banner-text-holder {
  vertical-align: bottom
}

.qode-banner.qode-banner-va-top .qode-banner-content .qode-banner-text-holder {
  vertical-align: top;
}

.qode-banner:hover .qode-banner-content {
  opacity: 0;
}

.content .content_inner>.container,
.content .content_inner>.full_width>.full_width_inner {
  background-color: #fff
}

.qode-twitter-feed-shortcode {
  overflow: hidden
}

.qode-twitter-feed-shortcode .qode-tfs-inner {
  margin: -33px -25px
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-item {
  width: 33.33%;
  float: left
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-item:nth-child(3n+1) {
  clear: both
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-item .qode-tfs-item-inner {
  padding: 33px 25px;
  box-sizing: border-box
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-image-info-holder {
  display: table;
  margin-bottom: 13px
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-info {
  line-height: 1em;
  margin-top: 3px
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-image {
  display: table-cell;
  width: 60px
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-image img {
  vertical-align: middle
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-info-holder {
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
  font-size: .8em;
  text-align: left
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-time {
  padding-left: 3px
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-text {
  text-align: left
}

.qode-twitter-feed-shortcode .qode-tfs-inner .qode-tfs-text * {
  color: inherit
}

ul.qode_twitter_widget li {
  margin-bottom: 10px
}

.qode-inter-page-navigation-holder {
  position: relative;
  padding: 80px 41px;
  background-color: #fff;
  z-index: 100
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-inner {
  display: table;
  width: 100%;
  table-layout: fixed
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-next,
.qode-inter-page-navigation-holder .qode-inter-page-navigation-prev {
  display: table-cell;
  vertical-align: middle
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-next a,
.qode-inter-page-navigation-holder .qode-inter-page-navigation-prev a {
  vertical-align: middle;
  color: #303030
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-next .qode-ipn-icon,
.qode-inter-page-navigation-holder .qode-inter-page-navigation-prev .qode-ipn-icon {
  color: #1abc9c
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-next {
  text-align: right
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-next .qode-ipn-arrow {
  padding-left: 21px;
  padding-right: 0
}

.qode-inter-page-navigation-holder .qode-ipn-arrow {
  font-size: 56px;
  display: table-cell;
  width: 1%;
  vertical-align: middle;
  padding-right: 21px
}

.qode-inter-page-navigation-holder .qode-inter-page-title {
  display: table-cell;
  vertical-align: middle
}

.qode-inter-page-navigation-holder .qode-inter-page-title span {
  font-size: 12px;
  font-weight: 500
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-back-link {
  display: table-cell;
  vertical-align: middle;
  width: 84px
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-back-link-inner {
  position: relative;
  width: 84px;
  height: 84px;
  line-height: 84px;
  border-radius: 84px;
  text-align: center;
  background-color: #1abc9c
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-back-link-inner a {
  display: block;
  position: relative;
  z-index: 2;
  color: #303030;
  font-size: 12px;
  font-weight: 500
}

.qode-inter-page-navigation-holder .qode-inter-page-navigation-back-link-inner:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 100%;
  background: #fff
}

.qode-inter-page-navigation-holder .container {
  background-color: transparent !important
}

.blog_holder.masonry_gallery {
  overflow: visible !important;
  opacity: 0
}

.blog_holder.masonry_gallery .blog_holder_grid_sizer {
  width: 25%
}

.blog_holder.masonry_gallery .blog_holder_grid_gutter {
  width: 0%
}

.blog_holder.masonry_gallery #infscr-loading {
  bottom: -35px
}

.blog_holder.masonry_gallery article {
  display: block;
  margin-bottom: 0;
  width: 25%;
  padding: 0;
  text-align: left;
  vertical-align: top;
  z-index: 100;
  box-sizing: border-box
}

.blog_holder.masonry_gallery article.large-width,
.blog_holder.masonry_gallery article.large-width-height {
  width: 50%
}

.blog_holder.masonry_gallery article .ql_full_link {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100
}

.blog_holder.masonry_gallery article .post_image>a {
  height: 100%;
  display: block;
  position: relative
}

.blog_holder.masonry_gallery article .post_image .flexslider .slides a:after,
.blog_holder.masonry_gallery article .post_image>a:after {
  background: rgba(0, 0, 0, .13);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity .2s ease-out 0s;
  transition: opacity .2s ease-out 0s;
  width: 100%;
  z-index: 3
}

.blog_holder.masonry_gallery article .time {
  background-color: #fff;
  left: 0;
  margin: 0;
  padding: 5px 13px;
  position: absolute;
  text-align: center;
  top: 0;
  color: #212121;
  line-height: 22px;
  font-size: 11px;
  z-index: 4
}

.blog_holder.masonry_gallery article .time .time_day {
  font-size: 26px;
  line-height: 26px;
  font-weight: 700
}

.blog_holder.masonry_gallery article .time .time_year {
  color: #a7a7a7
}

.blog_holder.masonry_gallery article .time span {
  display: block
}

.blog_holder.masonry_gallery article .post_text {
  display: block;
  position: absolute;
  bottom: 20px;
  width: 100%;
  padding: 0 60px;
  text-align: center;
  z-index: 99;
  box-sizing: border-box
}

.blog_holder.masonry_gallery article .post_text h5 a {
  color: #fff;
  font-size: 28px;
  text-transform: none;
  font-weight: 700
}

.blog_holder.masonry_gallery article .post_text .post_text_inner {
  padding: 0;
  background-color: transparent
}

.blog_holder.masonry_gallery article .post_info {
  margin-bottom: 10px;
  margin-top: 1px;
  color: #fff
}

.blog_holder.masonry_gallery article .post_info .social_share_list_holder ul li i,
.blog_holder.masonry_gallery article .post_info a:not(:hover) {
  color: #fff
}

.blog_holder.masonry_gallery article .post_content_holder,
.blog_holder.masonry_gallery article .post_image {
  height: 100%
}

.blog_holder.masonry_gallery article.format-link,
.blog_holder.masonry_gallery article.format-quote {
  background-color: #fff;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out
}

.blog_holder.masonry_gallery article.format-link .time,
.blog_holder.masonry_gallery article.format-quote .time {
  background-color: #212121;
  color: #fff;
  -webkit-transition: background-color .2s ease-out, color .2s ease-out;
  transition: background-color .2s ease-out, color .2s ease-out
}

.blog_holder.masonry_gallery article.format-link .time .time_year,
.blog_holder.masonry_gallery article.format-quote .time .time_year {
  color: #fff
}

.blog_holder.masonry_gallery article.format-link .post_text,
.blog_holder.masonry_gallery article.format-quote .post_text {
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: left;
  padding: 0 57px
}

.blog_holder.blog_chequered #infscr-loading,
.blog_holder.blog_gallery #infscr-loading {
  bottom: -35px
}

.blog_holder.masonry_gallery article.format-link .post_text .post_text_inner,
.blog_holder.masonry_gallery article.format-link .post_text:hover .post_text_inner,
.blog_holder.masonry_gallery article.format-quote .post_text .post_text_inner,
.blog_holder.masonry_gallery article.format-quote .post_text:hover .post_text_inner {
  padding: 0;
  background-color: transparent !important
}

.blog_holder.masonry_gallery article.format-link .post_title,
.blog_holder.masonry_gallery article.format-quote .post_title {
  padding: 0
}

.blog_holder.masonry_gallery article.format-link .post_title a,
.blog_holder.masonry_gallery article.format-quote .post_title a {
  font-size: 28px;
  font-weight: 700
}

.blog_holder.masonry_gallery article.format-link .quote_author,
.blog_holder.masonry_gallery article.format-quote .quote_author {
  font-size: 12px;
  font-weight: 300
}

.blog_holder.masonry_gallery article.format-link .post_info span,
.blog_holder.masonry_gallery article.format-link .post_title a,
.blog_holder.masonry_gallery article.format-link .post_title span,
.blog_holder.masonry_gallery article.format-link .social_share_list_holder ul li i,
.blog_holder.masonry_gallery article.format-link i.link_mark,
.blog_holder.masonry_gallery article.format-link i.qoute_mark,
.blog_holder.masonry_gallery article.format-quote .post_info span,
.blog_holder.masonry_gallery article.format-quote .post_title a,
.blog_holder.masonry_gallery article.format-quote .post_title span,
.blog_holder.masonry_gallery article.format-quote .social_share_list_holder ul li i,
.blog_holder.masonry_gallery article.format-quote i.link_mark,
.blog_holder.masonry_gallery article.format-quote i.qoute_mark {
  color: #212121;
  -webkit-transition: color .2s ease;
  transition: color .2s ease
}

.blog_holder.masonry_gallery article.format-link i.link_mark,
.blog_holder.masonry_gallery article.format-link i.qoute_mark,
.blog_holder.masonry_gallery article.format-quote i.link_mark,
.blog_holder.masonry_gallery article.format-quote i.qoute_mark {
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 28px
}

.blog_holder.masonry_gallery article.format-link:hover,
.blog_holder.masonry_gallery article.format-quote:hover {
  background-color: #1abc9c
}

.blog_holder.masonry_gallery article.format-link:hover .entry_title,
.blog_holder.masonry_gallery article.format-quote:hover .entry_title {
  text-decoration: underline
}

.blog_holder.masonry_gallery article.format-link:hover .post_info span,
.blog_holder.masonry_gallery article.format-link:hover .post_title a,
.blog_holder.masonry_gallery article.format-link:hover .post_title span,
.blog_holder.masonry_gallery article.format-link:hover .social_share_list_holder ul li i,
.blog_holder.masonry_gallery article.format-link:hover i.link_mark,
.blog_holder.masonry_gallery article.format-link:hover i.qoute_mark,
.blog_holder.masonry_gallery article.format-quote:hover .post_info span,
.blog_holder.masonry_gallery article.format-quote:hover .post_title a,
.blog_holder.masonry_gallery article.format-quote:hover .post_title span,
.blog_holder.masonry_gallery article.format-quote:hover .social_share_list_holder ul li i,
.blog_holder.masonry_gallery article.format-quote:hover i.link_mark,
.blog_holder.masonry_gallery article.format-quote:hover i.qoute_mark {
  color: #fff
}

.blog_holder.masonry_gallery article:not(.format-link):not(.format-quote):hover .entry_title {
  text-decoration: underline
}

.blog_holder.masonry_gallery article:not(.format-link):not(.format-quote):hover .post_image .flexslider .slides a:after,
.blog_holder.masonry_gallery article:not(.format-link):not(.format-quote):hover .post_image>a:after {
  opacity: 1
}

.blog_holder.blog_gallery {
  overflow: visible !important;
  opacity: 0
}

.blog_holder.blog_gallery .blog_holder_grid_sizer {
  width: 33.33%
}

.blog_holder.blog_gallery .blog_holder_grid_gutter {
  width: 0%
}

.blog_holder.blog_gallery article {
  display: block;
  margin-bottom: 0;
  width: 33.33%;
  padding: 0;
  text-align: left;
  vertical-align: top;
  z-index: 100;
  box-sizing: border-box
}

.blog_holder.blog_gallery article.large-width,
.blog_holder.blog_gallery article.large-width-height {
  width: 50%
}

.blog_holder.blog_gallery article .post_image>a {
  height: 100%;
  display: block;
  position: relative
}

.blog_holder.blog_gallery article .post_image .flexslider .slides a:after,
.blog_holder.blog_gallery article .post_image>a:after {
  background: rgba(0, 0, 0, .13);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .2s ease-out 0s;
  width: 100%;
  z-index: 3
}

.blog_holder.blog_gallery article .post_overlay {
  position: absolute;
  z-index: 98;
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-linear-gradient(transparent, #000);
  background: linear-gradient(transparent, #000)
}

.blog_holder.blog_gallery article .post_text {
  display: block;
  position: absolute;
  bottom: 50px;
  width: 100%;
  padding: 0 65px;
  text-align: left;
  z-index: 99;
  box-sizing: border-box
}

.blog_holder.blog_gallery article .post_text h5 {
  margin: 0 0 18px;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s
}

.blog_holder.blog_gallery article .post_text h5 a {
  color: #fff;
  text-transform: none;
  font-size: 36px;
  line-height: 42px;
  font-weight: 700
}

.blog_holder.blog_gallery article .post_text .post_excerpt {
  visibility: hidden;
  opacity: 0;
  color: #fff;
  -webkit-transition: opacity .2s cubic-bezier(.3, .67, .24, .86), visibility .2s;
  -moz-transition: opacity .2s cubic-bezier(.3, .67, .24, .86), visibility .2s;
  transition: opacity .2s cubic-bezier(.3, .67, .24, .86), visibility .2s;
  margin-bottom: 20px
}

.blog_holder.blog_gallery article .post_text .post_text_inner {
  padding: 0;
  background-color: transparent !important
}

.blog_holder.blog_gallery article:hover .post_excerpt {
  -webkit-transition: opacity .4s cubic-bezier(.3, .67, .24, .86) .15s;
  -moz-transition: opacity .4s cubic-bezier(.3, .67, .24, .86) .15s;
  transition: opacity .4s cubic-bezier(.3, .67, .24, .86) .15s
}

.blog_holder.blog_gallery article .post_info {
  margin-bottom: 0;
  margin-top: 0;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 3px
}

.blog_holder.blog_gallery article .post_info a:not(:hover) {
  color: #fff
}

.blog_holder.blog_gallery article .post_category {
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 3px;
  display: block;
  margin-bottom: 23px;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s
}

.blog_holder.blog_gallery article .post_category a,
.blog_holder.single_image_title_post article .post_category a {
  transition: color .2s ease-out, border-color .2s ease-out;
  -moz-transition: color .2s ease-out, border-color .2s ease-out
}

.blog_holder.blog_gallery article .post_category a {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 15px;
  padding: 3px 12px;
  transition: color .2s ease-out, border-color .2s ease-out
}

.blog_holder.blog_gallery article .post_content_holder,
.blog_holder.blog_gallery article .post_image {
  height: 100%
}

.blog_holder.blog_gallery article:not(.format-link):not(.format-quote) .post_text {
  -webkit-transition: -webkit-transform .3s cubic-bezier(.44, 1.22, 0, 1.43);
  transition: transform .2s cubic-bezier(.44, 1.22, 0, 1.43)
}

.blog_holder.blog_gallery article.format-link .post_text:hover .post_text_inner,
.blog_holder.blog_gallery article.format-quote .post_text:hover .post_text_inner {
  background-color: transparent !important;
  border-color: transparent !important
}

.blog_holder.blog_chequered {
  overflow: visible !important;
  opacity: 0;
  margin-bottom: 30px
}

.blog_holder.blog_chequered .blog_holder_grid_sizer {
  width: 25%
}

.blog_holder.blog_chequered .blog_holder_grid_gutter {
  width: 0%
}

.blog_holder.blog_chequered article {
  display: block;
  margin-bottom: 0;
  width: 25%;
  padding: 0;
  text-align: left;
  vertical-align: top;
  z-index: 100;
  box-sizing: border-box
}

.blog_holder.blog_chequered article.large-width,
.blog_holder.blog_chequered article.large-width-height {
  width: 50%
}

.blog_holder.blog_chequered article .ql_full_link {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100
}

.blog_holder.blog_chequered article .post_image>a {
  height: 100%;
  display: block;
  position: relative
}

.blog_holder.blog_chequered article .post_image .flexslider .slides a:after,
.blog_holder.blog_chequered article .post_image>a:after {
  background: rgba(0, 0, 0, .13);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  -webkit-transition: opacity .2s cubic-bezier(.3, .67, .24, .86);
  -moz-transition: opacity .2s cubic-bezier(.3, .67, .24, .86);
  transition: opacity .2s cubic-bezier(.3, .67, .24, .86)
}

.blog_holder.blog_chequered article .post_text {
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 45px 50px 0;
  text-align: left;
  z-index: 99;
  box-sizing: border-box
}

.blog_holder.blog_chequered article .post_text h5 {
  font-size: 23px;
  line-height: 29px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 0
}

.blog_holder.blog_chequered article .post_text h5 a {
  color: #fff
}

.blog_holder.blog_chequered article .post_text .post_text_inner {
  padding: 0;
  background-color: transparent
}

.blog_holder.blog_chequered article span.audio_icon,
.blog_holder.blog_chequered article span.video_icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: #fff;
  border-radius: 100%;
  width: 62px;
  height: 62px;
  line-height: 62px;
  text-align: center;
  color: #393939;
  font-size: 20px
}

.blog_holder.blog_chequered article .date {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  margin-bottom: 4px
}

.blog_holder.blog_chequered article .post_info {
  margin-bottom: 0;
  margin-top: 0;
  color: #fff;
  position: absolute;
  bottom: 40px;
  left: 50px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 700
}

.blog_holder.blog_chequered article .post_info .social_share_list_holder ul li i,
.blog_holder.blog_chequered article .post_info a {
  color: #fff
}

.blog_holder.blog_chequered article .post_content_holder,
.blog_holder.blog_chequered article .post_image {
  height: 100%
}

.blog_holder.blog_chequered article .post_content_holder {
  overflow: hidden;
  position: relative
}

.blog_holder.blog_chequered article.format-link,
.blog_holder.blog_chequered article.format-quote {
  background-color: #fff;
  -webkit-transition: background-color .3s ease;
  transition: background-color .2s ease
}

.blog_holder.blog_chequered article.format-link .post_text,
.blog_holder.blog_chequered article.format-quote .post_text {
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: left;
  padding: 0 45px;
  height: auto
}

.blog_holder.blog_chequered article.format-link .post_text .post_text_inner,
.blog_holder.blog_chequered article.format-link .post_text:hover .post_text_inner,
.blog_holder.blog_chequered article.format-quote .post_text .post_text_inner,
.blog_holder.blog_chequered article.format-quote .post_text:hover .post_text_inner {
  padding: 0;
  background-color: transparent !important
}

.blog_holder.blog_chequered article.format-link .post_title,
.blog_holder.blog_chequered article.format-quote .post_title {
  padding: 0
}

.blog_holder.blog_chequered article.format-link .post_title p,
.blog_holder.blog_chequered article.format-quote .post_title p {
  font-size: 17px;
  line-height: 23px;
  font-weight: 700
}

.blog_holder.blog_chequered article.format-link .quote_author,
.blog_holder.blog_chequered article.format-quote .quote_author {
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px
}

.blog_holder.blog_chequered article.format-link .post_info span,
.blog_holder.blog_chequered article.format-link .post_title a,
.blog_holder.blog_chequered article.format-link .post_title span,
.blog_holder.blog_chequered article.format-link .social_share_list_holder ul li i,
.blog_holder.blog_chequered article.format-link i.link_mark,
.blog_holder.blog_chequered article.format-link i.qoute_mark,
.blog_holder.blog_chequered article.format-quote .post_info span,
.blog_holder.blog_chequered article.format-quote .post_title a,
.blog_holder.blog_chequered article.format-quote .post_title span,
.blog_holder.blog_chequered article.format-quote .social_share_list_holder ul li i,
.blog_holder.blog_chequered article.format-quote i.link_mark,
.blog_holder.blog_chequered article.format-quote i.qoute_mark {
  color: #212121;
  -webkit-transition: color .2s cubic-bezier(.3, .67, .24, .86);
  -moz-transition: color .2s cubic-bezier(.3, .67, .24, .86);
  transition: color .2s cubic-bezier(.3, .67, .24, .86)
}

.blog_holder.blog_chequered article.format-link i.link_mark,
.blog_holder.blog_chequered article.format-quote i.link_mark {
  position: absolute;
  top: -14px;
  right: 11px;
  font-size: 77px;
  color: #e1e1e1
}

.blog_holder.blog_chequered article.format-link i.qoute_mark,
.blog_holder.blog_chequered article.format-quote i.qoute_mark {
  position: absolute;
  top: -50px;
  right: -18px;
  font-size: 125px;
  color: #e1e1e1
}

.blog_holder.blog_chequered article.format-link:hover,
.blog_holder.blog_chequered article.format-quote:hover {
  background-color: #1abc9c
}

.blog_holder.blog_chequered article.format-link:hover .post_info span,
.blog_holder.blog_chequered article.format-link:hover .post_title a,
.blog_holder.blog_chequered article.format-link:hover .post_title span,
.blog_holder.blog_chequered article.format-link:hover .social_share_list_holder ul li i,
.blog_holder.blog_chequered article.format-link:hover i.link_mark,
.blog_holder.blog_chequered article.format-link:hover i.qoute_mark,
.blog_holder.blog_chequered article.format-quote:hover .post_info span,
.blog_holder.blog_chequered article.format-quote:hover .post_title a,
.blog_holder.blog_chequered article.format-quote:hover .post_title span,
.blog_holder.blog_chequered article.format-quote:hover .social_share_list_holder ul li i,
.blog_holder.blog_chequered article.format-quote:hover i.link_mark,
.blog_holder.blog_chequered article.format-quote:hover i.qoute_mark {
  color: #fff
}

.blog_holder.blog_chequered article:not(.format-link):not(.format-quote) .post_text {
  opacity: 0;
  background-color: rgba(0, 0, 0, .6);
  -webkit-transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out
}

.blog_holder.blog_chequered article:not(.format-link):not(.format-quote):hover {
  -webkit-transition: background-color .2s cubic-bezier(.3, .67, .24, .86);
  -moz-transition: background-color .2s cubic-bezier(.3, .67, .24, .86);
  transition: background-color .2s cubic-bezier(.3, .67, .24, .86)
}

.blog_holder.blog_chequered article:not(.format-link):not(.format-quote):hover .post_image .flexslider .slides a:after,
.blog_holder.blog_chequered article:not(.format-link):not(.format-quote):hover .post_image>a:after,
.blog_holder.blog_chequered article:not(.format-link):not(.format-quote):hover .post_text {
  opacity: 1
}

.blog_compound article {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 56px;
  padding-bottom: 58px
}

.blog_compound article .qodef-image-shader {
  display: block;
  position: relative
}

.blog_compound article .qodef-image-shader:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: rgba(0, 0, 0, .3);
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  transition: opacity .2s
}

.blog_compound article .qode_blog_gallery_item.qode_blog_img_large_height .qodef-image-shader {
  height: 100%
}

.blog_compound article a:hover .qodef-image-shader:after {
  opacity: 1;
  -webkit-transition: opacity .4s;
  -moz-transition: opacity .4s;
  transition: opacity .4s
}

.blog_compound article:last-child {
  border: 0
}

.blog_compound article .post_image img {
  position: relative;
  display: block
}

.blog_compound article .post_text {
  margin-top: 45px
}

.blog_compound article .post_meta {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin-top: 40px
}

.blog_compound article .post_meta .three_columns>.column1 {
  text-align: left
}

.blog_compound article .post_meta .three_columns>.column2 {
  text-align: center
}

.blog_compound article .post_meta .three_columns>.column3 {
  text-align: right
}

.blog_compound article .post_meta .blog_like a,
.blog_compound article .post_meta .blog_share a,
.blog_compound article .post_meta .post_comments {
  font-style: italic
}

.blog_compound article .post_meta .blog_like a:not(:hover),
.blog_compound article .post_meta .blog_share a:not(:hover),
.blog_compound article .post_meta .post_comments:not(:hover) {
  color: #7f7f7f
}

.blog_compound article .post_meta .blog_share {
  margin-left: 10px
}

.blog_compound article .two_columns_50_50 .post_text {
  margin-top: 0
}

.blog_compound article .post_title {
  text-align: center
}

.blog_compound article .post_title .category {
  margin-bottom: 10px;
  display: block
}

.blog_compound article .post_title .category .date,
.blog_compound article .post_title .category a {
  color: #1abc9c;
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: underline
}

.blog_compound.sticky_posts article:last-child {
  margin: 0
}

.blog_compound.sticky_posts .post_title {
  text-align: center;
  margin: 45px 0 -15px
}

.qode_blog_masonry_gallery .qode_blog_gallery_sizer {
  width: calc((100% - 8px)/ 2)
}

.qode_blog_masonry_gallery .qode_blog_gallery_gutter {
  width: 8px
}

.qode_blog_masonry_gallery .qode_blog_gallery_item {
  box-sizing: border-box;
  width: calc((100% - 8px)/ 2)
}

.qode_blog_masonry_gallery .qode_blog_gallery_item a {
  display: block;
  padding: 0 0 8px;
  height: 100%
}

.qode_blog_masonry_gallery .qode_blog_gallery_item a img {
  vertical-align: middle;
  height: calc(100% - 8px)
}

.qode_blog_masonry_gallery .qode_blog_gallery_item.qode_blog_img_large_height,
.qode_blog_masonry_gallery .qode_blog_gallery_item.qode_blog_img_large_height_width {
  box-sizing: border-box
}

.qode_blog_masonry_gallery .qode_blog_gallery_item.qode_blog_img_large_height a,
.qode_blog_masonry_gallery .qode_blog_gallery_item.qode_blog_img_large_height_width a {
  height: calc(100% - 8px)
}

.qode_blog_masonry_gallery .qode_blog_gallery_item.qode_blog_img_large_height a img,
.qode_blog_masonry_gallery .qode_blog_gallery_item.qode_blog_img_large_height_width a img {
  height: 100%
}

.qode_blog_masonry_gallery .qode_blog_gallery_item.qode_blog_img_large_height_width,
.qode_blog_masonry_gallery .qode_blog_gallery_item.qode_blog_img_large_width {
  width: 100%
}

.blog_holder.blog_pinterest {
  margin-bottom: 20px;
  transition: none !important;
  overflow: visible !important;
  opacity: 0
}

.blog_holder.blog_pinterest .blog_holder_grid_sizer {
  width: 31.7%
}

.blog_holder.blog_pinterest .blog_holder_grid_gutter {
  width: 2.3%
}

.blog_holder.blog_pinterest .mejs-container,
.blog_holder.blog_pinterest .mejs-mediaelement {
  border: none
}

.blog_holder.blog_pinterest article {
  display: block;
  float: left;
  margin: 0 0 30px;
  padding: 0;
  text-align: left;
  vertical-align: top;
  z-index: 100;
  border: 0;
  width: 31.7%
}

.blog_holder.blog_pinterest article .entry_title:hover {
  text-decoration: underline
}

.blog_holder.blog_pinterest article .blog_holder_grid_gutter {
  width: 3%
}

.blog_holder.blog_pinterest article .post_text .post_text_inner {
  padding: 22px 30px 25px;
  background-color: #fff
}

.blog_holder.blog_pinterest article.format-link .post_text .post_text_inner,
.blog_holder.blog_pinterest article.format-quote .post_text .post_text_inner {
  padding: 50px 45px;
  background-color: #fff !important;
  text-align: center
}

.blog_holder.blog_pinterest article.format-link .post_text .post_text_inner .icon,
.blog_holder.blog_pinterest article.format-quote .post_text .post_text_inner .icon {
  font-size: 55px;
  color: #e0e0e0;
  margin-bottom: 30px;
  display: block
}

.blog_holder.blog_pinterest article.format-link .post_text .post_text_inner h5 span,
.blog_holder.blog_pinterest article.format-quote .post_text .post_text_inner h5 span {
  font-size: .8em
}

.blog_holder.blog_pinterest article.format-link .quote_author,
.blog_holder.blog_pinterest article.format-quote .quote_author {
  margin-top: 12px;
  text-transform: uppercase;
  font-size: 10px;
  color: #2e2e2e !important;
  font-weight: 600
}

.blog_holder.blog_pinterest article.format-audio .post_text .post_text_inner {
  padding-top: 8px
}

.blog_holder.blog_pinterest article .post_image {
  margin: 0;
  width: auto
}

.blog_holder.blog_pinterest article .post_info {
  margin-bottom: 10px
}

.blog_holder.blog_pinterest article .post_info,
.blog_holder.blog_pinterest article .post_info a:not(:hover) {
  font-size: 10px;
  color: #2e2e2e;
  text-transform: uppercase
}

.blog_holder.blog_pinterest article h5 a {
  color: #2e2e2e;
  font-size: 23px;
  line-height: 26px;
  text-transform: none;
  margin: 0
}

.two_columns_25_75 .blog_holder.blog_pinterest .blog_holder_grid_sizer,
.two_columns_25_75 .blog_holder.blog_pinterest article,
.two_columns_33_66 .blog_holder.blog_pinterest .blog_holder_grid_sizer,
.two_columns_33_66 .blog_holder.blog_pinterest article,
.two_columns_66_33 .blog_holder.blog_pinterest .blog_holder_grid_sizer,
.two_columns_66_33 .blog_holder.blog_pinterest article,
.two_columns_75_25 .blog_holder.blog_pinterest .blog_holder_grid_sizer,
.two_columns_75_25 .blog_holder.blog_pinterest article {
  width: 48%
}

.two_columns_25_75 .blog_holder.blog_pinterest .blog_holder_grid_gutter,
.two_columns_33_66 .blog_holder.blog_pinterest .blog_holder_grid_gutter,
.two_columns_66_33 .blog_holder.blog_pinterest .blog_holder_grid_gutter,
.two_columns_75_25 .blog_holder.blog_pinterest .blog_holder_grid_gutter {
  width: 4%
}

.blog_headlines {
  margin-bottom: 56px
}

.blog_headlines article {
  margin-bottom: 40px;
  text-align: center
}

.blog_headlines article h2 {
  line-height: 1em;
  opacity: 0;
  -webkit-transform: translateY(50px) scale(.7);
  -moz-transform: translateY(50px) scale(.7);
  transform: translateY(50px) scale(.7);
  -webkit-transition: opacity .6s ease, transform .6s ease;
  -moz-transition: opacity .6s ease, transform .6s ease;
  transition: opacity .6s ease, transform .6s ease
}

.blog_headlines article h2.show {
  opacity: 1;
  -webkit-transform: translateY(0) scale(1);
  -moz-transform: translateY(0) scale(1);
  transform: translateY(0) scale(1)
}

.blog_headlines article h2 a {
  line-height: 1em
}

.blog_holder.single_image_title_post article .post_content_holder {
  position: relative
}

.blog_holder.single_image_title_post article .post_image {
  text-align: center
}

.blog_holder.single_image_title_post article .single_top_part_holder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, .3)
}

.blog_holder.single_image_title_post article .single_top_part {
  display: table;
  height: 100%;
  width: 100%
}

.blog_holder.single_image_title_post article .single_top_part_inner {
  display: table-cell;
  height: 100%;
  vertical-align: middle
}

.blog_holder.single_image_title_post article .qode-itp-single-separator.separator {
  width: 64px;
  height: 4px;
  margin-top: 35px;
  margin-bottom: 35px
}

.blog_holder.single_image_title_post article .post_info {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 3px;
  display: block;
  color: #fff
}

.blog_holder.single_image_title_post article .post_info a,
.blog_holder.single_image_title_post article .post_info a:hover span {
  color: #fff
}

.blog_holder.single_image_title_post article .post_category {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 3px;
  display: block;
  margin-bottom: 17px
}

.blog_holder.single_image_title_post article .post_category a {
  color: #fff;
  transition: color .2s ease-out, border-color .2s ease-out
}

.blog_holder.single_image_title_post article h1 {
  color: #fff
}

.blog_holder.single_image_title_post article .itp_post_text .post_text_inner {
  padding: 95px 0 0
}

.blog_holder.single_image_title_post article .single_bottom_part {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 80px 0 30px;
  border-bottom: 1px solid #e5e5e5
}

.blog_holder.single_image_title_post article .single_bottom_part .single_bottom_part_left .tags_text h5,
.blog_holder.single_image_title_post article .single_bottom_part .single_bottom_part_right .social_share_list_holder>span,
.qode-card-panes .pane .card,
.qode-card-panes .pane.no-shadow:after {
  display: none
}

.blog_holder.single_image_title_post article .single_bottom_part .single_bottom_part_left {
  width: 50%;
  float: left;
  text-align: left
}

.blog_holder.single_image_title_post article .single_bottom_part .single_bottom_part_left .single_tags {
  margin: 0
}

.blog_holder.single_image_title_post article .single_bottom_part .single_bottom_part_right {
  width: 50%;
  float: left;
  text-align: right
}

.blog_holder.single_image_title_post .comment_holder {
  padding-left: 10%;
  padding-right: 10%
}

.blog_holder.single_image_title_post .comment_form {
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%
}

@-webkit-keyframes tease-card-headers {
  0% {
    opacity: 0;
    transform: translate(-20%, 0)
  }

  100% {
    opacity: 1;
    transform: translate(0, 0)
  }
}

@keyframes tease-card-headers {
  0% {
    opacity: 0;
    transform: translate(-20%, 0)
  }

  100% {
    opacity: 1;
    transform: translate(0, 0)
  }
}

.qode-cards-holder {
  max-width: 1100px;
  margin: 0 auto
}

.cards {
  position: relative;
  height: 60px;
  margin-top: 50px;
  z-index: 10
}

.cards .card {
  width: 100%;
  box-sizing: border-box;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  position: absolute;
  height: 60px
}

.cards .card .card-inner {
  position: relative;
  height: 100%;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  box-shadow: 0 -10px 10px rgba(95, 95, 95, .03)
}

.cards .card:nth-last-child(6) {
  padding: 0 110px;
  top: -75px
}

.cards .card:nth-last-child(5) {
  padding: 0 90px;
  top: -60px
}

.cards .card:nth-last-child(4) {
  padding: 0 65px;
  top: -45px
}

.cards .card:nth-last-child(3) {
  padding: 0 40px;
  top: -30px
}

.cards .card:nth-last-child(2) {
  padding: 0 20px;
  top: -15px
}

.cards .card:nth-last-child(1) {
  padding: 0;
  top: 0
}

.cards .card:not(:last-of-type) {
  cursor: pointer
}

.cards .card:not(:last-of-type):not(.active).hovered,
.cards .card:not(:last-of-type):not(.active):hover {
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  transform: translateY(-15px)
}

.qode-card-panes {
  position: relative;
  width: 100%
}

.qode-card-panes .pane {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity .5s ease;
  -moz-transition: opacity .5s ease;
  transition: opacity .5s ease;
  z-index: 1;
  box-shadow: 0 1px 25px rgba(95, 95, 95, .08)
}

.qode-card-panes .pane:after {
  border-radius: 100px/10px;
  bottom: 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, .15);
  content: "";
  left: 50%;
  position: absolute;
  right: 10px;
  top: 49%;
  z-index: -1;
  width: 98%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%)
}

.qode-card-panes .pane.active {
  opacity: 1;
  z-index: 2
}

.qode-card-panes .pane.navigation-bullets-disabled .qode-card-slider-holder-outer {
  padding-bottom: 65px
}

.qode-card-panes .pane.border-radius .qode-card-slider-holder .qode-card-slider .slide img {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px
}

.qode-card-panes .pane.qode-slide-shadow .qode-card-slider-holder .qode-card-slider .slide img {
  box-shadow: 0 3px 20px rgba(95, 95, 95, .08)
}

.qode-card-panes .pane.hover-animation .qode-card-slider-holder .qode-card-slider .slide:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px)
}

.qode-card-panes .pane.no-shadow {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none
}

.qode-card-panes .pane.no-shadow .qode-card-slider-holder-outer {
  padding: 0
}

.qode-card-slider-holder-outer {
  padding-top: 53px;
  padding-bottom: 88px
}

.qode-card-slider-holder {
  position: relative
}

.qode-card-slider-holder .qode-card-slider {
  transition: margin .5s ease-in-out 0s;
  white-space: nowrap;
  width: 100%;
  position: relative
}

.qode-card-slider-holder .qode-card-slider .slide {
  float: left;
  padding: 0 23px;
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  -webkit-transition: transform .3s ease;
  -moz-transition: transform .3s ease;
  transition: transform .3s ease
}

.qode-card-slider-holder .qode-card-slider .slide img {
  display: block;
  position: relative;
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  transition: opacity .3s ease
}

.qode-card-slider-holder .controls.arrows {
  position: static
}

.qode-card-slider-holder .controls.bullets {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 100;
  height: 27px;
  bottom: -57px
}

.qode-card-slider-holder .controls .button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  width: 90px;
  height: 90px;
  z-index: 100;
  border: 0;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(163, 163, 163, .45);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.qode-card-slider-holder .controls .button:hover {
  box-shadow: 0 0 5px 0 rgba(163, 163, 163, .45)
}

.qode-card-slider-holder .controls .button span {
  position: absolute;
  top: 0;
  font-size: 56px;
  height: 90px;
  line-height: 90px;
  width: 90px;
  text-align: center;
  text-indent: 0;
  visibility: visible;
  cursor: pointer
}

.qode-card-slider-holder .controls .button.prev {
  left: -60px
}

.qode-card-slider-holder .controls .button.prev span {
  left: -2px
}

.qode-card-slider-holder .controls .button.next {
  right: -60px
}

.qode-card-slider-holder .controls .button.next span {
  right: -2px
}

.qode-card-slider-holder .controls .button.hidden {
  opacity: 0;
  visibility: hidden
}

.qode-card-slider-holder .controls .button.hidden span {
  visibility: hidden
}

.qode-card-slider-holder .controls .dots {
  position: relative
}

.qode-card-slider-holder .controls .dots .dots-inner {
  margin: 0 auto;
  display: table
}

.qode-card-slider-holder .controls .dots .dots-inner .dot {
  position: relative;
  width: 7px;
  height: 7px;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  cursor: pointer;
  margin: 0 5px;
  background-color: #c5c5c5;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: background-color .2s ease;
  -moz-transition: background-color .2s ease;
  transition: background-color .2s ease
}

.qode-card-slider-holder .controls .dots .dots-inner .dot.active {
  background-color: #000
}

.qode-cards-gallery-holder {
  padding: 10px 0;
  position: relative
}

.qode-cards-gallery-holder .qode-cards-gallery {
  width: 100%
}

.qode-cards-gallery-holder img {
  position: relative;
  display: block;
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  transition: opacity .3s ease
}

.qode-cards-gallery-holder .fake_card {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  pointer-events: none
}

.qode-cards-gallery-holder .card {
  box-sizing: border-box;
  position: absolute;
  -webkit-transition: transform .5s ease-in-out, margin .3s ease-in-out, left .3s ease-in-out, right .3s ease-in-out, top .3s ease-in-out, bottom .3s ease-in-out;
  -moz-transition: transform .5s ease-in-out, margin .3s ease-in-out, left .3s ease-in-out, right .3s ease-in-out, top .3s ease-in-out, bottom .3s ease-in-out;
  transition: transform .5s ease-in-out, margin .3s ease-in-out, left .3s ease-in-out, right .3s ease-in-out, top .3s ease-in-out, bottom .3s ease-in-out;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2)
}

.qode-cards-gallery-holder .card:not(:last-of-type) {
  cursor: pointer
}

.qode-cards-gallery-holder.left .card.out {
  -webkit-transition: transform .5s ease-in-out, left .3s ease-in-out;
  -moz-transition: transform .5s ease-in-out, left .3s ease-in-out;
  transition: transform .5s ease-in-out, left .3s ease-in-out
}

.qode-cards-gallery-holder.left .card:nth-last-child(8) {
  -webkit-transform: scale(.3);
  -moz-transform: scale(.3);
  transform: scale(.3);
  left: -70%
}

.qode-cards-gallery-holder.left .card:nth-last-child(7) {
  -webkit-transform: scale(.4);
  -moz-transform: scale(.4);
  transform: scale(.4);
  left: -60%
}

.qode-cards-gallery-holder.left .card:nth-last-child(6) {
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  transform: scale(.5);
  left: -50%
}

.qode-cards-gallery-holder.left .card:nth-last-child(5) {
  -webkit-transform: scale(.6);
  -moz-transform: scale(.6);
  transform: scale(.6);
  left: -40%
}

.qode-cards-gallery-holder.left .card:nth-last-child(4) {
  -webkit-transform: scale(.7);
  -moz-transform: scale(.7);
  transform: scale(.7);
  left: -30%
}

.qode-cards-gallery-holder.left .card:nth-last-child(3) {
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  transform: scale(.8);
  left: -20%
}

.qode-cards-gallery-holder.left .card:nth-last-child(2) {
  -webkit-transform: scale(.9);
  -moz-transform: scale(.9);
  transform: scale(.9);
  left: -10%
}

.qode-cards-gallery-holder.left .card:nth-last-child(1) {
  padding: 0;
  left: 0
}

.qode-cards-gallery-holder.left .card:not(:last-of-type):not(.active):hover {
  margin-left: -10px !important
}

.qode-cards-gallery-holder.right .card.out {
  -webkit-transition: transform .5s ease-in-out, right .3s ease-in-out;
  -moz-transition: transform .5s ease-in-out, right .3s ease-in-out;
  transition: transform .5s ease-in-out, right .3s ease-in-out
}

.qode-cards-gallery-holder.right .card:nth-last-child(8) {
  -webkit-transform: scale(.3);
  -moz-transform: scale(.3);
  transform: scale(.3);
  right: -70%
}

.qode-cards-gallery-holder.right .card:nth-last-child(7) {
  -webkit-transform: scale(.4);
  -moz-transform: scale(.4);
  transform: scale(.4);
  right: -60%
}

.qode-cards-gallery-holder.right .card:nth-last-child(6) {
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  transform: scale(.5);
  right: -50%
}

.qode-cards-gallery-holder.right .card:nth-last-child(5) {
  -webkit-transform: scale(.6);
  -moz-transform: scale(.6);
  transform: scale(.6);
  right: -40%
}

.qode-cards-gallery-holder.right .card:nth-last-child(4) {
  -webkit-transform: scale(.7);
  -moz-transform: scale(.7);
  transform: scale(.7);
  right: -30%
}

.qode-cards-gallery-holder.right .card:nth-last-child(3) {
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  transform: scale(.8);
  right: -20%
}

.qode-cards-gallery-holder.right .card:nth-last-child(2) {
  -webkit-transform: scale(.9);
  -moz-transform: scale(.9);
  transform: scale(.9);
  right: -10%
}

.qode-cards-gallery-holder.right .card:nth-last-child(1) {
  padding: 0;
  right: 0
}

.qode-cards-gallery-holder.right .card:not(:last-of-type):not(.active):hover {
  margin-right: -10px !important
}

.qode-cards-gallery-holder.top .card.out {
  -webkit-transition: transform .5s ease-in-out, top .3s ease-in-out;
  -moz-transition: transform .5s ease-in-out, top .3s ease-in-out;
  transition: transform .5s ease-in-out, top .3s ease-in-out
}

.qode-cards-gallery-holder.top .card:nth-last-child(8) {
  -webkit-transform: scale(.3);
  -moz-transform: scale(.3);
  transform: scale(.3);
  top: -70%
}

.qode-cards-gallery-holder.top .card:nth-last-child(7) {
  -webkit-transform: scale(.4);
  -moz-transform: scale(.4);
  transform: scale(.4);
  top: -60%
}

.qode-cards-gallery-holder.top .card:nth-last-child(6) {
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  transform: scale(.5);
  top: -50%
}

.qode-cards-gallery-holder.top .card:nth-last-child(5) {
  -webkit-transform: scale(.6);
  -moz-transform: scale(.6);
  transform: scale(.6);
  top: -40%
}

.qode-cards-gallery-holder.top .card:nth-last-child(4) {
  -webkit-transform: scale(.7);
  -moz-transform: scale(.7);
  transform: scale(.7);
  top: -30%
}

.qode-cards-gallery-holder.top .card:nth-last-child(3) {
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  transform: scale(.8);
  top: -20%
}

.qode-cards-gallery-holder.top .card:nth-last-child(2) {
  -webkit-transform: scale(.9);
  -moz-transform: scale(.9);
  transform: scale(.9);
  top: -10%
}

.qode-cards-gallery-holder.top .card:nth-last-child(1) {
  padding: 0;
  top: 0
}

.qode-cards-gallery-holder.top .card:not(:last-of-type):not(.active):hover {
  margin-top: -10px !important
}

.qode-cards-gallery-holder.bottom .card.out {
  -webkit-transition: transform .5s ease-in-out, bottom .3s ease-in-out;
  -moz-transition: transform .5s ease-in-out, bottom .3s ease-in-out;
  transition: transform .5s ease-in-out, bottom .3s ease-in-out
}

.qode-cards-gallery-holder.bottom .card:nth-last-child(8) {
  -webkit-transform: scale(.3);
  -moz-transform: scale(.3);
  transform: scale(.3);
  bottom: -70%
}

.qode-cards-gallery-holder.bottom .card:nth-last-child(7) {
  -webkit-transform: scale(.4);
  -moz-transform: scale(.4);
  transform: scale(.4);
  bottom: -60%
}

.qode-cards-gallery-holder.bottom .card:nth-last-child(6) {
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  transform: scale(.5);
  bottom: -50%
}

.qode-cards-gallery-holder.bottom .card:nth-last-child(5) {
  -webkit-transform: scale(.6);
  -moz-transform: scale(.6);
  transform: scale(.6);
  bottom: -40%
}

.qode-cards-gallery-holder.bottom .card:nth-last-child(4) {
  -webkit-transform: scale(.7);
  -moz-transform: scale(.7);
  transform: scale(.7);
  bottom: -30%
}

.qode-cards-gallery-holder.bottom .card:nth-last-child(3) {
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  transform: scale(.8);
  bottom: -20%
}

.qode-cards-gallery-holder.bottom .card:nth-last-child(2) {
  -webkit-transform: scale(.9);
  -moz-transform: scale(.9);
  transform: scale(.9);
  bottom: -10%
}

.qode-cards-gallery-holder.bottom .card:nth-last-child(1) {
  padding: 0;
  bottom: 0
}

.qode-cards-gallery-holder.bottom .card:not(:last-of-type):not(.active):hover {
  margin-bottom: -10px !important
}

.qode-expanding-images {
  position: relative;
  text-align: center
}

.qode-expanding-images .qode-expanding-images-inner {
  display: inline-block;
  position: relative;
  max-width: 46.875%
}

.qode-expanding-images .qode-frame-image {
  position: relative;
  z-index: 30;
  pointer-events: none
}

.qode-expanding-images.qode-ie-specific .qode-frame-image {
  z-index: 10
}

.qode-expanding-images .qode-hero-image {
  position: absolute;
  height: 66%;
  width: 92%;
  left: 4%;
  top: 5%;
  z-index: 20;
  overflow: hidden;
  background-color: #f3f3f3
}

.qode-expanding-images .qode-hero-image .qode-hero-image-link {
  display: block;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  height: 100%
}

.qode-expanding-images .qode-hero-image .qode-hero-image-link:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, .12);
  opacity: 0;
  -webkit-transition: opacity .4s cubic-bezier(.21, .74, .52, .87);
  -moz-transition: opacity .4s cubic-bezier(.21, .74, .52, .87);
  transition: opacity .4s cubic-bezier(.21, .74, .52, .87)
}

.qode-expanding-images .qode-hero-image .qode-hero-image-link:hover:after {
  opacity: 1
}

.qode-expanding-images .qode-hero-image .qode-hero-image-link:hover>.qode-hero-image-title {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease
}

.qode-expanding-images .qode-hero-image .qode-hero-image-link:hover img {
  -webkit-transform: translate3d(0, -30px, 0);
  -moz-transform: translate3d(0, -30px, 0);
  transform: translate3d(0, -30px, 0);
  -webkit-transition: .35s cubic-bezier(.18, .87, .28, 1.01) 50ms;
  -moz-transition: .35s cubic-bezier(.18, .87, .28, 1.01) 50ms;
  transition: .35s cubic-bezier(.18, .87, .28, 1.01) 50ms
}

.qode-expanding-images .qode-hero-image img {
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  will-change: transform
}

.qode-expanding-images .qode-hero-image-title {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #fff;
  z-index: 300;
  padding: 17px 0;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  transition: all .35s ease;
  -webkit-transform: translate3d(0, 100%, 0);
  -moz-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0)
}

.qode-expanding-images .qode-hero-image-title h3 {
  text-transform: none;
  font-size: 14px;
  font-weight: 500
}

.qode-expanding-images .qode-side-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 77%
}

.qode-expanding-images .qode-side-image-link {
  -webkit-transition: opacity .4s cubic-bezier(.21, .74, .52, .87);
  -moz-transition: opacity .4s cubic-bezier(.21, .74, .52, .87);
  transition: opacity .4s cubic-bezier(.21, .74, .52, .87)
}

.qode-expanding-images .qode-side-image-link:hover {
  opacity: .95
}

.qode-expanding-images .qode-side-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: all 1s cubic-bezier(.21, .74, .52, .87);
  -moz-transition: all 1s cubic-bezier(.21, .74, .52, .87);
  transition: all 1s cubic-bezier(.21, .74, .52, .87);
  -webkit-box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, .1);
  -moz-box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, .1);
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, .1)
}

.qode-expanding-images .qode-side-image:nth-of-type(1) {
  z-index: 7
}

.qode-expanding-images .qode-side-image:nth-of-type(2) {
  z-index: 6
}

.qode-expanding-images .qode-side-image:nth-of-type(3) {
  z-index: 5
}

.qode-expanding-images .qode-side-image:nth-of-type(4) {
  z-index: 4
}

.qode-expanding-images .qode-side-image:nth-of-type(5) {
  z-index: 3
}

.qode-expanding-images .qode-side-image:nth-of-type(6) {
  z-index: 2
}

.qode-expanding-images .qode-side-image:nth-of-type(7) {
  z-index: 1
}

.qode-expanding-images .qode-side-image:nth-of-type(8) {
  z-index: 0
}

.qode-expanding-images .qode-side-image.qode-side-image-1,
.qode-expanding-images .qode-side-image.qode-side-image-3 {
  width: 16.92%;
  padding-top: 10.575%
}

.qode-expanding-images .qode-side-image.qode-side-image-2,
.qode-expanding-images .qode-side-image.qode-side-image-4 {
  width: 17.7%;
  padding-top: 11.0625%
}

.qode-expanding-images .qode-side-image.qode-side-image-1 {
  top: 7%;
  left: 13.5%
}

.qode-expanding-images .qode-side-image.qode-side-image-2 {
  bottom: 9.5%;
  left: 10.3%
}

.qode-expanding-images .qode-side-image.qode-side-image-3 {
  top: 7%;
  right: 14%
}

.qode-expanding-images .qode-side-image.qode-side-image-4 {
  bottom: 9.5%;
  right: 9.8%
}

.qode-expanding-images .qode-side-image.qode-side-image-5,
.qode-expanding-images .qode-side-image.qode-side-image-7 {
  width: 11.56%;
  padding-top: 7.225%
}

.qode-expanding-images .qode-side-image.qode-side-image-6,
.qode-expanding-images .qode-side-image.qode-side-image-8 {
  width: 10.88%;
  padding-top: 6.8%
}

.qode-expanding-images .qode-side-image.qode-side-image-5 {
  top: 12.2%;
  left: .5%
}

.qode-expanding-images .qode-side-image.qode-side-image-7 {
  top: 12.2%;
  right: .5%
}

.qode-expanding-images .qode-side-image.qode-side-image-6 {
  bottom: 24.5%;
  left: -3%
}

.qode-expanding-images .qode-side-image.qode-side-image-8 {
  bottom: 24.5%;
  right: -3%
}

.qode-item-showcase {
  text-align: center;
  opacity: 0
}

.touchevents .qode-item-showcase {
  opacity: 1
}

.qode-item-showcase .qode-item-image {
  display: inline-block;
  margin-top: -70px;
  opacity: 0;
  width: 36%;
  -webkit-transform: translateY(70px);
  -moz-transform: translateY(70px);
  transform: translateY(70px)
}

.qode-item-showcase .qode-item-image img {
  vertical-align: middle
}

.qode-item-showcase .qode-item-image.qode-appeared,
.touchevents .qode-item-showcase .qode-item-image {
  -webkit-transition: opacity .55s cubic-bezier(.23, 1, .32, 1), -webkit-transform .55s cubic-bezier(.23, 1, .32, 1);
  transition: opacity .55s cubic-bezier(.23, 1, .32, 1), transform .55s cubic-bezier(.23, 1, .32, 1);
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0)
}

.qode-item-showcase .qode-item {
  display: inline-block;
  opacity: 0
}

.qode-item-showcase .qode-item.qode-item-left {
  -webkit-transform: translateX(-70px);
  -moz-transform: translateX(-70px);
  transform: translateX(-70px);
  text-align: right
}

.qode-item-showcase .qode-item.qode-item-left .qode-item-icon {
  padding-left: 30px
}

.qode-item-showcase .qode-item.qode-item-right {
  text-align: left;
  -webkit-transform: translateX(70px);
  -moz-transform: translateX(70px);
  transform: translateX(70px)
}

.qode-item-showcase .qode-item.qode-item-right .qode-item-icon {
  padding-right: 30px
}

.qode-item-showcase .qode-item.qode-appeared,
.touchevents .qode-item-showcase .qode-item {
  -webkit-transition: opacity .55s cubic-bezier(.23, 1, .32, 1), -webkit-transform .55s cubic-bezier(.23, 1, .32, 1);
  transition: opacity .55s cubic-bezier(.23, 1, .32, 1), transform .55s cubic-bezier(.23, 1, .32, 1);
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0)
}

.qode-item-showcase .qode-item .qode-item-icon {
  display: table-cell;
  font-size: 30px
}

.qode-item-showcase .qode-item .qode-item-icon i {
  padding: 15px;
  font-size: 17px;
  color: #fff;
  background-color: #2a2a2a;
  -webkit-transition: opacity .15s ease-out;
  -moz-transition: opacity .15s ease-out;
  transition: opacity .15s ease-out
}

.qode-item-showcase .qode-item .qode-item-icon i:hover {
  opacity: .94
}

.qode-item-showcase .qode-item .qode-item-content {
  display: table-cell;
  vertical-align: top
}

.qode-item-showcase .qode-item .qode-item-content .qode-showcase-title-holder .qode-showcase-title {
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 25px;
  letter-spacing: 0;
  -webkit-transition: color .15s ease-in-out;
  -moz-transition: color .15s ease-in-out;
  transition: color .15s ease-in-out
}

.qode-item-showcase .qode-item .qode-item-content .qode-showcase-title-holder a:hover .qode-showcase-title {
  color: inherit
}

.qode-item-showcase .qode-item .qode-item-content .qode-showcase-text-holder .qode-showcase-text {
  margin: 7px 0 44px
}

.qode-item-showcase .qode-item-showcase-holder {
  position: relative;
  display: inline-block;
  width: 32%
}

.qode-item-showcase .qode-item-showcase-holder .qode-item-line {
  content: '';
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-left: 1px dashed #2a2a2a;
  opacity: 0;
  -webkit-transition: all .55s cubic-bezier(.23, 1, .32, 1) .25s;
  -moz-transition: all .55s cubic-bezier(.23, 1, .32, 1) .25s;
  transition: all .55s cubic-bezier(.23, 1, .32, 1) .25s;
  z-index: -1
}

.touchevents .qode-item-showcase .qode-item-showcase-holder .qode-item-line {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none
}

.qode-item-showcase .qode-item-showcase-holder.qode-holder-left {
  float: left;
  text-align: right
}

.qode-item-showcase .qode-item-showcase-holder.qode-holder-right {
  float: right;
  text-align: left
}

.qode-crossfade-images {
  display: inline-block;
  position: relative
}

.qode-crossfade-images .qode-cfi-link {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100
}

.qode-crossfade-images .qode-cfi-img-holder {
  -webkit-box-shadow: 0 10px 18px 0 rgba(0, 0, 0, .08);
  -moz-box-shadow: 0 10px 18px 0 rgba(0, 0, 0, .08);
  box-shadow: 0 10px 18px 0 rgba(0, 0, 0, .08);
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s
}

.qode-crossfade-images .qode-cfi-img-holder .qode-cfi-img-holder-inner {
  position: relative;
  overflow: hidden
}

.qode-crossfade-images .qode-cfi-img-holder .qode-cfi-img-holder-inner img {
  vertical-align: middle;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease
}

.qode-crossfade-images .qode-cfi-img-holder .qode-cfi-img-holder-inner .qodef-cfi-image-hover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease
}

.qode-crossfade-images .qode-cfi-title-holder {
  text-align: center;
  margin: 30px 0
}

.qode-crossfade-images .qode-cfi-title-holder .qode-cfi-title {
  text-transform: none;
  font-weight: 400;
  font-size: 19px
}

.qode-crossfade-images:hover .qode-cfi-img-holder {
  -webkit-box-shadow: 0 10px 18px 1px rgba(0, 0, 0, .2);
  -moz-box-shadow: 0 10px 18px 1px rgba(0, 0, 0, .2);
  box-shadow: 0 10px 18px 1px rgba(0, 0, 0, .2)
}

.qode-crossfade-images:hover .qode-cfi-img-holder .qode-cfi-img-holder-inner img {
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  transform: scale(.8);
  opacity: 0
}

.qode-crossfade-images:hover .qode-cfi-img-holder .qode-cfi-img-holder-inner .qodef-cfi-image-hover {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .3s .05s, opacity .3s .05s;
  transition: transform .3s .05s, opacity .3s .05s
}

.qode-cta-section {
  position: relative
}

.qode-cta-section .qode-cta-section-text-wrapper {
  position: absolute;
  display: table;
  table-layout: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 600px;
  height: 600px
}

.qode-cta-section .qode-cta-section-text-wrapper .qode-cta-section-text-wrapper-inner {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%
}

.qode-cta-section .qode-cta-section-text-wrapper .qode-cta-section-title {
  font-size: 46px;
  text-transform: none;
  font-weight: 300
}

.qode-cta-section .qode-cta-section-text-wrapper .qode-cta-section-description-holder {
  padding: 0 20px;
  margin-bottom: 40px
}

.qode-cta-section .qode-cta-section-text-wrapper .qode-cta-section-description {
  font-size: 17px;
  line-height: 1.68em
}

.qode-cta-section.qode-cta-appear-effect img {
  -webkit-transition: -webkit-transform 2s, opacity 2s;
  transition: transform 2s, opacity 2s
}

.no-touchevents .qode-cta-section.qode-cta-appear-effect img {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(1.2) translateY(100px);
  -moz-transform: scale(1.2) translateY(100px);
  transform: scale(1.2) translateY(100px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d
}

.qode-cta-section.qode-cta-appear-effect img.qode-appeared {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1) translateY(0);
  -moz-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  -webkit-animation-name: qodeCTAScaleUp;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-name: qodeCTAScaleUp;
  animation-duration: 15s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-delay: 2s;
  animation-fill-mode: forwards
}

@-webkit-keyframes qodeCTAScaleUp {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1)
  }

  100% {
    -webkit-transform: scale(1.07);
    -moz-transform: scale(1.07);
    transform: scale(1.07)
  }
}

@keyframes qodeCTAScaleUp {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1)
  }

  100% {
    -webkit-transform: scale(1.07);
    -moz-transform: scale(1.07);
    transform: scale(1.07)
  }
}

.qode-cta-section.qode-cta-appear-effect .qode-cta-section-text-wrapper .qode-cta-section-title-holder {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden
}

.qode-cta-section.qode-cta-appear-effect .qode-cta-section-text-wrapper .qode-cta-section-title-holder:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 200%;
  visibility: hidden;
  -webkit-transform: skew(-25deg) translateX(-200%);
  -moz-transform: skew(-25deg) translateX(-200%);
  transform: skew(-25deg) translateX(-200%);
  background: rgba(255, 255, 255, .1);
  background: linear-gradient(to right, rgba(255, 255, 255, .13) 0, rgba(255, 255, 255, .13) 77%, rgba(255, 255, 255, .55) 92%, rgba(255, 255, 255, 0) 100%)
}

.no-touchevents .qode-cta-section.qode-cta-appear-effect .qode-cta-section-text-wrapper {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%) scale(.5) translateY(100px);
  -moz-transform: translate(-50%, -50%) scale(.5) translateY(100px);
  transform: translate(-50%, -50%) scale(.5) translateY(100px);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0, 1), opacity .5s .2s;
  transition: transform 1s cubic-bezier(0, 0, 0, 1), opacity .5s .2s
}

.qode-cta-section.qode-cta-appear-effect .qode-cta-section-text-wrapper.qode-appeared {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1) translateY(0);
  -moz-transform: translate(-50%, -50%) scale(1) translateY(0);
  transform: translate(-50%, -50%) scale(1) translateY(0)
}

.qode-cta-section.qode-cta-appear-effect .qode-cta-section-text-wrapper.qode-appeared .qode-cta-section-title-holder:after {
  visibility: visible;
  -webkit-animation-name: qodeCTABling;
  -webkit-animation-duration: 4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-name: qodeCTABling;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-delay: 1s;
  animation-fill-mode: forwards
}

@-webkit-keyframes qodeCTABling {
  0% {
    left: 0;
    -webkit-transform: skew(-25deg) translateX(-200%);
    -moz-transform: skew(-25deg) translateX(-200%);
    transform: skew(-25deg) translateX(-200%);
    width: 50%
  }

  20% {
    width: 300%
  }

  100% {
    left: 120%;
    -webkit-transform: skew(-25deg) translateX(0);
    -moz-transform: skew(-25deg) translateX(0);
    transform: skew(-25deg) translateX(0);
    width: 10%
  }
}

@keyframes qodeCTABling {
  0% {
    left: 0;
    -webkit-transform: skew(-25deg) translateX(-200%);
    -moz-transform: skew(-25deg) translateX(-200%);
    transform: skew(-25deg) translateX(-200%);
    width: 50%
  }

  20% {
    width: 300%
  }

  100% {
    left: 120%;
    -webkit-transform: skew(-25deg) translateX(0);
    -moz-transform: skew(-25deg) translateX(0);
    transform: skew(-25deg) translateX(0);
    width: 10%
  }
}

.qode-giwt {
  text-align: center
}

.qode-giwt .qode-giwt-inner {
  display: inline-block;
  padding: 45px 20px;
  -webkit-transition: background-color .2s ease, box-shadow .2s ease;
  -moz-transition: background-color .2s ease, box-shadow .2s ease;
  transition: background-color .2s ease, box-shadow .2s ease
}

.qode-accordion-holder .qode-title-holder,
.qode-btn {
  transition: color .15s ease-out, background-color .15s ease-out, border-color .15s ease-out
}

.qode-giwt .qode-giwt-inner:hover {
  background-color: #fff;
  box-shadow: 0 7px 16px rgba(0, 0, 0, .1)
}

.qode-giwt .qode-giwt-inner .qode_icon_element {
  color: #b6b6b6
}

.qode-giwt .qode-giwt-inner .qode-giwt-content-holder {
  margin-top: 30px
}

.qode-giwt .qode-giwt-inner .qode-giwt-content-holder a:hover {
  color: inherit
}

.qode-btn {
  display: inline-block;
  position: relative;
  outline: 0;
  font-style: normal;
  font-weight: 500;
  font-family: inherit;
  padding: 18px 25px;
  font-size: 14px;
  line-height: 1.7em;
  -webkit-transition: color .15s ease-out, background-color .15s ease-out, border-color .15s ease-out;
  transition: color .15s ease-out, background-color .15s ease-out, border-color .15s ease-out;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.qode-btn.qode-btn-simple {
  padding: 0 !important;
  color: #303030;
  background-color: transparent;
  border: 0;
  vertical-align: middle
}

.qode-btn.qode-btn-simple .qode-btn-text {
  display: inline-block;
  vertical-align: middle
}

.qode-btn.qode-btn-simple:not(.qode-btn-custom-hover-bg):hover {
  background-color: transparent !important
}

.qode-btn.qode-btn-simple:not(.qode-btn-custom-border-hover):hover {
  border-color: transparent !important
}

.qode-btn.qode-btn-solid {
  background-color: #fff;
  border: none;
  color: #303030
}

.qode-btn.qode-btn-outline {
  border: 2px solid #000;
  color: #000;
  background-color: transparent
}

.qode-btn.qode-btn-outline:not(.qode-btn-custom-hover-color):hover {
  color: #fff !important
}

.qode-btn.qode-btn-outline:not(.qode-btn-custom-hover-bg):hover {
  background-color: #000 !important
}

.qode-btn.qode-btn-outline:not(.qode-btn-custom-border-hover):hover {
  border-color: #000 !important
}

.qode-btn.qode-btn-small {
  font-size: 10px;
  padding: 5px 15px
}

.qode-btn.qode-btn-large {
  font-size: 14px;
  padding: 12px 28px
}

.qode-btn.qode-btn-huge,
.qode-btn.qode-btn-huge-full-width {
  font-size: 16px;
  padding: 15px 31px
}

.qode-btn.qode-btn-huge-full-width {
  display: block;
  text-align: center
}

.qode-btn.qode-btn-with-shadow {
  -webkit-box-shadow: 0 4px 16px #e2e2e2;
  -moz-box-shadow: 0 4px 16px #e2e2e2;
  box-shadow: 0 4px 16px #e2e2e2
}

.qode-btn.qode-btn-icon {
  padding: 0;
  height: 64px;
  overflow: hidden;
  vertical-align: middle
}

.qode-btn.qode-btn-icon.qode-btn-simple {
  height: auto
}

.qode-btn.qode-btn-icon.qode-btn-simple .qode-btn-text {
  padding-right: 0;
  padding-left: 0
}

.qode-btn.qode-btn-icon.qode-btn-simple .qode-button-v2-icon-holder {
  padding: 0 0 0 8px;
  font-size: inherit
}

.qode-btn.qode-btn-icon.qode-btn-solid {
  line-height: 64px
}

.qode-btn.qode-btn-icon.qode-btn-solid.qode-btn-icon-square .qode-button-v2-icon-holder {
  width: 64px;
  box-sizing: border-box
}

.qode-btn.qode-btn-icon.qode-btn-outline {
  line-height: 60px
}

.qode-btn.qode-btn-icon.qode-btn-outline.qode-btn-icon-square .qode-button-v2-icon-holder {
  width: 60px;
  box-sizing: border-box
}

.qode-btn.qode-btn-icon .qode-btn-text {
  padding-right: 35px;
  padding-left: 35px;
  display: inline-block;
  vertical-align: top
}

.qode-btn.qode-btn-icon .qode-button-v2-icon-holder {
  font-size: 28px;
  line-height: inherit;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  padding: 0 14px;
  border-left: 1px solid #f3f3f3;
  transform: translateZ(0);
  -webkit-transition: border-color .15s ease-out, background-color .15s ease-out;
  -moz-transition: border-color .15s ease-out, background-color .15s ease-out;
  transition: border-color .15s ease-out, background-color .15s ease-out
}

.qode-btn.qode-btn-icon .qode-button-v2-icon-holder .qode-button-v2-icon-holder-inner {
  line-height: inherit
}

.qode-btn.qode-btn-icon:hover .qode-button-v2-icon-holder:before {
  -webkit-animation: qode-point 2s infinite ease-out;
  -moz-animation: qode-point 2s infinite ease-out;
  animation: qode-point 2s infinite ease-out
}

.qode-btn.qode-btn-icon.qode-btn-icon-rotate .qode-button-v2-icon-holder {
  position: relative;
  padding: 0;
  overflow: hidden
}

.qode-btn.qode-btn-icon.qode-btn-icon-rotate .qode-button-v2-icon-holder .qode-button-v2-icon-holder-inner {
  position: relative;
  left: 0;
  display: inline-block;
  padding: 0 14px;
  width: inherit;
  box-sizing: border-box;
  -webkit-transition: left .2s ease-out;
  -moz-transition: left .2s ease-out;
  transition: left .2s ease-out
}

.qode-btn.qode-btn-icon.qode-btn-icon-rotate .qode-button-v2-icon-holder:before {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -100%;
  top: 0;
  content: "\24";
  font-family: ElegantIcons;
  overflow: hidden;
  font-weight: 400;
  -webkit-transition: left .2s ease-out;
  -moz-transition: left .2s ease-out;
  transition: left .2s ease-out
}

.qode-btn.qode-btn-icon.qode-btn-icon-rotate:hover .qode-button-v2-icon-holder:before {
  left: 0
}

.qode-btn.qode-btn-icon.qode-btn-icon-rotate:hover .qode-button-v2-icon-holder .qode-button-v2-icon-holder-inner {
  left: 100%
}

button.qode-btn,
input[type=submit].qode-btn {
  cursor: pointer
}

.qode-3d-button-holder {
  display: inline-block;
  position: relative;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -o-perspective: 1000px;
  -ms-perspective: 1000px;
  perspective: 1000px;
  opacity: 0
}

.qode-3d-button-holder .qode-btn.qode-btn-3d-hover {
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  transition: all .2s ease-out;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  border-radius: 2px;
  visibility: visible
}

.qode-3d-button-holder .qode-btn.qode-btn-3d-hover:last-child {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden
}

.qode-3d-button-holder:hover .qode-btn.qode-btn-3d-hover:first-child {
  visibility: hidden
}

.qode-3d-button-holder:hover .qode-btn.qode-btn-3d-hover:last-child {
  visibility: visible
}

.qode-btn-shadow-hover {
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  transition: all .2s ease-out
}

.qode-btn-shadow-hover:hover {
  -webkit-box-shadow: 4px 8px 16px #dedede;
  -moz-box-shadow: 4px 8px 16px #dedede;
  box-shadow: 4px 8px 16px #dedede
}

.qode-image-with-icon-and-text {
  text-align: center
}

.qode-image-with-icon-and-text .qode-iwiat-image img {
  vertical-align: middle
}

.qode-image-with-icon-and-text .qode-iwiat-image-icon-holder {
  position: relative;
  margin-bottom: 63px
}

.qode-image-with-icon-and-text .qode-iwiat-image-icon-holder .qode-iwiat-icon-holder {
  position: absolute;
  text-align: center;
  left: 0;
  bottom: 0;
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  transform: translateY(50%);
  width: 100%
}

.qode-image-with-icon-and-text .qode-iwiat-text {
  margin-top: 10px
}

.qode-info-card-with-icon {
  text-align: center
}

.qode-info-card-with-icon .qode-icwi-image img {
  vertical-align: middle
}

.qode-info-card-with-icon .qode-icwi-image-icon-holder {
  position: relative
}

.qode-info-card-with-icon .qode-icwi-image-icon-holder .qode-icwi-icon-holder {
  position: absolute;
  text-align: center;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%
}

.qode-info-card-with-icon .qode-icwi-text-holder {
  padding: 32px 20px 37px;
  background-color: #fff
}

.qode-info-card-with-icon .qode-icwi-text {
  margin-top: 20px
}

.qode-info-card .qode-info-card-text,
.qode-int-icon-showcase .qode-showcase-content .qode-showcase-content-inner {
  margin-top: 18px
}

.qode-info-card {
  text-align: center
}

.qode-info-card .qode-info-card-image {
  background-color: #fff
}

.qode-info-card .qode-info-card-image img {
  vertical-align: middle
}

.qode-info-card .qode-info-card-text-holder {
  padding: 32px 40px 59px;
  background-color: #fff
}

.qode-elliptical-slider .qode-elliptical-slide {
  position: relative;
  display: inline-block;
  background-size: cover;
  background-position: center;
  width: 100%;
  vertical-align: middle
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-image-holder-wrapper {
  line-height: 0
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-image-holder {
  display: block
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-image-holder img {
  width: 100%;
  height: auto;
  max-height: 900px
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-content-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: -webkit-linear-gradient(left, #292929 50%, transparent 50%);
  background: linear-gradient(90deg, #292929 50%, transparent 50%)
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-content-holder-inner {
  width: 100%;
  height: 100%;
  display: inline-block
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-content-wrapper {
  height: 100%
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-content-wrapper .qode-elliptical-slide-wrapper-inner {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  display: table;
  position: relative
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-content-wrapper .qode-elliptical-slide-elements-holder {
  display: table-cell;
  vertical-align: middle;
  padding-right: 50px
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-svg-holder {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-svg-holder svg {
  width: auto;
  height: 100%;
  vertical-align: middle;
  position: relative;
  left: 100%;
  left: calc(100% - 2px)
}

.qode-elliptical-slider .qode-elliptical-slide .qode-elliptical-slide-svg-holder svg path {
  fill: #000
}

.qode-elliptical-slider .flex-control-nav.flex-control-paging {
  position: absolute;
  left: 50px;
  top: 50%;
  z-index: 100;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.qode-elliptical-slider .flex-control-nav.flex-control-paging li {
  padding: 3px 0
}

.qode-elliptical-slider .flex-control-nav.flex-control-paging li a {
  background-color: rgba(255, 255, 255, .4);
  border-radius: 100%;
  display: block;
  width: 10px;
  height: 10px;
  text-indent: -9999px
}

.qode-elliptical-slider .flex-control-nav.flex-control-paging li a.flex-active {
  background-color: #fff
}

.qode-pricing-calculator {
  display: table;
  table-layout: fixed;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e6e6e6
}

.qode-pricing-calculator .qode-pricing-calculator-items,
.qode-pricing-calculator .qode-pricing-calculator-text-holder {
  display: table-cell;
  vertical-align: middle
}

.qode-pricing-calculator .qode-pricing-calculator-items {
  width: 62%;
  box-sizing: border-box;
  border-right: 1px solid;
  border-color: inherit
}

.qode-pricing-calculator .qode-pricing-calculator-text-holder {
  width: 38%;
  text-align: center;
  padding: 0 20px;
  box-sizing: border-box
}

.qode-pricing-calculator .qode-pricing-calculator-item {
  display: table;
  width: 100%;
  padding: 19px 15px;
  box-sizing: border-box;
  border-bottom: 1px solid;
  border-color: inherit
}

@media only screen and (min-width: 600px) {
  .qode-pricing-calculator .qode-pricing-calculator-item:last-child {
    border-bottom: none
  }
}

.qode-pricing-calculator .qode-pricing-calculator-switcher-holder,
.qode-pricing-calculator .qode-pricing-calculator-title-holder {
  display: table-cell;
  vertical-align: middle
}

.qode-pricing-calculator .qode-pricing-calculator-switcher-holder {
  width: 1%
}

.qode-pricing-calculator .qode-pricing-calculator-title-holder {
  padding-left: 22px
}

.qode-pricing-calculator .qode-pricing-calculator-total-price-holder {
  font-size: 45px;
  color: #303030
}

.qode-pricing-calculator .qode-pricing-calculator-text {
  margin-bottom: 0;
  padding: 0;
  margin-top: 32px
}

.qode-pricing-calculator .qode-pricing-calculator-subtitle {
  margin-top: 10px;
  margin-bottom: 0
}

.qode-pricing-calculator .qode-pricing-calculator-button-holder {
  display: block;
  margin-top: 52px
}

.qode-pricing-calculator .qode-pricing-calculator-button-holder .qode-qbutton-full-width {
  padding-left: 0 !important;
  padding-right: 0 !important;
  box-sizing: initial !important
}

.qode-pricing-calculator .qode-pricing-calculator-switch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 77px;
  height: 40px
}

.qode-pricing-calculator .qode-pricing-calculator-switch input {
  display: none
}

.qode-pricing-calculator .qode-pricing-calculator-switch .qode-pricing-calculator-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eae8e8;
  box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, .1);
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s;
  border-radius: 34px
}

.qode-pricing-calculator .qode-pricing-calculator-switch .qode-pricing-calculator-slider:before {
  position: absolute;
  content: "";
  height: 34px;
  width: 34px;
  left: 3px;
  bottom: 3px;
  background-color: #fff;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, .1);
  border-radius: 50%;
  -webkit-transition: .4s;
  -moz-transition: .4s;
  transition: .4s
}

.qode-pricing-calculator .qode-pricing-calculator-switch input:checked+.qode-pricing-calculator-slider {
  background-color: #1abc9c
}

.qode-advanced-pricing-table .qode-apt-row:nth-child(odd),
.qode-comparative-features-table .qode-cft-row:nth-child(odd),
.qode-specification-list {
  background-color: #fff
}

.qode-pricing-calculator .qode-pricing-calculator-switch input:checked+.qode-pricing-calculator-slider:before {
  -webkit-transform: translateX(37px);
  -moz-transform: translateX(37px);
  transform: translateX(37px)
}

.qode-specification-list .qode-specification-list-image img {
  vertical-align: middle
}

.qode-specification-list .qode-specification-list-text-holder {
  padding: 28px 34px 34px
}

.qode-specification-list .qode-specification-list-items {
  margin-top: 34px
}

.qode-specification-list .qode-specification-list-item {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid #e6e6e6;
  padding: 4px 0
}

.qode-specification-list .qode-specification-list-item:last-child {
  border-bottom: none
}

.qode-specification-list .qode-specification-list-item .qode-specification-list-item-label,
.qode-specification-list .qode-specification-list-item .qode-specification-list-item-value {
  width: 50%;
  display: table-cell;
  vertical-align: middle
}

.qode-specification-list .qode-specification-list-item .qode-specification-list-item-value {
  text-align: right
}

.qode-comparative-features-table {
  border-collapse: collapse;
  box-sizing: border-box;
  border: 1px solid #e3e3e3
}

.qode-comparative-features-table .qode-cft-row {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px dashed #e3e3e3;
  border-collapse: initial
}

.qode-comparative-features-table .qode-cft-row:last-child {
  border: none
}

.qode-comparative-features-table .qode-cft-row>* {
  display: table-cell;
  vertical-align: middle;
  box-sizing: content-box;
  border-right: 1px solid #e3e3e3
}

.qode-comparative-features-table .qode-cft-footer.qode-cft-row>*,
.qode-comparative-features-table .qode-cft-row>:last-child {
  border: none
}

.qode-comparative-features-table .qode-cft-row:nth-child(even) {
  background-color: #f6f6f6
}

.qode-comparative-features-table .qode-cft-feature-title,
.qode-comparative-features-table .qode-cft-link-holder,
.qode-comparative-features-table .qode-cft-title-holder {
  width: 55%;
  padding: 23px 30px
}

.qode-comparative-features-table .qode-cft-column-link-holder,
.qode-comparative-features-table .qode-cft-column-title-holder,
.qode-comparative-features-table .qode-cft-feature-value {
  width: 15%;
  text-align: center;
  padding: 23px 0
}

.qode-comparative-features-table.qode-cft-two-columns .qode-cft-feature-title,
.qode-comparative-features-table.qode-cft-two-columns .qode-cft-link-holder,
.qode-comparative-features-table.qode-cft-two-columns .qode-cft-title-holder {
  width: 60%;
  padding: 23px 30px
}

.qode-comparative-features-table.qode-cft-two-columns .qode-cft-column-link-holder,
.qode-comparative-features-table.qode-cft-two-columns .qode-cft-column-title-holder,
.qode-comparative-features-table.qode-cft-two-columns .qode-cft-feature-value {
  width: 20%;
  text-align: center
}

.qode-comparative-features-table.qode-cft-one-column .qode-cft-feature-title,
.qode-comparative-features-table.qode-cft-one-column .qode-cft-link-holder,
.qode-comparative-features-table.qode-cft-one-column .qode-cft-title-holder {
  width: 70%;
  padding: 23px 30px
}

.qode-comparative-features-table.qode-cft-one-column .qode-cft-column-link-holder,
.qode-comparative-features-table.qode-cft-one-column .qode-cft-column-title-holder,
.qode-comparative-features-table.qode-cft-one-column .qode-cft-feature-value {
  width: 30%;
  text-align: center
}

.qode-comparative-features-table .qode-cft-footer {
  table-layout: auto;
  width: 100%
}

.qode-comparative-features-table .qode-cft-column-footer-image {
  padding: 21px 30px
}

.qode-comparative-features-table .qode-cft-column-footer-image img {
  vertical-align: middle
}

.qode-comparative-features-table .qode-cft-column-footer-text {
  text-align: right;
  padding-right: 45px
}

.qode-comparative-features-table .qode-cft-feature-item-title-responsive {
  vertical-align: middle;
  display: none
}

.qode-comparative-features-table .qode-cft-mark {
  font-size: 25px;
  vertical-align: middle
}

.qode-comparative-features-table .qode-cft-mark.qode-cft-active {
  color: #1abc9c
}

.qode-comparative-features-table .qode-cft-mark.qode-cft-inactive {
  color: #a9a9a9
}

.qode-comparative-features-table .qode-cft-link {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #303030
}

.qode-comparative-features-table .qode-cft-link:hover {
  color: #1abc9c
}

.qode-advanced-pricing-table {
  border-collapse: collapse;
  box-sizing: border-box;
  border: 1px solid #e3e3e3
}

.qode-advanced-pricing-table .qode-apt-row {
  display: table;
  width: 100%;
  border-bottom: 1px dashed #e3e3e3;
  border-collapse: initial
}

.qode-advanced-pricing-table .qode-apt-row>* {
  display: table-cell;
  vertical-align: middle;
  box-sizing: border-box
}

.qode-advanced-pricing-table .qode-apt-row:last-child {
  border: none
}

.qode-advanced-pricing-table .qode-apt-row:nth-child(even) {
  background-color: #f6f6f6
}

.qode-advanced-pricing-table .qode-apt-item-title,
.qode-advanced-pricing-table .qode-apt-title-holder {
  padding: 21px 0 21px 30px
}

.qode-advanced-pricing-table .qode-apt-column-title-holder,
.qode-advanced-pricing-table .qode-apt-item-price {
  text-align: right;
  padding-right: 35px
}

.qode-advanced-pricing-table .qode-apt-footer {
  table-layout: auto;
  width: 100%
}

.qode-advanced-pricing-table .qode-apt-column-footer-image {
  padding: 21px 30px
}

.qode-advanced-pricing-table .qode-apt-column-footer-image img {
  vertical-align: middle
}

.qode-advanced-pricing-table .qode-apt-column-footer-text {
  text-align: right;
  padding-right: 30px;
  font-size: 13px
}

.qode-advanced-pricing-list {
  box-sizing: border-box
}

.qode-advanced-pricing-list .qode-apl-title {
  margin-bottom: 30px
}

.qode-advanced-pricing-list .qode-apl-item {
  margin-bottom: 20px
}

.qode-advanced-pricing-list .qode-apl-item:last-child {
  margin-bottom: 0
}

.qode-advanced-pricing-list .qode-apl-item .qode-apl-item-top {
  display: table;
  width: 100%
}

.qode-advanced-pricing-list .qode-apl-item .qode-apl-item-top>* {
  display: table-cell;
  vertical-align: middle;
  box-sizing: border-box
}

.qode-advanced-pricing-list .qode-apl-item .qode-apl-item-top .qode-apl-line {
  height: 3px;
  border-bottom: 1px dotted #828383;
  display: table-cell;
  width: 98%;
  z-index: 1;
  position: relative;
  bottom: 6px
}

.qode-advanced-pricing-list .qode-apl-item .qode-apl-item-top .qode-apl-item-title {
  padding-right: 8px;
  white-space: nowrap
}

.qode-advanced-pricing-list .qode-apl-item .qode-apl-item-top .qode-apl-item-price {
  padding-left: 8px;
  white-space: nowrap
}

.qode-advanced-pricing-list .qode-apt-item-title,
.qode-advanced-pricing-list .qode-apt-title-holder {
  padding: 21px 0 21px 30px
}

.qode-advanced-pricing-list .qode-apt-column-title-holder,
.qode-advanced-pricing-list .qode-apt-item-price {
  text-align: right;
  padding-right: 35px
}

.qode-advanced-pricing-list .qode-apt-footer {
  table-layout: auto;
  width: 100%
}

.qode-advanced-pricing-list .qode-apt-column-footer-image {
  padding: 21px 30px
}

.qode-advanced-pricing-list .qode-apt-column-footer-image img {
  vertical-align: middle
}

.qode-advanced-pricing-list .qode-apt-column-footer-text {
  text-align: right;
  padding-right: 30px;
  font-size: 13px
}

.wpb_gallery_slides .qode-image-slider-description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 25px 0;
  background-color: #181818;
  color: #fff;
  text-align: center
}

.wpb_gallery_slides .qode-image-slider-description p {
  margin: 0;
  padding: 0;
  color: #fff
}

.qode-sliding-image-holder {
  position: relative
}

.qode-sliding-image-holder .qode-sih-image-holder {
  position: absolute;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1
}

.qode-sliding-image-holder .qode-sih-image-holder .qode-sliding-image-background-image {
  position: relative;
  max-width: 360%
}

.qode-sliding-image-holder .qode-sih-image-holder .qode-sliding-image-background-image.qode-aux-background-image {
  position: absolute;
  top: 0;
  left: 360%
}

.qode-advanced-tabs .qode-advanced-tabs-nav {
  display: table;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  vertical-align: middle;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.qode-advanced-tabs .qode-advanced-tabs-nav li {
  margin: 0;
  background-color: #1abc9c
}

.qode-advanced-tabs .qode-advanced-tabs-nav li a {
  position: relative;
  display: table;
  width: 100%;
  padding: 20px 28px;
  color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  -moz-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

.qode-advanced-tabs .qode-advanced-tabs-nav li a i,
.qode-advanced-tabs .qode-advanced-tabs-nav li a span {
  -webkit-transition: initial;
  -moz-transition: initial;
  transition: initial
}

.qode-advanced-tabs .qode-advanced-tabs-nav li a .qode-advanced-icon-frame {
  display: table-cell;
  width: 20px;
  font-size: 31px;
  line-height: 1;
  vertical-align: middle;
  padding-right: 11px
}

.qode-advanced-tabs .qode-advanced-tabs-nav li a .qode-advanced-tab-text-after-icon {
  display: table-cell;
  -webkit-transition: initial;
  -moz-transition: initial;
  transition: initial;
  vertical-align: middle
}

.qode-advanced-tabs .qode-advanced-tab-container {
  position: relative;
  padding: 35px 28px;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.qode-advanced-tabs.qode-advanced-horizontal-tab .qode-advanced-tabs-nav li {
  position: relative;
  display: table-cell;
  vertical-align: middle
}

.qode-advanced-tabs.qode-advanced-horizontal-tab .qode-advanced-tabs-nav li a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(255, 255, 255, .05);
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  transition: opacity .2s
}

.qode-advanced-tabs.qode-advanced-horizontal-tab .qode-advanced-tabs-nav li a span {
  position: relative;
  z-index: 1
}

.qode-advanced-tabs.qode-advanced-horizontal-tab .qode-advanced-tabs-nav li.ui-state-active {
  background-color: #fff
}

.qode-advanced-tabs.qode-advanced-horizontal-tab .qode-advanced-tabs-nav li.ui-state-active a {
  color: #303030
}

.qode-advanced-tabs.qode-advanced-horizontal-tab .qode-advanced-tabs-nav li:not(.ui-state-active).ui-state-hover a:after {
  opacity: 1
}

.qode-advanced-tabs.qode-advanced-horizontal-tab.qode-advanced-tabs-column-1 .qode-advanced-tabs-nav li {
  width: 100%
}

.qode-advanced-tabs.qode-advanced-horizontal-tab.qode-advanced-tabs-column-2 .qode-advanced-tabs-nav li {
  width: 50%
}

.qode-advanced-tabs.qode-advanced-horizontal-tab.qode-advanced-tabs-column-3 .qode-advanced-tabs-nav li {
  width: 33.33333%
}

.qode-advanced-tabs.qode-advanced-horizontal-tab.qode-advanced-tabs-column-4 .qode-advanced-tabs-nav li {
  width: 25%
}

.qode-advanced-tabs.qode-advanced-horizontal-tab.qode-advanced-tabs-column-5 .qode-advanced-tabs-nav li {
  width: 20%
}

.qode-advanced-tabs.qode-advanced-horizontal-tab.qode-advanced-tabs-column-6 .qode-advanced-tabs-nav li {
  width: 16.66667%
}

.qode-advanced-tabs.qode-advanced-horizontal-tab.qode-advanced-tabs-column-7 .qode-advanced-tabs-nav li {
  width: 14.28571%
}

.qode-advanced-tabs.qode-advanced-horizontal-tab.qode-advanced-tabs-column-8 .qode-advanced-tabs-nav li {
  width: 12.5%
}

.qode-advanced-tabs.qode-advanced-horizontal-tab.qode-advanced-tabs-column-9 .qode-advanced-tabs-nav li {
  width: 11.11111%
}

.qode-blog-carousel-titled {
  background-color: #fff;
  opacity: 0;
  -webkit-transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out
}

.qode-blog-carousel-titled .qode-bct-title-holder {
  position: relative;
  display: table;
  width: 100%;
  box-sizing: border-box;
  background-color: #1abc9c;
  border-bottom: 1px solid rgba(0, 0, 0, .07);
  color: #fff;
  padding: 0 56px;
  height: 56px
}

.qode-blog-carousel-titled .qode-bct-title-holder * {
  color: inherit
}

.qode-blog-carousel-titled .qode-bct-title-holder>* {
  display: table-cell;
  vertical-align: middle;
  text-align: center
}

.qode-blog-carousel-titled .qode-bct-title-holder .qode-bct-caroufredsel-nav-holder {
  width: 56px
}

.qode-blog-carousel-titled .qode-bct-title-holder .qode-bct-caroufredsel-next,
.qode-blog-carousel-titled .qode-bct-title-holder .qode-bct-caroufredsel-prev {
  position: absolute;
  width: 56px;
  top: 0;
  height: 100%
}

.qode-blog-carousel-titled .qode-bct-title-holder .qode-bct-caroufredsel-next .qode-bct-caroufredsel-nav-inner,
.qode-blog-carousel-titled .qode-bct-title-holder .qode-bct-caroufredsel-prev .qode-bct-caroufredsel-nav-inner {
  display: table;
  width: 100%;
  height: 100%
}

.qode-blog-carousel-titled .qode-bct-title-holder .qode-bct-caroufredsel-next .qode-bct-caroufredsel-nav-icon-holder,
.qode-blog-carousel-titled .qode-bct-title-holder .qode-bct-caroufredsel-prev .qode-bct-caroufredsel-nav-icon-holder {
  display: table-cell;
  vertical-align: middle;
  font-size: 33px
}

.qode-blog-carousel-titled .qode-bct-title-holder .qode-bct-caroufredsel-prev {
  left: 0;
  border-right: 1px solid rgba(0, 0, 0, .07)
}

.qode-blog-carousel-titled .qode-bct-title-holder .qode-bct-caroufredsel-next {
  right: 0;
  border-left: 1px solid rgba(0, 0, 0, .07)
}

.qode-blog-carousel-titled .qode-bct-posts-holder {
  padding: 32px 17px 44px
}

.qode-blog-carousel-titled .qode-bct-post {
  background-color: transparent;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  float: left;
  padding: 0 17px
}

.qode-blog-carousel-titled .qode-bct-post .qode-bct-post-image img {
  vertical-align: middle
}

.qode-blog-carousel-titled .qode-bct-post .qode-bct-post-title {
  margin-bottom: 14px
}

.qode-blog-carousel-titled .qode-bct-post .qode-bct-post-text {
  padding: 26px 0 0
}

.qode-blog-carousel-titled .qode-bct-post .qode-bct-post-excerpt {
  margin: 0;
  padding: 0
}

.qode-blog-carousel-titled .qode-bct-post .qode-bct-post-date {
  margin-top: 15px;
  color: #1abc9c
}

.qode-numbered-process-holder {
  position: relative
}

.qode-numbered-process-holder .qode-numbered-process-inner {
  font-size: 0
}

.qode-numbered-process-holder .qode-np-item {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  box-sizing: border-box
}

.qode-numbered-process-holder .qode-np-title {
  margin-top: 33px
}

.qode-numbered-process-holder .qode-np-item-image-holder {
  position: relative;
  height: 0;
  padding-top: 100%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.qode-numbered-process-holder .qode-np-item-image-holder .qode-np-item-image-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 12px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.qode-numbered-process-holder .qode-np-item-image-holder .qode-np-item-image-table {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed
}

.qode-numbered-process-holder .qode-np-item-image-holder .qode-np-item-image-table-cell {
  display: table-cell;
  width: 100%;
  vertical-align: middle
}

.qode-numbered-process-holder .qode-np-item-image-holder .qode-np-item-image-table-cell img {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  vertical-align: middle
}

.qode-numbered-process-holder .qode-np-item-image-holder .qode-np-item-number {
  position: absolute;
  top: 13%;
  left: 88%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  color: #000;
  background-color: #1abc9c;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.qode-numbered-process-holder.qode-numbered-process-holder-items-five .qode-np-item:nth-child(5) .qode-np-line,
.qode-numbered-process-holder.qode-numbered-process-holder-items-four .qode-np-item:nth-child(4) .qode-np-line,
.qode-numbered-process-holder.qode-numbered-process-holder-items-three .qode-np-item:nth-child(3) .qode-np-line {
  border: none
}

.qode-numbered-process-holder .qode-np-item-image-holder .qode-np-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65%;
  color: #fff;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.qode-numbered-process-holder.qode-numbered-process-holder-items-three .qode-np-item {
  width: 33.33%
}

.qode-numbered-process-holder.qode-numbered-process-holder-items-four .qode-np-item {
  width: 25%
}

.qode-numbered-process-holder.qode-numbered-process-holder-items-five .qode-np-item {
  width: 20%
}

.qode-numbered-process-holder .qode-np-line {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 100%;
  height: 0;
  border-bottom-color: #c3c3c3;
  border-bottom-width: 1px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.qode-numbered-process-holder.qodef-np-padding-small .qode-numbered-process-inner {
  margin: 0 -30px
}

.qode-numbered-process-holder.qodef-np-padding-small .qode-np-item {
  padding: 0 30px
}

.qode-numbered-process-holder.qodef-np-padding-small .qode-np-line {
  width: 60px
}

.qode-numbered-process-holder.qodef-np-padding-medium .qode-numbered-process-inner {
  margin: 0 -50px
}

.qode-numbered-process-holder.qodef-np-padding-medium .qode-np-item {
  padding: 0 50px
}

.qode-numbered-process-holder.qodef-np-padding-medium .qode-np-line {
  width: 100px
}

.qode-numbered-process-holder.qodef-np-padding-large .qode-numbered-process-inner {
  margin: 0 -70px
}

.qode-numbered-process-holder.qodef-np-padding-large .qode-np-item {
  padding: 0 70px
}

.qode-numbered-process-holder.qodef-np-padding-large .qode-np-line {
  width: 140px
}

.qode-numbered-process-holder.qode-np-line-solid .qode-np-line {
  border-bottom-style: solid
}

.qode-numbered-process-holder.qode-np-line-dashed .qode-np-line {
  border-bottom-style: dashed
}

.qode-numbered-process-holder.qode-np-line-skin-dark .qode-np-line {
  border-bottom-color: #000
}

.qode-numbered-process-holder.qode-np-line-skin-light .qode-np-line {
  border-bottom-color: #fff
}

.qode-accordion-holder {
  position: relative
}

.qode-accordion-holder .qode-accordion-content {
  margin: 0;
  padding: 20px;
  background-position: center;
  background-size: cover
}

.qode-accordion-holder .qode-accordion-content.qode-acc-title-with-icon {
  padding-left: 60px
}

.qode-accordion-holder .qode-title-holder {
  cursor: pointer;
  position: relative;
  padding: 0;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px solid #f4f4f4;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: color .15s ease-out, background-color .15s ease-out, border-color .15s ease-out;
  transition: color .15s ease-out, background-color .15s ease-out, border-color .15s ease-out
}

.qode-accordion-holder .qode-title-holder.ui-state-active,
.qode-accordion-holder .qode-title-holder:last-of-type {
  border-bottom-color: transparent
}

.qode-accordion-holder .qode-title-holder .qode-tab-title,
.qode-accordion-holder .qode-title-holder .qode-tab-title-inner {
  color: inherit;
  line-height: inherit
}

.qode-accordion-holder .qode-title-holder .qode-tab-title {
  display: inline-table;
  height: 66px;
  width: calc(100% - 41px);
  box-sizing: border-box;
  text-align: left;
  table-layout: fixed
}

.qode-accordion-holder .qode-title-holder .qode-tab-title span.qode-tab-title-inner {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  padding: 5px 0
}

.qode-accordion-holder .qode-title-holder .qode-tab-title span.qode-tab-title-inner:only-child {
  padding-left: 20px;
  width: 100%
}

.qode-accordion-holder .qode-title-holder .qode-tab-title .qode-tab-title-icon {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 60px;
  font-size: 26px;
  padding: 0 10px;
  box-sizing: border-box
}

.qode-accordion-holder .qode-title-holder .qode-accordion-mark {
  position: absolute;
  top: 0;
  right: 6px;
  display: inline-block;
  vertical-align: top;
  width: 30px;
  height: 100%;
  color: inherit;
  box-sizing: border-box
}

.qode-accordion-holder .qode-title-holder .qode-accordion-mark-icon {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 21px;
  line-height: inherit;
  text-align: center
}

.qode-accordion-holder .qode-title-holder .qode-accordion-mark-icon span {
  position: absolute;
  display: block;
  top: 50%;
  width: 100%;
  line-height: inherit;
  margin-left: -1px;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  transition: opacity .2s;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.qode-accordion-holder .qode-title-holder .qode-accordion-mark-icon span:last-child {
  opacity: 0
}

.qode-accordion-holder .qode-title-holder.ui-state-active,
.qode-accordion-holder .qode-title-holder.ui-state-hover {
  color: #fff;
  background-color: #1abc9c
}

.qode-advanced-image-gallery .owl-nav .owl-next:hover .qode-next-icon,
.qode-advanced-image-gallery .owl-nav .owl-next:hover .qode-prev-icon,
.qode-advanced-image-gallery .owl-nav .owl-prev:hover .qode-next-icon,
.qode-advanced-image-gallery .owl-nav .owl-prev:hover .qode-prev-icon,
.qode-showcase-icon .qode-icon-holder.qode-icon-circle .qode-icon-element {
  color: #1abc9c
}

.qode-accordion-holder .qode-title-holder.ui-state-active .qode-accordion-mark-icon span:first-child {
  opacity: 0
}

.qode-accordion-holder .qode-title-holder.ui-state-active .qode-accordion-mark-icon span:last-child {
  opacity: 1
}

.qode-int-icon-showcase {
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative
}

.qode-int-icon-showcase .qode-int-icon-showcase-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  margin: 50px
}

.qode-int-icon-showcase .qode-int-icon-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 100px);
  height: calc(100% - 100px);
  margin: 50px;
  background-color: transparent;
  border: 2px solid #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all .85s cubic-bezier(.18, .89, .32, 1.28);
  -moz-transition: all .85s cubic-bezier(.18, .89, .32, 1.28);
  transition: all .85s cubic-bezier(.18, .89, .32, 1.28);
  -webkit-transform: scale(.1, .1);
  -moz-transform: scale(.1, .1);
  transform: scale(.1, .1)
}

.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-int-icon-circle {
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1)
}

.qode-int-icon-showcase.qode-appeared .qode-int-icon-circle {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1)
}

.qode-int-icon-showcase .qode-showcase-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  text-align: center;
  -webkit-transition: opacity .3s ease-out;
  -moz-transition: opacity .3s ease-out;
  transition: opacity .3s ease-out
}

.qode-int-icon-showcase .qode-showcase-content .qode-section-subtitle {
  text-transform: capitalize
}

.qode-int-icon-showcase .qode-showcase-content-table {
  display: table;
  height: 100%;
  width: 100%;
  table-layout: fixed
}

.qode-int-icon-showcase .qode-showcase-content-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20%
}

.qode-showcase-icon {
  position: absolute;
  z-index: 10;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translate(-50%, -50%) scale(1.01);
  -moz-transform: translate(-50%, -50%) scale(1.01);
  transform: translate(-50%, -50%) scale(1.01)
}

.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-icon {
  -webkit-transition: opacity .2s ease !important;
  transition: opacity .2s ease !important;
  -webkit-transition-delay: 0s !important;
  transition-delay: 0s !important
}

.qode-showcase-icon .qode-icon-holder.qode-icon-circle {
  background-color: #fff
}

.qode-showcase-icon .qode-icon-holder {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 38px
}

.qode-showcase-item-holder.qode-showcase-active .qode-icon-holder.qode-icon-circle {
  background-color: #1abc9c !important
}

.qode-showcase-item-holder.qode-showcase-active .qode-icon-holder.qode-icon-circle .qode-icon-element {
  color: #fff
}

.qode-showcase-item-holder.qode-showcase-active .qode-showcase-content {
  opacity: 1
}

.qode-showcase-item-holder .qode-showcase-icon {
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity .2s ease, top .5s cubic-bezier(.18, .89, .32, 1), left .5s cubic-bezier(.18, .89, .32, 1), -webkit-transform .5s ease;
  transition: opacity .2s ease, top .5s cubic-bezier(.18, .89, .32, 1), left .5s cubic-bezier(.18, .89, .32, 1), transform .5s ease
}

.qode-int-icon-showcase.qode-appeared .qode-showcase-item-holder .qode-showcase-icon,
.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-item-holder .qode-showcase-icon {
  opacity: 1
}

.qode-int-icon-showcase.qode-appeared .qode-showcase-item-holder:first-child .qode-showcase-icon,
.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-item-holder:first-child .qode-showcase-icon {
  top: 0;
  left: 50%;
  -webkit-transition-delay: .65s;
  transition-delay: .65s
}

.qode-int-icon-showcase.qode-appeared .qode-showcase-item-holder:nth-child(2) .qode-showcase-icon,
.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-item-holder:nth-child(2) .qode-showcase-icon {
  top: 14.6%;
  left: 85.4%;
  -webkit-transition-delay: .85s;
  transition-delay: .85s
}

.qode-int-icon-showcase.qode-appeared .qode-showcase-item-holder:nth-child(3) .qode-showcase-icon,
.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-item-holder:nth-child(3) .qode-showcase-icon {
  top: 50%;
  left: 100%;
  -webkit-transition-delay: 1.05s;
  transition-delay: 1.05s
}

.qode-int-icon-showcase.qode-appeared .qode-showcase-item-holder:nth-child(4) .qode-showcase-icon,
.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-item-holder:nth-child(4) .qode-showcase-icon {
  top: 85.4%;
  left: 85.4%;
  -webkit-transition-delay: 1.25s;
  transition-delay: 1.25s
}

.qode-int-icon-showcase.qode-appeared .qode-showcase-item-holder:nth-child(5) .qode-showcase-icon,
.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-item-holder:nth-child(5) .qode-showcase-icon {
  top: 100%;
  left: 50%;
  -webkit-transition-delay: 1.45s;
  transition-delay: 1.45s
}

.qode-int-icon-showcase.qode-appeared .qode-showcase-item-holder:nth-child(6) .qode-showcase-icon,
.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-item-holder:nth-child(6) .qode-showcase-icon {
  top: 85.4%;
  left: 14.6%;
  -webkit-transition-delay: 1.65s;
  transition-delay: 1.65s
}

.qode-int-icon-showcase.qode-appeared .qode-showcase-item-holder:nth-child(7) .qode-showcase-icon,
.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-item-holder:nth-child(7) .qode-showcase-icon {
  top: 50%;
  left: 0;
  -webkit-transition-delay: 1.85s;
  transition-delay: 1.85s
}

.qode-int-icon-showcase.qode-appeared .qode-showcase-item-holder:nth-child(8) .qode-showcase-icon,
.touchevents .no_animation_on_touch .qode-int-icon-showcase .qode-showcase-item-holder:nth-child(8) .qode-showcase-icon {
  top: 14.6%;
  left: 14.6%;
  -webkit-transition-delay: 2.05s;
  transition-delay: 2.05s
}

.qode-workflow {
  margin-top: 50px;
  position: relative
}

.qode-workflow .main-line {
  background: #fff;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  right: 50%;
  top: 0;
  height: 100%;
  width: 2px
}

.qode-workflow .qode-workflow-item {
  margin-left: auto;
  margin-right: auto;
  max-width: 75%;
  position: relative;
  padding-bottom: 50px;
  overflow: hidden
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: middle
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-image,
.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-text {
  float: left;
  margin: 0;
  width: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-image {
  text-align: center
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-image.left {
  padding: 0 60px;
  text-align: left
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-image.right {
  padding: 0 60px;
  text-align: right
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-text {
  padding: 0 60px
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-text h4 {
  margin-top: 0;
  margin-bottom: 0
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-text p.text {
  margin-top: 25px
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-text .circle {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid transparent;
  border-radius: 50%;
  content: "";
  height: 18px;
  left: 50%;
  margin: 0 0 0 -9px;
  position: absolute;
  top: 0;
  width: 18px
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner .qode-workflow-subtitle {
  margin-top: 5px
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner.reverse {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.qode-workflow .qode-workflow-item .qode-workflow-item-inner.reverse .qode-workflow-text {
  text-align: right
}

.qode-workflow .qode-workflow-item .line {
  display: none;
  background-color: #fff;
  height: 100%;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  width: 2px
}

.qode-workflow .qode-workflow-item:first-of-type .line {
  display: block;
  top: 4px;
  transform: translateY(-100%)
}

.qode-workflow .qode-workflow-item:last-of-type .line {
  display: block;
  top: 24px
}

.qode-workflow.qode-workflow-animate {
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
  -webkit-transition: opacity .55s cubic-bezier(.23, 1, .32, 1), -webkit-transform .55s cubic-bezier(.23, 1, .32, 1);
  transition: opacity .55s cubic-bezier(.23, 1, .32, 1), transform .55s cubic-bezier(.23, 1, .32, 1)
}

.qode-workflow.qode-workflow-animate .main-line {
  opacity: 0;
  height: 0;
  -webkit-transition: opacity .55s cubic-bezier(.23, 1, .32, 1), height 1.8s ease-out;
  -moz-transition: opacity .55s cubic-bezier(.23, 1, .32, 1), height 1.8s ease-out;
  transition: opacity .55s cubic-bezier(.23, 1, .32, 1), height 1.8s ease-out
}

.qode-workflow.qode-workflow-animate .circle {
  -webkit-transform: scale(.2);
  -moz-transform: scale(.2);
  transform: scale(.2);
  -webkit-transition: -webkit-transform .6s cubic-bezier(.18, .89, .32, 1.68) .5s;
  transition: transform .6s cubic-bezier(.18, .89, .32, 1.68) .5s
}

.qode-workflow.qode-workflow-animate .qode-workflow-item .qode-workflow-item-inner .qode-workflow-image {
  opacity: 0;
  -webkit-transform: scale(.6);
  -moz-transform: scale(.6);
  transform: scale(.6);
  -webkit-transition: -webkit-transform .6s cubic-bezier(.18, .89, .32, 1.28), opacity .3s ease-out;
  transition: transform .6s cubic-bezier(.18, .89, .32, 1.28), opacity .3s ease-out
}

.qode-workflow.qode-workflow-animate .qode-workflow-item .qode-workflow-item-inner .qode-workflow-text h4,
.qode-workflow.qode-workflow-animate .qode-workflow-item .qode-workflow-item-inner .qode-workflow-text p {
  opacity: 0;
  -webkit-transition: opacity .5s cubic-bezier(.22, .61, .36, 1) .2s;
  -moz-transition: opacity .5s cubic-bezier(.22, .61, .36, 1) .2s;
  transition: opacity .5s cubic-bezier(.22, .61, .36, 1) .2s
}

.qode-workflow.qode-workflow-animate.qode-appeared {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
  opacity: 1
}

.qode-workflow.qode-workflow-animate.qode-appeared .main-line {
  opacity: 1;
  height: 100%
}

.qode-workflow.qode-workflow-animate.qode-appeared .qode-workflow-item.qode-appeared .qode-workflow-image {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1)
}

.qode-workflow.qode-workflow-animate.qode-appeared .qode-workflow-item.qode-appeared .qode-workflow-text h4,
.qode-workflow.qode-workflow-animate.qode-appeared .qode-workflow-item.qode-appeared .qode-workflow-text p {
  opacity: 1
}

.qode-workflow.qode-workflow-animate.qode-appeared .circle {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1)
}

.qode-advanced-call-to-action {
  position: relative;
  display: table;
  width: 100%;
  height: 110px;
  text-align: center
}

.qode-advanced-call-to-action .advanced-cta-link {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100
}

.qode-advanced-call-to-action .qode-advanced-cta-content {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 20
}

.qode-advanced-call-to-action .qode-advanced-cta-content .qode-advanced-cta-text-holder {
  display: inline-block;
  vertical-align: middle
}

.qode-advanced-call-to-action .qode-advanced-cta-text-holder {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  line-height: 1
}

.qode-advanced-call-to-action .qode-advanced-cta-icon-holder {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 17px;
  color: #fff;
  font-size: 27px
}

.qode-advanced-call-to-action .qode-advanced-cta-icon-holder .qode-advanced-cta-icon-holder-table {
  display: table;
  height: 100%;
  width: 100%
}

.qode-advanced-call-to-action .qode-advanced-cta-icon-holder .qode-advanced-cta-icon-holder-cell {
  position: relative;
  display: table-cell;
  vertical-align: middle
}

.qode-advanced-call-to-action.qode-advanced-cta-icon-circle .qode-advanced-cta-icon-holder {
  height: 46px;
  width: 46px
}

.qode-advanced-call-to-action.qode-advanced-cta-icon-circle .qode-advanced-cta-icon-border {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  border: 2px solid currentColor;
  border-radius: 50%;
  -webkit-transition: -webkit-transform .25s cubic-bezier(.11, .76, .83, 1.62);
  transition: transform .25s cubic-bezier(.11, .76, .83, 1.62)
}

.qode-advanced-call-to-action.qode-advanced-cta-icon-circle:hover .qode-advanced-cta-icon-border {
  -webkit-transform: scale(1.12);
  -moz-transform: scale(1.12);
  transform: scale(1.12)
}

.qode-advanced-call-to-action.qode-advanced-cta-gradient .qode-advanced-cta-background-holder {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden
}

.qode-advanced-call-to-action.qode-advanced-cta-gradient .qode-advanced-cta-background-1,
.qode-advanced-call-to-action.qode-advanced-cta-gradient .qode-advanced-cta-background-2 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1
}

@keyframes qodeFlowAnimation2 {

  0%,
  100% {
    opacity: 0
  }

  50% {
    opacity: 1
  }
}

.qode-advanced-call-to-action.qode-advanced-cta-gradient-animation .qode-advanced-cta-background-2 {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-animation: qodeFlowAnimation2 4s ease infinite;
  -moz-animation: qodeFlowAnimation2 4s ease infinite;
  animation: qodeFlowAnimation2 4s ease infinite
}

@keyframes qodeFlowAnimation3 {

  0%,
  100% {
    opacity: 0
  }

  50% {
    opacity: .35
  }
}

.qode-advanced-call-to-action.qode-advanced-cta-gradient-animation .qode-advanced-cta-background-3 {
  opacity: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-animation: qodeFlowAnimation3 2s ease infinite 2s;
  -moz-animation: qodeFlowAnimation3 2s ease infinite 2s;
  animation: qodeFlowAnimation3 2s ease infinite 2s;
  background-color: #1abc9c
}

.qode-advanced-call-to-action:not(.qode-advanced-cta-gradient-animation) .advanced-cta-link:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(255, 255, 255, .03);
  opacity: 0;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  transition: opacity .2s
}

.qode-advanced-call-to-action:not(.qode-advanced-cta-gradient-animation):hover .advanced-cta-link:after {
  opacity: 1
}

.qode-scrolling-image-holder {
  text-align: center;
  visibility: hidden
}

.qode-scrolling-image-holder .qode-si-title-holder {
  margin-bottom: 65px
}

.qode-scrolling-image-holder .qode-si-content-holder {
  position: relative;
  margin-bottom: 48px
}

.qode-scrolling-image-holder .qode-si-content-holder a {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100
}

.qode-scrolling-image-holder .qode-si-content-holder:hover .qode-si-icon-holder .qode-si-icon-background {
  -webkit-transition: all .3s cubic-bezier(.42, 0, 0, 2.38);
  -moz-transition: all .3s cubic-bezier(.42, 0, 0, 2.38);
  transition: all .3s cubic-bezier(.42, 0, 0, 2.38);
  -webkit-transform: scale(1.06);
  -moz-transform: scale(1.06);
  transform: scale(1.06)
}

.qode-scrolling-image-holder .qode-si-image-holder {
  position: relative;
  overflow: hidden
}

.qode-scrolling-image-holder .qode-si-image-holder .qode-si-image-holder-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}

.qode-scrolling-image-holder .qode-si-image-holder .qode-si-image {
  width: 100%;
  min-height: 100%;
  -webkit-transition-timing-function: cubic-bezier(.49, .13, .16, .96);
  transition-timing-function: cubic-bezier(.49, .13, .16, .96);
  will-change: transform;
  -webkit-filter: blur();
  filter: blur()
}

.qode-scrolling-image-holder .qode-si-image-holder .qode-si-frame {
  vertical-align: middle
}

.qode-scrolling-image-holder .qode-si-icon-holder {
  position: absolute;
  height: 96px;
  width: 96px;
  left: 50%;
  bottom: -48px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%
}

.qode-scrolling-image-holder .qode-si-icon-holder .qode-si-icon-table {
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
  z-index: 10
}

.qode-scrolling-image-holder .qode-si-icon-holder .qode-si-icon-table .qode-si-icon-cell {
  display: table-cell;
  vertical-align: middle
}

.qode-scrolling-image-holder .qode-si-icon-holder .qode-si-icon {
  font-size: 45px;
  color: #fff
}

.qode-scrolling-image-holder .qode-si-icon-holder .qode-si-icon-background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #1abc9c;
  border-radius: 50%;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s;
  will-change: transform
}

.qode-scrolling-image-holder.qode-si-box-shadow-yes .qode-si-icon-holder,
.qode-scrolling-image-holder.qode-si-box-shadow-yes .qode-si-image-holder {
  -webkit-transition: box-shadow .2s;
  -moz-transition: box-shadow .2s;
  transition: box-shadow .2s;
  box-shadow: 0 9px 38px 0 rgba(0, 0, 0, .13)
}

.qode-scrolling-image-holder.qode-si-box-shadow-yes .qode-si-content-holder:hover .qode-si-icon-holder,
.qode-scrolling-image-holder.qode-si-box-shadow-yes .qode-si-content-holder:hover .qode-si-image-holder {
  box-shadow: 0 12px 48px 0 rgba(0, 0, 0, .17)
}

.qode-scrolling-image-holder.qode-si-rounded-yes .qode-si-image-holder {
  border-radius: 7px
}

.qode-report-sheet {
  position: relative;
  padding: 70px 56px 116px;
  margin-bottom: 31px;
  background-color: #fff
}

.qode-report-sheet .qode-rs-title-holder {
  margin-bottom: 27px
}

.qode-report-sheet.qode-rs-one-column .qode-rs-table .qode-rs-table-content .qode-rs-table-column,
.qode-report-sheet.qode-rs-one-column .qode-rs-table .qode-rs-table-header .qode-rs-table-column {
  width: 50%;
  float: left
}

.qode-report-sheet.qode-rs-one-column .qode-rs-table .qode-rs-table-content .qode-rs-table-column:first-child,
.qode-report-sheet.qode-rs-one-column .qode-rs-table .qode-rs-table-header .qode-rs-table-column:first-child {
  width: 100%
}

.qode-report-sheet.qode-rs-two-columns .qode-rs-table .qode-rs-table-content .qode-rs-table-column,
.qode-report-sheet.qode-rs-two-columns .qode-rs-table .qode-rs-table-header .qode-rs-table-column {
  width: 33.33333%;
  float: left
}

.qode-report-sheet.qode-rs-two-columns .qode-rs-table .qode-rs-table-content .qode-rs-table-column:first-child,
.qode-report-sheet.qode-rs-two-columns .qode-rs-table .qode-rs-table-header .qode-rs-table-column:first-child {
  width: 66.66667%
}

.qode-report-sheet.qode-rs-three-columns .qode-rs-table .qode-rs-table-content .qode-rs-table-column,
.qode-report-sheet.qode-rs-three-columns .qode-rs-table .qode-rs-table-header .qode-rs-table-column {
  width: 25%;
  float: left
}

.qode-report-sheet.qode-rs-three-columns .qode-rs-table .qode-rs-table-content .qode-rs-table-column:first-child,
.qode-report-sheet.qode-rs-three-columns .qode-rs-table .qode-rs-table-header .qode-rs-table-column:first-child {
  width: 50%
}

.qode-report-sheet.qode-rs-four-columns .qode-rs-table .qode-rs-table-content .qode-rs-table-column,
.qode-report-sheet.qode-rs-four-columns .qode-rs-table .qode-rs-table-header .qode-rs-table-column {
  width: 20%;
  float: left
}

.qode-report-sheet.qode-rs-four-columns .qode-rs-table .qode-rs-table-content .qode-rs-table-column:first-child,
.qode-report-sheet.qode-rs-four-columns .qode-rs-table .qode-rs-table-header .qode-rs-table-column:first-child {
  width: 40%
}

.qode-report-sheet.qode-rs-five-columns .qode-rs-table .qode-rs-table-content .qode-rs-table-column,
.qode-report-sheet.qode-rs-five-columns .qode-rs-table .qode-rs-table-header .qode-rs-table-column {
  width: 16.66667%;
  float: left
}

.qode-report-sheet.qode-rs-five-columns .qode-rs-table .qode-rs-table-content .qode-rs-table-column:first-child,
.qode-report-sheet.qode-rs-five-columns .qode-rs-table .qode-rs-table-header .qode-rs-table-column:first-child {
  width: 33.33333%
}

.qode-report-sheet .qode-rs-table .qode-rs-table-header {
  padding: 10px 0;
  border-bottom: 1px solid #1abc9c
}

.qode-report-sheet .qode-rs-table .qode-rs-table-header .qode-rs-column-title-holder .qode-rs-column-title {
  text-transform: none
}

.qode-report-sheet .qode-rs-table .qode-rs-table-content .qode-rs-table-row {
  padding: 11px 0;
  border-bottom: 1px solid #1abc9c
}

.qode-report-sheet .qode-rs-table .qode-rs-table-content .qode-rs-table-row .qode-rs-table-column-title-header {
  display: none
}

.qode-report-sheet .qode-rs-table .qode-rs-table-content .qode-rs-table-row .qode-rs-table-column-title {
  font-size: 16px;
  font-weight: 600;
  color: #28282a
}

.qode-report-sheet .qode-rs-table .qode-rs-table-content .qode-rs-table-row .qode-rs-table-column-subtitle {
  font-size: 12px;
  color: #a0a0a1;
  text-transform: uppercase
}

.qode-report-sheet .qode-rs-button-holder {
  position: absolute;
  right: 0;
  top: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.qode-report-sheet .qode-rs-button-holder a {
  text-transform: none
}

.qode_video_box {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  max-width: 100%
}

.qode_video_box .qode_video_image {
  display: block;
  position: relative;
  overflow: hidden
}

.qode_video_box .qode_video_image::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, .1);
  z-index: 33;
  opacity: 0;
  transition: opacity .3s ease-out;
  -webkit-transition: opacity .3s ease-out
}

.qode_video_box .qode_video_image:hover::after {
  opacity: 1
}

.qode_video_box .qode_video_image:hover img {
  transform: scale(1.05) rotate(.01deg);
  -webkit-transform: scale(1.05) rotate(.01deg)
}

.qode_video_box .qode_video_image:hover .qode_video_box_button {
  background-color: #1abc9c
}

.qode_video_box .qode_video_image:hover .qode_video_box_button_arrow {
  border-left-color: #fff
}

.qode_video_box .qode_video_image img {
  display: block;
  position: relative;
  max-width: 100%;
  z-index: 22;
  -webkit-transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  backface-visibility: hidden
}

.qode_video_box .qode_video_image .qode_video_box_button_holder {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 44
}

.qode_video_box .qode_video_image .qode_video_box_button {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #fff;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: background-color .15s ease-out;
  -webkit-transition: background-color .15s ease-out
}

.qode_video_box .qode_video_image .qode_video_box_button_arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-48%, -50%);
  -webkit-transform: translate(-48%, -50%);
  border-top: 12px solid transparent;
  border-left: 18px solid #303030;
  border-bottom: 12px solid transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.qode_video_box.disabled_hover_overlay .qode_video_image::after {
  display: none
}

.qode-advanced-image-gallery,
.qode-advanced-image-gallery .qode-aig-image {
  display: inline-block;
  position: relative;
  width: 100%;
  vertical-align: middle
}

.qode_video_box.disabled_hover_zoom .qode_video_image:hover img {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none
}

.qode-advanced-image-gallery.qode-has-shadow .qode-aig-image-inner {
  box-shadow: 0 0 4.85px .15px rgba(0, 0, 0, .09)
}

.qode-advanced-image-gallery.qode-has-shadow.qode-aig-carousel-type .owl-stage-outer,
.qode-advanced-image-gallery.qode-has-shadow.qode-aig-slider-type .owl-stage-outer {
  padding: 0 0 20px
}

.qode-advanced-image-gallery.qode-has-shadow.qode-aig-carousel-type .qode-aig-image,
.qode-advanced-image-gallery.qode-has-shadow.qode-aig-slider-type .qode-aig-image {
  box-shadow: 0 0 4.85px .15px rgba(0, 0, 0, .09)
}

.qode-advanced-image-gallery .qode-aig-image {
  box-sizing: border-box
}

.qode-advanced-image-gallery .qode-aig-image a,
.qode-advanced-image-gallery .qode-aig-image img {
  position: relative;
  display: block
}

.qode-advanced-image-gallery .qode-aig-image-inner,
.qode-advanced-image-gallery .qode-aig-slider {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle
}

.qode-advanced-image-gallery .qode-aig-grid .qode-aig-image {
  float: left
}

.qode-advanced-image-gallery .qode-aig-grid.qode-aig-two-columns .qode-aig-image {
  width: 50%
}

.qode-advanced-image-gallery .qode-aig-grid.qode-aig-three-columns .qode-aig-image {
  width: 33.33333%
}

.qode-advanced-image-gallery .qode-aig-grid.qode-aig-four-columns .qode-aig-image {
  width: 25%
}

.qode-advanced-image-gallery .qode-aig-grid.qode-aig-five-columns .qode-aig-image {
  width: 20%
}

.qode-advanced-image-gallery .qode-aig-grid.qode-aig-six-columns .qode-aig-image {
  width: 16.66667%
}

.qode-advanced-image-gallery .qode-aig-masonry {
  opacity: 0;
  -webkit-transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out
}

.qode-advanced-image-gallery .qode-aig-masonry .qode-aig-grid-sizer {
  width: 100%
}

.qode-advanced-image-gallery .qode-aig-masonry .qode-aig-grid-gutter {
  width: 0
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-two-columns .qode-aig-grid-sizer,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-two-columns .qode-aig-image {
  width: 50%
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-two-columns .qode-aig-image.qode-aig-large-masonry-item,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-two-columns .qode-aig-image.qode-aig-large-width-masonry-item {
  width: 100%
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-three-columns .qode-aig-grid-sizer,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-three-columns .qode-aig-image {
  width: 33.33333%
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-three-columns .qode-aig-image.qode-aig-large-masonry-item,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-three-columns .qode-aig-image.qode-aig-large-width-masonry-item {
  width: 66.66667%
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-four-columns .qode-aig-grid-sizer,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-four-columns .qode-aig-image {
  width: 25%
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-four-columns .qode-aig-image.qode-aig-large-masonry-item,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-four-columns .qode-aig-image.qode-aig-large-width-masonry-item {
  width: 50%
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-five-columns .qode-aig-grid-sizer,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-five-columns .qode-aig-image {
  width: 20%
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-five-columns .qode-aig-image.qode-aig-large-masonry-item,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-five-columns .qode-aig-image.qode-aig-large-width-masonry-item {
  width: 40%
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-six-columns .qode-aig-grid-sizer,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-six-columns .qode-aig-image {
  width: 16.66667%
}

.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-six-columns .qode-aig-image.qode-aig-large-masonry-item,
.qode-advanced-image-gallery .qode-aig-masonry.qode-aig-six-columns .qode-aig-image.qode-aig-large-width-masonry-item {
  width: 33.33333%
}

.qode-advanced-image-gallery.qode-image-behavior-custom-link .qode-aig-image a:hover:after {
  opacity: 1
}

.qode-advanced-image-gallery.qode-image-behavior-custom-link .qode-aig-image a:after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: '';
  background-color: rgba(53, 53, 53, .4);
  opacity: 0;
  -webkit-transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out
}

.qode-advanced-image-gallery.qode-image-behavior-lightbox .qode-aig-image a .aig-gallery-hover {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(53, 53, 53, .4);
  opacity: 0;
  -webkit-transition: opacity .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out
}

.qode-advanced-image-gallery.qode-image-behavior-lightbox .qode-aig-image a .aig-gallery-hover span {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  font-size: 40px;
  margin: -20px 0 0 -20px;
  line-height: 40px;
  color: #fff
}

.qode-advanced-image-gallery.qode-image-behavior-lightbox .qode-aig-image a:hover .aig-gallery-hover {
  opacity: 1
}

.touchevents .qode-advanced-image-gallery.qode-image-behavior-zoom .qode-aig-image {
  cursor: pointer
}

.qode-advanced-image-gallery.qode-image-behavior-zoom .qode-aig-image:hover img {
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  transform: scale(1.04)
}

.qode-advanced-image-gallery.qode-image-behavior-zoom .qode-aig-image .qode-aig-image-inner {
  overflow: hidden
}

.qode-advanced-image-gallery.qode-image-behavior-zoom .qode-aig-image img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
  -webkit-transition: -webkit-transform .3s ease-in-out;
  -moz-transition: -moz-transform .3s ease-in-out;
  transition: transform .3s ease-in-out
}

.qode-advanced-image-gallery.qode-image-behavior-grayscale .qode-aig-image {
  overflow: hidden
}

.touchevents .qode-advanced-image-gallery.qode-image-behavior-grayscale .qode-aig-image {
  cursor: pointer
}

.qode-advanced-image-gallery.qode-image-behavior-grayscale .qode-aig-image:hover img {
  -webkit-filter: grayscale(0);
  filter: none
}

.qode-advanced-image-gallery.qode-image-behavior-grayscale .qode-aig-image img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: gray;
  filter: grayscale(100%);
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.qode-advanced-image-gallery .owl-nav.disabled {
  display: none
}

.qode-advanced-image-gallery .owl-nav .owl-next,
.qode-advanced-image-gallery .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  z-index: 3;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%)
}

.qode-advanced-image-gallery .owl-nav .qode-next-icon,
.qode-advanced-image-gallery .owl-nav .qode-prev-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  -webkit-transition: color .2s ease-out;
  -moz-transition: color .2s ease-out;
  transition: color .2s ease-out;
  font-size: 30px;
  width: 54px;
  height: 54px;
  line-height: 54px;
  border: 2px solid #303030;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%
}

.qode-advanced-image-gallery .owl-nav .qode-next-icon:before,
.qode-advanced-image-gallery .owl-nav .qode-prev-icon:before {
  display: block;
  line-height: inherit
}

.qode-advanced-image-gallery .owl-dots {
  text-align: center;
  margin: 16px 0 0
}

.qode-advanced-image-gallery .owl-dots.disabled {
  display: none
}

.qode-advanced-image-gallery .owl-dots .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  vertical-align: middle
}

.qode-advanced-image-gallery .owl-dots .owl-dot span {
  display: inline-block;
  vertical-align: middle;
  width: 9px;
  height: 9px;
  margin: 0 6px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-color: #cfcfcf;
  border: none;
  -webkit-transition: background-color .2s ease-out, border .2s ease-out;
  -moz-transition: background-color .2s ease-out, border .2s ease-out;
  transition: background-color .2s ease-out, border .2s ease-out;
  box-sizing: border-box
}

.qode-advanced-image-gallery .owl-dots .owl-dot.active span,
.qode-advanced-image-gallery .owl-dots .owl-dot:hover span {
  background-color: #303030
}

#qode-multi-device-showcase {
  position: relative;
  height: 100vh;
  width: 100vw;
  z-index: 1000
}

#qode-multi-device-showcase.qode-mds-overflow-hidden {
  overflow: hidden
}

#qode-multi-device-showcase .qode-mds-content-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 35%;
  box-sizing: border-box;
  padding-left: 2.5%
}

#qode-multi-device-showcase .qode-mds-content-holder .qode-mds-title-holder {
  margin-bottom: 10px
}

#qode-multi-device-showcase .qode-mds-content-holder .qode-mds-subtitle-holder {
  margin-bottom: 20px
}

#qode-multi-device-showcase .qode-mds-device-holder {
  position: absolute;
  -webkit-transform: rotate(-37deg);
  -moz-transform: rotate(-37deg);
  transform: rotate(-37deg)
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-laptop-holder {
  width: 35%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-laptop-holder.qode-mds-additional-element {
  top: 33%;
  left: 84%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-tablet-portrait-holder {
  width: 17%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-tablet-portrait-holder.qode-mds-additional-element-1 {
  top: 12%;
  left: 69%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-tablet-portrait-holder.qode-mds-additional-element-2 {
  top: -27%;
  left: 21%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-tablet-portrait-holder.qode-mds-additional-element-3 {
  top: 79%;
  left: 58%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-tablet-landscape-holder {
  width: 20%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-tablet-landscape-holder.qode-mds-additional-element.qode-mds-additional-element-1 {
  top: -12%;
  left: 51%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-tablet-landscape-holder.qode-mds-additional-element.qode-mds-additional-element-2 {
  top: -6%;
  left: 84%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-phone-portrait-holder {
  top: 70%;
  left: 32%;
  width: 10%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-laptop-slider-holder {
  top: 42%;
  left: 38%;
  width: 35%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-tablet-slider-holder {
  top: 5%;
  left: 35%;
  width: 17%
}

#qode-multi-device-showcase .qode-mds-device-holder.qode-mds-phone-slider-holder {
  top: 63%;
  left: 77%;
  width: 10%
}

#qode-multi-device-showcase .qode-mds-slide,
#qode-multi-device-showcase .qode-mds-slide-image,
#qode-multi-device-showcase .qode-mds-slide-link,
#qode-multi-device-showcase .qode-mds-slides-inner {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

#qode-multi-device-showcase .qode-mds-device-frame {
  display: block;
  backface-visibility: hidden
}

#qode-multi-device-showcase .qode-mds-slide {
  pointer-events: none;
  visibility: hidden
}

#qode-multi-device-showcase .qode-mds-slide.qode-active {
  pointer-events: auto;
  visibility: visible
}

#qode-multi-device-showcase .qode-mds-slide.qode-remove {
  visibility: visible
}

#qode-multi-device-showcase .qode-mds-slide-link {
  z-index: 100
}

#qode-multi-device-showcase .qode-mds-slide-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center
}

#qode-multi-device-showcase .qode-mds-device-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ccc
}

#qode-multi-device-showcase .qode-mds-slides-inner {
  background-color: #ccc
}

#qode-multi-device-showcase #qode-mds-laptop-slider {
  position: relative
}

#qode-multi-device-showcase #qode-mds-laptop-slider .qode-mds-laptop-slides {
  position: absolute;
  top: 8.7%;
  left: 16.4%;
  height: 69.1%;
  width: 67.7%;
  overflow: hidden
}

#qode-multi-device-showcase #qode-mds-tablet-slider {
  position: relative
}

#qode-multi-device-showcase #qode-mds-tablet-slider .qode-mds-tablet-slides {
  position: absolute;
  top: 15%;
  left: 15.5%;
  height: 70%;
  width: 66.5%;
  overflow: hidden
}

#qode-multi-device-showcase .qode-mds-phone-portrait-holder .qode-mds-device-holder-inner:before,
#qode-multi-device-showcase .qode-mds-phone-slider-holder .qode-mds-device-holder-inner:before {
  content: '';
  height: 12px;
  /* background-image: url(img/mds-phone-top.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  pointer-events: none
}

#qode-multi-device-showcase .qode-mds-phone-slider-holder .qode-mds-device-holder-inner {
  position: relative;
  z-index: 50
}

#qode-multi-device-showcase .qode-mds-phone-slider-holder .qode-mds-device-holder-inner:before {
  position: absolute;
  width: 70%;
  top: 2.2%;
  left: 8%;
  z-index: 30
}

#qode-multi-device-showcase #qode-mds-phone-slider {
  position: relative
}

#qode-multi-device-showcase #qode-mds-phone-slider .qode-mds-phone-slides {
  position: absolute;
  top: 3%;
  left: 7.7%;
  height: 87.3%;
  width: 72.5%;
  border-radius: 12px;
  overflow: hidden;
  z-index: 10
}

@-webkit-keyframes qodeMDSFadeInActive {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes qodeMDSFadeInActive {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@-webkit-keyframes qodeMDSFadeOutActive {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@keyframes qodeMDSFadeOutActive {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0
  }
}

@-webkit-keyframes qodeMDSSlideInActive {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@keyframes qodeMDSSlideInActive {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

@-webkit-keyframes qodeMDSSlideOutActive {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

@keyframes qodeMDSSlideOutActive {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
  }
}

@-webkit-keyframes qodeMDSPhoneSlideInActive {
  0% {
    -webkit-transform: translate(0, 100%);
    -moz-transform: translate(0, 100%);
    transform: translate(0, 100%)
  }

  100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@keyframes qodeMDSPhoneSlideInActive {
  0% {
    -webkit-transform: translate(0, 100%);
    -moz-transform: translate(0, 100%);
    transform: translate(0, 100%)
  }

  100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0)
  }
}

@-webkit-keyframes qodeMDSPhoneSlideOutActive {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }
}

@keyframes qodeMDSPhoneSlideOutActive {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  100% {
    -webkit-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
  }
}

#qode-multi-device-showcase.qode-mds-animating .qode-mds-laptop-slide.qode-active:not(.qode-mds-no-animation) {
  -webkit-animation: qodeMDSFadeInActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  -moz-animation: qodeMDSFadeInActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  animation: qodeMDSFadeInActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards
}

#qode-multi-device-showcase.qode-mds-animating .qode-mds-laptop-slide.qode-remove {
  -webkit-animation: qodeMDSFadeOutActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  -moz-animation: qodeMDSFadeOutActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  animation: qodeMDSFadeOutActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards
}

#qode-multi-device-showcase.qode-mds-animating .qode-mds-tablet-slide.qode-active:not(.qode-mds-no-animation) {
  -webkit-animation: qodeMDSSlideInActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  -moz-animation: qodeMDSSlideInActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  animation: qodeMDSSlideInActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards
}

#qode-multi-device-showcase.qode-mds-animating .qode-mds-tablet-slide.qode-remove {
  -webkit-animation: qodeMDSSlideOutActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  -moz-animation: qodeMDSSlideOutActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  animation: qodeMDSSlideOutActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards
}

#qode-multi-device-showcase.qode-mds-animating .qode-mds-phone-slide.qode-active:not(.qode-mds-no-animation) {
  -webkit-animation: qodeMDSPhoneSlideInActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  -moz-animation: qodeMDSPhoneSlideInActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  animation: qodeMDSPhoneSlideInActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards
}

#qode-multi-device-showcase.qode-mds-animating .qode-mds-phone-slide.qode-remove {
  -webkit-animation: qodeMDSPhoneSlideOutActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  -moz-animation: qodeMDSPhoneSlideOutActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards;
  animation: qodeMDSPhoneSlideOutActive 1s cubic-bezier(.694, 0, .335, 1) 1 forwards
}

#qode-multi-device-showcase .qode-mds-laptop-image {
  position: absolute;
  top: 8.7%;
  left: 16.5%;
  height: 69%;
  width: 67.5%
}

#qode-multi-device-showcase .qode-mds-tablet-portrait-image {
  position: absolute;
  top: 15%;
  left: 15.5%;
  height: 70%;
  width: 66.5%
}

#qode-multi-device-showcase .qode-mds-phone-portrait-holder .qode-mds-device-holder-inner {
  position: relative
}

#qode-multi-device-showcase .qode-mds-phone-portrait-holder .qode-mds-device-holder-inner:before {
  position: absolute;
  width: 70%;
  top: 2.2%;
  left: 8%;
  z-index: 30
}

#qode-multi-device-showcase .qode-mds-phone-portrait-holder .qode-mds-phone-portrait-image {
  position: absolute;
  top: 3%;
  left: 7.7%;
  height: 87.3%;
  width: 72.5%;
  border-radius: 12px
}

#qode-multi-device-showcase .qode-mds-tablet-landscape-image {
  position: absolute;
  top: 18%;
  left: 15.5%;
  height: 66.8%;
  width: 70%
}

.qode-remove-mds {
  opacity: 0;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  transition: opacity 1s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder {
  visibility: hidden
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder .qode-mds-device-holder-inner {
  opacity: 0;
  -webkit-transform: translate3d(100px, 0, 0);
  -moz-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
  will-change: transform, opacity
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(1) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) 60ms, opacity 1s 60ms;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) 60ms, opacity 1s 60ms
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(2) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) .12s, opacity 1s .12s;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) .12s, opacity 1s .12s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(3) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) .18s, opacity 1s .18s;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) .18s, opacity 1s .18s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(4) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) .24s, opacity 1s .24s;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) .24s, opacity 1s .24s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(5) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) .3s, opacity 1s .3s;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) .3s, opacity 1s .3s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(6) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) .36s, opacity 1s .36s;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) .36s, opacity 1s .36s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(7) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) .42s, opacity 1s .42s;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) .42s, opacity 1s .42s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(8) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) .48s, opacity 1s .48s;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) .48s, opacity 1s .48s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(9) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) .54s, opacity 1s .54s;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) .54s, opacity 1s .54s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder:nth-child(10) .qode-mds-device-holder-inner {
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(.09, .67, .22, .94) .6s, opacity 1s .6s;
  transition: transform 1.2s cubic-bezier(.09, .67, .22, .94) .6s, opacity 1s .6s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-content-holder {
  visibility: hidden
}

#qode-multi-device-showcase.qode-mds-appear-effect.qode-mds-loading .qode-mds-content-holder,
#qode-multi-device-showcase.qode-mds-appear-effect.qode-mds-loading .qode-mds-device-holder,
.touchevents #qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-content-holder,
.touchevents #qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder {
  visibility: visible
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-content-holder>div {
  opacity: 0;
  -webkit-transition: opacity .6s cubic-bezier(.694, 0, .335, 1);
  -moz-transition: opacity .6s cubic-bezier(.694, 0, .335, 1);
  transition: opacity .6s cubic-bezier(.694, 0, .335, 1);
  will-change: opacity
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-subtitle-holder .qode-mds-subtitle-holder-inner {
  -webkit-transition-delay: .3s;
  transition-delay: .3s
}

#qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-button-holder .qode-mds-button-holder-inner {
  -webkit-transition-delay: .6s;
  transition-delay: .6s
}

.touchevents #qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-content-holder>div {
  opacity: 1;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none
}

.touchevents #qode-multi-device-showcase.qode-mds-appear-effect .qode-mds-device-holder .qode-mds-device-holder-inner {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: none;
  -moz-transition: none;
  transition: none
}

#qode-multi-device-showcase.qode-mds-appear-effect #qode-mds-spinner {
  position: absolute;
  top: 50vh;
  left: 50vw;
  display: flex;
  align-items: center;
  justify-content: center
}

#qode-multi-device-showcase.qode-mds-appear-effect #qode-mds-spinner .qode-mds-pulse {
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  background-color: #1abc9c;
  border-radius: 16px;
  -webkit-animation: scaleout 1s infinite cubic-bezier(.09, .67, .22, .94);
  -moz-animation: scaleout 1s infinite cubic-bezier(.09, .67, .22, .94);
  animation: scaleout 1s infinite cubic-bezier(.09, .67, .22, .94)
}

#qode-multi-device-showcase.qode-mds-appear-effect.qode-mds-loading .qode-mds-content-holder.qode-show-item>div {
  opacity: 1
}

#qode-multi-device-showcase.qode-mds-appear-effect.qode-mds-loading .qode-mds-device-holder.qode-show-device .qode-mds-device-holder-inner {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.qode-simple-quote-holder {
  position: relative;
  margin-top: 50px
}

.qode-simple-quote-holder .qode-simple-quote-triangle {
  width: 0;
  height: 0;
  border-bottom: 50px solid #fff;
  border-right: 50px solid transparent;
  z-index: 10;
  position: absolute;
  left: 8%;
  top: -50px
}

.qode-simple-quote-holder .qode-simple-quote-holder-inner {
  display: table;
  width: 100%;
  position: relative;
  background-color: #fff;
  box-sizing: border-box;
  padding: 35px 45px;
  border-radius: 5px
}

.qode-simple-quote-holder .qode-simple-quote-holder-inner .qode-simple-quote-icon-holder {
  display: table-cell;
  width: 165px;
  vertical-align: middle;
  fill: #1abc9c
}

.qode-simple-quote-holder .qode-simple-quote-holder-inner .qode-simple-quote-icon-holder svg g {
  fill: inherit
}

.qode-simple-quote-holder .qode-simple-quote-holder-inner .qode-simple-quote-content-holder {
  display: table-cell;
  vertical-align: middle
}

.qode-simple-quote-holder .qode-simple-quote-holder-inner .qode-simple-quote-content-holder .qode-simple-quote-text-holder {
  margin-bottom: 10px
}

.qode-simple-quote-holder.qode-simple-quote-enabled-shadow .qode-simple-quote-triangle-shadow {
  width: 0;
  height: 0;
  border-bottom: 50px solid rgba(0, 0, 0, .05);
  border-right: 50px solid transparent;
  filter: blur(19px);
  position: absolute;
  left: 8%;
  top: -50px
}

.qode-simple-quote-holder.qode-simple-quote-enabled-shadow .qode-simple-quote-holder-inner {
  -webkit-box-shadow: 0 0 25px 10px rgba(0, 0, 0, .02);
  -moz-box-shadow: 0 0 25px 10px rgba(0, 0, 0, .02);
  box-shadow: 0 0 25px 10px rgba(0, 0, 0, .02)
}

.qode_vertical_separator {
  display: inline-block;
  background-color: #1abc9c
}

.qode-irc-holder {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.qode-irc-holder .qode-irc-holder-inner {
  padding: 42px 67px 57px
}

.qode-irc-holder .qode-irc-title-holder {
  margin-bottom: 30px
}

.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder {
  margin-bottom: 37px;
  text-align: center
}

.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-range-slider-loan-holder-inner,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-range-slider-period-holder-inner,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-range-slider-loan-holder-inner,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-range-slider-period-holder-inner {
  font-size: 18px;
  font-weight: 500;
  color: #3e333f;
  margin-bottom: 10px
}

.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-slider-loan-value,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-slider-period-value,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-slider-loan-value,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-slider-period-value {
  display: inline-block
}

.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-slider-loan-value.irc-min,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-slider-period-value.irc-min,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-slider-loan-value.irc-min,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-slider-period-value.irc-min {
  float: left
}

.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-slider-loan-value.irc-current,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-slider-period-value.irc-current,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-slider-loan-value.irc-current,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-slider-period-value.irc-current {
  color: #1abc9c
}

.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-slider-loan-value.irc-max,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-loan-holder .qode-irc-slider-period-value.irc-max,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-slider-loan-value.irc-max,
.qode-irc-holder .qode-irc-sliders-holder .qode-irc-range-slider-period-holder .qode-irc-slider-period-value.irc-max {
  float: right
}

.qode-irc-holder .qode-irc-sliders-holder .irc-range-slider {
  width: 100%
}

.qode-irc-holder .qode-irc-sliders-holder .rangeslider--horizontal {
  height: 6px;
  background-color: #efeae8;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.qode-irc-holder .qode-irc-sliders-holder .rangeslider--horizontal .rangeslider__fill {
  background-color: #1abc9c;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.qode-irc-holder .qode-irc-sliders-holder .rangeslider--horizontal .rangeslider__handle {
  height: 14px;
  width: 14px;
  top: -4px;
  background-color: #1abc9c;
  background-image: none;
  border: none;
  box-shadow: none
}

.qode-irc-holder .qode-irc-sliders-holder .rangeslider--horizontal .rangeslider__handle:after {
  display: none
}

.qode-irc-holder .qode-irc-content-holder .qode-irc-content-row {
  padding: 17px 0;
  border-bottom: 1px solid #efeae8
}

.qode-irc-holder .qode-irc-content-holder .qode-irc-content-row .qode-irc-label {
  float: left;
  font-size: 19px;
  font-weight: 400
}

.qode-irc-holder .qode-irc-content-holder .qode-irc-content-row .qode-irc-value-holder {
  float: right;
  font-size: 23px;
  color: #1c1c1c
}

.qode-irc-holder .qode-irc-content-holder .qode-irc-content-row.qode-irc-total-row .qode-irc-value-holder {
  color: #1abc9c
}

.qode-irc-holder .qode-irc-button-holder {
  margin-top: 49px
}

.qode-irc-holder .qode-irc-button-holder .qbutton.big_large_full_width {
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important
}

.qode-horizontal-timeline {
  opacity: 0;
  -webkit-transition: opacity .2s;
  -moz-transition: opacity .2s;
  transition: opacity .2s
}

.qode-horizontal-timeline::before {
  content: 'mobile';
  display: none
}

.qode-horizontal-timeline.loaded {
  opacity: 1
}

.qode-horizontal-timeline .qode-timeline {
  position: relative;
  height: 100px;
  margin: 0 auto
}

.qode-horizontal-timeline .qode-events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden
}

.qode-horizontal-timeline .qode-events-wrapper .qode-events ol,
.qode-horizontal-timeline .qode-timeline-navigation {
  margin: 0;
  list-style: none
}

.qode-horizontal-timeline .qode-events-wrapper .qode-events {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 3px;
  background: #e4e4e4;
  -webkit-transition: transform .4s;
  -moz-transition: transform .4s;
  transition: transform .4s
}

.qode-horizontal-timeline .qode-events-wrapper .qode-events .qode-filling-line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  background: #1abc9c;
  -webkit-transition: transform .3s;
  -moz-transition: transform .3s;
  transition: transform .3s
}

.qode-horizontal-timeline .qode-events-wrapper .qode-events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 20px;
  padding-bottom: 19px;
  text-transform: uppercase;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0)
}

.qode-horizontal-timeline .qode-events-wrapper .qode-events a .circle-outer {
  content: '';
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -4px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #1abc9c;
  -webkit-transition: all .25s ease-out;
  -moz-transition: all .25s ease-out;
  transition: all .25s ease-out;
  -webkit-box-shadow: .5px .87px 1px 0 rgba(0, 0, 0, .1);
  -moz-box-shadow: .5px .87px 1px 0 rgba(0, 0, 0, .1);
  box-shadow: .5px .87px 1px 0 rgba(0, 0, 0, .1)
}

.no-touchevents .qode-horizontal-timeline .qode-events-wrapper .qode-events a:hover .circle-outer {
  background-color: #1abc9c
}

.qode-horizontal-timeline .qode-events-wrapper .qode-events a.selected {
  pointer-events: none
}

.qode-horizontal-timeline .qode-events-wrapper .qode-events a.selected .circle-outer {
  -webkit-transform: translateX(-50%) scale(1.2);
  -moz-transform: translateX(-50%) scale(1.2);
  transform: translateX(-50%) scale(1.2);
  background: #1abc9c
}

.qode-horizontal-timeline .qode-timeline-navigation a {
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  line-height: 34px;
  border-radius: 50%;
  color: #dadada;
  text-align: center
}

.qode-horizontal-timeline .qode-timeline-navigation a.qode-prev {
  left: 0;
  color: #1abc9c
}

.qode-horizontal-timeline .qode-timeline-navigation a.qode-prev::after {
  content: "\34";
  display: inline-block;
  font-size: 24px;
  font-family: ElegantIcons
}

.qode-horizontal-timeline .qode-timeline-navigation a.qode-next {
  right: 0;
  color: #1abc9c
}

.qode-horizontal-timeline .qode-timeline-navigation a.qode-next::after {
  content: "\35";
  display: inline-block;
  font-size: 24px;
  font-family: ElegantIcons
}

.qode-horizontal-timeline .qode-timeline-navigation a.inactive {
  cursor: not-allowed
}

.qode-horizontal-timeline .qode-dots {
  height: 3px;
  background-image: radial-gradient(circle closest-side, #d2d2d2 99%, transparent 100%);
  background-position: 50% 70%;
  background-size: 20px 3px;
  background-repeat: repeat-x;
  position: relative;
  width: 100%;
  top: -2px;
  display: none
}

.qode-horizontal-timeline .qode-events-content {
  position: relative;
  width: 100%;
  margin-top: 40px;
  overflow: hidden;
  -webkit-transition: height .4s;
  -moz-transition: height .4s;
  transition: height .4s
}

.qode-horizontal-timeline .qode-events-content>ol {
  list-style: none;
  margin: 0
}

.qode-horizontal-timeline .qode-events-content>ol>li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  -webkit-animation-duration: .4s;
  animation-duration: .4s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out
}

.qode-horizontal-timeline .qode-events-content>ol>li.selected {
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  transform: translateX(0)
}

.qode-horizontal-timeline .qode-events-content>ol>li.qode-enter-right,
.qode-horizontal-timeline .qode-events-content>ol>li.qode-leave-right {
  -webkit-animation: qode-enter-right .4s ease-in-out;
  -moz-animation: qode-enter-right .4s ease-in-out;
  animation: qode-enter-right .4s ease-in-out
}

.qode-horizontal-timeline .qode-events-content>ol>li.qode-enter-left,
.qode-horizontal-timeline .qode-events-content>ol>li.qode-leave-left {
  -webkit-animation: qode-enter-left .4s ease-in-out;
  -moz-animation: qode-enter-left .4s ease-in-out;
  animation: qode-enter-left .4s ease-in-out
}

.qode-horizontal-timeline .qode-events-content>ol>li.qode-leave-left,
.qode-horizontal-timeline .qode-events-content>ol>li.qode-leave-right {
  -webkit-animation-direction: reverse;
  animation-direction: reverse
}

.qode-horizontal-timeline .qode-events-content .qode-hti-content-inner {
  position: relative;
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 6px
}

.qode-horizontal-timeline .qode-events-content .qode-hti-content-inner .qode-hti-content-inner-shadow {
  overflow: hidden
}

.qode-horizontal-timeline .qode-events-content .qode-hti-content-image {
  position: relative;
  display: table-cell;
  height: 100%;
  width: 50%;
  vertical-align: middle;
  text-align: center;
  line-height: 0
}

.qode-horizontal-timeline .qode-events-content .qode-hti-content-value {
  position: relative;
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  width: 50%;
  text-align: left;
  padding: 30px 45px;
  border-left: none
}

@media only screen and (min-width: 1100px) {
  .qode-horizontal-timeline::before {
    content: 'desktop'
  }
}

.qode-horizontal-timeline.qode-timeline-bottom .qode-events-content {
  margin-top: 0;
  margin-bottom: 105px
}

@-webkit-keyframes qode-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes qode-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    transform: translateX(100%)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }
}

@-webkit-keyframes qode-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }
}

@keyframes qode-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%)
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0)
  }
}

.qode-interactive-links.qode-il-links-aside .qode-il-responsive-title-holder {
  display: none
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner {
  display: flex;
  align-items: center
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-titles-holder {
  flex-basis: 0;
  flex: 1 1 0
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-titles-holder .qode-il-link {
  display: block;
  transition: all .3s ease
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-titles-holder .qode-il-link.qode-active {
  opacity: .25
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-images-holder {
  flex-basis: 0;
  flex: 2 1 0
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-images-holder .qode-il-images-holder-inner {
  position: relative
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-images-holder .qode-il-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-images-holder .qode-il-image:first-child {
  position: relative
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-images-holder .qode-il-image.qode-active {
  opacity: 1;
  visibility: visible
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-images-holder .qode-il-image a,
.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-images-holder .qode-il-image img {
  display: block
}

.qode-interactive-links.qode-il-links-aside .qode-il-inner .qode-il-widget-area {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 20
}

.qode-interactive-links.qode-il-links-below {
  height: 100vh
}

.qode-interactive-links.qode-il-links-below .qode-il-responsive-title-holder {
  display: none
}

.qode-interactive-links.qode-il-links-below .qode-il-inner {
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative
}

.qode-interactive-links.qode-il-links-below .qode-il-images-holder {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10
}

.qode-interactive-links.qode-il-links-below .qode-il-images-holder .qode-il-image {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease
}

.qode-interactive-links.qode-il-links-below .qode-il-images-holder .qode-il-image.qode-active {
  opacity: 1;
  visibility: visible
}

.qode-interactive-links.qode-il-links-below .qode-il-images-holder .qode-il-image a,
.qode-interactive-links.qode-il-links-below .qode-il-images-holder .qode-il-image img {
  display: block;
  margin: 0 auto
}

.qode-interactive-links.qode-il-links-below .qode-il-titles-holder {
  position: relative;
  z-index: 20;
  padding-bottom: 73px;
  padding-left: 40px;
  max-width: 50%;
  width: 100%
}

.qode-interactive-links.qode-il-links-below .qode-il-titles-holder .qode-il-link {
  position: relative;
  display: inline-block;
  margin-right: 31px;
  margin-bottom: 20px;
  color: inherit
}

.qode-interactive-links.qode-il-links-below .qode-il-titles-holder .qode-il-link:hover {
  color: inherit
}

.qode-interactive-links.qode-il-links-below .qode-il-titles-holder .qode-il-link .qode-il-title {
  position: relative;
  display: inline-block
}

.qode-interactive-links.qode-il-links-below .qode-il-titles-holder .qode-il-link .qode-il-title:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transition: width .3s ease
}

.qode-interactive-links.qode-il-links-below .qode-il-titles-holder .qode-il-link.qode-active .qode-il-title {
  opacity: 50%
}

.qode-interactive-links.qode-il-links-below .qode-il-widget-area {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 20
}

.qode-interactive-project-list {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  height: 100vh
}

.qode-interactive-project-list .qode-ipl-responsive-title {
  display: none
}

.qode-interactive-project-list .qode-ipl-inner {
  display: table;
  position: relative;
  margin: 0;
  height: 100%;
  width: 100%
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left {
  width: 50%;
  padding: 0 7.8%;
  display: table-cell;
  vertical-align: middle;
  position: relative
}

@media screen and (max-width: 768px) {
  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-left {
    width: 100%
  }
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article {
  width: 100%;
  padding: 0 0 35px;
  transition: all .3s;
  backface-visibility: hidden;
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article.qode-active .qode-ipl-title,
.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article:hover .qode-ipl-title {
  text-stroke: 1px #1abc9c;
  -webkit-text-stroke: 1px #1abc9c;
  -webkit-text-fill-color: transparent
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner {
  display: block
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  z-index: 200
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-category-holder {
  line-height: 1em;
  margin: 15px 0 0
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-category-holder a {
  vertical-align: top;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: .2em;
  color: #1abc9c
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-category-holder a:hover {
  color: inherit
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-category-holder a:not(:last-child):after {
  content: ','
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-item-number-holder {
  position: absolute;
  right: -5px;
  top: -14px
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-item-number-holder span {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border: 1px solid rgba(26, 188, 156, .2);
  text-align: center;
  border-radius: 3em;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #1abc9c
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title {
  position: relative;
  line-height: 1.05em;
  letter-spacing: 0;
  font-size: 120px;
  color: #1abc9c;
  -webkit-transition: .2s cubic-bezier(.2, .84, .5, 1);
  -o-transition: .2s cubic-bezier(.2, .84, .5, 1);
  transition: .2s cubic-bezier(.2, .84, .5, 1);
  text-stroke: 1px #1abc9c;
  -webkit-text-stroke: 1px #1abc9c;
  cursor: pointer
}

@media screen and (max-width: 1680px) {
  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article {
    padding: 0 0 20px
  }

  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title {
    font-size: 70px
  }
}

@media screen and (max-width: 768px) {
  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-item-number-holder {
    top: -25px
  }

  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title {
    font-size: 85px
  }
}

@media screen and (max-width: 480px) {
  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title {
    font-size: 55px
  }
}

@media screen and (max-width: 320px) {
  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title {
    font-size: 50px
  }
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-link {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-pl-item-inner {
  position: relative;
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-image,
.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-text-holder {
  width: 50%;
  display: table-cell;
  height: 100%;
  table-layout: fixed;
  vertical-align: middle
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left article .qode-ipl-image {
  display: none;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-left .qode-ipl-left-widget-area {
  position: absolute;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%)
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-right {
  width: 50%;
  display: table-cell;
  position: relative;
  vertical-align: middle;
  text-align: center;
  background-size: cover;
  background-position: center
}

@media screen and (max-width: 768px) {
  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-right {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    display: none
  }
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-right .qode-ipl-image {
  opacity: 0;
  position: absolute;
  width: 100%;
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s;
  -webkit-transform: translate(0, -52%);
  -moz-transform: translate(0, -52%);
  transform: translate(0, -52%)
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-right .qode-ipl-image:first-child {
  opacity: 1
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-right .qode-ipl-image-outer {
  margin: 0 21.3%
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-right .qode-ipl-image-inner {
  padding: 20px;
  background-color: #fff
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-right img {
  max-height: calc(100vh - 105px - 50px);
  width: auto
}

.qode-interactive-project-list .qode-ipl-inner .qode-ipl-right .qode-ipl-right-widget-area {
  position: absolute;
  bottom: 55px;
  left: 50%;
  transform: translateX(-50%)
}

@media only screen and (max-width: 1024px) {

  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-left-widget-area,
  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-right-widget-area {
    display: none
  }

  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-right .qode-ipl-image-outer {
    margin: 0 13%
  }

  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-right .qode-ipl-image-inner {
    padding: 10px
  }
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left {
  padding: 0 5.2%
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left article {
  padding: 0 0 31px
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title {
  font-size: 37px;
  line-height: 1.35em;
  font-weight: 500;
  text-transform: initial;
  color: #000;
  position: relative;
  display: inline-block;
  text-stroke: initial;
  -webkit-text-stroke: initial
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  transform-origin: 100% 50%;
  transition: transform .3s cubic-bezier(.85, .26, .17, 1);
  transform: scaleX(0)
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-category-holder {
  margin: 0;
  line-height: 1.4em
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-category-holder a {
  font-size: 13px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
  color: #afafaf;
  pointer-events: none
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left article.qode-active .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title,
.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left article:hover .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title {
  text-stroke: initial;
  -webkit-text-fill-color: initial
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left article.qode-active .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title:after,
.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-left article:hover .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title:after {
  transform: scaleX(1);
  transform-origin: 0 50%;
  transition: transform .4s cubic-bezier(.85, .26, .17, 1)
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-right .qode-ipl-image {
  transform: translate(0, -50%)
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-right .qode-ipl-image-outer {
  margin: 0
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-right .qode-ipl-image-inner {
  padding: 0;
  background-color: transparent
}

.qode-interactive-project-list.qode-ipl-layout-2 .qode-ipl-inner .qode-ipl-right img {
  max-height: initial
}

.qode-interactive-project-list.qode-ipl-layout-3 {
  height: auto
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-left {
  width: 100%;
  padding: 0 16.5%
}

@media only screen and (max-width: 1024px) {
  .qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-left {
    display: none
  }
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-left article {
  padding: 0 0 24px
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title {
  font-size: 140px;
  line-height: 1em;
  font-weight: 700;
  text-transform: capitalize;
  color: #000;
  transition: color .3s cubic-bezier(.85, .26, .17, 1);
  text-stroke: initial;
  -webkit-text-stroke: initial
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-category-holder {
  margin: 0;
  line-height: 1em
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-category-holder a {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  color: #000;
  pointer-events: none
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-left article .qode-ipl-item-inner .qode-ipl-category-holder a:before {
  content: '/'
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-left article.qode-active .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title,
.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-left article:hover .qode-ipl-item-inner .qode-ipl-item-text .qode-ipl-title {
  text-stroke: initial;
  -webkit-text-fill-color: initial;
  color: #fff
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center
}

@media only screen and (max-width: 1024px) {
  .qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right {
    position: relative;
    height: auto;
    display: block;
    width: 100%
  }

  .qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right .qode-ipl-image {
    position: relative;
    opacity: 1 !important
  }

  .qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right .qode-ipl-image .qode-ipl-responsive-title {
    display: block;
    position: absolute;
    bottom: 5%;
    left: 5%
  }

  .qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right .qode-ipl-image .qode-ipl-responsive-title .qode-ipl-link {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
  }
}

#qode-intro-section .qode-is-content-btm,
#qode-intro-section .qode-is-title {
  opacity: 0;
  transition: transform 1.2s cubic-bezier(.16, .09, 0, 1.02), opacity .3s
}

@media only screen and (max-width: 768px) {
  .qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right {
    left: 0;
    top: 0
  }
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right .qode-ipl-image {
  transform: none;
  bottom: 0;
  right: 0;
  width: auto
}

@media only screen and (max-width: 1680px) {
  .qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right .qode-ipl-image {
    width: 65%
  }
}

@media only screen and (max-width: 1024px) {
  .qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right .qode-ipl-image {
    width: 100%
  }
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right .qode-ipl-image-outer {
  margin: 0
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right .qode-ipl-image-inner {
  padding: 0;
  background-color: transparent
}

.qode-interactive-project-list.qode-ipl-layout-3 .qode-ipl-inner .qode-ipl-right img {
  max-height: initial;
  display: block
}

@media only screen and (max-width: 1000px) {
  .qode-interactive-project-list {
    height: calc(100vh - 100px)
  }

  .qode-interactive-project-list .qode-ipl-inner .qode-ipl-left {
    padding-bottom: 100px
  }
}

#qode-intro-section {
  display: flex;
  min-height: 100vh;
  overflow: hidden
}

#qode-intro-section .qode-is-content-btm,
#qode-intro-section .qode-is-subtitle,
#qode-intro-section .qode-is-title {
  color: #fff
}

.qode-ms-explorer #qode-intro-section {
  height: 100vh
}

.qode-browser--ms-explorer #qode-intro-section {
  height: 100vh
}

#qode-intro-section .qode-is-content-wrapper {
  position: relative;
  z-index: 10;
  width: 61%;
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center
}

@media only screen and (max-width: 1440px) {
  #qode-intro-section .qode-is-content-wrapper {
    width: 70%
  }
}

#qode-intro-section.qode-alignment--center .qode-is-content-wrapper .qode-is-content {
  text-align: center
}

#qode-intro-section.qode-alignment--left .qode-is-content-wrapper .qode-is-content {
  text-align: left
}

#qode-intro-section.qode-alignment--right .qode-is-content-wrapper .qode-is-content,
.qode-portfolio-project-slider.swiper-container .swiper-pagination {
  text-align: right
}

#qode-intro-section.qode-is-align-bottom {
  align-items: flex-end;
  box-sizing: border-box;
  padding-bottom: 12.4vh
}

#qode-intro-section .qode-is-title {
  display: block;
  line-height: 1.1em;
  font-size: 80px;
  margin: 0;
  z-index: 9;
  background-size: 300% 50%;
  background-position: 200% 50%;
  transform: translateY(30px) skewY(3deg) scaleY(1.2);
  transform-origin: left
}

.qode-browser--ms-explorer #qode-intro-section .qode-is-title {
  background: 0 0 !important
}

@media only screen and (max-width: 480px) {
  #qode-intro-section .qode-is-title {
    font-size: 40px
  }
}

#qode-intro-section .qode-is-content-btm {
  transform: translateY(30px) skewY(3deg) scaleY(1.2);
  transform-origin: top left;
  transition-delay: .1s
}

#qode-intro-section .qode-is-content.qode--appear .qode-is-content-btm,
#qode-intro-section .qode-is-content.qode--appear .qode-is-subtitle,
#qode-intro-section .qode-is-content.qode--appear .qode-is-title {
  opacity: 1;
  transform: translateY(0) skewY(0) scaleY(1)
}

#qode-intro-section .qode-is-subtitle {
  margin: 0;
  opacity: 0;
  transform: translateY(30px) translateZ(0) skewY(3deg) scaleY(1.2);
  transform-origin: top left;
  transition: transform 1s cubic-bezier(.16, .09, 0, 1.02) 50ms, opacity .3s 50ms
}

#qode-intro-section .qode-is-subtitle else {
  transition: transform 1s cubic-bezier(.16, .09, 0, 1.02), opacity .3s
}

#qode-intro-section .qode-is-text {
  margin: 18px 0 0;
  line-height: 1.2em;
  color: inherit
}

#qode-intro-section .qode-is-bg-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .5s cubic-bezier(.39, .575, .565, 1);
  will-change: opacity
}

#qode-intro-section .qode-is-bg-image {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover
}

#qode-intro-section.qode-fade-bg .qode-is-bg-wrapper {
  opacity: 0;
  pointer-events: none
}

.qode-inverted-portfolio-slider .qode-ips-item-inner {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center
}

.qode-inverted-portfolio-slider .qode-ips-content-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative
}

.qode-inverted-portfolio-slider .qode-ips-content-holder .qode-ips-image-holder {
  position: relative;
  z-index: 9999;
  overflow: hidden
}

.qode-inverted-portfolio-slider .qode-ips-info-holder {
  position: absolute;
  left: 60px;
  bottom: 53px
}

.qode-inverted-portfolio-slider .qode-ips-info-holder .portfolio_custom_field {
  display: flex;
  align-items: baseline;
  justify-content: flex-start
}

.qode-inverted-portfolio-slider .qode-ips-info-holder .portfolio_custom_field h6 {
  font-weight: 500;
  color: #000
}

.qode-inverted-portfolio-slider .qode-ips-info-holder .portfolio_custom_field p {
  font-weight: 400;
  color: #000;
  margin-left: 5px
}

.qode-inverted-portfolio-slider .qode-pps-title-link {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  white-space: nowrap;
  font-size: 352px;
  color: #000
}

@media only screen and (max-width: 1441px) {
  .qode-inverted-portfolio-slider .qode-pps-title-link {
    font-size: 290px
  }
}

@media only screen and (max-width: 1366px) {
  .qode-inverted-portfolio-slider .qode-ips-content-holder .qode-ips-image-holder {
    max-width: 85%
  }

  .qode-inverted-portfolio-slider .qode-pps-title-link {
    font-size: 230px
  }
}

@media only screen and (max-width: 1280px) {
  .qode-inverted-portfolio-slider .qode-pps-title-link {
    font-size: 240px
  }
}

@media only screen and (max-width: 1024px) {
  .qode-inverted-portfolio-slider .qode-ips-content-holder .qode-ips-image-holder {
    max-width: 70%
  }

  .qode-inverted-portfolio-slider .qode-pps-title-link {
    font-size: 18vw
  }
}

.qode-inverted-portfolio-slider .qode-pps-title-link.qode-pps-title-link-aux {
  color: #fff
}

.qode-inverted-portfolio-slider .qode-ips-navigation-holder {
  position: absolute;
  z-index: 9999;
  right: 49px;
  bottom: 53px;
  width: auto;
  display: flex
}

@media only screen and (max-width: 480px) {
  .qode-inverted-portfolio-slider .qode-ips-navigation-holder {
    bottom: 20px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    white-space: nowrap
  }
}

.qode-inverted-portfolio-slider .qode-ips-navigation-holder .qode-ips-button-next,
.qode-inverted-portfolio-slider .qode-ips-navigation-holder .qode-ips-button-prev {
  color: #000;
  cursor: pointer
}

.qode-inverted-portfolio-slider .qode-ips-navigation-holder .qode-ips-nav-label {
  vertical-align: middle;
  font-size: 17px
}

.qode-inverted-portfolio-slider .qode-ips-navigation-holder .qode-ips-nav-label.qode-ips-nav-label-prev {
  padding: 0 24px 0 1px
}

.qode-inverted-portfolio-slider .qode-ips-navigation-holder .qode-ips-nav-label.qode-ips-nav-label-next {
  padding: 0 10px 0 24px
}

.qode-inverted-portfolio-slider .qode-ips-navigation-holder .qode-ips-nav-arrow {
  vertical-align: middle;
  font-size: 24px
}

.qode-numbered-carousel {
  position: relative;
  height: 100vh
}

.qode-numbered-carousel .qode-nc-grid {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line {
  position: absolute;
  top: 0;
  height: 100%;
  width: 20%
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  background-color: rgba(173, 164, 146, .4);
  z-index: 10
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #050a1f
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(1) {
  left: 0
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(1):after {
  transition-delay: 70ms
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(2) {
  left: 20%
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(2):after {
  transition-delay: .14s
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(3) {
  left: 40%
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(3):after {
  transition-delay: .21s
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(4) {
  left: 60%
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(4):after {
  transition-delay: .28s
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(5) {
  left: 80%
}

.qode-numbered-carousel .qode-nc-grid .qode-nc-grid-line:nth-child(5):after {
  transition-delay: .35s
}

.qode-numbered-carousel .qode-nc-bg-items {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

.qode-numbered-carousel .qode-nc-bg-items:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(13, 19, 35, .25)
}

.qode-numbered-carousel .qode-nc-bg-items .qode-nc-bg-item {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: hidden
}

.qode-numbered-carousel .qode-nc-bg-items .qode-nc-bg-item.qode-active {
  visibility: visible
}

.qode-numbered-carousel .qode-nc-bg-items .qode-nc-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center
}

.qode-numbered-carousel .qode-nc-bg-items video {
  height: 100%;
  width: 100%;
  object-fit: cover
}

.qode-numbered-carousel .qode-nc-item-subtitle {
  margin: 0 0 10px
}

.qode-numbered-carousel .qode-nc-item-title {
  color: #fff;
  font-size: 69px;
  margin-top: 0
}

.qode-numbered-carousel .qode-nc-item-text-wrapper {
  max-width: 90%;
  margin: 11px 0 0
}

.qode-numbered-carousel .qode-nc-item-text {
  color: #d5d5d5;
  margin-bottom: 40px;
  font-size: 17px;
  font-weight: 300
}

.qode-numbered-carousel .qode-nc-item-number {
  font-size: 20.85vw;
  font-weight: 900;
  color: #fff;
  -webkit-text-stroke: 1px #fff
}

.qode-numbered-carousel .qode-nc-content {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 100
}

.qode-numbered-carousel .swiper-container {
  height: 100%;
  opacity: 0;
  -webkit-transform: translate3d(2%, 0, 0);
  -moz-transform: translate3d(2%, 0, 0);
  transform: translate3d(2%, 0, 0)
}

.qode-numbered-carousel .swiper-container.qode-show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: 1s cubic-bezier(.47, .07, .23, .99) .5s;
  -moz-transition: 1s cubic-bezier(.47, .07, .23, .99) .5s;
  transition: 1s cubic-bezier(.47, .07, .23, .99) .5s
}

.qode-numbered-carousel .qode-nc-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60vw;
  box-sizing: border-box;
  margin: 0 5vw
}

.qode-numbered-carousel .qode-nc-item .qode-nc-item-inner {
  max-width: 60%;
  padding-left: 11px;
  box-sizing: border-box
}

.qode-numbered-carousel .qode-nc-item .qode-nc-item-number-wrapper {
  display: flex;
  align-items: center;
  opacity: 1;
  transform: translateZ(0)
}

.qode-numbered-carousel .qode-nc-item .qode-nc-item-btn {
  border: 2px solid #fff;
  color: #fff
}

.qode-numbered-carousel .qode-nc-item .qode-nc-item-btn:hover {
  border-color: #fff;
  background-color: #fff;
  color: #000
}

.qode-numbered-carousel .qode-nc-item-inner {
  opacity: 0
}

.qode-numbered-carousel .swiper-slide-prev {
  cursor: pointer
}

.qode-numbered-carousel .swiper-slide-prev .qode-nc-item-inner {
  opacity: 0;
  -webkit-transform: translate3d(-5vw, 0, 0);
  -moz-transform: translate3d(-5vw, 0, 0);
  transform: translate3d(-5vw, 0, 0)
}

.qode-numbered-carousel .swiper-slide-prev .qode-nc-item-number {
  color: transparent;
  -webkit-text-stroke: 1px #fff
}

.qode-numbered-carousel .swiper-slide-prev:hover .qode-nc-item-number {
  color: #fff;
  -webkit-text-stroke: 1px #fff
}

.qode-numbered-carousel .swiper-slide-active .qode-nc-item-number-wrapper {
  pointer-events: none;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.qode-numbered-carousel .swiper-slide-active .qode-nc-item-inner {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.qode-numbered-carousel .swiper-slide-next,
.qode-numbered-carousel .swiper-slide-next~div:not(.swiper-slide-active) {
  cursor: pointer
}

.qode-numbered-carousel .swiper-slide-next .qode-nc-item-inner,
.qode-numbered-carousel .swiper-slide-next~div:not(.swiper-slide-active) .qode-nc-item-inner {
  opacity: 0;
  -webkit-transform: translate3d(5vw, 0, 0);
  -moz-transform: translate3d(5vw, 0, 0);
  transform: translate3d(5vw, 0, 0)
}

.qode-numbered-carousel .swiper-slide-next .qode-nc-item-number-wrapper,
.qode-numbered-carousel .swiper-slide-next~div:not(.swiper-slide-active) .qode-nc-item-number-wrapper {
  -webkit-transform: translate3d(-45vw, 0, 0);
  -moz-transform: translate3d(-45vw, 0, 0);
  transform: translate3d(-45vw, 0, 0)
}

.qode-numbered-carousel .swiper-slide-next .qode-nc-item-number,
.qode-numbered-carousel .swiper-slide-next~div:not(.swiper-slide-active) .qode-nc-item-number {
  color: transparent;
  -webkit-text-stroke: 1px #fff
}

.qode-numbered-carousel .swiper-slide-next:hover .qode-nc-item-number,
.qode-numbered-carousel .swiper-slide-next~div:not(.swiper-slide-active):hover .qode-nc-item-number {
  color: #fff;
  -webkit-text-stroke: 1px #fff
}

.qode-numbered-carousel.qode-initialized .qode-nc-grid-line:after {
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transition: -webkit-transform .5s cubic-bezier(.86, 0, .07, 1);
  -moz-transition: -moz-transform .5s cubic-bezier(.86, 0, .07, 1);
  transition: transform .5s cubic-bezier(.86, 0, .07, 1)
}

.qode-numbered-carousel .qode-nc-item-number-wrapper {
  -webkit-transition: -webkit-transform .6s;
  -moz-transition: -moz-transform .6s;
  transition: transform .6s
}

.qode-numbered-carousel .qode-nc-item-number {
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s
}

.qode-numbered-carousel.qode-next .swiper-slide-active .qode-nc-item-inner {
  -webkit-transition: transform 1s cubic-bezier(.075, .82, .165, 1) .4s, opacity 1s cubic-bezier(.075, .82, .165, 1) .4s;
  -moz-transition: transform 1s cubic-bezier(.075, .82, .165, 1) .4s, opacity 1s cubic-bezier(.075, .82, .165, 1) .4s;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1) .4s, opacity 1s cubic-bezier(.075, .82, .165, 1) .4s
}

.qode-numbered-carousel.qode-next .swiper-slide-prev .qode-nc-item-inner {
  -webkit-transition: .5s;
  -moz-transition: .5s;
  transition: .5s
}

.qode-numbered-carousel.qode-next .qode-nc-grid-line:after {
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  transform-origin: 100% 0
}

.qode-numbered-carousel.qode-next.qode-mask .qode-nc-grid-line:after {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0
}

.qode-numbered-carousel.qode-prev .swiper-slide-active .qode-nc-item-inner {
  -webkit-transition: transform 1s cubic-bezier(.075, .82, .165, 1) .4s, opacity 1s cubic-bezier(.075, .82, .165, 1) .4s;
  -moz-transition: transform 1s cubic-bezier(.075, .82, .165, 1) .4s, opacity 1s cubic-bezier(.075, .82, .165, 1) .4s;
  transition: transform 1s cubic-bezier(.075, .82, .165, 1) .4s, opacity 1s cubic-bezier(.075, .82, .165, 1) .4s
}

.qode-numbered-carousel.qode-prev.qode-fade-prev-content .qode-nc-item-inner {
  opacity: 0;
  -webkit-transition: all .4s cubic-bezier(.165, .84, .44, 1) .5s, -webkit-transform 0s .9s;
  transition: all .4s cubic-bezier(.165, .84, .44, 1) .5s, transform 0s .9s
}

.qode-numbered-carousel.qode-prev .qode-nc-grid-line:after {
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  transform-origin: 0 0
}

.qode-numbered-carousel.qode-prev.qode-mask .qode-nc-grid-line:after {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  transform-origin: 100% 0
}

.qode-numbered-carousel .qode-nc-indicators {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  text-align: center;
  z-index: 200
}

.qode-numbered-carousel .qode-nc-indicators>span {
  display: inline-block;
  position: relative;
  height: 36px;
  width: 17px;
  vertical-align: bottom;
  margin: 0 5px
}

.qode-numbered-carousel .qode-nc-indicators>span:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 13px;
  width: 1px;
  height: 100%;
  background-color: #fff;
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(.55);
  -moz-transform: scaleY(.55);
  transform: scaleY(.55);
  -webkit-transition: -webkit-transform .4s cubic-bezier(.075, .82, .165, 1);
  -moz-transition: -moz-transform .4s cubic-bezier(.075, .82, .165, 1);
  transition: transform .4s cubic-bezier(.075, .82, .165, 1)
}

.qode-numbered-carousel .qode-nc-indicators>span.qode-active {
  pointer-events: none
}

.qode-numbered-carousel .qode-nc-indicators>span.qode-active:after {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  transform: scaleY(1)
}

.qode-portfolio-carousel {
  padding-bottom: 53px
}

.qode-portfolio-carousel.qode-auto-width-carousel .swiper-slide {
  width: auto;
  position: relative
}

.qode-portfolio-carousel.qode-auto-width-carousel .swiper-slide:not(:first-child) {
  margin-left: -50px
}

.qode-portfolio-carousel.qode-auto-width-carousel .swiper-slide:nth-child(even) {
  transform: translateY(53px)
}

.qode-portfolio-carousel .swiper-slide,
.qode-portfolio-carousel .swiper-slide * {
  cursor: none
}

.qode-portfolio-carousel .qode-pc-custom-cursor {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 127px;
  height: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 9999;
  pointer-events: none;
  border: 1px solid #1abc9c;
  border-radius: 50%;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #1abc9c;
  transition: opacity .1s cubic-bezier(.39, .575, .565, 1)
}

.qode-portfolio-carousel .qode-pc-custom-cursor.qode-is-active {
  opacity: 1
}

#qode-portfolio-list-stacked {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw
}

#qode-portfolio-list-stacked .qode-pls-item {
  position: absolute;
  transition: transform 1s cubic-bezier(.2, .84, .5, 1);
  will-change: transform;
  backface-visibility: hidden;
  pointer-events: none
}

@supports (-ms-ime-align:auto) {
  #qode-portfolio-list-stacked .qode-pls-item {
    transition: none
  }
}

@media all and (-ms-high-contrast:none) {
  #qode-portfolio-list-stacked .qode-pls-item {
    transition: none
  }
}

#qode-portfolio-list-stacked .qode-pls-item.qode-active {
  z-index: 100
}

#qode-portfolio-list-stacked .qode-pls-item.qode-active,
#qode-portfolio-list-stacked .qode-pls-item.qode-active+.qode-pls-item {
  pointer-events: auto
}

#qode-portfolio-list-stacked img {
  display: block;
  will-change: transform
}

#qode-portfolio-list-stacked .qode-pls-item-inner {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0
}

#qode-portfolio-list-stacked .qode-pls-item-inner>div {
  opacity: 0;
  transform: translate3d(0, -10%, 0);
  transition: transform 1s cubic-bezier(.2, .84, .5, 1), opacity .4s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item:nth-of-type(1) .qode-pls-item-inner>div {
  transition-delay: .25s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item:nth-of-type(2) .qode-pls-item-inner>div {
  transition-delay: .5s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item:nth-of-type(3) .qode-pls-item-inner>div {
  transition-delay: .75s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item:nth-of-type(4) .qode-pls-item-inner>div {
  transition-delay: 1s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item:nth-of-type(5) .qode-pls-item-inner>div {
  transition-delay: 1.25s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item:nth-of-type(6) .qode-pls-item-inner>div {
  transition-delay: 1.5s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item:nth-of-type(7) .qode-pls-item-inner>div {
  transition-delay: 1.75s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item:nth-of-type(8) .qode-pls-item-inner>div {
  transition-delay: 2s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item:nth-of-type(9) .qode-pls-item-inner>div {
  transition-delay: 2.25s
}

#qode-portfolio-list-stacked.qode-loaded .qode-pls-item-inner>div {
  opacity: 1;
  transform: translate3d(0, 0, 0)
}

#qode-portfolio-list-stacked #qode-pls-text-items {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  z-index: 300;
  text-align: center;
  display: none
}

#qode-portfolio-list-stacked #qode-pls-text-items>div:not(.qode-active) {
  display: none
}

#qode-portfolio-list-stacked.qode-overflow {
  overflow: hidden !important
}

#qode-portfolio-list-stacked .qode-pls-end-of-scroll {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 60%;
  margin: auto;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity .4s
}

#qode-portfolio-list-stacked .qode-pls-end-of-scroll .qode-eos-title * {
  color: #1abc9c;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #1abc9c;
  font-size: 100px;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: .2em;
  font-style: italic
}

#qode-portfolio-list-stacked .qode-pls-end-of-scroll .qode-eos-title a {
  position: relative
}

#qode-portfolio-list-stacked .qode-pls-end-of-scroll .qode-eos-title a:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 10px;
  background-color: currentColor
}

@media all and (-ms-high-contrast:none) {
  #qode-portfolio-list-stacked .qode-pls-end-of-scroll {
    display: table;
    table-layout: fixed
  }

  #qode-portfolio-list-stacked .qode-pls-end-of-scroll>div {
    display: table-cell;
    vertical-align: middle
  }
}

#qode-portfolio-list-stacked .qode-pls-end-of-scroll.qode-visible {
  pointer-events: auto;
  opacity: 1;
  transition: opacity 1s
}

#qode-portfolio-list-stacked .qode-pls-scroll-note {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none
}

@media all and (-ms-high-contrast:none) {
  #qode-portfolio-list-stacked .qode-pls-scroll-note {
    left: -50px
  }
}

@-webkit-keyframes qodeArrowDown {
  0% {
    transform-origin: center;
    transform: rotate(-180deg)
  }

  100% {
    transform-origin: center;
    transform: rotate(0)
  }
}

@keyframes qodeArrowDown {
  0% {
    transform-origin: center;
    transform: rotate(-180deg)
  }

  100% {
    transform-origin: center;
    transform: rotate(0)
  }
}

@-webkit-keyframes qodeArrowUp {
  0% {
    transform-origin: center;
    transform: rotate(0)
  }

  100% {
    transform-origin: center;
    transform: rotate(-180deg)
  }
}

@keyframes qodeArrowUp {
  0% {
    transform-origin: center;
    transform: rotate(0)
  }

  100% {
    transform-origin: center;
    transform: rotate(-180deg)
  }
}

#qode-portfolio-list-stacked .qode-pls-scroll-note .qode-pls-down {
  position: absolute;
  bottom: 60px;
  left: 60px;
  opacity: 1;
  transition: opacity .4s ease-in .2s;
  transform: rotate(-180deg);
  animation: qodeArrowDown 1s forwards;
  will-change: transform
}

#qode-portfolio-list-stacked .qode-pls-scroll-note .qode-pls-down svg path {
  fill: #1abc9c
}

#qode-portfolio-list-stacked .qode-pls-widget-area {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 55px 0 0
}

#qode-portfolio-list-stacked .qode-pls-widget-area>div {
  white-space: nowrap
}

#qode-portfolio-list-stacked.qode-eos .qode-pls-scroll-note .qode-pls-down {
  animation: qodeArrowUp 1s forwards;
  will-change: transform
}

#qode-portfolio-list-stacked.qode-eos #qode-pls-text-items,
#qode-portfolio-list-stacked.qode-eos~#qode-pls-info {
  display: none !important
}

#qode-pls-info {
  position: fixed;
  top: -4px;
  left: 0;
  z-index: 5000;
  pointer-events: none;
  opacity: 0;
  transition: opacity .1s .15s
}

#qode-pls-info .qode-pls-title-holder {
  display: inline-block;
  padding: 0 18px;
  white-space: nowrap
}

#qode-pls-info .qode-pls-title-holder .qode-pls-title {
  margin-bottom: 5px;
  margin-top: 25px
}

#qode-pls-info .qode-pls-title-holder .qode-pls-title a {
  font-size: 60px;
  font-style: italic;
  font-weight: 400;
  color: #1abc9c
}

#qode-pls-info.qode-show {
  opacity: 1;
  transition: none
}

.qode-portfolio-project-slider.swiper-container {
  height: 100vh;
  margin-left: 100px
}

.qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner {
  font-size: 0;
  height: 100%
}

.qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner .qode-pps-image {
  display: flex;
  width: 69%;
  position: relative;
  top: 50%;
  transform: translateY(-50%)
}

.qode-safari .qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner .qode-pps-image {
  display: -webkit-box !important
}

.qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner .qode-pps-image .qodef-pps-title {
  margin: 0;
  position: absolute;
  top: 52px;
  right: -39.5%;
  width: 53%
}

.qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner .qode-pps-image .qodef-pps-title a {
  display: block;
  font-size: 100px;
  line-height: 1.1em;
  text-transform: uppercase
}

.qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner .qode-pps-bottom-content {
  font-size: 14px;
  display: block;
  vertical-align: bottom;
  width: 45%;
  padding-left: 3.1vw;
  position: absolute;
  left: 100%;
  bottom: 0
}

.qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner .qode-pps-bottom-content .qode-qode-pps-info {
  margin-bottom: 1.4vw
}

.qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner .qode-pps-bottom-content .qode-pps-custom-field p {
  display: inline-block;
  font-style: italic
}

.qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner .qode-pps-bottom-content .qode-pps-date {
  font-style: italic
}

.qode-portfolio-project-slider.swiper-container .swiper-slide .qode-pps-inner .qode-pps-bottom-content .qode-btn.qode-btn-simple .qode-btn-text {
  line-height: 1;
  vertical-align: bottom
}

.qode-portfolio-project-slider.swiper-container .swiper-pagination-bullets {
  position: absolute !important;
  right: 4.7vw;
  bottom: 6.2vh;
  width: auto
}

.qode-portfolio-project-slider.swiper-container .swiper-pagination-bullets .swiper-pagination-bullet {
  position: relative;
  margin: 0 8px;
  width: auto;
  height: auto;
  background: 0 0;
  opacity: 1
}

.qode-portfolio-project-slider.swiper-container .swiper-pagination-bullets .swiper-pagination-bullet:after {
  content: '';
  width: 100px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: calc(100% + 15px);
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, .3);
  opacity: 0
}

.qode-portfolio-project-slider.swiper-container .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  margin-right: 115px
}

.qode-portfolio-project-slider.swiper-container .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  opacity: 1
}

.qode-portfolio-project-slider.swiper-container .qode-pps-widget-area {
  position: absolute;
  left: 0;
  bottom: 6.2vh;
  z-index: 50
}

.qode-portfolio-project-slider.swiper-container .qode-pps-widget-area .widget {
  margin: 0
}

.qode-portfolio-project-slider.swiper-container.qode-pps-light .qode-pps-bottom-content *,
.qode-portfolio-project-slider.swiper-container.qode-pps-light .qode-pps-widget-area *,
.qode-portfolio-project-slider.swiper-container.qode-pps-light .qodef-pps-title a,
.qode-portfolio-project-slider.swiper-container.qode-pps-light .swiper-pagination-bullet {
  color: #fff
}

.qode-portfolio-project-slider.swiper-container.qode-pps-light .swiper-pagination-bullets .swiper-pagination-bullet:after {
  background-color: rgba(255, 255, 255, .3)
}

.qode-pl-holder {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle
}

.qode-pl-holder .qode-prl-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  display: none;
  margin-top: 45px;
  box-sizing: border-box;
  text-align: center;
  padding: 120px
}

.qode-pl-holder .qode-prl-loading .qode-prl-loading-msg {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1abc9c
}

.qode-pl-holder .qode-pl-categories {
  float: left;
  position: relative;
  padding-bottom: 12px
}

.qode-pl-holder .qode-pl-categories h6.qode-pl-categories-label {
  margin: 0;
  display: none;
  font-size: 13px
}

.qode-pl-holder .qode-pl-categories h6.qode-pl-categories-label:after {
  content: "\43";
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-family: ElegantIcons;
  font-size: 14px;
  line-height: 1em;
  margin-left: 8px;
  top: -1px
}

.qode-pl-holder .qode-pl-categories ul {
  list-style: none;
  display: inline-block;
  width: 100%;
  margin: 0
}

.qode-pl-holder .qode-pl-categories ul li {
  display: inline-block;
  float: left;
  position: relative;
  padding: 0 13px
}

.qode-pl-holder .qode-pl-categories ul li:first-child {
  padding-left: 0
}

.qode-pl-holder .qode-pl-categories ul li a {
  color: #818181;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-transform: color .3s ease;
  -moz-transform: color .3s ease;
  transform: color .3s ease
}

.qode-pl-holder .qode-pl-categories ul li a.active,
.qode-pl-holder .qode-pl-categories ul li a:hover {
  color: #1abc9c
}

.qode-pl-holder .qode-pl-ordering-outer {
  float: right;
  position: relative;
  padding-bottom: 12px
}

.qode-pl-holder .qode-pl-ordering-outer h6 {
  font-size: 13px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0 20px;
  display: inline-block;
  vertical-align: middle;
  line-height: 2.2em;
  cursor: pointer
}

.qode-pl-holder .qode-pl-ordering-outer h6:after {
  content: "\43";
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-family: ElegantIcons;
  font-size: 14px;
  line-height: 1em;
  margin-left: 8px
}

.qode-pl-holder .qode-pl-ordering-outer .qode-pl-ordering {
  background-color: #212121;
  display: inline-block;
  padding: 35px 20px;
  position: absolute;
  right: 0;
  top: 46px;
  width: 365px;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s ease .3s, opacity .3s ease;
  -moz-transition: visibility 0s ease .3s, opacity .3s ease;
  transition: visibility 0s ease .3s, opacity .3s ease
}

.qode-pl-holder .qode-pl-ordering-outer .qode-pl-ordering div {
  display: inline-block;
  width: 140px;
  margin: 0 20px
}

.qode-pl-holder .qode-pl-ordering-outer .qode-pl-ordering div h5 {
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 15px;
  letter-spacing: 1.5px;
  font-size: 13px
}

.qode-pl-holder .qode-pl-ordering-outer .qode-pl-ordering div ul {
  list-style: none
}

.qode-pl-holder .qode-pl-ordering-outer .qode-pl-ordering div ul li {
  position: relative;
  padding: 2px 0
}

.qode-pl-holder .qode-pl-ordering-outer .qode-pl-ordering div ul li a {
  color: #818181;
  font-size: 15px;
  -webkit-transform: color .3s ease;
  -moz-transform: color .3s ease;
  transform: color .3s ease
}

.qode-pl-holder .qode-pl-ordering-outer .qode-pl-ordering div ul li a.active,
.qode-pl-holder .qode-pl-ordering-outer .qode-pl-ordering div ul li a:hover {
  color: #fff
}

.qode-pl-holder .qode-pl-ordering-outer:hover .qode-pl-ordering {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
  transition-duration: .3s
}

.qode-pl-holder .qode-pl-outer {
  position: relative;
  z-index: 1;
  clear: both
}

.qode-pl-holder .qode-pl-outer .qode-no-posts {
  margin: 20px 15px 40px
}

.qode-pl-holder .qode-pli {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding: 0;
  z-index: 100;
  box-sizing: border-box
}

.qode-pl-holder .qode-pli .qode-pli-title {
  margin: 0
}

.qode-pl-holder .qode-pli .qode-pli-category {
  margin: 0 0 3px
}

.qode-pl-holder .qode-pli .qode-pli-category a {
  font-size: 14px
}

.qode-pl-holder .qode-pli .qode-pli-excerpt {
  color: #353535;
  line-height: 1.7em;
  margin: 3px 0 5px
}

.qode-pl-holder .qode-pli .qode-pli-rating-holder {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 0 0 3px
}

.qode-pl-holder .qode-pli .qode-pli-rating {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-family: ElegantIcons;
  font-size: 12px;
  line-height: 1.1em;
  letter-spacing: 6px;
  color: #1abc9c;
  overflow: hidden;
  -webkit-font-smoothing: antialiased
}

.qode-pl-holder .qode-pli .qode-pli-rating:before {
  content: "\e031\e031\e031\e031\e031"
}

.qode-pl-holder .qode-pli .qode-pli-rating span {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden
}

.qode-pl-holder .qode-pli .qode-pli-rating span:before {
  content: "\e033\e033\e033\e033\e033"
}

.qode-pl-holder .qode-pli .qode-pli-price {
  color: #818181;
  font-size: 14px;
  opacity: 1;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
  will-change: transform;
}

.qode-pl-holder .qode-pli .qode-pli-price * {
  font-size: inherit;
  line-height: inherit
}

.qode-pl-holder .qode-pli .qode-pli-price del {
  margin: 0 5px 0 0
}

.qode-pl-holder .qode-pli .qode-pli-price ins {
  text-decoration: none
}

.qode-pl-holder .qode-pli .qode-pli-add-to-cart {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  letter-spacing: 2px;
  opacity: 0;
  -webkit-transition: opacity .3s ease;
  -moz-transition: opacity .3s ease;
  transition: opacity .3s ease
}

.qode-pl-holder .qode-pli .qode-pli-add-to-cart a {
  color: #818181;
  -webkit-transition: color .3s ease;
  -moz-transition: color .3s ease;
  transition: color .3s ease;
  white-space: nowrap
}

.qode-pl-holder .qode-pli .qode-pli-add-to-cart a.qode-read-more-button {
  position: relative;
  display: block
}

.qode-pl-holder .qode-pli .qode-pli-add-to-cart .added_to_cart,
.qode-pl-holder .qode-pli .qode-pli-add-to-cart .button {
  margin: 16px 0 0
}

.qode-pl-holder .qode-pli .qode-pli-add-to-cart .added {
  display: none
}

/* TODO: Transform price */

.qode-pl-holder .qode-pli:hover .qode-pli-text-transform {
  -webkit-transition: all .6s cubic-bezier(.12, .68, .43, .95);
  -moz-transition: all .6s cubic-bezier(.12, .68, .43, .95);
  transition: all .6s cubic-bezier(.12, .68, .43, .95);
  opacity: 1;
}

.qode-pl-holder .qode-pli:hover .qode-pli-price-transform {
  -webkit-transform: translateX(60px);
  -moz-transform: translateX(60px);
  transform: translateX(60px);
  opacity: 0;
}

.qode-pl-holder .qode-pli:hover .qode-pli-add-to-cart-transform {
  opacity: 1;
}

/* ****************** */

.qode-pl-holder .qode-pli-inner {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  width: 100%;
}

.touchevents .qode-pl-holder .qode-pli-inner {
  cursor: pointer;
}

.qode-pl-holder .qode-pli-inner .qode-pli-image {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.qode-pl-holder .qode-pli-inner .qode-pli-image img {
  display: block;
  width: 100%;
  min-width: 300px;
  min-height: 300px;
}

.qode-pl-holder .qode-pli-inner .qode-pli-image .qode-pli-new-product,
.qode-pl-holder .qode-pli-inner .qode-pli-image .qode-pli-onsale,
.qode-pl-holder .qode-pli-inner .qode-pli-image .qode-pli-out-of-stock {
  position: absolute;
  display: block;
  top: 15px;
  right: 20px;
  width: auto;
  color: #000;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: center;
  z-index: 100;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  box-sizing: border-box;
  font-weight: 500;
}

.qode-pl-holder .qode-pli-inner .qode-pli-image .qode-pli-new-product {
  right: auto;
  left: 20px;
}

.qode-pl-holder .qode-pli-inner .qode-pli-text {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  display: inline-block;
  opacity: 0;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  z-index: 10;
}

.qode-pl-holder .qode-pli-inner .qode-pli-text-inner {
  font-size: 0;
}

.qode-pl-holder .qode-pli-inner .qode-pli-text-inner .qode-pli-category,
.qode-pl-holder .qode-pli-inner .qode-pli-text-inner .qode-pli-excerpt,
.qode-pl-holder .qode-pli-inner .qode-pli-text-inner .qode-pli-price,
.qode-pl-holder .qode-pli-inner .qode-pli-text-inner .qode-pli-rating {
  color: #999;
}

.qode-pl-holder.qode-info-below-image .qode-pli .qode-pli-text-wrapper .qode-pli-add-to-cart a:hover,
.qode-pl-holder.qode-info-on-image:not(.qode-product-info-light) .qode-pli-category,
.qode-pl-holder.qode-info-on-image:not(.qode-product-info-light) .qode-pli-excerpt,
.qode-pl-holder.qode-info-on-image:not(.qode-product-info-light) .qode-pli-price,
.qode-pl-holder.qode-info-on-image:not(.qode-product-info-light) .qode-pli-rating {
  color: #1abc9c;
}

.qode-pl-holder .qode-pli-inner .qode-pli-link {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.qode-pl-holder.qode-info-on-image .qode-pli-inner .qode-pli-text {
  padding: 10px 13%;
  background-color: rgba(255, 255, 255, .8);
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 8;
}

.qode-pl-holder.qode-info-on-image .qode-pli-inner .qode-pli-text .qode-pli-text-outer {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}

.qode-pl-holder.qode-info-on-image .qode-pli-inner .qode-pli-text .qode-pli-text-inner {
  position: relative;
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  text-align: center;
  font-size: unset;
}

.qode-pl-holder.qode-info-on-image .qode-pli-inner .qode-pli-text .qode-pli-price {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
  opacity: 1;
}

.qode-pl-holder.qode-info-below-image .qode-pli-text-wrapper.qode-no-rating-price {
  padding-bottom: 25px
}

.qode-pl-holder.qode-info-below-image .qode-pli .qode-pli-text-outer {
  overflow: hidden
}

.qode-pl-holder.qode-info-below-image .qode-pli .qode-pli-text-inner {
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  transition: all .2s;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  transform: translateY(100%)
}

.qode-pl-holder.qode-info-below-image .qode-pli .qode-pli-text-wrapper .qode-pli-add-to-cart {
  opacity: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s
}

.qode-pl-holder.qode-product-info-light .qode-pli-inner .qode-pli-text-inner .qode-pli-category,
.qode-pl-holder.qode-product-info-light .qode-pli-inner .qode-pli-text-inner .qode-pli-category a,
.qode-pl-holder.qode-product-info-light .qode-pli-inner .qode-pli-text-inner .qode-pli-excerpt,
.qode-pl-holder.qode-product-info-light .qode-pli-inner .qode-pli-text-inner .qode-pli-price,
.qode-pl-holder.qode-product-info-light .qode-pli-inner .qode-pli-text-inner .qode-pli-rating,
.qode-pl-holder.qode-product-info-light .qode-pli-inner .qode-pli-text-inner .qode-pli-title,
.qode-pl-holder.qode-product-info-light .qode-pli-inner .qode-pli-text-inner .qode-pli-title a,
.qode-vertical-portfolio-slider.qode-vps-light .qode-read-more a,
.qode-vertical-portfolio-slider.qode-vps-light .qode-vps-title-one,
.qode-vertical-portfolio-slider.qode-vps-light .qode-vps-title-two,
.qode-vertical-portfolio-slider.qode-vps-light.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  color: #fff
}

/* TODO: Transform price */

.qode-pl-holder.qode-info-below-image .qode-pli:hover .qode-pli-text-wrapper .qode-pli-add-to-cart-transform {
  opacity: 1;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all .4s .1s;
  -moz-transition: all .4s .1s;
  transition: all .4s .1s;
}

.qode-pl-holder.qode-info-below-image .qode-pli:hover .qode-pli-text-inner-transform {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  transform: translateY(0);
}

/* **************** */

.qode-pl-holder .qode-pli-text-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  margin: 22px 0;
  text-align: center
}

.qode-pl-holder.qode-product-info-light .qode-pli-inner .qode-pli-text-inner .qode-pli-price del {
  color: #ccc
}

.qode-pl-holder.qode-product-info-hover-dark.qode-info-on-image .qode-pli-inner .qode-pli-text {
  background-color: rgba(0, 0, 0, .4);
  padding: 0
}

.qode-pl-holder.qode-product-info-dark .qode-pli-inner .qode-pli-text-inner .qode-pli-category,
.qode-pl-holder.qode-product-info-dark .qode-pli-inner .qode-pli-text-inner .qode-pli-category a,
.qode-pl-holder.qode-product-info-dark .qode-pli-inner .qode-pli-text-inner .qode-pli-excerpt,
.qode-pl-holder.qode-product-info-dark .qode-pli-inner .qode-pli-text-inner .qode-pli-price,
.qode-pl-holder.qode-product-info-dark .qode-pli-inner .qode-pli-text-inner .qode-pli-rating,
.qode-pl-holder.qode-product-info-dark .qode-pli-inner .qode-pli-text-inner .qode-pli-title,
.qode-pl-holder.qode-product-info-dark .qode-pli-inner .qode-pli-text-inner .qode-pli-title a {
  color: #353535
}

.qode-pl-holder.qode-no-space .qode-pl-outer {
  margin: 0
}

.qode-pl-holder.qode-no-space .qode-pl-outer .qode-pli {
  padding: 0
}

.qode-pl-holder.qode-tiny-space .qode-pl-outer {
  margin: 0 -5px
}

.qode-pl-holder.qode-tiny-space .qode-pl-outer .qode-pli {
  padding: 0 5px 10px
}

.qode-pl-holder.qode-small-space .qode-pl-outer {
  margin: 0 -10px
}

.qode-pl-holder.qode-small-space .qode-pl-outer .qode-pli {
  padding: 0 10px 20px;
}

.qode-pl-holder.qode-normal-space .qode-pl-outer {
  margin: 0 -15px;
}

.qode-pl-holder.qode-normal-space .qode-pl-outer .qode-pli {
  padding: 0 15px 30px;
}

.qode-pl-holder.qode-large-space .qode-pl-outer {
  margin: 0 -25px;
}

.qode-pl-holder.qode-large-space .qode-pl-outer .qode-pli {
  padding: 0 25px 50px;
}

.qode-pl-holder.qode-standard-layout.qode-normal-space {
  margin: 0 0 -30px;
}

.qode-pl-holder.qode-standard-layout.qode-small-space {
  margin: 0 0 -20px;
}

.qode-pl-holder.qode-standard-layout.qode-tiny-space {
  margin: 0 0 -10px;
}

.qode-pl-holder.qode-standard-layout.qode-two-columns .qode-pli {
  width: 50%;
}

.qode-pl-holder.qode-standard-layout.qode-three-columns .qode-pli {
  width: 33.33333%;
}

.qode-pl-holder.qode-standard-layout.qode-four-columns .qode-pli {
  width: 25%;
}

.qode-pl-holder.qode-standard-layout.qode-five-columns .qode-pli {
  width: 20%;
}

.qode-pl-holder.qode-standard-layout.qode-six-columns .qode-pli {
  width: 16.66667%;
}

@media only screen and (min-width: 1025px) {

  .qode-advanced-image-gallery .qode-aig-grid.qode-aig-five-columns .qode-aig-image:nth-child(5n+1),
  .qode-advanced-image-gallery .qode-aig-grid.qode-aig-four-columns .qode-aig-image:nth-child(4n+1),
  .qode-advanced-image-gallery .qode-aig-grid.qode-aig-six-columns .qode-aig-image:nth-child(6n+1),
  .qode-advanced-image-gallery .qode-aig-grid.qode-aig-three-columns .qode-aig-image:nth-child(3n+1),
  .qode-advanced-image-gallery .qode-aig-grid.qode-aig-two-columns .qode-aig-image:nth-child(2n+1),
  .qode-pl-holder.qode-standard-layout.qode-five-columns .qode-pli:nth-child(5n+1),
  .qode-pl-holder.qode-standard-layout.qode-four-columns .qode-pli:nth-child(4n+1),
  .qode-pl-holder.qode-standard-layout.qode-six-columns .qode-pli:nth-child(6n+1),
  .qode-pl-holder.qode-standard-layout.qode-three-columns .qode-pli:nth-child(3n+1),
  .qode-pl-holder.qode-standard-layout.qode-two-columns .qode-pli:nth-child(2n+1) {
    clear: both
  }
}

.qode-pl-holder.qode-masonry-layout .qode-pl-outer {
  opacity: 0;
  overflow: hidden
}

.qode-pl-holder.qode-masonry-layout .qode-pl-sizer {
  width: 100%
}

.qode-pl-holder.qode-masonry-layout .qode-pl-gutter {
  width: 0
}

.qode-pl-holder.qode-masonry-layout .qode-pli-inner {
  width: 100%;
  height: 100%
}

.qode-pl-holder.qode-masonry-layout .qode-pli-inner .qode-pli-image {
  overflow: hidden;
  height: 100%;
}

.qode-pl-holder.qode-masonry-layout .qode-pli-inner .qode-pli-image img {
  height: 100%;
  left: 50%;
  max-width: none;
  position: relative;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  width: auto;
  -webkit-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
  -webkit-transform-origin: 50% 80%;
  transform-origin: 50% 80%
}

.qode-pl-holder.qode-masonry-layout .qode-pli:hover img {
  -webkit-transform: translateX(-50%) scale(1.05);
  -moz-transform: translateX(-50%) scale(1.05);
  transform: translateX(-50%) scale(1.05)
}

.qode-pl-holder.qode-masonry-layout.qode-two-columns .qode-pl-sizer,
.qode-pl-holder.qode-masonry-layout.qode-two-columns .qode-pli {
  width: 50%
}

.qode-pl-holder.qode-masonry-layout.qode-two-columns .qode-pli.qode-woo-image-large-width,
.qode-pl-holder.qode-masonry-layout.qode-two-columns .qode-pli.qode-woo-image-large-width-height {
  width: 100%
}

.qode-pl-holder.qode-masonry-layout.qode-three-columns .qode-pl-sizer,
.qode-pl-holder.qode-masonry-layout.qode-three-columns .qode-pli {
  width: 33.33333%
}

.qode-pl-holder.qode-masonry-layout.qode-three-columns .qode-pli.qode-woo-image-large-width,
.qode-pl-holder.qode-masonry-layout.qode-three-columns .qode-pli.qode-woo-image-large-width-height {
  width: 66.66667%
}

.qode-pl-holder.qode-masonry-layout.qode-four-columns .qode-pl-sizer,
.qode-pl-holder.qode-masonry-layout.qode-four-columns .qode-pli {
  width: 25%
}

.qode-pl-holder.qode-masonry-layout.qode-four-columns .qode-pli.qode-woo-image-large-width,
.qode-pl-holder.qode-masonry-layout.qode-four-columns .qode-pli.qode-woo-image-large-width-height {
  width: 50%
}

.qode-pl-holder.qode-masonry-layout.qode-five-columns .qode-pl-sizer,
.qode-pl-holder.qode-masonry-layout.qode-five-columns .qode-pli {
  width: 20%
}

.qode-pl-holder.qode-masonry-layout.qode-five-columns .qode-pli.qode-woo-image-large-width,
.qode-pl-holder.qode-masonry-layout.qode-five-columns .qode-pli.qode-woo-image-large-width-height {
  width: 40%
}

.qode-pl-holder.qode-masonry-layout.qode-six-columns .qode-pl-sizer,
.qode-pl-holder.qode-masonry-layout.qode-six-columns .qode-pli {
  width: 16.66667%
}

.qode-pl-holder.qode-masonry-layout.qode-six-columns .qode-pli.qode-woo-image-large-width,
.qode-pl-holder.qode-masonry-layout.qode-six-columns .qode-pli.qode-woo-image-large-width-height {
  width: 33.33333%
}

.qode-split-scrolling-section {
  position: relative;
  overflow: hidden;
  height: 100%
}

.qode-split-scrolling-section .qode-sss-ms-left,
.qode-split-scrolling-section .qode-sss-ms-right {
  width: 50%;
  height: 100%;
  box-sizing: border-box
}

.qode-split-scrolling-section .qode-sss-ms-section {
  height: 100%;
  overflow: hidden
}

.qode-split-scrolling-section .qode-sss-ms-left {
  position: fixed
}

.qode-split-scrolling-section .qode-sss-ms-left div,
.qode-split-scrolling-section .qode-sss-ms-left li,
.qode-split-scrolling-section .qode-sss-ms-left ul {
  height: 100%
}

.qode-split-scrolling-section .qode-sss-ms-left .qode-sss-ms-section {
  background-position: 100% center;
  background-size: cover
}

.qode-split-scrolling-section .qode-sss-ms-right {
  float: right;
  margin: 80px 0;
  padding: 0 25px
}

.qode-split-scrolling-section .qode-sss-ms-right .qode-sss-ms-section {
  background-position: 0 center;
  background-size: cover
}

.qode-split-scrolling-section .qode-image-slider-item-inner.image {
  display: none
}

.qode-split-scrolling-section .qode-image-slider-item-inner-wrapper {
  position: relative
}

.qode-split-scrolling-section .qode-image-slider-item-inner-wrapper .qode-image-slider-item-content {
  height: auto;
  position: absolute;
  bottom: 50%;
  width: 100%;
  padding: 0 5%;
  text-align: center;
  box-sizing: border-box;
  -webkit-transform: translateY(50%);
  -moz-transform: translateY(50%);
  transform: translateY(50%)
}

.qode-split-scrolling-section .qode-image-slider-item-inner-wrapper .qode-image-slider-item-content .qode-image-slider-item-inner.title {
  font-size: 40px;
  margin: 0;
  font-weight: 500;
  letter-spacing: .6em
}

.qode-split-scrolling-section .qode-image-slider-item-inner-wrapper .qode-image-slider-item-content .qode-image-slider-item-inner.title span {
  display: block;
  margin-bottom: 10px
}

.qode-split-scrolling-section .qode-prod-cat.qode-cat-with-image:nth-child(even) {
  top: 38px
}

.qode-split-scrolling-section .qode-owl-slider .owl-nav .owl-next,
.qode-split-scrolling-section .qode-owl-slider .owl-nav .owl-prev {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
  bottom: 50%
}

.qode-vertical-portfolio-slider {
  margin: 0 !important;
  height: 100vh !important;
  overflow: hidden
}

@media only screen and (max-width: 1000px) {
  .qode-vertical-portfolio-slider {
    height: calc(100vh - 70px) !important
  }
}

.admin-bar .qode-vertical-portfolio-slider {
  height: calc(100vh - 32px) !important
}

@media only screen and (max-width: 1000px) {
  .admin-bar .qode-vertical-portfolio-slider {
    height: calc(100vh - 70px - 32px) !important
  }
}

.qode-vertical-portfolio-slider .qode-vps-item {
  display: flex;
  align-items: center;
  justify-content: center
}

.qode-vertical-portfolio-slider .qode-vps-outer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover
}

.qode-vertical-portfolio-slider .qode-vps-inner {
  width: auto;
  margin: 0 auto;
  text-align: center;
  position: relative;
  height: 100%
}

.qode-vertical-portfolio-slider .qode-vps-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto
}

.qode-vertical-portfolio-slider .qode-vps-image img {
  display: block
}

.qode-vertical-portfolio-slider .qode-vps-title-one,
.qode-vertical-portfolio-slider .qode-vps-title-two {
  font-size: 145px;
  line-height: 1.11em;
  letter-spacing: .1em
}

.qode-vertical-portfolio-slider .qode-vps-title-one {
  position: absolute;
  top: 22%;
  left: 12%;
  z-index: 9999
}

.qode-vertical-portfolio-slider .qode-vps-title-one:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 425px;
  background-color: currentColor;
  left: -60px;
  top: 84px;
  transform: rotate(14.5deg)
}

.qode-vertical-portfolio-slider .qode-vps-title-one .qode-vps-to-first {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 116% 100%, 0 32%)
}

.qode-vertical-portfolio-slider .qode-vps-title-one .qode-vps-to-second {
  position: absolute;
  left: 8px;
  top: 0;
  clip-path: polygon(0 29%, 0 100%, 84% 88%)
}

.qode-vertical-portfolio-slider .qode-vps-title-two {
  position: relative
}

.qode-vertical-portfolio-slider .qode-vps-title-two:before {
  content: '';
  position: absolute;
  left: 74px;
  top: 100px;
  width: 428px;
  height: 1px;
  background-color: currentColor;
  transform: rotate(-28.5deg)
}

.qode-vertical-portfolio-slider .qode-vps-title-two:after {
  content: '';
  position: absolute;
  right: -8px;
  top: 103px;
  width: 270px;
  height: 1px;
  background-color: currentColor;
  transform: rotate(-49deg)
}

.qode-vertical-portfolio-slider .qode-vps-title-two .qode-vps-tt-one {
  clip-path: polygon(0 0, 69% 0, 21% 100%, 0 100%)
}

.qode-vertical-portfolio-slider .qode-vps-title-two .qode-vps-tt-two {
  position: absolute;
  left: -12px;
  top: 5px;
  clip-path: polygon(66% 0, 96% 0, 77% 100%, 25% 100%)
}

.qode-vertical-portfolio-slider .qode-vps-title-two .qode-vps-tt-three {
  position: absolute;
  left: -6px;
  top: -3px;
  clip-path: polygon(96% 0, 100% 0, 100% 100%, 77% 100%)
}

.qode-vertical-portfolio-slider .qode-vps-bottom {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: 26.2%;
  white-space: nowrap
}

.qode-vertical-portfolio-slider .qode-read-more {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .3em;
  margin-top: 20px
}

.qode-vertical-portfolio-slider .qode-read-more a {
  display: flex;
  align-items: center;
  white-space: nowrap
}

.qode-vertical-portfolio-slider .qode-read-more .qode-read-more-icon {
  margin-right: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border: 1px solid #000;
  position: relative
}

.qode-vertical-portfolio-slider .qode-read-more .qode-read-more-icon:after,
.qode-vertical-portfolio-slider .qode-read-more .qode-read-more-icon:before {
  background-color: #000;
  position: absolute;
  left: 50%;
  top: 50%
}

.qode-vertical-portfolio-slider .qode-read-more .qode-read-more-icon:before {
  content: '';
  width: 25px;
  height: 1px;
  transform: translateX(-50%)
}

.qode-vertical-portfolio-slider .qode-read-more .qode-read-more-icon:after {
  content: '';
  width: 1px;
  height: 25px;
  transform: translateY(-50%)
}

.qode-vertical-portfolio-slider.swiper-container-vertical>.swiper-pagination-bullets {
  right: 110px
}

.qode-vertical-portfolio-slider.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 36px 0;
  width: auto;
  height: auto;
  display: block;
  position: relative;
  border-radius: 0;
  background: 0 0;
  opacity: 1
}

.qode-vertical-portfolio-slider.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet:after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: -23px;
  width: 53px;
  height: 1px;
  background-color: currentColor;
  display: block;
  transform: rotate(-27deg);
  opacity: 0;
  transition: opacity .3s ease-in-out
}

.qode-vertical-portfolio-slider.swiper-container-vertical>.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active:after {
  opacity: 1
}

.qode-vertical-portfolio-slider.qode-vps-light .qode-read-more .qode-read-more-icon {
  border-color: #fff
}

.qode-vertical-portfolio-slider.qode-vps-light .qode-read-more .qode-read-more-icon:after,
.qode-vertical-portfolio-slider.qode-vps-light .qode-read-more .qode-read-more-icon:before {
  background-color: #fff
}

@media screen and (min-width: 1025px) {
  .qode-vertical-portfolio-slider .qodef-e-content {
    position: absolute;
    left: 4.5%;
    bottom: 64px;
    margin: 0
  }

  .qode-vertical-portfolio-slider.qodef-swiper-container.swiper-container-vertical .swiper-pagination-bullets {
    right: calc(4.5% - 6px) !important
  }
}

@media screen and (min-width: 681px) {
  .qode-vertical-portfolio-slider .qodef-e-content {
    display: flex;
    flex-direction: column-reverse
  }

  .qode-vertical-portfolio-slider .qodef-e-content .qodef-e-title {
    font-size: 75px
  }
}

@media only screen and (max-width: 1024px) {

  .qode-vertical-portfolio-slider .qodef-e-content,
  .qode-vertical-portfolio-slider .qodef-e-image {
    margin-left: auto;
    margin-right: auto
  }

  .qode-vertical-portfolio-slider .qodef-e-inner {
    width: 100%
  }

  .qode-vertical-portfolio-slider .qodef-e-image {
    text-align: center
  }
}

.wp-block-button .wp-block-button__link,
.wp-block-button.is-style-outline .wp-block-button__link {
  position: relative;
  border: 2px solid #303030;
  text-align: left;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  font-family: inherit;
  font-size: 13px
}

@media only screen and (max-width: 768px) {
  .qode-vertical-portfolio-slider.qodef-swiper-container.swiper-container-vertical .swiper-pagination-bullets {
    right: 45px !important
  }
}

@media only screen and (max-width: 480px) {
  .qode-vertical-portfolio-slider.qodef-swiper-container.swiper-container-vertical .swiper-pagination-bullets {
    display: none
  }
}

.qode-vertical-portfolio-slider .swiper-slide .entry-title,
.qode-vertical-portfolio-slider .swiper-slide .qodef-e-info-category {
  transform-origin: top left;
  transform: translateY(30px) translateZ(0) skewY(3deg) scaleY(1.2);
  opacity: 0;
  transition: transform 1s cubic-bezier(.16, .09, 0, 1.02) .15s, opacity .3s .15s
}

.qode-vertical-portfolio-slider .swiper-slide .qodef-e-info-category {
  transition: transform 1s cubic-bezier(.16, .09, 0, 1.02) .25s, opacity .3s .25s
}

.qode-vertical-portfolio-slider .swiper-slide .qodef-e-image {
  transform-origin: top left;
  transform: translateY(30px) translateZ(0) skewY(3deg) scaleY(1.2);
  opacity: 0;
  transition: transform 1.2s cubic-bezier(.16, .09, 0, 1.02) .35s, opacity .3s .35s
}

.qode-vertical-portfolio-slider .swiper-slide.swiper-slide-active .entry-title,
.qode-vertical-portfolio-slider .swiper-slide.swiper-slide-active .qodef-e-image,
.qode-vertical-portfolio-slider .swiper-slide.swiper-slide-active .qodef-e-info-category {
  transform: translateY(0) translateZ(0) skewY(0) scaleY(1);
  opacity: 1
}

.qode-vertical-portfolio-slider .swiper-slide.swiper-slide-prev .entry-title,
.qode-vertical-portfolio-slider .swiper-slide.swiper-slide-prev .qodef-e-info-category {
  transform: translateY(-30px) translateZ(0) skewY(0) scaleY(1);
  opacity: 0;
  transition: transform .35s cubic-bezier(.16, .09, 0, 1.02) 0s, opacity .35s 0s
}

.qode-vertical-portfolio-slider .swiper-slide.swiper-slide-prev .qodef-e-image {
  transform: translateY(-60px) translateZ(0) skewY(3deg) scaleY(.9);
  opacity: 0;
  transition: transform .4s cubic-bezier(.16, .09, 0, 1.02) 0s, opacity .4s 0s
}

p.has-background {
  padding: 20px 30px
}

.wp-block-audio {
  width: 100%
}

.wp-block-audio audio {
  width: auto
}

.wp-block-quote,
.wp-block-video video,
blockquote.wp-block-pullquote.aligncenter {
  width: 100%
}

.wp-block-audio.alignleft audio,
.wp-block-audio.alignright audio {
  max-width: 50%
}

.wp-block-audio.aligncenter audio {
  display: block;
  margin: 0 auto
}

.wp-block-button .wp-block-button__link {
  display: inline-block;
  width: auto;
  height: 39px;
  line-height: 39px;
  margin: 0;
  padding: 0 23px;
  font-weight: 700;
  color: #303030;
  text-decoration: none;
  white-space: nowrap;
  outline: 0;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  text-shadow: none;
  background-color: transparent;
  -webkit-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -moz-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -ms-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -o-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  box-sizing: initial !important;
  cursor: pointer
}

.wp-block-button .wp-block-button__link:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff;
  text-decoration: none
}

.wp-block-button.is-style-outline .wp-block-button__link,
.wp-block-file .wp-block-file__button {
  height: 39px;
  white-space: nowrap;
  outline: 0;
  text-shadow: none;
  background-color: transparent;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -moz-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -ms-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -o-transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  cursor: pointer;
  text-decoration: none;
  font-weight: 700
}

.wp-block-button.is-style-outline .wp-block-button__link {
  display: inline-block;
  width: auto;
  line-height: 39px;
  margin: 0;
  padding: 0 23px;
  color: #303030;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  box-sizing: initial !important
}

.wp-block-button.is-style-outline .wp-block-button__link:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff;
  text-decoration: none
}

.wp-block-archives,
.wp-block-categories,
.wp-block-latest-posts {
  margin: 0;
  padding-left: 0
}

.wp-block-archives ul,
.wp-block-categories ul,
.wp-block-latest-posts ul {
  padding-left: 0 !important
}

.wp-block-archives li,
.wp-block-categories li,
.wp-block-latest-posts li {
  margin-bottom: 6px;
  list-style-type: none
}

.wp-block-archives li a,
.wp-block-categories li a,
.wp-block-latest-posts li a {
  color: #353535;
  text-decoration: none
}

.wp-block-archives li a:hover,
.wp-block-categories li a:hover,
.wp-block-latest-posts li a:hover {
  color: #1abc9c
}

.wp-block-archives.aligncenter,
.wp-block-categories.aligncenter,
.wp-block-latest-posts.aligncenter {
  text-align: center
}

.wp-block-categories li ul {
  margin: 4px 0 0;
  padding: 0 0 0 10px;
  list-style-type: none
}

.wp-block-categories li ul li:last-child {
  padding: 4px 0 0
}

.wp-block-latest-posts.is-grid li {
  margin: 0 16px 16px 0
}

.wp-block-preformatted {
  padding: 15px;
  white-space: pre-wrap;
  word-wrap: break-word
}

.wp-block-verse {
  font-family: inherit;
  font-size: 22px;
  line-height: 1.8;
  padding: 15px;
  margin: 15px 0
}

.wp-block-pullquote blockquote,
.wp-block-quote,
blockquote.wp-block-pullquote {
  padding: 14px 14px 14px 10px !important;
  quotes: none;
  border: none !important;
  position: relative;
  box-sizing: border-box;
  vertical-align: middle
}

.has-drop-cap:not(:focus):first-letter {
  font-family: inherit;
  font-size: 3.375em;
  line-height: 1;
  font-weight: 700;
  margin: 0 .25em 0 0
}

blockquote.wp-block-pullquote {
  margin: 0;
  text-align: left;
  color: #818181 !important;
  display: inline-block;
  width: auto
}

blockquote.wp-block-pullquote>* {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  margin: 0
}

blockquote.wp-block-pullquote:after,
blockquote.wp-block-pullquote:before {
  content: ''
}

blockquote.wp-block-pullquote .wp-block-pullquote__citation,
blockquote.wp-block-pullquote .wp-block-quote__citation,
blockquote.wp-block-pullquote cite,
blockquote.wp-block-pullquote footer {
  display: block !important;
  margin-top: 10px !important;
  text-align: inherit !important;
  font-size: 14px !important;
  line-height: 1.3em !important;
  letter-spacing: 0 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  text-transform: none !important
}

.wp-block-pullquote p,
blockquote.wp-block-pullquote p {
  color: inherit !important;
  letter-spacing: inherit !important;
  font-family: inherit !important
}

blockquote.wp-block-pullquote.alignleft {
  text-align: left;
  max-width: 100%
}

blockquote.wp-block-pullquote.alignright {
  text-align: right;
  max-width: 100%
}

blockquote.wp-block-pullquote p {
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important;
  line-height: inherit !important;
  text-transform: inherit !important;
  margin: 0
}

.wp-block-pullquote {
  margin: 0;
  text-align: left
}

.wp-block-pullquote blockquote {
  display: inline-block;
  margin: 0;
  text-align: left;
  color: #818181 !important;
  width: auto
}

.wp-block-pullquote blockquote>* {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  margin: 0
}

.wp-block-pullquote blockquote:after,
.wp-block-pullquote blockquote:before {
  content: ''
}

.wp-block-pullquote blockquote .wp-block-pullquote__citation,
.wp-block-pullquote blockquote .wp-block-quote__citation,
.wp-block-pullquote blockquote cite,
.wp-block-pullquote blockquote footer {
  display: block !important;
  margin-top: 10px !important;
  text-align: inherit !important;
  font-size: 14px !important;
  line-height: 1.3em !important;
  letter-spacing: 0 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  text-transform: none !important
}

.wp-block-pullquote p {
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important;
  line-height: inherit !important;
  text-transform: inherit !important
}

.wp-block-quote {
  display: inline-block;
  margin: 0;
  text-align: left;
  color: #818181 !important
}

.wp-block-quote:after,
.wp-block-quote:before {
  content: ''
}

.wp-block-quote .wp-block-pullquote__citation,
.wp-block-quote .wp-block-quote__citation,
.wp-block-quote cite,
.wp-block-quote footer {
  display: block !important;
  margin-top: 10px !important;
  text-align: inherit !important;
  font-size: 14px !important;
  line-height: 1.3em !important;
  letter-spacing: 0 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  text-transform: none !important
}

.wp-block-quote>p {
  color: inherit !important;
  font-family: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important;
  line-height: inherit !important;
  letter-spacing: inherit !important;
  text-transform: inherit !important;
  margin: 0
}

.wp-block-quote>* {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  margin: 0
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large {
  margin: 1rem 0;
  font-size: 1.6875em !important;
  line-height: 1.6em !important
}

.wp-block-image img {
  display: block
}

.wp-block-image.aligncenter,
.wp-block-image.alignleft,
.wp-block-image.alignright {
  max-width: 100%
}

.wp-block-image.alignleft {
  margin-right: 20px
}

.wp-block-image.alignright {
  margin-left: 20px
}

.wp-block-image.alignfull {
  left: auto;
  max-width: 100%
}

.wp-block-image.alignfull img {
  width: 100vw
}

.wp-block-cover,
.wp-block-cover-image {
  position: relative;
  min-height: 430px;
  padding: 1rem;
  box-sizing: border-box
}

@media only screen and (min-width: 768px) {

  .wp-block-cover,
  .wp-block-cover-image {
    padding: 1rem 10%
  }
}

.wp-block-cover .wp-block-cover-image-text,
.wp-block-cover .wp-block-cover-text,
.wp-block-cover h2,
.wp-block-cover-image .wp-block-cover-image-text,
.wp-block-cover-image .wp-block-cover-text,
.wp-block-cover-image h2 {
  line-height: 1.304347826086957em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 28px !important;
  margin-bottom: 0
}

.wp-block-cover-image.aligncenter .wp-block-cover-image-text,
.wp-block-cover-image.aligncenter .wp-block-cover-text,
.wp-block-cover-image.aligncenter h2,
.wp-block-cover.aligncenter .wp-block-cover-image-text,
.wp-block-cover.aligncenter .wp-block-cover-text,
.wp-block-cover.aligncenter h2 {
  width: 100%;
  z-index: 1;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%
}

.wp-block-cover-image.alignleft,
.wp-block-cover-image.alignright,
.wp-block-cover.alignleft,
.wp-block-cover.alignright {
  width: 100%
}

.wp-block-cover-image.alignfull .wp-block-cover-image-text,
.wp-block-cover-image.alignfull .wp-block-cover-text,
.wp-block-cover-image.alignfull h2,
.wp-block-cover.alignfull .wp-block-cover-image-text,
.wp-block-cover.alignfull .wp-block-cover-text,
.wp-block-cover.alignfull h2 {
  max-width: calc(8 * (100vw / 12) - 28px)
}

.wp-block-gallery {
  list-style-type: none;
  padding-left: 0
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-bottom: 16px
}

.wp-block-audio figcaption,
.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption,
.wp-block-image figcaption,
.wp-block-video figcaption {
  font-size: .71111em;
  font-family: inherit;
  line-height: 1.6;
  margin: 0;
  padding: .5rem;
  text-align: center
}

.wp-block-separator,
hr {
  clear: both
}

.wp-block-embed-twitter {
  overflow: hidden
}

.wp-block-embed {
  margin: 0
}

.wp-block-table td,
.wp-block-table th {
  border-color: #dbdbdb
}

.wp-block-file {
  font-family: inherit
}

.wp-block-file .wp-block-file__button {
  position: relative;
  display: inline-block;
  width: auto;
  line-height: 39px;
  margin: 0;
  padding: 0 23px;
  border: 2px solid #303030;
  font-size: 13px;
  font-family: inherit;
  text-align: left;
  color: #303030;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear;
  -webkit-box-sizing: initial !important;
  -moz-box-sizing: initial !important;
  box-sizing: initial !important
}

.wp-block-file .wp-block-file__button:hover {
  background-color: #1abc9c;
  border-color: #1abc9c;
  color: #fff;
  text-decoration: none
}

.wp-block-code {
  border-radius: 0
}

.wp-block-code code {
  font-size: 1.125em;
  white-space: pre-wrap;
  word-break: break-word
}

@media only screen and (min-width: 768px) {
  .wp-block-columns .wp-block-column>:first-child {
    margin-top: 0
  }

  .wp-block-columns .wp-block-column>:last-child {
    margin-bottom: 0
  }

  .wp-block-columns[class*=has-]>* {
    margin-right: 1rem
  }

  .wp-block-columns[class*=has-]>:last-child {
    margin-right: 0
  }
}

.wp-block-latest-comments .wp-block-latest-comments__comment-meta {
  font-weight: 700
}

.wp-block-latest-comments .wp-block-latest-comments__comment-meta .wp-block-latest-comments__comment-date {
  font-weight: 400
}

.wp-block-latest-comments .wp-block-latest-comments__comment,
.wp-block-latest-comments .wp-block-latest-comments__comment-date,
.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
  font-size: inherit
}

.has-primary-background-color {
  background-color: #1abc9c
}

.has-primary-background-color a,
.has-primary-background-color h1,
.has-primary-background-color h2,
.has-primary-background-color h3,
.has-primary-background-color h4,
.has-primary-background-color h5,
.has-primary-background-color h6,
.has-primary-background-color p {
  color: #fff
}

.qode-panel-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 6000;
  outline: 0 !important;
  background-color: #000;
  transform: translateY(-100%);
  visibility: hidden;
  pointer-events: none;
  transition: all .3s cubic-bezier(1, .28, .15, .95)
}

.qode-panel-area-opened .qode-panel-area {
  visibility: visible;
  pointer-events: auto;
  transform: translateY(0);
  transition: all .4s cubic-bezier(1, .28, .15, .95)
}

.qode-panel-area .qode-panel-area-inner {
  position: relative;
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%
}

.qode-panel-area .qode-panel-area-inner .qode-panel-area-content {
  position: relative;
  display: table-cell;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  text-align: center
}

.qode-panel-area .qode-close-panel-holder {
  position: absolute;
  right: 120px;
  top: 75px;
  z-index: 9999
}

.qode-pl-holder .qode-pli .qode-pli-category a {
  font-size: 15px;
  color: #7b7b7b;
}

.qode-pl-holder .qode-pli .qode-pli-price {
  color: #212121;
  font-size: 17px;
  font-family: EB Garamond;
  font-style: italic;
  letter-spacing: 0;
}

.qode-pl-holder .qode-pli .qode-pli-title {
  margin: 0 0 -6px;
  width: 65%;
  line-height: 18px;
  margin-bottom: 0;
}

.qode-pl-holder .qode-pli .qode-pli-title a {
  line-height: 18px;
}

.woocommerce div.cart-collaterals div.cart_totals table th {
  font-weight: 400;
}

span.woocommerce-Price-amount.amount {
  color: #212121 !important;
  font-size: 18px;
  line-height: 28px;
  text-transform: none;
  font-family: EB Garamond, sans-serif;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}

.woocommerce div.cart-collaterals div.cart_totals table tr.order-total strong span.amount,
.woocommerce-page div.cart-collaterals div.cart_totals table tr.order-total strong span.amount {
  color: #212121 !important;
  font-size: 18px;
  line-height: 28px;
  text-transform: none;
  font-family: EB Garamond, sans-serif;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}

.woocommerce form.checkout table.shop_table span.amount {
  color: #212121 !important;
  font-size: 18px;
  line-height: 28px;
  text-transform: none;
  font-family: EB Garamond, sans-serif;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
}

.qode-pl-holder .qode-pli .qode-pli-add-to-cart {
  font-family: EB Garamond, sans-serif;
  font-size: 17px;
  letter-spacing: 0;
  font-weight: 400;
  text-transform: none;
  font-style: italic;
  color: #212121;
}

.qode-pl-holder .qode-pli .qode-pli-add-to-cart a {
  color: #212121;
}

.qode-pl-holder .qode-pli .qode-pli-price {
  float: right;
  position: absolute;
  right: 1px;
  top: 0;
}

.qode-pl-holder .qode-pli .qode-pli-add-to-cart {
  right: 0;
  top: 0;
  left: auto;
}

.qode-pl-holder.qode-info-below-image .qode-pli:hover .qode-pli-text-wrapper .qode-pli-add-to-cart {
  transform: translateX(0);
}

.qode-pl-holder .qode-pli-text-wrapper {
  overflow: hidden;
}

.qode-pl-holder .qode-pli-inner .qode-pli-image .qode-pli-new-product,
.qode-pl-holder .qode-pli-inner .qode-pli-image .qode-pli-onsale,
.qode-pl-holder .qode-pli-inner .qode-pli-image .qode-pli-out-of-stock {
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  border: 1px solid #b26b53;
  font-size: 16px;
  color: #b26b53;
  font-style: italic;
  font-family: EB Garamond;
  top: 24px;
  right: 25px;
}