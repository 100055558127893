.widget .search-form input[type=text] {
  font-size: 16px;
  font-weight: 300;
  text-transform: none;
  color: #363636;
  padding: 8px 12px;
}

.widget .search-form .search-icon {
  height: 33px;
  line-height: 28px;
  position: absolute;
  right: 0;
  padding: 0;
}

.elementor-widget-container .product-list > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
