header {
    position: fixed !important;
    background: #0a3639;
}

.mobile_menu_button span {
    color: #d8b80c;
}

header .q_logo img {
    height: 90px;
    left: 45%;
}

.menu {
    height: 100%;
    overflow: auto;
    width: 100%;
    scrollbar-width: none;
}

.vertical_logo_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px;
    background: #0a3639;
    z-index: 999;
}

.vertical_menu.vertical_menu_toggle {
    margin-top: 260px;
    margin-bottom: 50px;
}

.vertical_menu.vertical_menu_toggle.menu_category {
    margin: 0;
}

.vertical_menu.vertical_menu_toggle.menu_category>ul:not(.show) {
    display: none;
}

.vertical_menu.vertical_menu_toggle.menu_category>ul>li>a {
    font-size: 12px;
    text-transform: none;
}

.menu-item .plus {
    font-size: 12px;
    float: right;
}

header {
    height: 100%;
    overflow: auto;
    max-height: 100%;
    height: fit-content;
}

header .header_inner > .header_bottom > .container {
    position: fixed;
    height: 100px;
    background: #0a3639;
    z-index: 999;
}

header .mobile_menu:not(.menu_category) {
    margin-top: 100px;
}

header .mobile_menu.menu_category {
    float: none;
}

header .mobile_menu.menu_category ul li {
    margin: 0;
    padding-left: 25px;
}

@media only screen and (max-width: 600px) {
    header .q_logo img {
        left: 42%;
    }
}