h1.name {
  font-family: NgocBaoCac;
  font-size: 60px;
  color: #d8b80c;
  text-align: center;
  letter-spacing: 5px;
  margin-bottom: 10px;
  word-spacing: 8px;
  line-height: 70px;
}

h5.major {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #d8b80c;
  text-align: center;
  margin-top: 10px;
}

.information .item td:first-child {
  width: 120px;
}