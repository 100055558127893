@media only screen and (max-width: 1100px) {
    .woocommerce .widget_shopping_cart_content p.buttons a {
        display: block;
        text-align: center;
        width: auto !important
    }

    .woocommerce .widget_shopping_cart_content a.checkout {
        float: none;
        margin-top: 10px
    }

    .widget  .searchForm input[type=text] {
        width: 70.329670329670%
    }

    .woocommerce form.check-login .form-row {
        width: 48.69791666666667%;
        margin-right: 2.604166666666667%
    }

    .woocommerce .woocommerce-tabs {
        float: none;
        width: 100%;
        clear: both
    }

    .woocommerce .summary {
        margin-bottom: 45px
    }

    .woocommerce #customer_login .col-1,
    .woocommerce .col-1 {
        margin-bottom: 20px;
        margin-right: 0
    }

    .woocommerce #customer_login .col-1,
    .woocommerce #customer_login .col-2 {
        float: none;
        width: auto
    }

    .woocommerce .col-1,
    .woocommerce .col-2 {
        float: none;
        width: 100%
    }

    .woocommerce-account .col-1,
    .woocommerce-account .col-2 {
        float: left
    }

    .woocommerce form.check-login .form-row.form-row-last {
        margin-right: 0
    }

    .woocommerce form.check-login input[type=text],
    .woocommerce form.check-login input[type=password] {
        width: 91% !important
    }

    .woocommerce-account .addresses .col-1,
    .woocommerce-account .addresses .col-2,
    .woocommerce-checkout .addresses .col-1,
    .woocommerce-checkout .addresses .col-2 {
        float: left;
        width: 48.69791666666667%;
        margin-right: 2.604166666666667%
    }

    .woocommerce-account .addresses .col-2,
    .woocommerce-checkout .addresses .col-2 {
        margin-right: 0
    }

    .woocommerce-account table.shop_table,
    .woocommerce-checkout table.shop_table {
        width: 100%
    }

    .woocommerce-account table.shop_table {
        width: 87%
    }

    .full_width .grid_section .section_inner .qode_product_list_masonry_holder .qode_product_list_gutter,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder .qode_product_list_gutter,
    .qode_product_list_masonry_holder .qode_product_list_gutter,
    .qode_product_list_pinterest_holder .qode_product_list_gutter {
        width: 3.90615%
    }

    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.two_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.two_columns .qode_product_list_sizer,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.two_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.two_columns .qode_product_list_sizer,
    .qode_product_list_masonry_holder.two_columns .qode_product_list_item,
    .qode_product_list_masonry_holder.two_columns .qode_product_list_sizer,
    .qode_product_list_pinterest_holder.two_columns .qode_product_list_item,
    .qode_product_list_pinterest_holder.two_columns .qode_product_list_sizer {
        width: 48.04693%
    }

    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.two_columns .qode_product_list_item.large-width-height,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.two_columns .qode_product_list_item.large-width-height,
    .qode_product_list_masonry_holder.two_columns .qode_product_list_item.large-width-height,
    .qode_product_list_pinterest_holder.two_columns .qode_product_list_item.large-width-height {
        width: 100%
    }

    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.three_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.three_columns .qode_product_list_sizer,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.three_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.three_columns .qode_product_list_sizer,
    .qode_product_list_masonry_holder.three_columns .qode_product_list_item,
    .qode_product_list_masonry_holder.three_columns .qode_product_list_sizer,
    .qode_product_list_pinterest_holder.three_columns .qode_product_list_item,
    .qode_product_list_pinterest_holder.three_columns .qode_product_list_sizer {
        width: 30.72923%
    }

    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.three_columns .qode_product_list_item.large-width-height,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.three_columns .qode_product_list_item.large-width-height,
    .qode_product_list_masonry_holder.three_columns .qode_product_list_item.large-width-height,
    .qode_product_list_pinterest_holder.three_columns .qode_product_list_item.large-width-height {
        width: 65.36462%
    }

    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.four_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.four_columns .qode_product_list_sizer,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.four_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.four_columns .qode_product_list_sizer,
    .qode_product_list_masonry_holder.four_columns .qode_product_list_item,
    .qode_product_list_masonry_holder.four_columns .qode_product_list_sizer,
    .qode_product_list_pinterest_holder.four_columns .qode_product_list_item,
    .qode_product_list_pinterest_holder.four_columns .qode_product_list_sizer {
        width: 30.72923%
    }

    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.four_columns .qode_product_list_item.large-width-height,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.four_columns .qode_product_list_item.large-width-height,
    .qode_product_list_masonry_holder.four_columns .qode_product_list_item.large-width-height,
    .qode_product_list_pinterest_holder.four_columns .qode_product_list_item.large-width-height {
        width: 65.36462%
    }

    .full_width .qode_product_list_masonry_holder .qode_product_list_gutter,
    .full_width .qode_product_list_pinterest_holder .qode_product_list_gutter {
        width: 2.92959%
    }

    .full_width .qode_product_list_masonry_holder.two_columns .qode_product_list_item,
    .full_width .qode_product_list_masonry_holder.two_columns .qode_product_list_sizer,
    .full_width .qode_product_list_pinterest_holder.two_columns .qode_product_list_item,
    .full_width .qode_product_list_pinterest_holder.two_columns .qode_product_list_sizer {
        width: 48.53521%
    }

    .full_width .qode_product_list_masonry_holder.two_columns .qode_product_list_item.large-width-height,
    .full_width .qode_product_list_pinterest_holder.two_columns .qode_product_list_item.large-width-height {
        width: 100%
    }

    .full_width .qode_product_list_masonry_holder.three_columns .qode_product_list_item,
    .full_width .qode_product_list_masonry_holder.three_columns .qode_product_list_sizer,
    .full_width .qode_product_list_pinterest_holder.three_columns .qode_product_list_item,
    .full_width .qode_product_list_pinterest_holder.three_columns .qode_product_list_sizer {
        width: 31.38028%
    }

    .full_width .qode_product_list_masonry_holder.three_columns .qode_product_list_item.large-width-height,
    .full_width .qode_product_list_pinterest_holder.three_columns .qode_product_list_item.large-width-height {
        width: 65.69014%
    }

    .full_width .qode_product_list_masonry_holder.four_columns .qode_product_list_item,
    .full_width .qode_product_list_masonry_holder.four_columns .qode_product_list_sizer,
    .full_width .qode_product_list_pinterest_holder.four_columns .qode_product_list_item,
    .full_width .qode_product_list_pinterest_holder.four_columns .qode_product_list_sizer {
        width: 31.38028%
    }

    .full_width .qode_product_list_masonry_holder.four_columns .qode_product_list_item.large-width-height,
    .full_width .qode_product_list_pinterest_holder.four_columns .qode_product_list_item.large-width-height {
        width: 65.69014%
    }
}

@media only screen and (max-width: 1000px) {

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images.qode-add-gallery-and-zoom-support,
    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images:not(.qode-add-gallery-and-zoom-support) {
        clear: both;
        float: none;
        width: 100%
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .summary {
        clear: both;
        float: none;
        width: 100%;
        padding-top: 20px
    }
}

@media only screen and (max-width: 768px) {
    .woocommerce div.summary div[itemprop=description] {
        margin: 25px 0
    }

    .woocommerce .summary {
        float: none;
        width: auto;
        clear: both
    }

    .woocommerce .product .images {
        float: none;
        width: 100%;
        margin-right: 0
    }

    .woocommerce div.product .cart {
        margin-bottom: 25px
    }

    .woocommerce .woocommerce-tabs {
        margin: 25px 0
    }

    .woocommerce #reviews h2 {
        margin-bottom: 20px
    }

    .woocommerce form.checkout table.shop_table {
        width: 100%
    }

    .woocommerce aside ul.product_list_widget li img {
        float: none;
        width: 90px
    }

    .woocommerce.qode-content-sidebar-responsive aside ul.product_list_widget li img {
        float: left
    }

    .woocommerce .addresses header a.edit {
        position: static;
        margin-top: 15px
    }

    .woocommerce .widget_price_filter .button,
    .woocommerce .widget_price_filter .price_label,
    .woocommerce-page .widget_price_filter .button,
    .woocommerce-page .widget_price_filter .price_label {
        float: none
    }

    .widget  .searchForm input[type=text] {
        width: 63.57142857142857%
    }

    .woocommerce table.cart td.actions input[type=submit] {
        margin-bottom: 10px
    }

    ul.products.columns-4 li.product {
        width: 48.37209302325581%;
        margin-right: 3.255813953488372%
    }

    .columns-3 .container_inner>ul.products li.product:nth-child(even),
    .columns-3 .cross-sells>ul.products li.product:nth-child(even),
    .columns-3 .products>ul.products li.product:nth-child(even),
    .columns-3 .woocommerce_with_sidebar ul.products li.product:nth-child(even),
    ul.products.columns-4 li.product:nth-child(2n),
    div.woocommerce.columns-3 ul.products li.product:nth-child(even) {
        margin-right: 0
    }

    ul.products.columns-4 li.product:nth-child(2n+1) {
        clear: both
    }

    .columns-3 .container_inner>ul.products li.product:nth-child(2n+1),
    .columns-3 .cross-sells>ul.products li.product:nth-child(2n+1),
    .columns-3 .products>ul.products li.product:nth-child(2n+1),
    .columns-3 .woocommerce_with_sidebar ul.products li.product:nth-child(2n+1),
    div.woocommerce.columns-3 ul.products li.product:nth-child(2n+1) {
        clear: both
    }

    .columns-3 ul.products li.product {
        width: 48.89%;
        margin-right: 2.22%
    }

    .full_width .grid_section .section_inner .qode_product_list_masonry_holder .qode_product_list_gutter,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder .qode_product_list_gutter,
    .full_width .qode_product_list_masonry_holder .qode_product_list_gutter,
    .full_width .qode_product_list_pinterest_holder .qode_product_list_gutter,
    .qode_product_list_masonry_holder .qode_product_list_gutter,
    .qode_product_list_pinterest_holder .qode_product_list_gutter {
        width: 0
    }

    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.four_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.four_columns .qode_product_list_item.large-width-height,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.four_columns .qode_product_list_sizer,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.three_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.three_columns .qode_product_list_item.large-width-height,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.three_columns .qode_product_list_sizer,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.two_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.two_columns .qode_product_list_item.large-width-height,
    .full_width .grid_section .section_inner .qode_product_list_masonry_holder.two_columns .qode_product_list_sizer,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.four_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.four_columns .qode_product_list_item.large-width-height,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.four_columns .qode_product_list_sizer,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.three_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.three_columns .qode_product_list_item.large-width-height,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.three_columns .qode_product_list_sizer,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.two_columns .qode_product_list_item,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.two_columns .qode_product_list_item.large-width-height,
    .full_width .grid_section .section_inner .qode_product_list_pinterest_holder.two_columns .qode_product_list_sizer,
    .full_width .qode_product_list_masonry_holder.four_columns .qode_product_list_item,
    .full_width .qode_product_list_masonry_holder.four_columns .qode_product_list_item.large-width-height,
    .full_width .qode_product_list_masonry_holder.four_columns .qode_product_list_sizer,
    .full_width .qode_product_list_masonry_holder.three_columns .qode_product_list_item,
    .full_width .qode_product_list_masonry_holder.three_columns .qode_product_list_item.large-width-height,
    .full_width .qode_product_list_masonry_holder.three_columns .qode_product_list_sizer,
    .full_width .qode_product_list_masonry_holder.two_columns .qode_product_list_item,
    .full_width .qode_product_list_masonry_holder.two_columns .qode_product_list_item.large-width-height,
    .full_width .qode_product_list_masonry_holder.two_columns .qode_product_list_sizer,
    .full_width .qode_product_list_pinterest_holder.four_columns .qode_product_list_item,
    .full_width .qode_product_list_pinterest_holder.four_columns .qode_product_list_item.large-width-height,
    .full_width .qode_product_list_pinterest_holder.four_columns .qode_product_list_sizer,
    .full_width .qode_product_list_pinterest_holder.three_columns .qode_product_list_item,
    .full_width .qode_product_list_pinterest_holder.three_columns .qode_product_list_item.large-width-height,
    .full_width .qode_product_list_pinterest_holder.three_columns .qode_product_list_sizer,
    .full_width .qode_product_list_pinterest_holder.two_columns .qode_product_list_item,
    .full_width .qode_product_list_pinterest_holder.two_columns .qode_product_list_item.large-width-height,
    .full_width .qode_product_list_pinterest_holder.two_columns .qode_product_list_sizer,
    .qode_product_list_masonry_holder.four_columns .qode_product_list_item,
    .qode_product_list_masonry_holder.four_columns .qode_product_list_item.large-width-height,
    .qode_product_list_masonry_holder.four_columns .qode_product_list_sizer,
    .qode_product_list_masonry_holder.three_columns .qode_product_list_item,
    .qode_product_list_masonry_holder.three_columns .qode_product_list_item.large-width-height,
    .qode_product_list_masonry_holder.three_columns .qode_product_list_sizer,
    .qode_product_list_masonry_holder.two_columns .qode_product_list_item,
    .qode_product_list_masonry_holder.two_columns .qode_product_list_item.large-width-height,
    .qode_product_list_masonry_holder.two_columns .qode_product_list_sizer,
    .qode_product_list_pinterest_holder.four_columns .qode_product_list_item,
    .qode_product_list_pinterest_holder.four_columns .qode_product_list_item.large-width-height,
    .qode_product_list_pinterest_holder.four_columns .qode_product_list_sizer,
    .qode_product_list_pinterest_holder.three_columns .qode_product_list_item,
    .qode_product_list_pinterest_holder.three_columns .qode_product_list_item.large-width-height,
    .qode_product_list_pinterest_holder.three_columns .qode_product_list_sizer,
    .qode_product_list_pinterest_holder.two_columns .qode_product_list_item,
    .qode_product_list_pinterest_holder.two_columns .qode_product_list_item.large-width-height,
    .qode_product_list_pinterest_holder.two_columns .qode_product_list_sizer {
        width: 100%
    }

    .woocommerce-account .woocommerce-MyAccount-navigation {
        float: none;
        width: 100%;
        margin-bottom: 30px
    }

    .woocommerce-account .woocommerce-MyAccount-content {
        float: none;
        width: 100%
    }

    .woocommerce.single-product.qode-product-single-wide-gallery .product .images,
    .woocommerce.single-product.qode-product-single-wide-gallery .product .summary {
        clear: both;
        float: none;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box
    }

    .woocommerce.single-product.qode-product-single-wide-gallery .product .summary {
        padding-top: 20px
    }
}

@media only screen and (min-width: 600px) and (max-width:768px) {
    .woocommerce aside ul.product_list_widget li {
        text-align: center
    }

    .woocommerce aside ul.cart_list li,
    .woocommerce-page aside ul.cart_list li,
    .woocommerce.qode-content-sidebar-responsive aside ul.product_list_widget li {
        text-align: left
    }

    .woocommerce .widget .star-rating,
    .woocommerce-page .widget .star-rating {
        margin-left: auto;
        margin-right: auto
    }
}

@media only screen and (max-width: 600px) {

    .columns-2 .woocommerce_with_sidebar ul.products li.product,
    .columns-2 ul.products li.product,
    .columns-3 .woocommerce_with_sidebar ul.products li.product,
    .columns-3 ul.products li.product {
        width: 100%;
        float: none
    }

    .woocommerce ul.tabs li a {
        padding: 0 18px
    }

    .woocommerce .summary {
        margin-bottom: 0
    }

    .woocommerce .addresses header h3 {
        font-size: 18px
    }

    .woocommerce div.cart-collaterals div.cart_totals,
    .woocommerce-page .div.cart-collaterals div.cart_totals {
        float: none;
        margin-bottom: 20px;
        width: auto
    }

    .woocommerce div.cart-collaterals .woocommerce-shipping-calculator,
    .woocommerce-page .div.cart-collaterals .woocommerce-shipping-calculator {
        float: none;
        width: auto
    }

    .woocommerce table.cart div.coupon,
    .woocommerce-page table.cart div.coupon {
        float: none;
        margin-bottom: 10px
    }

    .woocommerce form.check-login .form-row {
        width: 100%;
        float: none
    }

    .woocommerce form.check-login .form-row input[type=text] {
        width: 92%
    }

    .woocommerce aside ul.product_list_widget li img {
        float: left;
        width: auto
    }

    .woocommerce .widget  .searchForm input[type=text],
    .woocommerce-page .widget  .searchForm input[type=text] {
        width: 82.73381294964029%
    }

    .woocommerce .content .container .column1,
    .woocommerce-page .content .container .column1 {
        margin-bottom: 50px
    }

    .woocommerce form.checkout_coupon input.button {
        width: 100%;
        margin-top: 15px
    }

    .woocommerce-page.woocommerce-checkout div.coupon .input-text {
        width: 94.33333333333333%;
        padding-left: 2.833333333333333%;
        padding-right: 2.833333333333333%
    }

    .woocommerce .change-pass-field {
        padding-left: 1.904761904761905%;
        padding-right: 1.904761904761905%;
        width: 96.19047619047619%;
        display: block;
        margin-right: 0;
        margin-bottom: 20px
    }

    .woocommerce table.cart div.coupon .input-text {
        padding: 9px 3.869047619047619%;
        width: 92%;
        margin-bottom: 10px
    }

    .woocommerce table.cart td.actions input[type=submit] {
        display: block;
        width: 100%;
        padding-left: 22px !important;
        padding-right: 22px !important;
        position: relative;
        top: 3px
    }

    .woocommerce table.cart td.actions div.coupon input[type=submit] {
        margin-bottom: 0
    }

    .woocommerce .woocommerce-message {
        text-align: center
    }

    .woocommerce .woocommerce-message a.button {
        float: none;
        margin-top: 15px;
        display: inline-block
    }

    .woocommerce .woocommerce-message p {
        display: inline-block
    }

    .woocommerce #shiptobilling,
    .woocommerce-page #shiptobilling {
        position: static
    }

    .qode_product_list_holder ul li,
    .qode_product_list_holder.three_columns ul li {
        width: 100%;
        float: none
    }

    .qode_product_list_holder.three_columns ul li:nth-child(2n),
    .qode_product_list_holder.two_columns ul li:nth-child(2n) {
        background-color: #f4f4f4
    }

    .qode_product_list_holder.three_columns ul li:nth-child(2n+1),
    .qode_product_list_holder.two_columns ul li:nth-child(2n+1) {
        background-color: #fff
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product.qode-product-with-gallery .woocommerce-main-image {
        width: 100%;
        float: none
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product.qode-product-with-gallery .thumbnails {
        float: none;
        width: 100%;
        margin: 0
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product.qode-product-with-gallery .thumbnails a {
        display: inline-block;
        width: 31.06060606060606%;
        margin-right: 3.409090909090909%;
        margin-bottom: 15px
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product.qode-product-with-gallery .thumbnails a:last-child {
        margin-right: 0
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .single-onsale {
        left: 20px
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs {
        padding: 50px 0 60px
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs .tabs-nav li {
        display: block;
        width: 100%;
        text-align: center;
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .q_tabs .tabs-nav li a {
        padding: 5px 0;
        height: auto;
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images.qode-add-gallery-and-zoom-support .flex-viewport {
        margin: 0 5px;
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images.qode-add-gallery-and-zoom-support .flex-control-nav {
        position: relative;
        width: 100%;
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images.qode-add-gallery-and-zoom-support .flex-control-nav li {
        width: 50%;
        float: left;
        padding: 10px 5px 0;
        box-sizing: border-box;
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product .images.qode-add-gallery-and-zoom-support .flex-control-nav li img {
        width: 100%;
    }

    .woocommerce.single-product.qode-product-single-tabs-on-bottom .product:not(.qode-product-with-gallery) .images.qode-add-gallery-and-zoom-support {
        padding-left: 0;
    }

    .qode_product_list_holder .product_list_inner {
        padding: 30px 20px !important;
    }

    .woocommerce .product .images.woocommerce-product-gallery--columns-4 .woocommerce-product-gallery__image:not(:first-child) {
        width: 90px;
    }

    .woocommerce table.cart td.actions>.checkout-button,
    .woocommerce-page table.cart td.actions>.checkout-button {
        float: none;
        padding-left: 22px !important;
        padding-right: 22px !important;
        margin-left: 0;
        width: 100%;
    }
}

@media only screen and (max-width: 480px) {
    ul.products.columns-4 li.product {
        width: 100%;
        margin-right: 0
    }

    .woocommerce .woocommerce-ordering,
    .woocommerce .woocommerce-result-count {
        float: none;
        width: 100%;
        text-align: center
    }

    .woocommerce .woocommerce-result-count {
        margin-top: 15px;
        margin-bottom: 10px
    }

    .woocommerce ul.tabs li a {
        font-size: 12px;
        padding: 0 8px
    }

    .woocommerce #customer_login .span-3 {
        width: 100%;
        float: none;
        margin-right: 0
    }

    .woocommerce #customer_login #reg_email,
    .woocommerce #customer_login input[type=text],
    .woocommerce #customer_login input[type=password],
    .woocommerce #customer_login input[type=email] {
        width: 87.5%
    }

    .woocommerce .addresses .col-1,
    .woocommerce .addresses .col-2 {
        width: 100%
    }

    .woocommerce .addresses .col-1 {
        margin-bottom: 20px
    }

    .woocommerce #shiptobilling,
    .woocommerce-page #shiptobilling {
        float: none;
        width: auto;
        margin-right: 0;
        margin-bottom: 15px
    }

    .woocommerce form.checkout #order_review_heading {
        margin-top: 0
    }

    .woocommerce form.checkout .col2-set .form-row.notes textarea {
        margin-bottom: 0
    }

    .woocommerce-account .addresses .col-1 address,
    .woocommerce-checkout .addresses .col-1 address {
        margin-bottom: 30px
    }

    .woocommerce-checkout .order_details.clearfix li.method,
    .woocommerce-checkout .order_details.clearfix li.total {
        margin-top: 15px
    }

    .woocommerce .widget  .searchForm input[type=text],
    .woocommerce-page .widget  .searchForm input[type=text] {
        width: 78.85906040268456%
    }

    .woocommerce .addresses .col-1.address,
    .woocommerce .addresses .col-2.address {
        width: 48%
    }

    .woocommerce .checkout table.shop_table thead tr th,
    .woocommerce table.cart thead tr th,
    .woocommerce-account table.my_account_orders thead tr th,
    .woocommerce-cart table.cart tbody tr td,
    .woocommerce-checkout .checkout table tbody tr td,
    .woocommerce-page .checkout table.shop_table thead tr th,
    .woocommerce-page table.cart thead tr th,
    .woocommerce-page table.my_account_orders thead tr th {
        padding: 8px 3px;
    }

    .woocommerce ul.products li.product {
        flex: 0 1 100%;
    }

    .woocommerce ul.products li.product .image-wrapper img {
        width: 100%;
    }

    .woocommerce-message.call_to_action .qbutton {
        margin: 15px 0 0;
    }

    .woocommerce #content .quantity .minus,
    .woocommerce #content .quantity .plus,
    .woocommerce .quantity .minus,
    .woocommerce .quantity .plus,
    .woocommerce-page #content .quantity .minus,
    .woocommerce-page #content .quantity .plus,
    .woocommerce-page .quantity .minus,
    .woocommerce-page .quantity .plus {
        width: 18px;
        height: 18px;
        padding: 0 0 5px
    }

    .woocommerce #content .quantity input.qty,
    .woocommerce .quantity input.qty,
    .woocommerce-page #content .quantity input.qty,
    .woocommerce-page .quantity input.qty {
        width: 15px;
        height: 26px;
        line-height: 25px;
        padding: 0;
        font-size: 15px
    }

    .woocommerce ul.products li.product:hover .add-to-cart-button,
    .woocommerce ul.products li.product:hover .added_to_cart {
        display: none;
        opacity: 0;
        height: 0
    }

    .woocommerce .quantity .plus {
        top: 0
    }

    .woocommerce.single-product.qode-product-single-wide-gallery .product .images .thumbnails a,
    .woocommerce.single-product.qode-product-single-wide-gallery .product .images .woocommerce-main-image,
    .woocommerce.single-product.qode-product-single-wide-gallery .product .images .woocommerce-product-gallery__image {
        width: 100%;
        margin-bottom: 10px
    }
}

@media only screen and (max-width: 420px) {
    .shopping_cart_outer {
        display: none
    }
}

.wishlist_table.mobile li {
    position: relative;
    margin: 0 0 50px
}

.wishlist_table.mobile li .additional-info-wrapper .product-remove {
    position: absolute;
    top: 0;
    right: 0
}

.wishlist_table.images_grid li .item-details table.item-details-table td,
.wishlist_table.mobile li .item-details table.item-details-table td,
.wishlist_table.mobile li table.additional-info td,
.wishlist_table.modern_grid li .item-details table.item-details-table td {
    font-size: inherit
}

.wishlist_table.images_grid li .item-details table.item-details-table td.label,
.wishlist_table.mobile li .item-details table.item-details-table td.label,
.wishlist_table.mobile li table.additional-info td.label,
.wishlist_table.modern_grid li .item-details table.item-details-table td.label {
    padding-left: 0
}

.wishlist_table.images_grid li .item-details h3,
.wishlist_table.mobile li .item-details h3,
.wishlist_table.mobile li .item-wrapper,
.wishlist_table.modern_grid li .item-details h3 {
    margin-bottom: 0
}

.wishlist_table.images_grid li .item-details table.item-details-table,
.wishlist_table.mobile li .item-details table.item-details-table {
    margin: 0
}

.wishlist_table.mobile li .item-wrapper .product-thumbnail {
    display: block
}

.wishlist_table.mobile li .item-wrapper .item-details {
    display: block;
    width: 100%
}

.wishlist_table.mobile li .additional-info-wrapper .product-add-to-cart a {
    text-align: left;
    margin: 10px 0 0 !important
}

@media only screen and (max-width: 480px) {
    .woocommerce-page.woocommerce-cart .woocommerce table.cart {
        display: block;
        text-align: center
    }

    .woocommerce-page.woocommerce-cart .woocommerce table.cart thead {
        display: none
    }

    .woocommerce-page.woocommerce-cart .woocommerce table.cart tbody {
        display: block
    }

    .woocommerce-page.woocommerce-cart .woocommerce table.cart tbody tr {
        display: block;
        width: 100%;
        padding: 10px 0
    }

    .woocommerce-page.woocommerce-cart .woocommerce table.cart tbody tr:not(:nth-last-child(2)) {
        border-bottom: none
    }

    .woocommerce-page.woocommerce-cart .woocommerce table.cart tbody td {
        display: block;
        text-align: center
    }

    .woocommerce-page.woocommerce-cart .woocommerce table.cart tbody td img {
        margin: 0 auto
    }
}

@media only screen and (max-width: 1024px) {
    #yith-quick-view-modal #yith-quick-view-content .summary {
        padding: 88px 50px 50px
    }

    #yith-quick-view-modal #yith-quick-view-content .summary .single_add_to_cart_button {
        padding: 8px 45px
    }
}

@media only screen and (max-width: 768px) {
    body {
        position: relative
    }

    #yith-quick-view-modal {
        position: absolute;
        right: auto;
        bottom: auto;
        width: 100%;
        height: 100%
    }

    #yith-quick-view-modal .yith-wcqv-wrapper {
        position: absolute
    }

    #yith-quick-view-modal #yith-quick-view-content .images,
    #yith-quick-view-modal #yith-quick-view-content .summary {
        width: 100%
    }

    #yith-quick-view-modal #yith-quick-view-content .summary {
        padding: 46px 50px 50px
    }
}

@media only screen and (max-width: 600px) {

    #yith-quick-view-modal #yith-quick-view-content .images,
    #yith-quick-view-modal #yith-quick-view-content .summary {
        width: 100%
    }

    #yith-quick-view-modal #yith-quick-view-content .summary {
        padding: 26px 40px
    }

    #yith-quick-view-modal #yith-quick-view-content .summary .variations tbody {
        width: 100%;
        display: block
    }

    #yith-quick-view-modal #yith-quick-view-content .summary .variations tbody tr {
        width: 100%;
        padding-right: 0
    }

    #yith-quick-view-modal #yith-quick-view-content .summary .single_add_to_cart_button {
        text-align: center;
        width: 100%;
        margin-top: 15px
    }

    #yith-quick-view-modal #yith-quick-view-content .summary div[itemprop=offers],
    #yith-quick-view-modal #yith-quick-view-content .summary form.cart {
        margin: 0 0 20px
    }

    #yith-quick-view-modal #yith-quick-view-content .summary .qode-quantity-buttons,
    #yith-quick-view-modal #yith-quick-view-content .summary table.group_table .qode-quantity-buttons {
        width: 100%;
        text-align: center
    }

    #yith-quick-view-modal #yith-quick-view-content .summary div[itemprop=description] {
        display: none
    }
}

@media only screen and (max-width: 768px) {

    .woocommerce-wishlist .woocommerce-error,
    .woocommerce-wishlist .woocommerce-info,
    .woocommerce-wishlist .woocommerce-message {
        padding: 17px 25px
    }
}

@media only screen and (max-width: 600px) {

    .woocommerce-wishlist .woocommerce-error,
    .woocommerce-wishlist .woocommerce-info,
    .woocommerce-wishlist .woocommerce-message {
        padding: 14px 20px;
        line-height: 2em
    }

    .woocommerce-wishlist .woocommerce-error a,
    .woocommerce-wishlist .woocommerce-info a,
    .woocommerce-wishlist .woocommerce-message a {
        float: none;
        margin: 0 70% 15px 0
    }

    .woocommerce-wishlist table.wishlist_table tr .product-add-to-cart,
    .woocommerce-wishlist table.wishlist_table tr .product-stock-status {
        display: none !important
    }
}