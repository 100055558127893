@media only screen and (min-width: 1560px) and (max-width:1860px) {
    .full_width .projects_masonry_holder .portfolio_masonry_item, .full_width .projects_masonry_holder .portfolio_masonry_item.large_height {
        width: 24.98%!important;
        margin: 0!important
    }
    .full_width .projects_masonry_holder .portfolio_masonry_item.large_width, .full_width .projects_masonry_holder .portfolio_masonry_item.large_width_height {
        width: 49.96%!important;
        margin: 0!important
    }
}

@media only screen and (min-width: 1000px) and (max-width:1660px) {
    .blog_holder.masonry_full_width .blog_holder_grid_sizer, .blog_holder.masonry_full_width article, .full_width .q_masonry_blog article {
        width: 23%
    }
    .blog_holder.masonry_full_width .blog_holder_grid_gutter {
        width: 2.6%
    }
}

@media only screen and (min-width: 1000px) and (max-width:1560px) {
    .projects_holder.v6.hover_text.no_space .filler, .projects_holder.v6.hover_text.no_space .mix, .projects_holder.v6.standard_no_space .filler, .projects_holder.v6.standard_no_space .mix {
        width: 19.99%
    }
    .safari_browser .projects_holder.v6.hover_text.no_space .filler, .safari_browser .projects_holder.v6.hover_text.no_space .mix, .safari_browser .projects_holder.v6.standard_no_space .filler, .safari_browser .projects_holder.v6.standard_no_space .mix {
        width: 19.93%
    }
    .touchevents footer.uncover {
        position: relative;
        top: 0;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        -ms-transition: none;
        transition: none
    }
    .full_width .projects_masonry_holder .portfolio_masonry_item, .full_width .projects_masonry_holder .portfolio_masonry_item.large_height {
        width: 33.3%!important;
        margin: 0!important
    }
    .full_width .projects_masonry_holder .portfolio_masonry_item.large_width, .full_width .projects_masonry_holder .portfolio_masonry_item.large_width_height {
        width: 66.6%!important;
        margin: 0!important
    }
}

@media only screen and (min-width: 1500px) and (max-width:1660px) {
    .qode_grid_1400 .boxed div.section .section_inner, .qode_grid_1400 .carousel-inner .slider_content_outer, .qode_grid_1400 .container_inner, .qode_grid_1400 .container_inner nav.content_menu, .qode_grid_1400 .elementor-container .elementor-widget-wrap>.qode_elementor_container_inner, .qode_grid_1400 .elementor-row .elementor-widget-wrap>.qode_elementor_container_inner, .qode_grid_1400 .footer_bottom_border.in_grid, .qode_grid_1400 .footer_top_border.in_grid, .qode_grid_1400 .full_width .parallax_content, .qode_grid_1400 .qode_elementor_container_inner, .qode_grid_1400 .steps_holder_inner, .qode_grid_1400 .title_border_in_grid_holder, .qode_grid_1400 div.grid_section .section_inner, .qode_grid_1400 nav.content_menu ul {
        width: 1100px
    }
}

@media only screen and (min-width: 1400px) and (max-width:1560px) {
    .qode_grid_1300 .boxed div.section .section_inner, .qode_grid_1300 .carousel-inner .slider_content_outer, .qode_grid_1300 .container_inner, .qode_grid_1300 .container_inner nav.content_menu, .qode_grid_1300 .elementor-container .elementor-widget-wrap>.qode_elementor_container_inner, .qode_grid_1300 .elementor-row .elementor-widget-wrap>.qode_elementor_container_inner, .qode_grid_1300 .footer_bottom_border.in_grid, .qode_grid_1300 .footer_top_border.in_grid, .qode_grid_1300 .full_width .parallax_content, .qode_grid_1300 .qode_elementor_container_inner, .qode_grid_1300 .steps_holder_inner, .qode_grid_1300 .title_border_in_grid_holder, .qode_grid_1300 div.grid_section .section_inner, .qode_grid_1300 nav.content_menu ul {
        width: 1100px
    }
}

@media only screen and (min-width: 1300px) and (max-width:1560px) {
    .qode_grid_1200 .boxed div.section .section_inner, .qode_grid_1200 .carousel-inner .slider_content_outer, .qode_grid_1200 .container_inner, .qode_grid_1200 .container_inner nav.content_menu, .qode_grid_1200 .elementor-container .elementor-widget-wrap>.qode_elementor_container_inner, .qode_grid_1200 .elementor-row .elementor-widget-wrap>.qode_elementor_container_inner, .qode_grid_1200 .footer_bottom_border.in_grid, .qode_grid_1200 .footer_top_border.in_grid, .qode_grid_1200 .full_width .parallax_content, .qode_grid_1200 .qode_elementor_container_inner, .qode_grid_1200 .steps_holder_inner, .qode_grid_1200 .title_border_in_grid_holder, .qode_grid_1200 div.grid_section .section_inner, .qode_grid_1200 nav.content_menu ul {
        width: 1100px
    }
}

@media only screen and (min-width: 1000px) and (max-width:1460px) {
    body.boxed .carousel-inner, body.boxed .footer_inner, body.boxed .full_width .parallax_content, body.boxed .header_inner, body.boxed .wrapper_inner, body.boxed footer {
        width: 1000px;
    }
    .boxed.vertical_menu_enabled .carousel-inner {
        width: 1000px!important;
    }
    body.boxed footer.uncover {
        width: 100%;
    }
    .boxed div.section .section_inner, .carousel-inner .slider_content_outer, .container_inner, .container_inner nav.content_menu, .elementor-container .elementor-widget-wrap>.qode_elementor_container_inner, .elementor-row .elementor-widget-wrap>.qode_elementor_container_inner, .full_width .parallax_content, .qode_elementor_container_inner, .steps_holder_inner, div.grid_section .section_inner, nav.content_menu ul {
        width: 950px;
    }
    .projects_holder.v6 .mix, .projects_holder.v6.hover_text .mix {
        width: 18.5%;
        margin: 0 0 1.7%;
    }
    .projects_holder.v6 .filler, .projects_holder.v6.hover_text .filler {
        width: 18.5%
    }
    .blog_small_image .post_info_left, .blog_small_image.blog_holder article .post_description .post_description_left, .blog_small_image.blog_holder article .post_description .post_description_right {
        float: none
    }
    .blog_small_image .post_info_left, .blog_small_image.blog_holder article .post_description .post_description_left {
        margin-top: 10px
    }
    .blog_holder.blog_small_image article .post_comments {
        margin-left: 0
    }
    .cover_boxes ul li, .cover_boxes ul li .box .thumb {
        width: 227px
    }
    .cover_boxes ul li .box, .cover_boxes ul li.act {
        width: 455px
    }
    .cover_boxes ul li .box .box_content {
        left: 247px;
        width: 207px
    }
    body.boxed .cover_boxes ul li, body.boxed .cover_boxes ul li .box .thumb {
        width: 240px
    }
    body.boxed .cover_boxes ul li .box, body.boxed .cover_boxes ul li.act {
        width: 480px
    }
    body.boxed .cover_boxes ul li .box .box_content {
        left: 260px;
        width: 220px
    }
}

@media only screen and (min-width: 1260px) and (max-width:1460px) {
    .qode_pricing_tables.four_columns .price_in_table .price {
        font-size: 60px
    }
}

@media only screen and (min-width: 1000px) and (max-width:1260px) {
    body.boxed .carousel-inner, body.boxed .footer_inner, body.boxed .full_width .parallax_content, body.boxed .header_inner, body.boxed .wrapper_inner, body.boxed footer {
        width: 818px
    }
    .boxed.vertical_menu_enabled .carousel-inner {
        width: 818px!important
    }
    .boxed div.section .section_inner, .carousel-inner .slider_content_outer, .container_inner, .elementor-container .elementor-widget-wrap>.qode_elementor_container_inner, .elementor-row .elementor-widget-wrap>.qode_elementor_container_inner, .full_width .parallax_content, .paspartu_enabled div.grid_section .section_inner, .paspartu_enabled.vertical_menu_enabled .container_inner, .qode_elementor_container_inner, .steps_holder_inner, div.grid_section .section_inner, nav.content_menu {
        width: 768px
    }
    .full_width .projects_holder_outer.v5, .full_width .projects_holder_outer.v6 {
        width: 100%
    }
    nav.mobile_menu>ul {
        width: 768px;
        margin: 0 auto
    }
    .title {
        height: auto!important;
        padding: 30px 0!important
    }
    .title_outer.with_image .title {
        padding: 0!important
    }
    .title.has_background, .title.has_fixed_background {
        background: 0 0!important;
        height: auto!important;
        padding: 0!important
    }
    .title_outer.animate_title_area {
        height: auto!important;
        opacity: 1!important
    }
    .title .not_responsive {
        display: block
    }
    .title_outer.with_image .title .title_subtitle_holder {
        padding: 0!important
    }
    .title h1 {
        margin: 0
    }
    .side_menu_button {
        height: 100px!important
    }
    header.menu_bottom.has_header_fixed_right .side_menu_button {
        height: 130px!important
    }
    .header_top .right #lang_sel ul ul, .header_top .right #lang_sel_click ul ul {
        right: 0
    }
    nav.content_menu {
        position: relative!important;
        top: 0!important;
        margin: 0 auto
    }
    nav.content_menu ul.menu {
        display: none
    }
    nav.content_menu .nav_select_menu {
        display: block
    }
    nav.content_menu .back_outer {
        display: none
    }
    .ls-nav-next, .ls-nav-prev {
        top: 50%!important
    }
    .projects_holder.v4 .mix, .projects_holder.v4.hover_text .mix, .projects_holder.v5 .mix, .projects_holder.v5.hover_text .mix, .projects_holder.v6 .mix, .projects_holder.v6.hover_text .mix {
        width: 23%;
        margin: 0 0 3%
    }
    .projects_holder.v4 .filler, .projects_holder.v4.hover_text .filler, .projects_holder.v5 .filler, .projects_holder.v5.hover_text .filler, .projects_holder.v6 .filler, .projects_holder.v6.hover_text .filler {
        width: 23%
    }
    .projects_holder.v2 .mix, .projects_holder.v3 .mix {
        margin: 0 0 3%
    }
    .projects_holder.v2.hover_text .mix, .projects_holder.v3.hover_text .mix, .projects_holder.v4.hover_text .mix, .projects_holder.v5.hover_text .mix, .projects_holder.v6.hover_text .mix {
        margin: 0 0 2.8%
    }
    .projects_holder.v4.hover_text.no_space .filler, .projects_holder.v4.hover_text.no_space .mix, .projects_holder.v4.standard_no_space .filler, .projects_holder.v4.standard_no_space .mix, .projects_holder.v5.hover_text.no_space .filler, .projects_holder.v5.hover_text.no_space .mix, .projects_holder.v5.standard_no_space .filler, .projects_holder.v5.standard_no_space .mix, .projects_holder.v6.hover_text.no_space .filler, .projects_holder.v6.hover_text.no_space .mix, .projects_holder.v6.standard_no_space .filler, .projects_holder.v6.standard_no_space .mix {
        width: 24.99%
    }
    .safari_browser .projects_holder.v4.hover_text.no_space .filler, .safari_browser .projects_holder.v4.hover_text.no_space .mix, .safari_browser .projects_holder.v4.standard_no_space .filler, .safari_browser .projects_holder.v4.standard_no_space .mix, .safari_browser .projects_holder.v5.hover_text.no_space .filler, .safari_browser .projects_holder.v5.hover_text.no_space .mix, .safari_browser .projects_holder.v5.standard_no_space .filler, .safari_browser .projects_holder.v5.standard_no_space .mix, .safari_browser .projects_holder.v6.hover_text.no_space .filler, .safari_browser .projects_holder.v6.hover_text.no_space .mix, .safari_browser .projects_holder.v6.standard_no_space .filler, .safari_browser .projects_holder.v6.standard_no_space .mix {
        width: 24.95%
    }
    .projects_holder.v3.standard_no_space .mix, .projects_holder.v4.standard_no_space .mix, .projects_holder.v5.standard_no_space .mix, .projects_holder.v6.standard_no_space .mix {
        margin: 0
    }
    .projects_holder article .feature_holder .feature_holder_icons {
        margin: 10px 0 0
    }
    .full_width .projects_masonry_holder .portfolio_masonry_item, .full_width .projects_masonry_holder .portfolio_masonry_item.large_height {
        width: 49.9%!important;
        margin: 0!important
    }
    .full_width .projects_masonry_holder .portfolio_masonry_item.large_width, .full_width .projects_masonry_holder .portfolio_masonry_item.large_width_height {
        width: 100%!important;
        margin: 0!important
    }
    .blog_holder.masonry .blog_holder_grid_sizer, .blog_holder.masonry article, .q_masonry_blog article {
        width: 47%
    }
    .blog_holder.masonry .blog_holder_grid_gutter {
        width: 6%
    }
    .blog_holder.masonry_full_width .blog_holder_grid_sizer, .blog_holder.masonry_full_width article, .full_width .q_masonry_blog article {
        width: 30.5%
    }
    .blog_holder.masonry_full_width .blog_holder_grid_gutter {
        width: 4.2%
    }
    .box_image_holder .box_icon .fa-stack {
        font-size: 3em
    }
    .q_steps_holder .step1 {
        left: -81px
    }
    .q_steps_holder .step2 {
        left: 122px
    }
    .q_steps_holder .step3 {
        left: 350px
    }
    .q_steps_holder .step4 {
        left: 560px
    }
    .q_steps_holder .step1, .q_steps_holder .step2, .q_steps_holder .step3, .q_steps_holder .step4 {
        margin: 0 0 30px
    }
    .qode_call_to_action .two_columns_75_25>.column2>.column_inner {
        padding: 0
    }
    .qode_call_to_action .qbutton {
        padding: 0 10px!important;
        font-size: 10px
    }
    .qode_clients.five_columns .qode_client_holder, .qode_clients.four_columns .qode_client_holder, .qode_clients.six_columns .qode_client_holder {
        width: 33.33333333333333%
    }
    .qode_clients.five_columns .qode_client_holder:nth-child(5n) .qode_client_holder_inner:before, .qode_clients.four_columns .qode_client_holder:nth-child(4n) .qode_client_holder_inner:before, .qode_clients.six_columns .qode_client_holder:nth-child(6n) .qode_client_holder_inner:before {
        border-right-width: 1px
    }
    .qode_clients.five_columns .qode_client_holder:nth-child(3n) .qode_client_holder_inner:before, .qode_clients.four_columns .qode_client_holder:nth-child(3n) .qode_client_holder_inner:before, .qode_clients.six_columns .qode_client_holder:nth-child(3n) .qode_client_holder_inner:before {
        border-right-width: 0
    }
    .animated_icons_with_text.five_columns .animated_icon_with_text_holder, .animated_icons_with_text.four_columns .animated_icon_with_text_holder, .animated_icons_with_text.three_columns .animated_icon_with_text_holder {
        width: 50%
    }
    .animated_icons_with_text.five_columns .animated_icon_with_text_holder:nth-child(5n) .animated_icon_with_text_inner:before, .animated_icons_with_text.four_columns .animated_icon_with_text_holder:nth-child(4n) .animated_icon_with_text_inner:before, .animated_icons_with_text.three_columns .animated_icon_with_text_holder:nth-child(3n) .animated_icon_with_text_inner:before {
        border-right-width: 1px
    }
    .animated_icons_with_text.five_columns .animated_icon_with_text_holder:nth-child(2n) .animated_icon_with_text_inner:before, .animated_icons_with_text.four_columns .animated_icon_with_text_holder:nth-child(2n) .animated_icon_with_text_inner:before, .animated_icons_with_text.three_columns .animated_icon_with_text_holder:nth-child(2n) .animated_icon_with_text_inner:before {
        border-right-width: 0
    }
    .cover_boxes ul {
        margin: 0 -8px 0 0
    }
    .cover_boxes ul li {
        margin-right: 8px
    }
    .cover_boxes ul li, .cover_boxes ul li .box .thumb {
        width: 187px
    }
    .cover_boxes ul li .box, .cover_boxes ul li.act {
        width: 374px
    }
    .cover_boxes ul li .box .box_content {
        left: 197px;
        width: 177px
    }
    body.boxed .cover_boxes ul li, body.boxed .cover_boxes ul li .box .thumb {
        width: 199px
    }
    body.boxed .cover_boxes ul li .box, body.boxed .cover_boxes ul li.act {
        width: 400px
    }
    body.boxed .cover_boxes ul li .box .box_content {
        left: 209px;
        width: 189px
    }
    .cover_boxes ul li .box .box_content p {
        max-height: 84px;
        overflow: hidden
    }
    .cover_boxes ul li .box h3 {
        margin: 0 0 5px
    }
    .cover_boxes ul li .box .qbutton {
        margin: 8px 0 0
    }
    .latest_post_holder.four_columns>ul>li {
        width: 49%;
        margin: 0 2% 25px 0!important
    }
    .latest_post_holder.boxes.four_columns>ul>li:nth-child(2n) {
        margin: 0 0 25px!important
    }
    .q_team .q_team_social_holder .fa-stack {
        margin: 0 4px 3px 0;
        width: 1.6em;
        height: 1.6em;
        line-height: 1.6em
    }
    .q_tabs .tabs-nav li a {
        padding: 0 7px
    }
    .price_table_inner ul li.table_title {
        padding: 18px 15px 17px
    }
    .price_table_inner ul li {
        padding: 11px 10px
    }
    .price_table_inner ul li.pricing_table_content {
        padding: 20px 12px 10px
    }
    .price_button .qbutton {
        margin-bottom: 5px
    }
    .content {
        margin-top: 0!important
    }
    .title .title_holder {
        height: 100%!important;
        padding: 0!important
    }
    .q_counter_holder.center {
        padding: 20px 30px
    }
    .wpb_flexslider.flexslider.have_frame {
        width: 566px
    }
    .frame_holder>.wpb_wrapper {
        padding: 30px 0 66px
    }
    .qode_pricing_tables.four_columns .q_price_table {
        width: 48.75%;
        margin-bottom: 15px
    }
    .qode_pricing_tables.four_columns .q_price_table:nth-child(2n+1) {
        margin-left: 0;
        clear: both
    }
    .gallery_holder ul.v5 li, .wpb_gallery_slides .gallery_holder ul.v5 li {
        width: 25%
    }
}

@media only screen and (min-width: 1260px) and (max-width:1360px) {
    .q_percentage_with_icon {
        width: 80%!important
    }
}

@media only screen and (min-width: 1000px) and (max-width:1160px) {
    .projects_holder.v4.hover_text.no_space .filler, .projects_holder.v4.hover_text.no_space .mix, .projects_holder.v4.standard_no_space .filler, .projects_holder.v4.standard_no_space .mix, .projects_holder.v5.hover_text.no_space .filler, .projects_holder.v5.hover_text.no_space .mix, .projects_holder.v5.standard_no_space .filler, .projects_holder.v5.standard_no_space .mix, .projects_holder.v6.hover_text.no_space .filler, .projects_holder.v6.hover_text.no_space .mix, .projects_holder.v6.standard_no_space .filler, .projects_holder.v6.standard_no_space .mix {
        width: 33.32%
    }
    .safari_browser .projects_holder.v4.hover_text.no_space .filler, .safari_browser .projects_holder.v4.hover_text.no_space .mix, .safari_browser .projects_holder.v4.standard_no_space .filler, .safari_browser .projects_holder.v4.standard_no_space .mix, .safari_browser .projects_holder.v5.hover_text.no_space .filler, .safari_browser .projects_holder.v5.hover_text.no_space .mix, .safari_browser .projects_holder.v5.standard_no_space .filler, .safari_browser .projects_holder.v5.standard_no_space .mix, .safari_browser .projects_holder.v6.hover_text.no_space .filler, .safari_browser .projects_holder.v6.hover_text.no_space .mix, .safari_browser .projects_holder.v6.standard_no_space .filler, .safari_browser .projects_holder.v6.standard_no_space .mix {
        width: 33.3%
    }
    .q_percentage {
        width: 140px!important;
        height: 140px!important;
        line-height: 140px!important
    }
    .q_percentage_with_icon {
        width: 90%!important;
        height: 150px!important;
        line-height: 150px!important
    }
    .q_percentage canvas, .q_percentage_with_icon canvas {
        width: 100%!important;
        height: 100%!important
    }
    .latest_post_two_holder.four_columns>ul>li, .latest_post_two_holder.three_columns>ul>li {
        width: 50%
    }
    .latest_post_two_holder.three_columns>ul>li:nth-child(3n+1) {
        clear: none
    }
    .latest_post_two_holder.four_columns>ul>li:nth-child(2n+1), .latest_post_two_holder.three_columns>ul>li:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (min-width: 1000px) and (max-width:1060px) {
    body.boxed .carousel-inner, body.boxed .footer_inner, body.boxed .full_width .parallax_content, body.boxed .header_inner, body.boxed .wrapper_inner, body.boxed footer {
        width: 650px
    }
    .boxed.vertical_menu_enabled .carousel-inner {
        width: 650px!important
    }
    .boxed div.section .section_inner, .carousel-inner .slider_content_outer, .container_inner, .elementor-container .elementor-widget-wrap>.qode_elementor_container_inner, .elementor-row .elementor-widget-wrap>.qode_elementor_container_inner, .full_width .parallax_content, .paspartu_enabled div.grid_section .section_inner, .qode_elementor_container_inner, .steps_holder_inner, div.grid_section .section_inner, nav.content_menu {
        width: 600px
    }
    .projects_holder.v3 .mix, .projects_holder.v3.hover_text .mix, .projects_holder.v4 .mix, .projects_holder.v4.hover_text .mix, .projects_holder.v5 .mix, .projects_holder.v5.hover_text .mix, .projects_holder.v6 .mix, .projects_holder.v6.hover_text .mix {
        width: 31%;
        margin: 0 0 4%
    }
    .projects_holder.v3 .filler, .projects_holder.v3.hover_text .filler, .projects_holder.v4 .filler, .projects_holder.v4.hover_text .filler, .projects_holder.v5 .filler, .projects_holder.v5.hover_text .filler, .projects_holder.v6 .filler, .projects_holder.v6.hover_text .filler {
        width: 31%
    }
    .projects_holder.v2 .mix {
        width: 48.3%;
        margin: 0 0 3.6%
    }
    .projects_holder.v2 .filler {
        width: 48.3%
    }
    .projects_holder.v2.hover_text .mix, .projects_holder.v3.hover_text .mix, .projects_holder.v4.hover_text .mix, .projects_holder.v5.hover_text .mix, .projects_holder.v6.hover_text .mix {
        margin: 0 0 3.4%
    }
    .projects_holder.v2.hover_text.no_space .filler, .projects_holder.v2.hover_text.no_space .mix, .projects_holder.v2.standard_no_space .filler, .projects_holder.v2.standard_no_space .mix, .projects_holder.v3.hover_text.no_space .filler, .projects_holder.v3.hover_text.no_space .mix, .projects_holder.v3.standard_no_space .filler, .projects_holder.v3.standard_no_space .mix, .projects_holder.v4.hover_text.no_space .filler, .projects_holder.v4.hover_text.no_space .mix, .projects_holder.v4.standard_no_space .filler, .projects_holder.v4.standard_no_space .mix, .projects_holder.v5.hover_text.no_space .filler, .projects_holder.v5.hover_text.no_space .mix, .projects_holder.v5.standard_no_space .filler, .projects_holder.v5.standard_no_space .mix, .projects_holder.v6.hover_text.no_space .filler, .projects_holder.v6.hover_text.no_space .mix, .projects_holder.v6.standard_no_space .filler, .projects_holder.v6.standard_no_space .mix {
        width: 49.99%
    }
    .safari_browser .projects_holder.v2.hover_text.no_space .filler, .safari_browser .projects_holder.v2.hover_text.no_space .mix, .safari_browser .projects_holder.v2.standard_no_space .filler, .safari_browser .projects_holder.v2.standard_no_space .mix, .safari_browser .projects_holder.v3.hover_text.no_space .filler, .safari_browser .projects_holder.v3.hover_text.no_space .mix, .safari_browser .projects_holder.v3.standard_no_space .filler, .safari_browser .projects_holder.v3.standard_no_space .mix, .safari_browser .projects_holder.v4.hover_text.no_space .filler, .safari_browser .projects_holder.v4.hover_text.no_space .mix, .safari_browser .projects_holder.v4.standard_no_space .filler, .safari_browser .projects_holder.v4.standard_no_space .mix, .safari_browser .projects_holder.v5.hover_text.no_space .filler, .safari_browser .projects_holder.v5.hover_text.no_space .mix, .safari_browser .projects_holder.v5.standard_no_space .filler, .safari_browser .projects_holder.v5.standard_no_space .mix, .safari_browser .projects_holder.v6.hover_text.no_space .filler, .safari_browser .projects_holder.v6.hover_text.no_space .mix, .safari_browser .projects_holder.v6.standard_no_space .filler, .safari_browser .projects_holder.v6.standard_no_space .mix {
        width: 49.97%
    }
    .projects_holder.v2.standard_no_space .mix, .projects_holder.v3.standard_no_space .mix, .projects_holder.v4.standard_no_space .mix, .projects_holder.v5.standard_no_space .mix, .projects_holder.v6.standard_no_space .mix {
        margin: 0
    }
    .blog_holder.masonry .blog_holder_grid_sizer, .blog_holder.masonry article, .blog_holder.masonry_full_width .blog_holder_grid_sizer, .blog_holder.masonry_full_width article, .full_width .grid_section .q_masonry_blog article, .full_width .qode_elementor_container_inner .q_masonry_blog article, .full_width .qode_elementor_container_inner .q_masonry_blog_grid_sizer, .q_masonry_blog article {
        width: 46.5%
    }
    .blog_holder.masonry .blog_holder_grid_gutter, .blog_holder.masonry_full_width .blog_holder_grid_gutter {
        width: 7%
    }
    .blog_holder.blog_small_image article .post_text .post_text_inner {
        min-height: 0!important
    }
    .comment_holder .comment_number {
        display: none
    }
    .comment_holder .comments, .full_width .q_masonry_blog article, .two_columns_25_75 .blog_holder.masonry .blog_holder_grid_sizer, .two_columns_25_75 .blog_holder.masonry article, .two_columns_25_75 .q_masonry_blog article, .two_columns_33_66 .blog_holder.masonry .blog_holder_grid_sizer, .two_columns_33_66 .blog_holder.masonry article, .two_columns_33_66 .q_masonry_blog article, .two_columns_66_33 .blog_holder.masonry .blog_holder_grid_sizer, .two_columns_66_33 .blog_holder.masonry article, .two_columns_66_33 .q_masonry_blog article, .two_columns_75_25 .blog_holder.masonry .blog_holder_grid_sizer, .two_columns_75_25 .blog_holder.masonry article, .two_columns_75_25 .q_masonry_blog article {
        width: 100%
    }
    .two_columns_25_75 .blog_holder.masonry .blog_holder_grid_gutter, .two_columns_33_66 .blog_holder.masonry .blog_holder_grid_gutter, .two_columns_66_33 .blog_holder.masonry .blog_holder_grid_gutter, .two_columns_75_25 .blog_holder.masonry .blog_holder_grid_gutter {
        width: 0
    }
    div.comment_form {
        padding-top: 40px;
        border-top: 1px solid #ddd
    }
    .title {
        padding: 25px 0!important
    }
    .q_line_graf {
        margin: 0 0 25px;
        width: 100%
    }
    .q_pie_graf {
        width: 70%;
        margin: 0 auto 25px;
        float: none
    }
    .q_line_graf canvas, .q_line_graf_legend, .q_pie_graf canvas, .q_pie_graf_legend {
        width: 100%!important;
        height: auto!important
    }
    .q_icon_list p, .q_list.number.circle_number li {
        line-height: 37px
    }
    .q_list.number.circle_number ul>li:before {
        top: 0
    }
    .q_icon_list p {
        padding: 0 0 0 45px
    }
    .q_icon_list i {
        line-height: 35px;
        height: 35px;
        width: 35px
    }
    .grid2 .blog_holder article .post_description .post_description_left, .grid2 .blog_holder article .post_description .post_description_right, .grid2 .post_info_left {
        float: none
    }
    .grid2 .blog_holder article .post_description .post_description_left, .grid2 .post_info_left {
        margin-top: 10px
    }
    .grid2 .blog_holder article .post_comments {
        margin-left: 0
    }
    .blog_holder.blog_small_image article .post_image, .blog_holder.blog_small_image article .post_text {
        float: left;
        width: 100%
    }
    .cover_boxes ul, .cover_boxes ul li {
        width: 100%!important
    }
    .cover_boxes ul li {
        margin: 0 0 20px!important
    }
    .cover_boxes ul li, .cover_boxes ul li .box .thumb {
        width: 270px
    }
    .cover_boxes ul li .box, .cover_boxes ul li.act {
        width: 100%
    }
    .cover_boxes ul li .box .box_content {
        position: static;
        left: 0;
        float: right;
        width: 310px
    }
    .cover_boxes ul li .box .box_content p {
        height: auto;
        max-height: none;
        overflow: visible
    }
    .latest_post_holder.boxes>ul>li {
        margin: 0 2% 25px 0!important
    }
    .latest_post_holder.boxes.four_columns>ul>li, .latest_post_holder.boxes.three_columns>ul>li, .latest_post_holder.boxes.two_columns>ul>li {
        width: 49%
    }
    .latest_post_holder.boxes>ul>li:nth-child(2n) {
        margin: 0 0 25px!important
    }
    .q_circles_holder .q_circle_outer {
        width: 50%!important;
        margin: 0 0 30px
    }
    .q_circles_holder:before {
        display: none;
        width: 0;
        height: 0;
        border: 0
    }
    #submit_comment, .drop_down .wide .second ul li .qbutton, .drop_down .wide .second ul li ul li .qbutton, .load_more a, .qbutton, .qbutton.small {
        padding: 0 14px
    }
    .qbutton.big_large, .qbutton.big_large_full_width {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        padding: 0 20px
    }
    .qbutton.large {
        height: 42px;
        line-height: 42px;
        padding: 0 17px
    }
    #submit_comment, .load_more.tiny a, .qbutton.medium {
        height: 38px;
        line-height: 38px;
        padding: 0 16px
    }
    .qbutton.big_large i, .qbutton.big_large_full_width i, .qbutton.large i {
        margin: 0 0 0 15px
    }
    .qbutton.medium i {
        margin: 0 0 0 13px
    }
    .qbutton.small i {
        margin: 0 0 0 8px
    }
    .q_social_icon_holder .fa-stack {
        margin: .1407692307692308em
    }
    .wpb_flexslider.flexslider.have_frame {
        width: 442px
    }
    .frame_holder>.wpb_wrapper {
        padding: 24px 0 58px
    }
    .have_frame.flexslider .flex-prev {
        left: -60px
    }
    .have_frame.flexslider .flex-next {
        right: -60px
    }
    .touchevents .content {
        margin: 0!important
    }
    .q_percentage_with_icon {
        width: 100%!important;
        height: 110px!important;
        line-height: 110px!important
    }
    .q_percentage_with_icon canvas {
        width: 100%!important;
        height: 100%!important;
    }
}

@media only screen and (max-width: 1024px) {
    .vertical_menu_enabled .carousel-inner:not(.relative_position) {
        left: 0!important;
    }
}

@media only screen and (max-width: 1025px) {
    .page_header .header_inner {
        display: block;
    }
    .vertical_menu_hidden.vertical_menu_hidden_with_logo .vertical_menu_area_bottom_logo, aside.vertical_menu_area {
        display: none;
    }
    .content .container, .full_width, .q_slider, .title_outer, footer {
        padding-left: 0!important;
    }
    body.page-template-blog-masonry-full-width-php.vertical_menu_enabled .content .full_width {
        padding-left: 45px!important;
    }
    .boxed.vertical_menu_enabled, .boxed.vertical_menu_enabled footer.uncover {
        padding-left: 0!important;
    }
    .vertical_menu_enabled .content .container, .vertical_menu_enabled .q_slider, .vertical_menu_enabled footer {
        width: 100%;
    }
    .vertical_menu_enabled .carousel-inner:not(.relative_position), .vertical_menu_enabled.vertical_menu_hidden .carousel-inner:not(.relative_position), .vertical_menu_enabled.vertical_menu_hidden.vertical_menu_right .carousel-inner:not(.relative_position) {
        left: 0!important;
    }
}